<h2 mat-dialog-title>Filter Grades</h2>
<div mat-dialog-content>
    <div style="width: 100%; display: flex; flex-direction: column; margin-bottom: 10px;">
        <label for="unit" class="label">Select Unit : </label>
        <select id="unit" class="inputbox" [(ngModel)]="selectedUnitId">
          <option value="" [defaultSelected]="true">All</option>
          <option *ngFor="let unit of data.unitlist" [value]="unit.unitid">
            {{ unit.unitname }}
          </option>
        </select>
    </div>

    <div style="width: 100%;">
        <label for="gradeInput" class="label">Enter Grade : </label>
        <div style="display: flex; gap: 4px; width: 100%;">
          <input id="gradeInput" style="width: 40%;" class="inputbox" type="number" placeholder="Enter grade" [(ngModel)]="gradeFilterValue">
  
          <select style="width: 60%;" class="inputbox" [(ngModel)]="filterGradeOp">
            <option value="=" [defaultSelected]="true">( = )  Equal To</option>
            <option value=">" >( > )  Grater Than</option>
            <option value="<" >( < )  Less Than</option>
            <option value=">=" >( >= ) Grater Than or Equal</option>
            <option value="<=" >( <= ) Less Than or Equal</option>
          </select>
        </div>
    </div>
</div>
<div style="float: right;" mat-dialog-actions>
  <button mat-button class="btn new-class" (click)="onCancel()">Cancel</button>
  <button mat-button class="btn new-class" (click)="onSubmit()">Submit</button>
</div>
