<!-- <button (click)="test()" >test</button> -->
<app-breadcrumb *ngIf="!isLoading" [s_breadcrumb]="coursename_diaplay"></app-breadcrumb>

<div class="content" [ngClass]="{'d-flex':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
  <div class="border-none d-flex justify-content-between align-items-center"
    *ngIf="currentScreenSize == 'Small' || currentScreenSize == 'XSmall'">
    <div>
      <span class="draft" *ngIf="course.coursestatus == '0'">aria-hidden</span>
      <span class="published" *ngIf="course.coursestatus == '1' && !course.newchanges ">RELEASED </span>
    </div>
    <div class=" d-flex align-items-center me-2">
      <app-button-loader *ngIf="course.coursestatus == '0'" (click)="updateCourseStatus(1)" [button_text]="'Release'"
        [isStillupload]="isStillPublish" [margin_left]="'0'" [width]="'100'" class="p-1">
      </app-button-loader>
      <app-button-loader *ngIf="course.coursestatus == '1'" (click)="updateCourseStatus(0)" [button_text]="'Hide'"
        [isStillupload]="isStillPublish" [margin_left]="'0'" [width]="'100'" class="p-1">
      </app-button-loader>
    </div>
  </div>


  <div class="side-instructor-mobile d-flex  justify-content-between align-items-center"
    *ngIf="currentScreenSize == 'Small' || currentScreenSize == 'XSmall'">


    <div class="d-flex flex-row" style=" overflow: auto;
        white-space: nowrap;">
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="infoUrl">Course Info</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="customPageUrl">Custom Page</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="prerequisitesUrl">Prerequisites</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="instructorUrl">Instructors</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <div class="d-flex align-items-center">
          <span [routerLink]="curriculumUrl" [ngClass]="course.newchanges ? 'me-1' : '' ">Contents</span>
          <span *ngIf="course.newchanges" matTooltip="Changes are not published." class="dot"></span>
        </div>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active" *ngIf="showClass">
        <span [routerLink]="classUrl">Classes</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="assignmentUrl">Assignments</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="videoUrl">Video Tracking</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="studentUrl">Students</span>
      </div>
      <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active">
        <span [routerLink]="gradebookUrl">Grade Book</span>
      </div>
      <!-- <div class="nav-mobile-item" routerLinkActive="course-manage-mobile-active" *ngIf="showSchedule">
        <span [routerLink]="scheduleUrl">Schedule</span>
      </div> -->


    </div>
  </div>

  <div class="side-menu-large  d-flex flex-column"
    *ngIf="currentScreenSize != 'Small' && currentScreenSize != 'XSmall'">
    <ul class="list-group" style="position: sticky; top: 70px;">
      <!-- <div class="list-group-item border-none">
        <span class="draft" *ngIf="course.coursestatus == '0'">DRAFT </span>
        <span class="published" *ngIf="course.coursestatus == '1'">PUBLISHED </span>
      </div> -->
      <li class="list-group-item" style="border-top:  1px solid var(--border-color);" (click)="goToPage('info')"
        [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="course-manage-active"><span
          [routerLink]="[infoUrl]">Course
          Information</span></li>
      <li class="list-group-item" (click)="goToPage('custompage')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[customPageUrl]">Custom Page</span>
      </li>
      <li class="list-group-item" (click)="goToPage('prerequisites')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[prerequisitesUrl]">Prerequisites</span></li>
      <li class="list-group-item" (click)="goToPage('instructors')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[instructorUrl]">Instructors</span>
      </li>
      <li class="list-group-item" (click)="goToPage('curriculum')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active">
        <!-- <span [routerLink]="[curriculumUrl]">Contents</span> -->
        <div class="d-flex align-items-center justify-content-between">
          <span [routerLink]="curriculumUrl">Contents</span>
          <span *ngIf="course.newchanges" matTooltip="Changes are not published." class="dot"></span>
        </div>
      </li>
      <li class="list-group-item" (click)="goToPage('class')" [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="showClass" routerLinkActive="course-manage-active"><span [routerLink]="[classUrl]">Classes</span>
      </li>
      <li class="list-group-item" (click)="goToPage('assignments')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[assignmentUrl]">Assignments</span>
      </li>
      <li class="list-group-item" (click)="goToPage('videos')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[videoUrl]">Video Tracking</span>
      </li>
      <li class="list-group-item" (click)="goToPage('students')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[studentUrl]">Students</span>
      </li>
      <li class="list-group-item" (click)="goToPage('gradebook')" [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="course-manage-active"><span [routerLink]="[gradebookUrl]">Grade Book</span>
      </li>
      <!-- <li class="list-group-item" (click)="goToPage('schedule')" [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="showSchedule" routerLinkActive="course-manage-active"><span [routerLink]="[scheduleUrl]">Schedule</span>
      </li> -->


      <app-button-loader *ngIf="course.coursestatus == '0'" (click)="updateCourseStatus(1)" [button_text]="'Release'"
        [isStillupload]="isStillPublish" [width]="'100'" class="p-1" [margin_left]="'0'">
      </app-button-loader>
      <app-button-loader *ngIf="course.coursestatus == '1'" (click)="updateCourseStatus(0)" [button_text]="'Hide'"
        [isStillupload]="isStillPublish" [margin_left]="'0'" [width]="'100'" class="p-1">
      </app-button-loader>
      <div class="mt-2">
        <!-- <span class="draft" *ngIf="course.coursestatus == '0'">DRAFT </span> -->
        <span class="draft" *ngIf="course.coursestatus == '0'">HIDDEN </span>
        <span class="published" *ngIf="course.coursestatus == '1'">RELEASED</span>

      </div>
      <!-- <button mat-flat-button color="primary" class="p-1">Publish</button> -->
    </ul>

  </div>


  <!-- Curriculum Contents -->
  <div id="curriculum" role="tabpanel" *ngIf="router.url ===  curriculumUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">

    <mat-card>
      <div class="course-header d-flex flex-wrap mb-4 align-items-center justify-content-between">
        <div class="d-flex align-items-center" style="margin-top : 7px;">
          <h3 class="m-0 p-0 me-2">Course Contents</h3>
          <span *ngIf="course.newchanges" matTooltip="Changes are not published." class="dot"></span>

          <!-- <a *ngIf="!isLoading" mat-stroked-button color="primary" target="_blank" (click)="previewcontent()">Preview</a> -->

        </div>
        <!-- <div *ngIf="course.newchanges">
          Publish
        </div> -->
        <!-- <app-button-loader *ngIf="course.newchanges" style="margin: 0 !important;" (click)="publicChanges()"
          [button_text]="'Publish'" [isStillupload]="publicChangesLoading" [margin_left]="'0'" [width]="'100'"
          class="p-1 m-0">
        </app-button-loader> -->
        <div class="d-flex align-items-center">
          <div>
            <div class="preview-course" [matMenuTriggerFor]="menu">
              Preview
            </div>
            <mat-menu #menu="matMenu">
              <button (click)="goToPreview('001')" mat-menu-item>
                <span>Published Contents</span>
              </button>
              <button (click)="goToPreview('002')" mat-menu-item>
                <span>Unpublished Contents</span>
              </button>
            </mat-menu>
          </div>
          <div class="line" *ngIf="course.newchanges"></div>
          <app-button-loader *ngIf="course.newchanges" style="margin: 0 !important;" (click)="publicChanges()"
            [button_text]="'Publish'" [isStillupload]="publicChangesLoading" [margin_left]="'0'"  [margin_top]="'0'" [width]="'100'"
            class="p-1 m-0">
          </app-button-loader>
        </div>
      </div>

      <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
      </div>

      <div class="new-section mt-2" *ngIf="!isLoading && sections.length == 0">

        <button mat-button color="primary" (click)="showNewSection()" *ngIf="!isNewSectionOpen"> <i class="fa fa-plus"
            aria-hidden="true"></i>
          Section</button>

        <button mat-button color="warn" (click)="showNewSection()" *ngIf="isNewSectionOpen"> <i class="fa fa-minus"
            aria-hidden="true"></i>
          Close
        </button>


        <div *ngIf="isNewSectionOpen">
          <div class="card mb-5">
            <div class="p-3 d-flex new-section">
              <div class="new-section-label pt-2 pb-1">
                <span> New Section: </span>
              </div>
              <div class="ml fx">
                <form [formGroup]="newSectionForm" (ngSubmit)="addFirstSection()">
                  <div class="form-group">
                    <input type="text" class="form-control form-control-sm" placeholder="Enter a section name"
                      formControlName="name" [ngClass]="{ 'is-invalid': newSectionFormSubmitted && f.name.errors }">

                    <div *ngIf="newSectionFormSubmitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors?.required">{{allinoneService.input_err_msg}}
                      </div>
                    </div>

                  </div>


                  <div class="actions-button">
                    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="showNewSection()">
                      Cancel
                    </button>

                    <app-button-loader [button_text]="'Save'" [isStillupload]="isStillupload">
                    </app-button-loader>

                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section" *ngIf="!isLoading">

        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="mb-3" *ngFor="let section of sections;let s = index;">
            <div class="card" (cdkDragEnded)="dropEnd($event)" cdkDrag>
              <div class="card-body">
                <!-- section review -->
                <div class=" d-flex justify-content-between section-header">
                  <div class="d-flex flex-wrap  align-items-center">
                    <svg cdkDragHandle class="drag me-3" width="16" height="16" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z"
                        fill="CurrentColor"></path>
                    </svg>
                    <span class="me-3 position-relative">
                      <span class="section-name d-flex">
                        <span class="index-label">{{s+1}}.0</span>
                        <span class="me-2" *ngIf="!section.isRename" (click)="clickdropDown(section,s)">{{
                          section.section_name}}</span>




                        <div *ngIf="section.isRename" style="display: flex; align-items: center;">
                          <form [formGroup]="renameSectionForm" (ngSubmit)="renameSection(s)" style="width:25rem;">
                            <input type="text" class="form-control form-control-sm" autofocus [disabled]="isStillUpdate"
                              placeholder="Enter a section name" formControlName="name"
                              (keydown.escape)="section.isRename = !section.isRename"
                              [ngClass]="{ 'is-invalid': renameSectionFormSubmitted && renameSectionFormControl.name.errors }">
                          </form>
                          <div style="display: flex; align-items: center;">
                            <!-- <button class="cbtn btn-outline-secondary" type="button">Button</button>
                            <button class="cbtn btn-outline-secondary" type="button">Button</button> -->

                            <div style="display: flex; align-items: center;" *ngIf="!isStillUpdate">
                              <mat-icon class="close-domain mx-2" (click)="renameSection(s)" title="Save"
                                style="color: var(--main-color)">check</mat-icon>

                              <mat-icon class="close-domain" (click)="section.isRename = !section.isRename"
                                title="Cancel" style="color: var(--icon-grey-color);">close
                              </mat-icon>
                            </div>
                            <div style="display: flex; align-items: center; margin-left: 10px;" *ngIf="isStillUpdate">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                            <!-- <button class="btn btn-outline-secondary" type="button">Save</button>
                          <button class="btn btn-outline-secondary" type="button">Cancel</button>
                           -->

                            <!--
                          <app-button-loader [button_text]="'Save'" [isStillupload]="isStillUpdate">
                          </app-button-loader> -->
                          </div>
                        </div>

                        <button mat-button color="primary" class="rename-btn"
                          *ngIf=" currentScreenSize != 'XSmall' && !section.isRename" (click)="showrenameSection(s);">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                          <span>Edit</span>
                        </button>
                      </span>

                      <span class="d-flex sub-unit-con" (click)="clickdropDown(section,s)" *ngIf="!section.open"
                        style="margin-top: 3px;">
                        <span class="sub-unit">{{ section.sub_sections.length }}
                          Subsections</span>
                        <span class="sub-unit">&nbsp;{{section.unitlength}} Units</span>
                      </span>
                    </span>


                    <!-- class="mini-icon-button" -->
                    <button mat-icon-button color="primary" *ngIf=" currentScreenSize == 'XSmall'"
                      (click)="showrenameSection(s);">
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>


                    <!-- <button mat-icon-button color="primary" *ngIf=" currentScreenSize == 'XSmall'"
                      (click)="deleteSection(s)">
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button> -->
                    <div class="content-menu-btn" [matMenuTriggerFor]="menu1" *ngIf="currentScreenSize == 'XSmall'">
                      <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                    </div>
                    <mat-menu #menu1="matMenu">
                      <button class="d-flex align-items-center" (click)="duplicateContent(s,'','', '001')"
                        mat-menu-item>
                        <div class="d-flex">
                          <mat-icon class="duplicate me-2">content_copy</mat-icon>
                        </div>
                        <div>
                          Duplicate
                        </div>
                      </button>
                      <button (click)="deleteSection(s)" mat-menu-item class="d-flex align-items-center text-danger">
                        <div class="d-flex">
                          <mat-icon class="delete text-danger me-2">delete</mat-icon>
                        </div>
                        <div>
                          Delete
                        </div>
                      </button>
                    </mat-menu>
                  </div>


                  <div class="d-flex align-items-center" style="margin-right: 1rem;">
                    <!-- (click)="deleteSection(s)" -->
                    <div class="content-menu-btn" [matMenuTriggerFor]="menu2" *ngIf=" currentScreenSize != 'XSmall'">
                      <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                    </div>
                    <mat-menu #menu2="matMenu">
                      <button class="d-flex align-items-center" (click)="duplicateContent(s,'','', '001')"
                        mat-menu-item>
                        <div class="d-flex">
                          <mat-icon class="duplicate me-2">content_copy</mat-icon>
                        </div>
                        <div>
                          Duplicate
                        </div>
                      </button>
                      <button (click)="deleteSection(s)" mat-menu-item class="d-flex align-items-center text-danger">
                        <div class="d-flex">
                          <mat-icon class="delete text-danger me-2">delete</mat-icon>
                        </div>
                        <div>
                          Delete
                        </div>
                      </button>
                    </mat-menu>

                    <!-- <ng-container matColumnDef="duplicate">
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="" style="width: 20px !important">
                        <mat-icon style="font-size: 20px;">more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button (click)="deleteSection(s)" mat-menu-item>Delete</button>
                        <button (click)="duplicateCourse(element)" mat-menu-item>Duplicate</button>
                      </mat-menu>
                    </ng-container> -->

                    <div (click)="clickdropDown(section,s)"
                      class="drop-down-container d-flex align-items-center justify-content-center mat-elevation-z4"
                      matRipple>
                      <i *ngIf="!section.open" class="fa fa-chevron-down" aria-hidden="true"></i>
                      <i *ngIf="section.open" class="fa fa-chevron-up" aria-hidden="true"></i>
                    </div>
                  </div>

                </div>
                <span cdkDragHandle></span>

                <!-- section rename -->
                <!-- <div class="d-flex d-flex rename-section new-section" *ngIf="section.isRename">
                  <div class="new-section-label pt-2 pb-1 mx-1">
                    <span>Section </span>
                  </div>
                  <div class="fx">
                    <form [formGroup]="renameSectionForm" (ngSubmit)="renameSection(s)">
                      <div class="form-group">
                        <input type="text" class="form-control form-control-sm" autofocus
                          placeholder="Enter a section name" formControlName="name"
                          [ngClass]="{ 'is-invalid': renameSectionFormSubmitted && renameSectionFormControl.name.errors }">
                        <div *ngIf="renameSectionFormSubmitted && renameSectionFormControl.name.errors"
                          class="invalid-feedback">
                          <div *ngIf="renameSectionFormControl.name.errors?.required">
                            {{allinoneService.input_err_msg}}
                          </div>
                        </div>

                      </div>

                      <div class="actions-button">
                        <button mat-stroked-button type="button" class="mt-2 cancel-button"
                          (click)="section.isRename = !section.isRename">
                          Cancel
                        </button>

                        <app-button-loader [button_text]="'Save'" [isStillupload]="isStillUpdate">
                        </app-button-loader>

                      </div>

                    </form>
                  </div>
                </div> -->


                <div class="dragAndDropContainer" *ngIf="section.open" cdkDropList
                  [cdkDropListData]="section.sub_sections" (cdkDropListDropped)="dropSubSection($event)"
                  [id]="section.sectionid" [cdkDropListConnectedTo]="connectedSectionDropList">
                  <div class="sub-section" *ngFor="let sub_section of section.sub_sections;let ss = index;"
                    (cdkDragEnded)="dropSubSectionEnd($event,s)" cdkDrag [cdkDragData]="sub_section">
                    <div class="d-flex justify-content-between sub-section-header"
                      [ngClass]="{'border-bottom':  sub_section.open}">
                      <div class="d-flex flex-wrap align-items-center">

                        <svg cdkDragHandle class="drag" width="16" height="16" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z"
                            fill="CurrentColor"></path>
                        </svg>
                        <span class="position-relative">
                          <span class="index-label">{{s+1}}.{{ss+1}}</span>
                          <span (click)="clickdropDown1(sub_section)" class="sub-section-name me-2"
                            style="cursor: pointer; min-width: 20px;" *ngIf="!sub_section.isRename">{{
                            sub_section.sub_section_name}}</span>
                          <div *ngIf="sub_section.isRename" style="display: flex; align-items: center;">
                            <form [formGroup]="renameSubSectionForm" (ngSubmit)="renameSubSection(s,ss)"
                              style="width:25rem;">
                              <input type="text" class="form-control form-control-sm" autofocus
                                placeholder="Enter a sub section name" formControlName="name">
                            </form>
                            <div style="display: flex; align-items: center;">
                              <!-- <button class="cbtn btn-outline-secondary" type="button">Button</button>
                                <button class="cbtn btn-outline-secondary" type="button">Button</button> -->

                              <div style="display: flex; align-items: center;"
                                *ngIf="!sub_section.isStillUpdateSubSection">
                                <mat-icon class="close-domain mx-2" (click)="renameSubSection(s,ss)" title="Save"
                                  style="color: var(--main-color); cursor: pointer;">check</mat-icon>

                                <mat-icon class="close-domain" (click)="sub_section.isRename = !sub_section.isRename"
                                  title="Cancel" style="color: var(--icon-grey-color); cursor: pointer;">close
                                </mat-icon>
                              </div>
                              <div style="display: flex; align-items: center; margin-left: 10px;"
                                *ngIf="sub_section.isStillUpdateSubSection">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              </div>
                              <!-- <button class="btn btn-outline-secondary" type="button">Save</button>
                              <button class="btn btn-outline-secondary" type="button">Cancel</button>
                               -->

                              <!--
                              <app-button-loader [button_text]="'Save'" [isStillupload]="isStillUpdate">
                              </app-button-loader> -->
                            </div>
                          </div>
                        </span>

                        <button mat-button color="primary" class="rename-btn"
                          *ngIf=" currentScreenSize != 'XSmall' && !sub_section.isRename"
                          (click)="showrenameSubsection(s,ss);">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                          <span>Edit</span>
                        </button>


                        <button mat-icon-button color="primary" *ngIf=" currentScreenSize == 'XSmall'"
                          (click)="showrenameSubsection(s,ss);">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>


                        <!-- <button mat-icon-button color="primary" *ngIf=" currentScreenSize == 'XSmall'"
                          (click)="deleteSubSection(s,ss)">
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button> -->
                        <div class="content-menu-btn" [matMenuTriggerFor]="menu3"
                          *ngIf=" currentScreenSize == 'XSmall'">
                          <a>
                            <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                        </div>
                        <mat-menu #menu3="matMenu">
                          <button (click)="duplicateContent(s,ss, '', '002')" mat-menu-item
                            class="d-flex align-items-center">
                            <div class="d-flex">
                              <mat-icon class="duplicate me-2">content_copy</mat-icon>
                            </div>
                            <div>
                              Duplicate
                            </div>
                          </button>
                          <button (click)="deleteSubSection(s,ss)" mat-menu-item
                            class="d-flex align-items-center text-danger">
                            <div class="d-flex">
                              <mat-icon class="delete text-danger me-2">delete</mat-icon>
                            </div>
                            <div>
                              Delete
                            </div>
                          </button>
                        </mat-menu>


                      </div>

                      <div class="d-flex">
                        <!-- <div class="delete-btn" (click)="deleteSubSection(s,ss)" *ngIf=" currentScreenSize != 'XSmall'">
                          <a>
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </a>
                        </div> -->
                        <div class="content-menu-btn" [matMenuTriggerFor]="menu4"
                          *ngIf=" currentScreenSize != 'XSmall'">
                          <a>
                            <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                        </div>
                        <mat-menu #menu4="matMenu">
                          <button (click)="duplicateContent(s,ss, '', '002')" mat-menu-item
                            class="d-flex align-items-center">
                            <div class="d-flex">
                              <mat-icon class="duplicate me-2">content_copy</mat-icon>
                            </div>
                            <div>
                              Duplicate
                            </div>
                          </button>
                          <button (click)="deleteSubSection(s,ss)" mat-menu-item
                            class="d-flex align-items-center text-danger">
                            <div class="d-flex">
                              <mat-icon class="delete text-danger me-2">delete</mat-icon>
                            </div>
                            <div>
                              Delete
                            </div>
                          </button>
                        </mat-menu>
                        <div (click)="clickdropDown1(sub_section)"
                          class="drop-down-container d-flex align-items-center justify-content-center mat-elevation-z4"
                          matRipple>
                          <i *ngIf="!sub_section.open " class="fa fa-chevron-down" aria-hidden="true"></i>
                          <i *ngIf="sub_section.open " class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>

                    <span cdkDragHandle></span>


                    <!--subsection rename -->
                    <!-- <div class="d-flex d-flex p-3 new-section" *ngIf="sub_section.isRename"
                      style="padding-left: 8px !important;">
                      <div class="new-section-label pt-2 pb-1 mx-1">
                        <span>Subsection</span>
                      </div>
                      <div class="fx">
                        <form [formGroup]="renameSubSectionForm" (ngSubmit)="renameSubSection(s,ss)">
                          <div class="form-group">
                            <input type="text" class="form-control form-control-sm" autofocus
                              placeholder="Enter a subsection name" formControlName="name">
                          </div>

                          <div class="actions-button">
                            <button mat-stroked-button type="button" class="mt-2 cancel-button"
                              (click)="sub_section.isRename = !sub_section.isRename">
                              Cancel
                            </button>
                            <app-button-loader [button_text]="'Save'" [isStillupload]="section.isStillUpdateSubSection">
                            </app-button-loader>

                          </div>

                        </form>
                      </div>
                    </div> -->


                    <div *ngIf="sub_section.open">

                      <!-- unit view -->

                      <div class="unit-group p-2 dragAndDropContainer" cdkDropList [cdkDropListData]="sub_section.units"
                        (cdkDropListDropped)="dropUnit($event)" [cdkDropListData]="sub_section.units"
                        [id]="sub_section.subsectionid" [cdkDropListConnectedTo]="connectedSubSectionDropList">
                        <div class="unit-item mb-2" *ngFor="let unit of sub_section.units;let su = index;" cdkDrag
                          [cdkDragData]="unit">
                          <div class="unit-header d-flex justify-content-between"
                            [ngClass]="{'border-bottom':  unit.open}" *ngIf="!unit.isRename">


                            <div class="d-flex  align-items-center">
                              <svg cdkDragHandle class="drag" width="16" height="16" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z"
                                  fill="CurrentColor"></path>
                              </svg>

                              <span class="section-name position-relative" (click)="clickQuestionDropdown(unit)">
                                <span class="index-label">{{s+1}}.{{ss+1}}.{{su+1}}</span>
                                <span *ngIf="unit.contenttype == 1 && unit.libraryid == ''">
                                  <img [src]="'../../../assets/images/' + unit.icon + '.png'" alt="Video"
                                    class="navbar-brand-logo" style="width: 15px; height: 15px;">
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 1 && unit.libraryid != ''">
                                  <!-- <i class="fa fa-video-camera"></i> -->
                                  <img [src]="allinoneService.fileurl + 'icons/video.png'" alt="Video"
                                    class="navbar-brand-logo" style="width: 15px; height: 15px;">

                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 2">
                                  <img [src]="'../../../assets/images/' + unit.icon + '.png'" alt="File"
                                    class="navbar-brand-logo" style="width: 15px; height: 15px;">
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 10">
                                  <img [src]="allinoneService.fileurl + 'icons/audio.png'" alt="Audio"
                                    class="navbar-brand-logo" style="width: 15px; height: 15px;">
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 3">
                                  <i class="fa fa-file-text"></i>
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 4">
                                  <i class="fas fa-link"></i>
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 5">
                                  <i class="fas  fa-paperclip" style="color:  black;"></i>
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 6">
                                  <i class="fas fa-clipboard-list" style="color: black;"></i>
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 7">
                                  <img src="'../../../assets/images/assignment.png" alt="Assignment"
                                    class="navbar-brand-logo" style="width: 15px; height: 15px;">
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                                <span *ngIf="unit.contenttype == 8">
                                  <img src="'../../../assets/images/discussion.png" alt="Assignment"
                                    class="navbar-brand-logo" style="width: 15px; height: 15px;">
                                  <span [ngClass]="{'ms-2' : unit.unit_name}">{{unit.unit_name}}</span>
                                </span>
                              </span>

                              <button mat-button color="primary" class="rename-btn"
                                (click)="showUnitEditor(s,ss,su,'edit')" *ngIf=" currentScreenSize != 'XSmall'">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                <span>Edit</span>
                              </button>


                              <button mat-icon-button color="primary" *ngIf=" currentScreenSize == 'XSmall'"
                                (click)="showUnitEditor(s,ss,su,'edit')">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                              </button>


                              <!-- <button mat-icon-button color="primary" *ngIf=" currentScreenSize == 'XSmall'"
                                (click)="deleteUnit(s,ss,su)">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </button> -->
                              <div class="content-menu-btn" [matMenuTriggerFor]="menu5"
                                *ngIf=" currentScreenSize == 'XSmall'">
                                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                              </div>
                              <mat-menu #menu5="matMenu">
                                <button (click)="duplicateContent(s,ss, su, '003')" mat-menu-item
                                  class="d-flex align-items-center">
                                  <div class="d-flex">
                                    <mat-icon class="duplicate me-2">content_copy</mat-icon>
                                  </div>
                                  <div>
                                    Duplicate
                                  </div>
                                </button>
                                <button (click)="deleteUnit(s,ss,su)" mat-menu-item
                                  class="d-flex align-items-center text-danger">
                                  <div class="d-flex">
                                    <mat-icon class="delete text-danger me-2">delete</mat-icon>
                                  </div>
                                  <div>
                                    Delete
                                  </div>
                                </button>
                              </mat-menu>


                            </div>


                            <div class="d-flex" style="margin-right: -0.5rem;">
                              <div *ngIf="unit.contenttype == 8" class="me-2 d-flex align-items-center view-discussion"
                                (click)="viewDiscussion(unit)">
                                View Discussion
                              </div>
                              <!-- <div class="delete-btn" (click)="deleteUnit(s,ss,su)"
                                [ngClass]="{'me-6' : unit.contenttype != 6}" *ngIf=" currentScreenSize != 'XSmall'">
                                <a>
                                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </a>
                              </div> -->
                              <div class="content-menu-btn" [matMenuTriggerFor]="menu6"
                                *ngIf=" currentScreenSize != 'XSmall'">

                                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>

                              </div>
                              <mat-menu #menu6="matMenu">
                                <button (click)="duplicateContent(s,ss, su, '003')" mat-menu-item
                                  class="d-flex align-items-center">
                                  <div class="d-flex">
                                    <mat-icon class="duplicate me-2">content_copy</mat-icon>
                                  </div>
                                  <div>
                                    Duplicate
                                  </div>
                                </button>
                                <button (click)="deleteUnit(s,ss,su)" mat-menu-item
                                  class="d-flex align-items-center text-danger">
                                  <div class="d-flex">
                                    <mat-icon class="delete text-danger me-2">delete</mat-icon>
                                  </div>
                                  <div>
                                    Delete
                                  </div>
                                </button>
                              </mat-menu>

                              <div (click)="clickQuestionDropdown(unit)"
                                class="drop-down-container d-flex align-items-center justify-content-center mat-elevation-z4"
                                matRipple *ngIf="unit.contenttype == 6">
                                <i *ngIf="!unit.open " class="fa fa-chevron-down" aria-hidden="true"></i>
                                <i *ngIf="unit.open " class="fa fa-chevron-up" aria-hidden="true"></i>
                              </div>

                            </div>

                          </div>

                          <span cdkDragHandle></span>

                          <div *ngIf="unit.open && unit.contenttype == 6">
                            <div class="questions-container">
                              <div class="questions">
                                <div *ngIf="unit.gettingquestions"
                                  class="d-flex align-items-center justify-content-center">
                                  <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
                                </div>
                                <ng-container *ngIf="!unit.gettingquestions && !unit.isNewQuestion">
                                  <div class="d-flex align-items-center">
                                    <div *ngIf="unit.questions.length > 0">

                                      <div>
                                        <h3 class="mb-1"><strong>Questions</strong></h3>
                                      </div>
                                      <div class="text-secondary mb-2">
                                        Total : {{unit.questions.length}}
                                      </div>
                                    </div>
                                  </div>
                                  <div cdkDropList (cdkDropListDropped)="dragAndDropQuestion(unit, $event)">
                                    <div *ngFor="let quiz of unit.questions; let qi = index"
                                      class="quiz d-flex align-items-center" cdkDrag [cdkDragData]="unit.questions">
                                      <div class="me-2">
                                        <svg cdkDragHandle class="drag" width="16" height="16" viewBox="0 0 24 24">
                                          <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z"
                                            fill="CurrentColor"></path>
                                        </svg>
                                      </div>
                                      <div class="overflow-question">
                                        <span *ngIf="unit.autonumbering">{{qi+1}}.&nbsp;</span><span
                                          [innerHtml]="quiz.type == '004' ? quiz.question.title : quiz.question"></span>
                                      </div>
                                      <div class="d-flex">
                                        <button mat-icon-button class="my-edit-delete" color="primary"
                                          (click)="editQuestion(quiz, unit)">
                                          <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </button>


                                        <button mat-icon-button class="my-edit-delete" color="primary"
                                          (click)="deleteQuestion(qi, unit)">
                                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div *ngIf="unit.contenttype == 6">
                                    <button mat-button color="primary" (click)="newQuestion(unit)"> <i
                                        class="fa fa-plus" aria-hidden="true"></i>
                                      Question</button>
                                    <button mat-button color="primary" (click)="newimportquestion(unit)"> <i
                                        class="fa fa-plus" aria-hidden="true"></i>

                                      Import Questions</button>
                                    <button mat-button color="primary" (click)="importFromExcel(unit)"> <i
                                        class="fa fa-plus" aria-hidden="true"></i>

                                      Import From Excel</button>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="unit.isNewQuestion">
                                  <!-- <app-question-editor [courseid]="courseid" [unitid]="unit.unitid"
                                    [sortkey]="unit.questions.length" [questionForEdit]="questionForEdit"
                                    (saveQuestion)="saveQuestion(unit, $event)"
                                    (updateQuestion)="updateQuestion(unit, $event)"
                                    (cancelQuestion)="cancelQuestion(unit)">
                                  </app-question-editor> -->
                                </ng-container>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <button mat-button color="primary" (click)="showUnitEditor(s,ss,0,'new')" style="margin-left: 10px;
                                            margin-right: 10px;
                                            width: 80px;">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Unit</button>

                    </div>

                  </div>
                </div>
                <div *ngIf="section.open">
                  <div class="new-section mt-1">
                    <button mat-button color="primary" (click)="showNewSubsection(section, 'open')"
                      *ngIf="!section.isNewSubsectionOpen"> <i class="fa fa-plus" aria-hidden="true"></i>
                      Subsection</button>

                    <button mat-button color="warn" (click)="showNewSubsection(section, 'close')"
                      *ngIf="section.isNewSubsectionOpen"> <i class="fa fa-minus" aria-hidden="true"></i>
                      Close
                    </button>



                    <div *ngIf="section.isNewSubsectionOpen">
                      <div class="card">
                        <div class="p-3 d-flex new-section">
                          <div class="new-section-label pt-1 pb-1">
                            <span> New Subsection: </span>
                          </div>
                          <div class="fx">
                            <form [formGroup]="renameSubSectionForm" (ngSubmit)="addSubSection(s)">
                              <div class="form-group">
                                <input type="text" class="form-control form-control-sm" autofocus
                                  placeholder="Enter a subsection name" formControlName="name">
                              </div>

                              <div class="actions-button">
                                <button mat-stroked-button class="mt-2 cancel-button" type="button"
                                  (click)="showNewSubsection(section, 'close')">
                                  Cancel
                                </button>

                                <app-button-loader [button_text]="'Save'"
                                  [isStillupload]="section.isStilluploadSubSection">
                                </app-button-loader>
                              </div>
                            </form>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="new-section mt-2">

              <button mat-button color="primary" (click)="showNewSection(s)" *ngIf="!section.isNewSectionOpen"> <i
                  class="fa fa-plus" aria-hidden="true"></i>
                Section</button>

              <button mat-button color="warn" (click)="showNewSection(s)" *ngIf="section.isNewSectionOpen"> <i
                  class="fa fa-minus" aria-hidden="true"></i>
                Close
              </button>


              <div class="card gray-background" *ngIf="section.isNewSectionOpen">
                <!-- <div class="card mb-5"> -->
                <div>
                  <div class="p-3 d-flex new-section pt-0">
                    <div class="new-section-label pt-2 pb-1">
                      <span> New Section: </span>
                    </div>
                    <div class="fx">
                      <form [formGroup]="newSectionForm" (ngSubmit)="addSection(s)">
                        <div class="form-group">
                          <input type="text" class="form-control form-control-sm" placeholder="Enter a section name"
                            formControlName="name"
                            [ngClass]="{ 'is-invalid': section.newSectionFormSubmitted && f.name.errors }">

                          <div *ngIf="section.newSectionFormSubmitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors?.required">
                              {{allinoneService.input_err_msg}}
                            </div>
                          </div>

                        </div>


                        <div class="actions-button">
                          <button mat-stroked-button class="mt-2 cancel-button" type="button"
                            (click)="showNewSection(s)">
                            Cancel
                          </button>

                          <app-button-loader [button_text]="'Save'" [isStillupload]="section.isStillupload">
                          </app-button-loader>

                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>


  <div [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} " class="tab-pane"
    role="tabpanel" id="info" *ngIf="router.url ===  infoUrl">
    <mat-card>
      <div class="course-header d-flex mb-2 justify-content-between align-items-center">
        <h3>Course Information</h3>

        <!-- <a *ngIf="!isLoading" mat-stroked-button color="primary" target="_blank" (click)="preview()">Preview</a> -->
      </div>

      <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
      </div>

      <div *ngIf="!isLoading" class="p-1">
        <div class="form-group  mb-3">
          <label for="coursename" class="mb-2"><strong>Title</strong></label>
          <input type="text" [(ngModel)]="course.coursename" class="form-control" id="coursename"
            placeholder="Enter a course title" required #coursetitle="ngModel" autocomplete="off"
            [ngClass]="{ 'is-invalid': courseSubmitted && coursetitle.invalid }" (keyup.enter)="updateCourse()">
          <div class="invalid-feedback"
            *ngIf="coursetitle.invalid || (coursetitle.dirty || coursetitle.touched) && courseSubmitted">
            <div *ngIf="coursetitle.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>

        <div class="form-group  mb-3">
          <label for="coursename" class="mb-2"><strong>Subtitle</strong></label>
          <input type="text" [(ngModel)]="course.coursesubname" class="form-control" id="coursesubname"
            placeholder="Enter a course subtitle" #coursesubname="ngModel" autocomplete="off"
            (keyup.enter)="updateCourse()">
        </div>

        <div class=" mb-3">
          <label for="coursedescription" class="mb-2"><strong>Description</strong></label>
          <angular-editor [(ngModel)]="course.description" [config]="config"></angular-editor>
        </div>

        <div class=" mb-3">
          <label for="coursedescription" class="mb-2"><strong>Requirements</strong></label>
          <angular-editor [(ngModel)]="course.requirements" [config]="config"></angular-editor>
        </div>

        <div class="form-group mb-3">
          <label for="coursedescription" class="mb-2"><strong>Category</strong></label>
          <select class="form-select" [(ngModel)]="course.category" required [disabled]="gettingCategory"
            (ngModelChange)="categoryChange()">
            <!-- <option selected value="">Choose a category</option> -->
            <option [value]="cat.categoryid" *ngFor="let cat of categories">{{cat.categoryname}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>
        </div>

        <div class="form-group mb-3" *ngIf="course.channelid">
          <label for="coursedescription" class="mb-2"><strong>Subcategory</strong></label>
          <select class="form-select" [(ngModel)]="course.subcategory" required [disabled]="gettingCategory">
            <option selected value="">-</option>
            <option [value]="cat.categoryname" *ngFor="let cat of filteredSubcategories">{{cat.subcategoryname}}
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>
        </div>

        <!-- <ng-container *ngIf="course.channelid">
          <div class="form-group mb-3">
            <label for="coursedescription" class="mb-2"><strong>Mandatory</strong></label>
            <div class="d-flex align-items-center mb-3 mt-2">
              <div class="me-5">
                <input type="radio" name="mandatory" id="department" class="me-2"
                  style="margin-bottom: 3px !important; cursor:pointer" [checked]="course.mandatorytype == '001'"
                  value="001" [(ngModel)]="course.mandatorytype">
                <label for="department" style="cursor: pointer;">For Department</label>
              </div>
              <div class="me-5">
                <input type="radio" name="mandatory" id="post" class="me-2"
                  style="margin-bottom: 3px !important; cursor:pointer" [checked]="course.mandatorytype == '002'"
                  value="002" [(ngModel)]="course.mandatorytype">
                <label for="post" style="cursor: pointer;">For Post</label>
              </div>
              <div class="me-5">
                <input type="radio" name="mandatory" id="optional" class="me-2"
                  style="margin-bottom: 3px !important; cursor:pointer" [checked]="course.mandatorytype == '003'"
                  value="003" [(ngModel)]="course.mandatorytype">
                <label for="optional" style="cursor: pointer;">Optional</label>
              </div>
            </div>
          </div>

          <div class="mb-3" *ngIf="course.mandatorytype == '001'">
            <div class="col-md-12 mb-3">

              <ng-select placeholder="Select a department" [multiple]="true" [(ngModel)]="course.mandatoryDepts"
                name="channelid" id="channelid" appearance="outline" [searchable]="true" [clearable]="true"
                [loading]="gettingTypes">
                <ng-option *ngFor="let dept of departmentlist" [value]="dept">{{dept}}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="mb-3" *ngIf="course.mandatorytype == '002'">
            <div class="col-md-12 mb-3">


              <ng-select placeholder="Select a post" [multiple]="true" [(ngModel)]="course.mandatoryPosts"
                name="channelid" id="channelid" appearance="outline" [searchable]="true" [clearable]="true"
                [loading]="gettingTypes">
                <ng-option *ngFor="let classcode of classcodelist" [value]="classcode.id">{{classcode.name}}</ng-option>
              </ng-select>
            </div>
          </div>
        </ng-container> -->

        <div class="form-group  mb-3">
          <div class="c_keywords">
            <label for="coursename" class="mb-2"><strong>Keywords</strong></label>
            <span>
              (Eg: beginner , photography , introduction)
            </span>
          </div>

          <input type="text" [(ngModel)]="course.keywords" class="form-control" id="coursename"
            placeholder="Enter keywords" #keywords="ngModel" autocomplete="off" (keyup.enter)="updateCourse()">
        </div>



        <div class="mb-3">
          <label for="channelName" class="me-2 mb-2"><strong>Channel</strong></label>
          <input class="mb-2" type="text" class="form-control" id="channelName"
            [placeholder]="course.channelname || 'KHub'" disabled>
        </div>

        <label class="d-flex align-items-center">
          <label for="image" class="mb-2"> <strong>Course Type</strong> </label>
        </label>
        <div class="d-flex align-items-center mb-3 mt-2">
          <div class="me-5">
            <input type="radio" name="type" id="selfpace" class="me-2"
              style="margin-bottom: 3px !important; cursor: pointer;" [checked]="course.type == '001'" value="001"
              [(ngModel)]="course.type">
            <label for="selfpace" style="cursor: pointer;">Self-Paced</label>
          </div>
          <div class="me-5">
            <input type="radio" name="type" id="instructorled" class="me-2"
              style="margin-bottom: 3px !important; cursor:pointer" [checked]="course.type == '002'" value="002"
              [(ngModel)]="course.type">
            <label for="instructorled" style="cursor: pointer;">Instructor-Led</label>
          </div>
          <div>
            <input type="radio" name="type" id="hybird" class="me-2"
              style="margin-bottom: 3px !important; cursor: pointer;" [checked]="course.type == '003'" value="003"
              [(ngModel)]="course.type">
            <label for="hybird" style="cursor: pointer;">Hybrid</label>
          </div>
        </div>
        <label class="d-flex align-items-center">
          <label for="image" class="mb-2"> <strong>Grading System</strong> </label>
        </label>
        <div class="d-flex align-items-center mb-3 mt-2">
          <div class="me-5">
            <input type="radio" name="grading" id="mark" class="me-2"
              style="margin-bottom: 3px !important; cursor: pointer;" [checked]="course.grading == '001'" value="001"
              [(ngModel)]="course.grading">
            <label for="mark" style="cursor: pointer;">Mark</label>
          </div>
          <div>
            <input type="radio" name="grading" id="hybird" class="me-2"
              style="margin-bottom: 3px !important; cursor: pointer;" [checked]="course.grading == '002'" value="002"
              [(ngModel)]="course.grading">
            <label for="percentage" style="cursor: pointer;">Percentage</label>
          </div>
        </div>
        <label class="d-flex align-items-center">
          <label for="pricing" class="mb-2"> <strong>Enrollment</strong> </label>
        </label>
        <div class="d-flex align-items-center mb-3 mt-2">
          <div class="me-5">
            <input type="radio" name="paymenttype" id="pricefree" class="me-2"
              style="margin-bottom: 3px !important; cursor: pointer;" [checked]="course.paymenttype == '001'"
              value="001" [(ngModel)]="course.paymenttype">
            <label for="pricefree" style="cursor: pointer;">Free</label>
          </div>
          <div class="me-5">
            <input type="radio" name="paymenttype" id="pricepaid" class="me-2"
              style="margin-bottom: 3px !important; cursor:pointer" [checked]="course.paymenttype == '002'" value="002"
              [(ngModel)]="course.paymenttype">
            <label for="pricepaid" style="cursor: pointer;">Paid</label>
          </div>

        </div>
        <div *ngIf="course.paymenttype=='002'" class="d-flex align-items-center mb-2 mt-2 col-12 col-sm-6">
          <div class="input-group mb-3">
            <input type="number" class="form-control" name="pricing" id="pricing" placeholder="1000"
              [(ngModel)]="course.fee" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
            <span class="input-group-text" id="">MMK</span>
          </div>
        </div>

        <div *ngIf="course.channelid && course.paymenttype=='002'">
          <label class="d-flex align-items-center">
            <label for="pricing" class="mb-2"> <strong>Members</strong> </label>
          </label>
          <div class="d-flex align-items-center mb-3 mt-2">
            <div class="me-5">
              <input type="radio" name="memberspaymenttype" id="memberspricefree" class="me-2"
                style="margin-bottom: 3px !important; cursor: pointer;" [checked]="course.memberspaymenttype == '001'"
                value="001" [(ngModel)]="course.memberspaymenttype">
              <label for="memberspricefree" style="cursor: pointer;">Free</label>
            </div>
            <div class="me-5">
              <input type="radio" name="memberspaymenttype" id="memberspricepaid" class="me-2"
                style="margin-bottom: 3px !important; cursor:pointer" [checked]="course.memberspaymenttype == '002'"
                value="002" [(ngModel)]="course.memberspaymenttype">
              <label for="memberspricepaid" style="cursor: pointer;">Paid</label>
            </div>

          </div>
          <div *ngIf="course.memberspaymenttype=='002'" class="d-flex align-items-center mb-2 mt-2 col-12 col-sm-6">
            <div class="input-group mb-3">
              <input type="number" class="form-control" name="memberspricing" id="memberspricing" placeholder="1000"
                [(ngModel)]="course.membersfee" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
              <span class="input-group-text" id="">MMK</span>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-3"
          *ngIf="course.channelid == '' && course.type != '002' && course.paymenttype == '001'">
          <input type="checkbox" id="anonymous" name="anonymous" class="me-2" (change)="anonymousChange($event)"
            style="margin-bottom: 3px !important;" [checked]="course.anonymousLearning == '002'">
          <label for="anonymous">Allow anonymous learning</label>
          <mat-icon class="helpicon d-flex align-items-center"
            matTooltip="This allows leaners access to your course without enrollment to your course.">
            help</mat-icon>
        </div>

        <!-- <div class=" mb-3">
          <label class="d-flex align-items-center">
            <label for="customepage " class="mb-2"> <strong>Custom Page</strong> </label>
          </label>
          <div class="d-flex align-items-center justify-content-start">
            <div class="form-check form-switch d-flex justify-content-center switch">
              <input class="form-check-input switch-on-off" type="checkbox" id="gradepage"
                style="width: 3rem;height:1.5rem;" [(ngModel)]="course.gradepage">
            </div>
            <div class="mx-2 me-5">
              Grade
            </div>

            <div class="form-check form-switch d-flex justify-content-center switch">
              <input class="form-check-input switch-on-off" type="checkbox" id="all" style="width: 3rem;height:1.5rem;"
                [(ngModel)]="course.certificatepage" (ngModelChange)="certificateOnOff()">
            </div>
            <div class="mx-2 me-5">
              Certificate
            </div>

            <div class="form-check form-switch d-flex justify-content-center switch">
              <input class="form-check-input switch-on-off" type="checkbox" id="feedback"
                style="width: 3rem;height:1.5rem;" [(ngModel)]="course.feedbackpage">
            </div>
            <div class="mx-2">
              Feedback
            </div>
          </div>


        </div>
        <div class="d-flex flex-column mb-2 " *ngIf="course.certificatepage">
          <label class="d-flex align-items-center">
            <label for="pricing" class="mb-2"> <strong>Certificate Template</strong> </label>
          </label>

          <div class="d-flex flex-wrap" *ngIf="gettingTemplate">
            <div *ngFor="let x of [1,2]" class="me-3">
              <ngx-skeleton-loader [theme]="{ height: '200px',width: '250px'}">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="d-flex flex-wrap" *ngIf="!gettingTemplate">
            <div class="layout-container me-3 mb-3" *ngFor="let template of templates;">
              <div class="container">
                <div class="round">
                  <input type="checkbox" id="{{template.templateid}}" value="{{template.templateid}}" name="templates"
                    [checked]="course.templateid==template.templateid"
                    [disabled]="course.templateid==template.templateid" (change)="checkTemplate($event)" />
                  <label for="{{template.templateid}}"></label>
                </div>
              </div>
              <div class="layout-item ">
                <label for="{{template.templateid}}" class="cus-point">
                  <img alt="khub" [defaultImage]="'../../../assets/images/certificate1.png'"
                            [lazyLoad]="template.url ? template.url : '../../../assets/images/khub.png'">
                  <img [src]="template.previewimageurl">
                </label>
              </div>
            </div>
          </div>
        </div> -->


        <label class="d-flex align-items-center">
          <label type="file" for="image" class="mb-2"> <strong>Course Image</strong> </label>
          <mat-icon class="helpicon"
            matTooltip="The course image displays in your course as a course banner. Because these image sizes vary, please use the recommended size in the image guide.">
            help</mat-icon>
        </label>

        <div class="course-image-format-container">
          <div class="d-flex flex-column align-items-baseline">
            <div style="min-width: 200px;">
              <input type="file" class="inputfile" (change)="fileChangeEvent($event)" #fileInput
                accept="image/png, image/jpeg" id="file">
              <label for="file">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                  <path
                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                  </path>
                </svg> Choose a file
              </label>
            </div>


            <!-- image-crop -->
            <div *ngIf="imageChangedEvent != '' && !coppedImage" class="img-cropper">
              <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [aspectRatio]="16 / 9" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
              <button mat-flat-button color="primary" class="text-end" (click)="copyImage()">Crop
                Image</button>
            </div>


            <ng-container *ngIf="course.courseimagefiletype == 'base64' && course.courseimage != '' && coppedImage">
              <div style="position: relative">
                <img [src]="croppedImage" class="banner-img mt-2" width="310" height="175" />
                <span class="remove-img-icon" matTooltip="Remove Course Image">
                  <i class="fa fa-minus-circle" (click)="removeImage()"></i>
                </span>
              </div>
            </ng-container>

            <ng-container *ngIf="course.courseimagefiletype == 'url' && course.courseimage != ''">
              <div style="position: relative">
                <img [src]="allinoneService.imgurl  + course.courseimage" class="banner-img mt-2" width="310"
                  height="175" />
                <span class="remove-img-icon" matTooltip="Remove Course Image">
                  <i class="fa fa-minus-circle" (click)="removeImage()"></i>
                </span>
              </div>
            </ng-container>
          </div>


          <div>
            <strong>Recommended format</strong>
            <ul style="margin-top: 8px;
                        padding-left: 16px;">
              <li>Image format: JPG/PNG</li>
              <li>Recommended size: 750 x 422 pixels</li>
            </ul>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <app-button-loader (click)="updateCourse()" [button_text]="'Save'" [isStillupload]="isStillUpdateCourse">
          </app-button-loader>
        </div>

      </div>
    </mat-card>
  </div>

  <!-- Custom Page View -->
  <div id="students" role="tabpanel" *ngIf="router.url === customPageUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
      </div>
      <ng-container *ngIf="!isLoading">
        <div class=" mb-3">
          <label class="d-flex align-items-center">
            <h3 class="mb-2"> <strong>Custom Page</strong> </h3>
          </label>
          <div class="d-flex align-items-center justify-content-start">
            <div class="form-check form-switch d-flex justify-content-center switch">
              <input class="form-check-input switch-on-off" type="checkbox" id="gradepage"
                style="width: 3rem;height:1.5rem;" [(ngModel)]="course.gradepage">
            </div>
            <div class="mx-2 me-5">
              Grade
            </div>

            <div class="form-check form-switch d-flex justify-content-center switch">
              <input class="form-check-input switch-on-off" type="checkbox" id="feedback"
                style="width: 3rem;height:1.5rem;" [(ngModel)]="course.feedbackpage" (ngModelChange)="feedbackOnOff()">
            </div>
            <div class="mx-2 me-5">
              Feedback
            </div>

            <div class="form-check form-switch d-flex justify-content-center switch">
              <input class="form-check-input switch-on-off" type="checkbox" id="all" style="width: 3rem;height:1.5rem;"
                [(ngModel)]="course.certificatepage" (ngModelChange)="certificateOnOff()">
            </div>
            <div class="mx-2 ">
              Certificate
            </div>


          </div>


        </div>

        <!-- Feedback Template -->
        <div class="d-flex flex-column mb-3" *ngIf="course.feedbackpage">
          <label class="d-flex align-items-center">
            <h3 class="mb-2"> <strong>Feedback Template</strong> </h3>
          </label>
          <div class="input-group">
            <select class="form-select" [(ngModel)]="course.feedbacktemplateid" [disabled]="gettingFeedbackTemplate">
              <option *ngIf="feedbackTemplates.length == 0" value="" [selected]="course.feedbacktemplateid == ''">-
              </option>
              <option *ngFor="let ft of feedbackTemplates" [selected]="course.feedbacktemplateid == ft.fbtmpid"
                [value]="ft.fbtmpid"> {{ft.name}} </option>
            </select>
            <button class="btn btn-outline-secondary" type="button" mat-flat-button color="primary" class="ms-0"
              (click)="viewFeedbackTemplateQuestions()" [disabled]="gettingFeedbackTemplate">View Questions</button>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>
          </div>
        </div>

        <!-- Certificate Template -->
        <div class="d-flex flex-column mb-2 " *ngIf="course.certificatepage">
          <label class="d-flex align-items-center">
            <h3 class="mb-2"> <strong>Class</strong> </h3>
          </label>

          <select class="form-select mb-2" [(ngModel)]="selectedClassId" [disabled]="gettingCourseClasses" (change)="onClassChange()">
            <option selected value="">All</option>
            <option *ngFor="let class of courseClasses" [selected]="selectedClassId == class?.classid"
              [value]="class?.classid"> {{class?.classname}} </option>
          </select>
          
          <div>
            <label class="d-flex align-items-center">
              <h3 class="mb-2"> <strong>Certificate Setting</strong> </h3>
            </label>
  
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" id="certificatefeedback"
                [(ngModel)]="classTemplate.certificatefeedback" [disabled]="!course.feedbackpage">
              <label class="form-check-label" for="certificatefeedback">
                Feedback Require
              </label>
            </div>
  
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" id="grade" [(ngModel)]="classTemplate.grade">
              <label class="form-check-label" for="grade">
                Minimum Grade
              </label>
            </div>
  
            <ng-container *ngIf="classTemplate.grade">
              <div class="input-group">
                <input type="number" class="form-control" placeholder="Enter Percentage"
                  [(ngModel)]="classTemplate.certificategrade">
                <span class="input-group-text" id="gradeper">%</span>
              </div>
            </ng-container>

            <label class="d-flex align-items-center mt-3">
              <h3> <strong>Certificate Template</strong> </h3>
            </label>
  
            <div class="d-flex flex-wrap" *ngIf="gettingTemplate">
              <div *ngFor="let x of [1,2]" class="me-3">
                <ngx-skeleton-loader [theme]="{ height: '200px',width: '250px'}">
                </ngx-skeleton-loader>
              </div>
            </div>

            <div class="d-flex flex-wrap" *ngIf="!gettingTemplate">
              <div class="layout-container me-3 mb-3" *ngFor="let template of templates;">
                <div class="container">
                  <div class="round">
                    <input type="checkbox" id="{{template.templateid}}" value="{{template.templateid}}" name="templates"
                      [checked]="classTemplate.templateid==template.templateid"
                      [disabled]="classTemplate.templateid==template.templateid" (change)="checkTemplate($event)" />
                    <label for="{{template.templateid}}"></label>
                  </div>
                </div>
                <div class="layout-item ">
                  <label for="{{template.templateid}}" class="cus-point">
                    <!-- <img alt="khub" [defaultImage]="'../../../assets/images/certificate1.png'"
                        [lazyLoad]="template.url ? template.url : '../../../assets/images/khub.png'"> -->
                    <img [src]="template.previewimageurl">
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <app-button-loader [button_text]="'Save'" [isStillupload]="customPageSaving" (click)="updateCustomPage()">
          </app-button-loader>
        </div>
      </ng-container>

    </mat-card>
  </div>

  <!-- Instructors View -->
  <div id="curriculum" role="tabpanel" *ngIf="router.url ===  instructorUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">

    <mat-card>
      <ng-container *ngIf="!gettingInstructors;else elseTemplate">
        <div class="row">
          <div>
            <div class="my-3 d-flex align-items-center">
              <div class="co-title">
                Primary Instructor
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-12 col-lg-6">
            <mat-card class="d-flex align-items-center instructor-card" (click)="changeInstructorProfile()"
              [ngClass]="{'cus-point': isPrimary}">
              <div class="img-wrapper">
                <div [ngClass]="{ 'img-profile': primaryInstructor.profileimage == ''}">
                  <img class="profileimage" *ngIf="primaryInstructor.profileimage != ''"
                    [src]="primaryInstructor.profileimage" alt="">
                  <span *ngIf="primaryInstructor.profileimage == ''"> {{
                    primaryInstructor.username.charAt(0)}}</span>
                </div>
              </div>
              <div class="details d-flex flex-column ps-3">
                <div class="name">{{primaryInstructor.username}}</div>
                <div class="userid">{{primaryInstructor.userid}}</div>
                <div class="institute">{{primaryInstructor.institute}}</div>
              </div>
              <div class="switchprofile" matTooltip="Change profile" *ngIf="isPrimary">
                <img src="../../../assets/images/switch.png" width="20px">
              </div>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div>
            <div class="my-3 d-flex align-items-center">
              <div class="co-title">
                Co-Instructors
              </div>
              <div class="ms-3" *ngIf="!isNewInstructorOpen && isPrimary">
                <button mat-flat-button color="primary" class="text-end" (click)="showNewInstructor()">
                  <i class="fa fa-plus me-2"></i>Add</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isNewInstructorOpen && isPrimary" class="row">
          <div class="col-12 mb-5">
            <div class="card" style="padding: 10px 10px !important;">
              <div class="d-flex new-section">
                <div class="new-section-label pb-1">
                  <span> Instructor ID: </span>
                </div>
                <div class="fx">
                  <form [formGroup]="newInstructorForm" (ngSubmit)="addInstructor()">
                    <div class="form-group">
                      <input type="text" class="form-control form-control-sm"
                        placeholder="john@domain.com, +959*********" formControlName="name"
                        [ngClass]="{ 'is-invalid': newInstructorFormSubmitted && newInsctructorformControl.name.errors }">

                      <div *ngIf="newInstructorFormSubmitted && newInsctructorformControl.name.errors"
                        class="invalid-feedback">
                        <div *ngIf="newInsctructorformControl.name.errors?.required">
                          {{allinoneService.input_err_msg}}
                        </div>
                      </div>

                    </div>


                    <div class="actions-button">
                      <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="showNewInstructor()">
                        Cancel
                      </button>

                      <app-button-loader [button_text]="'Save'" [isStillupload]="isStillAddingInstructor">
                      </app-button-loader>

                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="(isNewInstructorOpen && coInstructorList.length > 0) || (!isNewInstructorOpen)">
          <div *ngFor="let co of coInstructorList; let ci = index" class="col-sm-12 col-md-6 col-12 col-lg-6 mb-3">
            <mat-card class="d-flex align-items-center instructor-card justify-content-between">
              <div class="d-flex align-items-center">
                <div class="img-wrapper">
                  <div [ngClass]="{ 'img-profile': co.profileimage == ''}">
                    <img class="profileimage" *ngIf="co.profileimage != ''"
                      [src]="allinoneService.imgurl + co.profileimage" alt="">
                    <span *ngIf="co.profileimage == ''"> {{ co.username.charAt(0)}}</span>
                  </div>
                </div>
                <div class="details d-flex flex-column ps-3">
                  <div class="name">{{co.username}}</div>
                  <div class="userid">{{co.userid}}</div>
                  <div class="institute">{{co.institute}}</div>
                </div>
              </div>
              <!-- <div class="delete" matTooltip="Remove from co-instructors" *ngIf="isPrimary"
                (click)="deleteCoInstructor(ci)">
                <mat-icon>close</mat-icon>
              </div> -->
              <div *ngIf="isPrimary">
                <button mat-icon-button [matMenuTriggerFor]="coinstructorMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #coinstructorMenu="matMenu">
                  <button mat-menu-item (click)="transferOwnership(ci)">Transfer Ownership</button>
                  <button mat-menu-item (click)="deleteCoInstructor(ci)">Remove</button>
                </mat-menu>
              </div>
            </mat-card>
          </div>
          <div *ngIf="coInstructorList.length == 0">
            <div style="width: 100%; height: 230px; opacity: 0.8;font-size: 16px;"
              class="d-flex align-items-center justify-content-center">
              Empty
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Loading Skeleton -->
      <ng-template #elseTemplate>
        <div class="row">
          <div>
            <div class="my-3 d-flex align-items-center">
              <div class="co-title">
                Primary Instructor
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-12 col-lg-6">
            <mat-card class="d-flex align-items-center instructor-card">
              <div>
                <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '50px',width: '50px'}">
                </ngx-skeleton-loader>
              </div>
              <div class="details d-flex flex-column ps-3">
                <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '10px',width: '60px'}" style="margin-bottom: -10px">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '10px',width: '70px'}" style="margin-bottom: -10px">
                </ngx-skeleton-loader>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div>
            <div class="my-3 d-flex align-items-center">
              <div class="co-title">
                Co-Instructors
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let x of [1,2,3]" class="col-sm-12 col-md-6 col-12 col-lg-6 mb-3">
            <mat-card class="d-flex align-items-center instructor-card">
              <div>
                <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '50px',width: '50px'}">
                </ngx-skeleton-loader>
              </div>
              <div class="details d-flex flex-column ps-3">
                <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '10px',width: '60px'}" style="margin-bottom: -10px">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '10px',width: '70px'}" style="margin-bottom: -10px">
                </ngx-skeleton-loader>
              </div>
            </mat-card>
          </div>
        </div>
      </ng-template>
    </mat-card>
  </div>

  <!-- Instructor View -->
  <div id="class" role="tabpanel" *ngIf="router.url === classUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <div class="co-title">
        Class
      </div>
      <app-classview></app-classview>
    </mat-card>
  </div>

  <!-- Students View -->
  <div id="students" role="tabpanel" *ngIf="router.url === studentUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <app-paid-students></app-paid-students>
    </mat-card>
  </div>

  <!-- Assignments View -->
  <div id="assignments" role="tabpanel" *ngIf="router.url === assignmentUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <!-- <app-assignments-view a="{{assignmentList}}" ></app-assignments-view> -->
      <app-assignments-view [assList]="assignmentList"></app-assignments-view>
    </mat-card>
  </div>

  <!-- Videos View -->
  <div id="videos" role="tabpanel" *ngIf="router.url === videoUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <app-video-view></app-video-view>
    </mat-card>
  </div>

  <!-- Prerequistes View -->
  <div id="prerequisites" role="tabpanel" *ngIf="router.url === prerequisitesUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <div class="row">
        <div>
          <div class="my-3 d-flex align-items-center">
            <div class="co-title">
              Prerequisites
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="prerequitiesCourse.length > 0">
        <div class=" col-12 mb-3" *ngFor="let prerequisite of prerequitiesCourse">

          <mat-card class="d-flex align-items-center instructor-card">
            <div class="img-wrapper">
              <div [ngClass]="{ 'courseimage': prerequisite.courseimage == ''}">
                <img class="courseimage" *ngIf="prerequisite.courseimage != ''"
                  [src]="prerequisite.courseimage==''?allinoneService.defaultImg:prerequisite.courseimage"
                  [src]="prerequisite.courseimage" alt="">

              </div>
            </div>
            <div class="details d-flex flex-column ps-3">
              <div class="name">{{prerequisite.coursename}}</div>
              <div class="userid">{{prerequisite.instructorname}}</div>
              <div class="institute">{{prerequisite.institute}}</div>
            </div>

          </mat-card>
        </div>
      </div>
      <div class="nodata" *ngIf="prerequitiesCourse.length == 0">
        No prerequisite required.
      </div>

    </mat-card>
  </div>

  <!-- Grade Book view -->
  <div id="gradebook" role="tabpanel" *ngIf="router.url === gradebookUrl"
    [ngClass]="{'flex-grow-1 gradebook-desktop':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <app-grade-book></app-grade-book>
    </mat-card>
  </div>

  <!-- Schedule View -->
  <div id="students" role="tabpanel" *ngIf="router.url === scheduleUrl"
    [ngClass]="{'flex-grow-1':  currentScreenSize != 'Small' && currentScreenSize != 'XSmall'} ">
    <mat-card>
      <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
      </div>
      <app-coures-schedule [schedule]="courseSchedule" *ngIf="!isLoading" (updateSchedule)="updateSchedule($event)">
      </app-coures-schedule>
    </mat-card>
  </div>



  <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="ntd_offcanvas"
    aria-labelledby="offcanvasScrollingLabel">
    <div class="offcanvas-header">
      <h3 class="offcanvas-title" id="offcanvasScrollingLabel">Need to do following steps</h3>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="list-group">
        <div class="list-group-item d-flex justify-content-center" style="color: var(--main-color);">
          Course Information
        </div>
        <li class="list-group-item" *ngIf="course.coursesubname == ''">
          Have a course subtitle
        </li>
        <li class="list-group-item" *ngIf="course.description.length < 300">
          Have a course description with at least 300 words
        </li>
        <li class="list-group-item" *ngIf="course.requirements == ''">
          Have a course requirements
        </li>
        <li class="list-group-item" *ngIf="course.category == ''">
          Select the category of your course
        </li>
        <li class="list-group-item" *ngIf="course.courseimage == ''">
          Have a course image
        </li>
      </ul>
    </div>
  </div>
</div>