<div>
    <h2><strong>{{assignmentname}}</strong></h2>
</div>
<form (ngSubmit)="sumbit()">
    <!-- <div class="form-group" class="mb-2">
        <label for="unitname" class="mb-1">
            Unit Name
        </label>
        <input type="text" class="form-control form-control-sm" placeholder="" [(ngModel)]="submitForm.unitname"
            #unitname="ngModel" [ngClass]="{ 'is-invalid': formSubmitted &&  unitname.invalid}">

        <div *ngIf="formSubmitted && unitname.invalid" class="invalid-feedback">
            {{allinoneService.input_err_msg}}
        </div>
    </div> -->
    <div class="mb-2">
        <!-- <div class="new-section-label pt-1 pb-1">
          <strong>Due Date</strong>
        </div> -->
        <label for="deadlinedate" class="mb-1">
            Due Date
        </label>
        <div class="row">
          <div class="col-6">
            <input type="date" class="form-control" id="deadlinedate" name="deadlinedate" [(ngModel)]="submitForm.deadlinedate"
              required #deadlinedate="ngModel" step="1">
          </div>
          <div class="col-6">
            <div class="input-group">
              <input type="time" class="form-control form-control-sm" name="deadlinetime" autofocus placeholder=""
                [(ngModel)]="submitForm.deadlinetime" #deadlinetime="ngModel"
                [ngClass]="{ 'is-invalid': (formSubmitted && deadlinetime.invalid ) }" autocomplete="off">
              <button class="btn" type="button" (click)="deadlineclear()"
                style="background-color: var(--main-color);color: white;">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>

      </div>
    <div class="actions-button">
        <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()"
            [disabled]="isLoading">
            Cancel
        </button>

        <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="sumbit()">
        </app-button-loader>

    </div>
</form>