import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IndividualDeadlinesComponent } from '../../assignments/individual-deadlines/individual-deadlines.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { FormBuilder } from '@angular/forms';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LoadingService } from '../../shared/services/loading.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-noti-add-form',
  templateUrl: './noti-add-form.component.html',
  styleUrls: ['./noti-add-form.component.scss']
})
export class NotiAddFormComponent implements OnInit, OnDestroy {

  typeList = [
    {
      name: 'Class',
      id: '001',
    },
    {
      name: 'Student',
      id: '002',
    },
  ];
  
  subscriptions = new SubSink();
  courseid = '';
  classid = '';

  studentList: any = [];
  gettingStudents: boolean = false;
  saveLoading : boolean = false;

  formSubmitted : boolean = false;

  submitForm = {
    type : '001',
    student : '',
    title : '',
    message : ''
  }

  constructor(
    private dialogRef: MatDialogRef<IndividualDeadlinesComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log(data);
    
    if(data) {
      this.courseid = data.courseid
      this.classid = data.classid
      this.studentList = data.studentList
    }
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  pushNoti() {
    this.formSubmitted = true;
    if(this.submitForm.type == '002' && this.submitForm.student == '') {
      this.messageService.openSnackBar('Student cannot be blank.',
        'warn'
      );
      return;
    }

    if(this.submitForm.title == '') {
      this.messageService.openSnackBar('Title cannot be blank.',
        'warn'
      );
      return;
    }

    if(this.submitForm.message == '') {
      this.messageService.openSnackBar('Message cannot be blank.',
        'warn'
      );
      return;
    }

    this.saveLoading = true;
    var data = {
      courseid : this.courseid,
      classid : this.classid,
      title : this.submitForm.title,
      usersyskey : this.submitForm.student,
      message : this.submitForm.message,
      // type : this.submitForm.type
    }    
    this.subscriptions.sink = this.courseService.addNotification(data).subscribe(
      (res:any) => {
        if(res.returncode == 300) {
          this.dialogRef.close({
            success : true,
            newData : {
              title : this.submitForm.title,
              message : this.submitForm.message,
              type : this.submitForm.type,
            }
          })
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.formSubmitted = false;
      } ,(err:any) => {
        this.saveLoading = false;
        this.formSubmitted = false;
      }
    )
  }

  // getStudents() {
  //   this.gettingStudents = true;
  //   this.subscriptions.sink = this.courseService
  //     .getPaidStudents(this.courseid)
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == '300') {
  //           this.studentList = res.datalist;
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.message || res.status || 'Server Error',
  //             'warn'
  //           );
  //         }
  //         this.gettingStudents = false;
  //       },
  //       (err) => {
  //         this.gettingStudents = false;
  //       }
  //     );
  // }

  typeChage(event: any) {
    this.submitForm.type = event.target.value;
    if (event.target.value == '002') {
      // if (this.studentList.length == 0) {
      //   this.getStudents();
      // }
    } else {
      this.submitForm.student = '';
    }
  }

  cancel() {
    this.submitForm = {
      type : '001',
      student : '',
      title : '',
      message : ''
    }
  }

}
