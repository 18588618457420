import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-library-confirm-box',
  templateUrl: './library-confirm-box.component.html',
  styleUrls: ['./library-confirm-box.component.scss']
})
export class LibraryConfirmBoxComponent implements OnInit {

    message: string = "Your uploads are not complete. Would you like to cancel all ongoing uploads?";

    constructor(public dialogRef: MatDialogRef<LibraryConfirmBoxComponent>){}

    ngOnInit() {

    }


    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

}
