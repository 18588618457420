<app-breadcrumb [s_breadcrumb]="'Feedback Data'" [t_breadcrumb]="coursename" (emit)="goToFeedback()"></app-breadcrumb>


<div class="container-fluid mt-2 mb-2">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab>
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">people</mat-icon>
        Overall Feedback
      </ng-template>

      <div class="container-fluid my-3 card card-body px-5">

        <div *ngIf="isLoading" class="nodata">
          <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
        </div>
        <div *ngIf="!isLoading" class="question-container">
          <!-- No. of students - {{totalfeedback}} -->
          <div class="question-list mt-3" *ngFor="let ques of question;let i = index;">

            <ul class="questino-radio">

              <li class="question-li">
                <div class="d-flex w-100">
                  <div>
                    {{i+1}}.&nbsp;
                  </div>
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex justify-content-between w-100">
                      <div>
                        <span [innerHtml]="ques.question">
                        </span>
                        <span class="badge bg-main ms-3" *ngIf="ques.type=='003'">{{ques.count}}</span>
                        <span class="badge bg-main ms-3" *ngIf="ques.type=='004'">{{ques.answercount}}</span>
                      </div>
                    </div>
                    <div>

                      <div class="questino-radio card card-body p-3 mt-3" *ngIf="ques.type=='001' || ques.type == '002'"
                        style="width: 500px;">
                        <span *ngFor="let ans of ques.answerlist;let j = index;" class="mb-3">
                          <div>

                            <!-- <input *ngIf="ques.type=='001'" class="form-check-input" type="radio" [name]="'question'+i"
                              [id]="'question'+i+j" [checked]="j == ques.selected" disabled readonly>
                            
                              <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox" [name]="'question'+i"
                              [id]="'question'+i+j" [checked]="ques.checkboxans.includes(ans.answerid.toString())"
                              disabled readonly> -->

                            <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                            <div class="w-100 mb-2 d-flex justify-content-between align-items-end">
                              <span [innerHtml]="ans.answer"></span>
                              <!-- <span class="badge bg-main ms-3">{{ans.count}}</span> -->
                              {{ans.count}}
                            </div>

                            <div class="feedback-progress-bar">
                              <mat-progress-bar mode="determinate" [value]="(ans.count/ques.answercount)*100">
                              </mat-progress-bar>
                            </div>


                          </div>
                        </span>
                      </div>

                      <div *ngIf="ques.type == '003'" class="mt-3 viewans" (click)="viewAnswers(ques)">
                        View answers
                      </div>
                      <div class="d-flex flex-column" *ngIf="ques.type == '004'">
                        <div class="d-flex justify-content-between" style="width: 500px;">
                          <div style="max-width: 250px;">
                            <div class="rate">
                              <input type="radio" [id]="'question' + i + '1'" [name]="'question' + i" value="5" disabled
                                [checked]="ques.average == 5" />
                              <label [for]="'question' + i + '1'" title="text">
                                <mat-icon class="star-icon">star</mat-icon>
                              </label>
                              <input type="radio" [id]="'question' + i + '2'" [name]="'question' + i" value="4" disabled
                                [checked]="ques.average == 4" />
                              <label [for]="'question' + i + '2'" title="text">
                                <mat-icon class="star-icon">star</mat-icon>
                              </label>
                              <input type="radio" [id]="'question' + i + '3'" [name]="'question' + i" value="3" disabled
                                [checked]="ques.average == 3" />
                              <label [for]="'question' + i + '3'" title="text">
                                <mat-icon class="star-icon">star</mat-icon>
                              </label>
                              <input type="radio" [id]="'question' + i + '4'" [name]="'question' + i" value="2" disabled
                                [checked]="ques.average == 2" />
                              <label [for]="'question' + i + '4'" title="text">
                                <mat-icon class="star-icon">star</mat-icon>
                              </label>
                              <input type="radio" [id]="'question' + i + '5'" [name]="'question' + i" value="1" disabled
                                [checked]="ques.average == 1" />
                              <label [for]="'question' + i + '5'" title="text">
                                <mat-icon class="star-icon">star</mat-icon>
                              </label>
                            </div>

                          </div>
                          <div class="average-rating" style="max-width: 250px;">
                            <div>
                              {{ques.average}} out of 5
                            </div>
                          </div>



                        </div>
                        <!-- <small>{{ques.answercount}} global ratings</small> -->
                        <div class="questino-radio card card-body p-3 mt-3" style="width: 500px;">
                          <span *ngFor="let ans of ques.ratingcount;let j = index;" class="mb-3">
                            <div>

                              <!-- <input *ngIf="ques.type=='001'" class="form-check-input" type="radio" [name]="'question'+i"
                              [id]="'question'+i+j" [checked]="j == ques.selected" disabled readonly>
                            
                              <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox" [name]="'question'+i"
                              [id]="'question'+i+j" [checked]="ques.checkboxans.includes(ans.answerid.toString())"
                              disabled readonly> -->

                              <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                              <div class="w-100 mb-2 d-flex justify-content-between align-items-end">
                                <span [innerHtml]="ans.name"></span>
                                <!-- <span class="badge bg-main ms-3">{{ans.count}}</span> -->
                                {{changeRate(ans.count,ques.answercount)}}%
                              </div>

                              <div class="feedback-progress-bar">
                                <mat-progress-bar mode="determinate" [value]="(ans.count/ques.answercount)*100">
                                </mat-progress-bar>
                              </div>


                            </div>
                          </span>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </div>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">person</mat-icon>
        Students
      </ng-template>

      <div class="container-fluid my-3">

        <!-- List View -->
        <ng-container *ngIf="!isViewingFeedback">
          <div class="d-flex align-items-center flex-wrap mt-3 mb-3 justify-content-end">
            <div class="me-2">
              Total :<b> {{studentData.length}}</b>
            </div>
            <div class="input-group me-2" style="width: 260px !important">
              <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" />
              <button class="btn" type="button" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
                *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4; color: #00bcd4">
                <i class="fa fa-times"></i>
              </button>
            </div>
            <button class="btn new-class" matTooltip="Refresh" (click)="getFeedbackForm()" type="button">
              <i class="fa fa-refresh"></i>
            </button>
            <!-- <button  class="btn new-class mt-3 mb-3 ms-2" (click)="downloadSheet()" type="button">
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Export
            </button> -->
            <button type="button" class="btn new-class mt-3 mb-3 ms-2" (click)="downloadSheet()"
              matTooltip="Export Service Tickets To Excel" [disabled]="exportLoading">
              <img *ngIf="!exportLoading" class="excel-icon" src="../../../assets/images/excel.png">
              <span *ngIf="exportLoading" style="margin-right: 10px;"
                class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
              Export
            </button>
          </div>
          <div style="overflow: auto;">
            <table mat-table [dataSource]="studentTableData" matSort style="width: 100%;">

              <!-- User ID Column -->
              <ng-container matColumnDef="userid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
                <td mat-cell *matCellDef="let element" class="cus-point" (click)="viewDetail(element)">
                  {{element.userid}}
                </td>
              </ng-container>

              <!-- Username Column -->
              <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
                <td mat-cell *matCellDef="let element" class="cus-point" (click)="viewDetail(element)">
                  {{element.username}}
                </td>
              </ng-container>

              <!-- Student ID Column -->
              <ng-container matColumnDef="studentcode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Student ID</th>
                <td mat-cell *matCellDef="let element" class="cus-point" (click)="viewDetail(element)">
                  {{element.studentcode || '-'}}
                </td>
              </ng-container>

              <!-- Student Name Column -->
              <ng-container matColumnDef="studentname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Name</th>
                <td mat-cell *matCellDef="let element" class="cus-point" (click)="viewDetail(element)">
                  {{element.studentname || '-'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 115px;"> Action </th>
                <td mat-cell *matCellDef="let element" px-3 style="text-align: center;">
                  <button class="btn btn-outline-danger btn-custom-rounded" matTooltip="Delete Feedback"
                    (click)="deleteFeedback(element)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td colspan="5">
                  <span class="nodata" *ngIf="!isLoading">Empty</span>
                  <span class="nodata" *ngIf="isLoading">
                    <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                  </span>
                </td>
              </tr>
            </table>
            <mat-paginator #paginator *ngIf="studentData.length > 0" [pageSize]="100"
              [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </div>
        </ng-container>
        <ng-container *ngIf="isViewingFeedback">
          <div class="d-flex align-items-center">
            <div (click)="backToListView()" class="me-3 cus-point d-flex">
              <mat-icon class="me-2">arrow_back</mat-icon>
            </div>
            <div class="stu-title">
              {{currentStudentInfo.username || currentStudentInfo.userid}}'s Feedback
            </div>
          </div>
          <div *ngIf="isStudentLoading" class="card card-body my-3">
            <div class="nodata">
              <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
            </div>
          </div>
          <ng-container *ngIf="!isStudentLoading">
            <app-feedback-by-student [studentData]="currentStudentData"></app-feedback-by-student>
          </ng-container>


        </ng-container>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>