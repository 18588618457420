import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CourseService } from '../../shared/services/course.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatDialog } from '@angular/material/dialog';
import { SetupCmsComponent } from '../setup-cms/setup-cms.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ImagecropComponent } from '../imagecrop/imagecrop.component';

@Component({
  selector: 'app-create-channel',
  templateUrl: './create-channel.component.html',
  styleUrls: ['./create-channel.component.scss'],
})
export class CreateChannelComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private courseService: CourseService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog
  ) {
    if (this.allinoneService.getUserRole() != '100') {
      this.router.navigateByUrl('');
    }
  }

  name: string = '';
  logo: string = '';
  videoUrlPreview: string = '';
  status: string = '';
  channeltype: string = '001'; //001 - pubic , 002 - private
  banner: string = '001'; // 001 - No banner, 002 - image slide, 003 - video and some text
  orgid: string = '';
  chl_desc: string = '';
  edit_status: string = '';
  imageChangedEvent: any = 'image';
  sharecertificate: boolean = true; // show share button for certificate

  allowselfsignup: boolean = false;
  showstudiobutton: boolean = false;
  privatecourse : boolean = false;

  isCreating: boolean = false;
  gettingOrgs: boolean = false;
  publichide: boolean = false; // hide or show this channel in khub channel list; hide make this channel accessable only from domain url;
  formSubmitted: boolean = false;

  imageSliderList: any[] = [];
  imageTextList: any[] = [];
  videoSlide: any;

  ngOnInit(): void {
    this.getOrgs();
  }

  chl_status: any = [
    { status: 'enabled', value: 1 },
    { status: 'disabled', value: 0 },
  ];

  fileSelected: any = '';
  fileName: any = '';
  imgURL: any;

  orgs: any = [];

  homePageUiList: any = [
    {
      title: '',
      categories: [],
    },
  ];

  categoriesList = [
    {
      categoryname : 'a',
      categoryid : 'a',
    },
    {
      categoryname : 'b',
      categoryid : 'b',
    },
    {
      categoryname : 'c',
      categoryid : 'c',
    },
  ]

  categoriesList2 = [
    {
      "subcategoryname": "",
      "sortkey": 0,
      "channelid": "",
      "categoryid": "software-and-technology",
      "categoryname": "Software and Technology",
      "type": "1"
    },
    {
      "subcategoryname": "",
      "sortkey": 1,
      "channelid": "",
      "categoryid": "business-and-management",
      "categoryname": "Business and Management",
      "type": "1"
    },
    {
      "subcategoryname": "",
      "sortkey": 2,
      "channelid": "",
      "categoryid": "banking-and-finance",
      "categoryname": "Banking and Finance",
      "type": "1"
    },
  ];

  // uploadFile(event: any) {
  //   this.fileSelected = event[0];
  //   // console.log(this.fileSelected);

  //   var reader = new FileReader();
  //   // this.imagePath = fileSelected;
  //   reader.readAsDataURL(this.fileSelected);
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
  //   };

  //   this.fileName = this.allinoneService.getFileName(this.fileSelected);
  //   console.log(this.fileName);
  // }

  // uploadFile1(event: any) {
  //   this.fileSelected = event.target.files[0];
  //   // console.log(fileSelected);

  //   var reader = new FileReader();
  //   // this.imagePath = fileSelected;
  //   reader.readAsDataURL(this.fileSelected);
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
  //   };

  //   this.fileName = this.allinoneService.getFileName(this.fileSelected);
  //   console.log(this.fileName);
  // }

  newcategory(image: any, selectfile: any) {
    console.log('jaejae');
    console.log(selectfile);
    console.log(image);
    const dialog = this.dialog.open(ImagecropComponent, {
      maxWidth: '90vw',
      maxHeight: '80vh',
      width: '500px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        "image": image,
        "name": selectfile.name
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        console.log("ijwoefijwoejfioj")
        console.log(dialogResult);
        this.imgURL = dialogResult.image;
        this.fileSelected = dialogResult.file
        this.fileName = this.getFileName(this.fileSelected);
        console.log(this.imgURL);
        console.log(this.fileName);
      }
    });

  }

  removeSubmitConfig(index: number) {
    if (this.homePageUiList.length > 1) {
      this.homePageUiList.splice(index, 1);
    }
  }

  addNewStatus() {
    this.homePageUiList.push(
      {
        title: '',
        categories: [],
      }
    );
  }

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        // finalName = finalName + "_" + this.allinoneService.generateID() + "." + splitName[i]
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }


  uploadFile(event: any) {
    console.log("jaejaejokok");
    this.fileSelected = event[0];

    // console.log(this.fileSelected);

    // var reader = new FileReader();
    // // this.imagePath = fileSelected;
    // reader.readAsDataURL(this.fileSelected);
    // reader.onload = (_event) => {
    //   this.imgURL = reader.result;
    // };

    // this.fileName = this.getFileName(this.fileSelected);
    // console.log(this.fileName);
    this.newcategory(event, event[0]);

  }

  uploadFile1(event: any) {
    console.log("nyinyi");

    // this.fileSelected = event.target.files[0];
    // console.log(fileSelected);

    // var reader = new FileReader();
    // // this.imagePath = fileSelected;
    // reader.readAsDataURL(this.fileSelected);
    // reader.onload = (_event) => {
    //   this.imgURL = reader.result;
    // };


    // console.log(this.fileName);
    this.newcategory(event, event.target.files[0]);


  }

  removeImage() {
    this.fileName = '';
    this.fileSelected = '';
    this.imgURL = '';
    this.fileInput.nativeElement.value = '';
  }

  getOrgs() {
    this.gettingOrgs = true;
    this.courseService.getOrganizations().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.orgs = res.datalist;
          // this.orgid = this.orgs[0].orgid;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingOrgs = false;
      },
      (err) => {
        this.gettingOrgs = false;
      }
    );
  }

  async createChannel() {
    this.formSubmitted = true;
    if (this.name == '') {
      return;
    }
    if (!this.fileSelected) {
      return this.messageService.openSnackBar(
        'Please choose your channel logo.',
        'warn'
      );
    }
    // if (this.channeltype == '002' && this.orgid == '') {
    //   return this.messageService.openSnackBar(
    //     'Please select an organization.',
    //     'warn'
    //   );
    // }

    if (this.banner == '000' && this.imageTextList.length == 0) {
      return this.messageService.openSnackBar(
        'Please add a image for your banner.',
        'warn'
      );
    }
    if (this.banner == '002' && this.imageSliderList.length == 0) {
      return this.messageService.openSnackBar(
        'Please add a slide for your banner.',
        'warn'
      );
    }
    if (this.banner == '003' && !this.videoSlide) {
      return this.messageService.openSnackBar(
        'Please set up your banner video.',
        'warn'
      );
    }
    this.isCreating = true;
    var finalImageList: any[] = [];
    // this.imageSliderList.map(async (item: any) => {
    //   if (item.file.size >= 800000) {
    //     item.file = await this.allinoneService.compressImage(item.file);
    //     if (item.file == false) {
    //       this.messageService.openSnackBar(
    //         'Error while uploading your file.',
    //         'warn'
    //       );
    //       this.isCreating = false;
    //       return;
    //     }
    //   }
    //   finalImageList.push({
    //     text: item.text,
    //     imagename: item.filename,
    //   });
    // });
    if(this.banner == "000") {
      for (let i = 0; i < this.imageTextList.length; i++) {
        if (this.imageTextList[i].file.size >= 800000) {
          this.imageTextList[i].file = await this.allinoneService.compressImage(
            this.imageTextList[i].file,
            0.8
          );
          if (this.imageTextList[i].file == false) {
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            this.isCreating = false;
            return;
          }
        }
        finalImageList.push({
          text1: this.imageTextList[i].text1,
          text2: this.imageTextList[i].text2,
          imagename: this.imageTextList[i].filename,
        });
      }
    } else {
      for (let i = 0; i < this.imageSliderList.length; i++) {
        if (this.imageSliderList[i].file.size >= 800000) {
          this.imageSliderList[i].file = await this.allinoneService.compressImage(
            this.imageSliderList[i].file,
            0.8
          );
          if (this.imageSliderList[i].file == false) {
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            this.isCreating = false;
            return;
          }
        }
        finalImageList.push({
          text: this.imageSliderList[i].text,
          imagename: this.imageSliderList[i].filename,
        });
      }
    }

    var finalVideoSlide: any;
    if (this.videoSlide) {
      if (this.videoSlide.file.size >= 800000) {
        this.videoSlide.file = await this.allinoneService.compressImage(
          this.videoSlide.file,
          0.8
        );
      }
      finalVideoSlide = {
        text: this.videoSlide.text,
        videourl: this.videoSlide.videotype == '001' ? this.videoSlide.videourl : '',
        imagename: this.videoSlide.filename,
        libraryid: this.videoSlide.videotype == '001' ? "" : this.videoSlide.libraryid
      };
    }

    if (this.fileSelected && this.fileSelected.size >= 800000) {
      this.fileSelected = await this.allinoneService.compressImage(
        this.fileSelected,
        0.8
      );
    }

    const data = {
      name: this.name.trim(),
      description: this.chl_desc.trim(),
      logoname: this.fileSelected ? this.fileSelected.name : '',
      status: '1',
      type: this.channeltype,
      orgid: this.channeltype == '001' ? '' : this.orgid,
      // hidden: this.channeltype == '001' ? this.publichide : false,
      hidden: this.publichide,
      cmspage: this.banner,
      cmsimagedata: (this.banner == '000' || this.banner == "002") ? finalImageList : [],
      cmsvideodata: this.banner == '003' ? finalVideoSlide : '',
      sharecertificate: this.sharecertificate,
      allowsignup: this.allowselfsignup,
      studiolink: this.showstudiobutton,
      privatecourse : this.privatecourse,
      homepage : ''
    };
    // console.log(data);
    // return;
    this.courseService.createChannel(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (this.fileSelected) {
            const fileUpload =
              await this.allinoneService.fileUploadToS3WithPath(
                this.fileSelected,
                this.fileSelected.name,
                'channel/' + res.channelid
              );
          }
          if (this.banner == '000' && this.imageTextList.length > 0) {
            
            const fileupload = await this.allinoneService.multipleUploadToAWS(
              this.imageTextList,
              'channel/' + res.channelid
            );
            this.isCreating = false;
            this.router.navigateByUrl('/admin/channel');
          } else if (this.banner == '002' && this.imageSliderList.length > 0) {
            
            const fileupload = await this.allinoneService.multipleUploadToAWS(
              this.imageSliderList,
              'channel/' + res.channelid
            );
            this.isCreating = false;
            this.router.navigateByUrl('/admin/channel');
          } else if (this.banner == '003' && this.videoSlide) {

          
            const fileupload =
              await this.allinoneService.fileUploadToS3WithPathForImage(
                this.videoSlide.file,
                this.videoSlide.filename,
                'channel/' + res.channelid
              );
            this.isCreating = false;
            this.router.navigateByUrl('/admin/channel');
          } else {
            this.isCreating = false;
            this.router.navigateByUrl('/admin/channel');
          }
        } else {
          this.isCreating = false;
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.isCreating = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        }
      },
      (err) => {
        this.isCreating = false;
      }
    );
  }

  fileChangeEvent(file: any) {
    // this.coppedImage = false;
    if (file.target.files && file.target.files[0]) {
      //size filter
      const min_width = 750;
      const min_height = 422;

      try {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          console.log(e);

          image.onload = (rs: any) => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];
            console.log(img_height, img_width);
            if (img_height < min_height && img_width < min_width) {
              return this.messageService.openSnackBar(
                'Minimum dimentions allowed ' +
                min_width +
                '*' +
                min_height +
                'px',
                'warn'
              );
            } else {
              const imgBase64Path = e.target.result;
              this.imageChangedEvent = file;
              // this.courseimage = "";
            }
          };
        };
        reader.readAsDataURL(file.target.files[0]);
      } catch (e) {
        console.log(e);
      }
    }
  }

  goToChannels() {
    this.router.navigateByUrl('/admin/channel');
  }

  addImageToSlide(index?: number) {
    if (
      this.banner == '002' &&
      this.imageSliderList.length == 5 &&
      index == undefined
    ) {
      return this.messageService.openSnackBar(
        'Maximum number of slides is 5.',
        'warn'
      );
    }

    if (this.banner == '003' && this.videoSlide && index == undefined) {
      return;
    }

    const dialog = this.dialog.open(SetupCmsComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '650px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        bannertype: this.banner, // Change video or image from here
        isEditing: index != undefined ? true : false,
        editData:
          index != undefined
            ? this.banner == '000'
              ? this.imageTextList[index]
              : this.banner == '002' 
              ? this.imageSliderList[index]
              :  this.videoSlide
            : '',
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.banner == '000') {
          if (index != undefined) {
            this.imageTextList[index] = dialogResult;
            this.imageTextList[index].file = dialogResult.file;
            this.imageTextList[index].previewurl = dialogResult.previewurl;
            this.imageTextList[index].filename = dialogResult.filename;
            this.imageTextList[index].text1 = dialogResult.text1;
            this.imageTextList[index].text2 = dialogResult.text2;
          } else {
            this.imageTextList.push(dialogResult);
          }
        } else if (this.banner == '002') {
          if (index != undefined) {
            this.imageSliderList[index] = dialogResult;
            this.imageSliderList[index].file = dialogResult.file;
            this.imageSliderList[index].previewurl = dialogResult.previewurl;
            this.imageSliderList[index].filename = dialogResult.filename;
            this.imageSliderList[index].text = dialogResult.text;
          } else {
            this.imageSliderList.push(dialogResult);
          }
        } else {
          this.videoSlide = dialogResult;
          this.checkVideoUrl();
        }
      }
    });
  }

  checkVideoUrl() {
    //No video

    // var reg = new RegExp("^t\=+[0-9]+s$");

    if (this.videoSlide.videotype == '001') {
      if (this.videoSlide.videourl.toString().trim() == '') {
        this.videoUrlPreview = '';
      }
      //youtube
      else if (
        this.videoSlide.videourl.toString().startsWith('https://youtu.be/')
      ) {
        this.videoUrlPreview =
          'https://www.youtube.com/embed/' +
          this.videoSlide.videourl
            .split('/')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        if (
          this.videoUrlPreview.includes('start=') &&
          this.videoUrlPreview.endsWith('s')
        ) {
          this.videoUrlPreview = this.videoUrlPreview.substring(
            0,
            this.videoUrlPreview.length - 1
          );
        }
      } else if (
        this.videoSlide.videourl
          .toString()
          .startsWith('https://www.youtube.com')
      ) {
        this.videoUrlPreview =
          'https://www.youtube.com/embed/' +
          this.videoSlide.videourl
            .split('v=')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        if (
          this.videoUrlPreview.includes('start=') &&
          this.videoUrlPreview.endsWith('s')
        ) {
          this.videoUrlPreview = this.videoUrlPreview.substring(
            0,
            this.videoUrlPreview.length - 1
          );
        }
      } else {
        //vimeo
        // https://vimeo.com/568270540
        // https://player.vimeo.com/video/568270540
        this.videoUrlPreview =
          'https://player.vimeo.com/video/' +
          this.videoSlide.videourl.split('/').pop();
      }
    } else {
      if (this.videoSlide.preview.toString().trim() == '') {
        this.videoUrlPreview = '';
      } else {
        this.videoUrlPreview = this.videoSlide.preview;
      }
    }

    console.log(this.videoUrlPreview);
  }

  removeFromImageSlide(index: number) {
    if(this.banner === "000") {
      this.imageTextList.splice(index, 1);
    } else {
      this.imageSliderList.splice(index, 1);
    }
  }
}
