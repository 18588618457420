<div class="d-flex align-items-center justify-content-between mb-4">
    <div>
        <h2 class="mb-0">{{isEditing ? 'Edit Member Type' : 'New Member Type'}}</h2>
    </div>
    <div class="d-flex align-items-center">
        <mat-icon (click)="dismiss()" class="cus-point">close</mat-icon>
    </div>
</div>

<div class="row">
    <!-- <div class="mb-3 col-md-12">
      <label for="name" class="form-label">Name</label>
      <input type="text" class="form-control" id="name" name="name" [(ngModel)]="course.name"
        [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>
      <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
        <div *ngIf="name.errors?.required && formSubmitted">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div> -->
    <div class="mb-3 col-md-12">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control form-control-sm" aria-label="Number" matInput class="form-control"
            id="name" name="name" [(ngModel)]="membertype.name"
            [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>

        <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
            <div *ngIf="name.errors?.required && formSubmitted">
                {{allinoneService.input_err_msg}}
            </div>
        </div>

    </div>
    <div class="mb-3 col-md-12">
        <label for="name" class="form-label">Description</label>
        <textarea class="form-control form-control-sm" aria-label="Number"  class="form-control"
            id="desct" name="desct" [(ngModel)]="membertype.description"
           ></textarea>
<!-- 
        <div class="invalid-feedback" *ngIf="desc.invalid || (desc.dirty || desc.touched) && formSubmitted">
            <div *ngIf="desc.errors?.required && formSubmitted">
                {{allinoneService.input_err_msg}}
            </div>
        </div> -->

    </div>




</div>

<div class="actions-button">

    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled]="isLoading">
        Cancel
    </button>

    <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="submit()">
    </app-button-loader>

</div>