<div class="d-flex justify-content-between align-items-center mb-2">
    <h2 class="mb-0"> Individual Due Date</h2>
    <div mat-dialog-close>
        <mat-icon>close</mat-icon>
    </div>
</div>
<ng-container *ngIf="addingDueDate; else listViewTemplate">
    <form [formGroup]="deadlineForm" (ngSubmit)="submitForm()">
        <div class="form-group" class="mb-3">
            <label for="type" class="form-label">
                Type
            </label>
            <select class="form-select" name="type" required formControlName="type" (change)="typeChage($event)">
                <option [value]="type.id" *ngFor="let type of typeList;">
                    {{type.name}}
                </option>
            </select>
        </div>

        <div class="form-group" class="mb-3" *ngIf="formControl.type.value == '002'">
            <label for="student" class="form-label" >
                Student
            </label>
            <select class="form-select" name="student" required formControlName="studentid">
                <option value="">-</option>
                <option [value]="student.studentid" *ngFor="let student of studentList;">
                    {{student.username ? student.username + ' - ' + student.userid : student.userid}}
                </option>
            </select>

            <mat-progress-bar mode="indeterminate" *ngIf="gettingStudents"></mat-progress-bar>

            <div *ngIf="formSubmitted && formControl.studentid.errors" class="invalid-feedback">
                <div *ngIf="formControl.studentid.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>

        </div>

        <div class="form-group" class="mb-3" *ngIf="formControl.type.value == '001'">
            <label for="student" class="form-label">
                Class
            </label>
            <select class="form-select" name="class" required formControlName="classid">
                <option value="">-</option>
                <option [value]="class.classid" *ngFor="let class of classList;">
                    {{class.classname}}
                </option>
            </select>

            <mat-progress-bar mode="indeterminate" *ngIf="gettingClass"></mat-progress-bar>

            <div *ngIf="formSubmitted && formControl.classid.errors" class="invalid-feedback">
                <div *ngIf="formControl.classid.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>

        </div>

        <div class="form-group mb-3">
            <label class="form-label">Due Date</label>
            <div class="row">
                <div class="col-6">
                    <input type="date" class="form-control" id="deadlinedate" name="deadlinedate"
                        required formControlName="deadlinedate">
                </div>
                <div class="col-6">
                    <input type="time" class="form-control" id="deadlinetime" name="deadlinetime"
                        required formControlName="deadlinetime">
                </div>
            </div>
        </div>
        <div class="actions-button">

            <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()" [disabled]="saveLoading">
              Cancel
            </button>
      
            <app-button-loader [button_text]="'Save'" [isStillupload]="saveLoading">
            </app-button-loader>
      
          </div>
    </form>
</ng-container>
<ng-template #listViewTemplate>
    <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
            <button color="primary" class=" btn new-class" (click)="newIndividualDueDate()">
                <i class="fa fa-plus" aria-hidden="true"></i>
                New
            </button>
        </div>
        <div class="d-flex align-items-center">
            <div class="me-2">
                Total: {{duedateList.length}}
            </div>
            <div class="input-group" style="width: 260px !important;">
                <input class="form-control" type="text" placeholder="Search" (focus)="isFocus = true"
                    (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                    *ngIf="searchText" (click)="clear()"
                    style="border: 1px solid var(--main-color);color: var(--main-color);">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
    </div>
    <table class="table align-middle mb-0 bg-white">
        <thead class="bg-light">
            <tr>
                <th class="col-4">Name</th>
                <th class="col-4">Type</th>
                <th class="col-4">Due Date</th>
                <th style="text-align: center; min-width: 110px;">Action</th>
            </tr>
        </thead>
        <tbody *ngIf="!loadingData">
            <tr *ngFor="let item of duedateList| filter:searchText:'deadline' ; let index = index" class="item-tr">
                <td>
                    {{item.type == '001' ? item.classname : item.username ? item.username : item.userid}}
                </td>
                <td>
                    {{item.type == '001' ? 'Class' : 'Student'}}
                </td>
                <td>
                    {{allinoneService.formatDBToShow(item.deadlinedate)}} {{item.deadlinetime}}
                </td>
                <td>
                    <button class="btn btn-outline-primary btn-custom-rounded rounded-circle me-2" matTooltip="Edit" style="font-size: 14px;width:42px;height: 40px;" (click)="editDuedate(item)">
                        <i class="fa fa-edit"></i>
                    </button>
        
                    <button class="btn btn-outline-danger btn-custom-rounded rounded-circle" matTooltip="Delete" style="font-size: 14px;width:42px;height: 40px;" (click)="deleteDuedate(item)">
                        <i class="fa fa-trash"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="(duedateList| filter:searchText:'deadline').length == 0">
                <td colspan="4">
                    <span class="nodata">
                        Empty
                    </span>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="loadingData">
            <tr>
                <td colspan="4">
                    <span class="nodata">
                        <div class="spinner-border spinner-border-md" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>