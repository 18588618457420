import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormGroupName,
  FormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { concatAll } from 'rxjs/operators';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-course-name',
  templateUrl: './course-name.component.html',
  styleUrls: ['./course-name.component.scss'],
})
export class CourseNameComponent implements OnInit {
  courseForm = {
    coursename: '',
    category: '',
    channelname: '',
    channelid: 'choose',
    type: '003',
  };

  channelname: string = '';
  // channelForm = {
  //   "channelname": "",
  //   "channelid": ""
  // }
  // channelId: = "";
  channelidFromParam: string = '';
  channelidCheck: boolean = false;
  isStillupload!: boolean;
  submitted: boolean = false;
  page: string = '';
  gettingCategory: boolean = false;
  gettingmyChannel: boolean = false;
  isStillCourseName: boolean = false;
  categories: any = [];
  // channel: any = [];
  channel: any = [];

  constructor(
    private router: Router,
    private courseService: CourseService,
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private cookieService: CookieService,
    private _seoService: SEOService
  ) {
    //this.router.navigateByUrl('instructor/courses/course-name');
    this.getCategory();
    this.getMyChannel();
  }
  ngOnInit(): void {
    this._seoService.updateTitle('- New Course');
    const temp_cid = this.route.snapshot.queryParamMap.get('channelid');
    if (temp_cid) {
      this.channelidFromParam = temp_cid;
    }

    // this.route.params.subscribe((params) => {
    //   this.page = params['page'];
    // });
    this.getMyChannel();
  }

  getCategory() {
    this.gettingCategory = true;
    const data = {
      type: '1',
      channelid: this.courseForm.channelid,
    };
    this.courseService.getCategoryList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categories = res.data;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.gettingCategory = false;
      },
      (err) => {
        this.gettingCategory = false;
      }
    );
  }

  getMyChannel() {
    this.gettingmyChannel = true;
    const searchuserid = this.allinoneService.getKunyekUserId();
    const data = {
      type: '2',
    };
    this.courseService.getMychannel(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.channel = res.datalist;
          console.log(this.channel);

          var count = this.channel.length;
          if (this.channelidFromParam != '') {
            for (var i = 0; i < count; i++) {
              if (this.channel[i].channelid === this.channelidFromParam) {
                this.courseForm.channelid = this.channelidFromParam;
              }
            }
          }
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.gettingmyChannel = false;
      },
      (err) => {
        this.gettingmyChannel = false;
      }
    );
  }

  onSubmitName() {
    this.submitted = true;
    if (this.courseForm.coursename == '') {
      return;
    } else {
      this.router.navigateByUrl('instructor/courses/course-category');
      this.submitted = false;
      this.isStillCourseName = false;
    }
  }
  onSubmit() {
    this.submitted = true;
    if (
      this.courseForm.coursename == '' ||
      this.courseForm.category == '' ||
      this.courseForm.channelid == 'choose'
    ) {
      return;
    } else {
      console.log(this.courseForm);
      this.isStillupload = true;
      const userid = this.allinoneService.getUserId();
      const data = {
        coursename: this.courseForm.coursename,
        categoryid: this.courseForm.category,
        instructorid: userid,
        channelid: this.courseForm.channelid,
        access: '001',
        coursetype: this.courseForm.type,
      };
      console.log(data);

      this.courseService.createCourse(data).subscribe(
        (course: any) => {
          const { returncode, status, data } = course;

          if (returncode == '300') {
            this.router.navigateByUrl(
              `/instructor/courses/${data.courseid}/info`
            );
            this.messageService.openSnackBar('Created Successfully', 'success');
          } else if (returncode == '409') {
            this.messageService.openSnackBar('Duplicate Course Name', 'warn');
          } else {
            if (course.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(status, 'warn');
            }
          }

          this.isStillupload = false;
          this.submitted = false;
        },
        (err) => {
          this.isStillupload = false;
          this.submitted = false;
        }
      );
    }
  }

  channelChange(){
    this.getCategory();
  }
}
