import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { CourseService } from '../../shared/services/course.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../../shared/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-course-content-duplicate-editor',
  templateUrl: './course-content-duplicate-editor.component.html',
  styleUrls: ['./course-content-duplicate-editor.component.scss']
})
export class CourseContentDuplicateEditorComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.canLeave
  }

  isLoading: boolean = false;
  formSubmitted: boolean = false;
  canLeave : boolean = false;
  gettingmyChannel: boolean = false;

  dup_content : string = 'Content';

  // Web Socket
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  submitForm = {
    duplicatename: '',
    duplicatefrom: '',
    duplicateid : '',
    duplicatetype : '',
    courseid : ''
  }

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private router: Router,
    private http: HttpClient,
    public dialogRef: MatDialogRef<CourseContentDuplicateEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data);
    if (data) {
      this.submitForm.duplicatefrom = data.coursename;
      this.submitForm.duplicateid = data.duplicateid;
      this.submitForm.duplicatetype = data.duplicatetype;
      this.submitForm.courseid = data.courseid;
    }
    if(this.submitForm.duplicatetype == '001') {
      this.dup_content = 'Section';
    } else if(this.submitForm.duplicatetype == '002') {
      this.dup_content = 'Sub Section';
    } else if(this.submitForm.duplicatetype == '003') {
      this.dup_content = 'Unit';
    }
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.socket?.close(3001);
    clearTimeout(this.socketConnectTime);
  }

  submit() {
    this.formSubmitted = true;
    if (this.submitForm.duplicatename == '' || this.submitForm.duplicatename == null) {
      return;
    }
    this.canLeave = false;
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    this.connectSocketLoading = true;
    // const webSocketURL = this.allinoneService.attendanceSocketUrl;
    const webSocketURL = this.allinoneService.socketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {

      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {

      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      console.log('Changed data');
      console.log(tempData);
      if (this.connectionId == '') {
        this.connectionId = tempData.connectionId;
      }

      if (this.updateCId) {
        this.updateConnectionID();
      }

      if (tempData.message == 'Forbidden') {
        // if (this.displayMode == 'Daterange') {
        //   this.searchWithDateRangeWithSocket(false)
        // } else if (this.displayMode == 'Absentreport') {
        //   this.searchWithDateRangeWithSocket(true)
        // }
        this.getCourseDuplicateData();
      }

      if (tempData.returncode == '300') {
        this.messageService.openSnackBar('Course duplicated successfully.', 'success');
        this.isLoading = false;
        this.canLeave = true;
        this.dialogRef.disableClose = false;
        this.connectionId = '';
        this.socket?.close(3001);
        clearTimeout(this.socketConnectTime);
        this.dialogRef.close({ duplicateCon: true })
      }

      else if (tempData.returncode == '200') {
        this.messageService.openSnackBar('Something went wrong.', 'warn');
        this.isLoading = false;
        this.canLeave = true;
        this.dialogRef.disableClose = false;
        this.connectionId = '';
        this.socket?.close(3001);
        clearTimeout(this.socketConnectTime);

      }

      // this.drsocketfileurl = tempData.fileurl;

      // if (this.drsocketfileurl != '') {
      //   this.http.get(this.drsocketfileurl)
      //     .subscribe(
      //       (res: any) => {
      //         console.log("Your get data res");
      //         console.log(res);
      // this.isLoading = false;
      // this.loadingService.hide();
      // this.connectionId = '';
      // this.socket?.close(3001);
      // clearTimeout(this.socketConnectTime);
      //       }
      //     )
      // }
      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {

      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });


    this.socket.addEventListener('close', (event) => {

      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });

  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.isLoading = false;
        this.canLeave = true;
        this.dialogRef.disableClose = false;
      }
    }, 180 * 1000);
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '002'
    }
    this.courseService.updateConnectionID(data).subscribe(
      (res: any) => {
        console.log('id updated res');
        this.updateCId = false;
        console.log(res);
      }
    )
  }

  getCourseDuplicateData() {
    var data = {
      duplicatename: this.submitForm.duplicatename,
      courseid: this.submitForm.courseid,
      connectionid: this.connectionId,
      duplicateid : this.submitForm.duplicateid,
      duplicatetype : this.submitForm.duplicatetype,
    }
    this.courseService.duplicatCourseContent(data).subscribe((res: any) => {
      if(res.returncode != '300'){
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
    }, err => {
      this.isLoading = false;
      this.canLeave = true;
      this.dialogRef.disableClose = false;
      this.connectionId = '';
      this.socket?.close(3001);
      clearTimeout(this.socketConnectTime);
    })
  }

  dismiss() {
    // confirm('Changes you made may not be saved.')
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }

}
