<app-breadcrumb *ngIf="!isLoading && !studentPageView" [t_breadcrumb]="classcode + ' - ' +classname"
  [s_breadcrumb]="coursename" (emit)="goToCourse()"
  style="position: sticky !important;position: -webkit-sticky !important; top: 0 !important;">
</app-breadcrumb>

<app-breadcrumb *ngIf="!isLoading && studentPageView" [t_breadcrumb]="'Sections'" [s_breadcrumb]="'Students'"
  (emit)="goToStudent()" style="position: sticky !important;position: -webkit-sticky !important; top: 0 !important;">
</app-breadcrumb>
<!-- (emit)="goToChannels()" (emit2)="gotoChannelName()" -->

<div class="container-fluid mt-2 mb-2">
  <mat-tab-group mat-align-tabs="start" [ngClass]="{'invisible-tabs' : studentPageView}">
    <mat-tab *ngIf="!studentPageView">
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">people</mat-icon>
        Students
      </ng-template>

      <div class="container-fluid mt-2 mb-2 student-container">
        <div class="co-title d-flex align-items-center">
          <!-- <i class="fas fa-angle-left" (click)="goBackToClass()" style="cursor: pointer;"></i>  -->
          <!-- <mat-icon (click)="goBackToClass()" style="cursor: pointer; margin-right: 10px;">arrow_back</mat-icon> -->
          <!-- {{classnames}} ({{classcode}}) -->
        </div>
        <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
          <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-2">
            <div>
              <button color="primary" class="me-1 ms-0" (click)="NewStudents()" mat-flat-button>
                <i class="fa fa-plus" aria-hidden="true"></i>
                New Student
              </button>
              <button color="primary" class="me-1" (click)="InputStudent(1)" mat-flat-button>
                <img class="excel-icon" src="../../../assets/images/excel.png">
                Import
              </button>
              <button color="primary" class="me-auto " (click)="downloadSheet()" mat-flat-button>
                <img class="excel-icon" src="../../../assets/images/excel.png">
                Export
              </button>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="me-2">
                Total :<b> {{allStudent.length}}</b>
              </div>
              <div class="input-group" style="width: 260px !important;">
                <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                  (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                  *ngIf="searchText" (click)="clear()">
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button"
                style="margin-left: 10px;">
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- <div style="overflow: auto;"> -->
        <div class="mat-table-container">
          <table mat-table #table [dataSource]="showstudents" multiTemplateDataRows matSort
            (matSortChange)="sortData($event)" class="mat-elevation-z1" style="width: 100%;">
            <ng-container matColumnDef="userid">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by classname"
                style="padding-left:0px;">User ID</th>
                <!-- style="position: relative; overflow: hidden; border: none;" -->
              <td class="t1" mat-cell *matCellDef="let element"
                 (click)="toggleRow(element)" style="overflow: hidden; border: none;">
                <i class="fa fa-play mx-2" *ngIf="element.expanded" style="transform: rotate(90deg);"></i>
                <i class="fa fa-play mx-2" *ngIf="!element.expanded"></i>
                {{element.userid}}
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by classname">User Name
              </th>
              <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                {{element.username}}
              </td>
            </ng-container>
            <!-- Student ID -->
            <ng-container matColumnDef="studentcode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by student id"> Student
                ID </th>
              <td mat-cell *matCellDef="let element" px-3 style="border: none;" (click)="toggleRow(element)">
                {{element.studentcode}} </td>
            </ng-container>
            <!-- Student Name -->
            <ng-container matColumnDef="studentname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by student name">
                Student Name </th>
              <td mat-cell *matCellDef="let element" px-3 style="border: none;" (click)="toggleRow(element)">
                {{element.studentname}} </td>
            </ng-container>

            <!-- Remark Column -->
            <ng-container matColumnDef="remark">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by remark"> Remark </th>
              <td mat-cell *matCellDef="let element" px-3 style="border: none;" (click)="toggleRow(element)">
                {{element.remark}} </td>
            </ng-container>
            <!-- Action Column -->
            <ng-container matColumnDef="action" style="width: 100px;">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
              <td mat-cell *matCellDef="let element;let i = index;" style="text-align: center; border: none;">
                <!-- <button class="btn btn-outline-primary rounded-circle" matTooltip="Edit" >
                              <i class="fa fa-edit"></i>
                          </button> -->
                <button class="btn btn-outline-primary rounded-circle" matTooltip="Edit" (click)="NewStudents(element)"
                  style="font-size: 14px;width:37px;height: 35px;">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger rounded-circle" matTooltip="Delete"
                  (click)="deleteStudent(element,i)" style="font-size: 14px;width:37px;height: 35px;margin-left: 5px;">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="dropbox" *ngIf="element.expanded">
                  <div *ngIf="!element.gettingdropbox && element.submissions.length > 0">
                    <div class="db-container" *ngFor="let file of element.submissions">
                      <div class="connect">
                      </div>
                      <div class="filedetail">
                        <i class="fas fa-circle me-2" style="font-size: 8px;"></i><span class="db-title">{{
                          allinoneService.formatDBToShow(file.date)
                          }}&nbsp;{{file.time}}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{file.title}}&nbsp;&nbsp;&nbsp;<span
                          *ngIf="file.remark">|&nbsp;&nbsp;&nbsp;{{file.remark}}</span>
                        &nbsp;&nbsp;&nbsp;<span><a [href]="file.fileurl" target="_blank">Download</a></span>
                      </div>
                    </div>
                  </div>
                  <div class="nodata" *ngIf="element.gettingdropbox">
                    <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
                  </div>
                  <div class="nodata justify-content-start"
                    *ngIf="!element.gettingdropbox && element.submissions.length == 0">
                    Empty
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
              [ngClass]="{'opened-row' : element.expanded}">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td colspan="6">
                <span class="nodata" *ngIf="!isLoading">Empty</span>
                <span class="nodata" *ngIf="isLoading">
                  <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                </span>
              </td>
            </tr>
          </table>
          <!-- <div  *ngIf="paginate == true"> -->
          <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          <!-- </div> -->
        </div>
      </div>

    </mat-tab>
    <mat-tab *ngIf="!studentPageView">
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">move_to_inbox</mat-icon>
        Dropbox
      </ng-template>

      <div class="container-fluid mt-2 mb-2">

        <div class="co-title d-flex align-items-center">
        </div>
        <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
          <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-2">
            <div class="me-auto d-flex align-items-center">
              <!-- <h2 class="mb-0"><strong>Dropbox Submissions</strong></h2> -->
              <div class="">
                <button type="button" class="btn btn-custom" title="Import from Excel" (click)="importSheet()"
                  *ngIf="importStatus == '001' || importStatus == '005'">
                  <img class="excel-icon" src="../../../assets/images/excel.png">
                  Import
                </button>
                <div class="d-flex align-items-center" *ngIf="importStatus == '002' || importStatus == '003' ">
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-outline-black" disabled>
                      <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002' "></i>
                      <img src="../../../assets/images/upload.gif" width="20px" class="me-2"
                        *ngIf="importStatus == '003' " alt="">
                      {{ importStatus == '002' ? 'Checking' : 'Uploading' }}
                    </button>
                    <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
                      <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
                    </button>
                  </div>
                </div>
                <button type="button" class="btn btn-outline-danger" (click)="importSheet(true)"
                  *ngIf="importStatus == '004'" style="width : auto;">
                  <i class="fas fa-exclamation-circle me-2"></i>
                  Import Error
                </button>
              </div>
              <button color="primary" class="me-auto "
                [disabled]="selectedUnit == '' || this.datacount == '0' || downloadDropBoxUnit "
                (click)="downloadAssignment()" mat-flat-button>
                <div class="d-flex align-items-center justify-content-center ">
                  <i *ngIf="!downloadDropBoxUnit" class="fa fa-download me-2"></i>
                  <mat-spinner *ngIf="downloadDropBoxUnit" class="me-2" [strokeWidth]="2" [diameter]="20"></mat-spinner>
                  <p class="m-0 p-0">Download All</p>
                </div>
              </button>
            </div>


            <div class="d-flex flex-wrap align-items-center">

              <!-- <div class="me-2">
                Total :<b> {{alldropboxdata.length}}</b>
              </div> -->
              <div class="me-2">
                Total :<b> {{dropboxtotalcount}}</b>
              </div>
              <div class="input-group me-2" style="width: 260px !important;">
                <input class="form-control" type="text" (keyup)="searchItemDropBox($event)" placeholder="Search"
                  (focus)="isDropboxFocus = true" (blur)="isDropboxFocus = false" aria-label="Search"
                  [(ngModel)]="searchDropboxText">
                <button class="btn" type="button"
                  [ngClass]="{'c-clear' : isDropboxFocus, 'c-clear-not-focus': !isDropboxFocus}"
                  *ngIf="searchDropboxText" (click)="clearDropBox()">
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <!-- <div class="form-group " style="width: 260px !important;">
                <select class="form-select" [(ngModel)]="selectedDropbox" (change)="getDropbox()"
                  matTooltip="Filter by section" [disabled]="gettingDropbox">
                  <option value="all">All</option>
                  <option value="general">General</option>
                  <option [value]="sec.sectionid" *ngFor="let sec of allsection">
                    {{ sec.sectionname }}
                  </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingDropbox"></mat-progress-bar>
              </div>
              <div class="form-group ms-2" style="width: 260px !important;" *ngIf="selectedDropbox!= 'all' && selectedDropbox != 'general'">
                <select class="form-select" [(ngModel)]="selectedUnit" (change)="getDropbox()"
                  matTooltip="Filter by units" [disabled]="gettingDropbox">
                  <option value="">All</option>
                  <option [value]="unit.unitid" *ngFor="let unit of units">
                    {{ unit.unitname }}
                  </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingDropbox"></mat-progress-bar>
              </div> -->

              <div class="form-group ms-2" style="width: 260px !important;">
                <select class="form-select" [(ngModel)]="selectedUnit" (change)="refreshDropBox2()"
                  matTooltip="Filter by units" [disabled]="gettingDropbox">
                  <option value="">All</option>
                  <option [value]="unit.unitid" *ngFor="let unit of allunit">
                    {{ unit.unitname }}
                  </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingDropbox"></mat-progress-bar>
              </div>

              <button class="btn new-class" matTooltip="Refresh" (click)="refreshDropBox()" type="button"
                style="margin-left: 10px;">
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>

        </div>


        <div class="mat-table-container">
          <table mat-table #table [dataSource]="showdropboxdata" matSort (matSortChange)="sortDataDropBox($event)"
            matSort class="mat-elevation-z1" style="width: 100%;">
            <ng-container matColumnDef="userid">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by studentid"
                style="padding-left:0px;">User ID</th>
              <td mat-cell *matCellDef="let element">
                {{element.userid}}
              </td>
            </ng-container>
            <ng-container matColumnDef="studentid">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by studentid"
                style="padding-left:0px;">Student ID</th>
              <td mat-cell *matCellDef="let element">
                {{element.studentcode}}
              </td>
            </ng-container>
            <ng-container matColumnDef="studentname">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by studentname"
                style="padding-left:0px;">Student Name</th>
              <td mat-cell *matCellDef="let element">
                {{element.studentname ? element.studentname : element.username}}
              </td>
            </ng-container>

            <!-- Unit Name -->
            <ng-container matColumnDef="unitname">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by unitname"
                style="padding-left:0px;">Unit Name</th>
              <td mat-cell *matCellDef="let element">
                {{element.unitname}}
              </td>
            </ng-container>

            <ng-container matColumnDef="title">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title"
                style="padding-left:0px;">Title</th>
              <td mat-cell *matCellDef="let element" class="custom-td" [matTooltip]="element.title">
                {{element.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="file">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by file"
                style="padding-left:0px;">File</th>
              <td mat-cell *matCellDef="let element" class="custom-td" [matTooltip]="element.filename">
                {{element.filename}}
              </td>
            </ng-container>

            <ng-container matColumnDef="grade">

              <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left:0px; width: 150px;">Grade</th>
              <td mat-cell *matCellDef="let element">

                <span *ngIf="element.unitid">{{element.grade ? element.grade + '/' +element.totalgrade : '-'}}</span>
                <span *ngIf="!element.unitid">{{element.grade || '-'}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="feedback">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by remark"> Feedback
              </th>
              <td mat-cell *matCellDef="let element" px-3 [matTooltip]="element.remark" class="custom-td">
                {{element.feedback}} </td>
            </ng-container>
            <ng-container matColumnDef="datetime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date"
                style="width: 150px;">Submitted Date
              </th>
              <td mat-cell *matCellDef="let element"> {{ allinoneService.formatDBToShow(element.date)
                }}&nbsp;{{element.time}}
              </td>
            </ng-container>

            <ng-container matColumnDef="action" style="width: 100px;">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
              <td mat-cell *matCellDef="let element;let i = index;" style="text-align: center;">

                <button class="btn btn-outline-success rounded-circle" matTooltip="Download"
                  (click)="downloadFile(element.fileurl, element.filename)"
                  style="margin-left: 5px; height: 35px !important; width: 37px !important;">
                  <i class="fa fa-download"></i>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="dropboxDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dropboxDisplayedColumns;" (click)="gradeAssignment(row)"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td colspan="9">
                <span class="nodata" *ngIf="!isDropboxLoading">Empty</span>
                <span class="nodata" *ngIf="isDropboxLoading">
                  <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                </span>
              </td>
            </tr>
          </table>

          <mat-paginator #dbpaginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

      </div>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="lable-tab" *ngIf="!studentPageView">
        <!-- <mat-icon class="example-tab-icon mx-1">move_to_inbox</mat-icon> -->
        <!-- <img src = "../../../assets/images/module.svg" width="20px" class = "mx-1"> -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" class="mx-1">
          <path
            d="M 25 2 C 24.83275 2 24.651 2.041 24.5 2.125 L 17.03125 6.28125 L 25 10.625 L 33 6.3125 L 25.5 2.125 C 25.349 2.041 25.16725 2 25 2 z M 16 8 L 16 10.84375 L 6.5 16.125 A 1.0001 1.0001 0 0 0 6 17 L 6 25.625 L 2.96875 27.3125 L 11 31.625 L 18.9375 27.28125 L 11.5 23.125 C 11.197 22.957 10.803 22.957 10.5 23.125 L 8 24.5 L 8 17.59375 L 16 13.15625 L 16 16.34375 C 16 16.70675 16.183 17.04275 16.5 17.21875 L 24 21.375 L 24 12.375 L 16 8 z M 34 8.0625 L 26 12.375 L 26 21.375 L 33.5 17.21875 C 33.818 17.04275 34 16.70675 34 16.34375 L 34 13.15625 L 42 17.59375 L 42 24.53125 L 39.5 23.125 C 39.199 22.957 38.801 22.957 38.5 23.125 L 31.03125 27.28125 L 39 31.625 L 46.9375 27.28125 L 44 25.625 L 44 17 C 44 16.637 43.818 16.301 43.5 16.125 L 34 10.84375 L 34 8.0625 z M 20 28.96875 L 12 33.375 L 12 42.375 L 13.3125 41.65625 L 24.5 47.875 A 1.0001 1.0001 0 0 0 25.5 47.875 L 36.6875 41.65625 L 38 42.375 L 38 33.375 L 30 29 L 30 37.34375 C 30 37.70675 30.182 38.04175 30.5 38.21875 L 34.625 40.5 L 25 45.84375 L 15.375 40.5 L 19.5 38.21875 C 19.817 38.04275 20 37.70675 20 37.34375 L 20 28.96875 z M 48 28.96875 L 40 33.375 L 40 42.375 L 47.5 38.21875 C 47.818 38.04175 48 37.70675 48 37.34375 L 48 28.96875 z M 2 29.0625 L 2 37.34375 C 2 37.70675 2.183 38.04175 2.5 38.21875 L 10 42.375 L 10 33.375 L 2 29.0625 z" />
        </svg>
        Sections
      </ng-template>
      <div class="container-fluid mt-2 mb-2">
        <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
          <div *ngIf="!isSchedule" class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-2">
            <div class="me-auto">
              <h2 class="mb-0"><strong>Sections</strong></h2>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="me-2">
                Total :<b> {{allsection.length}}</b>
              </div>

              <div class="input-group me-2" style="width: 260px !important;">
                <input class="form-control" type="text" (keyup)="searchSection($event)" placeholder="Search"
                  (focus)="isSectionFocus = true" (blur)="isSectionFocus = false" aria-label="Search"
                  [(ngModel)]="searchSectionText">
                <button class="btn" type="button"
                  [ngClass]="{'c-clear' : isSectionFocus, 'c-clear-not-focus': !isSectionFocus}"
                  *ngIf="searchSectionText" (click)="clearSectionSearch()">
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <button *ngIf="!studentPageView" color="primary" class="me-2 ms-0" (click)="openScheduleForAll()"
                mat-flat-button>
                Schedule
              </button>
              <button class="btn new-class" matTooltip="Refresh"
                (click)="studentPageView ? getStudentSection() : getSection()" type="button">
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>

          <div *ngIf="isSchedule" class="mt-2 w-100">
            <div class="me-auto">
              <!-- <mat-icon>schedule</mat-icon> -->
              <h2 class="mb-0"><strong>Sections</strong></h2>
            </div>
            <div class="d-flex w-100 justify-content-between align-items-center">
              <div class="d-flex justify-content-center align-items-center">
                <p class="m-0" style="font-weight: 500;">Schedule - </p>
                <div class="card p-2 ms-2" style="color : var(--main-color);cursor: pointer;"
                  (click)="openScheduleForAll()">
                  <div *ngIf="isStart && isEnd">
                    {{ allinoneService.formatDBToShow(scheduleSD) + " " + scheduleST }} <span style="font-weight:700;"
                      class="mx-2"> - </span> {{ allinoneService.formatDBToShow(scheduleED) + " " + scheduleET }}
                  </div>
                  <div *ngIf="isStart && !isEnd">
                    {{ allinoneService.formatDBToShow(scheduleSD) + " " + scheduleST }} <span style="font-weight:700;"
                      class="mx-2"> - </span> --/--/-- --:-- --
                  </div>
                  <div *ngIf="!isStart && isEnd">
                    --/--/-- --:-- -- <span style="font-weight:700;" class="mx-2"> - </span> {{
                    allinoneService.formatDBToShow(scheduleED) + " " + scheduleET }}
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center">
                <div class="me-2">
                  Total :<b> {{allsection.length}}</b>
                </div>

                <div class="input-group me-2" style="width: 260px !important;">
                  <input class="form-control" type="text" (keyup)="searchSection($event)" placeholder="Search"
                    (focus)="isSectionFocus = true" (blur)="isSectionFocus = false" aria-label="Search"
                    [(ngModel)]="searchSectionText">
                  <button class="btn" type="button"
                    [ngClass]="{'c-clear' : isSectionFocus, 'c-clear-not-focus': !isSectionFocus}"
                    *ngIf="searchSectionText" (click)="clearSectionSearch()">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <button color="primary" class="me-2 ms-0 d-flex justify-content-center align-items-center"
                  style="height: 33px !important;" (click)="openScheduleForAll()" mat-flat-button>
                  Schedule
                </button>
                <button class="btn new-class" matTooltip="Refresh" (click)="getSection()" type="button">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class="mat-table-container">
          <!-- matSort (matSortChange)="sortSections($event)" -->
          <table mat-table #table [dataSource]="showSections" class="mat-elevation-z1" style="width: 100%;">

            <ng-container matColumnDef="no">

              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by sectionname"
                style="padding-left:0px;">No.</th>
              <td mat-cell *matCellDef="let element" class="custom-td">

              </td>
            </ng-container>

            <ng-container matColumnDef="sectionname">

              <!-- mat-sort-header sortActionDescription="Sort by sectionname" -->
              <!-- style="padding-left:0px;" -->
              <th mat-header-cell *matHeaderCellDef>Section Name</th>
              <!-- <td (click)="studentPageView ? null :openSchedule(element)" mat-cell *matCellDef="let element"
                class="custom-td section-name" style="height: 60px;"> -->
                <td (click)="studentPageView ? null :openSchedule(element)" mat-cell *matCellDef="let element"
                class="custom-td-2" style="height: 60px;">
                <span>
                  {{element.no | number : '1.1'}}
                </span>
                {{element.sectionname}}
              </td>
            </ng-container>

            <ng-container matColumnDef="schedule">
              <!-- mat-sort-header sortActionDescription="Sort by schedule" -->
              <th mat-header-cell *matHeaderCellDef style="padding-left:0px;">Schedule</th>
              <td (click)="studentPageView ? null : openSchedule(element)" mat-cell *matCellDef="let element"
                class="custom-td" style="height: 60px;">
                <!-- {{element.startdate}} -->
                <div *ngIf="element.startdate && element.enddate ">
                  {{ allinoneService.formatDBToShow(element.startdate) + " " + element.starttime }} <span
                    style="font-weight:700;" class="mx-2"> - </span> {{ allinoneService.formatDBToShow(element.enddate)
                  + " " + element.endtime }}
                </div>
                <span *ngIf="element.startdate && !element.enddate ">
                  {{ allinoneService.formatDBToShow(element.startdate) + " " + element.starttime }} <span
                    style="font-weight:700;" class="mx-2"> - </span> --/--/-- --:-- --
                </span>
                <span *ngIf="!element.startdate && element.enddate ">
                  --/--/-- --:-- -- <span style="font-weight:700;" class="mx-2"> - </span> {{
                  allinoneService.formatDBToShow(element.enddate) + " " + element.endtime }}
                </span>
                <span *ngIf="!element.startdate && !element.enddate ">
                  --/--/-- --:-- -- <span style="font-weight:700;" class="mx-2"> - </span> --/--/-- --:-- --
                </span>
              </td>
              <!-- <td></td> -->
            </ng-container>


            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 10px;">
                <div class="d-flex align-items-center justify-content-center">
                  <div>
                    Status
                  </div>
                  <div class="form-check form-switch d-flex justify-content-center switch">
                    <input class="form-check-input switch-on-off" type="checkbox" id="all" [checked]="openedall"
                      [disabled]="gettingDropbox" [matTooltip]="openedall ? 'Close all sections': 'Open all sections'"
                      (change)="openedall ? closeallsections() : openallsections()">
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element;let i = index;" style="text-align: center;">
                <div class="form-check form-switch d-flex justify-content-center w-100 switch">
                  <input class="form-check-input switch-on-off" type="checkbox" [id]="'switch'+i"
                    [checked]="openedSections.includes(element.sectionid)" matTooltip="On/Off this section"
                    (change)="sectionsUpdate(element.sectionid)">
                </div>
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="studentPageView ? studentSectionsDisplayedColumns : sectionsDisplayedColumns"></tr>
            <tr mat-row
              *matRowDef="let row; columns: studentPageView ? studentSectionsDisplayedColumns : sectionsDisplayedColumns;">
            </tr>
            <tr class="mat-row" *matNoDataRow>
              <td colspan="7">
                <span class="nodata" *ngIf="!gettingDropbox">Empty</span>
                <span class="nodata" *ngIf="gettingDropbox">
                  <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="!studentPageView">
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">notifications</mat-icon>
        Notification
      </ng-template>

      <app-push-noti [courseid]="courseid" [classid]="classid" [studentList]="allStudent"></app-push-noti>

    </mat-tab>
  </mat-tab-group>

</div>