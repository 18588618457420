<div class="d-flex align-items-center justify-content-between mb-4">
  <div>
    <h2 class="mb-0">{{isEditing ? 'Edit Schedule' : 'New Schedule'}}</h2>
  </div>
  <div class="d-flex align-items-center">
    <mat-icon (click)="dismiss()" class="cus-point">close</mat-icon>
  </div>
</div>

<div class="row">
  <!-- <div class="mb-3 col-md-12">
    <label for="name" class="form-label">Name</label>
    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="course.name"
      [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>
    <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
      <div *ngIf="name.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div> -->
  <div class="mb-3 col-md-12">
    <label for="name" class="form-label">Course Name</label>
    <input type="text" class="form-control form-control-sm" placeholder="Enter or choose course name" aria-label="Number" matInput
      class="form-control" [matAutocomplete]="auto" id="course" name="course" #trigger="matAutocompleteTrigger"
      [(ngModel)]="course.name" (click)="showList()" (ngModelChange)="changeName()"
      [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let course of allCourses | appFilter:course.name" (click)="selectCourse(course)">
        {{course.coursename}}
      </mat-option>
    </mat-autocomplete>
    <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
      <div *ngIf="name.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
    <div *ngIf="isCourseSelected" class="linkedto">
      <i class="fa fa-check me-2"></i>Linked to course
    </div>
  </div>
  <div class="mb-3 col-md-12">
    <label for="status" class="form-label">Status</label>
    <select type="text" class="form-select" id="status" name="status" [(ngModel)]="course.status"
      [ngClass]="{ 'is-invalid': formSubmitted && status.invalid }" #status="ngModel" required>
      <option *ngFor="let item of statusList" [value]="item.id">
        {{item.name}}
      </option>
    </select>
    <div class="invalid-feedback" *ngIf="status.invalid || (status.dirty || status.touched) && formSubmitted">
      <div *ngIf="status.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="mb-3 col-md-6">
    <label for="startdate" class="form-label">Start Date</label>
    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="course.startdate"
      [ngClass]="{ 'is-invalid': formSubmitted && startdate.invalid }" #startdate="ngModel" required>
    <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) && formSubmitted">
      <div *ngIf="startdate.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="mb-3 col-md-6">
    <label for="enddate" class="form-label">End Date</label>
    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="course.enddate"
      [min]="course.startdate" [ngClass]="{ 'is-invalid': formSubmitted && enddate.invalid }" #enddate="ngModel"
      required>
    <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && formSubmitted">
      <div *ngIf="enddate.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <!-- <div class="mb-3 col-md-12">
    <label for="description" class="form-label">Description</label>
    <textarea [rows]="3" class="form-control" [(ngModel)]="course.description" id="description" name="description">
    </textarea>
  </div> -->
  <div class="mb-3 col-md-12">
    <div class="mb-3">
      <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput>
      <button class="ms-0" mat-flat-button color="primary" (click)="hiddenfileinput.click()">
        Choose Image
      </button>
    </div>


    <div *ngIf="course.file || course.filename">
      <!-- image-crop -->
      <div *ngIf="imageChangedEvent != '' && !croppedImage" class="img-cropper">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="16/9"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>

        <!-- <button mat-flat-button color="primary" class="text-end" (click)="copyImage()">Crop
          Image</button> -->
      </div>


      <div *ngIf="course.previewurl&& croppedImage" style="position: relative">
        <img [src]="course.previewurl" class="cropped-image">
        <span class="remove-img-icon" matTooltip="Remove Image">
          <i class="fa fa-minus-circle" (click)="removeImage()"></i>
        </span>
      </div>
    </div>
    <div *ngIf="!course.file && !course.filename && course.courseimageurl">
      <img [src]="course.courseimageurl" class="cropped-image">
    </div>
  </div>
</div>

<div class="actions-button">

  <button mat-flat-button color="primary" class="mt-2 me-auto" (click)="copyImage()"
    *ngIf="imageChangedEvent != '' && !croppedImage">Crop
    Image</button>

  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled]="isLoading">
    Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="submit()">
  </app-button-loader>

</div>