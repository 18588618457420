import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatInputModule } from '@angular/material/input';
import { transferArrayItem } from '@angular/cdk/drag-drop';
import ImageViewer from 'awesome-image-viewer';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { CourseService as InsctructorCourseService } from 'projects/e-learning-instructor/src/app/shared/services/course.service';
import { ConfirmDialogTwoComponent } from '../../shared/components/confirm-dialog-two/confirm-dialog-two.component';
// import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { CostExplorer } from 'aws-sdk';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  @Input() unitid: string = '';
  @Input() status: string = '';
  @Input() courseid: string = '';
  @Input() sectionid: string = '';
  @Input() grading: string = '002';
  @Input() subsectionid: string = '';
  @Input() smallscreenview: boolean = false;
  @Input() autonumbering: boolean = false;
  @Input() isFromInstructor: boolean = false;

  @Output('cancel')
  cancelquiz: EventEmitter<any> = new EventEmitter<any>();

  @Output('complete')
  completequiz: EventEmitter<any> = new EventEmitter<any>();

  @Output('startTimer')
  startTimer: EventEmitter<any> = new EventEmitter<any>();

  @Output('stopTimer')
  stopTimer: EventEmitter<any> = new EventEmitter<any>();

  @Output('changedAnsList')
  changedAnsList: EventEmitter<any> = new EventEmitter<any>();

  @Output('callQuizCache')
  callQuizCache: EventEmitter<any> = new EventEmitter<any>();

  // close or refresh browswer, tab
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    // api call here
    console.log('Quiz Component');
    console.log(this.isfeedback);


    if (this.isFromInstructor) {
      return true;
    } else if (this.isfeedback) {
      console.log('ef con');

      return true;
    }
    else if (this.bygroup && !this.isfeedback) {
      console.log('1');

      this.saveDataWhenReload();
      return false;
    } else if (!this.bygroup && this.isMobileView && !this.isfeedback) {
      console.log('2');
      this.callAPIForNoGroupMobileView();
      return false;
    }
    else {
      return true;
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    console.log('Back button clicked');
    if (this.bygroup && !this.isFromInstructor && !this.isfeedback) {
      this.saveDataWhenReload();
    } else if (!this.bygroup && this.isMobileView && !this.isFromInstructor && !this.isfeedback) {
      this.callAPIForNoGroupMobileView();
    }
  }

  isPublished: boolean = false;

  isLoading: boolean = false;
  question: any = [];

  // question2: any = [
  //   {
  //     "quizid": "03275578484857201211",
  //     "unitid": "46780169236593923326",
  //     "courseid": "56083397615073251876",
  //     "question": "Html stand for ___ .",
  //     "answerlist": [
  //         ""
  //     ],
  //     "countbyquantity": false,
  //     "imagelist": [],
  //     "type": "003",
  //     "sortkey": "0"
  // },
  //   {
  //     "quizid": "97543106454816225718",
  //     "unitid": "01422608271398273025",
  //     "courseid": "69394627897528782033",
  //     // "question": "firstblank {{0}} and secondblank {{1}}",
  //     "question": "Html stand for Hyper {{}} Markup Language. HTML element for the largest heading {{}}.HTML element for inserting a line break {{}}.",
  //     // "question": 'firstblank <a href="https://www.w3schools.com">Visit W3Schools.com!</a> and secondblank {{}}',
  //     // "question": 'firstblank <select class="form-select" aria-label="Default select example"><option selected>Open this select menu</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select> and secondblank {{}}',
  //     // "question" : '<select class="form-select" aria-label="Default select example"><option selected>Open this select menu</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>',
  //     // "answerlist": [
  //     //   ['1', '2',], ['1', '2']
  //     // ],
  //     "answerlist": [
  //       ['Text', 'Tool',], ['h6', 'h1'], ['br', 'break', 'lb']
  //     ],
  //     "countbyquantity": false,
  //     "correctanswer": [
  //       '1', '2'
  //     ],
  //     "type": "005",
  //     "sortkey": "1",
  //     "modifieddate": "04-05-2024 10:27 AM",
  //     "createddate": "04-05-2024 10:27 AM",
  //     "createdby": "+959786883358",
  //     "casesensitive": "false",
  //     "imagelist": []
  //   }
  // ];

  // displayDropDownQuiz(d : any): SafeHtml {
  //   // const test = 'test {{}} testOne {{}} TestTwo {{}} {{}}';
  //   // let answer = [
  //   //   ['1', '2'],
  //   //   ['3', '4'],
  //   //   ['5', '6'],
  //   //   ['7', '8'],
  //   // ];

  //   var test : any = d.question;
  //   var answer : any = d.answerlist;

  //   let data: any = [];
  //   for (var i = 0; i < answer.length; i++) {
  //     const options = answer[i]
  //       .map((item : any, index : any) => {
  //         return `<option value="${item}">${item}</option>`;
  //       })
  //       .join('');

  //     const html = `
  //       <select>
  //         ${options}
  //       </select>
  //     `;

  //     data.push(html);
  //   }
  //   let parts: string[] = test.split(/({{}})/);
  //   let dropDownArray: any = [];
  //   const modifiedParts = parts.map((part, index) => {
  //     if (part === '{{}}') {
  //       dropDownArray.push(part);
  //       data.forEach((item: any, a: any) => {
  //         dropDownArray.map((dropItem: any, z: any) => {
  //           if (z == a) {
  //             part = item;
  //           } else {
  //             part;
  //           }
  //         });
  //       });
  //       return part;
  //     } else {
  //       return part;
  //     }
  //   });
  //   let showData = modifiedParts.join('');
  //   return this.sanitizer.bypassSecurityTrustHtml(showData);
  // }

  // changeDropDown(p : any) {
  //   if(p) {
  //     var c1 = p.replace("{{0}}", 'c1');
  //     var f = c1.replace("{{1}}", 'c2');
  //     return f;
  //     // var c = p;
  //     // console.log('ok ok ok');
  //     // // var 'c' + 0 = '';
  //     // var v0 = p;
  //     // for(var i = 0 ; i < this.replacecounts; i++) {

  //     //   'v'+[i] = 'v'+i.replace("{{"+i+"}}", 'c'+i);
  //     //   console.log(c);


  //     // }
  //     // return c;
  //   } else {
  //     return 'No Changes';
  //   }
  // }

  // unitid: string = '';
  resultid: string = '';
  unitname: string = '';
  issaveing: boolean = false;
  // courseid: string = '';
  // status: string = '';
  // percentage: number = 0;
  score:number= 0;
  mark:number = 0;
  totalmark:number = 0;
  isfeedback: boolean = true;
  isMobileView: boolean = false;

  bygroup: boolean = false;
  grouptime: boolean = false;
  groupQuestionCount: number = 0;
  mobileViewWidth: number = 501;
  studytime: number = 0;
  currentPage: number = 0;
  groupQuestionList: any = [];

  timeInterval: any;

  changeGroupLoading: boolean = false;

  // timer for all quiz
  quiztimer: any = '00:00';
  havequiztimer: boolean = false;
  reducequiztime: any;
  allTimeLimitList: any = [];

  firstPage: boolean = true;
  isfirstTime: boolean = true;

  haveAPIDataList: boolean = false;
  APIAnswerList: any = [];
  APITimeList: any = [];
  APICurrentPage: any = '';

  quizCacheSubscription!: Subscription;

  keysPressed: any = {};

  constructor(
    private courseService: CourseService,
    private instructorCourseService: InsctructorCourseService,
    public allinOneService: AllInOneService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog, // private cookieService: CookieService
    private sanitizer: DomSanitizer
  ) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    if (!this.smallscreenview) {
      this.route.params.subscribe((params) => {
        this.unitid = params['lectureid'];
        this.courseid = params['courseid'];
        this.status = params['resultid'];
        this.sectionid = params['sectionid'];
        this.subsectionid = params['subsectionid'];
        this.grading = params['grading']
      });
    }
    console.log('greding >> ' , this.grading)
  }

  // detectFullscreenChange(): void {
  //   document.addEventListener('fullscreenchange', () => {
  //     if (!document.fullscreenElement) {
  //       alert("Fullscreen mode is required to take the quiz.");
  //       this.detectedSSDialog();

  //     }
  //   });
  // }

  // enableFullScreen() {
  //   var doc: any = document.documentElement;
  //   if (doc.requestFullscreen) {
  //     console.log('1');

  //     doc.requestFullscreen();

  //   } else if (doc.mozRequestFullScreen) { /* Firefox */
  //   console.log('2');
  //     doc.mozRequestFullScreen();
  //   } else if (doc.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
  //   console.log('3');
  //     doc.webkitRequestFullscreen();
  //   } else if (doc.msRequestFullscreen) { /* IE/Edge */
  //   console.log('4');
  //     doc.msRequestFullscreen();
  //   }
  // }

  // @HostListener('document:visibilitychange', ['$event'])
  // onVisibilityChange(event: Event): void {
  //   if (document.hidden) {
  //     alert("Switching tabs is not allowed!");
  //     // You can log this event or take other actions
  //   }
  // }

  // @HostListener('document:contextmenu', ['$event'])
  // onRightClick(event: MouseEvent): void {
  //   event.preventDefault();
  // }

  ngOnInit(): void {
    // this.enableFullScreen();
    // this.detectFullscreenChange();

    // document.addEventListener('visibilitychange', function () {
    //   if (document.hidden) {
    //     alert("Switching tabs is not allowed!");
    //     // You can log this event or take other actions
    //   }
    // });

    // document.addEventListener('fullscreenchange', function () {
    //   if (!document.fullscreenElement) {
    //     alert("Fullscreen mode is required to take the quiz.");
    //     }
    //   });

    // detect screen shtot
    // document.addEventListener('keydown', (event) => {
    //   this.keysPressed[event.key] = true;

    //   if (this.keysPressed['Meta'] && this.keysPressed['Shift']) {
    //     // alert(event.key);
    //     this.blurSensitiveContent();
    //     this.detectedSSDialog();
    //   }
    // });

    // document.addEventListener('keyup', (event) => {
    //   // delete this.keysPressed[event.key];
    //   this.keysPressed = {};
    //   this.rblurSensitiveContent();
    // });
    var tempCon = false;
    this.activatedRoute.data.subscribe((data) => {
      tempCon = data.isFromInstructor;
      this.isPublished = data.isPublished;

      if (tempCon || this.isFromInstructor) {
        this.isFromInstructor = true;

      }

    });

    this.getQuizQuestion();

  }

  ngOnDestroy(): void {
    clearInterval(this.reducequiztime);
    clearInterval(this.timeInterval);
  }

  
  intToRomanInt(num: number) {
    const romanNumerals: any = {
      1: 'i',
      2: 'ii',
      3: 'iii',
      4: 'iv',
      5: 'v',
      6: 'vi',
      7: 'vii',
      8: 'viii',
      9: 'ix',
      10: 'x'
    };

    return romanNumerals[num] || 'Invalid number';
  }

  detectedSSDialog() {
    let dialogRef = this.dialog.open(ConfirmDialogTwoComponent, {
      minWidth: '300px',
      closeOnNavigation: true,
      data: {
        message: 'Screenshot Detected.',
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.rblurSensitiveContent();
      // this.enableFullScreen();
    });
  }

  blurSensitiveContent() {
    const sensitiveElements = document.querySelectorAll('.ss');
    sensitiveElements.forEach(el => {
      el.classList.add('blur');
    });
  }

  rblurSensitiveContent() {
    const sensitiveElements = document.querySelectorAll('.ss');
    sensitiveElements.forEach(el => {
      el.classList.remove('blur');
    });
  }

  getValidityStatus(i: any) {
    var trueFalse: boolean = true;
    const questions = this.groupQuestionList[i]?.questions;
    if (!questions || questions.length === 0) {
      return false;
    }

    questions.forEach((question: any) => {
      const answerItem = this.answerList.find((ans: any) => ans.quizid === question.quizid);

      if (answerItem?.answerid && Array.isArray(answerItem.answerid)) {
        if (answerItem.answerid.length == 0) {
          trueFalse = false;
        } else {
          answerItem.answerid.filter((ans: any) => {
            if (ans == null || ans == 'null' || ans == -1 || ans == "") {
              trueFalse = false;
            } else if (ans.answerid == '') {
              trueFalse = false;
            } else {
              trueFalse = true;
            }
          });
        }
      } else {
        if (
          answerItem.answerid == '-1' ||
          answerItem.answerid == ''
        ) {
          trueFalse = false;
        } else {
          trueFalse = true;
        }
      }
    });

    return trueFalse;
  }

  generatePdf() {

    const elementToPrint: any = document.querySelector("#main-page");

    html2canvas(elementToPrint, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Determine the aspect ratio of the HTML content
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the corresponding dimensions for the PDF page while maintaining the aspect ratio
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdfWidth / aspectRatio;

      // Resize the canvas to match the calculated dimensions
      canvas.width = pdfWidth;
      canvas.height = pdfHeight;

      // Add the resized image to the PDF document
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      // Save the PDF document
      pdf.save('Feedback.pdf');
    })

      .catch(error => {
        console.error('Error encountered:', error);
      });
  }

  t() {
    console.log(this.question);


  }

  // t2() {
  //   // console.log(this.haveAPIDataList);
  //   // console.log(this.groupQuestionList);
  //   // console.log(this.APITimeList);
  //   // console.log(this.isfeedback);
  //   console.log(this.studytime);
  // }

  saveDataWhenReload() {
    var al = this.getAnswerList();
    var tempTimeList: any = [];
    if (this.grouptime) {
      this.groupQuestionList.map((x: any) => {
        tempTimeList.push(x.starttime);
      });
    }

    var tempData = {
      unitid: this.unitid,
      currentPage: this.currentPage,
      time: this.grouptime ? tempTimeList : this.quiztimer,
      answerlist: al,
    };
    // this.saveTimeInCookie(tempData);
    this.addQuizCache(tempData, true);
  }

  outPut(selectedques: any) {
    selectedques.answerid = [];
    for (var each of selectedques.formattedQuiz) {
      if (each.type == '002') {
        selectedques.answerid.push(each.selected)
      }
    }
    console.log(selectedques.answerid, "there should be answerid")
    if (this.isMobileView) {
      this.callAPIForNoGroupMobileView()

    } else if (!this.bygroup && !this.isMobileView) {
      this.outputAnsToParent();
      this.callAPIINParent();
    }
  }

  outputAnsToParent() {

    // this.changedAnsList.emit(this.question);

    if (!this.bygroup && !this.isMobileView) {
      console.log('out put');
      var al = this.getAnswerList();
      this.changedAnsList.emit(al);
    }

  }

  callAPIINParent() {
    if (!this.bygroup && !this.isMobileView) {
      this.callQuizCache.emit();
    }
  }

  callAPIForNoGroupMobileView() {
    var al = this.getAnswerList();
    var tempData = {
      unitid: this.unitid,
      currentPage: '0',
      time: this.quiztimer,
      answerlist: al,
    }
    this.addQuizCache(tempData);
  }

  getAnswerList() {
    this.answerList = [];
    for (let i = 0; i < this.question.length; i++) {
      let apiAnswer = this.APIAnswerList.find(
        (apiAns: any) => apiAns.quizid === this.question[i].quizid
      );

      if (this.question[i].type == '001') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid:
            this.question[i].selected == '-1'
              ? '-1'
              : this.question[i].answerlist[this.question[i].selected].answerid,
        });
      } else if (this.question[i].type == '003') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].selected == '-1'
            ? '-1'
            : (apiAnswer && apiAnswer?.answerid?.length && !this.question[i].answerid?.length) 
              ? apiAnswer.answerid 
              : this.question[i].answerid,
        });
      } else if (this.question[i].type == '002') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].checkboxans
            ? this.question[i].checkboxans
            : [],
        });
      } else if (this.question[i].type == '005') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].answerid,
        })
      } else {
        var tempArray: any[] = [];
        for (var j = 0; j < this.question[i].question.questions.length; j++) {
          var tempObj = {
            questionid: this.question[i].question.questions[j].questionid,
            answerid:
              this.question[i].question.questions[j].anscontanier.length > 0
                ? this.question[i].question.questions[j].anscontanier[0]
                  .answerid
                : '',
          };
          tempArray.push(tempObj);
        }
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: tempArray,
        });
      }
    }
    // console.log(this.answerList, "answerlist")
    return this.answerList;
  }

  getQuizQuestion() {
    this.isLoading = true;
    if (this.status == 'start') {
      this.resultid = '';
      this.isfeedback = false;
    } else {
      this.resultid = this.status;
      this.isfeedback = true;
    }
    var fun = this.isfeedback
      ? this.courseService.getFeedback(
        this.unitid,
        this.resultid,
        this.courseid
      )
      : this.isFromInstructor ? this.instructorCourseService.getQuizQuestion(
        this.unitid,
        this.resultid,
        this.courseid,
        this.sectionid,
        this.subsectionid
      ) : this.courseService.getQuizQuestion(
        this.unitid,
        this.resultid,
        this.courseid,
        this.sectionid,
        this.subsectionid
      );
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.unitname = res.unitname;
          this.question = res.datalist;
          if (!this.smallscreenview) {
            this.groupQuestionCount = isNaN(parseInt(res.groupquestionscount))
              ? 0
              : parseInt(res.groupquestionscount);
            this.bygroup = res.bygroup;
            this.studytime = isNaN(parseInt(res.studytime))
              ? 0
              : parseInt(res.studytime);
            this.grouptime = res.grouptime;

            if (this.studytime > 0) {
              this.havequiztimer = true;
            }
          }
          this.isLoading = false;
          if (!this.isfeedback) {
            if (res.answerlist.length != 0) {
              this.haveAPIDataList = true;
            }
          }
          this.APIAnswerList = res.answerlist;
          this.APICurrentPage = parseInt(res.currentpage);
          // this.APITimeList = res.time;
          if (res.time != '') {
            if (this.bygroup && this.grouptime) {
              var tempapitime = res.time;
              for (var i = 0; i < tempapitime.length; i++) {
                if (tempapitime[i] != '') {
                  this.APITimeList.push(parseInt(tempapitime[i]));
                } else {
                  this.APITimeList.push('');
                }
              }
            } else if (this.bygroup && !this.grouptime) {
              this.APITimeList = parseInt(res.time);
              console.log(this.APITimeList);
            } else if (!this.bygroup) {
              console.log('no group time');

              this.APITimeList = parseInt(res.time);
              console.log(this.APITimeList);
            }
          }

          // get time from storage and save data in storage for group time for all
          // if (!this.grouptime && this.bygroup && !this.isfeedback && !this.isMobileView) {
          if (!this.grouptime && this.bygroup && !this.isfeedback) {
            if (this.studytime == 0) {
              // this.havequiztimer = false;
            } else {
              // this.havequiztimer = true;

              // get time from cookie
              // var tempList = this.allinOneService.getQuizTimeLimit();
              var tempList = localStorage.getItem('quizcachelist');
              this.allTimeLimitList = tempList ? JSON.parse(tempList) : [];

              var tempi = this.allTimeLimitList.findIndex(
                (x: any) => x.unitid == this.unitid
              );

              if (this.haveAPIDataList) {
                console.log('your error time con');
                console.log(this.APITimeList);

                var cookietime = this.APITimeList;
                // *** int error
                var cookiemin = Math.floor(cookietime / 60);
                var cookiesec = cookietime % 60;
                var min: number = cookiemin;
                var sec: number = cookiesec;
              } else if (tempi >= 0 && !this.haveAPIDataList) {
                // var cookietime = this.allTimeLimitList[tempi].time.split(':');
                // var cookiemin = parseInt(cookietime[0]);
                // var cookiesec = parseInt(cookietime[1]);
                var cookietime = this.allTimeLimitList[tempi].time;
                var cookiemin = Math.floor(cookietime / 60);
                var cookiesec = cookietime % 60;
                var min: number = cookiemin;
                var sec: number = cookiesec;
                // this.question = this.allTimeLimitList[tempi].question
              } else {
                var min: number = this.studytime - 1;
                var sec: number = 60;
              }

              this.reducequiztime = setInterval(() => {
                // min--;
                sec--;

                if (sec == 0) {
                  min--;
                  sec = 60;

                  if (min < 0) {
                    this.submit('', false, false, false);
                    min = 0;
                    sec = 0;
                    clearInterval(this.reducequiztime);
                  }
                }
                var fmin = min.toString().padStart(2, '0');
                var fsec = sec.toString().padStart(2, '0');
                this.quiztimer = fmin + ':' + fsec;

                var tempStorage = min * 60 + sec;

                // save in cookie
                if (this.quiztimer != '00:00') {
                  var al = this.getAnswerList();
                  var tempData = {
                    unitid: this.unitid,
                    currentPage: this.currentPage,
                    // time: this.quiztimer,
                    time: tempStorage,
                    // groupQuestionList: this.groupQuestionList,
                    answerlist: al,
                  };
                  // this.saveTimeInCookie(tempData);

                  // ****
                  // var temp = this.cookieService.get('quizcachelist');
                  // this.allTimeLimitList = temp ? JSON.parse(temp) : [];
                  // // var tempData = {
                  // //   unitid: this.unitid,
                  // //   time: this.quiztimer
                  // // };
                  // var tempData = {
                  //   unitid: this.unitid,
                  //   forsinglequiz: false,
                  //   time: this.quiztimer,
                  //   groupQuestionList: this.groupQuestionList
                  // }
                  // var i = this.allTimeLimitList.findIndex((x: any) => x.unitid == tempData.unitid);
                  // if (i >= 0) {
                  //   this.allTimeLimitList.splice(i, 1);
                  // }
                  // this.allTimeLimitList.push(tempData);
                  // this.cookieService.set('quizcachelist', JSON.stringify(this.allTimeLimitList));
                }
              }, 1000);
            }
          }
          // time end

          // Feedback
          for (let i = 0; i < this.question.length; i++) {
            // for question format
            if (this.question[i].type == '005') {
              var resDropdown = this.dropdownReplaceDisplay(
                this.question[i].question,
                this.question[i].answerlist,
                this.isfeedback,
                this.question[i].answerid,
                i
              );
              this.question[i].formattedQuiz = resDropdown;
            } else if (this.question[i].type == '003') {
              var resDropdown = this.fillinblankReplaceDisplay(
                this.question[i].question,
                this.question[i].answerid,
                this.isfeedback
              );
              this.question[i].formattedQuiz = resDropdown;
            }
            if (this.isfeedback) {
              console.log('error con feedback');
              this.score = res.score;
              this.mark = res.mark;
              this.totalmark = res.totalmark;
              // this.percentage =(res.score/res.mark)*100;
              // Radio Question
              if (this.question[i].type == '001') {
                var index = this.question[i].answerlist.findIndex(
                  (ans: any) => ans.answerid == this.question[i].answerid
                );
                this.question[i].selected = index; //
                console.log('index ===> ' + this.question[i].selected);
                this.question[i].explaination =
                  this.question[i].selected == '-1'
                    ? ''
                    : this.question[i].answerlist[this.question[i].selected]
                      .explanation;
              }

              // Checkbox quesitons
              else if (this.question[i].type == '002') {
                this.question[i].checkboxans = this.question[i].answerid;
                this.question[i].selectall = false;
                var count = 0;
                this.question[i].correctanswer.map((item: any) => {
                  if (this.question[i].answerid.includes(item)) {
                    count += 1;
                  }
                });
                if (count == this.question[i].correctanswer.length) {
                  this.question[i].selectall = true;
                }
              }

              // Fill in the blank Questions
              else if (this.question[i].type == '003') {
                this.question[i].explaination = this.question[i].answerlist[0];
              }

              // Drag and drop matching
              else if (this.question[i].type == '004') {
                this.question[i].answerid.map((item: any) => {
                  var quesIndex = this.question[i].question.questions.findIndex(
                    (x: any) => x.questionid == item.questionid
                  );
                  var ansIndex = this.question[i].answerlist.findIndex(
                    (x: any) => x.answerid == item.answerid
                  );
                  if (quesIndex > -1) {
                    this.question[i].question.questions[
                      quesIndex
                    ].anscontanier = []; //
                    if (ansIndex > -1) {
                      this.question[i].question.questions[
                        quesIndex
                      ].anscontanier = [this.question[i].answerlist[ansIndex]];
                      this.question[i].answerlist.splice(ansIndex, 1);
                    }
                  }
                });
              }
            } else {
              console.log('feedback con 2');


              this.question[i].checkboxans = [];
              if (this.question[i].type != '003') {
                this.question[i].selected = '-1';
              } else {
                this.question[i].selected = '';
                this.question[i].answerid = [];
                this.question[i].fixedsequence = true;
              }
              if (this.question[i].type == '004') {
                this.question[i].question.questions.map((item: any) => {
                  item.anscontanier = [];
                });
              }
            }
          }

          // Get data for by group
          // ***
          // if (this.bygroup && !this.isfeedback && !this.isMobileView) {
          if (this.bygroup && !this.isfeedback) {
            // if(this.studytime > 0){
            //   this.havequiztimer = true;
            // }
            this.changeGroupLoading = true;
            let totalPage = Math.ceil(
              this.question.length / this.groupQuestionCount
            );

            if (this.haveAPIDataList && !this.isfeedback) {
              if (this.grouptime) {
                console.log('api con 1');
                var apiListSingleGroup = this.APIAnswerList;
                var apiTime = this.APITimeList;

                // get answer from api
                this.getAnswerFromLocalStorage(apiListSingleGroup);

                // build question list for ui
                for (let i = 0; i < totalPage; i++) {
                  let start = i * this.groupQuestionCount;
                  let end = start + this.groupQuestionCount;
                  let tempPage = {
                    // timelimit: !this.grouptime ? 0 : this.studytime,
                    timelimit: this.studytime,
                    starttime: apiTime[i],
                    questions: this.question.slice(start, end),
                    disabled: apiTime[i] === 0 ? true : false,
                  };
                  this.groupQuestionList.push(tempPage);
                }

                this.isfirstTime = false;
                console.log('cg 1');
                console.log(this.groupQuestionList);

                this.changeGroup(false, this.APICurrentPage);
                this.firstPage = false;
              } else if (!this.grouptime) {
                console.log('api con 2');
                var apiListGroupAll = this.APIAnswerList;

                // get answer from api
                this.getAnswerFromLocalStorage(apiListGroupAll);
                // var apiTime = this.APITimeList;
                // build question list for ui
                for (let i = 0; i < totalPage; i++) {
                  let start = i * this.groupQuestionCount;
                  let end = start + this.groupQuestionCount;
                  let tempPage = {
                    // timelimit: !this.grouptime ? 0 : this.studytime,
                    timelimit: this.studytime,
                    starttime: '',
                    questions: this.question.slice(start, end),
                    disabled: false,
                  };
                  this.groupQuestionList.push(tempPage);
                }
                this.currentPage = this.APICurrentPage;
                console.log('cg 2');
                this.changeGroup(false, this.APICurrentPage);
                this.firstPage = false;
              } else {
                for (let i = 0; i < totalPage; i++) {
                  let start = i * this.groupQuestionCount;
                  let end = start + this.groupQuestionCount;
                  let tempPage = {
                    // timelimit: !this.grouptime ? 0 : this.studytime,
                    timelimit: this.studytime,
                    starttime: '',
                    questions: this.question.slice(start, end),
                    disabled: false,
                  };
                  this.groupQuestionList.push(tempPage);
                }
              }
            } else {
              var tempList2 = localStorage.getItem('quizcachelist');
              this.allTimeLimitList = tempList2 ? JSON.parse(tempList2) : [];

              var tempi2 = this.allTimeLimitList.findIndex(
                (x: any) => x.unitid == this.unitid
              );

              console.log('Your ANS');
              console.log(tempi2);
              console.log(this.grouptime);

              // get answer from storage for single group time
              if (tempi2 >= 0 && this.grouptime && !this.isfeedback) {

                var storageListSingleGroup =
                  this.allTimeLimitList[tempi2].answerlist;
                var storageTime = this.allTimeLimitList[tempi2].time;

                // get answer from local storage
                this.getAnswerFromLocalStorage(storageListSingleGroup);

                // build question list for ui
                for (let i = 0; i < totalPage; i++) {
                  let start = i * this.groupQuestionCount;
                  let end = start + this.groupQuestionCount;
                  let tempPage = {
                    // timelimit: !this.grouptime ? 0 : this.studytime,
                    timelimit: this.studytime,
                    starttime: storageTime[i],
                    questions: this.question.slice(start, end),
                    disabled: false,
                  };
                  this.groupQuestionList.push(tempPage);
                }

                this.isfirstTime = false;
                console.log('cg 3');
                this.changeGroup(
                  false,
                  this.allTimeLimitList[tempi2].currentPage
                );
                this.firstPage = false;
              } else if (tempi2 >= 0 && !this.grouptime && !this.isfeedback) {
                console.log('your con 2');

                // old code
                // this.groupQuestionList =
                //   this.allTimeLimitList[tempi2].groupQuestionList;
                // this.currentPage = this.allTimeLimitList[tempi2].currentPage

                var storageListGroupAll =
                  this.allTimeLimitList[tempi2].answerlist;

                // get answer from local storage
                this.getAnswerFromLocalStorage(storageListGroupAll);

                // build question list for ui
                for (let i = 0; i < totalPage; i++) {
                  let start = i * this.groupQuestionCount;
                  let end = start + this.groupQuestionCount;
                  let tempPage = {
                    // timelimit: !this.grouptime ? 0 : this.studytime,
                    timelimit: this.studytime,
                    starttime: '',
                    questions: this.question.slice(start, end),
                    disabled: false,
                  };
                  this.groupQuestionList.push(tempPage);
                }

                // change page from storage
                this.currentPage = this.allTimeLimitList[tempi2].currentPage;
                console.log('cg 4');
                this.changeGroup(
                  false,
                  this.allTimeLimitList[tempi2].currentPage
                );
                this.firstPage = false;
              } else {
                for (let i = 0; i < totalPage; i++) {
                  let start = i * this.groupQuestionCount;
                  let end = start + this.groupQuestionCount;
                  let tempPage = {
                    // timelimit: !this.grouptime ? 0 : this.studytime,
                    timelimit: this.studytime,
                    starttime: '',
                    questions: this.question.slice(start, end),
                    disabled: false,
                  };
                  this.groupQuestionList.push(tempPage);
                }
              }

              if (
                this.groupQuestionList.length > 0 &&
                this.grouptime &&
                this.havequiztimer &&
                this.firstPage
              ) {
                console.log('cd 1');

                this.startCountdown(this.groupQuestionList[0]);
              } else {
                this.changeGroupLoading = false;
              }
            }
          }

          // Get data for no group
          if (!this.bygroup && !this.isfeedback && !this.isMobileView) {
            console.log('con 3');

            if (this.haveAPIDataList) {
              console.log('api con 3', this.APIAnswerList);
              var apiNoGroupList = this.APIAnswerList;

              // get answer from api
              this.getAnswerFromLocalStorage(apiNoGroupList);
            }

            // var tempList = this.allinOneService.getQuizTimeLimit();
            // var tempList = localStorage.getItem('quizcachelist') ? localStorage.getItem('quizcachelist') : '' ;
            var tempList = localStorage.getItem('quizcachelist');
            this.allTimeLimitList = tempList ? JSON.parse(tempList) : [];

            var tempi = this.allTimeLimitList.findIndex(
              (x: any) => x.unitid == this.unitid
            );
            if (tempi >= 0) {
              // this.question = this.allTimeLimitList[tempi].question;

              var storageListNoGroup = this.allTimeLimitList[tempi].answerlist;

              console.log('your list');
              console.log(storageListNoGroup);

              // get answer from local storage
              this.getAnswerFromLocalStorage(storageListNoGroup);
            }
            this.outputAnsToParent();
            // this.callAPIINParent();
          }

          // Get data for mobile view
          if (!this.bygroup && this.isMobileView && !this.isfeedback) {
            console.log('your mobile con');

            if (this.studytime == 0) {
              // this.havequiztimer = false;
              var apiListMobileView = this.APIAnswerList;

              // get answer from apix
              this.getAnswerFromLocalStorage(apiListMobileView);
            } else {
              // this.havequiztimer = true;

              // get time from cookie
              // var tempList = this.allinOneService.getQuizTimeLimit();

              var tempList = localStorage.getItem('quizcachelist');
              this.allTimeLimitList = tempList ? JSON.parse(tempList) : [];

              var tempi = this.allTimeLimitList.findIndex(
                (x: any) => x.unitid == this.unitid
              );

              if (this.haveAPIDataList) {
                console.log('mobile api get con');

                var cookietime = this.APITimeList;
                var cookiemin = Math.floor(cookietime / 60);
                var cookiesec = cookietime % 60;
                var min: number = cookiemin;
                var sec: number = cookiesec;

                var apiListMobileView = this.APIAnswerList;

                // get answer from apix
                this.getAnswerFromLocalStorage(apiListMobileView);

                // console.log('you list');

                // console.log(this.groupQuestionList);

              }
              else if (tempi >= 0 && !this.haveAPIDataList) {
                console.log('mobile storage get con');
                // var cookietime = this.allTimeLimitList[tempi].time.split(':');
                // var cookiemin = parseInt(cookietime[0]);
                // var cookiesec = parseInt(cookietime[1]);
                // var min: number = cookiemin;
                // var sec: number = cookiesec;
                // this.question = this.allTimeLimitList[tempi].question
                var cookietime = this.allTimeLimitList[tempi].time;
                var cookiemin = Math.floor(cookietime / 60);
                var cookiesec = cookietime % 60;
                var min: number = cookiemin;
                var sec: number = cookiesec;

                // get answer from local storage
                var mobileStorageList = this.allTimeLimitList[tempi].answerlist;
                console.log('mb list');
                console.log(mobileStorageList);

                this.getAnswerFromLocalStorage(mobileStorageList);
              } else {
                var min: number = this.studytime - 1;
                var sec: number = 60;
              }

              this.reducequiztime = setInterval(() => {
                // min--;
                sec--;

                if (sec == 0) {
                  min--;
                  sec = 60;

                  if (min < 0) {
                    this.submit('', false, false, false);
                    min = 0;
                    sec = 0;
                    clearInterval(this.reducequiztime);
                  }
                }
                // mobile view
                var fmin = min.toString().padStart(2, '0');
                var fsec = sec.toString().padStart(2, '0');
                this.quiztimer = fmin + ':' + fsec;

                var tempStorage2 = min * 60 + sec;

                // save in cookie
                if (this.quiztimer != '00:00') {
                  var al = this.getAnswerList();
                  var tempData = {
                    unitid: this.unitid,
                    // forsinglequiz: false,
                    currentPage: this.currentPage,
                    // time: this.quiztimer,
                    time: tempStorage2,
                    // question: this.question,
                    answerlist: al,
                  };
                  // this.saveTimeInCookie(tempData);
                }
              }, 1000);
            }
            console.log('end mobile con');
          }
          // mobile view end


          console.log('you group question list');

          console.log(this.groupQuestionList);

          if (!this.isfeedback && !this.bygroup) {
            this.startTimer.emit(this.APITimeList);
          }
        } else {
          this.isLoading = false;
          this.messageService.openSnackBar(res.status || res.message, 'warn');
          setTimeout(() => {
            if (res.returncode == '404') {
              this.back();
            }
          }, 3000);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  answerList: any = [];

  getAnswerFromLocalStorage(ansList: any) {
    console.log('call count',ansList);
    for (let i = 0; i < this.question.length; i++) {
      for (var s = 0; s < ansList.length; s++) {
        if (this.question[i].quizid == ansList[s].quizid) {
          if (this.question[i].type == '001') {
            var index = this.question[i].answerlist.findIndex(
              (ans: any) => ans.answerid == ansList[s].answerid
            );
            this.question[i].selected = index;
          } else if (this.question[i].type == '002') {
            this.question[i].checkboxans = [];
            ansList[s].answerid.map((x: any) => {
              this.question[i].checkboxans.push(x);
            });
          } else if (this.question[i].type == '003') {
            console.log( " in local answer >>>>>", ansList[s].answerid)
            // this.question[i].selected = ansList[s].answerid;
            var resDropdown = this.fillinblankReplaceDisplay(
              this.question[i].question,
              ansList[s].answerid,
              true
            );
            this.question[i].formattedQuiz = resDropdown;
          } else if (this.question[i].type == '004') {
            ansList[s].answerid.map((item: any) => {
              var quesIndex = this.question[i].question.questions.findIndex(
                (x: any) => x.questionid == item.questionid
              );
              var ansIndex = this.question[i].answerlist.findIndex(
                (x: any) => x.answerid == item.answerid
              );
              if (quesIndex > -1) {
                this.question[i].question.questions[quesIndex].anscontanier =
                  []; //
                if (ansIndex > -1) {
                  this.question[i].question.questions[quesIndex].anscontanier =
                    [this.question[i].answerlist[ansIndex]];
                  this.question[i].answerlist.splice(ansIndex, 1);
                }
              }
            });
          } else if (this.question[i].type == '005') {

            var resDropdown = this.dropdownReplaceDisplay(
              this.question[i].question,
              this.question[i].answerlist,
              true,
              ansList[s].answerid,
              i
            );

            this.question[i].formattedQuiz = resDropdown;

          }
        }
      }
    }
  }

  radio(event: any, indexquestion: any, index: any) {
    if (this.bygroup) {
      indexquestion =
        indexquestion + this.currentPage * this.groupQuestionCount;
      this.question[indexquestion].selected = index;
    } else {
      this.question[indexquestion].selected = index;
      this.outputAnsToParent();
      // call api
      this.callAPIINParent();
      if (!this.bygroup && this.isMobileView) {
        this.callAPIForNoGroupMobileView();
      }
    }
  }

  checkbox(event: any, indexquestion: any, index: any) {
    if (this.bygroup) {
      indexquestion =
        indexquestion + this.currentPage * this.groupQuestionCount;
    }
    console.log(event.currentTarget.checked);
    var checked = event.currentTarget.checked;
    if (checked) {
      if (!this.question[indexquestion].checkboxans) {
        this.question[indexquestion].checkboxans = [
          this.question[indexquestion].answerlist[index].answerid,
        ];
      } else {
        this.question[indexquestion].checkboxans.push(
          this.question[indexquestion].answerlist[index].answerid
        );
      }
    } else {
      const id = this.question[indexquestion].checkboxans.indexOf(
        this.question[indexquestion].answerlist[index].answerid
      ); // 2
      const removedDrink = this.question[indexquestion].checkboxans.splice(
        id,
        1
      );
    }

    this.outputAnsToParent();
    this.callAPIINParent();
    if (!this.bygroup && this.isMobileView) {
      this.callAPIForNoGroupMobileView();
    }
    // console.log(this.question[indexquestion].checkboxans);
    // this.changedAnsList.emit(this.question);
  }

  cancel() {
    if (this.isfeedback) {
      this.back();
      return;
    }

    if (this.isFromInstructor) {
      this.submit('', false, false, false);
      this.loadingService.hide();
      return;
    }

    // var al = this.getAnswerList();
    // var haveAns = false;
    // for (var i = 0; i < al.length; i++) {
    //   if (al[i].answerid != '-1') {
    //     haveAns = true;
    //   }
    // }
    var al = this.getAnswerList();
    var haveAns = true;
    for (var i = 0; i < al.length; i++) {
      if (Array.isArray(al[i].answerid)) {
        if (this.answerList[i].answerid.length == 0) {
          haveAns = false;
        } else {
          al[i].answerid.filter(
            (ans: any) => {
              if (ans == null || ans == 'null' || ans == -1) {
                haveAns = false;
              }
              else if (ans.answerid == '') {
                haveAns = false;
              }
            }
          )
        }
      } else {
        if (al[i].answerid == '-1' || al[i].answerid == '') {
          haveAns = false;
        }
      }
    }

    var okGroupIndividualTime: boolean = true;
    var tempTimeList: any = [];
    if (this.grouptime) {
      this.groupQuestionList.map((x: any) => {
        tempTimeList.push(x.starttime);
      });
    }

    for (var i = 0; i < tempTimeList.length; i++) {
      if (tempTimeList[i] > 0 || tempTimeList[i] === '') {
        if (Array.isArray(this.answerList[i].answerid)) {
          // check for check box
          if (this.answerList[i].answerid.length == 0) {
            okGroupIndividualTime = false;
          } else {
            // check for matching
            this.answerList[i].answerid.filter(
              (ans: any) => {
                if (ans == null || ans == 'null' || ans == -1) {
                  haveAns = false;
                }
                else if (ans.answerid == '') {
                  okGroupIndividualTime = false;
                }
              }
            )
          }
        } else {
          // check for fill in the blank and radio
          if (this.answerList[i].answerid == '-1' || this.answerList[i].answerid == '') {
            okGroupIndividualTime = false;
          }
        }

      }
    }

    if ((!this.grouptime && !haveAns && !this.isFromInstructor)
      || (this.grouptime && !okGroupIndividualTime && !this.isFromInstructor)
    ) {
      let dialogRefA = this.dialog.open(ConfirmDialogTwoComponent, {
        minWidth: '300px',

        data: {
          message: 'You have not answered all the questions.',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRefA.afterClosed().subscribe((result) => { });
    } else {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: '300px',
        data: {
          message: 'Your progress will be submitted. Are you sure?',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          // this.back();
          console.log('you cancel con con');

          this.submit();
          this.loadingService.hide();
        }
        this.loadingService.hide();
      });
    }
  }

  // remove form cookie
  removeFromCookie() {
    clearInterval(this.reducequiztime);
    // var temp = this.cookieService.get('quizcachelist');
    // var temp = this.allinOneService.getQuizTimeLimit();
    var temp = localStorage.getItem('quizcachelist');
    this.allTimeLimitList = temp ? JSON.parse(temp) : [];
    var i = this.allTimeLimitList.findIndex(
      (x: any) => x.unitid == this.unitid
    );
    if (i >= 0) {
      this.allTimeLimitList.splice(i, 1);
      // this.cookieService.set('quizcachelist', JSON.stringify(this.allTimeLimitList));
      // this.allinOneService.setCookie(
      //   'quizcachelist',
      //   JSON.stringify(this.allTimeLimitList)
      // );
      localStorage.setItem(
        'quizcachelist',
        JSON.stringify(this.allTimeLimitList)
      );
    }
    // this.cookieService.set('quizcachelist', JSON.stringify(this.allTimeLimitList));
  }

  // submitQuiz
  submit(
    courseView: string = '',
    ask: boolean = true,
    askconfirm: boolean = false,
    checkHaveAllAnswers: boolean = true,
  ) {

    console.log('quiz submit fun');

    // return;
    this.answerList = [];

    if (this.isFromInstructor) {
      this.removeFromCookie();
      this.stopTimer.emit();
      this.issaveing = false;
      this.loadingService.hide();
      this.back();
      // this.complete(courseView);
      return;
    }

    // var al = this.getAnswerList();
    // var haveAns = false;
    // for (var i = 0; i < al.length; i++) {
    //   if (al[i].answerid != '-1') {
    //     haveAns = true;
    //   }
    // }

    // old code check have not answer
    // this.answerList = this.getAnswerList();
    // var haveAns = false;
    // for (var i = 0; i < this.answerList.length; i++) {
    //   if (Array.isArray(this.answerList[i].answerid)) {
    //     this.answerList[i].answerid.filter(
    //       (ans: any) => {
    //         if (ans.answerid != '') {
    //           haveAns = true;
    //         }
    //       }
    //     )
    //   } else {
    //     if (this.answerList[i].answerid != '-1' && this.answerList[i].answerid != '') {
    //       haveAns = true;
    //     }
    //   }
    // }
    // if (!haveAns && !this.isFromInstructor) {
    //   let dialogRefA = this.dialog.open(ConfirmDialogTwoComponent, {
    //     minWidth: '300px',
    //     data: {
    //       message: 'You have not answered all the questions.',
    //     },
    //     scrollStrategy: new NoopScrollStrategy(),
    //   });
    //   dialogRefA.afterClosed().subscribe((result) => { });
    //   return;
    // }
    // old code check have not answer end

    // now here

    this.answerList = this.getAnswerList();
    var haveAns = true;
    for (var i = 0; i < this.answerList.length; i++) {
      if (Array.isArray(this.answerList[i].answerid)) {
        // check for check box
        if (this.answerList[i].answerid.length == 0) {
          haveAns = false;
        } else {
          // check for matching
          this.answerList[i].answerid.filter(
            (ans: any) => {
              if (ans == null || ans == 'null' || ans == -1 || ans.trim() == "") {
                haveAns = false;
              }
              else if (ans.answerid == '') {
                haveAns = false;
              }
            }
          )
        }
      } else {
        // check for fill in the blank and radio
        if (this.answerList[i].answerid == '-1' || this.answerList[i].answerid == '') {
          haveAns = false;
        }
      }
    }

    var okGroupIndividualTime: boolean = true;
    var tempTimeList: any = [];
    if (this.grouptime) {
      this.groupQuestionList.map((x: any) => {
        tempTimeList.push(x.starttime);
      });
    }

    for (var i = 0; i < tempTimeList.length; i++) {
      if (tempTimeList[i] > 0 || tempTimeList[i] === '') {
        if (Array.isArray(this.answerList[i].answerid)) {
          // check for check box
          if (this.answerList[i].answerid.length == 0) {
            okGroupIndividualTime = false;
          } else {
            // check for matching
            this.answerList[i].answerid.filter(
              (ans: any) => {
                if (ans == null || ans == 'null' || ans == -1 || ans.trim() == "") {
                  haveAns = false;
                }
                else if (ans.answerid == '') {
                  okGroupIndividualTime = false;
                }
              }
            )
          }
        } else {
          // check for fill in the blank and radio
          if (this.answerList[i].answerid == '-1' || this.answerList[i].answerid == '') {
            okGroupIndividualTime = false;
          }
        }

      }
    }

    console.log('submit ok group con');
    console.log(okGroupIndividualTime);

    if ((!this.grouptime && !haveAns && !this.isFromInstructor && checkHaveAllAnswers)
      || (this.grouptime && !okGroupIndividualTime && !this.isFromInstructor && checkHaveAllAnswers)
    ) {
      let dialogRefA = this.dialog.open(ConfirmDialogTwoComponent, {
        minWidth: '300px',
        data: {
          message: 'You have not answered all the questions.',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRefA.afterClosed().subscribe((result) => { });
      return;
    }

    // for (let i = 0; i < this.question.length; i++) {
    //   if (this.question[i].type == '001') {
    //     this.answerList.push({
    //       quizid: this.question[i].quizid,
    //       answerid:
    //         this.question[i].selected == '-1'
    //           ? '-1'
    //           : this.question[i].answerlist[this.question[i].selected].answerid,
    //     });
    //   } else if (this.question[i].type == '003') {
    //     this.answerList.push({
    //       quizid: this.question[i].quizid,
    //       answerid: this.question[i].selected.toString().trim(),
    //     });
    //   } else if (this.question[i].type == '002') {
    //     this.answerList.push({
    //       quizid: this.question[i].quizid,
    //       answerid: this.question[i].checkboxans
    //         ? this.question[i].checkboxans
    //         : [],
    //     });
    //   } else {
    //     var tempArray: any[] = [];
    //     for (var j = 0; j < this.question[i].question.questions.length; j++) {
    //       var tempObj = {
    //         questionid: this.question[i].question.questions[j].questionid,
    //         answerid:
    //           this.question[i].question.questions[j].anscontanier.length > 0
    //             ? this.question[i].question.questions[j].anscontanier[0]
    //               .answerid
    //             : '',
    //       };
    //       tempArray.push(tempObj);
    //     }
    //     this.answerList.push({
    //       quizid: this.question[i].quizid,
    //       answerid: tempArray,
    //     });
    //   }
    // }

    console.log(this.answerList);
    // return;

    // var checkNotAnswer = this.answerList.filter(
    //   (ans: any) => ans.answerid == '-1' || ans.answerid.length == 0
    // );

    // old code
    // if (checkNotAnswer.length > 0 && courseView == '' && ask) {
    //   let dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //     minWidth: '300px',
    //     data: {
    //       message:
    //         'You have not answered all the questions. Are you sure to submit your answers?',
    //     },
    //     scrollStrategy: new NoopScrollStrategy(),
    //   });

    //   dialogRef.afterClosed().subscribe((result) => {
    //     if (result) {
    //       this.issaveing = true;
    //       this.loadingService.show();
    //       this.courseService
    //         .submitQuiz(this.unitid, this.courseid, this.answerList, '')
    //         .subscribe(
    //           (res: any) => {
    //             if (res.returncode == '300') {
    //               this.removeFromCookie();
    //               this.stopTimer.emit();
    //               this.issaveing = false;
    //               this.loadingService.hide();
    //               this.complete(courseView);
    //               this.messageService.openSnackBar(
    //                 'Submitted successfully.',
    //                 'success'
    //               );
    //             } else {
    //               this.messageService.openSnackBar(
    //                 res.status || res.message || res.error,
    //                 'warn'
    //               );
    //               this.issaveing = false;
    //               this.loadingService.hide();
    //               setTimeout(() => {
    //                 if (res.returncode == '404') {
    //                   this.back();
    //                 }
    //               }, 3000);
    //             }
    //           },
    //           (err) => {
    //             this.issaveing = false;
    //             this.loadingService.hide();
    //           }
    //         );
    //     }
    //   });
    // } else { }
    if (askconfirm) {
      let confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        minWidth: '300px',
        data: {
          message: 'Are you sure to submit your answers?',
        },
        scrollStrategy: new NoopScrollStrategy(),
      });
      confirmDialog.afterClosed().subscribe((r: any) => {
        if (r) {
          this.issaveing = true;
          this.loadingService.show();
          this.courseService
            .submitQuiz(this.unitid, this.courseid, this.answerList, '')
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.removeFromCookie();
                  this.issaveing = false;
                  this.loadingService.hide();
                  this.complete(courseView);
                  this.messageService.openSnackBar(
                    'Submitted successfully.',
                    'success'
                  );
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message || res.error,
                    'warn'
                  );
                  this.issaveing = false;
                  this.loadingService.hide();
                  setTimeout(() => {
                    if (res.returncode == '404') {
                      this.back();
                    }
                  }, 3000);
                }
              },
              (err) => {
                this.issaveing = false;
                this.loadingService.hide();
              }
            );
        }
      });
    } else {
      this.dialog.closeAll();
      this.issaveing = true;
      this.loadingService.show();
      this.courseService
        .submitQuiz(this.unitid, this.courseid, this.answerList, '')
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.stopTimer.emit();
              this.removeFromCookie();
              this.issaveing = false;
              this.loadingService.hide();
              this.complete(courseView);
              this.messageService.openSnackBar(
                'Submitted successfully.',
                'success'
              );
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
              this.issaveing = false;
              this.loadingService.hide();
              setTimeout(() => {
                if (res.returncode == '404') {
                  this.back();
                }
              }, 3000);
            }
          },
          (err) => {
            this.issaveing = false;
            this.loadingService.hide();
          }
        );
    }

  }

  complete(courseView: string = '') {
    if (this.smallscreenview) {
      this.completequiz.emit(courseView);
    } else {
      this.back();
    }
  }

  // answerChanged(quiz : any = this.question) {
  //   console.log('call');
  //   this.ansChangedList.emit(quiz);
  // }

  back() {
    if (this.smallscreenview) {
      this.cancelquiz.emit();
    } else {
      if (this.isFromInstructor) {
        if (this.isPublished) {
          this.router.navigateByUrl(
            `preview/courses/${this.courseid}/lectures/${this.unitid}/published`
          );
        } else {
          this.router.navigateByUrl(
            `preview/courses/${this.courseid}/lectures/${this.unitid}/unpublished`
          );
        }

      } else {
        this.router.navigateByUrl(
          `courses/${this.courseid}/lectures/${this.unitid}`
        );
      }


    }
  }

  restoreDrop(event: any) {
    if (event.previousContainer === event.container) {
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        0
      );
    }
    if (!this.bygroup) {
      this.outputAnsToParent();
      this.callAPIINParent();
    }
    if (!this.bygroup && this.isMobileView) {
      this.callAPIForNoGroupMobileView();
    }
  }

  drop(event: any) {
    var previousIndex = event.item.data;
    if (event.previousContainer === event.container) {

    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        previousIndex,
        0
      );
      if (event.container.data.length > 1) {
        transferArrayItem(
          event.container.data,
          event.previousContainer.data,
          1,
          previousIndex
        );
      }
      // if (!this.bygroup) {
      //   this.outputAnsToParent();
      //   this.callAPIINParent();
      // }
      // if (!this.bygroup && this.isMobileView) {
      //   this.callAPIForNoGroupMobileView();
      // }
    }
    if (!this.bygroup) {
      this.outputAnsToParent();
      this.callAPIINParent();
    }
    if (!this.bygroup && this.isMobileView) {
      this.callAPIForNoGroupMobileView();
    }
  }

  imageViewer(img: any, index: number) {
    var imgArray: any = [];
    img.map((item: any) => {
      imgArray.push({
        mainUrl: item.imageurl,
        thumbnailUrl: item.imageurl,
        description: '',
      });
    });
    const viewer = new ImageViewer({
      images: imgArray,
      currentSelected: index,
      stretchImages: true,
      isZoomable: false,
    });
  }

  matchingImageViewer(img: any) {
    var imgArray: any = [];
    imgArray.push({
      mainUrl: img,
      thumbnailUrl: img,
      description: '',
    });
    const viewer = new ImageViewer({
      images: imgArray,
      // currentSelected: index,
      stretchImages: true,
      isZoomable: false,
    });
  }

  saveTimeInCookie(data: any) {
    console.log('call save fun');
    this.allTimeLimitList = [];
    // var temp = this.cookieService.get('quizcachelist');

    // var temp = this.allinOneService.getQuizTimeLimit();
    var temp = localStorage.getItem('quizcachelist');
    this.allTimeLimitList = temp ? JSON.parse(temp) : [];
    var i = this.allTimeLimitList.findIndex(
      (x: any) => x.unitid == data.unitid
    );
    if (i >= 0) {
      this.allTimeLimitList.splice(i, 1);
    }
    this.allTimeLimitList.push(data);
    // this.cookieService.delete()
    // this.cookieService.set('quizcachelist', JSON.stringify(this.allTimeLimitList));

    // this.allinOneService.setCookie(
    //   'quizcachelist',
    //   JSON.stringify(this.allTimeLimitList)
    // );
    localStorage.setItem(
      'quizcachelist',
      JSON.stringify(this.allTimeLimitList)
    );
  }

  formatTimeMinSec(sec: any) {
    const formattedMinutes = Math.floor(sec / 60)
      .toString()
      .padStart(2, '0');
    const formattedSeconds = (sec % 60).toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  startCountdown(groupQuestion: any) {
    console.log('error fun');
    console.log(groupQuestion.starttime);

    var startTime = '';

    if (groupQuestion.starttime !== '') {
      var tempmin = Math.floor(parseInt(groupQuestion.starttime) / 60);
      var tempsec = parseInt(groupQuestion.starttime) % 60;

      var tempfmin = tempmin.toString().padStart(2, '0');
      var tempfsec = tempsec.toString().padStart(2, '0');
      startTime = tempfmin + ':' + tempfsec;
    } else {
      startTime = '';
    }

    // let startTime = groupQuestion.starttime;
    let minutes = groupQuestion.timelimit;
    let remainingSeconds = 0;

    if (startTime !== '') {
      const [startMinutes, startSeconds] = startTime.split(':').map(Number);
      remainingSeconds = startMinutes * 60 + startSeconds;
    } else {
      console.log('error con main');

      remainingSeconds = minutes * 60;
    }

    // remainingSeconds = groupQuestion.starttime;

    this.timeInterval = setInterval(() => {
      remainingSeconds--;

      const formattedMinutes = Math.floor(remainingSeconds / 60)
        .toString()
        .padStart(2, '0');
      const formattedSeconds = (remainingSeconds % 60)
        .toString()
        .padStart(2, '0');

      // groupQuestion.starttime = `${formattedMinutes}:${formattedSeconds}`;
      groupQuestion.starttime = remainingSeconds;
      this.changeGroupLoading = false;
      if (remainingSeconds <= 0) {
        console.log('s con');
        console.log(this.currentPage);
        console.log(this.groupQuestionList.length);

        clearInterval(this.timeInterval);
        groupQuestion.disabled = true;
        // groupQuestion.starttime = '00:00';
        groupQuestion.starttime = 0;

        if (this.currentPage == this.groupQuestionList.length - 1) {
          console.log('your con your con');
          // this.submit('', false);
        }
        console.log('cg 5');

        this.changeGroup();
      }
      // if (groupQuestion.starttime != '00:00') {
      if (groupQuestion.starttime != 0) {
        // get time for single group
        var tempTimeList: any = [];
        var timeList: any = [];
        this.groupQuestionList.map((x: any) => {
          tempTimeList.push(x.starttime);
        });

        var al = this.getAnswerList();
        var tempData2 = {
          unitid: this.unitid,
          currentPage: this.currentPage,
          time: tempTimeList,
          answerlist: al,
        };
        // this.saveTimeInCookie(tempData2);
      }
    }, 1000);
  }

  changeGroup(next: boolean = true, index?: number) {
    console.log('this fun');
    this.isfirstTime = false;

    if (index === 0 || index) {
      this.currentPage = index;
      this.changeGroupLoading = true;
    } else {
      if (next) {
        if (this.currentPage < this.groupQuestionList.length - 1) {
          this.currentPage += 1;
          this.changeGroupLoading = true;
        }
        console.log('next page');
      } else {
        if (this.currentPage > 0) {
          this.currentPage -= 1;
          this.changeGroupLoading = true;
        }
        console.log('previous page');
      }
    }

    console.log('1');

    clearInterval(this.timeInterval);
    console.log('2');
    console.log(this.groupQuestionList);
    console.log('c');
    console.log(this.currentPage);
    console.log(this.groupQuestionList[this.currentPage]);

    if (
      !this.groupQuestionList[this.currentPage].disabled &&
      this.grouptime &&
      this.havequiztimer
    ) {
      console.log('e con 1');
      console.log('cd 2');
      this.startCountdown(this.groupQuestionList[this.currentPage]);
    } else {
      console.log('e con 2');
      this.changeGroupLoading = false;
    }

    // Nag
    var al = this.getAnswerList();
    var tempTimeList: any = [];
    if (this.grouptime) {
      this.groupQuestionList.map((x: any) => {
        tempTimeList.push(x.starttime);
      });
    }

    var tempData = {
      unitid: this.unitid,
      // forsinglequiz: false,
      currentPage: this.currentPage,
      time: this.grouptime ? tempTimeList : this.quiztimer,
      // groupQuestionList: this.groupQuestionList,
      answerlist: al,
    };
    // this.saveTimeInCookie(tempData);
    this.allinOneService.scrollTo('container');
    this.addQuizCache(tempData);
  }

  addQuizCache(d: any, onClose: boolean = false) {
    if (!this.isFromInstructor) {
      const usersyskey = this.allinOneService.getUserId();
      var strTimeList: any = [];
      var timeForGroupAll: any = '';

      var temptime = d.time;
      console.log('your time');
      console.log(temptime);

      if (this.studytime != 0 || this.studytime! < 0) {
        if (this.bygroup && this.grouptime) {
          for (var i = 0; i < temptime.length; i++) {
            // || temptime[i] === 0
            if (temptime[i] !== '') {
              // if (temptime[i] != '') {
              console.log('time value con');

              strTimeList.push(temptime[i].toString());
            } else {
              console.log('time blank con');

              var studysec: number = this.studytime * 60;
              var strt: string = studysec.toString();
              strTimeList.push(strt);
              // strTimeList.push('');
            }
          }
        } else if (this.bygroup && !this.grouptime) {
          var t = temptime.split(':');
          var min = parseInt(t[0]);
          var sec = parseInt(t[1]);
          timeForGroupAll = min * 60 + sec;
        } else if (!this.bygroup && this.isMobileView) {
          var t = temptime.split(':');
          var min = parseInt(t[0]);
          var sec = parseInt(t[1]);
          timeForGroupAll = min * 60 + sec;
        }
      }

      var apidata = {
        answerlist: d.answerlist,
        unitid: d.unitid,
        courseid: this.courseid,
        currentpage: d.currentPage.toString(),
        usersyskey: usersyskey,
        time:
          this.studytime <= 0
            ? ''
            : this.bygroup && this.grouptime
              ? strTimeList
              : timeForGroupAll.toString(),
      };
      if (!onClose) {
        this.quizCacheSubscription && this.quizCacheSubscription.unsubscribe();
        this.quizCacheSubscription = this.courseService
          .addQuizCache(apidata)
          .subscribe((res: any) => {
            if (res.returncode == 300) {

            } else {
              this.saveTimeInCookie(apidata);
            }
          },
            (err: any) => {
              this.saveTimeInCookie(apidata);
            }
          );

      } else {
        this.courseService.addQuizCacheOnClose(apidata);
      }
    }
  }

  // dropDown: any = [];
  // submitAnswerList: any = [[],[]];

  dropdownReplaceDisplay(
    question: string,
    answer: any,
    isfeedback: boolean,
    answeridlist: any,
    questionindex: number,
  ) {
    let dropdownDisplay: any = [];
    let dropDownIndex: number = 0;
    let typeList = ["{{}}", "___", "____", "_____", "______"];
    const diffpart = question.includes('{{}}');
    const splitPattern = /(___+|____+)/g;
    let parts: string[] = diffpart? question.split(/({{}})/) : question.split(splitPattern).filter(part => part !== '');
    this.question[questionindex].answerid = [];
    if(answer == undefined || answer.length == 0) {
      answer = []
      parts.map((part) => {
        if( typeList.includes(part) ) {
          answer.push('')
        }
      })
    } else if (!Array.isArray(answer)) {
      console.log(' answer id is not in array , ???')
      answer  = [answer]
    }
    parts.map((part, index) => {
      if (!typeList.includes(part)) {
        dropdownDisplay.push({
          type: '001',
          text: part,
          option: null,
          index: index,
          selectOption: '',
        });
      } else {
        answer.map((ans: any, i: any) => {
          if (dropDownIndex == i) {
            this.question[questionindex].answerid.push(
              isfeedback ? answeridlist[i] : null
            );
            dropdownDisplay.push({
              type: '002',
              text: null,
              option: ans.blankanslist,
              index: dropDownIndex,
              selectOption: isfeedback ? answeridlist[i] : null,
            });
          }
        });
        dropDownIndex++;
      }
    });
    return dropdownDisplay;
    
    // let parts: string[] = question.split(/({{}})/);
    // this.question[questionindex].answerid = []
    // parts.map((part, index) => {
    //   if (part !== '{{}}') {
    //     dropdownDisplay.push({
    //       type: '001',
    //       text: part,
    //       option: null,
    //       index: index,
    //       selectOption: '',
    //     });
    //   } else {
    //     answer.map((ans: any, i: any) => {
    //       if (dropDownIndex == i) {
    //         this.question[questionindex].answerid.push(isfeedback ? answeridlist[i] : null)
    //         // this.submitAnswerList[0].push(isfeedback ? answeridlist[i] : null);
    //         dropdownDisplay.push({
    //           type: '002',
    //           text: null,
    //           option: ans.blankanslist,
    //           index: dropDownIndex,
    //           selectOption: isfeedback ? answeridlist[i] : null,
    //         });
    //       }
    //     });
    //     dropDownIndex++;
    //   }
    // });
    // return dropdownDisplay;
  }

  fillinblankReplaceDisplay(
    question: string,
    answer: any,
    isfeedback: boolean,
  ) {
    let blankDisplay: any = [];
    let blankIndex: number = 0;
    let typeList = ["{{}}", "___", "____", "_____", "______"];
    const diffpart = question.includes('{{}}');
    const splitPattern = /(___+|____+)/g;
    
    let parts: string[] = diffpart? question.split(/({{}})/) : question.split(splitPattern).filter(part => part !== '');

    if(answer == undefined || answer.length == 0) {
      answer = []
      parts.map((part) => {
        if( typeList.includes(part) ) {
          answer.push('')
        }
      })
    } else if (!Array.isArray(answer)) {
      console.log(' answer id is not in array , ???')
      answer  = [answer]
    }
    // console.log(answer, "should be in array format")
    parts.map((part) => {
      if ( !typeList.includes(part) ) {
        blankDisplay.push({
          type: '001',
          text: part,
        });
      } else {
        console.log('is in feedback', isfeedback)
        isfeedback
          ? answer.map((ans: any, i: any) => {
            if (blankIndex == i) {
              blankDisplay.push({
                type: '002',
                text: part,
                index: blankIndex,
                selected: ans
              });
            }
          })
          : blankDisplay.push({
            type: '002',
            text: part,
            index: blankIndex,
            selected: ''
          });
        blankIndex++;
      }
    });
    return blankDisplay;
  }

  onDropDownChange(event: any, index: any, selectedquestion: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;
    selectedquestion.answerid.map((item: any, i: any) => {
      if (index == i) {
        selectedquestion.answerid[i] = (selectedIndex - 1).toString();
      }
    });

    // add quiz cache for drop down

    if (!this.bygroup) {
      this.outputAnsToParent();
      this.callAPIINParent();
    }
    if (!this.bygroup && this.isMobileView) {
      this.callAPIForNoGroupMobileView();
    }
  }

  areListsEqual(list1: string[], list2: string[], casesensitive: boolean): boolean {
    if (list1.length !== list2.length) {
      console.log('wrong in length')
      return false;
    }
    var lowerlist1 = list1
    var lowerlist2 = list2
    if (!casesensitive) {
      lowerlist1 = list1.map(str => str.toLowerCase());
      lowerlist2 = list2.map(str => str.toLowerCase());
    }

    // Sort both lists to ensure order does not matter
    lowerlist1.sort();
    lowerlist2.sort();
    for (let i = 0; i < lowerlist1.length; i++) {
      if (lowerlist1[i] !== lowerlist2[i]) {
        return false;
      }
    }

    return true;
  }
}
