import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit, OnDestroy {

  allData: any[] = [];
  displayedColumns: any;
  showVideoDetails: MatTableDataSource<any> = new MatTableDataSource(this.allData);

  searchText: string = '';
  isFocus: boolean = false;
  isLoading: boolean = false;
  paginate: boolean = false;
  courseid: string = "";
  unitid: string = '';
  unitname: string = '';

  datacount: string = '0';
  getListSubscription!: Subscription;

  coursename : string = '';

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    const coid = this.route.snapshot.params['courseid'];
    this.courseid = coid;
    const uid = this.route.snapshot.params['unitid'];
    this.unitid = uid;
    this.unitname = this.route.snapshot.params['unitname'];
  }

  //  Details

  // user id, user name, time, last viewed date, last viewed time

  ngOnInit(): void {
    this.displayedColumns = ['userid', 'username', 'currenttime', 'lastviewdate'];
    this.getVideoTracking();
  }

  ngOnDestroy(): void {
    this.getListSubscription && this.getListSubscription.unsubscribe();
  }

  test() {
    // var t = '11:55:37 AM';
    var t = '03:31:59 PM';

    this.f12to24(t);
    console.log(this.f12to24(t));
    
  }

  f12to24(time12hr: string) {
    if (time12hr != undefined && time12hr != '' && time12hr != '-' ) {
      
        const [time, period] = time12hr.split(' ');
        const [hours, minutes, seconds] = time.split(':');

        let hours24 = parseInt(hours);

        if (period === 'PM' && hours24 !== 12) {
          hours24 += 12;
        } else if (period === 'AM' && hours24 === 12) {
          hours24 = 0;
        }        

        const formattedHours24 = hours24.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        const formattedSeconds = seconds.padStart(2, '0');

        return `${formattedHours24}:${formattedMinutes}:${formattedSeconds}`;
    }
    return '';
  }

  getVideoTracking() {
    this.isLoading = true;
    const data = {
      courseid: this.courseid,
      unitid: this.unitid,
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.courseService.getVideoTracking(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.allData = res.datalist;
          this.datacount = this.allData.length.toString();
          this.coursename = res.coursename;
          this.showVideoDetails = new MatTableDataSource(this.allData);
          console.log('your data is');

          console.log(this.allData);

        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
      }
    )
  }

  goToVideo() {
    // this.router.navigateByUrl(`instructor/courses/${this.courseid}/class`);
    this.router.navigateByUrl(`/instructor/courses/${this.courseid}/videos`);
  }

  sortData(sort: Sort) {
    const data = this.allData;
    if (!sort.active || sort.direction === '') {
      this.allData = data;
      return;
    }

    this.allData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'currenttime':
          return this.allinoneService.compare(a.currenttime, b.currenttime, isAsc);
        case 'lastviewdate':
          return this.allinoneService.compare(a.lastviewdate + this.f12to24(a.lastviewtime), b.lastviewdate + this.f12to24(b.lastviewtime), isAsc);
        // case 'lastviewtime':
        //   return this.allinoneService.compare(a.lastviewtime, b.lastviewtime, isAsc);
        default:
          return 0;
      }
    });
    this.showVideoDetails = new MatTableDataSource(this.allData);
  }

  // downloadSheet() {
  //   const classname = this.classname;
  //   const classcode = this.classcode;
  //   var data: any = [];
  //   var name =
  //     classname + ' ' + '(' + classcode + ')' + ' ' + 'Students' + '.xlsx';
  //   this.allStudent.map((student: any) => {
  //     data.push({
  //       'User ID': student.userid,
  //       'Student ID': student.studentcode,
  //       'Student Name': student.studentname,
  //       Remark: student.remark,
  //     });
  //   });
  //   this.allinoneService.exportExcel(data, name);
  // }

  downloadSheet() {
    var name = 'Video tracking.xlsx';
    var data : any = [];
    this.allData.map((d:any) => {
      data.push({
        'User ID': d.userid,
        'User Name' : d.username,
        'Current Time' : d.currenttime,
        'Last Viewed Date' : d.lastviewdate != '-' ? this.allinoneService.formatDBToShow(d.lastviewdate) + ' ' + d.lastviewtime : '-' ,
      });
    });
    this.allinoneService.exportExcel(data, name)
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showVideoDetails.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = '';
    this.showVideoDetails.filter = '';
  }

}
