<div class="container-fluid mt-2 mb-2 student-container">
    <div class="co-title d-flex align-items-center">
        <!-- <i class="fas fa-angle-left" (click)="goBackToClass()" style="cursor: pointer;"></i>  -->
        <!-- <mat-icon (click)="goBackToClass()" style="cursor: pointer; margin-right: 10px;">arrow_back</mat-icon> -->
        <!-- {{classnames}} ({{classcode}}) -->
    </div>
    <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
        <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-2">
            <div>
                <button color="primary" class="me-1 ms-0" (click)="newNoti()" mat-flat-button>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    New Notification
                </button>
            </div>
            <div class="d-flex flex-wrap align-items-center">
                <div class="me-2">
                    Total :<b> {{allNotiList.length}}</b>
                </div>
                <div class="input-group" style="width: 260px !important;">
                    <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                        (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                    <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                        *ngIf="searchText" (click)="clear()">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button"
                    style="margin-left: 10px;">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="mat-table-container">
        <table mat-table #table [dataSource]="shownoti" multiTemplateDataRows matSort
            (matSortChange)="sortData($event)" class="mat-elevation-z1" style="width: 100%;">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
                    Type
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                    {{element.type == '001' ? 'Class' : 'Student'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">
                    Title
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                    {{element.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="message" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by message">
                    Message
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;max-width: 220px;" (click)="toggleRow(element)">
                    {{element.message}}
                </td>
            </ng-container>

            <ng-container matColumnDef="userid" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by userid">
                    User ID
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                    {{element.type == '002' ? element.userid : '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="username" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by username">
                    User Name
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                    {{element.type == '002' ? element.username : '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="submitteddate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by submitte ddate">
                    Date
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                    {{ element.sentdate ? allinoneService.formatDBToShow(element.sentdate) + ' ' + element.senttime : ''}}
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="student">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by student">
                    Student
                </th>
                <td mat-cell *matCellDef="let element" style="border: none;" (click)="toggleRow(element)">
                    {{element.student}}
                </td>
            </ng-container> -->
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
            <tr class="mat-row" *matNoDataRow>
                <td colspan="6">
                    <span class="nodata" *ngIf="!isLoading">Empty</span>
                    <span class="nodata" *ngIf="isLoading">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                    </span>
                </td>
            </tr>
        </table>
        <!-- <div  *ngIf="paginate == true"> -->
        <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        <!-- </div> -->
    </div>
</div>