<div class="mb-3 d-flex justify-content-between flex-wrap">
  <div>
    <h2 class="mb-0"><strong>{{isEditing ? 'Edit Question' : 'New Question'}}</strong></h2>
  </div>
  <div>
    <select class="form-select" [(ngModel)]="type">
      <option *ngFor="let option of quesitontypes" [selected]="option.id == type" [value]="option.id">
        {{option.name}}
      </option>
    </select>
  </div>
</div>


<ng-container>
  <div class="mb-2">
    <label class="mb-2"><strong>Question</strong></label>
    <angular-editor [(ngModel)]="question" [config]="config" placeholder=" "></angular-editor>
  </div>
</ng-container>
<ng-container *ngIf="type == '001' || type == '002'">
  <div class="mb-2 d-flex align-items-center flex-wrap">
    <div class="me-2">
      <strong>Answers</strong>
    </div>
  </div>
  <div *ngFor="let ans of answers; let index = index">
    <div class="form-check mb-2 ps-0">
      <div class="d-flex">

        <div class="mb-2 w-100">
          <angular-editor [(ngModel)]="ans.answer" [config]="ans.config" placeholder="Add an answer" class="invalid">
          </angular-editor>
        </div>

        <div>
          <button mat-icon-button color="primary" (click)="removeAns(index)">
            <i class="fa fa-trash-o" aria-hidden="true" style="color: red;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="d-flex align-items-center justify-content-between">
  <div>
    <button mat-button color="primary" (click)="onfocus()"
      *ngIf="answers.length < 5 && (type == '001' || type == '002')">
      <i class="fa fa-plus" aria-hidden="true"></i>
      Answer</button>

  </div>
  <div class="actions-button">
    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()">
      Cancel
    </button>

    <app-button-loader [button_text]="'Save'" [isStillupload]="isStillUpload" (click)="save()">
    </app-button-loader>
  </div>
</div>