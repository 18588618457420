<div class="d-flex align-items-center justify-content-between mb-4">
  <div>
    <h2 class="mb-0">{{course.coursename}}</h2>
  </div>
  <div class="d-flex align-items-center">
    <mat-icon (click)="dismiss()" class="cus-point">close</mat-icon>
  </div>
</div>
<div>
  <input type="text" class="form-control form-control-sm" placeholder="Select Course" aria-label="Number" matInput
    class="form-control" [matAutocomplete]="auto" id="course" name="course" #trigger="matAutocompleteTrigger" [formControl] = "myControl" (click)="showList()">

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let course of allcourses | appFilter:myControl.value" (click)="selectCourse(course)">
      {{course.coursename}}
    </mat-option>
  </mat-autocomplete>
</div>

<div class = "my-3 pre-title" *ngIf="course.prerequisites.length > 0">
  Prerequisites
</div>
<div class="prerequisite-container" *ngIf="course.prerequisites.length > 0">
  <div *ngFor="let course of course.prerequisites; let i = index" class = "card card-body d-flex flex-row justify-content-between align-items-center mb-2">
    <div>
      {{course.coursename}}
    </div>
    <div>
      <mat-icon (click)="remove(i)">delete</mat-icon>
    </div>
  </div>
</div> 

<!-- <div class="prerequisite-container nodata" *ngIf="course.prerequisites.length == 0">
  Empty
</div> -->

<div class="actions-button mt-3">
  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled] = "isLoading">
      Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="save()">
  </app-button-loader>

</div>