<app-breadcrumb [s_breadcrumb]="'Feedback Data'"></app-breadcrumb>
<div class="container-fluid mt-2 mb-2">
  <div class="container-fluid mt-2 mb-2 student-container">
    <div class="d-flex align-items-center justify-content-end mt-3 mb-3">
      <div class="me-2">
        <select class="form-select" [(ngModel)]="channelid" required #chlid="ngModel"
          (ngModelChange)="channelChange($event)">
          <option value="" *ngIf="role == 'admin'">KHub</option>
          <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.name}}</option>
        </select>
      </div>
      <div class="me-2">
        <select class="form-select" [(ngModel)]="courseid" #cid="ngModel" id="courseid" name="courseid"
          (ngModelChange)="courseChange()" required [disabled]="gettingCourses">
          <option value="" *ngIf="courses.length == 0">-</option>
          <option *ngFor="let course of courses" [value]="course.courseid">{{course.coursename}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingCourses"></mat-progress-bar>
      </div>
      <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
    <table mat-table #table [dataSource]="feedbackTableData" matSort class="mat-elevation-z1" style="width: 100%;" *ngIf="!gettingResult">
      <ng-container matColumnDef="question">

        <th mat-header-cell *matHeaderCellDef
          style="width: 500px;"></th>
        <td mat-cell *matCellDef="let element">
          {{element.question}}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="i.toString()" *ngFor="let i of [1, 2, 3, 4, 5]; let index = index">

        <th mat-header-cell *matHeaderCellDef style="padding-right: 0px !important; width: 100px;">
          <span *ngIf="i == 1">Strongly Disagree</span>
          <span *ngIf="i == 2">Disagree</span>
          <span *ngIf="i == 3">Somewhat Agree</span>
          <span *ngIf="i == 4">Agree</span>
          <span *ngIf="i == 5">Strongly Agree</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.answerlist[index].count == 0 ? '-' : element.answerlist[index].count}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="gettingResult" class="nodata">
      <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
    </div>
  </div>
  <!-- <mat-tab-group mat-align-tabs="start">
    <mat-tab>
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">people</mat-icon>
        Templates
      </ng-template>

      <div class="container-fluid mt-2 mb-2 student-container">
        Templates
      </div>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="lable-tab">
        <mat-icon class="example-tab-icon mx-1">people</mat-icon>
        Feedbacks
      </ng-template>

      <div class="container-fluid mt-2 mb-2 student-container">
        <div class="d-flex align-items-center justify-content-end mt-3 mb-3">
          <div class="me-2">
            <select class="form-select" [(ngModel)]="channelid" required #chlid="ngModel"
              (ngModelChange)="channelChange($event)">
              <option value="" *ngIf="role == 'admin'">KHub</option>
              <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.name}}</option>
            </select>
          </div>
          <div class="me-2">
            <select class="form-select" [(ngModel)]="courseid" #cid="ngModel" id="courseid" name="courseid"
              (ngModelChange)="courseChange()" required [disabled]="gettingCourses">
              <option value="" *ngIf="courses.length == 0">-</option>
              <option *ngFor="let course of courses" [value]="course.courseid">{{course.coursename}}</option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingCourses"></mat-progress-bar>
          </div>
          <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <table mat-table #table [dataSource]="feedbackTableData" matSort class="mat-elevation-z1" style="width: 100%;" *ngIf="!gettingResult">
          <ng-container matColumnDef="question">

            <th mat-header-cell *matHeaderCellDef
              style="width: 500px;"></th>
            <td mat-cell *matCellDef="let element">
              {{element.question}}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="i.toString()" *ngFor="let i of [1, 2, 3, 4, 5]; let index = index">

            <th mat-header-cell *matHeaderCellDef style="padding-right: 0px !important; width: 100px;">
              <span *ngIf="i == 1">Strong Disgree</span>
              <span *ngIf="i == 2">Disgree</span>
              <span *ngIf="i == 3">Somewhat Agree</span>
              <span *ngIf="i == 4">Agree</span>
              <span *ngIf="i == 5">Strong Agree</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.answerlist[index].count == 0 ? '-' : element.answerlist[index].count}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="gettingResult" class="nodata">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group> -->

</div>