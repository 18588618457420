import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-update-grade-result',
  templateUrl: './update-grade-result.component.html',
  styleUrls: ['./update-grade-result.component.scss'],
})
export class UpdateGradeResultComponent implements OnInit {
  isLoading: boolean = false;

  grade!: number;
  totalgrade!: number;
  student: string = '';
  id: string = '';
  unitname: string = '';
  type!: number;

  constructor(
    private dialogRef: MatDialogRef<UpdateGradeResultComponent>,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.student = data.student;
    this.id = data.id;
    this.totalgrade = parseInt(data.questioncount);
    this.unitname = data.unitname;
    this.grade = parseInt(data.grade);
    this.type = parseInt(data.type);
  }

  ngOnInit(): void {}

  dismiss() {
    this.dialogRef.close();
  }

  save() {
    if (this.isLoading) {
      return;
    }

    if ((this.grade && this.grade < 0) || this.totalgrade < this.grade) {
      this.messageService.openSnackBar('Please enter a proper grade.', 'warn');
      return;
    }
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    const temp = this.grade;
    const grade = temp == null ? '' : temp.toString();
    this.courseService.updateGradeResult(this.type, this.id, grade).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dialogRef.close({
            grade: grade,
          });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }
}
