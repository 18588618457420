
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './../../shared/components/confirm-dialog/confirm-dialog.componet';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FileUploadComponent } from '../../shared/components/file-upload/file-upload.component';


@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit, OnDestroy {

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;
  @Input() editMembers: any = [{}];
  showmembers !: MatTableDataSource<any>;

  channelid: string = "";
  displayedColumns: any;
  channelname: string = "";
  gettingMember: boolean = false;
  // members: any;
  channelId: string = ""
  isadding: boolean = false;
  role: string = "";
  remark: string = "";
  editPage: boolean = false;
  memberPage: boolean = true;
  members: any[] = [];
  gettingEditMember: boolean = true;
  edit_stat: boolean = true;
  edit: string = "";
  chl_text: string = "";
  userid: string = "";
  kuserid: string = "";
  paginate: boolean = false;
  datacount: string = "0";
  isFocus: boolean = false;
  searchText: string = "";
  adminrole: string = '';
  isRootAdmin: boolean = false;

  memberlist: any[] = [];
  gettingmembertype :boolean = false;

  // Import Excel 000
  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;
  importData: any;
  statusInterval: any;


  constructor(private router: Router,
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private _liveAnnouncer: LiveAnnouncer

  ) {
    this.channelid = this.route.snapshot.params['channelid'];
    this.route.data.subscribe((data) => {
      this.adminrole = data.role;
    });
    if ((this.adminrole == 'admin' && !this.allinoneService.isRootAdmin()) || (this.adminrole == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
    if(this.adminrole == 'admin' && this.allinoneService.isRootAdmin()) {
      this.isRootAdmin = true;
    }

  }

  importMembers(){
    this.messageService.openSnackBar('This feature is not available yet.', 'info');
    return;
  }

  getRoleName(role: string): string {
    switch(role) {
      case '1': return 'Instructor';
      case '2': return 'Supervisor';
      case '100': return 'Admin';
      case '3': return 'HR Admin';
      default: return '';
    }
  }

  exportMembers(){
    const fileName = `${this.channelname}_MemberList.xlsx`
    const dataToExport = this.showmembers.data.map(member => ({
      'User ID': member.userid,
      'User Name': member.username,
      'Role': this.getRoleName(member.role),
      'Member Type': member.membertypename,
      'Remark': member.remark
    }));

    this.allinoneService.exportExcel(dataToExport, fileName);
  }

  goToCreateUser() {
    this.router.navigateByUrl(`/${this.adminrole}/channel/${this.edit}/${this.channelid}/member/create-user`);
  }

  // editMembers: any[] = []

  ngOnInit(): void {
    this.memberPage = true;
    this.getAllMember();
    this.displayedColumns = ['userid', 'username', 'role','membertype', 'remark', 'action'];
    this.userid = this.allinoneService.getKunyekUserId();
    this.kuserid = this.allinoneService.getKunyekUserId();
    this.getMychannel();
    this.getMemberTypeList();
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.statusInterval);
  }

  importSheet(isError: boolean = false) {
    const channelid = this.getChannelId();
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        // *** check data
        isError : isError,
        errorData : this.importData,
        type: '001',
        channelid : channelid.toString()
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getImportStatus();
      if(dialogResult.success) {
        this.getAllMember();
      }
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    const channelid = this.getChannelId();
    var data = {
      // *** check type and check data
      type: '001',
      channelid : channelid.toString()
    };
    this.courseService.getImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            this.refresh();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  getChannelId() {
    const channelid = this.route.snapshot.params['channelid'];
    return channelid;
  }

  getMychannel() {
    const edit_status = this.route.snapshot.params['edit'];
    if (edit_status == "editchannel") {
      this.edit_stat = false;
      this.chl_text = "Channels"
      this.edit = edit_status;
    } else if (edit_status == "editmychannel") {
      this.edit_stat = true;
      this.chl_text = "My Channels"
      this.edit = edit_status;
    }
  }

  getMemberTypeList() {
    this.memberlist = [];
    const channelid = this.getChannelId();
    this.gettingmembertype = true;
    // var fun = this.role == 'admin' ? this.courseService.channelGet(): this.courseService.getMychannel({type: '1'}) 
    const data = {
      // channelid: this.selectedChannel,
      channelid: channelid.toString(),
    }
    this.courseService.getMembertype(data).subscribe(
      (category_data: any) => {
        if (category_data.returncode == '300') {
          this.memberlist = category_data.datalist;
        } else {
          this.messageService.openSnackBar(category_data.status || category_data.message, 'warn');
        }
        this.gettingmembertype = false;
      },
      (err: any) => {
        this.messageService.openSnackBar('Server error.', 'warn');
        this.gettingmembertype = false;
      }
    );
  }


  addMember() {
    const id = this.getChannelId();
    console.log(id);
    this.router.navigateByUrl(`/${this.adminrole}/channel/${this.edit}/${id}/member/addmember`)
  }

  getAllMember() {
    this.editMembers = [];
    this.gettingMember = true;
    this.showmembers = new MatTableDataSource();
    const channelid = this.getChannelId();
    const data = {
      "channelid": channelid.toString()
    }

    this.courseService.getMember(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.editMembers = res.datalist;
        if (this.editMembers == undefined || this.editMembers.length == 0) {
          this.paginate = false;
        } else {
          this.paginate = true;
        }
        this.showmembers = new MatTableDataSource(res.datalist);
        this.showmembers.sort = this.sort;
        this.showmembers.paginator = this.paginator;
        this.datacount = res.datalist.length;
        if (res.name != "") {
          this.channelname = res.name;
        }
        this.gettingMember = false;
        // const edit = this.route.snapshot.params['edit'];
        // this.router.navigateByUrl(`/${this.adminrole}/channel/${edit}/${channelid}/member`)
      }
      else {
        this.gettingMember = false;
        if (res.returncode == "210") {
          setTimeout(() => {
            this.gettingMember = false;
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
        }
        else {
          this.gettingMember = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      }
    }, err => {
      this.gettingMember = false;
    });
    console.log(this.editMembers);
  }


  getMember(data: any, idMember: any) {
    this.gettingEditMember = true;
    this.editPage = true;
    this.memberPage = false;
    const channelid = this.getChannelId();
    this.editMembers = [data];
    // this.editMembers = data;
    this.gettingEditMember = false;


  }
  goToChannels() {
    this.router.navigateByUrl(`${this.adminrole}/channel`);
    // if (this.edit_stat) {
    //   this.router.navigateByUrl('/instructor/mychannel');

    // } else {
    //   this.router.navigateByUrl('/admin/channel');

    // }
  }




  cat = [
    'instructor', 'role'
  ]
  role_list: any[] = [
    {
      id: '1',
      name: 'Instructor'
    },
    {
      id: '2',
      name : 'Supervisor'
    },
    {
      id: '100',
      name : 'Admin'
    },
    {
      id: '3',
      name : 'HR Admin'
    }
  ]
  getChanneId() {
    const channelid = this.route.snapshot.params['channelid'];
    return channelid;
  }

  upadateMember() {
    if(this.editMembers[0].membertype == '' || this.editMembers[0].membertype == null) {
      return this.messageService.openSnackBar("Member Type cannot be blank.", "warn")
    }
    this.isadding = true;
    const channelid = this.getChanneId();
    const data = {
      "adduserid": this.editMembers[0].userid.trim(),
      "channelid": channelid.toString(),
      "role": this.editMembers[0].role,
      "status": this.editMembers[0].status,
      "remark": this.editMembers[0].remark.trim(),
      "id": this.editMembers[0].id,
      "membertype" : this.editMembers[0].membertype,
      "username": this.editMembers[0].username,
      "password": this.editMembers[0].password
    }

    // 09967081701

    this.courseService.updataMember(data).subscribe((res: any) => {
      if (res.returncode == "300") {

        this.isadding = false;
        this.editPage = false;
        this.memberPage = true;
        this.editMembers = [];
        this.getAllMember();

      }
      else {
        this.editPage = true;
        this.memberPage = false;
        this.isadding = false;
        if (res.returncode == "210") {
          setTimeout(() => {
            this.isadding = false;
            this.editPage = true;
            this.memberPage = false;
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
        }
        else {
          this.editPage = true;
          this.memberPage = false;
          this.isadding = false;

          this.messageService.openSnackBar(res.message, 'warn');
        }
      }
    }, err => {
      this.editPage = true;
      this.memberPage = false;
      this.isadding = false;
    });
  }



  goToMembers() {
    this.memberPage = true;
    this.editPage = false;
    this.editMembers = [];
    this.getAllMember();
    const channelId = this.getChanneId();
    this.router.navigateByUrl(`${this.adminrole}/channel/editchannel/${channelId}/member`)
  }

  gotoChannelName() {
    const channelId = this.getChanneId();
    const edit = this.route.snapshot.params['edit'];
    this.router.navigateByUrl(`/${this.adminrole}/channel/${edit}/${channelId}`)

  }



  deleteMember(dedata: any) {
    if (dedata.userid == this.userid && this.edit_stat == true) {
      return;
    } else {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: "300px",
        data: { isNormal: false ,message: "Are you sure?", adminrole: this.adminrole } 
      });

      dialogRef.afterClosed().subscribe(result => {
        const channelid = this.getChanneId();
        if (result?.confirmed == true) {
          const deleteFromDb = result.deleteFromDb;
          const data = {
            "channelid": channelid.toString(),
            "id": dedata.id.toString(),
            "confirmdeleteaccount": deleteFromDb,
            "memberuserid": dedata.userid
          }
          
          this.courseService.delemember(data).subscribe((res: any) => {
            if (res.returncode == "300") {
              this.editPage = false;
              this.memberPage = true;
              this.loadingService.hide();
              this.editMembers = [];
              this.getAllMember();

            }
            else {
              this.editPage = false;
              this.memberPage = true;
              this.loadingService.hide();
              if (res.returncode == "210") {
                setTimeout(() => {
                  this.loadingService.hide();
                  this.router.navigateByUrl('login');
                  this.allinoneService.logout();
                }, 2000);
              }
              else {
                this.messageService.openSnackBar(res.message, 'warn');
              }
            }
          }, err => {
            this.editPage = false;
            this.loadingService.hide();
            this.memberPage = true;
          });


        }
      })
    }

  }

  announceSortChange(sortState: Sort, data: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    console.log(data)
  }


  clear() {
    this.searchText = "";
    this.showmembers.filter = "";
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showmembers.filter = searchItem.trim().toLowerCase();

  }
  refresh() {
    this.gettingMember = true;
    this.editMembers = [];
    if (this.editMembers == undefined || this.editMembers.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showmembers = new MatTableDataSource();
    this.getAllMember();

  }
}
