import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ThisReceiver } from '@angular/compiler';
import { SEOService } from '../../shared/services/seo.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { SetupCmsComponent } from '../setup-cms/setup-cms.component';
import { MatDialog } from '@angular/material/dialog';
import { ImagecropComponent } from '../imagecrop/imagecrop.component';

@Component({
  selector: 'app-edit-channel',
  templateUrl: './edit-channel.component.html',
  styleUrls: ['./edit-channel.component.scss'],
})
export class EditChannelComponent implements OnInit {
  gettingChannel: boolean = false;
  isCreating: boolean = false;
  gettingOrgs: boolean = false;
  chl_text: string = '';
  edit_stat: boolean = true;
  formSubmitted: boolean = false;

  imageSliderList: any[] = [];
  imageTextList: any[] = [];
  videoSlide: any;

  // edit_name: string = "";
  // edit_status: string = "";
  // edit_chl_desc: string = "";

  status: string = '';
  role: string = '';
  videoUrlPreview: string = '';
  orgs: any[] = [];

  homePageType = '001';

  chl_status: any = [
    { status: 'Enabled', value: 1 },
    { status: 'Disabled', value: 0 },
  ];

  homePageUiList: any = [
    {
      title: '',
      categories: [],
    },
  ];

  categoriesList: any = [];

  categoriesListT = [
    {
      categoryname: 'a',
      categoryid: 'a',
    },
    {
      categoryname: 'b',
      categoryid: 'b',
    },
    {
      categoryname: 'c',
      categoryid: 'c',
    },
  ]

  gettingCategory: boolean = false;

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private courseService: CourseService,
    private router: Router,
    public allinoneService: AllInOneService,
    private _seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())
    ) {
      this.router.navigateByUrl('');
    }
  }

  ngOnInit(): void {
    if (this.role == 'admin') {
      this.getOrgs();
    }
    this.editGetchannel();
    this.getMychannel();
    this.getCategory();
  }

  getCategory() {
    const channelid = this.route.snapshot.params['id'];
    this.gettingCategory = true;
    var data = {
      channelid: channelid.toString(),
    }
    this.courseService.getCategoryListWithCounts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categoriesList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCategory = false;
      },
      (err) => {
        this.gettingCategory = false;
      }
    )
  }

  getOrgs() {
    this.gettingOrgs = true;
    this.courseService.getOrganizations().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.orgs = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingOrgs = false;
      },
      (err) => {
        this.gettingOrgs = false;
      }
    );
  }

  getMychannel() {
    const edit_status = this.route.snapshot.params['edit'];
    if (edit_status == 'editchannel') {
      this.edit_stat = true;
      this.chl_text = 'Channels';
    } else {
      this.edit_stat = false;
      this.chl_text = 'My Channels';
    }
  }

  @ViewChild('fileInput') fileInput: any;

  fileSelected: any = '';
  fileName: any = '';
  imgURL: any;

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        // finalName = finalName + "_" + this.allinoneService.generateID() + "." + splitName[i]
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }



  newcategory(image: any, selectfile: any) {
    console.log('jaejae');
    console.log(image);

    console.log(selectfile);
    const dialog = this.dialog.open(ImagecropComponent, {
      maxWidth: '90vw',
      maxHeight: '80vh',
      width: '500px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        "image": image,
        "name": selectfile.name
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        console.log("ijwoefijwoejfioj")
        console.log(dialogResult);
        this.imgURL = dialogResult.image;
        this.fileSelected = dialogResult.file;
        this.fileName = this.getFileName(this.fileSelected);
        this.fileInput.nativeElement.value = '';

      }
    });

  }

  uploadFile(event: any) {
    console.log("jaejaejokok");
    this.fileSelected = event[0];

    // console.log(this.fileSelected);

    // var reader = new FileReader();
    // // this.imagePath = fileSelected;
    // reader.readAsDataURL(this.fileSelected);
    // reader.onload = (_event) => {
    //   this.imgURL = reader.result;
    // };

    // this.fileName = this.getFileName(this.fileSelected);
    // console.log(this.fileName);
    this.newcategory(event, event[0]);

  }

  uploadFile1(event: any) {

    console.log("nyinyi");

    console.log(event);

    // this.fileSelected = event.target.files[0];
    // console.log(fileSelected);

    // var reader = new FileReader();
    // // this.imagePath = fileSelected;
    // reader.readAsDataURL(this.fileSelected);
    // reader.onload = (_event) => {
    //   this.imgURL = reader.result;
    // };


    // console.log(this.fileName);
    this.newcategory(event, event.target.files[0]);


  }

  removeImage() {
    this.fileName = '';
    this.fileSelected = '';
    this.imgURL = '';
    this.fileInput.nativeElement.value = '';
  }

  @Input() channel: any = {};

  editGetchannel() {
    this.gettingChannel = true;
    const channelid = this.route.snapshot.params['id'];
    this.courseService.channelGetId(channelid.toString()).subscribe(
      (res: any) => {
        if (res.returncode == '300') {


          this.channel = res.datalist[0];
          console.log('here 123');
          console.log(this.channel);
          console.log(this.channel.privatecourse);
          // privatecourse
          
          this.channel.privatecourse = 'privatecourse' in this.channel ? this.channel.privatecourse : false;
          this.channel.showstudiobutton = 'studiolink' in this.channel ? this.channel.studiolink : true;
          this.channel.allowselfsignup = 'allowsignup' in this.channel ? this.channel.allowsignup : true;
          this.channel.sharecertificate = 'sharecertificate' in this.channel ? this.channel.sharecertificate : true;
          if (this.channel.homepage) {
            this.homePageType = '002';
            this.homePageUiList = this.channel.homepage;
          } else {
            this.homePageType = '001';
            this.homePageUiList = [
              {
                title: '',
                categories: [],
              },
            ];
          }
          if(this.channel.cmspage === "000") {
            this.channel.cmsimagedata.map((item: any) => {
              this.imageTextList.push({
                file: '',
                previewurl: item.imageurl,
                filename: item.imagename,
                text1: item.text1,
                text2: item.text2,
              });
            });
          } else {
            this.channel.cmsimagedata.map((item: any) => {
              this.imageSliderList.push({
                file: '',
                previewurl: item.imageurl,
                filename: item.imagename,
                text: item.text,
              });
            });
          }
          if (this.channel.cmsvideodata != '') {
            this.videoSlide = {
              file: '',
              previewurl: this.channel.cmsvideodata.imageurl,
              filename: this.channel.cmsvideodata.imagename,
              text: this.channel.cmsvideodata.text,
              libraryid: this.channel.cmsvideodata.libraryid,
              videourl: this.channel.cmsvideodata.libraryid ? "" : this.channel.cmsvideodata.videourl,
              preview: this.channel.cmsvideodata.libraryid ? this.channel.cmsvideodata.videourl : "",
              videotype: this.channel.cmsvideodata.libraryid ? '002' : '001'
            };
            console.log(this.videoSlide);
            this.checkVideoUrl();
          }
          this._seoService.updateTitle('- ' + this.channel.name);
          this.imgURL = this.channel.url;
          this.gettingChannel = false;
        } else {
          this.gettingChannel = false;
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
      },
      (err) => {
        this.gettingChannel = false;
      }
    );
  }

  // async updateEditChannel() {
  //   this.isCreating = true;
  //   const channelid = this.route.snapshot.params['id'];
  //   if (this.fileSelected) {
  //     const fileUpload = await this.allinoneService.fileUploadToS3(
  //       this.fileSelected,
  //       this.fileSelected.name
  //     );
  //     if (fileUpload == false) {
  //       this.messageService.openSnackBar(
  //         'Error while uploading your logo.',
  //         'warn'
  //       );
  //       this.isCreating = false;
  //       return;
  //     }
  //   }
  //   this.courseService
  //     .updateChannel(
  //       channelid.toString(),
  //       this.channel.name.trim(),
  //       this.channel.status,
  //       this.channel.description.trim(),
  //       this.fileSelected
  //         ? this.fileSelected.name
  //         : this.imgURL
  //         ? this.channel.filename
  //         : ''
  //     )
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == '300') {
  //           this.isCreating = false;
  //           this.router.navigateByUrl(`/${this.role}/channel`);
  //           // if(this.edit_stat == true) {
  //           //   this.router.navigateByUrl(`/${this.role}/channel`);
  //           // } else {
  //           //   this.router.navigateByUrl('/admin/mychannel');
  //           // }
  //         } else {
  //           this.isCreating = false;
  //           this.messageService.openSnackBar(res.message, 'warn');
  //         }
  //       },
  //       (err) => {
  //         this.isCreating = false;
  //       }
  //     );
  // }

  removeSubmitConfig(index: number) {
    if (this.homePageUiList.length > 1) {
      this.homePageUiList.splice(index, 1);
    }
  }

  addNewStatus() {
    if (this.homePageUiList.length < 11) {
      this.homePageUiList.push(
        {
          title: '',
          categories: [],
        }
      );
    }
  }

  async updateEditChannel() {
    this.formSubmitted = true;
    if (!this.channel.filename && !this.fileSelected) {
      return this.messageService.openSnackBar(
        'Please choose your channel logo.',
        'warn'
      );
    }
    // if (this.channel.type == '002' && this.channel.orgid == '') {
    //   return this.messageService.openSnackBar(
    //     'Please select an organization.',
    //     'warn'
    //   );
    // }

    if (this.channel.cmspage == '000' && this.imageTextList.length == 0) {
      return this.messageService.openSnackBar(
        'Please add an image for your banner.',
        'warn'
      );
    }

    if (this.channel.cmspage == '002' && this.imageSliderList.length == 0) {
      return this.messageService.openSnackBar(
        'Please add a slide for your banner.',
        'warn'
      );
    }
    if (this.channel.cmspage == '003' && !this.videoSlide) {
      return this.messageService.openSnackBar(
        'Please set up your banner.',
        'warn'
      );
    }

    if (this.homePageType == '002') {
      for (var l = 0; l < this.homePageUiList.length; l++) {
        if (!this.homePageUiList[l]['title']) {
          this.messageService.openSnackBar(
            'Please enter home page title.',
            'warn'
          );
          return;
        }
        if (this.homePageUiList[l]['categories'].length <= 0) {
          this.messageService.openSnackBar(
            'Please select home page categories.',
            'warn'
          );
          return;
        }
      }
    }


    // console.log('abc');

    // return;

    this.isCreating = true;
    var finalImageList: any[] = [];
    var finalImageToUpload: any[] = [];

    if (this.channel.cmspage === '000') {
      for (let i = 0; i < this.imageTextList.length; i++) {
        if (this.imageTextList[i].file) {
          if (this.imageTextList[i].file.size >= 800000) {
            this.imageTextList[i].file =
              await this.allinoneService.compressImage(
                this.imageTextList[i].file,
                0.8
              );
            if (this.imageTextList[i].file == false) {
              this.messageService.openSnackBar(
                'Error while uploading your file.',
                'warn'
              );
              this.isCreating = false;
              return;
            }
          }
          finalImageToUpload.push(this.imageTextList[i]);
        }
        finalImageList.push({
          text1: this.imageTextList[i].text1,
          text2: this.imageTextList[i].text2,
          imagename: this.imageTextList[i].filename,
        });
      }
    } else {
      for (let i = 0; i < this.imageSliderList.length; i++) {
        if (this.imageSliderList[i].file) {
          if (this.imageSliderList[i].file.size >= 800000) {
            this.imageSliderList[i].file =
              await this.allinoneService.compressImage(
                this.imageSliderList[i].file,
                0.8
              );
            if (this.imageSliderList[i].file == false) {
              this.messageService.openSnackBar(
                'Error while uploading your file.',
                'warn'
              );
              this.isCreating = false;
              return;
            }
          }
          finalImageToUpload.push(this.imageSliderList[i]);
        }
        finalImageList.push({
          text: this.imageSliderList[i].text,
          imagename: this.imageSliderList[i].filename,
        });
      }
    }

    console.log('loop finished');
    // this.imageSliderList.map(async (item: any) => {
    //   if (item.file) {
    //     if (item.file.size >= 800000) {
    //       item.file = await this.allinoneService.compressImage(item.file);
    //       if (item.file == false) {
    //         this.messageService.openSnackBar(
    //           'Error while uploading your file.',
    //           'warn'
    //         );
    //         this.isCreating = false;
    //         return;
    //       }
    //     }
    //     finalImageToUpload.push(item);
    //   }
    //   finalImageList.push({
    //     text: item.text,
    //     imagename: item.filename,
    //   });
    // });

    var finalVideoSlide: any;
    if (this.videoSlide) {
      if (this.videoSlide.file.size >= 800000) {
        this.videoSlide.file = await this.allinoneService.compressImage(
          this.videoSlide.file,
          0.8
        );
      }
      finalVideoSlide = {
        text: this.videoSlide.text,
        videourl: this.videoSlide.videotype == '001' ? this.videoSlide.videourl : '',
        imagename: this.videoSlide.filename,
        libraryid: this.videoSlide.videotype == '001' ? "" : this.videoSlide.libraryid
      };
    }

    console.log("1222222222222");
    console.log(this.fileSelected);
    console.log(this.fileSelected.size);
    if (this.fileSelected && this.fileSelected.size >= 800000) {
      this.fileSelected = await this.allinoneService.compressImage(
        this.fileSelected,
        0.8
      );
    }



    const data = {
      channelid: this.channel.channelid,
      name: this.channel.name.trim(),
      status: this.channel.status,
      description: this.channel.description.trim(),
      logoname: this.fileSelected ? this.fileSelected.name : this.imgURL ? this.channel.filename : '',
      type: this.channel.type,
      // orgid: this.channel.type == '001' ? '' : this.channel.orgid,
      orgid: this.channel.orgid,
      // orgid: '',
      // hidden: this.channel.type == '001' ? this.channel.hidden : false,
      hidden: this.channel.hidden,
      cmspage: this.channel.cmspage,
      cmsimagedata: finalImageList,
      cmsvideodata: this.channel.cmspage == '003' ? finalVideoSlide : '',
      sharecertificate: this.channel.sharecertificate,
      allowsignup: this.channel.allowselfsignup,
      studiolink: this.channel.showstudiobutton,
      privatecourse : this.channel.privatecourse,
      homepage: this.homePageType == '001' ? '' : this.homePageUiList
    };
    // console.log(data);
    // return;
    this.courseService.updateChannel(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (this.fileSelected) {
            const fileUpload =
              await this.allinoneService.fileUploadToS3WithPath(
                this.fileSelected,
                this.fileSelected.name,
                'channel/' + this.channel.channelid
              );
            if (fileUpload == false) {
              this.isCreating = false;
              this.messageService.openSnackBar(
                'Error while uploading your logo.',
                'warn'
              );
              return;
            }
          }
          if ((this.channel.cmspage == '002' || this.channel.cmspage == '000') && finalImageToUpload.length > 0) {

            const fileupload = await this.allinoneService.multipleUploadToAWS(
              finalImageToUpload,
              'channel/' + this.channel.channelid
            );
            this.isCreating = false;
            this.router.navigateByUrl(`/${this.role}/channel`);
          } else if (
            this.channel.cmspage == '003' &&
            this.videoSlide &&
            this.videoSlide.file
          ) {
            const fileupload =
              await this.allinoneService.fileUploadToS3WithPathForImage(
                this.videoSlide.file,
                this.videoSlide.filename,
                'channel/' + this.channel.channelid
              );
            this.isCreating = false;
            this.router.navigateByUrl(`/${this.role}/channel`);
          } else {
            this.isCreating = false;
            this.router.navigateByUrl(`/${this.role}/channel`);
          }
        } else {
          this.isCreating = false;
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.isCreating = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        }
      },
      (err) => {
        this.isCreating = false;
      }
    );
  }

  goToChannels() {
    this.router.navigateByUrl(`/${this.role}/channel`);
    // if(this.edit_stat == true) {
    //   this.router.navigateByUrl(`/${this.role}/channel`)
    // } else {
    //   this.router.navigateByUrl('/instructor/mychannel')
    // }
  }

  addImageToSlide(index?: number) {
    if (
      this.channel.cmspage == '002' &&
      this.imageSliderList.length == 5 &&
      index == undefined
    ) {
      return this.messageService.openSnackBar(
        'Maximum number of slides is 5.',
        'warn'
      );
    }

    if (
      this.channel.cmspage == '003' &&
      this.videoSlide &&
      index == undefined
    ) {
      return;
    }

    const dialog = this.dialog.open(SetupCmsComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '650px',
      closeOnNavigation: true,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        bannertype: this.channel.cmspage, // Change video or image from here
        isEditing: index != undefined ? true : false,
        editData:
          index != undefined
            ? this.channel.cmspage == '002'
              ? this.imageSliderList[index]
              : this.channel.cmspage == '000' 
              ? this.imageTextList[index]
              : this.videoSlide
            : '',
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.channel.cmspage == '002') {
          if (index != undefined) {
            this.imageSliderList[index] = dialogResult;
            this.imageSliderList[index].file = dialogResult.file;
            this.imageSliderList[index].previewurl = dialogResult.previewurl;
            this.imageSliderList[index].filename = dialogResult.filename;
            this.imageSliderList[index].text = dialogResult.text;
          } else {
            this.imageSliderList.push(dialogResult);
          }
        } else if (this.channel.cmspage == '003') {
          // Handle video slide (003)
          this.videoSlide = dialogResult;
          this.checkVideoUrl();
        } else if (this.channel.cmspage == '000') {
          // Handle case for '000' - Two text fields and one image
          if (index != undefined) {
            this.imageTextList[index] = dialogResult;
            this.imageTextList[index].text1 = dialogResult.text1;
            this.imageTextList[index].text2 = dialogResult.text2;
            this.imageTextList[index].file = dialogResult.file;
            this.imageTextList[index].previewurl = dialogResult.previewurl;
            this.imageTextList[index].filename = dialogResult.filename;
          } else {
            this.imageTextList.push(dialogResult);
          }
        }
      }
    });
  }

  removeFromImageSlide(index: number) {
    if(this.channel.cmspage === "000") {
      this.imageTextList.splice(index, 1);
    } else {
      this.imageSliderList.splice(index, 1);
    }
  }

  checkVideoUrl() {
    //No video

    // var reg = new RegExp("^t\=+[0-9]+s$");

    if (this.videoSlide.videotype == '001') {
      if (this.videoSlide.videourl.toString().trim() == '') {
        this.videoUrlPreview = '';
      }
      //youtube
      else if (
        this.videoSlide.videourl.toString().startsWith('https://youtu.be/')
      ) {
        this.videoUrlPreview =
          'https://www.youtube.com/embed/' +
          this.videoSlide.videourl
            .split('/')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        if (
          this.videoUrlPreview.includes('start=') &&
          this.videoUrlPreview.endsWith('s')
        ) {
          this.videoUrlPreview = this.videoUrlPreview.substring(
            0,
            this.videoUrlPreview.length - 1
          );
        }
      } else if (
        this.videoSlide.videourl
          .toString()
          .startsWith('https://www.youtube.com')
      ) {
        this.videoUrlPreview =
          'https://www.youtube.com/embed/' +
          this.videoSlide.videourl
            .split('v=')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        if (
          this.videoUrlPreview.includes('start=') &&
          this.videoUrlPreview.endsWith('s')
        ) {
          this.videoUrlPreview = this.videoUrlPreview.substring(
            0,
            this.videoUrlPreview.length - 1
          );
        }
      } else {
        //vimeo
        // https://vimeo.com/568270540
        // https://player.vimeo.com/video/568270540
        this.videoUrlPreview =
          'https://player.vimeo.com/video/' +
          this.videoSlide.videourl.split('/').pop();
      }
    } else {
      if (this.videoSlide.preview.toString().trim() == '') {
        this.videoUrlPreview = '';
      } else {
        this.videoUrlPreview = this.videoSlide.preview;
      }
    }

    console.log(this.videoUrlPreview);
  }
}
