<app-breadcrumb s_breadcrumb="Analytics" [t_breadcrumb]="coursename" [a_breadcrumb]="channelname"
  (emit)="goTo(role+'/analytics')" (emit1)="goToChannel()" *ngIf="(!isLoading && isfirsttime) || !isfirsttime">
</app-breadcrumb>

<div class="content">
  <div class="d-flex align-item-center justify-content-between flex-wrap mt-2">
    <div class="form-group mb-3" style="width: 260px !important;">
      <select class="form-select" [(ngModel)]="classid" (change)="getStudents()" matTooltip="Filter by Class"
        [disabled]="gettingClass">
        <option value="">All</option>
        <option [value]="class.classid" *ngFor="let class of classlist">
          {{ class.classcode }}
        </option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingClass"></mat-progress-bar>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="me-2 d-flex align-items-center">
        Total :&nbsp;<b> {{totaltime}}</b>
      </div>
      <div class="input-group" style="width: 260px !important;">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
        <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <button class="btn new-chl me-2" matTooltip="Refresh" (click)="refresh()" type="button">
        <i class="fa fa-refresh"></i>
      </button>
      <button type="button" class="btn new-class" (click)="downloadSheet()"
        matTooltip="Export Course To Excel">
        <img class="excel-icon" src="../../../assets/images/excel.png">
        Export
      </button>
    </div>
  </div>
  <div style="overflow: auto;">
    <table mat-table #stutable [dataSource]="showstudents" class="mat-elevation-z1" style="width: 100%;" matSort
      (matSortChange)="announceSortChange($event)">


      <!-- Name Column -->
      <ng-container matColumnDef="userid">
        <th mat-header-cell mat-sort-header="userid" *matHeaderCellDef>User ID
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          {{element.userid}}</td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="username"> User Name
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          {{element.username}}</td>
      </ng-container>

      <!-- Grade Column -->
      <ng-container matColumnDef="grade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="grade"> Grade
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          {{element.grade}} %</td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="progress"> Progress
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          {{element.percentage}} %</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          <span class="status" [ngClass]="{
            'status-green': element.completed,
            'status-gray': !element.completed && element.percentage == 0,
            'status-yellow': !element.completed && element.percentage != 0
    
        }">{{element.completed ? 'Completed' : !element.completed && element.percentage == 0 ? 'Not Started' : 'In Progress' }}</span></td>
      </ng-container>

      <!-- Enrolled Date Column -->
      <ng-container matColumnDef="enrolleddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="enrolleddate"> Enrolled Date
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          {{element.enrolleddate}}</td>
      </ng-container>

      <!-- Completed Date Column -->
      <ng-container matColumnDef="completeddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="completeddate"> Completed Date
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToDetails(element.userid)">
          {{element.completeddate}}</td>
      </ng-container>

      <!-- Time spent Column -->
      <ng-container matColumnDef="timespent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="timespent" sortActionDescription="Sort by time spent">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color);" (click)="goToDetails(element.userid)">
          {{element.totaltime}} </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="9">
          <span class="nodata" *ngIf="!isLoading">Empty</span>
          <span class="nodata" *ngIf="isLoading">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>

    </table>

    <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div>
</div>