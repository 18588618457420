<app-breadcrumb [s_breadcrumb]="'Instructor Requests'"></app-breadcrumb>

<div class="content">

    <div class="d-flex">
        <!-- (click)="newChannel()" -->

        <span class=" me-auto">
            
        </span>
        <div class="mt-4 mb-3 me-2">
            Total :<b> {{datacount}}</b>
        </div>


        <div class="input-group mt-3 mb-3" style="width: 260px !important;">
            <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
            <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}" *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4;color: #00bcd4;">
                <i class="fa fa-times" ></i>
            </button>
        </div>

        <button class="btn new-chl mt-3 mb-3" matTooltip="Refresh" (click)="refresh()" type="button">
            <i class="fa fa-refresh" ></i>
        </button>
    </div>
    <div style="overflow: auto;">
        <table mat-table #table [dataSource]="instructor" matSort (matSortChange)="sortData($event)" matSort class="mat-elevation-z1" style="width: 100%;">


            <!-- Name Column -->
            <ng-container matColumnDef="userid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by userid" style="padding-left: 0px;">User ID</th>
                <td mat-cell *matCellDef="let element" px-3> {{element.userid}} </td>
            </ng-container>


            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Username"> Username </th>
                <td mat-cell *matCellDef="let element" px-3> {{element.username}} </td>
            </ng-container>
            <ng-container matColumnDef="institute">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Institute"> Institute </th>
                <td mat-cell *matCellDef="let element" px-3> {{element.institute}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status"> Status </th>
                <!--  -->
                <!-- <td mat-cell *matCellDef="let element" [ngClass]="{'green':element.approved == '0','red':element.approved != '0'}"> {{element.approved == "0" ? "Pending" : "Rejected"}}</td> -->
                <td mat-cell *matCellDef="let element">
                    <!-- {{element.approved == "0" ? "Pending" : "Rejected"}} -->
                    <span class="badge bg-primary" *ngIf="element.approved == '0'">Pending</span>
                    <span class="badge bg-success" *ngIf="element.approved == '1'">Approved</span>

                    <span class="badge bg-danger" *ngIf="element.approved == '2'">Rejected</span>
                </td>


            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; padding-right: 0 !important;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index;" style="text-align: center; padding-right: 0 !important;">

                    <!-- <button mat-flat-button color="primary" (click)="clickApprove(element,1,i)">
                        Approve
                    </button> -->

                    <button type="button" class="mybtn btn btn-outline-success me-2" (click)="clickApprove(element,1,i)" [disabled]="element.approved == '1'">Approve</button>
                    <button type="button" class="mybtn btn btn-outline-danger" (click)="clickApprove(element,2,i)" [disabled]="element.approved == '2'">Reject</button>

                    <!-- <button *ngIf="element.approved == '0'" mat-flat-button color="warn" (click)="clickApprove(element,2,i)">
                        Reject
                    </button> -->

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td colspan="5">
                    <span class="nodata" *ngIf="!gettingInstructor">Empty</span>
                    <span class="nodata" *ngIf="gettingInstructor">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                    </span>
                </td>
            </tr>
        </table>
        <!-- <div  *ngIf="paginate == true"> -->
        <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        <!-- </div> -->
    </div>
</div>