import { Component, Inject, OnInit } from '@angular/core';
import { Grade } from '../../shared/models/Grade';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-grade-book-total-details',
  templateUrl: './grade-book-total-details.component.html',
  styleUrls: ['./grade-book-total-details.component.scss']
})
export class GradeBookTotalDetailsComponent implements OnInit {

  // grades: Grade[] =[];
  grades: any[] =[];
  user = '';
  totalPercentage = '';
  gettingGrade: boolean = false;

  courseid: string = '';
  userid : string = '';

  constructor(
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.courseid = data.courseid;
    this.userid = data.userid;
  }

  ngOnInit(): void {
    this.getGrades();
  }

  getGrades(){
    this.gettingGrade = true;
    this.courseService.getGrades(this.courseid, this.userid).subscribe((res: any) => {
      if(res.returncode == '300'){
        this.grades = res.datalist;
        var temp = {
          isTotal : true,
          totalPercentage : res.totalpercentage
        }
        this.grades.push(temp);
        
        this.user = res.username ? res.username : res.userid;
        this.totalPercentage = res.totalpercentage;
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.gettingGrade = false;
    }, err=> {
      this.gettingGrade = false;
    })
  }

}
