import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Course } from '../models/Course';
import { AllInOneService } from './all-in-one.service';
import { MessageService } from './message.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(
    public allinOneService: AllInOneService,
    private cookieService: CookieService,
    private router: Router,
    private http: HttpClient,
    private messageService: MessageService
  ) {}
  private handleError = (error: HttpErrorResponse) => {
    console.error(error);
    if (error.status == 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message);
      this.messageService.openSnackBar('Network Error', 'warn');
    } else {
      if (error.status.toString().startsWith('5')) {
        if (error.status != 504) {
          this.messageService.openSnackBar('Server Error', 'warn');
        } else {
          this.messageService.openSnackBar(
            'Request Timeout, please try again',
            'warn'
          );
        }
      } else if (error.status.toString().startsWith('4')) {
        if (error.status.toString() == '401') {
          this.messageService.openSnackBar('Unauthorized Access.', 'warn');
        } else {
          this.messageService.openSnackBar('Client Error', 'warn');
        }
      }
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError('Something bad happened; please try again later.');
  };

  httpRequestForLogin(url: string, param: any) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post(url, param, options)
      .pipe(catchError(this.handleError));
  }

  httpRequestWithInterval(url: string, param: any) {
    const appid = this.allinOneService.getAppId();
    var userid = this.allinOneService.getKunyekUserId();
    const atoken = this.allinOneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
    });

    return this.http.post(url, body, options).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  httpRequest(url: string, param: any) {
    const appid = this.allinOneService.getAppId();
    var userid = this.allinOneService.getKunyekUserId();
    const atoken = this.allinOneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
    });

    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  fetchHttpRequest(url: string, param: any) {
    const appid = this.allinOneService.getAppId();
    var userid = this.allinOneService.getKunyekUserId();
    const atoken = this.allinOneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
    });

    fetch(url, {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      },
      body: JSON.stringify(body),
    });
  }

  httpRequestForPayment(url: string, param: any) {
    return this.http.post(url, param, {
      headers: new HttpHeaders({
        Accept: 'text/html',
        'Content-Type': 'text/html',
      }),
      responseType: 'text',
    });
  }

  certificateDownload(fileUrl: string, fileName: string) {
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((data: Blob) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const base64data = fileReader.result as string;
        const downloadLink = document.createElement('a');
        downloadLink.href = base64data;
        downloadLink.download = fileName;
        downloadLink.click();
      };
      fileReader.readAsDataURL(data);
    });
  }

  downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' });
  }

  requestQR(requesturl: any) {
    const url = this.allinOneService.iamdomain + 'auth/requestqr';
    const param = {
      // "requesturl": requesturl,
      appid: 'kunyek',
      type: '0',
    };
    return this.httpRequestForLogin(url, param);
  }

  getLoginQr() {
    const url = this.allinOneService.iamdomain + 'auth/getloginqr';
    const param = {
      appid: 'kunyek',
    };
    return this.httpRequestForLogin(url, param);
  }

  checkQR(requestqr: any) {
    const url = this.allinOneService.iamdomain + 'auth/checkqr';
    const param = {
      qr: requestqr,
      // "appid": "kunyek"
    };
    return this.httpRequestForLogin(url, param);
  }

  getUser() {
    const url = this.allinOneService.userUrl + 'getuser';
    const userid = this.allinOneService.getUserId();

    const param = {
      usersyskey: userid,
      channelid: this.allinOneService.channelid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param amount
   * @param coupon
   * @param paymentappid
   * @returns
   */
  addpayment(
    courseid: string,
    amount: string,
    coupon: string,
    paymentappid: string
  ) {
    const url = this.allinOneService.courseUrl + 'addpayment';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      usersyskey: usersyskey,
      amount: amount,
      coupon: coupon,
      paymentappid: paymentappid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param orderno
   * @param orderid
   * @param transactionno
   * @param status - 001- success , 002 - fail
   * @returns
   */
  updatePayment(
    orderno: string,
    orderid: string,
    transactionno: string,
    status: string
  ) {
    const url = this.allinOneService.courseUrl + 'updatepayment';
    const param = {
      orderno: orderno,
      orderid: orderid,
      transno: transactionno,
      status: status,
      pappid: this.allinOneService.currentPaymentConfig.appid,
      pappname: this.allinOneService.currentPaymentConfig.appname,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param orderno
   * @returns
   */
  deletePayment(orderno: string) {
    const url = this.allinOneService.courseUrl + 'deletepayment';
    const param = {
      orderno: orderno,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @returns
   */
  verifyPayment(courseid: string) {
    const url = this.allinOneService.courseUrl + 'verifypayment';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      usersyskey: usersyskey,
      pappid: this.allinOneService.currentPaymentConfig.appid,
      pappname: this.allinOneService.currentPaymentConfig.appname,
    };
    return this.httpRequest(url, param);
  }

  paymentgateway(param: any) {
    const url = 'https://paymentgateway.mitcloud.com/api/pay';
    return this.httpRequestForPayment(url, param);
  }

  //user
  // kunyek user to create a new one if not in elearning. [Elearning Server]
  checkUser() {
    const url = this.allinOneService.userUrl + 'checkuser';
    const param = {
      domain: this.allinOneService.currentDomain,
    };

    return this.httpRequest(url, param);
  }

  getMychannel(data: any) {
    const url = this.allinOneService.channelUrl + 'getmychannel';
    return this.httpRequest(url, data);
  }

  getLearningPath(data: any) {
    const url = this.allinOneService.learningPathUrl + 'getbyuser';
    return this.httpRequest(url, data);
  }

  getLearningPathCourses(data: any) {
    const url = this.allinOneService.learningPathUrl + 'getcourse';
    return this.httpRequest(url, data);
  }

  searchCourses(
    search: string,
    end: string,
    instructorid: string,
    profileid: string
  ) {
    const url = this.allinOneService.courseUrl + 'search';
    const userid = this.allinOneService.getUserId();
    const param = {
      searchtext: search,
      instructorid: instructorid,
      profileid: profileid,
      channelid: this.allinOneService.channelid,
      usersyskey: userid || '',
      end: end,
      department: this.allinOneService.user
        ? this.allinOneService.user.department
        : '',
      typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
    };

    return this.httpRequest(url, param);
  }

  getCoursesWithChannel(
    end: string,
    recentCourse: boolean = false,
    allCategories: boolean = false
  ) {
    const url = this.allinOneService.courseUrl + 'getcourse';
    const userid = this.allinOneService.getUserId();
    const param = {
      courseid: '',
      categoryid: '',
      subcategoryid: '',
      channelid: this.allinOneService.channelid,
      usersyskey: userid || '',
      end: end,
      department: this.allinOneService.user
        ? this.allinOneService.user.department
        : '',
      getrecentcourse: recentCourse,
      typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
      all: allCategories,
      web: true,
    };

    return this.httpRequest(url, param);
  }

  getCustomCourses(end: string, title: string) {
    const url = this.allinOneService.channelUrl + 'courses/get';
    const userid = this.allinOneService.getUserId();
    const param = {
      channelid: this.allinOneService.channelid,
      usersyskey: userid || '',
      end: end,
      title: title,
      // department: this.allinOneService.user
      //   ? this.allinOneService.user.department
      //   : '',
      // typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
    };

    return this.httpRequest(url, param);
  }

  createUser(username: string) {
    const url = this.allinOneService.userUrl + 'createuser';

    var userid = this.allinOneService.getKunyekUserId();

    const param = {
      userid: userid,
      username: username,
      userrole: 0,
      institute: '',
      domain: this.allinOneService.currentDomain,
    };
    return this.httpRequestForLogin(url, param);
  }

  getInstructor(instructorid: string) {
    const url = this.allinOneService.userUrl + 'getinstructor';

    const param = {
      usersyskey: instructorid,
    };

    return this.httpRequestForLogin(url, param);
  }

  signIn(userid: string, password?: string) {
    const appid = this.allinOneService.getAppId();
    const rememberme = sessionStorage.getItem('rememberme') || 'FALSE';

    const url = this.allinOneService.iamdomain + 'auth/signin';
    const param = {
      userid: userid,
      password: password || '',
      uuid: '',
      recaptcha: '',
      appid: appid,
      rememberme: rememberme,
    };

    return this.httpRequestForLogin(url, param);
  }

  checkToken() {
    const url = this.allinOneService.iamdomain + 'auth/checktoken';
    const atoken = this.allinOneService.getAtoken();
    const appid = this.allinOneService.getAppId();
    const kunyek_userid = this.allinOneService.getKunyekUserId();

    const param = {
      userid: kunyek_userid,
      appid: appid,
      atoken: atoken,
    };

    // {
    //   "returncode": "3" // 0 = valid, 1 = invalid, 3 = error
    //  }

    return this.httpRequestForLogin(url, param);
  }

  renewToken() {
    const url = this.allinOneService.iamdomain + 'auth/renewtoken';
    const param = {};
    return this.httpRequest(url, param);
  }

  hcmLogin(data: any) {
    const url = this.allinOneService.domain + 'hcm/login';
    return this.httpRequestForLogin(url, data);
  }

  verify(username: string, otp: string) {
    var userid = this.allinOneService.getKunyekUserId();
    var password = this.allinOneService.getKunyekPassword();

    const session = this.allinOneService.getSession();
    const appid = this.allinOneService.getAppId();

    const url = this.allinOneService.iamdomain + 'auth/verifyuser';
    const param = {
      userid: userid,
      username: username,
      uuid: '',
      otp: otp,
      session: session,
      password: password,
      appid: appid,
    };

    return this.httpRequestForLogin(url, param);
  }

  /**
   * @param courseid - no need
   * @param status -2 [will return all published course list]
   */

  getCourses() {
    const url = this.allinOneService.courseUrl + 'getcourse';
    const userid = this.allinOneService.getUserId();
    const param = {
      courseid: '',
      categoryid: '',
      subcategoryid: '',
      channelid: '',
      usersyskey: userid || '',
      status: '2',
      department: this.allinOneService.user
        ? this.allinOneService.user.department
        : '',
      typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param channelid
   * @param status -2 [will return all published course list]
   */

  getCoursesByChannel(channelid: string) {
    const url = this.allinOneService.courseUrl + 'getcourse';
    const userid = this.allinOneService.getUserId();
    const param = {
      courseid: '',
      categoryid: '',
      subcategoryid: '',
      channelid: channelid,
      usersyskey: userid || '',
      status: '2',
      department: this.allinOneService.user
        ? this.allinOneService.user.department
        : '',
      typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - no need
   * @param status -2 [will return all published course list]
   */

  getCoursesByCategory(categoryid: string, subcatid: string, end: string) {
    const url = this.allinOneService.courseUrl + 'getcourse';
    const userid = this.allinOneService.getUserId();

    const param = {
      channelid: this.allinOneService.channelid,
      courseid: '',
      categoryid: categoryid,
      subcategoryid: subcatid,
      usersyskey: userid || '',
      end: end,
      department: this.allinOneService.user
        ? this.allinOneService.user.department
        : '',
      typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
    };

    return this.httpRequest(url, param);
  }

  getMySchedule() {
    const url = this.allinOneService.courseUrl + 'getmyschedule';
    const userid = this.allinOneService.getUserId();
    const channelid = this.allinOneService.getChannelid();
    const param = {
      channelid: channelid,
      usersyskey: userid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param ongoingend
   * @param upcomingend
   * @returns
   */
  getScheduledCourse(ongoingend: string, upcomingend: string) {
    const url = this.allinOneService.courseUrl + 'getschedule';
    const userid = this.allinOneService.getUserId();
    const channelid = this.allinOneService.getChannelid();
    const param = {
      channelid: channelid,
      usersyskey: userid,
      ongoingend: ongoingend,
      upcommingend: upcomingend,
    };
    return this.httpRequest(url, param);
  }

  getRegisteredCourses(data: any) {
    const url = this.allinOneService.studentUrl + 'getregisteredcourses';
    return this.httpRequest(url, data);
  }

  /**
   * @param status -  1 [will return only the name of sections, subsections and units]
   */

  getCourseDetail(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getsinglecourse';
    const userid = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      status: '1',
      usersyskey: userid,
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param calledby - value of Side [Student: 1, Instructor: 0]
   */

  enrollCourse(courseid: string, completedunits: string[]) {
    const url = this.allinOneService.studentUrl + 'registercourse';
    const userid = this.allinOneService.getUserId();

    const param = {
      studentid: userid,
      courseid: courseid,
      completeunits: completedunits,
    };

    return this.httpRequest(url, param);
  }

  /**
   */

  unEnrollCourse(pkey: string) {
    const url = this.allinOneService.studentUrl + 'unregistercourse';
    const param = {
      pkey: pkey,
    };

    return this.httpRequest(url, param);
  }

  //learn

  /** 
   * @param status - 0 [will return sections, subsections and units with details]
   */

  getCourseByCourseID(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getcoursedata';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      usersyskey: usersyskey,
      status: '0',
    };

    return this.httpRequest(url, param);
  }

  /**
   * @param courseid - id of the course
   */

  getCourseByCourseIDForPreview(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getcourse';
    const param = {
      courseid: courseid,
      usersyskey: '',
      status: 3,
      department: this.allinOneService.user
        ? this.allinOneService.user.department
        : '',
      typeid: this.allinOneService.user ? this.allinOneService.user.typeid : '',
    };

    return this.httpRequest(url, param);
  }

  //progress [complete unit]
  // https://api.learn.networksapi.net/student/updatecompletedunits
  updateCompletedUnits(unitlists: any, pkey: string) {
    const url = this.allinOneService.studentUrl + 'updatecompletedunits';

    const param = {
      unitlist: unitlists,
      pkey: pkey,
    };
    return this.httpRequest(url, param);
  }
  getCompletedUnits(pkey: string) {
    const url = this.allinOneService.studentUrl + 'getcompletedunits';

    const param = {
      pkey: pkey,
    };
    return this.httpRequest(url, param);
  }

  getCategoryList() {
    const url = this.allinOneService.categoryUrl + 'getcategory';
    return this.httpRequest(url, {});
  }

  getCategoryListWithCounts() {
    const url = this.allinOneService.categoryUrl + 'getcategorywithcounts';
    const param = {
      channelid: this.allinOneService.channelid,
    };
    return this.httpRequest(url, param);
  }

  timeAnalytic(data: any) {
    const url = this.allinOneService.domain + 'addanalytics';
    return this.httpRequestWithInterval(url, data);
  }

  checkAnalytics() {
    const url = this.allinOneService.domain + 'checkanalytics';
    const param = {};
    return this.httpRequestWithInterval(url, param);
  }

  /**
   * @param courseid
   * @param classid
   * @param sectionid
   * @param unitid
   * @param remark
   * @param title
   * @param filename
   * @param type
   */
  studentFileUpload(
    courseid: string,
    classid: string,
    sectionid: string,
    unitid: string,
    remark: string,
    title: string,
    filename: string,
    type: string,
    text: string = ''
  ) {
    const url = this.allinOneService.courseUrl + 'studentfileupload';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      classid: classid,
      sectionid: sectionid,
      unitid: unitid,
      remark: remark,
      studentid: usersyskey,
      title: title,
      filename: filename,
      type: type,
      text: text,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param courseid
   * @param classid
   */
  getStudentDropboxList(courseid: string, classid: string) {
    const url = this.allinOneService.courseUrl + 'getstudentdropboxlist';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      classid: classid,
      studentid: usersyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param classdbid - primary key for the item
   */
  deleteSubmittedFile(classdbid: string) {
    const url = this.allinOneService.courseUrl + 'studentfiledelete';
    const param = {
      classdbid: classdbid,
    };
    return this.httpRequest(url, param);
  }

  addServerLog(route_url: string) {
    const url = this.allinOneService.kunyekdomain + 'senterrorlog';
    const param = {
      accesskey: 'kunyek@s3rvic3-KEYf0raccesskey',
      domain: '',
      functionname: 'elearning',
      description: route_url,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param unitid
   */
  getAssignmentResult(courseid: string, unitid: string) {
    const url = this.allinOneService.courseUrl + 'getassignmentresult';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      unitid: unitid,
      studentid: usersyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
  * @param unit_id - primary key for the item

  *
  */

  getQuizresult(unit_id: any, courseid: string) {
    const url = this.allinOneService.unitUrl + 'getquizresult';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      usersyskey: usersyskey,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   * @param unit_id - primary key for the item
   * @param usersyskey
   * @param resultid
   *
   */
  // const url = this.allinOneService.unitUrl + 'getquizquestion';
  getQuizQuestion(
    unit_id: any,
    resultid: any,
    courseid: string,
    sectionid: string,
    subsectionid: string
  ) {
    const url = this.allinOneService.unitUrl + 'getquizquestions';
    // const url = "https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test"
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      usersyskey: usersyskey,
      resultid: resultid,
      courseid: courseid,
      sectionid: sectionid,
      subsectionid: subsectionid,
    };
    return this.httpRequest(url, param);
  }

  getFeedback(unit_id: any, resultid: any, courseid: string) {
    const url = this.allinOneService.unitUrl + 'getquizfeedback';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      usersyskey: usersyskey,
      resultid: resultid,
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param unit_id
   * @param courseid
   * @param answerlist
   * @param templateid
   * @returns
   */
  submitQuiz(
    unit_id: any,
    courseid: any,
    answerlist: any[],
    templateid: string
  ) {
    const url = this.allinOneService.unitUrl + 'addquizresult';
    // const url = 'https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test2';

    const usersyskey = this.allinOneService.getUserId();
    const param = {
      unitid: unit_id,
      courseid: courseid,
      usersyskey: usersyskey,
      answerlist: answerlist,
      templateid: templateid,
    };
    return this.httpRequest(url, param);
  }

  replyDiscussion(data: any) {
    const url = this.allinOneService.kunyekdomain + 'message/sent';
    return this.httpRequest(url, data);
  }

  /**
   *
   * @param unitid
   * @param courseid
   * @param end Sort Key/date time of last message
   * @param count Number of discssuion to get
   * @param parentsyskey Parent discussion syskey
   * @returns
   */
  getDiscussion(
    unitid: string,
    courseid: string,
    end: string,
    count: number,
    parentsyskey: string
  ) {
    const url = this.allinOneService.unitUrl + 'getdiscussion';
    const param = {
      unitid: unitid,
      courseid: courseid,
      end: end,
      count: count,
      parentsyskey: parentsyskey,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @returns
   */
  getGrades(courseid: string) {
    const url = this.allinOneService.courseUrl + 'getgrade';
    const param = {
      courseid: courseid,
      searchuser: this.allinOneService.getKunyekUserId(),
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @returns
   */
  getCertificate(courseid: string, templateid: string) {
    const url = this.allinOneService.courseUrl + 'getcertificate';
    // const url = "https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/uploadfileclient";
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      usersyskey: usersyskey,
      courseid: courseid,
      templateid: templateid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param courseid
   * @param imagename
   * @param templateid
   * @returns
   */
  addCertificate(courseid: string, imagename: string, templateid: string) {
    const url = this.allinOneService.courseUrl + 'addcertificate';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      courseid: courseid,
      usersyskey: usersyskey,
      imagename: imagename,
      templateid: templateid,
    };
    return this.httpRequest(url, param);
  }

  /**
   *
   * @param certificateid
   * @returns
   */
  verifyCertificate(certificateid: string) {
    const url = this.allinOneService.courseUrl + 'verifycertificate';
    const param = {
      certificateid: certificateid,
    };
    return this.httpRequestForLogin(url, param);
  }

  /**
   *
   * @param appid
   * @param userid
   * @param atoken
   * @param domain
   * @returns
   */
  checkDomain(appid: string, userid: string, atoken: string, domain: string, queryChannelDomain: string) {
    const url = this.allinOneService.domain + 'checkdomainurl';
    const param = {
      appid: appid,
      userid: userid,
      atoken: atoken,
      domain: queryChannelDomain ?? domain,
      // domain : 'com.nirvasoft.app059'
    };
    return this.httpRequestForLogin(url, param);
  }

  /**
   *
   * @param templateid
   * @returns
   */
  getFeedbackForm(templateid: string) {
    const url = this.allinOneService.courseUrl + 'getfeedbackform';
    const param = {
      templateid: templateid,
    };
    return this.httpRequest(url, param);
  }
  getStudentReport(startdate: string, enddate: string) {
    const url = this.allinOneService.courseUrl + 'getanalyticswithstudent';
    const usersyskey = this.allinOneService.getUserId();
    const param = {
      channelid: this.allinOneService.channelid,
      usersyskey: usersyskey,
      startdate: startdate,
      enddate: enddate,
      searchuser: this.allinOneService.getKunyekUserId(),
    };
    return this.httpRequest(url, param);
  }

  deleteFeedBack(courseid: any) {
    const url = this.allinOneService.courseUrl + 'deletestudentfeedback';
    const param = {
      courseid: courseid,
    };
    return this.httpRequest(url, param);
  }

  addCurrentVideoTime(data: any) {
    const url = this.allinOneService.courseUrl + 'updatecurrenttime';
    return this.httpRequest(url, data);
  }

  addToClass(qrvalue: string) {
    const url = this.allinOneService.courseUrl + 'addstudentclass';
    const userid = this.allinOneService.getKunyekUserId();

    const param = {
      courseid: '',
      adduser: userid,
      classid: '',
      studentcode: '',
      studentname: '',
      remark: '',
      status: '2',
      qrdata: qrvalue,
      miod: this.allinOneService.currentDomain == 'miod.khub.cloud',
    };
    return this.httpRequest(url, param);
  }

  addQuizCache(data: any) {
    const url = this.allinOneService.unitUrl + 'addquizcache';
    return this.httpRequest(url, data);
  }

  addQuizCacheOnClose(data: any) {
    const url = this.allinOneService.unitUrl + 'addquizcache';
    this.fetchHttpRequest(url, data);
  }



  

  convertFileToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);  // Resolve the promise with the Base64 string
      };
      reader.onerror = (error) => {
        reject('Error converting file to Base64: ' + error);  // Reject the promise on error
      };
      reader.readAsDataURL(file);  // Start reading the file as Base64 string
    });
  }

  maxFileSize = 5 * 1024 * 1024;
  uploadFile(file: File, name: string, path: string): Observable<any> {
    const url = "https://rwm99kh7ta.execute-api.ap-southeast-1.amazonaws.com/Stage/test2";

    if (file.size > this.maxFileSize) {
      // this.messageService.openSnackBar('File size exceeds the 5 MB limit.', 'warn');
      return throwError('File size exceeds the 5 MB limit.');
    }

    return new Observable(observer => {
        this.convertFileToBase64(file).then(base64 => {
          const param = {
            base64: base64,
            filename: name,
            filetype: file.type,
            filepath: path,
            buckname: 'elearningbucket'
          };
  
          this.http.post(url, param).subscribe(
            (res: any) => {
  
               if (res.returncode == '200') {
                 this.messageService.openSnackBar('Error while uploading your file [Server Error]', 'warn');  // Handle error
               }else{
                observer.next(res);  // Emit response to subscriber
                observer.complete();  
               }
                 // Complete the observable
            },
            error => {
              observer.error(error);    // Emit error to subscriber
            }
          );
        }).catch(error => {
          observer.error(error);      // Emit error to subscriber
        });
    });
  }
}
