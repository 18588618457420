<div class="d-flex align-items-center justify-content-between">
  <div>
    <h2 class="foldername">
      <strong>{{datalist.filename}}</strong>
    </h2>
  </div>
  <div (click)="cancel()" style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </div>
</div>

<!--  -->
<form>


  <ng-container *ngIf="!isLoading">
    <div class="form-group mb-3">
      <table class="table">
        <tbody>
          <tr>
            <td>File ID</td>
            <td>{{datalist.fileid}}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>{{datalist.filetype}}</td>
          </tr>
          <tr>
            <td>Owner</td>
            <td>
              <div class="d-flex">
                <div [ngClass]="{ 'menu-img-profile': datalist.profileimage == ''}">
                  <span *ngIf="datalist.profileimage == ''"> {{ datalist.username.charAt(0)}}</span>
                  <img class="profileimage" *ngIf="datalist.profileimage != ''"
                    [src]="allinoneService.imgurl + datalist.profileimage" alt="">

                </div>
                <div style="margin-top: 4px;margin-left: 10px;">{{datalist.username == '' ? datalist.userid :
                  datalist.username}}</div>
              </div>

            </td>
          </tr>
          <tr>
            <td>File size</td>
            <td>
              <span *ngIf="datalist.filesize && datalist.filesize < 1024">
                {{datalist.filesize | number :'1.0-2'}} KB
              </span>
              <span *ngIf="datalist.filesize && datalist.filesize > 1024">
                {{datalist.filesize /1024 | number :'1.0-2'}} MB
              </span>
              <span *ngIf="datalist.filetype == 'folder'">
                -
              </span>
              <span *ngIf="datalist.filetype == ''">
                -
              </span>
            </td>
          </tr>
          <tr>
            <td>File Description</td>
            <td>{{datalist.modifieddate}}</td>
          </tr>
          <!-- modifieddate -->
        </tbody>
      </table>


    </div>


  </ng-container>

  <ng-container *ngIf="isLoading">
    <table class="table">
      <tbody>
        <tr class="mat-row" *ngFor="let i of [1,2,3,4]">
          <td>
            <ngx-skeleton-loader [theme]="{ height: '10px',width: '230px'}" style="margin-bottom: -10px">
            </ngx-skeleton-loader>
          </td>
          <td>
            <ngx-skeleton-loader [theme]="{ height: '10px',width: '230px'}" style="margin-bottom: -10px">
            </ngx-skeleton-loader>
          </td>

        </tr>
      </tbody>
    </table>
  </ng-container>




</form>