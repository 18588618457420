import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SEOService } from '../../shared/services/seo.service';
import { MatDialog } from '@angular/material/dialog';
import { CourseDuplicateEditorComponent } from '../course-duplicate-editor/course-duplicate-editor.component';

export interface Courses {
  courseid: string;
  coursename: string;
  courseimage: string;
  type: string;
  studentcount: string;
  instructorname: string;
  institute: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  filter: string = '';
  allcourses: any = [];
  courses: any[] = [];

  gettingCategory: boolean = false;

  selectedChannel: any = 'all';
  selectedChannelName: any = 'All';

  selectedCourseType: any = '';
  selectedCourseTypeName: any = 'All';

  selectedStatus: any = '';
  selectedStatusName: any = 'All';

  selectedCategory: any = '';
  selectedCategoryName: any = 'All';

  chl: any = '';

  // channels: any[] = [];
  channels: any[] = [
    {
      name: 'All',
      channelid: 'all',
    },
  ];

  categoryFilterList: any[] = [
    {
      categoryname: 'All',
      categoryid: '',
    },
  ];

  courseTypeFilter: any[] = [
    {
      name: 'All',
      id: '',
    },
    {
      name: 'Self-Paced',
      id: '001',
    },
    {
      name: 'Instructor-Led',
      id: '002',
    },
    {
      name: 'Hybrid',
      id: '003',
    },
  ];

  statusFilterList: any[] = [
    {
      name: 'All',
      id: '',
    },
    {
      name: 'Hidden',
      id: '0',
    },
    {
      name: 'Released',
      id: '1',
    },
  ];

  showcourses: MatTableDataSource<any> = new MatTableDataSource(this.courses);;

  displayedColumns: any;

  isLoading: boolean = false;
  paginate: boolean = false;
  gettingmyChannel: boolean = false;
  datacount: string = '0';
  searchText: string = '';
  isFocus: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private changeDetectorRefs: ChangeDetectorRef,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private route: ActivatedRoute,
    private _seoService: SEOService,
    private dialog: MatDialog
  ) {
    this._seoService.updateTitle('');
  }

  ngOnInit() {
    const atoken = this.allinoneService.getAtoken();
    if (!atoken) {
      this.allinoneService.logout();
    }
    this.isLoading = true;
    this.displayedColumns = [
      'image',
      'coursename',
      'studentcount',
      'type',
      'duplicate',
    ];
    this.getMyChannel();
    this.getAllCourse();
    // console.log(this.channels);
    // this.getAllCourse();
  }

  // getParamChl() {
  //   var chls : any;
  //   chls = this.route.snapshot.queryParamMap.get('id') || "";
  //   var marvelHeroes = this.channels.filter(function (data) {
  //     return data.channelid == chls;
  //   });
  // }

  getParamChl() {
    var id = this.route.snapshot.queryParamMap.get('id') || '';
    if (id) {
      var chl_id = this.channels.filter(function (data) {
        return data.channelid == id;
      });

      if (chl_id.length == 0) {
        this.selectedChannel = 'all';
        this.getAllCourse();
      } else {
        this.selectedChannel = chl_id[0].channelid;
        this.getAllCourse();
      }
    } else {
      this.selectedChannel = 'all';
      this.getAllCourse();
    }
  }

  getCategory() {
    this.gettingCategory = true;
    const data = {
      type: '1',
      channelid: this.selectedChannel,
    };
    this.courseService.getCategoryList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categoryFilterList = [
            {
              categoryname: 'All',
              categoryid: '',
            },
          ];
          this.categoryFilterList = this.categoryFilterList.concat(res.data);
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.gettingCategory = false;
      },
      (err) => {
        this.gettingCategory = false;
      }
    );
  }

  channelFilter() {
    const filter = {
      channelid: this.selectedChannel,
      coursetype: this.selectedCourseType,
      coursestatus: this.selectedStatus,
      category: this.selectedCategory,
    };
    this.showcourses.filter = JSON.stringify(filter);
    this.datacount = this.showcourses.data.length.toString();
  }

  channelFilter2() {
    this.courses = [];
    console.log(this.allcourses);
    console.log(this.selectedChannel);
    console.log(this.selectedCourseType);
    console.log(this.selectedStatus);
    console.log(this.selectedCategory);

    this.allcourses.map((data: any) => {
      if (this.selectedChannel == 'all') {
        if (
          data.coursestatus == this.selectedStatus &&
          data.coursetype == this.selectedCourseType &&
          data.category == this.selectedCategory
        ) {
          this.courses.push(data);
        }
      } else if (!this.selectedCourseType) {
        if (
          data.channelid == this.selectedChannel &&
          data.coursestatus == this.selectedStatus &&
          data.category == this.selectedCategory
        ) {
          this.courses.push(data);
        }
      } else if (!this.selectedStatus) {
        if (
          data.channelid == this.selectedChannel &&
          data.coursetype == this.selectedCourseType &&
          data.category == this.selectedCategory
        ) {
          this.courses.push(data);
        }
      } else if (!this.selectedCategory) {
        if (
          data.channelid == this.selectedChannel &&
          data.coursestatus == this.selectedStatus &&
          data.coursetype == this.selectedCourseType
        ) {
          this.courses.push(data);
        }
      } else {
        if (
          data.channelid == this.selectedChannel &&
          data.coursestatus == this.selectedStatus &&
          data.coursetype == this.selectedCourseType &&
          data.category == this.selectedCategory
        ) {
          this.courses.push(data);
        }
      }

      // if (data.channelid == this.selectedChannel && data.coursestatus == this.selectedStatus && data.coursetype == this.selectedCourseType && data.category == this.selectedCategory ) {
      //   this.courses.push(data)
      // }
    });
    this.datacount = this.courses.length.toString();
    this.showcourses = new MatTableDataSource(this.courses);
  }

  channelChnage(data: any) {
    console.log(data);
    this.selectedCategory = '';
    this.selectedCategoryName = 'All';
    this.selectedChannel = data.channelid;
    this.selectedChannelName = data.name;
    this.getCategory();
    this.filterCourse();
  }

  courseTypeChange(data: any) {
    this.selectedCourseType = data.id;
    this.selectedCourseTypeName = data.name;
    this.filterCourse();
  }

  statusChange(data: any) {
    this.selectedStatus = data.id;
    this.selectedStatusName = data.name;
    this.filterCourse();
  }

  categoryChange(data: any) {
    this.selectedCategory = data.categoryid;
    this.selectedCategoryName = data.categoryname;
    this.filterCourse();
  }

  filterCourse() {
    const filter = {
      coursename: this.searchText.toString().trim().toLowerCase(),
      channelid: this.selectedChannel,
      coursetype: this.selectedCourseType,
      coursestatus: this.selectedStatus,
      category: this.selectedCategory,
    };
    this.showcourses.filter = JSON.stringify(filter);
    this.datacount = this.showcourses.data.length.toString();
  }

  duplicateCourse(d: any) {
    console.log(d);

    const dialog = this.dialog.open(CourseDuplicateEditorComponent, {
      width: '500px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      closeOnNavigation: true,
      autoFocus: false,
      panelClass: ['mat-typography'],
      data: {
        coursename: d.coursename,
        courseid: d.courseid,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (dialogResult.duplicateCon) {
          this.refresh();
        }
      }
    });
  }

  announceSortChange(sort: Sort) {
    console.log('hhhhh');
    return;
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.compare(a.coursename, b.coursename, isAsc);
        case 'studentcount':
          return this.compare(
            parseInt(a.studentcount),
            parseInt(b.studentcount),
            isAsc
          );
        case 'type':
          return this.compare(a.type, b.type, isAsc);
        default:
          return 0;
      }
    });
    this.showcourses = new MatTableDataSource(this.courses);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  test() {
    console.log(this.channels);
  }

  getAllCourse() {
    this.courses = [];
    this.isLoading = true;
 

    this.courseService.getAllCourseByInstructor(this.selectedChannel).subscribe(
      (courses: any) => {
        if (courses.returncode == '300') {
          console.log(courses);
          courses.data.map(async (course: any) => {
            await this.courses.push({
              courseid: course.courseid,
              coursename: course.coursename,
              courseimage: course.courseimage,
              // type: course.coursestatus == "0" ? "DRAFT" : "PUBLISHED",
              type: course.coursestatus == '0' ? 'HIDDEN' : 'RELEASED',
              studentcount: course.studentcount,
              instructorname: course.instructorname,
              institute: course.institute,
              channelid: course.channelid,
              coursestatus: course.coursestatus,
              coursetype: course.coursetype,
              category: course.category,
            });
            this.datacount = courses.data.length;
            // this.allcourses = JSON.stringify(JSON.parse(this.courses))
            // console.log(courses);
          });

          this.allcourses = JSON.parse(JSON.stringify(this.courses));
          this.showcourses = new MatTableDataSource(this.courses);
          this.showcourses.filterPredicate = function (
            record: any,
            filter: string
          ) {
            if (filter == '') return true;
            let jsonFilter = JSON.parse(filter);
            let isMatch = false;
            for (let [key, value] of Object.entries(jsonFilter)) {
              if (key == 'coursename') {
                isMatch =
                  record[key as string].toLowerCase().indexOf(value) !== -1;
              } else if (key == 'channelid') {
                isMatch = value == 'all' || record[key as string] == value;
              } else {
                isMatch = value == '' || record[key as string] == value;
              }
              if (!isMatch) return false;
            }
            return isMatch;
          };
          if (this.courses == undefined || this.courses.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 400);

          setTimeout(() => {
            this.showcourses.sort = this.sort;
            this.showcourses.paginator = this.paginator;
          }, 600);

          // this.table.renderRows();
        } else {
          if (courses.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(courses.status, 'warn');
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 400);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );


  }

  getCourses() {
    this.router.navigate([]);
    this.getAllCourse();
  }

  goToManage(courseid: string) {
    this.router.navigateByUrl(`/instructor/courses/${courseid}/info`);
  }

  clear() {
    this.searchText = '';
    this.showcourses.filter = '';
  }
  searchItem() {
    // const searchItem = data.target.value;
    // this.showcourses.filter = searchItem.trim().toLowerCase();
    this.filterCourse();
  }

  refresh() {
    this.isLoading = true;
    this.courses = [];
    if (this.courses == undefined || this.courses.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showcourses = new MatTableDataSource();
    this.getAllCourse();
  }

  onChange(data: any) {
    const searchItem = data.target.value;
    console.log(searchItem);
    if (data.target.value == 'all') {
      this.getAllCourse();
    } else {
      this.showcourses.filter = searchItem.trim().toLowerCase();
    }
  }

  getMyChannel() {
    this.gettingmyChannel = true;
    const searchuserid = this.allinoneService.getKunyekUserId();
    const data = {
      type: '2',
    };
    this.courseService.getMychannel(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.channels = res.datalist;
          this.channels = this.channels.concat(res.datalist);
          // this.getParamChl();
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.gettingmyChannel = false;
      },
      (err) => {
        this.gettingmyChannel = false;
      }
    );
  }

  gotoClass(data: any) {
    console.log(data);
    this.router.navigateByUrl(`instructor/courses/${data.courseid}/classes`);
    //
  }
}
