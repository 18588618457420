import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SubSink } from 'subsink';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-individual-deadlines',
  templateUrl: './individual-deadlines.component.html',
  styleUrls: ['./individual-deadlines.component.scss'],
})
export class IndividualDeadlinesComponent implements OnInit, OnDestroy {
  loadingData: boolean = false;
  addingDueDate: boolean = false;
  isFocus: boolean = false;
  gettingStudents: boolean = false;
  gettingClass: boolean = false;
  isEditing: boolean = false;
  formSubmitted: boolean = false;
  saveLoading: boolean = false;

  searchText: string = '';
  courseid: string = '';
  idToEdit: string = '';
  unitid: string = '';

  duedateList: any = [];

  classList: any = [];
  studentList: any = [];

  subscriptions = new SubSink();

  typeList = [
    {
      name: 'Class',
      id: '001',
    },
    {
      name: 'Student',
      id: '002',
    },
  ];

  deadlineForm = this.formBuider.group({
    type: ['001', Validators.required], // 001 class, 002 student
    classid: ['', Validators.required],
    studentid: ['', Validators.required],
    deadlinedate: ['', Validators.required],
    deadlinetime: ['', Validators.required],
  });

  currentdate!: any;

  get formControl() {
    return this.deadlineForm.controls;
  }

  constructor(
    private dialogRef: MatDialogRef<IndividualDeadlinesComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.courseid = data.courseid;
    this.unitid = data.unitid;
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.getDeadlines();
  }

  getDeadlines() {
    this.loadingData = true;
    const data = {
      unitid: this.unitid,
      courseid: this.courseid,
      contenttype: '7',
    };
    this.subscriptions.sink = this.courseService
      .getAssignmentDeadline(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.duedateList = res.datalist;
            this.duedateList.map((item: any) => {
              item.type = '001';
              if (item.classid == '') {
                item.type = '002';
              }
            });
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || '300',
              'warn'
            );
          }
          this.loadingData = false;
        },
        (err) => {
          this.loadingData = false;
        }
      );
  }

  newIndividualDueDate() {
    this.addingDueDate = true;
    this.dialogRef.updateSize('500px');

    if (this.classList.length == 0) {
      this.getClass();
    }
  }
  cancel() {
    this.addingDueDate = false;
    this.isEditing = false;
    this.idToEdit = '';
    this.formControl.type.enable();
    this.deadlineForm.setValue({
      type: '001',
      classid: '',
      studentid: '',
      deadlinedate: '',
      deadlinetime: '',
    });
    this.dialogRef.updateSize('800px');
  }

  editDuedate(data: any) {
    this.addingDueDate = true;
    this.isEditing = true;
    this.idToEdit = data.id;
    this.deadlineForm.setValue({
      type: data.type,
      classid: data.classid,
      studentid: data.studentid,
      deadlinedate: this.allinoneService.formatDBToShowInEdit(data.deadlinedate),
      deadlinetime: this.allinoneService.f12to24(data.deadlinetime),
    });
    if(data.type == '001' && this.classList.length == 0){
      this.getClass()
    }
    if(data.type == '002' && this.studentList.length == 0){
      this.getStudents()
    }
    this.formControl.type.disable();
    this.formControl.studentid.disable();
    this.dialogRef.updateSize('500px');
  }

  clear() {
    this.searchText = '';
  }

  submitForm() {
    if (
      this.formControl.type.value == '001' &&
      this.formControl.classid.invalid
    ) {
      this.messageService.openSnackBar('Class cannot be blank!', 'warn');
      return;
    }
    if (
      this.formControl.type.value == '002' &&
      this.formControl.studentid.invalid
    ) {
      this.messageService.openSnackBar('Student cannot be blank!', 'warn');
      return;
    }
    if (
      this.formControl.deadlinedate.invalid ||
      this.formControl.deadlinetime.invalid
    ) {
      this.messageService.openSnackBar('Due date and time cannot be blank!', 'warn');
      return;
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    const data = {
      id: this.idToEdit,
      courseid: this.courseid,
      unitid: this.unitid,
      unittype: '7', // assignment unit type
      classid: this.formControl.type.value == '001' ? this.formControl.classid.value : '',
      adduser: this.formControl.type.value == '002' ? this.formControl.studentid.value : '',
      deadlinedate: this.allinoneService.formatDate(
        this.formControl.deadlinedate.value
      ),
      deadlinetime: this.allinoneService.f24to12(
        this.formControl.deadlinetime.value
      ),
    };

    var fun = this.isEditing
      ? this.courseService.updateAssignmentDeadline(data)
      : this.courseService.addAssignmentDeadline(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getDeadlines();
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  getStudents() {
    this.gettingStudents = true;
    this.subscriptions.sink = this.courseService
      .getPaidStudents(this.courseid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.studentList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingStudents = false;
        },
        (err) => {
          this.gettingStudents = false;
        }
      );
  }

  getClass() {
    this.gettingClass = true;
    this.subscriptions.sink = this.courseService
      .getAllClass(this.courseid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.classList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingClass = false;
        },
        (err) => {
          this.gettingClass = false;
        }
      );
  }

  typeChage(event: any) {
    if (event.target.value == '001') {
      if (this.classList.length == 0) {
        this.getClass();
      }
    } else {
      if (this.studentList.length == 0) {
        this.getStudents();
      }
    }
  }

  deleteDuedate(item: any){
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        const data = {
          id: item.id,
          courseid: this.courseid,
          unitid: this.unitid
        }
        this.courseService.deleteAssignmentDeadline(data).subscribe((res: any)=> {
          if(res.returncode == "300"){
            this.getDeadlines();
          }
          else{
            this.messageService.openSnackBar(res.status || res.message || 'Server Error.', 'warn');
          }
          this.loadingService.hide();
        }, err => {
          this.loadingService.hide();
        })
      }
    })
  }
}
