import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import ImageViewer from 'awesome-image-viewer';
import { transferArrayItem } from '@angular/cdk/drag-drop';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { GradeBookResetQuizComponent } from '../grade-book-reset-quiz/grade-book-reset-quiz.component';

@Component({
  selector: 'app-quiz-pdf',
  templateUrl: './quiz-pdf.component.html',
  styleUrls: ['./quiz-pdf.component.scss'],
})
export class QuizPdfComponent implements OnInit {
  @Input() courseid: string = '';
  @Input() unitid: string = '';
  @Input() resultid: string = '';
  @Input() coursename: string = '';
  @Input() studentname: string = '';
  @Input() studentId: string = '';
  @Input() element: any;
  @Input() elementAllData: any;
  @Output() sendData = new EventEmitter<string>();
  unitname: string = '';
  question: any = [];
  isfeedback: boolean = true;
  grading: string = '';
  score: number = 0;
  mark: number = 0;
  totalmark: number = 0;
  isLoading: boolean = true;
  smallscreenview: boolean = false;
  bygroup: boolean = false;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    await this.quizFeedback();
  }

  intToRomanInt(num: number) {
    const romanNumerals: any = {
      1: 'i',
      2: 'ii',
      3: 'iii',
      4: 'iv',
      5: 'v',
      6: 'vi',
      7: 'vii',
      8: 'viii',
      9: 'ix',
      10: 'x'
    };

    return romanNumerals[num] || 'Invalid number';
  }

  quizFeedback() {
    this.courseService.getQuizresult(this.unitid, this.courseid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.resultid = res.resultid;
          this.courseService
            .getFeedback(this.unitid, this.resultid, this.courseid, this.studentId)
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.isLoading = false;
                  console.log(res);
                  this.unitname = res.unitname;
                  this.grading = res.grading;
                  this.score = res.score;
                  this.mark = Number(res.mark);
                  this.totalmark = Number(res.totalmark);
                  this.question = res.datalist;
                  for (let i = 0; i < this.question.length; i++) {
                    // for question format
                    if (this.question[i].type == '005') {
                      var resDropdown = this.dropdownReplaceDisplay(
                        this.question[i].question,
                        this.question[i].answerlist,
                        this.isfeedback,
                        this.question[i].answerid,
                        i
                      );
                      console.log('formatted Dropdown Quiz >>', resDropdown)
                      this.question[i].formattedQuiz = resDropdown;
                    } else if (this.question[i].type == '003') {
                      console.log(' In get quiz>>>>>');
                      var resDropdown = this.fillinblankReplaceDisplay(
                        this.question[i].question,
                        this.question[i].answerid,
                        this.isfeedback
                      );
                      this.question[i].formattedQuiz = resDropdown;
                    }
                    if (this.isfeedback) {
                      console.log('error con feedback');

                      this.score = res.score;
                      // Radio Question
                      if (this.question[i].type == '001') {
                        var index = this.question[i].answerlist.findIndex(
                          (ans: any) =>
                            ans.answerid == this.question[i].answerid
                        );
                        this.question[i].selected = index; //
                        console.log('index ===> ' + this.question[i].selected);
                        this.question[i].explaination =
                          this.question[i].selected == '-1'
                            ? ''
                            : this.question[i].answerlist[
                                this.question[i].selected
                              ].explanation;
                      }

                      // Checkbox quesitons
                      else if (this.question[i].type == '002') {
                        this.question[i].checkboxans =
                          this.question[i].answerid;
                        this.question[i].selectall = false;
                        var count = 0;
                        // if(this.question[i].answerid.length == this.question[i].correctanswer.length){
                        this.question[i].correctanswer.map((item: any) => {
                          if (this.question[i].answerid.includes(item)) {
                            count += 1;
                          }
                        });
                        if (count == this.question[i].correctanswer.length) {
                          this.question[i].selectall = true;
                        }
                      }

                      // Fill in the blank Questions
                      else if (this.question[i].type == '003') {
                        this.question[i].explaination =
                          this.question[i].answerlist[0];
                      }

                      // Drag and drop matching
                      else if (this.question[i].type == '004') {
                        this.question[i].answerid.map((item: any) => {
                          var quesIndex = this.question[
                            i
                          ].question.questions.findIndex(
                            (x: any) => x.questionid == item.questionid
                          );
                          var ansIndex = this.question[i].answerlist.findIndex(
                            (x: any) => x.answerid == item.answerid
                          );
                          if (quesIndex > -1) {
                            this.question[i].question.questions[
                              quesIndex
                            ].anscontanier = []; //
                            if (ansIndex > -1) {
                              this.question[i].question.questions[
                                quesIndex
                              ].anscontanier = [
                                this.question[i].answerlist[ansIndex],
                              ];
                              this.question[i].answerlist.splice(ansIndex, 1);
                            }
                          }
                        });
                      }
                    } else {
                      console.log('feedback con 2');

                      this.question[i].checkboxans = [];
                      if (this.question[i].type != '003') {
                        this.question[i].selected = '-1';
                      } else {
                        this.question[i].selected = '';
                        this.question[i].answerid = [];
                        this.question[i].fixedsequence = true;
                      }
                      if (this.question[i].type == '004') {
                        this.question[i].question.questions.map((item: any) => {
                          item.anscontanier = [];
                        });
                      }
                    }
                  }
                } else {
                  this.isLoading = false;
                  this.messageService.openSnackBar(
                    res.status || res.message,
                    'warn'
                  );
                  this.sendData.emit('false');
                }
              },
              (err) => {
                this.isLoading = false;
                console.log(err);
                this.sendData.emit('false');
              }
            );
        } else {
          this.messageService.openSnackBar(res.status || res.message, 'warn');
          this.sendData.emit('false');
        }
      },
      (err) => {
        console.log(err);
        this.sendData.emit('false');
      }
    );
  }

  dropdownReplaceDisplay(
    question: string,
    answer: any,
    isfeedback: boolean,
    answeridlist: any,
    questionindex: number
  ) {
    let dropdownDisplay: any = [];
    let dropDownIndex: number = 0;
    let typeList = ["{{}}", "___", "____", "_____", "______"];
    const diffpart = question.includes('{{}}');
    const splitPattern = /(___+|____+)/g;
    let parts: string[] = diffpart? question.split(/({{}})/) : question.split(splitPattern).filter(part => part !== '');
    this.question[questionindex].answerid = [];
    if(answer == undefined || answer.length == 0) {
      answer = []
      parts.map((part) => {
        if( typeList.includes(part) ) {
          answer.push('')
        }
      })
    } else if (!Array.isArray(answer)) {
      console.log(' answer id is not in array , ???')
      answer  = [answer]
    }

    parts.map((part, index) => {
      if (!typeList.includes(part)) {
        dropdownDisplay.push({
          type: '001',
          text: part,
          option: null,
          index: index,
          selectOption: '',
        });
      } else {
        answer.map((ans: any, i: any) => {
          if (dropDownIndex == i) {
            this.question[questionindex].answerid.push(
              isfeedback ? answeridlist[i] : null
            );
            dropdownDisplay.push({
              type: '002',
              text: null,
              option: ans.blankanslist,
              index: dropDownIndex,
              selectOption: isfeedback ? answeridlist[i] : null,
            });
          }
        });
        dropDownIndex++;
      }
    });
    return dropdownDisplay;

    // let parts: string[] = question.split(/({{}})/);
    // this.question[questionindex].answerid = [];
    // parts.map((part, index) => {
    //   if (part !== '{{}}') {
    //     dropdownDisplay.push({
    //       type: '001',
    //       text: part,
    //       option: null,
    //       index: index,
    //       selectOption: '',
    //     });
    //   } else {
    //     answer.map((ans: any, i: any) => {
    //       if (dropDownIndex == i) {
    //         this.question[questionindex].answerid.push(
    //           isfeedback ? answeridlist[i] : null
    //         );
    //         // this.submitAnswerList[0].push(isfeedback ? answeridlist[i] : null);
    //         dropdownDisplay.push({
    //           type: '002',
    //           text: null,
    //           option: ans.blankanslist,
    //           index: dropDownIndex,
    //           selectOption: isfeedback ? answeridlist[i] : null,
    //         });
    //       }
    //     });
    //     dropDownIndex++;
    //   }
    // });
    // return dropdownDisplay;
  }
  areListsEqual(
    list1: string[],
    list2: string[],
    casesensitive: boolean
  ): boolean {
    if (list1.length !== list2.length) {
      console.log('wrong in length');
      return false;
    }
    var lowerlist1 = list1;
    var lowerlist2 = list2;
    if (!casesensitive) {
      lowerlist1 = list1.map((str) => str.toLowerCase());
      lowerlist2 = list2.map((str) => str.toLowerCase());
    }

    // Sort both lists to ensure order does not matter
    lowerlist1.sort();
    lowerlist2.sort();
    for (let i = 0; i < lowerlist1.length; i++) {
      if (lowerlist1[i] !== lowerlist2[i]) {
        return false;
      }
    }

    return true;
  }
  fillinblankReplaceDisplay(
    question: string,
    answer: any,
    isfeedback: boolean
  ) {
    let blankDisplay: any = [];
    let blankIndex: number = 0;
    let typeList = ["{{}}", "___", "____", "_____", "______"];
    const diffpart = question.includes('{{}}');
    const splitPattern = /(___+|____+)/g;
    
    let parts: string[] = diffpart? question.split(/({{}})/) : question.split(splitPattern).filter(part => part !== '');

    if (answer == undefined || answer.length == 0) {
      answer = [];
      parts.map((part) => {
        if (typeList.includes(part)) {
          answer.push('');
        }
      });
    } else if (!Array.isArray(answer)) {
      console.log(' answer id is not in array , ???');
      answer = [answer];
    }
    console.log(answer, 'should be in array format');
    parts.map((part) => {
      if (!typeList.includes(part)) {
        blankDisplay.push({
          type: '001',
          text: part,
        });
      } else {
        isfeedback
          ? answer.map((ans: any, i: any) => {
              if (blankIndex == i) {
                blankDisplay.push({
                  type: '002',
                  text: part,
                  index: blankIndex,
                  selected: ans,
                });
              }
            })
          : blankDisplay.push({
              type: '002',
              text: part,
              index: blankIndex,
              selected: '',
            });
        blankIndex++;
      }
    });
    return blankDisplay;
  }
  imageViewer(img: any, index: number) {
    var imgArray: any = [];
    img.map((item: any) => {
      imgArray.push({
        mainUrl: item.imageurl,
        thumbnailUrl: item.imageurl,
        description: '',
      });
    });
    const viewer = new ImageViewer({
      images: imgArray,
      currentSelected: index,
      stretchImages: true,
      isZoomable: false,
    });
  }
  matchingImageViewer(img: any) {
    var imgArray: any = [];
    imgArray.push({
      mainUrl: img,
      thumbnailUrl: img,
      description: '',
    });
    const viewer = new ImageViewer({
      images: imgArray,
      // currentSelected: index,
      stretchImages: true,
      isZoomable: false,
    });
  }
  isMobileView: boolean = false;
  answerList: any = [];
  getAnswerList() {
    this.answerList = [];
    for (let i = 0; i < this.question.length; i++) {
      if (this.question[i].type == '001') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid:
            this.question[i].selected == '-1'
              ? '-1'
              : this.question[i].answerlist[this.question[i].selected].answerid,
        });
      } else if (this.question[i].type == '003') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].answerid,
        });
      } else if (this.question[i].type == '002') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].checkboxans
            ? this.question[i].checkboxans
            : [],
        });
      } else if (this.question[i].type == '005') {
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: this.question[i].answerid,
        });
      } else {
        var tempArray: any[] = [];
        for (var j = 0; j < this.question[i].question.questions.length; j++) {
          var tempObj = {
            questionid: this.question[i].question.questions[j].questionid,
            answerid:
              this.question[i].question.questions[j].anscontanier.length > 0
                ? this.question[i].question.questions[j].anscontanier[0]
                    .answerid
                : '',
          };
          tempArray.push(tempObj);
        }
        this.answerList.push({
          quizid: this.question[i].quizid,
          answerid: tempArray,
        });
      }
    }
    console.log(this.answerList, 'answerlisy');
    return this.answerList;
  }

  outputAnsToParent() {
    // this.changedAnsList.emit(this.question);

    if (!this.bygroup && !this.isMobileView) {
      console.log('out put');
      var al = this.getAnswerList();
      // this.changedAnsList.emit(al);
    }
  }
  callAPIINParent() {
    if (!this.bygroup && !this.isMobileView) {
      // this.callQuizCache.emit();
    }
  }
  quiztimer: any = '00:00';

  callAPIForNoGroupMobileView() {
    var al = this.getAnswerList();
    var tempData = {
      unitid: this.unitid,
      currentPage: '0',
      time: this.quiztimer,
      answerlist: al,
    };
    // this.addQuizCache(tempData);
  }

  drop(event: any) {
    var previousIndex = event.item.data;
    if (event.previousContainer === event.container) {
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        previousIndex,
        0
      );
      if (event.container.data.length > 1) {
        transferArrayItem(
          event.container.data,
          event.previousContainer.data,
          1,
          previousIndex
        );
      }
      // if (!this.bygroup) {
      //   this.outputAnsToParent();
      //   this.callAPIINParent();
      // }
      // if (!this.bygroup && this.isMobileView) {
      //   this.callAPIForNoGroupMobileView();
      // }
    }
    if (!this.bygroup) {
      this.outputAnsToParent();
      this.callAPIINParent();
    }
    if (!this.bygroup && this.isMobileView) {
      this.callAPIForNoGroupMobileView();
    }
  }

  restoreDrop(event: any) {
    if (event.previousContainer === event.container) {
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        0
      );
    }
    if (!this.bygroup) {
      this.outputAnsToParent();
      this.callAPIINParent();
    }
    if (!this.bygroup && this.isMobileView) {
      this.callAPIForNoGroupMobileView();
    }
  }

  downloadQuizPDF() {
    const data = document.getElementById('quiz-pdf-print');
    html2canvas(data!, { useCORS: true, allowTaint: true, scrollY: 0 }).then(
      (canvas) => {
        const image = { type: 'jpeg', quality: 0.98 };
        const margin = [0.5, 0.5];
        const filename = 'myfile.pdf';

        var imgWidth = 8.5;
        var pageHeight = 11;

        var innerPageWidth = imgWidth - margin[0] * 2;
        var innerPageHeight = pageHeight - margin[1] * 2;

        // Calculate the number of pages.
        var pxFullHeight = canvas.height;
        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        var nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        var pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        var pageCanvas = document.createElement('canvas');
        var pageCtx = pageCanvas.getContext('2d');
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        // Initialize the PDF.
        var pdf = new jsPDF('p', 'in', [8.5, 11]);

        for (var page = 0; page < nPages; page++) {
          // Trim the final page to reduce file size.
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight =
              (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          // Display the page.
          var w = pageCanvas.width;
          var h = pageCanvas.height;
          pageCtx!.fillStyle = 'white';
          pageCtx!.fillRect(0, 0, w, h);
          pageCtx!.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          // Add the page to the PDF.
          if (page > 0) pdf.addPage();
          debugger;
          var imgData = pageCanvas.toDataURL(
            'image/' + image.type,
            image.quality
          );
          pdf.addImage(
            imgData,
            image.type,
            margin[1],
            margin[0],
            innerPageWidth,
            pageHeight
          );
        }

        pdf.save(`${this.studentname}-${this.unitname}.pdf`);
      }
    );
    // html2canvas(data!).then((canvas: any) => {
    //   const imgWidth = 208;
    //   const pageHeight = 295;
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   let heightLeft = imgHeight;
    //   let position = 0;
    //   heightLeft -= pageHeight;
    //   const doc = new jsPDF('p', 'mm');
    //   doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     doc.addPage();
    //     doc.addImage(
    //       canvas,
    //       'PNG',
    //       0,
    //       position,
    //       imgWidth,
    //       imgHeight,
    //       '',
    //       'FAST'
    //     );
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save(`${this.studentname}-${this.unitname}.pdf`);
    // });
  }

  resetQuiz(user: any, result: any, quizname: any) {
    let dialogRef = this.dialog.open(GradeBookResetQuizComponent, {
      // width: '500px',
      minWidth: '400px',
      maxWidth: '95vw',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        student: user.username || user.userid,
        id: result.id,
        questioncount: result.questioncount,
        unitname: quizname,
        unitid: result.unitid,
        grade: result.grade,
        type: '6',
      },
    });
  }

  cancel() {
    this.sendData.emit('false');
    // window.location.reload();
  }
}
