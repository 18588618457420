import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  otp: string = '';
  verified: boolean = false;
  existsuser: boolean = false;
  showOtpComponent: boolean = true;
  isStillVerifying: boolean = false;
  isStillCreateUser: boolean = false;
  isStillResend: boolean = false;
  userFormSubmitted: boolean = false;
  isuserCreated: boolean = false;

  userForm = this.formBuider.group({
    name: ['', Validators.required],
  });

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.verify();
    }
  }

  constructor(
    private router: Router,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinOneService: AllInOneService,
    private route: ActivatedRoute,
    private formBuider: FormBuilder,
    private cookieService: CookieService
  ) {
    if (this.allinOneService.getAtoken()) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    const existsuser = sessionStorage.getItem('existsuser');
    if (existsuser) {
      if (existsuser == '1') {
        this.existsuser = true;
      } else {
        this.existsuser = false;
      }
    }
  }
  onOtpChange(otp: any) {
    this.otp = otp;
  }

  verify() {
    if (this.otp.length == 6) {
      this.isStillVerifying = true;

      const username = sessionStorage.getItem('kunyekusername') || '';

      this.courseService.verify(username, this.otp).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinOneService.setCookie('atoken', res.atoken);
            // this.verified = true;
            window.location.reload();
          } else {
            if (res.returncode == '202') {
              this.messageService.openSnackBar(
                'Invalid OTP. Please try again.',
                'warn'
              );
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.verified = false;
            this.isStillVerifying = false;
          }
        },
        (err) => {
          this.verified = false;
          this.isStillVerifying = false;
        }
      );
    }
  }

  resend() {
    if (!this.isStillResend) {
      this.isStillResend = true;
      const userid = this.allinOneService.getKunyekUserId();
      const password = this.allinOneService.getKunyekPassword();
      this.courseService.signIn(userid, password).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            sessionStorage.setItem('session', res.session);
            this.isStillResend = false;
          } else {
            this.isStillResend = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.isStillResend = false;
        }
      );
    }
  }
}
