<div class="container">
  <form class="formbox" #userForm="ngForm" (ngSubmit)="handleSubmit()" novalidate>
    <div *ngIf="!isImportExcel" class="section">
      <div style="width: 50%;">
        <label class="label" for="username">Username:</label>
        <input class="form-control" required id="username" name="username" placeholder="Name" type="text" [(ngModel)]="useritem.username" #username="ngModel" />
        <div *ngIf="username.invalid && username.touched" class="text-danger">
          Username is required.
        </div>
      </div>

      <div style="width: 50%;">
        <label class="label" for="userid">User ID:</label>
        <input class="form-control" required id="userid" name="userid" placeholder="john@domain.com, +959********" type="text" [(ngModel)]="useritem.userid" #userid="ngModel" />
        <div *ngIf="userid.invalid && userid.touched" class="text-danger">
          User ID is required.
        </div>
      </div>
    </div>

    <div class="section">
      <div style="width: 50%;">
        <label class="label" for="membertype">Member Type:</label>
        <select class="form-select" required id="membertype" name="membertype" [(ngModel)]="useritem.membertype" #membertype="ngModel">
          <option value = '' selected >-</option>
          <option *ngFor="let member of memberlist" [value]="member.id">{{ member.name }}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingmembertype"></mat-progress-bar>
        <div *ngIf="membertype.invalid && membertype.touched" class="text-danger">
          Member Type is required.
        </div>
      </div>

      <div style="width: 50%;">
        <label class="label" for="role">Role:</label>
        <select class="form-select" id="role" name="role" [(ngModel)]="useritem.role" #role="ngModel">
          <option value = '' selected >-</option>
          <option *ngFor="let role of role_list" [value]="role.id">{{ role.name }}</option>
        </select>
      </div>
    </div>

    <div class="d-flex my-3">
      <input id="isImport" class="me-2" type="checkbox" name="isImportExcel" [(ngModel)]="isImportExcel" >
      <label for="isImport" style="cursor: pointer;">Import From Excel?</label>
    </div>

    <div class="d-flex">
      <button class="btn new-class me-2" type="button" (click)="navigateBack()">Cancel</button>

      <div *ngIf="isImportExcel" class="button-container">
        <label for="fileUpload" [ngClass]="createUserLoading ? 'disable-button' : '' "  class="btn new-class">Import Excel</label>
        <input id="fileUpload" type="file" [disabled]="loadingFile || createUserLoading" (change)="handleFileInput($event)" style="display: none;" accept=".xlsx, .xls" />
        
        <span class="filename" (click)="downloadSample()">Sample Template</span>
  
      </div>
  
      <button *ngIf="!isImportExcel" class="btn new-class" type="submit" [disabled]="userForm.invalid || loadingFile || createUserLoading">Save</button>
  
      <div *ngIf="loadingFile || createUserLoading" class="text-center my-3">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>


    <div *ngIf="fileError" class="text-danger text-center" style="margin-top: 100px;">
      {{ fileError }}
    </div> 
  </form>
</div>
