import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../shared/services/loading.service';
import { HttpClient } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LearningTrackCourseAddComponent } from '../learning-track-course-add/learning-track-course-add.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LearningTrackUserAddComponent } from '../learning-track-user-add/learning-track-user-add.component';
import { SubSink } from 'subsink';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { element } from 'protractor';

@Component({
  selector: 'app-learning-track',
  templateUrl: './learning-track.component.html',
  styleUrls: ['./learning-track.component.scss']
})
export class LearningTrackComponent implements OnInit {

  channelid: string = '';
  channels: any[] = [];
  selectedChannel: string = '';
  gettingChannels: boolean = false;

  isAdding: boolean = false;
  isEditing: boolean = false;
  LTList: any = [];

  searchText: string = '';
  isFocus: boolean = false;
  paginate: boolean = false;
  displayedColumns: any;
  isLoading: boolean = true;

  showLT: MatTableDataSource<any> = new MatTableDataSource(
    this.LTList
  );

  subscriptions = new SubSink();
  getListSub!: Subscription;

  // Add Form
  saveLoading: boolean = false;
  formSubmitted: boolean = false;

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  // Add Form Course
  gettingCourse: boolean = false;
  courseList: any = [];
  selectedCourseList: any = [];

  submitForm = {
    id : '',
    name: '',
    description: '',
    duration: 30,
    order: false
  }


  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'name',
      'description',
      'courses',
      'duration',
      'action'
    ];
    this.getChannels();
    if (this.channels.length > 0) {
      this.getCourses();
    }
    this.getAllLT();
  }

  channelChange() {
    this.selectedCourseList = [];
    this.getCourses();
    this.getAllLT();
  }

  getChannels() {
    // if (this.role == 'supervisor') {
    //   this.channels = this.allinoneService.supervisorChannels;
    // } else {
    //   this.channels = this.allinoneService.adminChannels;
    // }
    this.gettingChannels = true;
    this.channels = this.allinoneService.hradminChannels;
    if (this.channels.length > 0) {
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.selectedChannel = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
    }
    this.gettingChannels = false;
  }

  test() {
    // console.log(this.selectedChannel);
    // this.gettingCourse = true;
    this.router.navigateByUrl(`hradmin/learningtrack/${this.selectedChannel}/user`)
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedCourseList, event.previousIndex, event.currentIndex);
    // this.sectionsortKeyUpdate();
  }

  saveData() {
    this.formSubmitted = true;
    if (!this.submitForm.name || !this.submitForm.duration) {
      return
    }

    if (this.selectedCourseList.length < 1) {
      this.messageService.openSnackBar("Please add at least one course.", "warn")
      return
    }

    this.saveLoading = true;

    var courseData: any = [];
    console.log(this.selectedCourseList);
    if (this.selectedCourseList.length > 0) {
      for (var i = 0; i < this.selectedCourseList.length; i++) {
        courseData.push({
          order: i + 1,
          id: this.selectedCourseList[i].courseid
        })
      }
    }

    // courseidlist
    // channelid
    // name
    // description
    // order
    var data = {
      id : this.submitForm.id,
      name: this.submitForm.name,
      description: this.submitForm.description,
      duration: this.submitForm.duration.toString(),
      courseidlist: courseData,
      channelid: this.selectedChannel,
      order: this.submitForm.order
    }
    console.log(data);

    var fun = this.isEditing ? 
    this.courseService.updateLearningTrack(data)
    : this.courseService.addLearningTrack(data)

    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          this.LTList = [];
          this.showLT = new MatTableDataSource(this.LTList);
          this.getAllLT();
          this.isAdding = false;
          this.isEditing = false;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.formSubmitted = false;
      },
      (err: any) => {
        this.saveLoading = false;
        this.formSubmitted = false;
      }
    )

  }

  editLT(data: any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm = {
      id : data.id,
      name: data.name,
      description: data.description,
      duration: data.duration,
      order: data.order
    }
    this.selectedCourseList = data.courseidlist
    // if(this.selectedCourseList.length > 0) {
    //   this.selectedCourseList.map((x:any) => {
    //     const checkexist = this.courseList.findIndex(
    //       (item: any) => item.courseid == x.courseid
    //     );
    //     if(checkexist > -1) {
    //       this.courseList.splice(checkexist, 1);
    //     }
    //   })
    // }
  }

  getAllLT() {
    this.isLoading = true;
    var data = {
      channelid: this.selectedChannel
    }
    this.getListSub && this.getListSub.unsubscribe();
    this.getListSub = this.courseService.getLearningTrack(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.LTList = res.datalist;
        this.showLT = new MatTableDataSource(this.LTList);
      } else {
        this.messageService.openSnackBar(
          res.message || res.status || 'Server Error',
          'warn'
        );
      }
      this.isLoading = false;
    },
      (err: any) => {
        this.isLoading = false;
      }
    )
  }

  gotoMember(d: any) {
    console.log(d);
    
    this.router.navigateByUrl(`hradmin/learningtrack/${this.selectedChannel}/${d.id}/user`)
  }

  newLT() {
    this.isAdding = true;
  }

  deleteLT(d: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.isLoading = true;
        const data = {
          id: d.id,
          channelid: this.selectedChannel,
          // learningpathid: d.id
        }
        this.courseService.deleteLT(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.LTList.findIndex(
                (x: any) => x.id == d.id
              );
              this.LTList.splice(i, 1);
              this.showLT = new MatTableDataSource(this.LTList);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.isLoading = false;
            this.loadingService.hide();
          },
          (err) => {
            this.isLoading = false;
            this.loadingService.hide();
          }
        )
      }
    })
  }

  getCourses() {
    this.gettingCourse = true;
    this.courseList = [];
    this.courseService.getPrerequisiteCourses(this.selectedChannel).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.courseList = res.data;
          var temp = res.data;
          for(var i = 0; i < temp.length; i++) {
            if(temp[i]['prerequisites'].length == 0) {
              this.courseList.push(temp[i])
            }
          }
          // var temp = res.data;
          // if(this.selectedCourseList.length > 0) {
          //   this.selectedCourseList.map((x:any) => {
          //     const checkexist = temp.findIndex(
          //       (item: any) => item.courseid == x.courseid
          //     );
          //     if(checkexist > -1) {
          //       temp.splice(checkexist, 1);
          //     }
          //   })
          // }
          // this.courseList = temp;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCourse = false;
      },
      (err) => {
        this.gettingCourse = false;
      }
    );
  }

  plusCourse() {
    var temp = JSON.parse(JSON.stringify(this.courseList));
    if(this.selectedCourseList.length > 0) {
      this.selectedCourseList.map((x:any) => {
        const checkexist = temp.findIndex(
          (item: any) => item.courseid == x.courseid
        );
        console.log('c i');
        console.log(checkexist);
        if(checkexist > -1) {
          temp.splice(checkexist, 1);
        }
      })
    }
    let dialogRef = this.dialog.open(LearningTrackCourseAddComponent, {
      minWidth: '600px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseList: temp
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      console.log(dialogResult);

      if (dialogResult) {
        if (dialogResult.selectedCourseList.length > 0) {
          dialogResult.selectedCourseList.map((d: any) => {
            this.selectedCourseList.push(d);
          })
        }
      }
    })
  }

  deleteSelectedCourse(course: any, i: any) {
    console.log('t');
    
    console.log(course);
    console.log(this.courseList);
    console.log(this.courseList.length);
    
    
    // this.courseList.push(course);
    this.selectedCourseList.splice(i, 1);

    console.log('e');
    console.log(this.courseList);
    console.log(this.courseList.length);

    console.log(this.selectedCourseList);
    console.log(this.selectedCourseList.length);
    
    
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.saveLoading = false;
    this.submitForm = {
      id : '',
      name: '',
      description: '',
      duration: 1,
      order: false
    }
    this.selectedCourseList = [];
  }

  sortData(sort: Sort) {
    const data = this.LTList;
    console.log(data);

    if (!sort.active || sort.direction === '') {
      this.LTList = data;
      return;
    }

    this.LTList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(a.description, b.description, isAsc);
        default:
          return 0;
      }
    });
    this.showLT = new MatTableDataSource(this.LTList);
  }

  refresh() {
    this.isLoading = true;
    this.LTList = [];
    if (this.LTList == undefined || this.LTList.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showLT = new MatTableDataSource();
    this.getAllLT();
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showLT.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = '';
    this.showLT.filter = '';
  }

}
