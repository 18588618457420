import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-duplicate-files',
  templateUrl: './duplicate-files.component.html',
  styleUrls: ['./duplicate-files.component.scss']
})
export class DuplicateFilesComponent implements OnInit {

  filelist: any[] = []

  constructor(public dialogRef: MatDialogRef<DuplicateFilesComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
      this.filelist = data.filelist
  }

  ngOnInit() {

  }


  onConfirm(): void {
      this.dialogRef.close(true);
  }

  onDismiss(): void {
      this.dialogRef.close(false);
  }

}
