import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { Answer, Question } from '../../shared/models/Quiz';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-template-questions-setup',
  templateUrl: './template-questions-setup.component.html',
  styleUrls: ['./template-questions-setup.component.scss'],
})
export class TemplateQuestionsSetupComponent implements OnInit {
  quesitontypes = [
    {
      name: 'Multiple Choice Question (Radio)',
      id: '001',
    },
    {
      name: 'Multiple Choice Question (Checkbox)',
      id: '002',
    },
    {
      name: 'Textarea',
      id: '003',
    },
    {
      name: 'Rating',
      id: '004',
    },
  ];
  config = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'insertUnorderedList',
        'insertOrderedList',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  isEditing: boolean = false;
  isStillUpload: boolean = false;

  type: string = '001';
  question: string = '';
  sortkey: string = '-1';
  templateid: string = '';

  courseid : string = '';

  correctanswer: any[] = [];
  answers: any[] = [
    {
      answerid: '0',
      answer: '',
      explanation: '',
      config: {
        editable: true,
        spellcheck: true,
        height: '3rem',
        minHeight: '3rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        showToolbar: false,
      },
    },
    {
      answerid: '1',
      answer: '',
      explanation: '',
      config: {
        editable: true,
        spellcheck: true,
        height: '3rem',
        minHeight: '3rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        showToolbar: false,
      },
    },
  ];

  qforedit!: Question;
  questionForEdit!: Question;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<TemplateQuestionsSetupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data.courseid) {
      this.courseid = data.courseid
    }
    if (data.templateid) {
      this.templateid = data.templateid;
      this.sortkey = data.sortkey;
      this.questionForEdit = data.question;
    }
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    console.log(this.questionForEdit);
    if (this.questionForEdit) {
      this.qforedit = JSON.parse(JSON.stringify(this.questionForEdit));
      this.isEditing = true;
      this.question = this.qforedit.question;
      this.type = this.qforedit.type;
      if (this.type == '001' || this.type == '002') {
        this.correctanswer = this.qforedit.correctanswer;
        this.sortkey = this.qforedit.sortkey;
        this.answers = [];
        this.qforedit.answerlist.map((item: any) => {
          item = {
            answerid: item.answerid,
            answer: item.answer,
            explanation: item.explanation,
            config: {
              editable: true,
              spellcheck: true,
              height: '3rem',
              minHeight: '3rem',
              placeholder: 'Enter text here...',
              translate: 'no',
              defaultParagraphSeparator: 'p',
              showToolbar: false,
            },
          };
          this.answers.push(item);
        });
      }
    }
  }

  removeAns(index: number) {
    if (this.answers.length > 1) {
      this.answers.splice(index, 1);
      this.answers.map((item: Answer, index: number) => {
        item.answerid = index.toString();
      });
    }
  }

  onfocus() {
    if (this.answers.length < 5 && (this.type == '001' || this.type == '002')) {
      this.answers.push({
        answerid: this.answers.length.toString(),
        answer: '',
        explanation: '',
        correct: false,
        config: {
          editable: true,
          spellcheck: true,
          height: '3rem',
          minHeight: '3rem',
          placeholder: 'Enter text here...',
          translate: 'no',
          defaultParagraphSeparator: 'p',
          showToolbar: false,
        },
      });
    }
  }

  cancel() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      this.loadingService.hide();
      if (dialogResult) {
        this.dialogRef.close();
      }
    });
  }

  save() {
    if (!this.isStillUpload) {
      if (this.question.toString().trim() == '') {
        this.messageService.openSnackBar('Please enter the question.', 'warn');
        return;
      }
      var finalquestion: any = this.question;
      var finalcasesensitve = 'false';
      var finalanswer: any[] = [];
      if (this.type == '001' || this.type == '002') {
        if (this.answers.length < 2) {
          this.messageService.openSnackBar(
            'There should be at least two answers',
            'warn'
          );
          return;
        }
        var temp = this.answers.filter(
          (ans: Answer) => ans.answer.toString().trim() == ''
        );
        if (temp.length > 0) {
          this.messageService.openSnackBar(
            'Please fill all the answers.',
            'warn'
          );
          return;
        }
        this.answers.map((item: any) => {
          var temp = JSON.parse(JSON.stringify(item));
          delete temp.config;
          finalanswer.push(temp);
        });
      }
      this.isStillUpload = true;
      var fun = this.isEditing
        ? this.courseService.updateQuestion(
            this.qforedit.quizid,
            finalquestion,
            finalanswer,
            this.correctanswer,
            this.type,
            this.sortkey.toString(),
            finalcasesensitve,
            this.courseid,
          )
        : this.courseService.addQuestion(
            this.templateid,
            this.courseid,
            finalquestion,
            finalanswer,
            this.correctanswer,
            this.type,
            this.sortkey.toString(),
            finalcasesensitve,
            true,
          );
      // return;
      fun.subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.dialogRef.close(res);
            this.messageService.openSnackBar('Successful.', 'success');
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
            this.isStillUpload = false;
          }
        },
        (err) => {
          this.isStillUpload = false;
        }
      );
    }
  }
}
