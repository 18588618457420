import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss'],
})
export class ReportFilterComponent implements OnInit {
  gettingTypes: boolean = false;
  gettingCourses: boolean = false;
  gettingCategory: boolean = false;

  reportForm: any;
  channels: any[] = [];
  categories: any[] = [];
  courses: any[] = [];

  categroyList: any[] = [];

  role: string = '';
  coursetypes = [
    {
      id: '001',
      name: 'Self-paced',
    },
    {
      id: '002',
      name: 'Instructor-led',
    },
    {
      id: '003',
      name: 'Hybrid',
    },
  ];

  schedules = [
    {
      id: '1',
      name: 'Unscheduled',
    },
    {
      id: '2',
      name: 'On-going',
    },
    {
      id: '3',
      name: 'Completed',
    },
  ];

  classcodelist: any[] = [];
  departmentlist: any[] = [];
  divisionlist: any[] = [];
  branchlist: any[] = [];

  constructor(
    private courseService: CourseService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<ReportFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    
    this.role = data.role;
    this.reportForm = JSON.parse(JSON.stringify(data.reportForm));
    this.channels = data.channels;
    this.categories = data.categories;
    this.courses = data.courses;
  }

  ngOnInit(): void {
    this.getTypes();
    this.getCategory(this.reportForm.channelid);
  }

  channelChange(event: any) {
    
    this.getCourses();
    this.reportForm.branches = [];
    this.reportForm.departments = [];
    this.reportForm.divisions = [];
    this.reportForm.posts = [];
    this.reportForm.coursename = '';
    this.reportForm.postsname = [];
    this.getTypes();
    this.getCategory(event);
  }

  getTypes() {
    this.gettingTypes = true;
    this.courseService.getType(this.reportForm.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.classcodelist = res.classcodelist;
          this.branchlist = res.branchlist;
          this.divisionlist = res.divisionlist;
          this.departmentlist = res.departmentlist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingTypes = false;
      },
      (err) => {
        this.gettingTypes = false;
      }
    );
  }

  save() {
    const postlist = this.classcodelist.filter((x: any) =>
      this.reportForm.posts.includes(x.id)
    );
    var postname: any[] = [];
    postlist.map((item: any) => {
      postname.push(item.name);
    });
    const coursetypelist = this.coursetypes.filter((x: any) =>
      this.reportForm.coursetypes.includes(x.id)
    );
    var coursetypename: any[] = [];
    coursetypelist.map((item: any) => {
      coursetypename.push(item.name);
    });
    this.reportForm.coursetypesname = coursetypename;
    this.reportForm.postsname = postname;
    this.dialogRef.close({ report: this.reportForm, courses: this.courses });
  }

  close() {
    this.dialogRef.close();
  }

  getCategory(cid : any) {
    this.gettingCategory = true;
    var data = {
      channelid : cid
    }
    this.courseService.getCategoryListWithCounts(data).subscribe(
      (res:any) => {
        if (res.returncode == '300') {
          this.categroyList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCategory = false;
      },
      (err) => {
        this.gettingCategory = false;
      }
    )
  }

  getCourses() {
    this.gettingCourses = true;
    this.courseService
      .getCoursesByChannel(this.reportForm.channelid, '')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.courses = res.datalist;
            if (this.courses.length > 0) {
              this.reportForm.courseid = this.courses[0].courseid;
              this.reportForm.coursename = this.courses[0].coursename;
            }
            else{
              this.reportForm.courseid = '';
              this.reportForm.coursename = '';
            }
            
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingCourses = false;
        },
        (err) => {
          this.gettingCourses = false;
        }
      );
  }

  courseChange() {
    this.reportForm.coursename = this.courses.find(
      (x: any) => x.courseid == this.reportForm.courseid
    ).coursename;
  }

  // categoryChange() {
  //   if(this.reportForm.categoryid != ''){
  //     this.reportForm.categoryname = this.categories.find(
  //       (x: any) => x.categoryid == this.reportForm.categoryid
  //     ).categoryname;
  //   }
  //   else{
  //     this.reportForm.categoryname = ''
  //   }
    
  // }

  clearall() {
    this.reportForm = {
      startdate: this.reportForm.startdate,
      enddate: this.reportForm.enddate,
      channelid: this.reportForm.channelid,
      categoryid: '',
      categoryname: '',
      courseid: '',
      coursename: '',
      searchtype: this.reportForm.searchtype,
      branches: [],
      divisions: [],
      posts: [],
      postsname: [],
      departments: [],
      coursetypes: [],
      coursetypesname: [],
    };
  }
}
