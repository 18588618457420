<app-breadcrumb [s_breadcrumb]="'My Channels'"></app-breadcrumb>


<div class="content">
    <div class="d-flex">
        <div class="me-auto">

        </div>
        <div class="mt-4 mb-3 me-2">
            Total :<b> {{datacount}}</b>
        </div>


        <div class="input-group mt-3 mb-3" style="width: 260px !important;">
            <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
            <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}" *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4;color: #00bcd4;">
                <i class="fa fa-times" ></i>
            </button>
        </div>

        <button class="btn new-chl mt-3 mb-3" matTooltip="Refresh" (click)="refresh()" type="button">
            <i class="fa fa-refresh" ></i>
        </button>
    </div>
    <div style="overflow: auto;">
        <table mat-table #table [dataSource]="show_mychl" matSort class="mat-elevation-z1" style="width: 100%;">

            <!-- Logo Column -->
            <ng-container matColumnDef="image">

                <th mat-header-cell *matHeaderCellDef></th>

                <td mat-cell *matCellDef="let element" (click)="element.status == 0 ? '' :  editChannel(element)" style="position: relative; overflow: hidden;">



                    <div *ngIf="!element.url" style="background-image: url('../../../assets/images/khub.png')" class="channel-listing-image" [ngClass]="{'blur' : element.status == '0'}">

                    </div>

                    <div *ngIf="element.url" style="background-image: url({{element.url}})" class="channel-listing-image" [ngClass]="{'blur' : element.status == '0'}">

                    </div>

                    <div class="top-cover" *ngIf="element.status == '0'">

                        Disabled

                    </div>

                </td>



            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Channel Name </th>
                <td mat-cell *matCellDef="let element" (click)="element.status == 0 ? '' :  editChannel(element)"> {{element.name}} </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description"> Description </th>
                <td mat-cell *matCellDef="let element" px-3 class="desc" (click)="element.status == 0 ? '' :  editChannel(element)"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="member">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description"> Members </th>
                <td mat-cell *matCellDef="let element" px-3 class="clickable" (click)="gotoMember(element)"> {{element.totalmember}} </td>
            </ng-container>

            <ng-container matColumnDef="course">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description"> Courses </th>
                <td mat-cell *matCellDef="let element" px-3 class="clickable" (click)="gotoDashboard(element)"> {{element.totalcourse}} </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
                <td mat-cell *matCellDef="let element" px-3 style="text-align: center;">

                    <button [disabled]="element.status == 0" [style.color]="element.status == 0 ? 'grey' : 'primary'" [style.border-color]="element.status == 0 ? 'grey' : 'primary'" class="btn btn-outline-primary btn-custom-rounded me-2" matTooltip="Edit Channel" (click)="editChannel(element)">
                        <i class="fa fa-edit"></i>
                    </button>
                    <!-- <button [disabled]="element.status == 0" [style.color]="element.status == 0 ? 'grey' : 'primary'" [style.border-color]="element.status == 0 ? 'grey' : 'primary'" class="btn btn-outline-primary btn-custom-rounded me-2" matTooltip="Students Analytics" (click)="userAnalytics(element)">
                        <i class="fa fa-bar-chart"></i>
                    </button> -->
                    <button [disabled]="element.status == 0" [style.color]="element.status == 0 ? 'grey' : 'primary'" [style.border-color]="element.status == 0 ? 'grey' : 'primary'" class="btn btn-outline-primary btn-custom-rounded" matTooltip="Create Course" (click)="createCourse(element)">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td colspan="6">
                    <span class="nodata" *ngIf="!gettingChannel">Empty</span>
                    <span class="nodata" *ngIf="gettingChannel">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                    </span>
                </td>
            </tr>
        </table>

        <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>