import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { CreateSubCategoryComponent } from '../create-sub-category/create-sub-category.component';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss'],
})
export class SubCategoryComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  gettingCategories: boolean = false;
  gettingChannels: boolean = false;
  gettingCategory: boolean = false;
  isFocus: boolean = false;

  channelid: string = '';
  searchText: string = '';

  channels: any[] = [];

  categories: any[] = [
    {
      categoryid: 'software-and-technology',
      categoryname: 'Software and Technology',
      sortkey: '0',
      subcategories: [
        'Web Development',
        'iOS Development',
        'Cloud Service',
        'Backend Development',
      ],
    },
    {
      categoryid: 'business-and-management',
      categoryname: 'Business and Management',
      sortkey: '1',
      subcategories: ['Human Resource Management', 'Business Intelligence'],
    },
    {
      categoryid: 'banking-and-finance',
      categoryname: 'Banking and Finance',
      sortkey: '2',
      subcategories: ['Finance'],
    },
    {
      categoryid: 'music',
      categoryname: 'Music',
      sortkey: '3',
      subcategories: [],
    },
    {
      categoryid: 'lifestyle',
      categoryname: 'Lifestyle',
      sortkey: '4',
      subcategories: ['Health Knowledge', 'Blah Blah Blah'],
    },
    {
      categoryid: 'other',
      categoryname: 'Other',
      sortkey: '5',
      subcategories: [],
    },
  ];

  categoryData: any[] = [];
  categoryTableData: MatTableDataSource<any> = new MatTableDataSource(
    this.categoryData
  );

  displayedColumns: string[] = ['subcategory', 'category', 'order', 'action'];

  constructor(
    private messageService: MessageService,
    private courseService: CourseService,
    private allinoneService: AllInOneService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (!this.allinoneService.isChlAdmin()) {
      this.router.navigateByUrl('');
    }
    this.getChannels();
  }

  ngOnInit(): void {
    this.getSubCategories();
  }

  getChannels() {
    this.channels = this.allinoneService.adminChannels;
    this.channelid = this.channels[0].channelid;
  }

  getSubCategories() {
    this.gettingCategories = true;
    this.categoryData = [];
    this.categoryTableData = new MatTableDataSource(this.categoryData);
    const data = {
      type: '2',
      channelid: this.channelid,
    };
    this.courseService.getCategoryList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categories = res.data;
          this.categoryData = res.subcategorylist.map((item: any) => ({
            ...item,
            maincatname: this.categories.find(
              (x: any) => x.categoryid == item.categoryid
            ).categoryname,
          }));
          this.categoryTableData = new MatTableDataSource(this.categoryData);
          this.categoryTableData.paginator = this.paginator;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
        this.gettingCategories = false;
      },
      (err) => {
        this.gettingCategories = false;
      }
    );
  }

  channelChange() {
    this.getSubCategories();
  }

  clear() {
    this.categoryTableData.filter = '';
    this.searchText = '';
  }

  sortData(sort: Sort) {
    const data = this.categoryData;
    if (!sort.active || sort.direction === '') {
      this.categoryData = data;
      return;
    }

    this.categoryData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'subcategory':
          return this.allinoneService.compare(
            a.subcategoryname,
            b.subcategoryname,
            isAsc
          );
        case 'category':
          return this.allinoneService.compare(
            a.maincatname,
            b.maincatname,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.categoryTableData = new MatTableDataSource(this.categoryData);
  }

  searchItem(event: any) {
    const searchItem = event.target.value;
    this.categoryTableData.filter = searchItem.trim().toLowerCase();
  }

  newSubcategory() {
    let dialogRef = this.dialog.open(CreateSubCategoryComponent, {
      width: '500px',
      maxWidth: '95vw',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        categories: this.categories,
        channelid: this.channelid,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: any }) => {
        if (result.success) {
          this.categoryData.push(result.data);
          this.categoryTableData = new MatTableDataSource(this.categoryData);
          this.categoryTableData.paginator = this.paginator;
        }
      });
  }
  editSubcategory(subcat: any) {
    let dialogRef = this.dialog.open(CreateSubCategoryComponent, {
      width: '500px',
      maxWidth: '95vw',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        categories: this.categories,
        channelid: this.channelid,
        category: subcat,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: any }) => {
        if (result.success) {
          subcat.subcategoryname = result.data.subcategoryname;
          subcat.categoryid = result.data.categoryid;
          subcat.categoryname = result.data.categoryname;
          subcat.maincatname = result.data.maincatname;
          subcat.sortkey = result.data.sortkey;
        }
      });
  }

  deleteSubcategory(subcat: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .deleteSubcategory(subcat.categoryid, subcat.categoryname, "")
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                const index = this.categoryData.findIndex(
                  (item: any) =>
                    item.categoryname == subcat.categoryname &&
                    item.categoryid == subcat.categoryid
                );
                this.categoryData.splice(index, 1);
                this.categoryTableData = new MatTableDataSource(
                  this.categoryData
                );
                this.categoryTableData.paginator = this.paginator;
              } else {
                this.messageService.openSnackBar(
                  res.message || res.status || 'Server Error',
                  'warn'
                );
              }
              this.loadingService.hide();
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }
}
