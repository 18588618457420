<div class="d-flex align-items-center justify-content-between mb-4">
    <div>
      <h2 class="mb-0">{{isEditing ? 'Edit Category' : 'New Category'}}</h2>
    </div>
    <div class="d-flex align-items-center">
      <mat-icon (click)="dismiss()" class="cus-point">close</mat-icon>
    </div>
  </div>
  
  <div class="row">
    <!-- <div class="mb-3 col-md-12">
      <label for="name" class="form-label">Name</label>
      <input type="text" class="form-control" id="name" name="name" [(ngModel)]="course.name"
        [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>
      <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
        <div *ngIf="name.errors?.required && formSubmitted">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div> -->
    <div class="mb-3 col-md-12">
      <label for="name" class="form-label">Name</label>
      <input type="text" class="form-control form-control-sm" aria-label="Number" matInput
        class="form-control" id="name" name="name" 
        [(ngModel)]="category.name"
        [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>
  
      <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
        <div *ngIf="name.errors?.required && formSubmitted">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
     
    </div>
    
   
    
  </div>
  
  <div class="form-group" class="mb-3">
    <label for="order" class="mb-2">
      Order
    </label>
    <input type="number" class="form-control" id="order" name="order" value="0" [(ngModel)]="category.order"
      #order="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && order.invalid }" required>
    <div class="invalid-feedback" *ngIf="order.invalid || (order.dirty || order.touched) && formSubmitted">
      <div *ngIf="order.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="actions-button">
  
    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled]="isLoading">
      Cancel
    </button>
  
    <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="submit()">
    </app-button-loader>
  
  </div>