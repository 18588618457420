import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import * as S3 from 'aws-sdk/clients/s3';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-library-new-folder',
  templateUrl: './library-new-folder.component.html',
  styleUrls: ['./library-new-folder.component.scss'],
})
export class LibraryNewFolderComponent implements OnInit {
  @ViewChild('inputName') inputName: ElementRef | undefined;

  isSaving: boolean = false;
  newFolderFormSubmitted: boolean = false;
  newFolderForm = this.formBuider.group({
    name: ['Untitled Folder', Validators.required],
  });

  get newFolderFormControl() {
    return this.newFolderForm.controls;
  }

  folderlist: any[] = [];
  filepath: string = '';

  constructor(
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<LibraryNewFolderComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (data) {
      this.folderlist = data.folderlist;
      this.filepath = data.currentpath;
      console.log(this.folderlist)
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      const inputElem = <HTMLInputElement>this.inputName?.nativeElement;
      inputElem.select();
    }, 100);
  }

  submitNewFolder(): void {
    if (this.newFolderFormControl.name.invalid) {
      return;
    }
    //Check folder name alreay exist
    var name = this.newFolderFormControl.name.value.toString().trim();
    var temp = this.folderlist.filter((item: any) => item.filename == name);
    if (temp.length > 0) {
      this.messageService.openSnackBar('Folder name already exists.', 'warn');
      return;
    }
    this.isSaving = true;
    this.dialogRef.disableClose = true;
    // this.createNewFolder(name).then(
    //   () => {
    //     this.dialogRef.disableClose = false;
    //     this.isSaving = false;
    //     this.dialogRef.close({name: name})
    //   },
    //   () => {
    //     this.messageService.openSnackBar('Error creating new folder.', 'warn');
    //     this.isSaving = false;
    //     this.dialogRef.disableClose = false;
    //   }
    // );
    // this.dialogRef.close(true);
  }

  // createNewFolder(name: string) {
  //   return new Promise<void>((resolve, reject) => {
  //     const bucket = new S3({
  //       accessKeyId: this.allinoneService.acc_key,
  //       secretAccessKey: this.allinoneService.sec_key,
  //       region: 'ap-southeast-1',
  //     });
  //     const params = {
  //       Bucket: 'elearningbucket',
  //       Key: 'library/' + this.filepath + name + '/E772ECAF9E9C43562FB3BC5FD7EA7.txt',
  //       ACL: 'public-read',
  //       Body: '../../../assets/files/temp.txt',
  //     };
  //     bucket.upload(params, function (err: any, data: any) {
  //       if (err) {
  //         reject();
  //       }
  //       resolve();
  //     });
  //   });
  // }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
