import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Discussion, Reply } from '../../shared/models/Discussion';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-view-live-discussion',
  templateUrl: './view-live-discussion.component.html',
  styleUrls: ['./view-live-discussion.component.scss'],
})
export class ViewLiveDiscussionComponent implements OnInit {
  isloading: boolean = true;

  unit: any;
  unitid: string = '';
  courseid: string = '';

  firstreply: boolean = false;
  gettingreply: boolean = false;
  firstreplying: boolean = false;
  firstreplyconent: any = '';
  firstreplycount: number = 5;
  firstreplycurrentcount: number = 5;
  replylist: Discussion[] = [];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        // 'underline',
        'strikeThrough',
        // 'subscript',
        // 'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private datepipe: DatePipe,
    private _seoService: SEOService,
    private dialog: MatDialog,
    private loadingService: LoadingService
  ) {
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.unitid = params['unitid'];
    });
  }

  ngOnInit(): void {
    this.getUnit();
  }

  getUnit() {
    this.isloading = true;
    this.courseService.getUnit(this.courseid, this.unitid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.unit = res.data;
          this.getDiscussion();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isloading = false;
      },
      (err) => {
        this.isloading = false;
      }
    );
  }

  goBackToCourse() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/curriculum`);
  }

  getDiscussion(isgettingmore?: boolean) {
    this.gettingreply = true;
    if (!isgettingmore) {
      this.replylist = [];
    } else {
      this.firstreplycurrentcount += this.firstreplycount;
    }
    console.log(this.replylist);
    const endtime = isgettingmore
      ? this.replylist[this.replylist.length - 1].sortkey
      : '';
    this.courseService
      .getDiscussion(
        this.unitid,
        this.courseid,
        endtime!,
        this.firstreplycount,
        ''
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((data: any) => {
              var temp: Discussion = {
                sortkey: data.sort,
                syskey: data.syskey,
                username: data.username,
                userid: data.userid,
                profileimage: data.profileimageurl,
                institute: data.institute,
                message: data.message,
                date: this.allinoneService.formatDateForDiscussionDatePipe(
                  data.date
                ),
                time: data.time,
                open: false,
                totalreplies: data.childcount,
                reply: false,
                gettingreply: false,
                replying: false,
                replycontent: '',
                replylist: [],
                currentcount: 0,
                instructor: data.instructor,
                deleting: false,
              };
              this.replylist.push(temp);
            });
            this.isloading = false;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isloading = false;
          this.gettingreply = false;
        },
        (err) => {
          this.isloading = false;
          this.gettingreply = false;
        }
      );
  }

  reply(discussion?: Discussion) {
    const currentDate = this.datepipe.transform(new Date(), 'yyyy/MM/dd');
    const currentDateForKy = this.datepipe.transform(new Date(), 'yyyyMMdd');
    const currentTime = this.datepipe.transform(new Date(), 'hh:mm:ss aa');
    const currentTimeForKy = this.datepipe.transform(new Date(), 'hh:mm aa');
    var data = {
      domain: '',
      domainid: '',
      contact: '',
      channelname: '',
      date: currentDateForKy?.toString(),
      time: currentTimeForKy?.toString(),
      message: '',
      imagename: '',
      imagedata: '',
      imagelist: [],
      tmpimage: '',
      channelid: this.unit.kunyekchannelid,
      name: '',
      mention: [],
      eventid: '',
      replysyskey: '',
      filename: '',
      notimessage: '',
      instructor: true,
      profileid: this.unit.maininstructor ? this.unit.profileid : '',
    };
    if (discussion) {
      discussion.replying = true;
      data.message = discussion.replycontent;
      data.replysyskey = discussion.syskey;
      this.courseService.replyDiscussion(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            discussion.totalreplies++;
            discussion.replylist.unshift({
              syskey: res.list[0].syskey,
              username: this.unit.maininstructor
                ? this.unit.instructorname
                : this.allinoneService.user.username,
              userid: this.allinoneService.user.useid,
              profileimage: this.unit.maininstructor
                ? this.unit.profileimageurl
                : this.allinoneService.user.profileimage
                ? this.allinoneService.imgurl +
                  this.allinoneService.user.profileimage
                : '',
              message: data.message,
              date: currentDate?.toString(),
              time: currentTime?.toString(),
              sortkey: res.list[0].sortkey,
              institute: this.unit.maininstructor
                ? this.unit.institute
                : this.allinoneService.user.institute,
              instructor: true,
            });
            discussion.replycontent = '';
            discussion.reply = false;
            discussion.open = true;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          discussion.replying = false;
        },
        (err) => {
          discussion.replying = false;
        }
      );
    } else {
      this.firstreplying = true;
      data.message = this.firstreplyconent;
      this.courseService.replyDiscussion(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.replylist.unshift({
              syskey: res.list[0].syskey,
              username: this.unit.maininstructor
                ? this.unit.instructorname
                : this.allinoneService.user.username,
              userid: this.allinoneService.user.userid,
              profileimage: this.unit.maininstructor
                ? this.unit.profileimageurl
                : this.allinoneService.user.profileimage
                ? this.allinoneService.imgurl +
                  this.allinoneService.user.profileimage
                : '',
              message: this.firstreplyconent,
              date: currentDate?.toString(),
              time: currentTime?.toString(),
              open: false,
              totalreplies: 0,
              reply: false,
              gettingreply: false,
              replycontent: '',
              replylist: [],
              institute: this.unit.maininstructor
                ? this.unit.institute
                : this.allinoneService.user.institute,
              replying: false,
              currentcount: 0,
              instructor: true,
              deleting: false,
            });
            this.firstreply = false;
            this.firstreplying = false;
            this.firstreplyconent = '';
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            this.firstreplying = false;
          }
        },
        (err) => {
          this.firstreplying = false;
        }
      );
    }
  }

  openReplies(discussion: Discussion) {
    if (discussion.open) {
      discussion.open = false;
      return;
    }
    discussion.open = true;
    if (discussion.replylist.length == 0) {
      this.getDiscussionReplies(discussion);
    }
  }

  getDiscussionReplies(discussion: Discussion, isgettingmore?: boolean) {
    discussion.gettingreply = true;
    discussion.currentcount = discussion.currentcount + 5;
    const endtime = isgettingmore
      ? discussion.replylist[discussion.replylist.length - 1].sortkey
      : '';
    this.courseService
      .getDiscussion(
        this.unitid,
        this.courseid,
        endtime || '',
        discussion.currentcount,
        discussion.syskey
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((data: any) => {
              var temp: Reply = {
                username: data.username,
                userid: data.userid,
                profileimage: data.profileimageurl,
                institute: data.institute,
                message: data.message,
                date: this.allinoneService.formatDateForDiscussionDatePipe(
                  data.date
                ),
                time: data.time,
                sortkey: data.sort,
                instructor: data.instructor,
                syskey: data.syskey,
              };
              discussion.replylist.push(temp);
            });
            discussion.open = true;
            if (discussion.replylist.length == 0) {
              discussion.open = false;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          discussion.gettingreply = false;
        },
        (err) => {
          discussion.gettingreply = false;
        }
      );
  }

  deleteDiscussion(discussion: any, reply: any = null) {
    console.log(reply);
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        message:
          'Are you sure to delete this reply' +
          (reply ? ' ?' : ' and all related replies?'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        // if (reply) {
        //   reply.deleting = true;
        // } else {
        //   discussion.deleting = true;
        // }
        this.courseService
          .deleteDiscussionReply(
            this.courseid,
            this.unitid,
            reply ? reply.syskey : discussion.syskey
          )
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                if (reply) {
                  discussion.replylist.splice(
                    discussion.replylist.indexOf(reply),
                    1
                  );
                  discussion.totalreplies--;
                } else {
                  this.replylist.splice(this.replylist.indexOf(discussion), 1);
                }
              } else {
                this.messageService.openSnackBar(
                  res.message || res.status || 'Server Error',
                  'warn'
                );
                // if (reply) {
                //   reply.deleting = false;
                // } else {
                //   discussion.deleting = false;
                // }
              }
              this.loadingService.hide();
            },
            (err: any) => {
              this.messageService.openSnackBar('Server Error', 'warn');
              this.loadingService.hide();
              // if (reply) {
              //   reply.deleting = false;
              // } else {
              //   discussion.deleting = false;
              // }
            }
          );
      }
    });
  }
}
