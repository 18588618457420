import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { CourseService } from '../../shared/services/course.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CourseDuplicateEditorComponent } from '../course-duplicate-editor/course-duplicate-editor.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-learning-track-course-add',
  templateUrl: './learning-track-course-add.component.html',
  styleUrls: ['./learning-track-course-add.component.scss']
})
export class LearningTrackCourseAddComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  courseList: any = [];
  selectedCourse: any = [];

  courseSearchText: string = '';
  saveLoading: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private router: Router,
    public dialogRef: MatDialogRef<CourseDuplicateEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data);

    if (data) {
      this.courseList = data.courseList;
    }
  }

  ngOnInit(): void {
  }

  saveData() {
    this.dialogRef.close({
      selectedCourseList : this.selectedCourse
    });
  }

  // selectCourse(course: any) {
  //   const checkexist = this.courseList.findIndex(
  //     (item: any) => item.courseid == course.courseid
  //   );
  //   this.courseList.splice(checkexist, 1);
  //   const temp = JSON.parse(JSON.stringify(course));
  //   this.selectedCourse.push(temp);
  //   // delete temp['prerequisites'];
  //   // this.course.prerequisites.unshift(temp);
  // }

  selectCourse(course: any) {
    console.log(course);
    const checkexist = this.courseList.findIndex(
      (item: any) => item.courseid == course.courseid
    );
    this.courseList.splice(checkexist, 1);
    const temp = JSON.parse(JSON.stringify(course));
    this.selectedCourse.push(temp);
  }

  showList() {
    this.autocomplete.openPanel();
  }

  remove(index: number) {
    // this.allcourses.push(this.course.prerequisites[index]);
    // this.course.prerequisites.splice(index, 1);
    this.courseList.push(this.selectedCourse[index]);
    this.selectedCourse.splice(index, 1);
  }

  cancel() {
    this.dialogRef.close();
  }

}
