import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root' // Add this to ensure your SEO service will be app-wide available
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }

  

  updateTitle(title: string) {
    this.title.setTitle('KHub Studio ' + title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'ogUrl', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateImg(imgUrl: string){
    this.meta.updateTag({property: 'og:image', content: imgUrl});
    this.meta.updateTag({property: 'og:image:width', content: "400" })
    this.meta.updateTag({property: 'og:image:height', content: "400" })
  }
}