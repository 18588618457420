import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-channel-analytics',
  templateUrl: './channel-analytics.component.html',
  styleUrls: ['./channel-analytics.component.scss']
})
export class ChannelAnalyticsComponent implements OnInit {

  displayedColumns: any;

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  gettingData: boolean = false;
  channels: any[] = [];
  channelData !: MatTableDataSource<any>;
  edit: string = "editchannel";
  paginate: boolean = false;
  searchText: string = "";
  isFocus: boolean = false;
  datacount: string = "0 hr(s) 0 min(s)";
  role: string = '';
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) || (this.role == 'supervisor' && !this.allinoneService.isSupervisor())) {
      this.router.navigateByUrl('')
    }
  }

  ngOnInit(): void {
    this.displayedColumns = ['image', 'channelname', 'totaltime', 'action'];
    this.getData();
  }

  refresh() {
    this.gettingData = true;
    this.datacount = "0 hr(s) 0 min(s)";
    this.channels = [];
    if (this.channels == undefined || this.channels.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.channelData = new MatTableDataSource();
    this.getData();
  }

  getData() {
    this.channels = [];
    this.gettingData = true;
    this.channelData = new MatTableDataSource();
    const rolename = this.role == 'chladmin' ? '100' : this.role == 'supervisor' ? '2' : this.role == 'hradmin' ? '3' : '';
    this.courseService.getAnalyticsData(rolename).subscribe(
      (channel_data: any) => {
        if (channel_data.returncode == '300') {
          const dataSource = new MatTableDataSource(channel_data.datalist);
          this.channels = channel_data.datalist;
          this.datacount = channel_data.totaltime;
          if (this.channels == undefined || this.channels.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }
          this.channelData = new MatTableDataSource(channel_data.datalist);
          this.channelData.sort = this.sort;
          this.channelData.paginator = this.paginator;
          this.gettingData = false;
        } else {
          this.gettingData = false;
          if (channel_data.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.gettingData = false;
            this.messageService.openSnackBar(channel_data.status, 'warn');
          }
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.channelData.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = "";
    this.channelData.filter = "";
  }


  sortData(sort: Sort) {

  }

  goToCourseAnalytic(channelid: string,type:any) {
    const tempid = channelid ? channelid : 'public';
    // { state: false }
    this.router.navigateByUrl(`/${this.role}/analytics/${tempid}?type=${this.allinoneService.encrypt(type)}`)
  }
}
