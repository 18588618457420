<div *ngIf="!input">

  <div>
    <h2><strong>{{isEditing ? 'Edit Student' : 'New Student'}}</strong></h2>
  </div>
  <form [formGroup]="studentForm" (ngSubmit)="sumbitClass()">
    <!-- <div class="form-group" class="mb-3">
      <label for="classcode" class="mb-2">
        User ID
      </label>
      <input [readonly]="isEditing" type="text" class="form-control form-control-sm"
        placeholder="john@domain.com, +959*********" formControlName="adduser" id="adduser" name="adduser"
        [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.adduser.errors }">

      <div *ngIf="classFormSubmitted && classFormControl.adduser.errors" class="invalid-feedback">
        <div *ngIf="classFormControl.adduser.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div> -->

    <div class="form-group" class="mb-3">
      <label for="classcode" class="mb-2">
        User ID
      </label>

      <input [readonly]="isEditing" type="text" class="form-control form-control-sm"
        placeholder="john@domain.com, +959*********" aria-label="Number" matInput class="form-control"
        [matAutocomplete]="auto1" formControlName="adduser" id="adduser" name="adduser" [disabled]="gettingMembers"
        #trigger="matAutocompleteTrigger"
        [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.adduser.errors }">
      <mat-progress-bar mode="indeterminate" *ngIf="gettingMembers"></mat-progress-bar>
      <mat-autocomplete #auto1="matAutocomplete">
        <mat-option *ngFor="let member of studentList| filter : classFormControl.adduser.value:16" (onSelectionChange)="selectStudent(member, trigger)"
          (click)="selectStudent(member, trigger)">
          {{member.username}} {{member.userid}}
        </mat-option>
      </mat-autocomplete>

      <div *ngIf="classFormSubmitted && classFormControl.adduser.errors" class="invalid-feedback">
        <div *ngIf="classFormControl.adduser.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>


    <div class="form-group" class="mb-3">
      <label for="studentid" class="mb-2">
        Student ID
      </label>
      <input type="text" class="form-control form-control-sm" placeholder="SD 001" formControlName="studentid"
        id="studentid" name="studentid">
    </div>

    <div class="form-group" class="mb-3">
      <label for="studentname" class="mb-2">
        Student Name
      </label>
      <input type="text" class="form-control form-control-sm" placeholder="Enter Student Name"
        formControlName="studentname" id="studentname" name="studentname">
    </div>

    <div class="form-group" class="mb-3">
      <label for="remark" class="mb-2">
        Remark
      </label>
      <textarea class="form-control" rows="3" placeholder="Enter Remark" formControlName="remark" id="remark"
        name="remark"></textarea>
    </div>

    <div class="actions-button">
      <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isLoading">
        Cancel
      </button>

      <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading">
      </app-button-loader>

    </div>

  </form>
</div>

<div *ngIf="input">

  <div class="mb-3">
    <h2>Import Students</h2>
    <label for="file" class="form-label">Excel Template (<span class="cc-label" (click)="downloadSampleFile()"
        style="color: var(--main-color);cursor: pointer;">Download Sample Template</span>)</label>
    <div class="input-group">
      <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile"
        #excelFile [disabled]="excel">
      <button class="input-group-text" mat-flat-button color="primary" id="btnGroupAddon2" *ngIf="excel"
        (click)="removeExcel()"
        style="background-color: var(--main-color);color: white;margin-left: 0px !important;height: 38px !important;">Remove</button>
      <button class="input-group-text" mat-flat-button color="primary"
        style="border-left: 1px solid white;background-color: var(--main-color);color: white;margin-left: 0px !important;"
        id="btnGroupAddon2" [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
        <span *ngIf="!checkingExcelFile">
          Check
        </span>
        <span *ngIf="checkingExcelFile">
          <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
          <span class="mx-1">Checking</span>
        </span>
      </button>
    </div>
    <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
      {{allinoneService.input_err_msg}}
    </div>



  </div>
  <div style="color : green"
    *ngIf="allok && errorList.length == 0 && duplicateList.length == 0 && warningList.length == 0">
    <i class="fa fa-check me-2"></i> Template is correct.
  </div>
  <div class="row" *ngIf="!checkingExcelFile">
    <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0"
      style="max-height: 300px;overflow: auto;width: 48%;margin: 5px;">
      <div class="d-flex align-items-center">
        <mat-icon class="me-2">
          error
        </mat-icon>
        <div>
          Error
        </div>
      </div>
      <hr>
      <ul style="padding-left: 1rem;">
        <li *ngFor="let e of errorList;">
          In line no. {{e.line}}, {{e.error}}
        </li>
      </ul>
    </div>
    <div class="alert alert-warning custom-box col-6" *ngIf="duplicateList.length > 0 || warningList.length >0"
      style="max-height: 300px;overflow: auto;width: 48%;margin: 5px;">

      <div class="d-flex align-items-center">
        <mat-icon class="me-2">
          warning
        </mat-icon>
        <div>
          Warning
        </div>
      </div>
      <hr>

      <ul style="padding-left: 1rem;">
        <li *ngFor="let du of duplicateList;">
          "{{du}}" is duplicated.
        </li>
        <li *ngFor="let e of warningList;">
          In line no. {{e.line}}, {{e.error}}
        </li>
      </ul>


    </div>
  </div>
  <div class="actions-button">
    <button mat-flat-button class="mt-2 " type="button" (click)="onDismiss()" [disabled]="isLoading"
      style="border-color:rgba(0, 0, 0, 0.12);">
      Cancel
    </button>
    <!-- <button mat-flat-button color="primary" class="mt-2 " type="button" [disabled]="allok || errorList.length == 0 ||  warningList.length == 0">
            Save
        </button> -->
    <!-- [disabled]="allok && errorList.length == 0 && duplicateList.length == 0 && warningList.length == 0" -->
    <button [disabled]="isLoading || !allok" mat-flat-button color="primary" class="mt-2 " type="submit"
      (click)="importData()">
      <span style="padding: 0;margin: 0;" *ngIf="isLoading"
        class="d-flex align-items-center justify-content-center p-2">
        <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
      </span>
      <span *ngIf="!isLoading"> Save </span>
    </button>
    <!-- <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="inputData()">
        </app-button-loader> -->

  </div>
</div>