<app-breadcrumb [s_breadcrumb]="'Analytics'"></app-breadcrumb>

<div class="content">

  <div class="d-flex justify-content-end">
    <div class="mt-4 mb-3 me-2">
      Total :<b> {{datacount}}</b>
    </div>


    <div class="input-group mt-3 mb-3" style="width: 260px !important;">
      <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
      <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
        *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4;color: #00bcd4;">
        <i class="fa fa-times"></i>
      </button>
    </div>

    <button class="btn new-chl mt-3 mb-3 ms-2" matTooltip="Refresh" (click)="refresh()" type="button">
      <i class="fa fa-refresh"></i>
    </button>
  </div>
  <div style="overflow: auto;">
    <table mat-table #table [dataSource]="channelData" matSort (matSortChange)="sortData($event)" matSort
      class="mat-elevation-z1" style="width: 100%;">

      <ng-container matColumnDef="image">

        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;"
          (click)="goToCourseAnalytic(element.channelid,element.type)">
          <img *ngIf="element.url == ''" [src] = "'../../../assets/images/khub.png'" class="course-listing-image"  style="object-fit: cover;"/>
          <img *ngIf="element.url != ''" [src]="element.url" class="course-listing-image" />
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="channelname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Channel Name </th>
        <td mat-cell *matCellDef="let element" (click)="goToCourseAnalytic(element.channelid,element.type)"> {{element.channelname}}
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="totaltime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by time"> Time Spent</th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color)"
          (click)="goToCourseAnalytic(element.channelid,element.type)"> {{element.totaltime}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element" px-3 style="text-align: center;">
          <button class="btn btn-outline-primary btn-custom-rounded me-2 align-item-center" matTooltip="Info"
            (click)="goToCourseAnalytic(element.channelid,element.type)">
            <i class="fa fa-info mem-icon" aria-hidden="true"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td colspan="4">
          <span class="nodata" *ngIf="!gettingData">Empty</span>
          <span class="nodata" *ngIf="gettingData">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>
    </table>
    <!-- <div  *ngIf="paginate == true"> -->
    <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <!-- </div> -->
  </div>
</div>



