import { Component, Input, OnInit } from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../shared/services/loading.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { NotiAddFormComponent } from '../noti-add-form/noti-add-form.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-push-noti',
  templateUrl: './push-noti.component.html',
  styleUrls: ['./push-noti.component.scss']
})
export class PushNotiComponent implements OnInit {

  @Input() courseid : string = '';
  @Input() classid : string = '';
  @Input() studentList : any = [];

  allNotiList: any = [];
  isLoading: boolean = false;

  isFocus: boolean = false;
  searchText: string = '';
  paginate: boolean = false;
  displayedColumns: any;

  getListSub! : Subscription;

  shownoti: MatTableDataSource<any> = new MatTableDataSource(
    this.allNotiList
  );

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'type',
      'title',
      'message',
      'userid',
      'username',
      'submitteddate',
    ];
    this.getAllNotiList();
  }

  toggleRow(data : any) {
    
  }

  getAllNotiList() {
    this.isLoading = true;
    const data = {
      courseid : this.courseid,
      classid : this.classid,
    }
    this.getListSub && this.getListSub.unsubscribe();
    this.getListSub = this.courseService.getNotification(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.allNotiList = res.datalist;
          this.shownoti = new MatTableDataSource(this.allNotiList);
          
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
      }
    )
  }

  newNoti() {
    let dialogRef = this.dialog.open(NotiAddFormComponent, {
      minWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid : this.courseid,
        classid : this.classid,
        studentList : this.studentList
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if(data) {
        if(data.success) {
          this.getAllNotiList();
          // this.allNotiList.push(data.newData);
          // this.shownoti = new MatTableDataSource(this.allNotiList);
          // console.log('dd');
          // console.log(this.allNotiList);
          
        }
      }
    })
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.shownoti.filter = searchItem.trim().toLowerCase();
  }

  sortData(sort: Sort) {
    const data = this.allNotiList;
    console.log(data);
    
    if (!sort.active || sort.direction === '') {
      this.allNotiList = data;
      return;
    }

    this.allNotiList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title':
          return this.allinoneService.compare(a.title, b.title, isAsc);
        case 'message':
          return this.allinoneService.compare(a.message, b.message, isAsc);
        case 'type':
          return this.allinoneService.compare(a.type, b.type, isAsc);
          case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
          case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
          // case 'submitteddate':
          //   return this.allinoneService.compare(a.sentdate, b.sentdate, isAsc);
          case 'submitteddate':
            return this.allinoneService.compare( this.allinoneService.formatDBToShowInEdit(a.sentdate) + this.allinoneService.f12to24(a.senttime) , this.allinoneService.formatDBToShowInEdit(b.sentdate) + this.allinoneService.f12to24(b.senttime), isAsc);
        default:
          return 0;
      }
    });
    this.shownoti = new MatTableDataSource(this.allNotiList);
  }

  clear() {
    this.searchText = '';
    this.shownoti.filter = '';
  }

  refresh() {
    this.isLoading = true;
    this.allNotiList = [];
    if (this.allNotiList == undefined || this.allNotiList.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.shownoti = new MatTableDataSource();
    this.getAllNotiList();
  }



}
