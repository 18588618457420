<app-breadcrumb [s_breadcrumb]="'Create Course'"></app-breadcrumb>

<div class="content  d-flex justify-content-center">

  <div class="course-header">

    <strong>Course title <mat-icon matTooltip="Don't worry about your course title. You can change it later">
        help</mat-icon></strong>

    <div class="form-group mt-2 mb-3 ">
      <input class="form-control" [(ngModel)]="courseForm.coursename" type="text" name="_name" ngModel required
        autofocus #_name=ngModel placeholder="Enter course title" autocomplete="off"
        [ngClass]="{ 'is-invalid': submitted && _name.invalid }" (keyup.enter)="onSubmitName()">

      <div class="invalid-feedback" *ngIf="_name.invalid || (_name.dirty || _name.touched) && submitted">
        <div *ngIf="_name.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
    <div>

      <strong>Channel <mat-icon matTooltip="Please choose the channel you want to upload your course.">
          help</mat-icon></strong>

      <div class="form-group mt-2">

        <select class="form-select" [(ngModel)]="courseForm.channelid"  (ngModelChange)="channelChange()" required #chl="ngModel"
          [ngClass]="{ 'is-invalid' :submitted &&  chl.value == 'choose'  }" [disabled]="gettingmyChannel">
          <option value="choose">Choose a channel</option>
          <option [value]="chl.channelid" *ngFor="let chl of channel"
            [selected]="chl.channelid == courseForm.channelid">{{chl.name}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingmyChannel"></mat-progress-bar>
        <div class="invalid-feedback" *ngIf="chl.value == 'choose' && submitted">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>


    <div style="margin-top: 20px;">

      <strong>Course category <mat-icon matTooltip="Don't worry about your course category. You can change it later">
          help</mat-icon></strong>

      <div class="form-group mt-2">
        <select class="form-select" [(ngModel)]="courseForm.category" required #cat="ngModel"
          [ngClass]="{ 'is-invalid': submitted && cat.invalid }" [disabled]="gettingCategory">
          <option selected value="">Choose a category</option>
          <option [value]="cat.categoryid" *ngFor="let cat of categories">{{cat.categoryname}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>


        <div class="invalid-feedback" *ngIf="cat.invalid || (cat.dirty || cat.touched) && submitted">
          <div *ngIf="cat.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;">

      <strong>Course Type <mat-icon matTooltip="Don't worry about it. You can change it back later.">
          help</mat-icon></strong>

      <div class="d-flex align-items-center mb-4 mt-2">
        <div class="me-5">
          <input type="radio" name="type" id="selfpace" class="me-2" [(ngModel)]="courseForm.type"
            style="margin-bottom: 3px !important; cursor: pointer;" [checked]="courseForm.type == '001'" value="001">
          <label for="selfpace" style="cursor: pointer;">Self-Paced</label>
        </div>
        <div class="me-5">
          <input type="radio" name="type" id="instructorled" class="me-2" [(ngModel)]="courseForm.type"
            style="margin-bottom: 3px !important; cursor:pointer" [checked]="courseForm.type == '002'" value="002">
          <label for="instructorled" style="cursor: pointer;">Instructor-Led</label>
        </div>
        <div>
          <input type="radio" name="type" id="hybird" class="me-2" [(ngModel)]="courseForm.type"
            style="margin-bottom: 3px !important; cursor: pointer;" [checked]="courseForm.type == '003'" value="003">
          <label for="hybird" style="cursor: pointer;">Hybrid</label>
        </div>
      </div>
    </div>
    <div class="next text-end">

      <app-button-loader (click)="onSubmit()" [button_text]="'Create Course'" [isStillupload]="isStillupload">
      </app-button-loader>
    </div>
    <!-- 
        <div class="next text-end">

            <app-button-loader (click)="onSubmitName()" [button_text]="'Continue'" [isStillupload]="isStillCourseName">
            </app-button-loader>
        </div> -->
  </div>
</div>
<!-- 
<div class="content  d-flex justify-content-center" *ngIf="page == 'course-category'">
    <div class="course-header">
        <div>

            <div>

                <strong>Channel <mat-icon matTooltip="Please choose the channel you want to upload your course.">
                        help</mat-icon></strong>

                <div class="form-group mt-2">
                    <select class="form-select" [(ngModel)]="courseForm.channelid" required #chl="ngModel"
                        [ngClass]="{ 'is-invalid' :submitted &&  chl.value == 'choose'  }" [disabled]="gettingCategory">
                        <option value="choose">Choose a channel</option>
                        <option [value]="chl.channelid" *ngFor="let chl of channel">{{chl.name}}</option>
                    </select>
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>


                    <div class="invalid-feedback" *ngIf="chl.value === 'choose' && submitted">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;">

                <strong>Course category <mat-icon
                        matTooltip="Don't worry about your course category. You can change it later">
                        help</mat-icon></strong>

                <div class="form-group mt-2">
                    <select class="form-select" [(ngModel)]="courseForm.category" required #cat="ngModel"
                        [ngClass]="{ 'is-invalid': submitted && cat.invalid }" [disabled]="gettingCategory">
                        <option selected value="">Choose a category</option>
                        <option [value]="cat.categoryid" *ngFor="let cat of categories">{{cat.categoryname}}</option>
                    </select>
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>


                    <div class="invalid-feedback" *ngIf="cat.invalid || (cat.dirty || cat.touched) && submitted">
                        <div *ngIf="cat.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="next text-end">

                <app-button-loader (click)="onSubmit()" [button_text]="'Create Course'" [isStillupload]="isStillupload">
                </app-button-loader>
            </div>
        </div>
    </div> -->