import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-overall-feedback',
  templateUrl: './overall-feedback.component.html',
  styleUrls: ['./overall-feedback.component.scss'],
})
export class OverallFeedbackComponent implements OnInit {
  student: any;
  courseid: string = '';

  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<OverallFeedbackComponent>,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.student = JSON.parse(JSON.stringify(data.student));
    this.courseid = data.courseid;
  }

  ngOnInit(): void {}

  onDismiss() {
    this.dialogRef.close();
  }

  save() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    this.courseService
      .giveOverallFeedbacks(this.student.id, this.student.feedback, this.student.userid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.dialogRef.close({
              success: true,
              feedback: this.student.feedback,
            });
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        },
        (err) => {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        }
      );
  }
}
