import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-class-student-details',
  templateUrl: './class-student-details.component.html',
  styleUrls: ['./class-student-details.component.scss'],
})
export class ClassStudentDetailsComponent implements OnInit {
  @ViewChild('paginator') classPagniator!: MatPaginator;

  bcname: string = 'Classes';
  classname: string = 'Students';

  role: string = '';
  courseid: string = '';
  daterange: string = '';
  classid: string = '';
  studentstatus: string = '';
  classstatus: string = '';

  displayedColumns: string[] = [
    'userid',
    'username',
    'studentcode',
    'studentname',
    'post',
    'department',
    'remark',
  ];

  studentData: any[] = [];

  studentTableData!: MatTableDataSource<any>;

  gettingStudents: boolean = false;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private activatedRoute: ActivatedRoute,
    private _SEOService: SEOService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }
    this.activatedRoute.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.classid = params['classid'];
      this.classstatus = params['status'];
      this.studentstatus = params['studentstatus'];
      this.daterange = params['daterange'];
      if (this.studentstatus == 'all') {
        this.studentstatus = '';
      }
    });
  }

  ngOnInit(): void {
    this.getStudentsInfo();
  }

  getStudentsInfo() {
    this.studentData = [];
    this.studentTableData = new MatTableDataSource(this.studentData);
    this.gettingStudents = true;
    const data = {
      courseid: this.courseid,
      classid: this.classid,
      studentstatus: this.studentstatus,
    };
    this.courseService.getClassStudentDetailReport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.studentData = res.datalist;
          this.studentTableData = new MatTableDataSource(this.studentData);
          this.studentTableData.paginator = this.classPagniator;
          this.bcname = res.coursename;
          if (this.classstatus == '001') {
            this.bcname = this.bcname + ' - Active Classes';
          } else if (this.classstatus == '002') {
            this.bcname = this.bcname + ' - Inactive Classes';
          } else if (this.classstatus == '003') {
            this.bcname = this.bcname + ' - Completed Classes';
          }
          this.classname = res.classname;
          if (this.studentstatus == '001') {
            this.classname = this.classname + ' - Not Started';
          } else if (this.classstatus == '002') {
            this.classname = this.classname + ' - In Progress';
          } else if (this.classstatus == '003') {
            this.classname = this.classname + ' - Completed';
          }
          this.gettingStudents = false;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
          this.gettingStudents = false;
        }
      },
      (err) => {
        this.gettingStudents = false;
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.studentData;
    if (!sort.active || sort.direction === '') {
      this.studentData = data;
      return;
    }

    this.studentData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'studentcode':
          return this.allinoneService.compare(
            a.studentcode,
            b.studentcode,
            isAsc
          );
        case 'studentname':
          return this.allinoneService.compare(
            a.studentname,
            b.studentname,
            isAsc
          );
        case 'post':
          return this.allinoneService.compare(a.typename, b.typename, isAsc);
        case 'department':
          return this.allinoneService.compare(a.department, b.department, isAsc);
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        default:
          return 0;
      }
    });
    this.studentTableData = new MatTableDataSource(this.studentData);
  }

  goToReport() {
    this.router.navigateByUrl(`${this.role}/report`);
  }

  goToClass() {
    this.router.navigateByUrl(
      `${this.role}/report/${this.courseid}/${this.classstatus}/${this.daterange}`
    );
  }
}
