<div class="d-flex justify-content-between align-items-center mb-2">
    <h2 class="mb-0"> Push Notification </h2>
    <div mat-dialog-close>
        <mat-icon>close</mat-icon>
    </div>
</div>
<div>
    <div class="mb-3">
        <label for="type" class="form-label">
            Type
        </label>
        <select class="form-select" name="type" required [(ngModel)]="submitForm.type" (change)="typeChage($event)">
            <option [value]="type.id" *ngFor="let type of typeList;">
                {{type.name}}
            </option>
        </select>
    </div>

    <div class="mb-3" *ngIf="submitForm.type == '002'">
        <label for="student" class="form-label">
            Student
        </label>
        <select class="form-select" name="student" #student="ngModel"
            [ngClass]="{ 'is-invalid': formSubmitted && student.invalid }" required="submitForm.type == '002'"
            [(ngModel)]="submitForm.student">
            <option value="">-</option>
            <option [value]="student.studentid" *ngFor="let student of studentList;">
                {{student.username ? student.username + ' - ' + student.userid : student.userid}}
            </option>
        </select>

        <mat-progress-bar mode="indeterminate" *ngIf="gettingStudents"></mat-progress-bar>

        <!-- <div *ngIf="formSubmitted && formControl.studentid.errors" class="invalid-feedback">
            <div *ngIf="formControl.studentid.errors?.required">
                {{allinoneService.input_err_msg}}
            </div>
        </div> -->
        <div class="invalid-feedback" *ngIf="
            student.invalid ||
              ((student.dirty || student.touched) && formSubmitted)
            ">
            <div *ngIf="student.errors?.required">
                {{ allinoneService.input_err_msg }}
            </div>
        </div>

    </div>

    <div class="mb-3">
        <label for="title" class="form-label">
            Title
        </label>
        <input type="text" class="form-control" id="title" name="title" placeholder="Enter Title"
            [(ngModel)]="submitForm.title" required #title="ngModel"
            [ngClass]="{ 'is-invalid': formSubmitted && title.invalid }">

        <div class="invalid-feedback" *ngIf="
            title.invalid ||
              ((title.dirty || title.touched) && formSubmitted)
            ">
            <div *ngIf="title.errors?.required">
                {{ allinoneService.input_err_msg }}
            </div>
        </div>
    </div>

    <div class="mb-3">
        <label for="message" class="form-label">Message</label>
        <textarea class="form-control" id="message" [(ngModel)]="submitForm.message" name="message" rows="3" required
            #message="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && message.invalid }"
            placeholder="Enter Message"></textarea>
        <div class="invalid-feedback" *ngIf="
            message.invalid ||
              ((message.dirty || message.touched) && formSubmitted)
            ">
            <div *ngIf="message.errors?.required">
                {{ allinoneService.input_err_msg }}
            </div>
        </div>

    </div>

    <div class="actions-button">

        <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()" [disabled]="saveLoading">
            Cancel
        </button>

        <app-button-loader [button_text]="'Save'" (click)="pushNoti()" [isStillupload]="saveLoading">
        </app-button-loader>

    </div>


</div>