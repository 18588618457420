<h2>
  Schedule
</h2>
<div class="row">
  <div class="mb-3 col">
    <label for="startdate" class="form-label">Start Date</label>
    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="formattedSchedule.startdate"
    [ngClass]="{ 'is-invalid': formSubmitted && startdate.invalid }" #startdate="ngModel" required>
    <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) && formSubmitted">
      <div *ngIf="startdate.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="mb-3 col">
    <label for="starttime" class="form-label">Start Time</label>
    <input type="time" class="form-control" id="starttime" name="starttime" [(ngModel)]="formattedSchedule.starttime"
    [ngClass]="{ 'is-invalid': formSubmitted && starttime.invalid }" #starttime="ngModel" required>
    <div class="invalid-feedback" *ngIf="starttime.invalid || (starttime.dirty || starttime.touched) && formSubmitted">
      <div *ngIf="starttime.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="mb-3 col">
    <label for="enddate" class="form-label">End Date</label>
    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="formattedSchedule.enddate"
    [ngClass]="{ 'is-invalid': formSubmitted && enddate.invalid }"
    #enddate="ngModel" required>
    <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && formSubmitted">
      <div *ngIf="enddate.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="mb-3 col">
    <label for="endtime" class="form-label">End Time</label>
    <input type="time" class="form-control" id="endtime" name="endtime" [(ngModel)]="formattedSchedule.endtime"
    [ngClass]="{ 'is-invalid': formSubmitted && endtime.invalid }" #endtime="ngModel" required>
    <div class="invalid-feedback" *ngIf="endtime.invalid || (endtime.dirty || endtime.touched) && formSubmitted">
      <div *ngIf="endtime.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  
  <div class ="p-1 mt-2" *ngIf="formattedSchedule.startdate || formattedSchedule.starttime || formattedSchedule.enddate || formattedSchedule.endtime">
    <button mat-button (click)="clear()">Clear</button>
  </div>
  <app-button-loader (click)="updateSchedule()" [button_text]="'Save'"
    [isStillupload]="isLoading" [margin_left]="'0'" class="p-1">
  </app-button-loader>
</div>