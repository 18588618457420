<h2>
  {{bannertype == '002' ? 'Image Slider' : bannertype == '003' ? 'Video' : 'Text and Image'}}
</h2>

<div>
  <ng-container *ngIf="bannertype == '000'">
    <!-- First Text Input -->
    <div class="form-group mb-3">
      <label class="mb-2"><strong>First Text</strong></label>
      <textarea class="form-control" placeholder="Enter first text here" [(ngModel)]="textImageSlide.text1"></textarea>
    </div>

    <!-- Second Text Input -->
    <div class="form-group mb-3">
      <label class="mb-2"><strong>Second Text</strong></label>
      <textarea class="form-control" placeholder="Enter second text here" [(ngModel)]="textImageSlide.text2"></textarea>
    </div>

    <!-- Image Upload Section -->
    <div class="mb-3">
      <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput>
      <button class="ms-0" mat-flat-button color="primary" (click)="hiddenfileinput.click()">
        Choose Image
      </button>
    </div>

    <div>
      <strong>Recommended format</strong>
      <ul style="margin-top: 8px; padding-left: 16px;">
        <li>Image format: JPG/PNG</li>
        <li>Recommended size: 1920 x 690 pixels</li>
      </ul>
    </div>

    <!-- Image Cropper Section -->
    <div *ngIf="imageChangedEvent != '' && !croppedImage" class="img-cropper">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="25/9"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </div>

    <!-- Preview Cropped Image -->
    <div *ngIf="croppedImage">
      <img [src]="textImageSlide?.previewurl" class="cropped-image">
    </div>
  </ng-container>

  <ng-container *ngIf="bannertype == '003'">
    <div class="mb-3">
      <label class="mb-2"><strong>Text</strong>&nbsp;(Optional)</label>
      <angular-editor [(ngModel)]="videoSlide.text" [config]="config">
      </angular-editor>
    </div>

    <div class="d-flex align-items-center my-2">
      <div class="form-check me-5">
        <input class="form-check-input" type="radio" name="videotype" id="url" [(ngModel)]="videoSlide.videotype"
          value="001" [checked]="videoSlide.videotype == '001'" (ngModelChange)="videoTypeChange()">
        <label class="form-check-label" for="url">
          URL
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="videotype" id="library" [(ngModel)]="videoSlide.videotype"
          value="002" [checked]="videoSlide.videotype == '002'" (ngModelChange)="videoTypeChange()">
        <label class="form-check-label" for="library">
          Library
        </label>
      </div>
    </div>
    <ng-container *ngIf="videoSlide.videotype == '001'">
      <div class="form-group mb-3">
        <label class="mb-2"><strong>Video URL</strong></label>
        <input class="form-control" placeholder="https://www.youtube.com/xxxxxxxx" [(ngModel)]="videoSlide.videourl"
          (ngModelChange)="checkVideoUrl()">
      </div>
      <!-- video preview -->
      <div class="video-responsive mb-3" *ngIf="videoUrlPreview && videoSlide.videourl">
        <iframe width="422" height="200" [src]="videoUrlPreview | safe" title="Video Player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </ng-container>
    <ng-container *ngIf="videoSlide.videotype == '002'">
      <button type="button" mat-button (click)="chooseFromLibraryVideo()" class="px-2 mb-3">
        <svg style="width: 20px; margin-right: 5px" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
          enable-background="new 0 0 1000 1000" xml:space="preserve">
          <g>
            <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
              <path
                d="M2488.2,4153.8c-104.2-51.1-145-159.3-145-388.1v-190h1062.3h1062.3V3780c0,234.9-24.5,304.4-128.7,367.7c-61.3,38.8-108.3,40.9-923.4,40.9C2655.7,4188.5,2549.4,4184.5,2488.2,4153.8z" />
              <path
                d="M678.2,3669.7c-102.1-47-134.8-100.1-157.3-265.6c-12.3-85.8-18.4-159.3-14.3-163.4c4.1-4.1,343.2-26.6,753.8-51.1c410.6-24.5,796.7-49,862.1-55.2l114.4-12.3l14.3,134.8c12.3,108.3,8.2,147.1-22.5,210.4c-67.4,134.8-100.1,143-692.5,177.7c-292.1,18.4-590.4,36.8-663.9,42.9C784.5,3694.2,719.1,3688.1,678.2,3669.7z" />
              <path
                d="M8600.2,3647.2c-392.2-26.6-441.2-40.9-506.6-138.9c-32.7-49-40.8-112.4-34.7-269.7l4.1-104.2l700.7,47c384,24.5,702.7,44.9,706.8,44.9c24.5,4.1-16.4,288-49,337.1C9339.7,3686,9280.5,3692.2,8600.2,3647.2z" />
              <path
                d="M6771.9,3400c-126.7-67.4-118.5,112.4-118.5-2908.9v-2757.8h643.5h643.5V491.1c0,3031.5,8.2,2843.6-122.6,2911C7732,3447,6853.6,3445,6771.9,3400z M7756.5,2166.2v-326.8h-459.6h-459.6v326.8V2493h459.6h459.6V2166.2z" />
              <path
                d="M2343.1,123.4v-3145.9h1062.3h1062.3V123.4v3145.9H3405.4H2343.1V123.4z M4263.4-2225.8V-2614h-847.8h-847.8v388.1v388.1h847.8h847.8V-2225.8z" />
              <path
                d="M5223.5,3020.1c-196.1-8.2-382-26.6-412.6-38.8c-91.9-36.8-143-110.3-153.2-216.5c-8.2-91.9,190-4500.3,202.2-4510.5c6.1-4.1,1291,53.1,1295.1,59.2c2.1,0-42.9,1013.2-98,2249.1c-94,2053-106.2,2255.2-143,2332.9c-44.9,98-136.8,151.2-255.4,147.1C5615.7,3040.5,5419.6,3030.3,5223.5,3020.1z" />
              <path
                d="M484.2,2887.3c0-20.4-55.2-960.1-122.6-2089.8C294.2-332.2,214.5-1666.1,185.9-2170.7c-30.6-502.5-51.1-919.3-44.9-925.4c12.3-12.3,1711.9-118.5,1722.1-106.2c10.2,10.2,363.6,6003.8,353.4,6012c-6.1,6.1-1622,112.4-1716,112.4C492.3,2922,484.2,2905.7,484.2,2887.3z" />
              <path
                d="M8794.3,2870.9c-380-24.5-694.6-49-700.7-53.1c-8.2-8.2,355.5-6007.9,363.6-6018.1c2.1-2,318.7,16.3,704.8,40.9l698.7,42.9v49c0,138.9-363.6,5973.2-371.8,5977.2C9484.7,2911.8,9172.2,2893.4,8794.3,2870.9z" />
              <path
                d="M5438-1972.5c-269.7-14.3-506.6-26.6-525-26.6c-32.7-2-36.8-26.6-36.8-196.1v-194.1h67.4c128.7,0,1068.4,42.9,1154.2,53.1l85.8,10.2v128.7c0,71.5-6.1,159.3-12.2,194.1C6155-1927.6,6210.1-1931.7,5438-1972.5z" />
              <path d="M6653.4-2777.4v-204.3h643.5h643.5v204.3v204.3h-643.5h-643.5V-2777.4z" />
              <path
                d="M5358.3-2885.7l-445.3-20.4l14.3-410.6c14.3-459.6,30.6-518.8,153.2-600.6c65.4-44.9,85.8-47,255.4-36.8c102.1,6.1,306.4,18.4,455.5,24.5c230.9,12.3,281.9,20.4,337.1,57.2c114.4,77.6,120.5,104.2,106.2,543.4c-8.2,218.6-18.4,414.7-22.5,435.1C6199.9-2853,6114.1-2853,5358.3-2885.7z" />
              <path
                d="M6653.4-3533.2v-245.1l69.5-77.6l69.4-75.6h504.6h504.6l69.4,75.6l69.5,77.6v245.1v245.1h-643.5h-643.5V-3533.2z" />
              <path
                d="M2343.1-3516.9c0-234.9,40.9-339.1,149.1-390.2c63.3-30.6,169.6-34.7,927.4-34.7c811,0,858,2,919.3,40.8c104.2,63.3,128.7,132.8,128.7,367.7v204.3H3405.4H2343.1V-3516.9z" />
              <path
                d="M110.3-3486.3c-32.7-198.1,12.2-330.9,132.8-390.2c81.7-38.8,1356.4-114.4,1427.9-85.8c104.2,44.9,143,108.3,165.5,273.7c12.3,85.8,18.4,159.3,14.3,163.4c-4.1,4.1-361.6,26.5-794.6,51.1c-433.1,24.5-819.2,49-860,55.2C124.6-3408.6,122.6-3410.7,110.3-3486.3z" />
              <path
                d="M9115-3474c-292.1-18.4-557.7-36.8-588.3-36.8l-59.2-2l14.3-136.9c34.7-326.9,75.6-339.1,878.4-283.9c406.5,26.6,451.4,40.9,508.7,165.5c34.7,71.5,38.8,138.9,20.4,261.5l-14.3,75.6l-114.4-2.1C9697.2-3435.2,9407.1-3453.6,9115-3474z" />
            </g>
          </g>
        </svg>
        Choose from Library
      </button>
      <div *ngIf="videoUrlPreview && videoSlide.libraryid && videoSlide.preview" class="mb-3">
        <div>
          <vm-player>
            <vm-video>
              <source [attr.data-src]="videoUrlPreview" type="video/mp4" />
            </vm-video>
            <vm-default-ui no-click-to-play>
            </vm-default-ui>
          </vm-player>
        </div>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="bannertype == '002'">
    <div class="mb-3">
      <label class="mb-2"><strong>Text</strong>&nbsp;(Optional)</label>
      <angular-editor [(ngModel)]="imageSlide.text" [config]="config">
      </angular-editor>
    </div>
  
    <div class="mb-3">
      <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput>
      <button class="ms-0" mat-flat-button color="primary" (click)="hiddenfileinput.click()">
        Choose Background Image
      </button>
    </div>
  
    <div>
      <strong>Recommended format</strong>
      <ul style="margin-top: 8px;
                  padding-left: 16px;">
        <li>Image format: JPG/PNG</li>
        <li>Recommended size: 1920 x 690 pixels</li>
      </ul>
    </div>
  
    <div>
      <!-- image-crop -->
      <div *ngIf="imageChangedEvent != '' && !croppedImage" class="img-cropper">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="25/9"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
  
        <!-- <button mat-flat-button color="primary" class="text-end" (click)="copyImage()">Crop
          Image</button> -->
      </div>
  
  
      <div *ngIf="(videoSlide.previewurl || imageSlide.previewurl) && croppedImage">
        <img [src]="bannertype == '002' ? imageSlide.previewurl : videoSlide.previewurl" class="cropped-image">
      </div>
    </div>
  </ng-container>

</div>

<div class="actions-button">

  <button mat-flat-button color="primary" class="mt-2 me-auto" (click)="copyImage()"
    *ngIf="imageChangedEvent != '' && !croppedImage">Crop
    Image</button>

  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isLoading">
    Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="submit()">
  </app-button-loader>

</div>