<div class="memberPage" *ngIf="memberPage">

  <app-breadcrumb [t_breadcrumb]="'Members'" [b_breadcrumb]="channelname" [s_breadcrumb]="chl_text"
    (emit)="goToChannels()" (emit2)="gotoChannelName()"></app-breadcrumb>

  <!-- <app-confirm-dialog></app-confirm-dialog> -->

  <div class="content">


    <div class="d-flex flex-wrap justify-content-between align-items-center">
      <div class="d-flex flex-wrap">
        <button class="btn new-chl my-3 me-2" (click)="goToCreateUser()">
          <i class="fa fa-plus" aria-hidden="true"></i> Create User
        </button>
        <button (click)="addMember()" class="btn new-chl me-2 my-3">
          <i class="fa fa-plus" aria-hidden="true"></i> Add Member
        </button>
        <div class="my-3">
          <button type="button" class="btn btn-custom" title="Import from Excel" (click)="importSheet()"
            *ngIf="importStatus == '001' || importStatus == '005'">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Import
          </button>
          <div class="d-flex align-items-center" *ngIf="importStatus == '002' || importStatus == '003' ">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-black" disabled>
                <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002' "></i>
                <img src="../../../assets/images/upload.gif" width="20px" class="me-2" *ngIf="importStatus == '003' "
                  alt="">
                {{ importStatus == '002' ? 'Checking' : 'Uploading' }}
              </button>
              <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
                <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
              </button>
            </div>
          </div>
          <button type="button" class="btn btn-outline-danger" (click)="importSheet(true)" *ngIf="importStatus == '004'"
            style="width : auto;">
            <i class="fas fa-exclamation-circle me-2"></i>
            Import Error
          </button>
        </div>
      </div>
      <!-- <button color="primary" class="my-3 me-1" (click)="importMembers()" mat-flat-button>
        <img class="excel-icon" src="../../../assets/images/excel.png">
        Import
      </button> -->
      
      <div class="d-flex align-item-center ms-3">
        <div class="me-2 d-flex align-items-center">
          Total :&nbsp;<b> {{datacount}}</b>
        </div>


        <div class="input-group mt-3 me-1 mb-3" style="width: 260px !important;">
          <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
          <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
            *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
            <i class="fa fa-times"></i>
          </button>
        </div>
        
        <button class="btn new-chl mt-3 mb-3" matTooltip="Refresh" (click)="refresh()" type="button">
          <i class="fa fa-refresh"></i>
        </button>
        
        <button class="btn new-chl my-3 ms-2" (click)="exportMembers()">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
      </div>
    </div>

    <div style="overflow: auto;">
      <table mat-table #table [dataSource]="showmembers" (matSortChange)="announceSortChange($event, showmembers)"
        matSort class="mat-elevation-z1" style="width: 100%;">

        <!-- ID Column -->
        <ng-container matColumnDef="userid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
          <td mat-cell *matCellDef="let element"> {{element.userid}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by user_name">User Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by role"> Role </th>
          <ng-container *matCellDef="let element">
            <td mat-cell>
              <span *ngIf="element.role == '1'">Instructor</span>
              <span *ngIf="element.role == '2'">Supervisor</span>
              <span *ngIf="element.role == '100'">Admin</span>
              <span *ngIf="element.role == '3'">HR Admin</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- Member Type Column -->
        <ng-container matColumnDef="membertype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by membertype"> Member Type </th>
          <td mat-cell *matCellDef="let element"> {{element.membertypename}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <ng-container *matCellDef="let element">
            <td mat-cell></td>
          </ng-container>
        </ng-container>

        <!-- Department Column -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef> Department </th>
          <ng-container *matCellDef="let element">
            <td mat-cell></td>
          </ng-container>
        </ng-container>

        <!-- Division Column -->
        <ng-container matColumnDef="division">
          <th mat-header-cell *matHeaderCellDef> Division </th>
          <ng-container *matCellDef="let element">
            <td mat-cell></td>
          </ng-container>
        </ng-container>

        <!-- Remark Column -->
        <ng-container matColumnDef="remark">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by remark"> Remark </th>
          <td mat-cell *matCellDef="let element" px-3> {{element.remark}} </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
          <td mat-cell *matCellDef="let element" px-3 style="text-align: center;">
            <button (click)="getMember(element,element.user_id)"
              class="btn btn-outline-primary btn-custom-rounded me-2 my-1 " matTooltip="Edit Member">
              <i class="fa fa-edit"></i>
            </button>


            <button [disabled]="userid == element.userid && edit_stat == true"
              [style.color]="userid == element.userid && edit_stat == true ? 'grey' : ''"
              [style.border-color]="userid == element.userid && edit_stat == true ? 'grey' : ''"
              class="btn btn-outline-danger btn-custom-rounded my-1" (click)="deleteMember(element)"
              matTooltip="Delete Member">
              <i class="fa fa-trash"></i>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td colspan="8">
            <span class="nodata" *ngIf="!gettingMember">Empty</span>
            <span class="nodata" *ngIf="gettingMember">
              <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

            </span>
          </td>
        </tr>
      </table>
      <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
        [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

</div>

<div class="Editpage" *ngIf="editPage">

  <app-breadcrumb [t_breadcrumb]="'Edit Member'" [b_breadcrumb]="'Members'" [a_breadcrumb]="channelname"
    [s_breadcrumb]="chl_text" (emit1)=gotoChannelName() (emit)="goToChannels()" (emit2)="goToMembers()">
  </app-breadcrumb>

  <div class="container-fluid mt-4">
    <div class="edit-content">
      <div class="card card-body my-card">
        <div *ngIf="gettingEditMember" class="nodata">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </div>

        <form #f="ngForm" (ngSubmit)="f.form.valid && upadateMember()" name="form" novalidate
          *ngIf="!gettingEditMember">
          <div class="row p-3">
            <div class="form-group col-md-6 mb-3">
              <label for="username" class="form-label">User Name</label>
              <input type="text" [(ngModel)]="editMembers[0].username" name="username" class="form-control"
                id="username" required #username="ngModel" autocomplete="off"
                [ngClass]="{ 'is-invalid': f.submitted && username.invalid }">
              <div role="alert" *ngIf="username.errors?.required && f.submitted" style="color: red;padding: 6px;">
                {{allinoneService.input_err_msg}}
              </div>
            </div>

            <div *ngIf="isRootAdmin" class="form-group col-md-6 mb-3" matTooltip="If no changes, the password will be the current password">
              <label for="password" class="form-label">User Password</label>
              <input type="text" [(ngModel)]="editMembers[0].password" name="password" class="form-control"
                id="password" #password="ngModel" autocomplete="off"
                [ngClass]="{ 'is-invalid': f.submitted && password.invalid }">
            </div>

          <div class="form-group col-md-6 mb-3">
            <label for="user_id" class="form-label">User ID</label>
            <input type="text" disabled [(ngModel)]="editMembers[0].userid" name="user_id" class="form-control"
              id="user_id" required #userid="ngModel" autocomplete="off"
              [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }">
            <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
              {{allinoneService.input_err_msg}}
            </div>
          </div>

          <div class="form-group col-md-6 mb-3">
            <label for="status" class="form-label">Status</label>
            <select class="form-select" id="status" name="status" [(ngModel)]="editMembers[0].status" [ngModelOptions]="{standalone: true}">
                <option value = '001' [selected]="editMembers[0].status == '001' " >Active</option>
                <option value = '002' [selected]="editMembers[0].status == '002' " >Inactive</option>
            </select>
          </div> 

          <div class="form-group col-md-6 mb-3">
            <label for="membertype" class="form-label">Member Type</label>
            
            <select class="form-select" name="membertype"
              [(ngModel)]="editMembers[0].membertype" required #membertype="ngModel" [ngClass]="{ 'is-invalid': f.submitted && membertype.invalid }" >
              <option value = '' >-</option>
              <option *ngFor="let item of memberlist" [selected]="item.id === editMembers[0].role"
                value="{{item.id}}">{{item.name}}</option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingmembertype"></mat-progress-bar>
            <div role="alert" *ngIf="membertype.errors?.required && f.submitted" style="color: red;padding: 6px;">
              {{allinoneService.input_err_msg}}
            </div>
          </div>

          <div class="form-group col-md-6 mb-3">
            <label for="role" class="form-label">Role</label>
            
            <select class="form-select" [disabled]="kuserid == editMembers[0].userid && adminrole == 'chladmin'" name="role"
              [(ngModel)]="editMembers[0].role">
              <option value = '' >-</option>
              <option *ngFor="let role of role_list" [selected]="role.id === editMembers[0].role"
                value="{{role.id}}">{{role.name}}</option>
            </select>
          </div>
          
          <div class="form-group col-md-12  mb-3">
            <label for="remark" class="form-label">Remark</label>
            <textarea type="text" [(ngModel)]="editMembers[0].remark" name="remark" class="form-control" id="remark" rows="3"
              #remark_id="ngModel" autocomplete="off"></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button [disabled]="isadding" (click)="goToMembers()" mat-flat-button color="primary" class="mt-2">
              Cancel
            </button>
            <app-button-loader [button_text]="'Update'" [isStillupload]="isadding">
            </app-button-loader>

          </div>
        </div>
        </form>
      </div>
    </div>
  </div>

  <!-- <div class="p-2">

    <div class="container mt-3 mb-3">
      <mat-card>
        <div *ngIf="gettingEditMember" class="nodata">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </div>

        <form #f="ngForm" (ngSubmit)="f.form.valid && upadateMember()" name="form" novalidate
          *ngIf="!gettingEditMember">
          <div class="form-group  mb-3">
            <label for="chl_name" class="mb-2">User ID</label>
            <input type="text" disabled [(ngModel)]="editMembers[0].userid" name="user_id" class="form-control"
              id="user_id" required #userid="ngModel" autocomplete="off"
              [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }">
            <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
              {{allinoneService.input_err_msg}}
            </div>
          </div>

          <div class="form-group mb-4">
            <label for="role" class="mb-2">Role</label>
            <select class="form-select" [disabled]="kuserid == editMembers[0].userid && adminrole == 'chladmin'" name="role"
              [(ngModel)]="editMembers[0].role">

              <option *ngFor="let role of role_list" [selected]="role.id === editMembers[0].role"
                value="{{role.id}}">{{role.name}}</option>
            </select>
          </div>
          <div class="form-group  mb-3">
            <textarea type="text" [(ngModel)]="editMembers[0].remark" name="remark" class="form-control" id="remark"
              #remark_id="ngModel" autocomplete="off"></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button [disabled]="isadding" (click)="goToMembers()" mat-flat-button color="primary" class="mt-2">
              Cancel
            </button>
            <app-button-loader [button_text]="'Update'" [isStillupload]="isadding">
            </app-button-loader>

          </div>
        </form>
      </mat-card>
    </div>
  </div> -->
</div>