import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CreateClassComponent } from '../create-class/create-class.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Class } from '../../shared/models/Class';
import { ClassviewQrComponent } from '../classview-qr/classview-qr.component';

@Component({
  selector: 'app-classview',
  templateUrl: './classview.component.html',
  styleUrls: ['./classview.component.scss'],
})
export class ClassviewComponent implements OnInit {
  // @Input() courseid!: string;

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  filter: string = '';
  allClass: Class[] = [];
  chl: any = '';

  showclass: MatTableDataSource<any> = new MatTableDataSource(this.allClass);

  displayedColumns: any;

  isLoading: boolean = false;
  paginate: boolean = false;
  gettingmyChannel: boolean = false;
  datacount: string = '0';
  searchText: string = '';
  isFocus: boolean = false;
  courseid: string = "";

  classname: string = "";
  classcode: string = "";
  classid: string = "";
  student: boolean = false;
  coursename: string = "";
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    const coid = this.route.snapshot.params['courseid'];
    this.courseid = coid;
  }

  ngOnInit() {
    // this.displayedColumns = ['classcode', 'classname', 'group', 'startdate','remark', 'status', 'action'];
    this.displayedColumns = ['classcode', 'classname', 'startdate','remark', 'status', 'action'];
    this.getAllClass();
  }

  openClassQR(data:any) {
    console.log(data);
    const dialog = this.dialog.open(ClassviewQrComponent, {
      maxWidth: '95vw',
      width: '310px',
      // height: '565px',
      maxHeight: '95vh',
      // panelClass: ['mat-typography'],
      panelClass: 'my-panel-class',
      closeOnNavigation: true,
      autoFocus: false,
      data : {
        classid : data.classid,
        classname : data.classname
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if(dialogResult) {

      }
    })
  }

  newClass(classdata?: any) {
    let dialogRef = this.dialog.open(CreateClassComponent, {
      minWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid: this.courseid,
        update: classdata ? true : false,
        class: classdata ? classdata : {}
      },
    });

    dialogRef.afterClosed().subscribe((result: { success: boolean, data: Class }) => {
      if (result.success) {
        var tempClass = result.data;
        if (classdata) {
          classdata.classcode = tempClass.classcode;
          classdata.classname = tempClass.classname;
          classdata.remark = tempClass.remark;
          classdata.classstatus = tempClass.classstatus;
          classdata.startdate = tempClass.startdate;
          classdata.kunyekchannelid = tempClass.kunyekchannelid;
          classdata.kunyekchannelname = tempClass.kunyekchannelname;
          classdata.hidesections = tempClass.hidesections;
        }
        else {
          this.allClass.push(tempClass)
          this.showclass = new MatTableDataSource(this.allClass);
          this.showclass.paginator = this.paginator;
          this.datacount = this.allClass.length.toString();
        }
      }
    })
  }

  disableClassQr(status : any) {
    if(status == '001') {
      return false;
    }
    return true;
  }

  sortData(sort: Sort) {
    const data = this.allClass;
    if (!sort.active || sort.direction === '') {
      this.allClass = data;
      return;
    }

    this.allClass = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'classcode':
          return this.compare(a.classcode, b.classcode, isAsc);
        case 'classname':
          return this.compare(a.classname, b.classname, isAsc);
        case 'startdate':
          return this.compare(a.startdate, b.startdate, isAsc);
        case 'status':
          return this.compare(a.classstatus, b.classstatus, isAsc);
        case 'remark':
          return this.compare(a.remark, b.remark, isAsc);
        default:
          return 0;
      }
    });
    this.showclass = new MatTableDataSource(this.allClass);
    this.showclass.sort = this.sort;
    this.showclass.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getAllClass() {
    this.allClass = [];
    this.isLoading = true;
    this.courseService.getAllClass(this.courseid).subscribe(
      (classes: any) => {
        if (classes.returncode == '300') {
          this.allClass = classes.datalist;
          
          this.datacount = this.allClass.length.toString();
          this.coursename = classes.coursename;
          this.showclass = new MatTableDataSource(this.allClass);
          if (
            this.allClass == undefined ||
            this.allClass.length == 0
          ) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }

          this.showclass.sort = this.sort;
          this.showclass.paginator = this.paginator;
          this.isLoading = false;
        } else {
          if (classes.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(classes.status || classes.message || classes.error, 'warn');
          }
          this.isLoading = false;
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  clear() {
    this.searchText = '';
    this.showclass.filter = '';
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showclass.filter = searchItem.trim().toLowerCase();
  }
  refresh() {
    this.isLoading = true;
    this.allClass = [];
    this.datacount = "0";
    if ( 
      this.allClass == undefined ||
      this.allClass.length == 0
    ) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showclass = new MatTableDataSource();
    this.getAllClass();
  }

  onChange(data: any) {
    const searchItem = data.target.value;
    if (data.target.value == 'all') {
      this.getAllClass();
    } else {
      this.showclass.filter = searchItem.trim().toLowerCase();
    }
  }


  gotoStudents(data: any) {
    // this.student = true;
    this.router.navigateByUrl(`instructor/courses/${data.courseid}/classes/${data.classid}`);
    // this.classname = data.classname;
    // this.classcode = data.classcode;
    // this.classid = data.classid;
  }

  goToCourse() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/info`)

  }
  goTodropbox(data: any) {
    // instructor/courses/:courseid/classes/dropbox
    console.log("data" + data.classid);
    this.router.navigateByUrl(`instructor/courses/${data.courseid}/${data.classid}/dropbox`);
  }
}
