<div class="d-flex">
    <h2><strong>Share</strong></h2>
    <h4 class="filename">({{filename}})</h4>
</div>
<!-- white-space: nowrap;overflow: hidden; -->

<!--  -->
<form [formGroup]="form" (ngSubmit)="sumbitForm()">


    <ng-container>
        <div class="form-group mb-3">
            <label for="description" class="mb-2"> User ID </label>
            <!--  -->
            <input type="text" class="form-control form-control-sm" placeholder="User ID" formControlName="userid" id="userid" name="userid" [ngClass]="{ 'is-invalid': submitted && formControl.userid.errors }" />

            <div *ngIf="submitted && formControl.userid.errors" class="invalid-feedback">
                <div *ngIf="formControl.userid.errors?.required">
                    {{ allinoneService.input_err_msg }}
                </div>
            </div>
        </div>


    </ng-container>

    <ng-container>
        <h3 *ngIf="shareList.length > 0">Who has access</h3>
    </ng-container>

    <ng-container *ngIf="!isLoading">
        <div style="max-height: 98px;overflow-x: auto;">

            <div class="d-flex userwhole" style="padding: 5px;padding-left: 0px;cursor: pointer;" *ngFor="let share of shareList;let i">
                <!-- defaultImg -->
                <!-- <img *ngIf="share.profileimage == ''" [src]="allinoneService.defaultImg" alt="" style="width: 40px;height: 40px;border-radius: 50px;">
                <img *ngIf="share.profileimage != ''" [src]="allinoneService.imgurl + share.profileimage" alt="" style="width: 40px;height: 40px;border-radius: 50px;"> -->
                <div [ngClass]="{ 'menu-img-profile': share.profileimage == ''}">
                    <span *ngIf="share.profileimage == ''"> {{ share.username.charAt(0)}}</span>
                    <img class="profileimage" *ngIf="share.profileimage != ''" [src]="allinoneService.imgurl + share.profileimage" alt="">

                </div>
                <div class="me-auto">
                    <div style="margin-left: 10px;">{{share.username}}</div>
                    <div style="margin-left: 10px;opacity: 0.7;font-size: 11px;">{{share.userid}}</div>
                </div>
                <div class="remove">
                    <div *ngIf="index != i" class="removeclick" (click)="deleteshareuser(share,i)">Remove</div>


                </div>
                <div *ngIf="index == i " class="removeclick1">
                    Removing...
                </div>


            </div>


        </div>


        <!-- <div *ngIf="!element.url" style="background-image: url('../../../assets/images/khub.png')" class="channel-listing-image" [ngClass]="{'blur' : element.status == '0'}">
        </div> -->

    </ng-container>
    <!-- *ngIf="isLoading" -->
    <ng-container *ngIf="isLoading">
        <tr class="mat-row" *ngFor="let i of [1,2]">
            <div class="d-flex">

                <ngx-skeleton-loader [theme]="{ height: '40px',width: '40px','border-radius':'50px'}" style="margin-bottom: -10px;">
                </ngx-skeleton-loader>
                <div>
                    <ngx-skeleton-loader [theme]="{ height: '15px',width: '100px'}" style="margin-left: 10px;">
                    </ngx-skeleton-loader><br>
                    <ngx-skeleton-loader [theme]="{ height: '15px',width: '100px'}" style="margin-left: 10px;">
                    </ngx-skeleton-loader>
                </div>
            </div>


        </tr>
    </ng-container>

    <div mat-dialog-actions>
        <div class="mb-3 w-100">
            <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
                <button type="button" mat-stroked-button class="ms-0" (click)="cancel()" [disabled]="isSaving">
                Cancel
              </button>
                <button type="submit" mat-flat-button class="ms-2" color="primary" [disabled]="isSaving">
                <span *ngIf="isSaving">
                  <div
                    class="spinner-border spinner-border-sm text-primary"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
                <span *ngIf="!isSaving"> Share </span>
              </button>
            </div>
        </div>
    </div>
</form>