<section class="text-sm-start" *ngIf="signinType">
    <div class="signup-wrapper">
        <div class="mb-4">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->
            <h3 style="font-weight: 600;" class="mb-1">Sign in</h3>
            <!-- <span>to receive a verfication code.</span> -->
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="signUp()">

            <div class="form-group my-2">
                <input type="text" class="form-control" autofocus placeholder="Email" formControlName="userid" [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.userid.errors }">
                <div *ngIf="signupFormSubmitted && signupFormControl.userid.errors" class="invalid-feedback">
                    <div *ngIf="signupFormControl.userid.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

            </div>

            <div class="form-group my-2">
                <div class="input-group">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': signupFormSubmitted && signupFormControl.password.errors }">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,
                                                       'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
                        </span>
                    </div>
                    <div *ngIf="signupFormSubmitted && signupFormControl.password.errors" class="invalid-feedback">
                        <div *ngIf="signupFormControl.password.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>

                </div>


            </div>

            <div class="col-sm-12 offset-0 mt-3 mb-1 d-flex justify-content-between">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="remember" name="_remember" formControlName="isrememberme" (change)="onRememberMeChange($event)">
                    <label class="form-check-label mb-1 ms-1" for="remember">
                        Remember me
                    </label>
                </div>
                <div class="form-check d-flex align-items-center" (click)="forgotPassword()">
                    <label class="sign_forgot mb-1 ms-1">
                        Forgot Password ?
                    </label>
                </div>
            </div>

            <app-button-loader [isStillupload]="isStillSigningIn" [button_text]="'Sign in'" [width]="'100'" [margin_left]="'0'">
            </app-button-loader>


            <!-- <div class="center">
                <button type="button" class="btn btn-light qrlogin" (click)="qrLogin()">Sign in by QR</button>
            </div> -->
        </form>
        <!-- <div class="mt-4">
            
            <span>Don't have an account? <strong class="sign_forgot" routerLink="/signup">Sign up</strong></span>
        </div> -->
        <div class="col-sm-12 offset-0 mt-3 mb-1 d-flex justify-content-between">
            <div class="foot">
                <span>Don't have an account? <strong class="sign_forgot" routerLink="/signup">Sign up</strong></span>
            </div>
            <div class=" d-flex align-items-center">
                <strong class="sign_forgot" (click)="qrLogin()">QR Sign in</strong>
                <!-- <label class="sign_forgot mb-1 ms-1">
                    
                </label> -->
            </div>
        </div>

    </div>
</section>

<!-- QR Signin -->
<section class="text-sm-start" *ngIf="!signinType">
    <div>
        <div class="center mb-4">
            <div *ngIf="qrLoading && !showRefresh" style="height: 200px;width: 364px;">
                <div class="spinner-border" style="margin-top: 85px;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!qrLoading && showRefresh" style="height: 200px;width: 364px;" (click)="getQR()">
                <div style="padding-top: 85px;font-size: 30px; cursor: pointer;" role="status">
                    <!-- <span class="visually-hidden">Loading...</span> -->
                    <!-- <mat-icon>refresh</mat-icon> -->
                    <i class="fa fa-refresh"></i>
                </div>
            </div>
            <ngx-qrcode *ngIf="!qrLoading" qrc-element-type="canvas" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"></ngx-qrcode>
        </div>
        <div class="center title mb-3">
            Sign in to Studio by QR Code
        </div>
        <ol class="list">
            <li>Open Connect 365 app in your phone</li>
            <li>Go to <strong>More > QR Scan</strong></li>
            <li>Point your phone at this screen to confirm sign in</li>
        </ol>
        <!-- <div class="center mt-5">
            <button type="button" class="btn btn-light logintype" (click)="phLogin()">SIGN IN BY PHONE NUMBER</button>
        </div> -->
        <div class="center">
            <button type="button" class="btn btn-light qrlogin" (click)="phLogin()">Sign in by Email</button>
        </div>
    </div>
</section>