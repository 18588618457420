import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { AllInOneService } from '../../services/all-in-one.service';
import { CourseService } from '../../services/course.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @ViewChild('choosefile') choosefile: any;

  isError: boolean = false;
  saveLoading: boolean = false;
  reupload: boolean = false;

  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  channelid: string = '';
  learningPathid : string = '';

  courseid: string = '';
  classid: string = '';

  domainid: string = '';
  errorData: any;
  type: string = ''; // 001 - member(instructor) import // 002 classes dropbox

  // appid = this.allinOneService.getAppId();
  // userid = this.allinOneService.getKunyekUserId();

  allowedFiletype = ['xlsx'];

  progress: number = 0;

  constructor(
    private messageService: MessageService,
    private allinoneService: AllInOneService,
    private courseService: CourseService,
    private allinOneService: AllInOneService,
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('your data');
    console.log(data);
    
    this.isError = data.isError;
    this.channelid = data.channelid;
    this.errorData = data.errorData;
    this.type = data.type
    this.courseid = data.courseid
    this.classid = data.classid
    this.learningPathid = data.learningpathid
  }

  ngOnInit(): void {
  }

  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  uploadfile() {
    if (!this.fileSelected) {
      return this.messageService.openSnackBar('Please upload a file.', 'warn');
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var filename = this.allinoneService.getCurrentDateToDB() + this.allinoneService.getCurrentTimeToDB2() + '_' + this.filename;
  
  
    this.allinoneService
      .fileUploadToS3WithPathForImageAndProgress(
        this.fileSelected,
        filename,
        'importfiles/origin'
      )
      .on('httpUploadProgress', (progress) => {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
        this.progress = progressPercentage;
      })
      .send((err: any, data: any) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          //Fail
          this.progress = 0;
          this.saveLoading = false;
          this.dialogRef.disableClose = false;

          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          return false;
        }
        this.submitImport(filename);
        return true;
      });
  }

  submitImport(filename: string) {
    var type = '';
    if (this.type == '001') {
      type = '001';
    }
    else if (this.type == '002') {
      type = '002';
    } else if (this.type == '003') {
      type = '003';
    }
    var data;
    if (type == '001') {
      data = {
        // appid : this.appid,
        // userid : this.userid,
        channelid: this.channelid,
        filename: filename,
        type: type,
        importstatusid: this.errorData.importstatusid,
      }
    }
    else if (type == '002') {
      data = {
        courseid: this.courseid,
        classid: this.classid,
        filename: filename,
        type: type,
        importstatusid: this.errorData.importstatusid,
      }
    } else if( type == '003') {
      data = {
        // courseid: this.courseid,
        // classid: this.classid,
        // filename: filename,
        // type: type,
        // // importstatusid: this.errorData.importstatusid,
        // importstatusid : this.errorData.importstatusid,
        importstatusid: this.errorData.importstatusid,
        channelid : this.channelid,
        learningpathid : this.learningPathid,
        type : '003',
        filename : filename
      }
    }
    // *** create fun

    var fun = this.courseService.addMemberImport(data);
    if(this.type == '001') {
      fun = this.courseService.addMemberImport(data);
    } else if(this.type == '002') {
      fun = this.courseService.assignmentImport(data);
    } else if (this.type == '003') {
      fun = this.courseService.learningPathUserImport(data);
    }

    console.log("Your data");
    console.log(data);

    fun.subscribe((res: any) => {
      if (res.returncode == '300') {
        this.dialogRef.close({success : true});
      }
      else {
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    }, err => {
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    })
  }

  cancelImport() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data;
    if(this.type == '001') {
      data = {
        channelid: this.channelid,
        importstatusid: this.errorData.importstatusid,
      };
    } else if(this.type == '002') {
      data = {
        classid: this.classid,
        importstatusid: this.errorData.importstatusid,
        channelid : ''
      }
    }
    // *** change this data
    else if(this.type == '003') {
      data = {
        importstatusid: this.errorData.importstatusid,
        channelid : this.channelid,
        learningpathid : this.learningPathid,
      }
    }
    var fun = this.courseService.updateMemberImport(data);
    // if
    fun.subscribe((res: any) => {
      if (res.returncode == '300') {
        this.dialogRef.close(true);
      }
      else {
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    }, err => {
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    })
  }

  onDismiss() {
    if (this.saveLoading) {
      return;
    }
    this.dialogRef.close();
  }

  reuploadFile() {
    this.reupload = true;
  }

  downloadSample() {
    if (this.type == '001') {
      window.open(this.allinOneService.fileurl + 'import/ChannelMemberSampleTemplate.xlsx', '_blank');
    } else if (this.type == '002') {
      window.open(this.allinOneService.fileurl + 'import/AssignmentSampleTemplate.xlsx', '_blank');
    } 
    else if (this.type == '003') {
      window.open(this.allinOneService.fileurl + 'import/LearningTrackUsersTemplate.xlsx', '_blank');
    } 
  }

  //1 - origin, 2- error
  downloadFile(type: number) {
    if (type == 1) {
      window.open(this.errorData.originfilelink, '_blank');
    } else {
      window.open(this.errorData.errorfilelink, '_blank');
    }
  }

}
