import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-by-student',
  templateUrl: './feedback-by-student.component.html',
  styleUrls: ['./feedback-by-student.component.scss'],
})
export class FeedbackByStudentComponent implements OnInit {
  @Input() studentData: any;

  constructor() {
    // console.log(this.studentData);
    // for (let i = 0; i < this.studentData.length; i++) {
    //   // Radio Question
    //   if (this.studentData[i].type == '001') {
    //     var index = this.studentData[i].answerlist.findIndex(
    //       (ans: any) => ans.answerid == this.studentData[i].answerid
    //     );
    //     this.studentData[i].selected = index;
    //     console.log('index ===> ' + this.studentData[i].selected);
    //     this.studentData[i].correct = this.studentData[
    //       i
    //     ].correctanswer.includes(this.studentData[i].answerid);
    //     this.studentData[i].explaination =
    //       this.studentData[i].selected == '-1'
    //         ? ''
    //         : this.studentData[i].answerlist[this.studentData[i].selected]
    //             .explanation;
    //   }
    //   // Checkbox quesitons
    //   else if (this.studentData[i].type == '002') {
    //     this.studentData[i].checkboxans = this.studentData[i].answerid;
    //     this.studentData[i].selectall = false;
    //     var count = 0;
    //     // if(this.studentData[i].answerid.length == this.studentData[i].correctanswer.length){
    //     this.studentData[i].correctanswer.map((item: any) => {
    //       if (this.studentData[i].answerid.includes(item)) {
    //         count += 1;
    //       }
    //     });
    //     if (count == this.studentData[i].correctanswer.length) {
    //       this.studentData[i].selectall = true;
    //     }
    //     if (
    //       this.studentData[i].answerid.length ==
    //       this.studentData[i].correctanswer.length
    //     ) {
    //       if (count == this.studentData[i].correctanswer.length) {
    //         this.studentData[i].correct = true;
    //       }
    //     }
    //   }

    //   // Fill in the blank Questions
    //   else if (this.studentData[i].type == '003') {
    //     const casesensitive = this.studentData[i].casesensitive == 'true';
    //     console.log('case sensitive ----> ' + casesensitive);
    //     if (casesensitive) {
    //       console.log('case sensitive');
    //       this.studentData[i].correct = this.studentData[
    //         i
    //       ].correctanswer.includes(this.studentData[i].answerid);
    //     } else {
    //       console.log('not case sensitive');
    //       const correct = this.studentData[i].correctanswer.find(
    //         (ans: string) => {
    //           return (
    //             ans.toLowerCase() == this.studentData[i].answerid.toLowerCase()
    //           );
    //         }
    //       );
    //       this.studentData[i].correct = correct ? true : false;
    //     }
    //     this.studentData[i].selected = this.studentData[i].answerid;
    //     this.studentData[i].explaination = this.studentData[i].answerlist[0];
    //   }

    //   // Drag and drop matching
    //   else if (this.studentData[i].type == '004') {
    //     this.studentData[i].answerid.map((item: any) => {
    //       var quesIndex = this.studentData[i].question.questions.findIndex(
    //         (x: any) => x.questionid == item.questionid
    //       );
    //       var ansIndex = this.studentData[i].answerlist.findIndex(
    //         (x: any) => x.answerid == item.answerid
    //       );
    //       if (quesIndex > -1) {
    //         this.studentData[i].question.questions[quesIndex].anscontanier = [];
    //         if (ansIndex > -1) {
    //           this.studentData[i].question.questions[quesIndex].anscontanier = [
    //             this.studentData[i].answerlist[ansIndex],
    //           ];
    //           this.studentData[i].answerlist.splice(ansIndex, 1);
    //         }
    //       }
    //     });
    //   }
    // }
  }

  ngOnInit(): void {
    console.log(this.studentData);
  }
}
