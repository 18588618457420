import { data } from 'jquery';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss'],
})
export class CreateStudentComponent implements OnInit {
  courseid: string = '';
  classid: string = '';
  channelid: string = '';

  isEditing: boolean = false;
  isLoading: boolean = false;
  remove: string = 'false';
  checkingExcelFile: boolean = false;
  excelerror: boolean = false;
  isExcel: boolean = false;
  excel: any;
  rawStudentList: any[] = [];
  input: boolean = false;
  @ViewChild('excelFile') excelFile: any;
  @Input() studentList: any = [{}];
  memberSearchText: any = '';
  gettingMembers: boolean = false;

  start: number = 0;
  end: number = 20;

  classFormSubmitted: boolean = false;
  studentForm = this.formBuider.group({
    id: [''],
    adduser: ['', Validators.required],
    studentid: [''],
    studentname: [''],
    username: [''],
    remark: [''],
  });

  get classFormControl() {
    return this.studentForm.controls;
  }

  constructor(
    private dialogRef: MatDialogRef<CreateStudentComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.courseid = data.courseid;
    this.isEditing = data.update;
    this.classid = data.classid;
    this.channelid = data.channelid;
    this.input = data.input == 1 ? true : false;

    if (this.isEditing) {
      this.studentForm.setValue({
        id: data.student.id,
        adduser: data.student.userid,
        username: data.student.username,
        remark: data.student.remark,
        studentid: data.student.studentcode || '',
        studentname: data.student.studentname || '',
      });
    }
  }

  ngOnInit(): void {
    this.getAllMember();
  }


  async getAllMember() {
    this.studentList = [];

    // this.showmembers = new MatTableDataSource();
    console.log("CID>>>");
    console.log(this.channelid);




    if (this.channelid != "" && this.channelid != undefined) {
      this.gettingMembers = true;

      var data = {
        channelid : this.channelid.toString()
      }

      this.courseService.getMember(data).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.studentList = res.datalist;
          // if (this.studentList == undefined || this.studentList.length == 0) {
          //   this.paginate = false;
          // } else {
          //   this.paginate = true;
          // }
          // this.showmembers = new MatTableDataSource(res.datalist);
          // this.showmembers.sort = this.sort;
          // this.showmembers.paginator = this.paginator;
          // this.datacount = res.datalist.length;
          // if (res.name != "") {
          //   this.channelname = res.name;
          // }

          this.gettingMembers = false;
          // const edit = this.route.snapshot.params['edit'];

          // this.router.navigateByUrl(`/admin/channel/${edit}/${channelid}/member`)
        }
        else {
          this.gettingMembers = false;
          if (res.returncode == "210") {
            setTimeout(() => {
              this.gettingMembers = false;
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          }
          else {
            this.gettingMembers = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        }
      }, err => {
        this.gettingMembers = false;
      });
      console.log(this.studentList);
    }
  }

  selectStudent(member: any, trigger: MatAutocompleteTrigger) {

    console.log(member.userid);

    this.studentForm.patchValue({
      adduser: member.userid,
    });
  }

  sumbitClass() {
    this.classFormSubmitted = true;
    if (this.classFormControl.adduser.invalid) {
      return;
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;
    const pkey = this.classFormControl.id.value.toString().trim();

    const adduser = this.allinoneService
      .checkUserId(this.classFormControl.adduser.value.toString())
      .trim();

    const studentcode = this.classFormControl.studentid.value.toString().trim();
    const studentname = this.classFormControl.studentname.value
      .toString()
      .trim();
    const remark = this.classFormControl.remark.value.toString().trim();
    var fun = this.isEditing
      ? this.courseService.updateStudent(
        adduser,
        pkey,
        this.remove,
        studentcode,
        studentname,
        remark
      )
      : this.courseService.createStudent(
        this.courseid,
        adduser,
        this.classid,
        studentcode,
        studentname,
        remark
      );
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.isEditing) {
            var tempClass: any = {
              // classid: this.isEditing ? this.userid : res.classid,09967081701
              id: pkey,
              userid: adduser,
              classid: this.classid,
              username: res.username,
              studentcode: studentcode,
              studentname: studentname,
              remark: remark,
            };
          } else {
            var tempClass: any = {
              id: res.pkey,
              userid: adduser,
              classid: this.classid,
              username: res.username,
              studentcode: studentcode,
              studentname: studentname,
              remark: remark,
              studentid: res.studentid,
            };
          }

          this.isLoading = false;
          this.dialogRef.close({
            success: true,
            bulk: false,
            data: tempClass,
          });
        } else {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(
            res.message || res.error || res.status,
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  onDismiss(): void {
    this.dialogRef.close({ success: false, bulk: false, data: {} });
  }

  importData() {
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    var tempStudentList: any[] = [];
    if (this.end > this.userList.length) {
      this.end = this.userList.length;
    }
    for (let i = this.start; i < this.end; i++) {
      tempStudentList.push(this.userList[i]);
    }
    this.courseService
      .createImportStudent(this.courseid, this.classid, tempStudentList)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (this.end == this.userList.length) {
              this.isLoading = false;
              this.dialogRef.close({
                success: true,
                bulk: true,
              });
            } else {
              this.start = this.end;
              this.end = this.end + 20;
              this.importData();
            }
          } else {
            this.isLoading = false;
            this.dialogRef.disableClose = false;
            this.messageService.openSnackBar(
              res.message || res.error || res.status,
              'warn'
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        }
      );
  }

  downloadSampleFile() {
    window.open('https://assets.khub.cloud/essential/SampleTemplate.xlsx', '_blank');
    // this.http
    //   .get(
    //     'https://assets.khub.cloud/essential/SampleTemplate.xlsx',
    //     { responseType: 'blob' }
    //   )
    //   .subscribe((response: any) => {
    //     saveAs(response, 'SampleTemplate.xlsx');
    //   }),
    //   () => {
    //     this.messageService.openSnackBar('Download failed.', 'warn');
    //   };
  }

  onExcelFileSelect(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawStudentList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }
  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
  }
  duplicateList: any[] = [];
  finalMemberList: any[] = [];
  useridList: any[] = [];
  uidList: any[] = [];
  errorList: any[] = [];
  warningList: any[] = [];
  allok: boolean = false;
  newcclist: any[] = [];
  userList: any[] = [];

  checkExcelFile() {
    this.checkingExcelFile = true;

    this.duplicateList = [];
    this.errorList = [];
    this.warningList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];
    this.userList = [];

    const stuidlist: any = [];
    for (let i = 1; i < this.rawStudentList.length; i++) {
      const raw = this.rawStudentList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '') &&
        (raw[2] == null || raw[2] == undefined || raw[2] == '')
      ) {
      } else {
        if (raw[0] == null || raw[0] == undefined || raw[0] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'User ID' is empty.",
          };
          this.errorList.push(tempError);
        } else {
          const tempStucode = raw[1] ? raw[1].toString().trim() : '';
          const tempStuIndex = stuidlist.findIndex(
            (item: any) => item == tempStucode
          );
          console.log('---> code ---> ' + tempStucode)
          console.log('---> index ---> ' + tempStuIndex)
          if (tempStuIndex > -1) {
            const tempError = {
              line: (i + 1).toString(),
              error: "Student ID '" + tempStucode + "' is duplicated.",
            };
            this.errorList.push(tempError);
          } else {
            if (tempStucode) {
              stuidlist.push(tempStucode);
            }
            const uidindex = this.uidList.findIndex((x: any) =>
              x.userid.trim() == raw[0] ? raw[0] : ''.toString().trim()
            );
            console.log(uidindex);
            if (uidindex > -1) {
              const tempError = {
                line: (i + 1).toString(),
                error:
                  "User ID '" + raw[0].toString().trim() + "' is duplicated.",
              };
              this.warningList.push(tempError);
            } else {
              if (raw[0] ? raw[0] : ''.toString().trim() != '') {
                var tempEId = {
                  line: (i + 1).toString(),
                  userid: raw[0].toString().trim(),
                };
                this.uidList.push(tempEId);
              }

              const uid = this.allinoneService.checkUserId(
                raw[0].toString().trim()
              );

              const studentcode = raw[1] ? raw[1].toString().trim() : '';
              const studentname = raw[2] ? raw[2].toString().trim() : '';
              const userid = uid;
              const remark = raw[3] ? raw[3].toString().trim() : '';

              const userlist = {
                userid: userid,
                line: (i + 1).toString(),
                studentcode: studentcode,
                studentname: studentname,
                remark: remark,
              };
              this.userList.push(userlist);
              if (this.useridList.indexOf(uid) > -1) {
                if (this.duplicateList.indexOf(uid) == -1) {
                  this.duplicateList.push(uid);
                }
              } else {
                this.useridList.push(uid);
                const tempMember = {
                  userid: userid,
                  studentcode: studentcode,
                  studentname: studentname,
                  remark: remark,
                };
                this.finalMemberList.push(tempMember);
                // this.checkingExcelFile = false;
                // this.allok = true;
              }
              // }
              // }
            }
          }
        }
      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.warningList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (this.errorList.length == 0 && this.finalMemberList.length > 0) {
      this.checkStudentClassList(this.userList);
    } else {
      this.checkingExcelFile = false;
    }
  }
  checkStudentClassList(data: any) {
    console.log('1');
    console.log(data);
    this.allok = false;
    this.courseService.checkStudentClasList(this.courseid, data, this.classid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res);

          this.errorList = res.errorlist;
          this.userList = [];
          if (this.errorList.length == 0) {
            this.allok = true;
            this.userList = res.successlist;
          }

          this.checkingExcelFile = false;
        } else {
          this.checkingExcelFile = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.checkingExcelFile = false;
      }
    );
  }
}
