<!-- <button (click)="t()">test</button> -->
<!-- <button (click)="t2()">test2</button> -->
<div id="main-page" class="ss">
  <section style="user-select: none;" id="container">

    <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" style="height: 500px;">
      <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

    </div>
    <div *ngIf="!isLoading">

      <nav class="navbar navbar-expand-lg  px-3  navbar-light d-flex"
        style="border-bottom: 1px solid gray;height: 56px;" *ngIf="!smallscreenview">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class=" d-flex align-items-center" (click)="cancel()" style="cursor: pointer;">
            <div style="color: var(--main-color);margin-top: 5px; ">
              <mat-icon style="width: 20px !important; height: 20px !important; font-size: 20px !important;">arrow_back
              </mat-icon>

            </div>
            <span class="back" style="font-size: 13px;color: var(--main-color);">Back</span>
            <strong class="course-title">{{unitname == '' ? "Quiz" : unitname}}</strong>
          </div>
          <strong *ngIf="!bygroup && isMobileView && studytime != 0" class="timer ms-auto">{{quiztimer}}</strong>
        </div>

      </nav>

      <ng-container *ngIf="bygroup && !isfeedback; else normalTemplate">
        <div class="container-fluid question-small pt-0 d-flex"
          [ngClass]="{'mobile-question-container' : isMobileView}">
          <div style="flex: 1 auto;">
            <ng-container *ngIf="!changeGroupLoading; else groupLoadingTemplate">
              <div class="question-container"
                [style.backgroundColor]="ques.type === '005' && '#ececec'"
                *ngFor="let ques of groupQuestionList[currentPage].questions;let i = index;">
                <div class="question-list">

                  <ul class="questino-radio">

                    <li class="question-li">
                      <div class="d-flex w-100">
                        <div *ngIf="autonumbering">
                          {{i+1}}.&nbsp;
                        </div>
                        <div class="d-flex flex-column w-100">
                          <div class="d-flex justify-content-between w-100">
                            <div class="quiz-header" *ngIf="ques.type != '005'&& ques.type !== '003'">
                              <span class="question-text" [innerHtml]="ques.type == '004' ? ques.question.title : ques.question">
                              </span>
                            </div>

                            <div class="quiz-header" style="border-bottom: none;" *ngIf="ques.type == '005'">
                              <div class="align-items-center row " style="padding-left: 15px;">
                                <span *ngFor="let item of ques.formattedQuiz"
                                  style="width: fit-content;padding: 0px !important;align-self: center;">
                                  <!-- <p  *ngIf="item.type == '001';else dropDownDisplay">{{item.text}}</p> -->
                                  <p  *ngIf="item.type == '001';else dropDownDisplay" [innerHtml]="item.text"></p>
                                  <ng-template #dropDownDisplay>
                                    <span>
                                      <select style="margin: 0 10px; margin-bottom: 10px; padding: 6px 8px; cursor: pointer; border-radius: 5px;"
                                      [disabled]="groupQuestionList[currentPage].disabled"
                                        (change)="onDropDownChange($event,item.index,ques)">
                                        <option [value]="null">-</option>
                                        <option [selected]="item.selectOption == i"
                                          *ngFor="let dropdown of item.option;let i = index" [ngValue]="i">{{dropdown}}
                                        </option>
                                      </select>
                                    </span>
                                  </ng-template>
                                </span>
                              </div>
                            </div>

                            <div style="width: 100%;" *ngIf="ques.type == '003'">
                              <div class="quiz-header">
                                <span *ngFor="let item of ques.formattedQuiz;let j = index">
                                  <span *ngIf="item.type == '001';else fillinblankDisplay" [innerHtml]="item.text"></span>
                                  <ng-template #fillinblankDisplay>
                                    <span class="me-2">
                                      <span class="fillinblankformat text-primary">{{intToRomanInt(item.index + 1)}}</span>
                                    </span>
                                  </ng-template>
                                </span>
                              </div>

                              <div style="margin: 10px;" *ngFor="let item of ques.formattedQuiz;let j = index" class="pt-2">
                                <div *ngIf="item.type == '002'" class='d-flex align-items-baseline'>
                                  <span class="me-2">{{intToRomanInt(item.index + 1)}}.</span>
                                  <input type='text' class='form-control blank' [id]="'question'+i+j"
                                    [(ngModel)]="item.selected" [disabled]="isfeedback || groupQuestionList[currentPage].disabled" (ngModelChange)="outPut(ques)">
                                </div>
                              </div>
                            </div>

                            <!-- <div *ngIf="ques.type == '003'">
                              <div style="padding-right: 5px;padding-left: 5px;padding-bottom: 5px;">
                                <span *ngFor="let item of ques.formattedQuiz;let j = index">
                                  <span *ngIf="item.type == '001';else fillinblankDisplay"
                                    [innerHtml]="item.text"></span>
                                  <ng-template #fillinblankDisplay>
                                    <input type="text" class="form-control blank" style="width: fit-content;" [id]="'question'+i+j"
                                [(ngModel)]="item.selected" [disabled]="isfeedback" (ngModelChange)="outPut(ques)">
                                    <span class="me-2">
                                      <span class="fillinblankformat text-primary">{{item.index+1}}</span>
                                    </span>
                                  </ng-template>
                                </span>
                              </div>
                            </div> -->
                          </div>

                          <div class="row mt-3" *ngIf="ques.imagelist.length > 0">
                            <div class="col-md-6 px-3 mb-3" *ngFor="let img of ques.imagelist; let index = index">
                              <div class="quiz-img-container" (click)="imageViewer(ques.imagelist, index)">
                                <img [src]="img.imageurl">
                              </div>
                            </div>
                          </div>

                          <div style="margin: 10px;" *ngIf="ques.type == '004'">
                            <div cdkDropListGroup>
                              <div class="anlist-example-container">
                                <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="ques.answerlist"
                                  class="answer-list" (cdkDropListDropped)="restoreDrop($event)"
                                  [cdkDropListDisabled]="groupQuestionList[currentPage].disabled">
                                  <!-- <div class="answer-box" *ngFor="let item of ques.answerlist; let aindex = index"
                                    cdkDrag [cdkDragData]="aindex">
                                    <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                    {{item.answer}}
                                  </div> -->

                                  <div class="d-flex align-items-center"
                                    *ngFor="let item of ques.answerlist; let aindex = index" cdkDrag
                                    [cdkDragData]="aindex">
                                    <div *ngIf="item.type != 'image'" class="answer-box">
                                      <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                      <span>{{ item.answer}}</span>
                                    </div>

                                    <div *ngIf="item.type == 'image'" class="answer-box">
                                      <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                      <div class="" (click)="matchingImageViewer(item.imageurl)">
                                        <img [src]="item.imageurl"
                                          style="max-width: 80px; max-height: 80px; object-fit: contain;">
                                      </div>
                                    </div>
                                  </div>


                                </div>
                              </div>
                              <div class="example-container">
                                <div *ngFor="let ans of ques.question.questions; let qindex = index"
                                  class="d-flex justify-content-between"
                                  [ngClass]="{'background-gray': qindex % 2 == 0}">
                                  <div class="matching-ques">
                                    <!-- {{ans.question}} -->
                                    <span *ngIf="ans.type != 'image' ">{{ans.question}}</span>
                                    <div *ngIf="ans.type == 'image'" (click)="matchingImageViewer(ans.imageurl)">
                                      <img [src]="ans.imageurl"
                                        style="max-width: 80px; max-height: 80px; object-fit: contain;">
                                    </div>
                                  </div>
                                  <div cdkDropList [cdkDropListData]="ans.anscontanier"
                                    class="example-list col-6 col-md-6" (cdkDropListDropped)="drop($event)"
                                    [cdkDropListDisabled]="groupQuestionList[currentPage].disabled">
                                    <div class="example-box" *ngFor="let item of ans.anscontanier" cdkDrag>
                                      <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                      <!-- {{item.answer}} -->
                                      <span *ngIf="item.type != 'image'">{{ item.answer}}</span>
                                      <div *ngIf="item.type == 'image'" (click)="matchingImageViewer(item.imageurl)">
                                        <img [src]="item.imageurl"
                                          style="max-width: 80px; max-height: 80px; object-fit: contain;">
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style="margin: 10px;" *ngIf="ques.type == '001' || ques.type == '002' || ques.type == '003'">
                            <ol class="questino-radio">
                              <li *ngFor="let ans of ques.answerlist;let j = index;">
                                <div class="form-check " *ngIf="ques.type=='001' || ques.type == '002'">

                                  <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox"
                                    [name]="'question'+ques.quizid" [id]="'question'+ques.quizid+j"
                                    [checked]="ques.checkboxans.includes(ans.answerid.toString())"
                                    (change)="checkbox($event,i,j)"
                                    [disabled]="groupQuestionList[currentPage].disabled">

                                  <input *ngIf="ques.type=='001'" class="form-check-input" type="radio"
                                    [name]="'question'+ques.quizid" [id]="'question'+ques.quizid+j"
                                    [checked]="j == ques.selected" (change)="radio($event,i,j)"
                                    [disabled]="groupQuestionList[currentPage].disabled">

                                  <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                                  <label class="form-check-label w-100" [for]="'question'+ques.quizid+j">
                                    <span [innerHtml]="ans.answer"></span>
                                  </label>
                                </div>
                                <!-- <div *ngIf="ques.type=='003'">
                                  <input type="text" class="form-control blank" id="formGroupExampleInput"
                                    [placeholder]="isfeedback ? '' :'Fill the answer.'" [id]="'question'+ques.quizid+j"
                                    [(ngModel)]="ques.selected" [disabled]="groupQuestionList[currentPage].disabled">
                                </div> -->
                              </li>
                            </ol>
                          </div>

                        </div>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
              <div class="d-flex">
                <!-- <button mat-flat-button color="primary" class="ms-0" (click)="changeGroup(false)"
              *ngIf="currentPage > 0">Prev</button> -->
                <button mat-flat-button color="primary" *ngIf="currentPage > 0" class="ms-0"
                  (click)="changeGroup(false)">Prev</button>
                <button mat-flat-button color="primary" *ngIf="currentPage < groupQuestionList.length - 1"
                  class="ms-auto" (click)="changeGroup()">Next</button>
                <!-- <button mat-flat-button color="primary" class="ms-auto" (click)="changeGroup()"
              *ngIf="currentPage < groupQuestionList.length - 1">Next</button> -->
                <!-- <button mat-flat-button color="primary" class="ms-auto" *ngIf="currentPage ==  groupQuestionList.length - 1" (click)="submit('', false, true)">Submit</button> -->
              </div>
            </ng-container>
            <ng-template #groupLoadingTemplate>

              <div class="mt-4">
                <ngx-skeleton-loader [theme]="{ height: '40px', width: '100%' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '80%' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '30%' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '30%' }"></ngx-skeleton-loader>
              </div>
              <div class="mt-3">
                <ngx-skeleton-loader [theme]="{ height: '40px', width: '100%' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '20px', width: '60%' }"></ngx-skeleton-loader>
              </div>
            </ng-template>
          </div>

          <section [ngClass]="{'navigation-container-holder' : isMobileView}">
            <div class="navigation-container">

              <div class="question-container" style="padding: 1rem">

                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div>
                    <h3 class="mb-0"><strong>Navigation</strong></h3>
                  </div>
                  <!-- *** -->
                  <div class="d-flex align-items-center timer" *ngIf="havequiztimer">
                    <div class="me-2 d-flex align-items-center">
                      <mat-icon>access_time</mat-icon>
                    </div>
                    <div *ngIf="bygroup && grouptime">
                      {{groupQuestionList[currentPage].starttime === '' ? '--:--' :
                      formatTimeMinSec(groupQuestionList[currentPage].starttime) }}
                    </div>
                    <div *ngIf="!grouptime">
                      {{ quiztimer }}
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap">
                  <ng-container *ngFor="let page of groupQuestionList; let index = index">
                    <div class="page-container"
                      [ngClass]="{'active': index == currentPage,'has-answer': getValidityStatus(index)}"
                      (click)="changeGroup(false,index)">
                      {{index + 1}}
                    </div>
                  </ng-container>
                </div>
                <div class="mt-2 d-flex justify-content-between">
                  <!-- <div>
                  <button mat-flat-button color="primary"  [disabled]="currentPage === 0" class="ms-0 me-2"
                    (click)="changeGroup(false)">Prev</button>
                  <button mat-flat-button color="primary" [disabled]="currentPage === groupQuestionList.length - 1" class="ms-auto"
                    (click)="changeGroup()">Next</button>
                </div> -->
                  <div *ngIf="currentPage == groupQuestionList.length - 1" class="d-flex align-items-center">
                    <div class="submit-text" (click)="submit('', false, true)">
                      <!-- <div class="submit-text" (click)="t()"> -->
                      Submit
                    </div>
                    <!-- <div class="submit-text" (click)="submit('', false, true)"> -->
                    <!-- <div class="submit-text" (click)="t()">
                      t
                    </div> -->
                    <!-- <button mat-flat-button color="primary" class="ms-auto"
                    (click)="submit('', false, true)">Submit</button> -->
                  </div>
                </div>
              </div>
              <mat-divider *ngIf="isMobileView"></mat-divider>
            </div>
          </section>

        </div>
      </ng-container>


      <!-- group quiz end -->

      <ng-template #normalTemplate>
        <div class="container" [ngClass]="{'question' : !smallscreenview && !isfeedback, 'question-small': smallscreenview  && !isfeedback,
        'question-f' : !smallscreenview && isfeedback, 'question-small-f': smallscreenview  && isfeedback
      }">


          <div id="main-page-t">
            <!-- [ngClass]="{'sticky-title' : smallscreenview , 'sticky-title-mobile' : !smallscreenview}" -->
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h1 class="mb-1"><i class="fas fa-clipboard-list me-2" aria-hidden="true"></i>{{unitname == '' ? "Quiz"
                  :
                  unitname}}
                </h1>
                <h3 class="mb-1" *ngIf="isfeedback">
                  <strong>Last Submission Grade : 
                    <span *ngIf="grading == '002'" style="color: green;">{{score | number:"1.1"}}%</span>
                    <span *ngIf="grading == '001'">{{ mark}}/{{ totalmark}} points</span>
                  </strong>
                </h3>
                <h3>{{question.length}} Questions</h3>
              </div>
              <!-- <div class="d-flex justify-content-end" *ngIf="!isfeedback">

            <button [disabled]="issaveing" mat-flat-button color="primary" class="mt-2 " type="submit"
              style="height: 40px;" (click)="submit()">
              <span style="padding: 0;margin: 0;" *ngIf="issaveing"
                class="d-flex align-items-center justify-content-center p-2">
                <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>

              </span>
              <span *ngIf="!issaveing">Submit</span>
            </button>

          </div> -->
            </div>

            <div class="question-list mt-3" *ngFor="let ques of question;let i = index;">

              <ul class="questino-radio">

                <li class="question-li">
                  <div class="d-flex w-100 quiz-container" [style.backgroundColor]="ques.type === '005' && '#ececec'">
                    <div class="d-flex flex-column w-100 ">
                      <div class="d-flex justify-content-between w-100" style="position: relative;">
                        <div class="quiz-header" *ngIf="ques.type != '005' && ques.type !== '003'">
                          <div *ngIf="autonumbering"
                            [ngClass]="{dropdwonnumber : ques.type == '005', quiznumber : ques.type != '005' }">
                            {{i+1}}.&nbsp;
                          </div>
                          <span class="question-text" [style.marginRight.px]="isfeedback ? 70 : 0" [innerHtml]="ques.type == '004' ? ques.question.title : ques.question">
                          </span>
                          <!-- <span *ngIf="ques.type != '005'" [innerHtml]="ques.type == '004' ? ques.question.title : ques.question">
                          </span> -->
                          <!-- <span *ngIf="ques.type == '005'" [innerHtml]="displayDropDownQuiz(ques)"></span> -->
                          <!-- <span *ngIf="ques.type == '005'" [innerHtml]="changeDropDown(ques.question)"></span> -->
                          <!-- <span *ngIf="ques.type == '005'">{{changeDropDown(ques.question)}}</span> -->
                        </div>

                        <div class="quiz-header" [style.paddingRight.px]="isfeedback ? 70 : 8" *ngIf="ques.type == '005'" style="border-bottom: none;">
                          <div style="display: flex; align-items: center;">
                            <div style="margin-top: -12px;" *ngIf="autonumbering"
                              [ngClass]="{dropdwonnumber : ques.type == '005', quiznumber : ques.type != '005' }">
                              {{i+1}}.&nbsp;
                            </div>

                            <span *ngFor="let item of ques.formattedQuiz"                              
                              style="width: fit-content;padding: 0px !important; display: flex; align-items: start;">
                              <!-- <p  *ngIf="item.type == '001';else dropDownDisplay">{{item.text}}</p> -->
                              <p  *ngIf="item.type == '001';else dropDownDisplay" [innerHtml]="item.text"></p>
                              <ng-template #dropDownDisplay>
                                <span>
                                  <select [disabled]="isfeedback" style="margin: 0 10px; padding: 6px 8px; cursor: pointer; border-radius: 5px;"
                                    (change)="onDropDownChange($event,item.index,ques)">
                                    <option [value]="null">-</option>
                                    <option [selected]="item.selectOption == i"
                                      *ngFor="let dropdown of item.option;let i = index" [ngValue]="i">{{dropdown}}
                                    </option>
                                  </select>
                                </span>
                              </ng-template>
                            </span>
                          </div>
                        </div>

                        <div style=" width: 100%;" *ngIf="ques.type == '003'">
                          <div class="quiz-header" [style.paddingRight.px]="isfeedback ? 70 : 8">
                            <div *ngIf="autonumbering"
                              [ngClass]="{dropdwonnumber : ques.type == '005', quiznumber : ques.type != '005' }">
                              {{i+1}}.&nbsp;
                            </div>

                            <span *ngFor="let item of ques.formattedQuiz;let j = index">
                              <span *ngIf="item.type == '001';else fillinblankDisplay" [innerHtml]="item.text"></span>
                              <ng-template #fillinblankDisplay>
                                <!-- <input type="text" class="form-control blank" style="width: fit-content;" [id]="'question'+i+j"
                                [(ngModel)]="item.selected" [disabled]="isfeedback" (ngModelChange)="outPut(ques)"> -->
                                <span class="me-2">
                                  <span class="fillinblankformat text-primary">{{intToRomanInt(item.index+1)}}</span>
                                </span>
                              </ng-template>
                            </span>
                          </div>

                          <div *ngFor="let item of ques.formattedQuiz;let j = index" class="pt-2" style="margin: 10px 30px;">
                            <div *ngIf="item.type == '002'" class='d-flex align-items-baseline'>
                              <span class="me-2">{{intToRomanInt(item.index+1)}}.</span>
                              <input type='text' class='form-control blank' [id]="'question'+i+j"
                                [(ngModel)]="item.selected" [disabled]="isfeedback" (ngModelChange)="outPut(ques)">
                            </div>
                          </div>
                        </div>

                        <div class="point-container" *ngIf="isfeedback && !ques.countbyquantity &&  ques.type != '004'">
                          <span class="point"> {{ques.score}}/{{ques.mark}}
                            {{ques.score > 1 ? 'points' : 'point' }}</span>
                        </div>
                        <div class="point-container" *ngIf="isfeedback && ques.countbyquantity && ques.type == '004'">
                          <span class="point"> {{ques.score}}/{{ques.mark*ques.question.questions.length}}
                            {{ques.question.questions.length > 1 ? 'points' : 'point' }}</span>
                        </div>

                        <div class="point-container" *ngIf="isfeedback && !ques.countbyquantity && ques.type == '004' ">
                          <span class="point"> {{ques.correct ? '1' : '0'}}/1 point</span>
                        </div>

                        <!-- <div class="point-container" *ngIf="isfeedback && !countbyquantity">
                          <span class="point"> {{ques.correct ? '1' : '0'}}/1 point</span>
                        </div>

                        <div class="point-container" *ngIf="isfeedback && countbyquantity">
                          <span class="point"> {{ques.correct ? '1' : '0'}}/{{ques.question.questions.length}} point</span>
                        </div> -->
                      </div>

                      <div class="row mt-3" *ngIf="ques.imagelist.length > 0">
                        <div class="col-md-6 px-3 mb-3" *ngFor="let img of ques.imagelist; let index = index">
                          <div class="quiz-img-container" (click)="imageViewer(ques.imagelist, index)">
                            <img [src]="img.imageurl">
                          </div>
                        </div>
                      </div>

                      <div style="margin: 10px 30px;" *ngIf="ques.type == '004'">
                        <div cdkDropListGroup>
                          <div class="anlist-example-container">
                            <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="ques.answerlist"
                              class="answer-list" (cdkDropListDropped)="restoreDrop($event)"
                              [cdkDropListDisabled]="isfeedback">

                              <div *ngFor="let item of ques.answerlist; let aindex = index"
                                class="d-flex align-items-center" cdkDrag [cdkDragData]="aindex">

                                <div *ngIf="item.type != 'image'" class="answer-box">
                                  <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                  <span>{{ item.answer}}</span>
                                </div>

                                <div *ngIf="item.type == 'image'" class="answer-box">
                                  <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                  <div class="" (click)="matchingImageViewer(item.imageurl)">
                                    <img [src]="item.imageurl"
                                      style="max-width: 80px; max-height: 80px; object-fit: contain;">
                                  </div>
                                </div>

                              </div>

                              <!-- <div class="answer-box" *ngFor="let item of ques.answerlist; let aindex = index" cdkDrag
                            [cdkDragData]="aindex">
                            <div class="custom-placeholder" *cdkDragPlaceholder></div>

                            <span>{{ item.answer}}</span>

                            <div class="" (click)="matchingImageViewer(item.imageurl)">
                              <img [src]="item.imageurl"
                                style="max-width: 80px; max-height: 80px; object-fit: contain;">
                            </div>
                          </div> -->

                            </div>
                          </div>
                          <div class="example-container">
                            <div *ngFor="let ans of ques.question.questions; let qindex = index"
                              class="d-flex justify-content-between" [ngClass]="{'background-gray': qindex % 2 == 0}">
                              <div class="matching-ques">
                                <!-- {{ans.question}} -->
                                <span *ngIf="ans.type != 'image' ">{{ans.question}}</span>
                                <!-- <img *ngIf="ans.type == 'image'" [src]="ans.imageurl"
                              style="max-width: 80px; max-height: 80px; object-fit: contain;" alt=""> -->
                                <div *ngIf="ans.type == 'image'" (click)="matchingImageViewer(ans.imageurl)">
                                  <img [src]="ans.imageurl"
                                    style="max-width: 80px; max-height: 80px; object-fit: contain;">
                                </div>
                              </div>
                              <div cdkDropList [cdkDropListData]="ans.anscontanier" class="example-list col-6 col-md-6"
                                (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="isfeedback">
                                <div class="example-box" *ngFor="let item of ans.anscontanier" cdkDrag>
                                  <div class="custom-placeholder" *cdkDragPlaceholder></div>
                                  <!-- {{item.answer}} -->
                                  <span *ngIf="item.type != 'image'">{{ item.answer}}</span>
                                  <!-- <img *ngIf="item.type == 'image'" [src]="item.imageurl"
                                style="max-width: 80px; max-height: 80px; object-fit: contain;" alt=""> -->

                                  <div *ngIf="item.type == 'image'" (click)="matchingImageViewer(item.imageurl)">
                                    <img [src]="item.imageurl"
                                      style="max-width: 80px; max-height: 80px; object-fit: contain;">
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="isfeedback && ques.type != '004'">
                          <div class="alert alert-danger feedback feedback-danger" *ngIf="!ques.correct">
                            <div class="d-flex">
                              <mat-icon>cancel</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Incorrect
                              </div>
                            </div>
                          </div>
                          <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                            <div class="d-flex">
                              <mat-icon>check_circle</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Correct
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="isfeedback && ques.type == '004'">
                          <div class="alert alert-danger feedback feedback-danger"
                            *ngIf="!ques.correct && ques.score == 0">
                            <div class="d-flex">
                              <mat-icon>cancel</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Incorrect
                              </div>
                            </div>
                          </div>

                          <div class="alert alert-primary feedback" *ngIf="!ques.correct && ques.score > 0">
                            <div class="d-flex">
                              <mat-icon>check_circle</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                {{ ques?.correct_questions }} {{ ques?.correct_questions > 1 ? 'questions' : 'question' }} correct
                              </div>
                            </div>
                          </div>

                          <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                            <div class="d-flex">
                              <mat-icon>check_circle</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Correct
                              </div>
                            </div>
                          </div>
                        </ng-container>

                      </div>

                      <div style="margin: 10px 30px;" *ngIf="ques.type != '004'">
                        <ol class="questino-radio">
                          <li *ngFor="let ans of ques.answerlist;let j = index;">
                            <div class="form-check " *ngIf="ques.type=='001' || ques.type == '002'">

                              <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox"
                                [name]="'question'+i" [id]="'question'+i+j"
                                [checked]="ques.checkboxans.includes(ans.answerid.toString())"
                                (change)="checkbox($event,i,j)" [disabled]="isfeedback">

                              <input *ngIf="ques.type=='001'" class="form-check-input" type="radio"
                                [name]="'question'+i" [id]="'question'+i+j" [checked]="j == ques.selected"
                                (change)="radio($event,i,j)" [disabled]="isfeedback">

                              <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                              <label class="form-check-label w-100" [for]="'question'+i+j">
                                <span [innerHtml]="ans.answer"></span>
                              </label>
                            </div>
                            <!-- <div *ngIf="ques.type=='003'">
                              <input type="text" class="form-control blank" id="formGroupExampleInput"
                                [placeholder]="isfeedback ? '' :'Fill the answer.'" [id]="'question'+i+j"
                                [(ngModel)]="ques.selected" [disabled]="isfeedback"
                                (keyup.enter)="outPut(ques)">
                            </div> -->
                            <ng-container *ngIf="ques.type == '002' && isfeedback">
                              <div class="alert alert-danger feedback feedback-danger m-0 mt-2"
                                *ngIf="!ques.correctanswer.includes(ans.answerid.toString()) && ques.checkboxans.includes(ans.answerid.toString())">
                                <div class="d-flex">
                                  <mat-icon>cancel</mat-icon>
                                </div>
                                <div class="d-flex flex-column">
                                  <div class="d-flex">
                                    Incorrect
                                  </div>
                                  <div class="mt-2" *ngIf="ans.explanation">
                                    {{ans.explanation}}
                                  </div>
                                </div>
                              </div>
                              <div class="alert alert-success feedback feedback-success m-0 mt-2"
                                *ngIf="ques.correctanswer.includes(ans.answerid.toString()) && ques.checkboxans.includes(ans.answerid.toString())">
                                <div class="d-flex">
                                  <mat-icon>check_circle</mat-icon>
                                </div>
                                <div class="d-flex flex-column">
                                  <div>
                                    Correct
                                  </div>
                                  <div class="mt-2" *ngIf="ans.explanation">
                                    {{ans.explanation}}
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </li>
                        </ol>
                        <ng-container *ngIf="(ques.type == '001' || ques.type == '003') && isfeedback">
                          <div class="alert alert-danger feedback feedback-danger" *ngIf="!ques.correct">
                            <div class="d-flex">
                              <mat-icon>cancel</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Incorrect
                              </div>
                              <div class="mt-2" *ngIf="ques.explaination">
                                {{ques.explaination}}
                              </div>
                            </div>
                          </div>
                          <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                            <div class="d-flex">
                              <mat-icon>check_circle</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Correct
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="ques.type == '002' && isfeedback && !ques.selectall">
                          <div class="alert alert-secondary feedback feedback-secondary">
                            <div class="d-flex">
                              <mat-icon></mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                You did not select all the correct answers.
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="ques.type == '005' && isfeedback">
                          <div *ngIf="!ques.correct" class="alert alert-danger feedback feedback-danger">
                            <div class="d-flex">
                              <mat-icon>cancel</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Incorrect
                              </div>
                              <!-- <ng-container *ngFor="let item of ques.correctanswer;let ind = index">
                                <div class="mt-2" *ngIf="item != ques.answerid[ind]">
                                  At Dropdwon No.{{ind+1}}, {{ques.answerlist[ind].explanation}}
                                </div>
                              </ng-container> -->

                            </div>
                          </div>
                          
                          <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                            <div class="d-flex">
                              <mat-icon>check_circle</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Correct
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

            </div>
          </div>

          <!-- <div class="d-flex justify-content-end" *ngIf="isfeedback && !hideCloseBtn">
            <button mat-flat-button color="primary" class="mt-2" style="height: 40px;" (click)="cancel()">
              Close
            </button>
          </div> -->
          <div class="d-flex justify-content-end sticky-title" *ngIf="!isfeedback">

            <!-- (click)="submit('', false, true)" -->
            <button [disabled]="issaveing" mat-flat-button color="primary" class="mt-2 " type="submit"
              style="height: 40px;" (click)="submit()">
              <span style="padding: 0;margin: 0;" *ngIf="issaveing"
                class="d-flex align-items-center justify-content-center p-2">
                <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>

              </span>
              <span *ngIf="!issaveing">Submit</span>
            </button>

          </div>
          <!-- <button [disabled]="issaveing" mat-flat-button color="primary" class="mt-2" style="height: 40px;"
          (click)="cancel()">
          Cancel
        </button> -->

        </div>
      </ng-template>
    </div>

  </section>
</div>
<div class="ss">
  <div class="d-flex justify-content-end" style="padding: 0 30px;" *ngIf="isfeedback && !isLoading">
    <button mat-flat-button color="primary" class="mt-2" style="height: 40px;" (click)="cancel()">
      Close
    </button>
  </div>
</div>
