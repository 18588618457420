import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { Question } from '../../shared/models/Quiz';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { TemplateQuestionsSetupComponent } from '../template-questions-setup/template-questions-setup.component';

@Component({
  selector: 'app-template-questions-view',
  templateUrl: './template-questions-view.component.html',
  styleUrls: ['./template-questions-view.component.scss'],
})
export class TemplateQuestionsViewComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  questionTableData!: MatTableDataSource<any>;
  subscription!: Subscription;
  questionsData: any = [];
  templateid = '';
  gettingQuestions: boolean = false;
  isFocus: boolean = false;
  isPopup: boolean = false;

  displayedColumns: string[] = ['question', 'type', 'action'];
  searchText: string = '';
  templateName: string = '';
  role: string = '';

  courseid : string = '';

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<TemplateQuestionsViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if(data.courseid) {
      this.courseid = data.courseid
    }

    if(data.templateid){
      this.templateid = data.templateid;
      this.isPopup = true;
      this.displayedColumns = ['question', 'type'];
    }
    else{
      this.activatedRoute.data.subscribe((data) => {
        this.role = data.role;
      });
      if (
        (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
        (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())
      ) {
        this.router.navigateByUrl('');
      }
      this.templateid = this.route.snapshot.params['templateid'];
    }
  }

  ngOnInit(): void {
    
    this.getQuestions();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
  getQuestions() {
    this.questionsData = [];
    this.gettingQuestions = true;
    this.questionTableData = new MatTableDataSource();
    this.subscription = this.courseService
      .getQuestionsByTemplate('', '', this.templateid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.templateName = res.templatename;
            this.questionsData = res.datalist;
            this.questionTableData = new MatTableDataSource(this.questionsData);
            setTimeout(() => {
              this.questionTableData.paginator = this.paginator;
              // this.questionTableData.sort = this.sort;
            }, 100);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingQuestions = false;
        },
        (err: any) => {
          this.gettingQuestions = false;
        }
      );
  }

  refresh() {
    this.getQuestions();
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.questionTableData.filter = searchItem.trim().toLowerCase();
  }

  deleteQuestion(question: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService.deleteQuestion(question.quizid, this.courseid).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var index = this.questionsData.findIndex(
                (x: any) => x.fbtmpid == question.quizid
              );
              this.questionsData.splice(index, 1);
              this.questionTableData = new MatTableDataSource(
                this.questionsData
              );
              setTimeout(() => {
                this.questionTableData.paginator = this.paginator;
                this.questionTableData.sort = this.sort;
              }, 100);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.loadingService.hide();
          },
          (err) => {
            this.loadingService.hide();
          }
        );
      }
    });
  }

  clear() {
    this.searchText = '';
    this.questionTableData.filter = '';
  }
  questionSetup(question?: any) {
    const dialog = this.dialog.open(TemplateQuestionsSetupComponent, {
      maxWidth: '95vw',
      width: '900px',
      maxHeight: '95vh',
      height: 'auto',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isEditing: question ? true : false,
        question: question,
        templateid: this.templateid,
        sortkey: this.questionsData.length,
        courseid : this.courseid
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (question) {
          question.question = dialogResult.question;
          question.answerlist = dialogResult.answerlist;
          question.sortkey = dialogResult.sortkey;
          question.type = dialogResult.type;
        } else {
          var tempQ: Question = dialogResult;
          this.questionsData.push(tempQ);
          this.questionTableData = new MatTableDataSource(this.questionsData);
          setTimeout(() => {
            this.questionTableData.paginator = this.paginator;
            this.questionTableData.sort = this.sort;
          }, 100);
        }
      }
    });
  }

  goToFeedback() {
    this.router.navigateByUrl(`${this.role}/feedback`);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.questionsData,
      event.previousIndex,
      event.currentIndex
    );
    // setTimeout(() => {
      this.questionTableData = new MatTableDataSource(this.questionsData);
      this.questionTableData.paginator = this.paginator;
    // }, 100);

    this.questionsortKeyUpdate(this.questionsData);
  }

  questionsortKeyUpdate(questions: Question[]) {
    var fquestions: any = [];
    questions.map((question: any, index: number) => {
      question.sortkey = index;
      fquestions.push({
        quizid: question.quizid,
        sortkey: index.toString().trim(),
      });
    });

    this.courseService.sortQuestions(fquestions).subscribe(
      (res: any) => {},
      (err) => {}
    );
  }

  dismiss(){
    this.dialogRef.close();
  }
}
