import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { UpdateGradeResultComponent } from '../update-grade-result/update-grade-result.component';
import { GradeBookTotalDetailsComponent } from '../grade-book-total-details/grade-book-total-details.component';
import { GradeBookResetQuizComponent } from '../grade-book-reset-quiz/grade-book-reset-quiz.component';
import { FilterGradeComponent } from './filter-grade/filter-grade.component';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { data } from 'jquery';
import { table } from 'console';
import html2canvas from 'html2canvas';
import { QuizPdfComponent } from '../quiz-pdf/quiz-pdf.component';
import { EventEmitter } from 'stream';
@Component({
  selector: 'app-grade-book',
  templateUrl: './grade-book.component.html',
  styleUrls: ['./grade-book.component.scss'],
})
export class GradeBookComponent implements OnInit {
  nogradeableunits: boolean = false;
  gettinggrade: boolean = true;
  alldata: any[] = [];
  showdata!: MatTableDataSource<any>;

  courseid: string = '';
  coursename: string = '';
  unitId: string = '';
  selectedUnitId: string = ''; 
  elementData: any;
  elementAllData: any;
  studentName: string = '';
  studentId: string = '';

  searchText: string = '';

  classid: string = '';
  classlist: any[] = [];
  gettingClass: boolean = false;

  isFocus: boolean = false;

  unitlist: any[] = [];

  // 7- Assignment
  // 6 - Quiz
  // 0 - Total
  gradetype: string = '7';
  gradeFilterValue: number = 0;
  filterGradeOp: string = '=';

  displayedColumns = ['name'];

  dataSource!: MatTableDataSource<any>;

  gradeSubscription!: Subscription;

  constructor(
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
  }

  ngOnInit(): void {
    this.getGradebook();
    this.getAllClass();
  }

  getAllClass() {
    this.classlist = [];
    this.gettingClass = true;
    this.courseService.getAllClass(this.courseid).subscribe(
      (classes: any) => {
        if (classes.returncode == '300') {
          this.classlist = classes.datalist;
          this.gettingClass = false;
        } else {
          if (classes.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              classes.status || classes.message || classes.error,
              'warn'
            );
          }
          this.gettingClass = false;
        }
      },
      (err) => {
        this.gettingClass = false;
      }
    );
  }

  totalDetails(data: any) {
    console.log(data);
    let totalDialog = this.dialog.open(GradeBookTotalDetailsComponent, {
      width: '800px',
      maxWidth: '95vw',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid: this.courseid,
        userid: data.userid,
      },
    });
    totalDialog.afterClosed().subscribe((dresult) => {});
  }

  getGradebook() {
    this.nogradeableunits = false;
    this.gettinggrade = true;
    this.displayedColumns = ['name'];
    this.gradeSubscription = this.courseService
      .getGradebook(this.courseid, this.gradetype, this.classid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.coursename = res.coursename;
            if (res.unitlist.length == 0) {
              this.nogradeableunits = true;
              return;
            }
            this.alldata = res.datalist;
            this.unitlist = res.unitlist;
            if (this.gradetype == '0') {
              this.displayedColumns = ['name', 'percentage'];
            } else {
              this.unitlist.map((unit: any) => {
                this.displayedColumns.push(unit.unitid);
              });
            }
            this.showdata = new MatTableDataSource(this.alldata);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettinggrade = false;
        },
        (err) => {
          this.nogradeableunits = true;
          this.gettinggrade = false;
        }
      );
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterGradeComponent, {
      width: '400px',
      data: { unitlist: this.unitlist }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedUnitId = result.selectedUnitId;
        this.gradeFilterValue = result.gradeFilterValue;
        this.filterGradeOp = result.filterGradeOp;
        this.applyGradeFilter();
      }
    });
  }

  onUnitChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedUnitId = target.value;

    if (this.selectedUnitId) {
      const filteredData = this.alldata.filter((data: any) => 
        data.grade.some((g: any) => g.unitid === this.selectedUnitId)
      );
      this.showdata = new MatTableDataSource(filteredData);
    } else {
      this.showdata = new MatTableDataSource(this.alldata);
    }
  }

  applyGradeFilter() {
    const matchesGradeFilter = (grade: string): boolean => {
      const gradeValue = parseFloat(grade);
  
      switch (this.filterGradeOp) {
        case '=':
          return gradeValue === this.gradeFilterValue;
        case '>=':
          return gradeValue >= this.gradeFilterValue;
        case '<=':
          return gradeValue <= this.gradeFilterValue;
        case '>':
          return gradeValue > this.gradeFilterValue;
        case '<':
          return gradeValue < this.gradeFilterValue;
        default:
          return false;
      }
    };
  
    const filteredData = this.alldata.filter((data: any) => {
      const hasGradeMatch = data.grade.some((g: any) => {
        const matchesUnit = !this.selectedUnitId || g.unitid === this.selectedUnitId;
        const matchesGrade = this.gradeFilterValue === 0 
          ? g.grade === '-' 
          : matchesGradeFilter(g.grade);
  
        return matchesUnit && matchesGrade;
      });
  
      return hasGradeMatch;
    });
  
    this.showdata = new MatTableDataSource(filteredData);
  }
  

  changeType() {
    this.gradeSubscription && this.gradeSubscription.unsubscribe();
    this.getGradebook();
  }

  clear() {
    this.searchText = '';
    this.showdata.filter = '';
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showdata.filter = searchItem.trim().toLowerCase();
  }

  resetQuiz(user: any, result: any, quizname: any) {
    if (this.gradetype == '7' || result.grade == '-') {
      return;
    }
    let dialogRef = this.dialog.open(GradeBookResetQuizComponent, {
      // width: '500px',
      minWidth: '400px',
      maxWidth: '95vw',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        student: user.username || user.userid,
        id: result.id,
        questioncount: result.questioncount,
        unitname: quizname,
        unitid: result.unitid,
        grade: result.grade,
        type: this.gradetype,
      },
    });

    dialogRef.afterClosed().subscribe((dresult) => {
      if (dresult.success) {
        result.grade = '-';
      }
    });
  }

  editResult(user: any, result: any) {
    console.log(this.gradetype);
    if (this.gradetype == '6' || result.grade == '-') {
      return;
    }
    let dialogRef = this.dialog.open(UpdateGradeResultComponent, {
      width: '500px',
      maxWidth: '95vw',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        student: user.username || user.userid,
        id: result.id,
        questioncount: result.questioncount,
        unitname: result.unitname
          ? result.unitname
          : this.gradetype == '6'
          ? 'Quiz'
          : 'Assignment',
        grade: result.grade,
        type: this.gradetype,
      },
    });

    dialogRef.afterClosed().subscribe((dresult) => {
      if (dresult) {
        var tempGrade = dresult.grade.toString();
        result.grade = tempGrade == '' ? '-' : tempGrade;
      }
    });
  }

  t() {
    console.log(this.gradetype);
    console.log(this.unitlist);
    console.log(this.alldata);
    console.log(this.unitlist.length);
  }

  downloadSheet() {
    var data: any = [];
    var name =
      (this.gradetype == '6'
        ? 'Quiz Gradebook'
        : this.gradetype == '7'
        ? 'Assignment Gradebook'
        : 'Total Gradebook') + '.xlsx';
    console.log('ad');
    console.log(this.alldata);

    this.alldata.map((student: any) => {
      console.log('ys');
      console.log(student);

      var temp: any = {
        'User ID': student.userid,
        Name: student.username,
      };
      if (this.gradetype != '0') {
        for (let i = 0; i < this.unitlist.length; i++) {
          var name;
          var d = 1;
          var id;
          if (this.gradetype == '6') {
            name = student.grade[i].unitname;
            id = student.grade[i].unitid;
          } else {
            name = student.grade[i].unitname;
            id = student.grade[i].unitid;
          }

          // temp[name] = student.grade[i].grade;

          // var n = name + id;
          // temp[n] = student.grade[i].grade;

          if (name in temp) {
            // var n = name + " - " + id;
            d = d + 1;
            var n = name + ' (' + d.toString() + ')';
            temp[n] = student.grade[i].grade;
          } else {
            temp[name] = student.grade[i].grade;
          }
        }
      }

      if (this.gradetype == '0') {
        temp = Object.assign(temp, {
          Percentage: student.totalpercentage + '%',
        });
      }
      data.push(temp);

      console.log('your d d d');
      console.log(data);
    });
    this.allinoneService.exportExcel(data, name);
  }

  async downloadPDFSheet() {
    const doc = new jsPDF();

    const header: any = [];
    const body: any = [];
    header.push('Name');

    this.unitlist.forEach((item) => {
      header.push(item.unitname);
    });

    this.alldata.map((student: any) => {
      var data = [];
      data.push(student.username);
      for (let i = 0; i < this.unitlist.length; i++) {
        data.push(student.grade[i].grade);
      }
      body.push(data);
    });

    let totalPages = 0;
    const columnCount = header.length;
    const tableWidth = 120;
    const fontSize = Math.floor(tableWidth / columnCount);
    const defaultFontSize = 14;
    autoTable(doc, {
      head: [header],
      body: body,
      showHead: 'firstPage',
      theme: 'striped',
      styles: {
        fontSize: fontSize < defaultFontSize ? fontSize : defaultFontSize,
      },
      didDrawPage: function (data: any) {
        doc.setFontSize(10);
        totalPages++;
      },
    });
    for (var i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' of ' + String(totalPages),
        doc.internal.pageSize.width - 10,
        doc.internal.pageSize.height - 10,
        { align: 'right' }
      );
    }
    doc.save('table.pdf');
  }

  pdfPrint: boolean = false;

  // <td mat-cell *matCellDef="let element" (click)="quizPdfPrintPreView(element.grade[i],element)">
  // <!-- (click)="resetQuiz(element, element.grade[i], unit.unitname || 'Quiz ' + i + 1)" -->
  quizPdfPrintPreView(element: any, studentData: any) {
    console.log("element", element);
    console.log(studentData);
    this.studentName = studentData.username;
    this.studentId = studentData.userid;
    if (this.gradetype != '6' || element.grade == '-') {
      return;
    }
    this.unitId = element.unitid;
    this.elementAllData = studentData;
    this.elementData = element;
    this.pdfPrint = true;
  }
  receiveDataFromChild(data: string) {
    this.pdfPrint = !this.pdfPrint;
  }
}
