import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-load-text',
  templateUrl: './button-load-text.component.html',
  styleUrls: ['./button-load-text.component.scss']
})
export class ButtonLoadTextComponent implements OnInit {

  @Input() button_text!: string;
  @Input() load_button_text!: string;
  @Input() width!: string;
  @Input() outlinedanger: boolean = false;

  @Input() isLoading!: boolean;

  @Input() disabled : boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    if (this.width == null) {
      this.width = "auto";
    }
  }

}
