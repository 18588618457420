<div class="d-flex justify-content-between align-items-center mb-2">
    <h2 class="mb-0"> Class Section Schedule </h2>
    <div mat-dialog-close>
        <mat-icon>close</mat-icon>
    </div>
</div>

<div class="row">
    <div class="col-md-6 mb-2">
        <label for="startdate" class="form-label">Start Date</label>
        <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="submitForm.startdate"
          required #startdate="ngModel" max="9999-12-31" step="1" (change)="startDateChange()">

        <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) ">
          <div *ngIf="startdate.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-2">
        <label for="starttime" class="form-label">Start Time</label>
        <div class="input-group">
          <input type="time" class="form-control form-control-sm" name="starttime" autofocus placeholder=""
            [(ngModel)]="submitForm.starttime" #starttime="ngModel"
             autocomplete="off">
             <button class="btn" type="button" (click)="startClear()"
                style="background-color: var(--main-color);color: white;">
                <i class="fa fa-times"></i>
              </button>
            </div>
      </div>

      <div class="col-md-6 mb-2">
        <label for="enddate" class="form-label">End Date</label>
        <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="submitForm.enddate"
          required #enddate="ngModel" [min]="submitForm.startdate" max="9999-12-31" step="1">

        <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
          <div *ngIf="enddate.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-2">
        <label for="enddate" class="form-label">End Time</label>
        <div class="input-group">
          <input type="time" class="form-control form-control-sm" name="endtime" autofocus placeholder=""
            [(ngModel)]="submitForm.endtime" #endtime="ngModel"
             autocomplete="off">
             <button class="btn" type="button" (click)="endClear()"
                style="background-color: var(--main-color);color: white;">
                <i class="fa fa-times"></i>
              </button>
            </div>
      </div>
</div>

<div class="actions-button">

    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()" [disabled]="saveLoading">
        Cancel
    </button>

    <app-button-loader [button_text]="'Save'" (click)="saveData()" [isStillupload]="saveLoading">
    </app-button-loader>

</div>