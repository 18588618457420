<div>
    <div class="d-flex flex-column w-100 justify-content-center align-items-center">
        <!-- <p class="info mb-3">{{ channelName }}</p> -->
        <div class="d-flex w-100 justify-content-center align-items-center">
            <span>Expires after</span>
            <select class="form-select mx-2" style="max-width: 80px;" name="monday" id="monday"
                [(ngModel)]="submitform.expday" (ngModelChange)="expDayChange()">
                <option *ngFor="let x of expDayList" [value]="x">
                    {{x}}
                </option>
            </select>
            <span>day(s).</span>
        </div>

        <div class="my-3">
            <span class="nodata" *ngIf="isLoading">
                <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
              </span>
              <div *ngIf="!isLoading">
                <ngx-qrcode [margin]="0" [width]="180" qrc-element-type="canvas" [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel" [value]="qrUrl" cssClass="bshadow"></ngx-qrcode>
              </div>
            
        </div>
        <!-- <button type="button" class="btn btn-link w-100" style="text-align: center;" (click)="downloadQRCode()">Save
            QR</button> -->
            <a class="saveqrtxt" style="text-align: center;" (click)="downloadQRCode()">Save
                QR</a>
        <!-- <div class="close-btn">
            <button type="button" class="btn btn-custom " mat-dialog-close>Close</button>
        </div> -->
    </div>
</div>