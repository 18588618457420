import { Component, OnInit, ViewChild } from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SEOService } from '../../shared/services/seo.service';


@Component({
  selector: 'app-my-channel',
  templateUrl: './my-channel.component.html',
  styleUrls: ['./my-channel.component.scss']
})
export class MyChannelComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  displayedColumns: any;
  gettingChannel: boolean = false;
  dbutton = false;
  channels: any[] = [];
  mychannels: any[] = [];
  show_mychl !: MatTableDataSource<any>;
  searchuserid: string = "";
  edit: string = "editmychannel";
  paginate: boolean = false;
  searchText: string = "";
  isFocus: boolean = false;
  datacount = 0;
  total_member = 0;
  total_course = 0;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    private _seoService: SEOService,
  ) {
    if (this.allinoneService.getUserRole() == '100' || this.allinoneService.getUserRole() == '1') {
      return;
    } else {
      this.router.navigateByUrl('');
    }
  }

  ngOnInit(): void {
    this._seoService.updateTitle('- My Channels');
    this.displayedColumns = ['image', 'name', 'description', 'member', 'course', 'action'];
    this.getChannel();
  }

  // @ViewChild(MatSort) sort?: MatSort;

  getChannel() {
    // window.location.reload();
    this.mychannels = []
    this.gettingChannel = true;
    this.show_mychl = new MatTableDataSource();
    this.searchuserid = this.allinoneService.getKunyekUserId();
    const data =
    {
      "searchuserid": this.searchuserid.toString()
    };
    console.log("daaa" + data);
    // this.courseService.getMychannel(data).subscribe(

    //   (channel_data: any) => {
    //     if (channel_data.returncode == '300') {
    //       this.channels = channel_data.datalist;
    //       let chl: any[] | undefined = [];
    //       this.datacount = (channel_data.datalist.length) - 1;
    //       for (let i = 0; i < this.channels.length; i++) {
    //         if (this.channels[i].channelid == "") {
    //           // chl.push(this.channels[i]);
    //         } else {
    //           chl.push(this.channels[i]);
    //         }
    //       }
    //       if (chl == undefined || chl.length == 0) {
    //         this.paginate = false;
    //       } else {
    //         this.paginate = true;
    //       }
    //       this.show_mychl = new MatTableDataSource(chl);
    //       this.show_mychl.sort = this.sort;
    //       this.show_mychl.paginator = this.paginator;
    //       this.mychannels = chl;
    //       // console.log(this.mychannels);
    //       this.gettingChannel = false;
    //     } else {
    //       this.gettingChannel = false;
    //       if (channel_data.returncode == '210') {
    //         setTimeout(() => {
    //           this.router.navigateByUrl('login');
    //           this.allinoneService.logout();
    //         }, 2000);
    //       } else {
    //         this.gettingChannel = false;
    //         this.messageService.openSnackBar(channel_data.status, 'warn');
    //       }
    //     }
    //   },
    //   (err) => {
    //     this.gettingChannel = false;
    //   }
    // );
  }

  editChannel(channel: any) {
    if (channel.status == "0") {
      return;
    } else {
      const id = channel.channelid;
      this.router.navigateByUrl(`/admin/channel/${this.edit}/${id}`)
    }
  }

  userAnalytics(channel: any){
    if (channel.status == "0") {
      return;
    } else {
      const id = channel.channelid;
      this.router.navigateByUrl(`/instructor/analytics/${channel.channelid}`);
    }
  }


  gotoMember(channel: any) {
    if (channel.status == "0") {
      return;
    } else {
      const id = channel.channelid;
      this.router.navigateByUrl(`/admin/channel/${this.edit}/${id}/member`);

    }
  }

  gotoDashboard(channel: any) {
    let name = channel.channelid;
    console.log(channel);
    let  data = "0";
    // localStorage.set("first", data);
    localStorage.setItem("first", data);
    this.router.navigateByUrl(`/instructor/courses?id=${name}`);
    
  }

  createCourse(channel: any) {
    const gotopage = "course-name";
    this.router.navigate(
    [`/instructor/courses/${gotopage}`] , 
    { queryParams: { 'channelid' : channel.channelid } }
    );
  }

  clear() {
    this.searchText = "";
    this.show_mychl.filter = "";
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.show_mychl.filter = searchItem.trim().toLowerCase();

  }
  refresh() {
    this.gettingChannel = true;
    this.channels = [];
    if (this.channels == undefined || this.channels.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.show_mychl = new MatTableDataSource();
    this.getChannel();

  }

}
