import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-checkurl',
  templateUrl: './checkurl.component.html',
  styleUrls: ['./checkurl.component.scss']
})

export class CheckurlComponent implements OnInit {
  user:any;
  isAdmin:any;

  constructor(
    private router: Router,
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
     private cookieService: CookieService
  ) { }

  async ngOnInit(): Promise<void> {
    this.cookieService.deleteAll('/');

    var res=await this.checkURL();

    this.router.navigateByUrl('dashborad');



    

  }

  checkUserId(userid: string) {
    const checkmail = this.validateMail(userid);
    if (!checkmail && !userid.includes('@')) {
      if (userid.startsWith('09')) {
        userid = '+959' + userid.slice(2, userid.length);
      } else if (userid.startsWith('+959')) {
        userid = userid;
      } else if (userid.startsWith('959')) {
        userid = '+959' + userid.slice(3, userid.length);
      } else {
        userid = '+959' + userid;
      }
    } else {
      userid = userid.toLowerCase();
    }

    return userid;
  }

  validateMail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  getUser() {
    this.courseService.getUser().subscribe(
      async (res: any) => {
        if (res.returncode == '300') {

          this.user = res.data;
          this.isAdmin = this.user.userrole == '100';
          this.allinOneService.setCookie('role', this.user.userrole);


        } else {
          this.messageService.openSnackBar(res.status, 'warn');
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
        }
      },
      (err) => {
      }
    );
  }


  async checkURL(){
    return new Promise(async (resolver)=>{
      let queryParams = new URLSearchParams(window.location.search);
      const appid = queryParams.get('appid') || '';
      let atoken = queryParams.get('atoken') || '';
      let userid = queryParams.get('userid') || '';
      userid=this.checkUserId(userid)

      let data={
        "userid":this.checkUserId(userid),
        "appid":appid,
        "atoken":atoken
      }
      this.courseService.exchangeToken(data).subscribe(async (res: any) => {

        if (res.returncode == '300') {

      

          // Success
          this.allinOneService.setCookie('atoken', res.atoken);
  
          this.allinOneService.setCookie("userid", res.usersyskey);

          this.allinOneService.setCookie("atoken", res.atoken);
      this.allinOneService.setCookie("kunyek_userid",userid);

  
          sessionStorage.setItem("session", res.session);
          sessionStorage.setItem("existsuser", res.type);
          sessionStorage.setItem("kunyekusername", res.username);
  
          this.getUser();
          resolver(true);

  
  
        }
        else {
          this.allinOneService.unauthorizedMsg = res.message;
          this.allinOneService.isApproved = false;
        resolver(true);

        }
      });

    })
    

  }
}
