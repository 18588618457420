<div class="d-flex justify-content-center align-items-center">
    <div>
        <!-- <p class="info mb-3">{{ channelName }}</p> -->
        <div class="mb-1">
        <ngx-qrcode [margin]="0" [width]="150" qrc-element-type="canvas" [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel" [value]="channelID" cssClass="bshadow"></ngx-qrcode>
        </div>
        <button type="button" class="btn btn-link w-100" style="text-align: center;"
            (click)="downloadQRCode()">Save
            QR</button>
        <!-- <div class="close-btn">
            <button type="button" class="btn btn-custom " mat-dialog-close>Close</button>
        </div> -->
    </div>
</div>