<div [ngClass]="{'content' : !ispopup}">
  <div class="library-breadcrumb">
    <ng-container *ngFor="let item of navBarList;let index = index">
      <ng-container *ngIf="index == navBarList.length - 1, else navTemplate">
        <span mat-button class="last-item">{{item.filename}}</span>
      </ng-container>
      <ng-template #navTemplate>
        <button mat-button class="custom-btn" (click)="navClick(index)">{{item.filename}}</button>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </ng-template>
    </ng-container>
    <!-- <button mat-button class="custom-btn">My Library</button>
        <mat-icon>keyboard_arrow_right</mat-icon>
        <span mat-button class="last-item">Folder Name</span> -->
  </div>
  <div class="d-flex justify-content-between align-items-center mb-2" *ngIf="!ispopup">
    <div class="me-auto">
      <button mat-flat-button class="ms-0 me-2" color="primary" (click)="newFolder(true)" *ngIf="!sharedWithMe">
        <mat-icon class="me-1 custom-mat-icon">add</mat-icon>New Folder
      </button>
      <button mat-flat-button class="me-2" color="primary" (click)="newFolder(false)" *ngIf="!sharedWithMe">
        <mat-icon class="me-1 custom-mat-icon">add</mat-icon>New File
      </button>
    </div>
    <div>
      <button mat-flat-button class="me-0" color="primary" (click)="libraryChange()">
        <img *ngIf="sharedWithMe"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAACEklEQVR4nO3cQW7bQAyFYU4Wyabet4fLFbJvegH3HD1bjK6D7tlFjUAInJZDzTyx0v+tBVJ6mHg4hmIzAAAAAACAg3H3e3f/7u4XX+fF3c/ufr/1M5V0DWek89bPpNYiF7n7xcy+DOz7s7U2sl550aB9eOPWQr334m7rGzgKghY5XNBeeYJaeUM3Dbu5/mfZZII63GboG01QmY+OZzN7aB3M7MHMviV6zTB6rPwcuSizok+ttV+9d+PuJzN7fWu83Yre5K8zs6KfvHMDuF7/lOi1G0f8jP5vVjQSCFqkXNC+7kDRe4ioNUGtm+dv+0uvEQeKDw8R7677lMzjFHmWpXIr2swehTVkE1S5qWNUr9n1I72WKq7oXSJoEYIWIWgRghapHnToQGG1voa9qfp4F/5K1oNfwzLe3RY6UGQPEUrVV3TZ+pFeS9VX9G4QtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQdCXv3in+mnnV1d2fI+8UZ3rNrp/t1U358na21+z6mV5L1f/9bfZ7HbyIXr1+pNcSm6EIQVei3KkzvWbXz/bqptyps71m18/0WmLqYOqoXT/Sa4nNUISgRQhahKBFCFokGvRlcN+Xyb1m14/2ehMN+seKG+mtN6LX7Ppz6l2PnWf/8wsva/zzF2JW9ppdv6sXAAAAAADAXv0GYwHCkNVqotQAAAAASUVORK5CYII="
          width="20px" class="me-2" style="color: white;" />

        <mat-icon class="me-1 custom-mat-icon" *ngIf="!sharedWithMe">people</mat-icon>{{sharedWithMe ? 'My Library' :
        'Shared With Me'}}
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center mb-2" *ngIf="ispopup">
    <div class="me-auto">
      <button mat-stroked-button (click)="cancelFileSelect()">
        Cancel
      </button>
      <button mat-flat-button (click)="confirmFileSelect()" class="ms-2" color="primary" [disabled]="!selectedRow">
        Save
      </button>
    </div>
    <div>
      <button mat-flat-button class="me-2" color="primary" (click)="libraryChange()">
        <img *ngIf="sharedWithMe"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAACEklEQVR4nO3cQW7bQAyFYU4Wyabet4fLFbJvegH3HD1bjK6D7tlFjUAInJZDzTyx0v+tBVJ6mHg4hmIzAAAAAACAg3H3e3f/7u4XX+fF3c/ufr/1M5V0DWek89bPpNYiF7n7xcy+DOz7s7U2sl550aB9eOPWQr334m7rGzgKghY5XNBeeYJaeUM3Dbu5/mfZZII63GboG01QmY+OZzN7aB3M7MHMviV6zTB6rPwcuSizok+ttV+9d+PuJzN7fWu83Yre5K8zs6KfvHMDuF7/lOi1G0f8jP5vVjQSCFqkXNC+7kDRe4ioNUGtm+dv+0uvEQeKDw8R7677lMzjFHmWpXIr2swehTVkE1S5qWNUr9n1I72WKq7oXSJoEYIWIWgRghapHnToQGG1voa9qfp4F/5K1oNfwzLe3RY6UGQPEUrVV3TZ+pFeS9VX9G4QtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQdCXv3in+mnnV1d2fI+8UZ3rNrp/t1U358na21+z6mV5L1f/9bfZ7HbyIXr1+pNcSm6EIQVei3KkzvWbXz/bqptyps71m18/0WmLqYOqoXT/Sa4nNUISgRQhahKBFCFokGvRlcN+Xyb1m14/2ehMN+seKG+mtN6LX7Ppz6l2PnWf/8wsva/zzF2JW9ppdv6sXAAAAAADAXv0GYwHCkNVqotQAAAAASUVORK5CYII="
          width="20px" class="me-2" style="color: white;" />

        <mat-icon class="me-1 custom-mat-icon" *ngIf="!sharedWithMe">people</mat-icon>{{sharedWithMe ? 'My Library' :
        'Shared With Me'}}
      </button>
    </div>
    <div>
      <div class="input-group mt-3 mb-3" style="width: 260px !important;">
        <input class="form-control" type="text" placeholder="Search by ID" (focus)="isFocus = true"
          (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchItem" [disabled]="afterSearch">
        <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
          style="border: 1px solid var(--main-color);color: var(--main-color);"
          (click)="!afterSearch ? search() : clearSearch()">
          <mat-spinner [strokeWidth]="2" [diameter]="20" *ngIf="isSearching"></mat-spinner>
          <i class="fas fa-search" *ngIf="!isSearching && !afterSearch"></i>
          <i class="fas fa-close" *ngIf="!isSearching && afterSearch"></i>
        </button>
      </div>
    </div>
  </div>
  <div style="overflow: auto">
    <table mat-table #table [dataSource]="datatoshow" (matSortChange)="sortData($event)" matSort
      class="mat-elevation-z1" style="width: 100%">
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef style="width:60px;"></th>
        <td mat-cell *matCellDef="let element">
          <img [src]="element.iconurl" width="20px">
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
          Name
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div class="d-flex align-items-center justify-content-start me-3">
            <span class="filename">
              {{ element.filename }}
            </span>
            <mat-icon *ngIf="element.sharetype == '1' && !sharedWithMe">people</mat-icon>

          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="fileid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">
          ID
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="width: 150px;">
          <span class="filename">
            {{ element.fileid }}
          </span>

        </td>
      </ng-container>

      <!-- File Type Column -->
      <ng-container matColumnDef="filetype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
          File Type
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); width: 100px;">{{ element.filetype }}
        </td>
      </ng-container>
      <!-- Owner Column -->
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by owner">
          Owner
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); width: 100px;">
          me
        </td>
      </ng-container>

      <!-- File Size Column -->
      <ng-container matColumnDef="filesize">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by file size">
          File Size
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); width: 150px;">
          <span *ngIf="element.filesize && element.filesize < 1024">
            {{element.filesize | number :'1.0-2'}} KB
          </span>
          <span *ngIf="element.filesize && element.filesize > 1024">
            {{element.filesize /1024 | number :'1.0-2'}} MB
          </span>
          <span *ngIf="element.filetype == 'folder'">
            -
          </span>
        </td>
      </ng-container>

      <!-- Modified Date -->
      <ng-container matColumnDef="modifieddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">
          Last Modified Date
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); width: 200px;">
          {{element.modifieddate}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [style.cursor]="row.filetype == 'folder' || (row.filetype == 'pdf' && ispopup && !isattachment && !isvideo) || (isattachment && !isvideo && ispopup) || ((videoFileType.includes(row.filetype) && isvideo && !isattachment && ispopup))? 'pointer' :'auto'"
        [style.background-color]="ispopup ? 
        (row.filename == selectedRow.filename ? 'lightblue' : 
        isattachment ? 'white' : (isvideo && videoFileType.includes(row.filetype)) ? 'white' :
        ((!isvideo && row.filetype == 'pdf') || row.filetype == 'folder') ? 'white' : 'rgb(233, 231, 231)') 
        : 'white'" [style.opacity]="ispopup ? 
        isattachment ? '1' :  (isvideo && videoFileType.includes(row.filetype))  ? '1' :
        (row.filetype == 'folder' ||(!isvideo && row.filetype == 'pdf')) ? '1' : '0.4' : '1'"
        (dblclick)="goToFolder($event, row)" (click)="selectRow($event, row)"
        (contextmenu)="onRightClick($event, row, i)">
      </tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [style.cursor]="row.filetype == 'folder' || (row.filetype == 'pdf' && ispopup && !isattachment && !isvideo && !isaudio) || (row.filetype == 'mp3' && ispopup && isaudio && !isattachment && !isvideo) || (isattachment && !isvideo && !isaudio && ispopup) || ((videoFileType.includes(row.filetype) && isvideo && !isattachment && !isaudio && ispopup))? 'pointer' :'auto'"
        [style.background-color]="ispopup ? 
        (row.filename == selectedRow.filename ? 'lightblue' : 
        isattachment ? 'white' : (isvideo && videoFileType.includes(row.filetype)) ? 'white' : 
        ((!isvideo && !isaudio && row.filetype == 'pdf') || row.filetype == 'folder') ? 'white' : ((!isvideo && isaudio && row.filetype == 'mp3') || row.filetype == 'folder') ? 'white' : 'rgb(233, 231, 231)') 
        : 'white'" 
        [style.opacity]="ispopup ? 
        isattachment ? '1' :  (isvideo && videoFileType.includes(row.filetype))  ? '1' : (row.filetype == 'folder' ||(!isvideo && isaudio && row.filetype == 'mp3')) ? '1' :
        (row.filetype == 'folder' ||(!isvideo && !isaudio && row.filetype == 'pdf')) ? '1' : '0.4' : '1'"
        (dblclick)="goToFolder($event, row)" (click)="selectRow($event, row)"
        (contextmenu)="onRightClick($event, row, i)">
      </tr>
      <ng-container *matNoDataRow>
        <ng-container *ngIf="isLoading, else emptyTemplate">
          <tr class="mat-row" *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
            <td>
              <ngx-skeleton-loader [theme]="{ height: '10px',width: '40px'}" style="margin-bottom: -10px">
              </ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
              </ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
              </ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
              </ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
              </ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
              </ngx-skeleton-loader>
            </td>
          </tr>
        </ng-container>
        <ng-template #emptyTemplate>
          <!-- <tr appDragDrop (onFileDropped)="uploadFile($event)"> -->
          <tr>
            <td colspan="7">
              <span class="nodata">
                Empty
              </span>
            </td>
          </tr>
        </ng-template>

      </ng-container>
    </table>
  </div>
</div>



<div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item" let-index="index">
    <button mat-menu-item (click)="viewInfo(item,index)" *ngIf="sharedWithMe">
      <mat-icon>information</mat-icon>
      <span>View Info</span>
    </button>
    <button mat-menu-item (click)="share(item,index)" *ngIf="!sharedWithMe">
      <mat-icon>share</mat-icon>
      <span>Share</span>
    </button>
    <button mat-menu-item (click)="edit(item,index)" *ngIf="!sharedWithMe">
      <mat-icon>edit</mat-icon>
      <span>Edit</span>
    </button>
    <ng-container *ngIf="item.filetype != 'folder'">
      <button mat-menu-item (click)="download(item)">
        <mat-icon>file_download</mat-icon>
        <span>Download</span>
      </button>
      <mat-divider *ngIf="!sharedWithMe"></mat-divider>
      <button mat-menu-item (click)="delete(item, index)" *ngIf="!sharedWithMe">
        <mat-icon color="warn">delete</mat-icon>
        <span color="warn">Delete</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>