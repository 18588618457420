import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() t_breadcrumb!: string;
  @Input() s_breadcrumb!: string;
  @Input() a_breadcrumb!: string;
  @Input() b_breadcrumb!: string;

  
  @Output('emit')
  breadcrump: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit1')
  breadcrump2: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit2')
  breadcrump3: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  goToMyCourses() {
    if (this.t_breadcrumb) {
      this.router.navigateByUrl('home/courses');
    }
  }

  emitfunction1() {
    this.breadcrump.emit()
  }
  emitfunction2() {
    this.breadcrump2.emit()
  }

  emitfunction3() {
    this.breadcrump3.emit()
  }

}
