import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AddcategoryComponent } from '../addcategory/addcategory.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { LoadingService } from '../../shared/services/loading.service';
@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.scss']
})
export class CategorylistComponent implements OnInit {


  displayedColumns: any;

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  gettingCategory: boolean = false;
  categorys: any[] = [];
  showcategory !: MatTableDataSource<any>;
  edit: string = "editchannel";
  paginate: boolean = false;
  searchText: string = "";
  role: string = "";
  isFocus: boolean = false;
  datacount: string = "0";
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    private _liveAnnouncer: LiveAnnouncer,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private loadingService: LoadingService,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
    console.log(this.role);
  }

  ngOnInit(): void {
    this.displayedColumns = ['categoryname', 'sortkey', 'action'];
    this.getChannels();
    this.getCategory();
  }
  arr: any = [];
  max: any = 0;
  getCategory() {
    this.categorys = [];
    this.gettingCategory = true;
    this.showcategory = new MatTableDataSource();
    // var fun = this.role == 'admin' ? this.courseService.channelGet(): this.courseService.getMychannel({type: '1'}) 
    const data = {
      type: '1',
      channelid: this.selectedChannel,
    }
    this.courseService.getCategoryMainList(data).subscribe(
      (category_data: any) => {
        if (category_data.returncode == '300') {
          const dataSource = new MatTableDataSource(category_data.datalist);
          this.categorys = category_data.datalist;
          this.datacount = category_data.datalist.length;
          for (let i = 0; i < this.categorys.length; i++) {
            this.arr.push(Number(this.categorys[i]['sortkey']));
          }

          if (this.arr.length > 0) {
            this.max = this.arr.reduce((a: number, b: number) => Math.max(a, b));
          }
          if (this.categorys == undefined || this.categorys.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }
          this.showcategory = new MatTableDataSource(category_data.datalist);
          this.showcategory.sort = this.sort;
          this.showcategory.paginator = this.paginator;
          this.gettingCategory = false;
        } else {
          this.gettingCategory = false;
          if (category_data.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.gettingCategory = false;
            this.messageService.openSnackBar(category_data.status || category_data.message, 'warn');
          }
        }
      },
      (err: any) => {
        this.gettingCategory = false;
      }
    );
  }

  editcategory(data: any) {
    const dialog = this.dialog.open(AddcategoryComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        "categoryList": this.categorys,
        "channelid": this.selectedChannel,
        "name": data.categoryname,
        "edit": true,
        "categoryid": data.categoryid,
        "order": data.sortkey
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        //Editing
        this.getCategory();
      }
    });

    console.log(data);
  }

  deleteCategory(data: any) {
    console.log(data)
    if (!data?.categoryid) {
      return;
    } else {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: "300px",
      });
 
      dialogRef.afterClosed().subscribe(result => {
        if (result?.confirmed == true) {
          this.courseService.deleteSubcategory(data?.categoryid, data?.categoryname, this.selectedChannel).subscribe(
            (res: any) => {
              console.log("delete category res >>", res)
              if (res.returncode == '300') {
                this.loadingService.hide();
              this.refresh()
              } else {
                this.loadingService.hide();
                this.messageService.openSnackBar(
                  res.message || res.status || 'Server Error',
                  'warn'
                );
              }
            }
          );          
        }
      })
    }
 
  }

  gotoMember(channelid: any) {
    this.router.navigateByUrl(`/${this.role}/category/${this.edit}/${channelid}/member`);
  }

  announceSortChange(sortState: Sort, data: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    console.log(data)
  }

  clear() {
    this.searchText = "";
    this.showcategory.filter = "";
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showcategory.filter = searchItem.trim().toLowerCase();

  }
  refresh() {
    this.gettingCategory = true;
    this.categorys = [];
    if (this.categorys == undefined || this.categorys.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showcategory = new MatTableDataSource();
    this.getCategory();

  }

  gettingChannels: boolean = false;
  channels: any = [];
  selectedChannel: string = '';

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'supervisor') {
      this.channels = this.allinoneService.supervisorChannels;
    } else {
      this.channels = this.allinoneService.adminChannels;
    }
    if (this.channels.length > 0) {
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.selectedChannel = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
    }

    console.log(this.selectedChannel);
    this.gettingChannels = false;
  }
  channelChange() {
    this.getCategory();
  }

  newcategory() {
    const dialog = this.dialog.open(AddcategoryComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        "categoryList": this.categorys,
        "channelid": this.selectedChannel,
        "name": "",
        "edit": false,
        "categoryid": "",
        "order": this.max + 1
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        //Editing
        this.getCategory();

      }
    });

  }

}
