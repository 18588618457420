<app-breadcrumb *ngIf="!isLoading && !isError " [s_breadcrumb]="course.coursename" [t_breadcrumb]="'Course Informatin Preview'" (emit)="goBackPreview()"></app-breadcrumb>




<ng-container *ngIf="!isLoading;else elseTemplate">
    <div *ngIf="!isError">

        <section class="billboard  p-3 pt-3   pt-lg-5 pt-sm-5    text-sm-start">
            <div class="container">
                <div class="alert alert-warning d-flex align-items-center" role="alert" *ngIf="isPreview && course.coursestatus == 0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                        <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                    <div>
                        This course is in draft mode
                    </div>
                </div>




                <div class="d-flex align-items-center justify-content-between billboard-wrapper">
                    <div class="w-50 w-sm-100 left-banner-side">
                        <h1 style="font-weight: 700;">{{course.coursename}}</h1>
                        <p class="fs-6 my-3">
                            {{course.coursesubname}}
                        </p>

                        <!-- <div class="container ">
                            <div class="item">Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, voluptatem. Molestiae minus, harum corporis magnam delectus provident dolores repellendus quibusdam, consequuntur facere enim voluptatum officiis eum maiores, doloribus ad rerum!</div>
                            <div class="item">2</div>
                          </div> -->

                        <div class="align-items-center container show-flex" style="max-width: 100%;">

                            <div class="channel d-flex my-2  me-3" style="max-width: 100%;min-width: 20%" *ngIf="course.channelid">

                                <div>
                                    <img width="40" height="40" class="rounded-circle chl-img" *ngIf="course.url" [src]="course.url" alt="">
                                    <!-- <img width="40" height="40" class="rounded-circle chl-img"  *ngIf="course.url == ''" [src]="def_img" alt="" > -->
                                    <div class="instructor-default-img" *ngIf="!course.url">
                                        KHub
                                    </div>
                                </div>
                                <span class="chl-name fw-bold" style="margin-left: 7px"> {{course.channelname}} </span>
                            </div>


                            <div class="instructor my-2 " style="max-width: 100%;" *ngIf="instructor">
                                <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                    {{instructor.username.charAt(0)}}
                                </div>
                                <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                                <span class="instructor-name fw-bold">{{instructor.username}}</span>
                            </div>
                        </div>
                        <div class="d-flex">
                            <app-button-loader [button_text]="'Enroll Now'" [isStillupload]="isStillUpload" (click)="enrollCourse()">
                            </app-button-loader>
                            <span class="viewcouse">View Course</span>
                            <!-- *ngIf="course.channelid == '' && course.access == '002'" *ngIf="course.channelid == '' && course.access == '002'" -->
                            <span>
                                <mat-icon
                                    matTooltip="This course has public access so you can learn even if you are not logged in but your progress will not be saved.">
                                    help</mat-icon>
                            </span>
                        </div>

                        <button [disabled]="isPreview" *ngIf="isPreview && course.coursestatus == 0" mat-flat-button color="primary" class="mt-2 ">
                            Enroll Now
                        </button>


                        <div class="pt-2 fs-6">
                            <b>{{course.studentcounts | number}}</b> students already enrolled
                        </div>

                    </div>
                    <div class="w-50 d-flex justify-content-end right-banner-side">
                        <img class="img-fluid course-banner-img  rounded" (error)="handleImgError($event)" [src]="allinOneService.imgurl + course.courseimage" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <section *ngIf="course.requirements != ''" lass="billboard  p-3 pt-3   pt-lg-3 pt-sm-3    text-sm-start">
            <div class="container">
                <div class="d-flex align-items-start billboard-wrapper">
                    <div class="left-banner-side w-50">
                        <h1 style="font-weight: 700;" class="mb-1">Requirements </h1>
                        <p class="fs-6 my-4" [innerHTML]="course.requirements"></p>
                    </div>
                </div>
            </div>
        </section>


        <section *ngIf="course.description != ''" class="billboard  p-3 pt-3   pt-lg-3 pt-sm-3    text-sm-start">
            <div class="container">
                <div class="d-flex align-items-start billboard-wrapper">
                    <div class="left-banner-side w-50">
                        <h1 style="font-weight: 700;" class="mb-1">Description</h1>
                        <div class="" [ngClass]="{'show-more-container':  course.description.length > 200}" [class.show]="show">
                            <p class="fs-6 my-4" [innerHTML]="course.description"></p>
                        </div>
                        <button *ngIf="course.description.length > 200" mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show">
                            <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                    aria-hidden="true"></i></span>
                            <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                    aria-hidden="true"></i></span>
                        </button>

                    </div>
                </div>
            </div>
        </section>


        <section class="section-crriculum p-3   text-sm-start">
            <div class="container">
                <div class="">
                    <div class="">
                        <h1 style="font-weight: 700;">Outline</h1>
                        <p class="fs-5 my-4">
                            Inside you'll learn
                        </p>
                    </div>

                    <div class=" custom-accordion  accordion">
                        <div class="accordion-item" *ngFor="let section of course.sections;let s = index;">
                            <h2 class="accordion-header" [id]="'panelsStayOpen-heading' + s">
                                <button class=" custom-accordion-button accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#panelsStayOpen-collapse' + s" aria-expanded="true" aria-controls="panelsStayOpen-headingOne">
                                    <!-- <strong class="mx-1 text-warning" > {{ s+ 1+ ' - '}}</strong> -->
                                    {{ section.sectionname}}

                                </button>
                            </h2>

                            <div [id]="'panelsStayOpen-collapse' + s" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                <div class="section-accordion-body">

                                    <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                                        <h2 class="accordion-header">
                                            <button class=" custom-accordion-button custom-sub-accordion-button subsectioname accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#panelsStayOpen-collapseSubsection' + s +'-' + ss" aria-expanded="true" aria-controls="panelsStayOpen-headingOne">
                                                <!-- <strong class="mx-1 text-warning" style="letter-spacing: 2px;">{{s+ 1 + '.' + (ss + 1 )+ ' - '}}</strong>  -->
                                                {{subsection.subsectionname}}

                                            </button>
                                        </h2>

                                        <div [id]="'panelsStayOpen-collapseSubsection' + s +'-' + ss" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSubsectionOne">
                                            <div class="accordion-body">
                                                <ul>
                                                    <li *ngFor="let unit of subsection.units;let su = index;">
                                                        <span *ngIf="unit.contenttype == 1">
                                                            <i class="fa fa-play-circle-o document"
                                                                aria-hidden="true"></i>
                                                            <span class="mx-2">{{unit.unitname ? unit.unitname : 'Video'}}</span>
                                                        </span>
                                                        <span *ngIf="unit.contenttype == 2">
                                                            <i class="fa fa-file-o document" aria-hidden="true"></i>
                                                            <span class="mx-2">{{unit.unitname ? unit.unitname : 'File'}}</span>
                                                        </span>
                                                        <div *ngIf="unit.contenttype == 3">
                                                            <i class="fa fa-file-text-o document" aria-hidden="true"></i>
                                                            <span class="mx-2">{{unit.unitname ? unit.unitname : 'Article'}}</span>
                                                        </div>
                                                        <div *ngIf="unit.contenttype == 4">
                                                            <i class="fa fa-link" aria-hidden="true"></i>
                                                            <span class="mx-2">{{unit.unitname ? unit.unitname : 'Url'}}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>



    </div>

    <div class="check-error" *ngIf="isError">
        <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
        <button type="button" (click)="reload()" class="btn btn-outline-secondary">
            Reload
        </button>
    </div>
</ng-container>


<!-- Loading Skeleton -->
<ng-template #elseTemplate>


    <div class="container p-3 pt-3   pt-lg-5 pt-sm-5">
        <div class="d-flex align-items-center justify-content-between billboard-wrapper">
            <div class="w-50 w-sm-100 left-banner-side">
                <ngx-skeleton-loader count="1"></ngx-skeleton-loader>

                <ngx-skeleton-loader [theme]="{ height: '20px',width: '80%'}">
                </ngx-skeleton-loader>

                <div class="d-flex align-items-center">

                    <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '60px',width: '60px'}">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader [theme]="{ height: '20px',width: '25%'}">
                    </ngx-skeleton-loader>
                </div>

                <ngx-skeleton-loader class="mx-2" [theme]="{ height: '40px',width: '30%'}">
                </ngx-skeleton-loader>
            </div>
            <div class="w-50 mx-5 d-flex justify-content-end right-banner-side">
                <ngx-skeleton-loader [theme]="{ height: '300px',width: '100%'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>

    <div class="container p-3 pt-3 pt-lg-5 pt-sm-5 d-flex flex-column">
        <ngx-skeleton-loader [theme]="{ height: '20px',width: '20%'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ height: '20px',width: '40%'}"></ngx-skeleton-loader>
    </div>
</ng-template>