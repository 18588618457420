import { CreateChannelComponent } from './channel/create-channel/create-channel.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ImageCropperModule } from 'ngx-image-cropper';
import { CourseNameComponent } from './instructor/course-name/course-name.component';
import { CourseUnitEditorComponent } from './instructor/course-unit-editor/course-unit-editor.component';
import { DashboardComponent } from './instructor/dashboard/dashboard.component';
import { CourseAddComponent } from './instructor/course-add/course-add.component';
import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.componet';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FilterPipe } from './filter.pipe';
import { InstructorFilterPipe } from './shared/filter/filter.pipe';
import { PlyrModule } from 'ngx-plyr';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { InstructorSignupComponent } from './instructor/instructor-signup/instructor-signup.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { TopBarComponent } from './instructor/top-bar/top-bar.component';
import { ButtonLoaderComponent } from './shared/components/button-loader/button-loader.component';
import { MessageService } from './shared/services/message.service';
import { AllInOneService } from './shared/services/all-in-one.service';
import { VerifyComponent } from './instructor/verify/verify.component';
import { LoginComponent } from './instructor/login/login.component';
import { ProfileComponent } from './profiles/profile/profile.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { ForgotPasswordComponent } from './instructor/forgot-password/forgot-password.component';
import { PasswordStrengthComponent } from './shared/components/password-strength/password-strength.component';
import { QrloginComponent } from './instructor/qrlogin/qrlogin.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ChannelComponent } from './channel/channel/channel.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { EditChannelComponent } from './channel/edit-channel/edit-channel.component';
import { AddmemberComponent } from './instructor/addmember/addmember.component';
import { MemberComponent } from './instructor/member/member.component';
import { MatSortModule } from '@angular/material/sort';
import { MyChannelComponent } from './instructor/my-channel/my-channel.component';
import { ChannelAnalyticsComponent } from './analytic/channel-analytics/channel-analytics.component';
import { CourseAnalyticsComponent } from './analytic/course-analytics/course-analytics.component';
import { DatePipe } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CourseContentPreviewComponent } from './instructor/course-content-preview/course-content-preview.component';
import { CoursePreviewComponent } from './instructor/course-preview/course-preview.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SafePipe } from './safe.pipe';
import { ApproveInstructorComponent } from './instructor/approve-instructor/approve-instructor.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ClassviewComponent } from './class/classview/classview.component';
import { CreateClassComponent } from './class/create-class/create-class.component';
import { StudentsComponent } from './class/students/students.component';
import { CreateStudentComponent } from './class/create-student/create-student.component';
import { DropboxSubmissionsComponent } from './class/dropbox-submissions/dropbox-submissions.component';
import { LibraryViewComponent } from './library/library-view/library-view.component';
import { LibraryNewFolderComponent } from './library/library-new-folder/library-new-folder.component';
import { DragDropDirective } from './shared/directives/dragdrop.directive';
import { LibraryConfirmBoxComponent } from './library/library-confirm-box/library-confirm-box.component';
import { DuplicateFilesComponent } from './library/duplicate-files/duplicate-files.component';
import { UploadComponent } from './library/upload/upload.component';
import { ShareLibraryComponent } from './library/share-library/share-library.component';
import { InfoLibraryComponent } from './library/info-library/info-library.component';
import { QuestionEditorComponent } from './quizz/question-editor/question-editor.component';
import { CheckurlComponent } from './instructor/checkurl/checkurl.component';
import { PaidStudentsComponent } from './class/paid-students/paid-students.component';
import { AddStudentClassComponent } from './class/add-student-class/add-student-class.component';
import { ProfileViewComponent } from './profiles/profile-view/profile-view.component';
import { ChangeProfileComponent } from './profiles/change-profile/change-profile.component';
import { UserAnalyticsComponent } from './instructor/user-analytics/user-analytics.component';
import { MatChipsModule } from '@angular/material/chips';
import { StudentsByCourseComponent } from './analytic/students-by-course/students-by-course.component';
import { CoursesByStudentComponent } from './analytic/courses-by-student/courses-by-student.component';
import { SingleStudentSingleCourseComponent } from './analytic/single-student-single-course/single-student-single-course.component';
import { TemplatesComponent } from './certificates/templates/templates.component';
import { SettingDashboardComponent } from './settings/setting-dashboard/setting-dashboard.component';
import { StudentslistComponent } from './instructor/studentslist/studentslist.component';
import { GradeAssignmentComponent } from './class/grade-assignment/grade-assignment.component';
import { TimeAgoPipe } from './shared/datetimeago/time-ago.pipe';
import { ViewLiveDiscussionComponent } from './discussion/view-live-discussion/view-live-discussion.component';
import { GradeBookComponent } from './instructor/grade-book/grade-book.component';
import { ChartsModule } from 'ng2-charts';
import { ReportByCategoryComponent } from './reporting/report-by-category/report-by-category.component';
import { CouresScheduleComponent } from './schedules/coures-schedule/coures-schedule.component';
import { AdminScheduleComponent } from './schedules/admin-schedule/admin-schedule.component';
import { UpdateScheduleComponent } from './schedules/update-schedule/update-schedule.component';
import { TemplatePreviewComponent } from './certificates/template-preview/template-preview.component';
import { ReportFilterComponent } from './reporting/report-filter/report-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClassDetailsComponent } from './reporting/class-details/class-details.component';
import { ClassStudentDetailsComponent } from './reporting/class-student-details/class-student-details.component';
import { PrerequisitesComponent } from './settings/prerequisites/prerequisites.component';
import { UpdatePrerequisitesComponent } from './settings/update-prerequisites/update-prerequisites.component';
import { FeedbackTemplateComponent } from './feedback/feedback-template/feedback-template.component';
import { FeedbackDataComponent } from './feedback/feedback-data/feedback-data.component';
import { SubCategoryComponent } from './category/sub-category/sub-category.component';
import { CreateSubCategoryComponent } from './category/create-sub-category/create-sub-category.component';
import { UpdateGradeResultComponent } from './instructor/update-grade-result/update-grade-result.component';
import { OverallFeedbackComponent } from './feedback/overall-feedback/overall-feedback.component';
import { SetupCmsComponent } from './channel/setup-cms/setup-cms.component';
import { QuillModule } from 'ngx-quill';
import { InstructorReportDashboardComponent } from './instructor/instructor-report-dashboard/instructor-report-dashboard.component';
import { Spinner, VimeModule } from '@vime/angular';
import { TemplateViewComponent } from './feedback/template-view/template-view.component';
import { TemplateSetupComponent } from './feedback/template-setup/template-setup.component';
import { TemplateQuestionsViewComponent } from './feedback/template-questions-view/template-questions-view.component';
import { TemplateQuestionsSetupComponent } from './feedback/template-questions-setup/template-questions-setup.component';
import { FeedbackCourseViewComponent } from './feedback/feedback-course-view/feedback-course-view.component';
import { FeedbackTextareaDataComponent } from './feedback/feedback-textarea-data/feedback-textarea-data.component';
import { FeedbackByStudentComponent } from './feedback/feedback-by-student/feedback-by-student.component';
import { CustomSchedulesComponent } from './schedules/custom-schedules/custom-schedules.component';
import { CustomScheduleSetupComponent } from './schedules/custom-schedule-setup/custom-schedule-setup.component';
import { ImagecropComponent } from './channel/imagecrop/imagecrop.component';
import { AddcategoryComponent } from './category/addcategory/addcategory.component';
import { CategorylistComponent } from './category/categorylist/categorylist.component';
import { CanDeactivateFormsGuard } from './shared/guards/can-deactivate-forms.guard';
import { MembertypeComponent } from './member/membertype/membertype.component';
import { AddmembertypeComponent } from './member/addmembertype/addmembertype.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { ButtonLoadTextComponent } from './shared/components/button-load-text/button-load-text.component';
import { ImportQuestionsComponent } from './instructor/import-questions/import-questions.component';
import { GradeBookTotalDetailsComponent } from './instructor/grade-book-total-details/grade-book-total-details.component';
import { ChannelQrViewComponent } from './channel/channel-qr-view/channel-qr-view.component';
import { ClassviewQrComponent } from './class/classview-qr/classview-qr.component';
import { AssignmentsViewComponent } from './assignments/assignments-view/assignments-view.component';
import { AssignmentsEditComponent } from './assignments/assignments-edit/assignments-edit.component';
import { IndividualDeadlinesComponent } from './assignments/individual-deadlines/individual-deadlines.component';
import { PushNotiComponent } from './class/push-noti/push-noti.component';
import { NotiAddFormComponent } from './class/noti-add-form/noti-add-form.component';
import { VideoViewComponent } from './videos/video-view/video-view.component';
import { VideoDetailsComponent } from './videos/video-details/video-details.component';
import { CourseDuplicateEditorComponent } from './instructor/course-duplicate-editor/course-duplicate-editor.component';
import { ClassSectionScheduleComponent } from './class/class-section-schedule/class-section-schedule.component';
import { LearningTrackComponent } from './instructor/learning-track/learning-track.component';
import { LearningTrackCourseAddComponent } from './instructor/learning-track-course-add/learning-track-course-add.component';
import { LearningTrackUserAddComponent } from './instructor/learning-track-user-add/learning-track-user-add.component';
import { GradeBookResetQuizComponent } from './instructor/grade-book-reset-quiz/grade-book-reset-quiz.component';
import { FileUploadTwoComponent } from './shared/components/file-upload-two/file-upload-two.component';
import { CourseContentDuplicateEditorComponent } from './instructor/course-content-duplicate-editor/course-content-duplicate-editor.component';
import { ReportByCategoryDetailsComponent } from './reporting/report-by-category-details/report-by-category-details.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { LearningModule } from 'projects/e-learning/src/app/shared/modules/learning/learning.module';
import { CourseLearnComponent } from 'projects/e-learning/src/app/students/course-learn/course-learn.component';
import { GradesComponent } from 'projects/e-learning/src/app/custom-page/grades/grades.component';
import { FeedbackComponent } from 'projects/e-learning/src/app/custom-page/feedback/feedback.component';
import { CertificateComponent } from 'projects/e-learning/src/app/custom-page/certificate/certificate.component';
import { CourseLearnPageviewComponent } from 'projects/e-learning/src/app/students/course-learn-pageview/course-learn-pageview.component';
import { QuestionComponent } from 'projects/e-learning/src/app/quiz/question/question.component';
import { DropboxComponent } from 'projects/e-learning/src/app/class/dropbox/dropbox.component';
import { GetVideoIdPipe } from 'projects/e-learning/src/app/shared/get-video-id/get-video-id.pipe';
import { SwipeAngularListModule } from 'swipe-angular-list';
import { SubmissionDetailsComponent } from 'projects/e-learning/src/app/class/submission-details/submission-details.component';
import { PdfViewerComponent } from 'projects/e-learning/src/app/shared/components/pdf-viewer/pdf-viewer.component';
import { SpinnerOverlayComponent as SpinnerOverlayComponent2 } from 'projects/e-learning/src/app/shared/components/spinner-overlay/spinner-overlay.component';
import { AssignmentComponent } from 'projects/e-learning/src/app/class/assignment/assignment.component';
import { QuizPdfComponent } from './instructor/quiz-pdf/quiz-pdf.component';
import { FilterGradeComponent } from './instructor/grade-book/filter-grade/filter-grade.component';
import { CreateUserComponent } from './create-user/create-user.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CourseAddComponent,
    CourseNameComponent,
    CourseUnitEditorComponent,
    ButtonLoaderComponent,
    ConfirmDialogComponent,
    SpinnerOverlayComponent,
    FilterPipe,
    InstructorSignupComponent,
    TopBarComponent,
    VerifyComponent,
    LoginComponent,
    ProfileComponent,
    BreadcrumbComponent,
    ForgotPasswordComponent,
    PasswordStrengthComponent,
    QrloginComponent,
    ChannelComponent,
    CreateChannelComponent,
    EditChannelComponent,
    AddmemberComponent,
    MemberComponent,
    MyChannelComponent,
    ChannelAnalyticsComponent,
    CourseAnalyticsComponent,
    CourseContentPreviewComponent,
    CoursePreviewComponent,
    SafePipe,
    ApproveInstructorComponent,
    UnauthorizedComponent,
    ClassviewComponent,
    CreateClassComponent,
    StudentsComponent,
    CreateStudentComponent,
    DropboxSubmissionsComponent,
    LibraryViewComponent,
    LibraryNewFolderComponent,
    DragDropDirective,
    LibraryConfirmBoxComponent,
    DuplicateFilesComponent,
    UploadComponent,
    ShareLibraryComponent,
    InfoLibraryComponent,
    QuestionEditorComponent,
    CheckurlComponent,
    PaidStudentsComponent,
    AddStudentClassComponent,
    ProfileViewComponent,
    ChangeProfileComponent,
    UserAnalyticsComponent,
    StudentsByCourseComponent,
    CoursesByStudentComponent,
    SingleStudentSingleCourseComponent,
    TemplatesComponent,
    SettingDashboardComponent,
    StudentslistComponent,
    GradeAssignmentComponent,
    TimeAgoPipe,
    ViewLiveDiscussionComponent,
    GradeBookComponent,
    ReportByCategoryComponent,
    CouresScheduleComponent,
    AdminScheduleComponent,
    UpdateScheduleComponent,
    TemplatePreviewComponent,
    ReportFilterComponent,
    ClassDetailsComponent,
    ClassStudentDetailsComponent,
    PrerequisitesComponent,
    UpdatePrerequisitesComponent,
    FeedbackTemplateComponent,
    FeedbackDataComponent,
    SubCategoryComponent,
    CreateSubCategoryComponent,
    UpdateGradeResultComponent,
    OverallFeedbackComponent,
    SetupCmsComponent,
    InstructorReportDashboardComponent,
    TemplateViewComponent,
    TemplateSetupComponent,
    TemplateQuestionsViewComponent,
    TemplateQuestionsSetupComponent,
    FeedbackCourseViewComponent,
    FeedbackTextareaDataComponent,
    FeedbackByStudentComponent,
    CustomSchedulesComponent,
    CustomScheduleSetupComponent,
    ImagecropComponent,
    AddcategoryComponent,
    CategorylistComponent,
    MembertypeComponent,
    AddmembertypeComponent,
    FileUploadComponent,
    ButtonLoadTextComponent,
    ImportQuestionsComponent,
    GradeBookTotalDetailsComponent,
    ChannelQrViewComponent,
    ClassviewQrComponent,
    AssignmentsViewComponent,
    AssignmentsEditComponent,
    IndividualDeadlinesComponent,
    PushNotiComponent,
    NotiAddFormComponent,
    VideoViewComponent,
    VideoDetailsComponent,
    CourseDuplicateEditorComponent,
    InstructorFilterPipe,
    ClassSectionScheduleComponent,
    LearningTrackComponent,
    LearningTrackCourseAddComponent,
    LearningTrackUserAddComponent,
    GradeBookResetQuizComponent,
    FileUploadTwoComponent,
    CourseContentDuplicateEditorComponent,
    ReportByCategoryDetailsComponent,
    CourseLearnComponent,
    GradesComponent,
    FeedbackComponent,
    CertificateComponent,
    CourseLearnPageviewComponent,
    QuestionComponent,
    BreadcrumbComponent,
    DropboxComponent,
    GetVideoIdPipe,
    SubmissionDetailsComponent,
    PdfViewerComponent,
    SpinnerOverlayComponent2,
    AssignmentComponent,
    QuizPdfComponent,
    FilterGradeComponent,
    CreateUserComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    DragDropModule,
    MatExpansionModule,
    MatRippleModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    MatTooltipModule,
    MatTableModule,
    MatProgressBarModule,
    ImageCropperModule,
    MatSnackBarModule,
    MatMenuModule,
    OverlayModule,
    AngularEditorModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    NgxDocViewerModule,
    PlyrModule,
    NgxJsonViewerModule,
    NgOtpInputModule,
    NgxQRCodeModule,
    PerfectScrollbarModule,
    NgxExtendedPdfViewerModule,
    MatSortModule,
    ChartsModule,
    VimeModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    LazyLoadImageModule,
    MatChipsModule,
    NgSelectModule,
    QuillModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SwipeAngularListModule,
    MatCheckboxModule,
    // LearningModule, // If you want to include LearningModule, make sure it doesn't have the same imports again
  ],

  providers: [
    MessageService,
    AllInOneService,
    DatePipe,
    CanDeactivateFormsGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
