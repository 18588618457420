import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { CustomScheduleSetupComponent } from '../custom-schedule-setup/custom-schedule-setup.component';
import { UpdateScheduleComponent } from '../update-schedule/update-schedule.component';

@Component({
  selector: 'app-custom-schedules',
  templateUrl: './custom-schedules.component.html',
  styleUrls: ['./custom-schedules.component.scss'],
})
export class CustomSchedulesComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  courses: any[] = [];
  allCourses: any[] = [];
  channels: any[] = [];
  displayedColumns: string[] = [
    'image',
    'name',
    'status',
    'startdate',
    'enddate',
    'action',
  ];

  gettingCourses: boolean = false;
  gettingAllCourses: boolean = false;
  gettingmyChannel: boolean = false;
  isFocus: boolean = false;
  gettingChannels: boolean = false;

  searchText: string = '';
  selectedChannel: string = '';
  role: string = 'supervisor'; // supervisor , chladmin

  coursesTableData!: MatTableDataSource<any>;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }
  }

  ngOnInit(): void {
    this.getChannels();
    if (this.channels.length > 0) {
      this.getCourses();
      this.getAllCourses();
    }
  }

  clear() {
    this.coursesTableData.filter = '';
    this.searchText = '';
  }

  channelChange(){
    this.getCourses();
    this.getAllCourses();
  }

  sortCategoryData(sort: Sort) {
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(
            a.coursename,
            b.coursename,
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate, b.enddate, isAsc);
        default:
          return 0;
      }
    });
    this.coursesTableData = new MatTableDataSource(this.courses);
  }

  getCourses() {
    this.gettingCourses = true;
    this.allinoneService.selectedChannelid = this.selectedChannel;
    this.courses = [];
    this.coursesTableData = new MatTableDataSource(this.courses);
    this.courseService.getCustomSchedule(this.selectedChannel).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.courses = res.datalist;
          this.courses = this.courses.map((x: any) => ({
            ...x,
            startdatetoshow: this.allinoneService.formatDBToShow(x.startdate),
            enddatetoshow: this.allinoneService.formatDBToShow(x.enddate),
            statustoshow: x.status == '002' ? 'Ongoing' : 'Upcoming',
          }));
          setTimeout(() => {
            this.coursesTableData = new MatTableDataSource(this.courses);
            this.coursesTableData.paginator = this.paginator;
          }, 100);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCourses = false;
      },
      (err) => {
        this.gettingCourses = false;
      }
    );
  }

  getAllCourses() {
    this.gettingAllCourses = true;
    this.courseService
      .getCoursesByChannel(this.selectedChannel, '')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allCourses = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingAllCourses = false;
        },
        (err) => {
          this.gettingAllCourses = false;
        }
      );
  }

  goToSetting() {
    this.router.navigateByUrl('admin/settings');
  }

  searchItem(event: any) {
    const searchItem = event.target.value;
    this.coursesTableData.filter = searchItem.trim().toLowerCase();
  }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'supervisor') {
      this.channels = this.allinoneService.supervisorChannels;
    } else {
      this.channels = this.allinoneService.adminChannels;
    }
    if (this.channels.length > 0) {
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.selectedChannel = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
    }
    this.gettingChannels = false;
  }

  openScheduleSetup(course?: any) {
    var availableCourses = this.allCourses.filter(({ courseid: c }) => !this.courses.some(({ courseid: s }) => c === s));
    if(course && course.courseid != ''){
      var temp = this.allCourses.find((x: any) => x.courseid == course.courseid)
      availableCourses.push(temp);
    }
    const dialog = this.dialog.open(CustomScheduleSetupComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        course: course,
        isEditing: course ? true : false,
        channelid: this.selectedChannel,
        allCourses: availableCourses
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        //Editing
        if (course) {
          course.name = dialogResult.name;
          course.image = dialogResult.image;
          course.imageurl = dialogResult.imageurl;
          course.description = dialogResult.description;
          course.startdate = dialogResult.startdate;
          course.enddate = dialogResult.enddate;
          course.status = dialogResult.status;
          course.startdatetoshow = this.allinoneService.formatDBToShow(
            course.startdate
          );
          course.enddatetoshow = this.allinoneService.formatDBToShow(
            course.enddate
          );
          course.statustoshow = course.status == '002' ? 'Ongoing' : 'Upcoming';
          course.courseid = dialogResult.courseid;
          course.courseimage = dialogResult.courseimage;
        }
        // New
        else {
          dialogResult.startdatetoshow = this.allinoneService.formatDBToShow(
            dialogResult.startdate
          );
          dialogResult.enddatetoshow = this.allinoneService.formatDBToShow(
            dialogResult.enddate
          );
          dialogResult.statustoshow =
            dialogResult.status == '002' ? 'Ongoing' : 'Upcoming';
          this.courses.push(dialogResult);
          setTimeout(() => {
            this.coursesTableData = new MatTableDataSource(this.courses);
            this.coursesTableData.paginator = this.paginator;
          }, 100);
        }
      }
    });
  }

  deleteSchedule(schedule: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .deleteCustomSchedule(this.selectedChannel, schedule.scheduleid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                var index = this.courses.indexOf(
                  (x: any) => x.scheduleid == schedule.scheduleid
                );
                this.courses.splice(index, 1);
                setTimeout(() => {
                  this.coursesTableData = new MatTableDataSource(this.courses);
                  this.coursesTableData.paginator = this.paginator;
                }, 100);
                this.loadingService.hide();
              } else {
                this.loadingService.hide();
              }
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }
}
