<div>
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="me-auto">
      <h2 class="mb-0"><strong>Import Questions</strong></h2>
    </div>
    <div>

      <div>
        <select class="form-select" [(ngModel)]="questiontype" (change)="questionTypeChange()">
          <option *ngFor="let option of quesitontypes" [selected]="option.id == questiontype" [value]="option.id">
            {{option.name}}
          </option>
        </select>
      </div>
    </div>

  </div>



  <div class="mb-2">
    <div style="position: relative;">
      <div class="expand-icon" (click)="resize()">
        <i *ngIf="!isExpand" class='fas fa-expand-alt'></i>
        <i *ngIf="isExpand" class='fas fa-compress-alt'></i>

      </div>
      <angular-editor [(ngModel)]="question" [config]="config" placeholder=" "></angular-editor>
    </div>
  </div>
  <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0 && checkError == true">
    <div class="d-flex align-items-center">
      <mat-icon class="me-2">
        error
      </mat-icon>
      <div>
        Error
      </div>
    </div>
    <hr>
    <ul style="padding-left: 1rem;">
      <li *ngFor="let e of errorList;">
        {{e}}
      </li>
    </ul>
  </div>
  <div class="d-flex">

    <div class="mb-2 me-auto mt-3" style="color: #3391c3;text-decoration: underline;cursor: pointer;" (click)="downloadSampleFile()">
      Download Sample Template
    </div>
    <div class="actions-button">
      <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()">
        Cancel
      </button>

      <app-button-loader [button_text]="'Save'" [isStillupload]="saveLoading" (click)="uploadfile()">
      </app-button-loader>
    </div>

  </div>
</div>