import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Library } from '../../shared/models/Library';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-info-library',
  templateUrl: './info-library.component.html',
  styleUrls: ['./info-library.component.scss']
})
export class InfoLibraryComponent implements OnInit {

  datalist :any = [];
  isLoading:boolean = false;
  id:string = "";
  constructor(
    public dialogRef: MatDialogRef<InfoLibraryComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if (data) {
      console.log(data);
      this.id = data.datalist.id;


    }
  }

  ngOnInit(): void {
    this.getViewInfor();
  }

  cancel() {
    this.dialogRef.close({ success: false, data: {} });
  }

  getViewInfor(){
    this.isLoading = true;
    this.courseService.getShareInfo(this.id).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.datalist = res;
          
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.messageService.openSnackBar(
            res.message || res.error || res.status,
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
    // setTimeout(() => {
    //   
    // }, 1000);
  }

}
