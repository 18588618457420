<div class="img-cropper" *ngIf="!coppedImage">
  <div class="mb-4">
    <label for="aspectRatio">Select Ratio:</label>
    <select id="aspectRatio" class="ratio" [(ngModel)]="selectedAspectRatio" (change)="onAspectRatioChange()">
      <option *ngFor="let ratio of aspectRatios" [value]="ratio.value">{{ ratio.label }}</option>
    </select>
  </div>
  
  <ng-container *ngIf="selectedAspectRatio !== 0">
    <image-cropper class="cropper mb-2" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio"
        *ngIf="maintainAspectRatio; else freeformCropper" [aspectRatio]="aspectRatio" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()">
    </image-cropper>
    <ng-template #freeformCropper>
      <image-cropper class="cropper mb-2" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
          format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </ng-template>
  </ng-container>

  <button mat-flat-button color="primary" class="text-end" (click)="copyImage()">Crop Image</button>
</div>

<ng-container *ngIf="coppedImage">
  <div style="position: relative">
    <img [src]="croppedImage" class="banner-img mt-2" width="310" height="175" />
    <span class="remove-img-icon" matTooltip="Remove Course Image">
      <i class="fa fa-minus-circle" (click)="removeImage()"></i>
    </span>
  </div>
</ng-container>
