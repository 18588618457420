<app-breadcrumb s_breadcrumb="Analytics" [t_breadcrumb]="username" [a_breadcrumb]="channelname"
  [b_breadcrumb]="coursename" (emit)="goToAnalytics()" (emit1)="goToChannel()" (emit2)="goToCourse()"
  *ngIf="isFromCourse">
</app-breadcrumb>

<app-breadcrumb s_breadcrumb="Analytics" [t_breadcrumb]="coursename" [a_breadcrumb]="channelname"
  [b_breadcrumb]="username" (emit)="goToAnalytics()" (emit1)="goToChannel()" (emit2)="goToUser()" *ngIf="!isFromCourse">
</app-breadcrumb>

<div class="content">
  <div class = "d-flex justify-content-end my-3" style="font-weight: bold;">
    Total :&nbsp;{{totaltime}}
  </div>
  <ng-container *ngIf="course && !isLoading">
    <div class=" custom-accordion  accordion">
      <div class="accordion-item" *ngFor="let section of course.sections;let s = index;">
        <h2 class="accordion-header" [id]="'panelsStayOpen-heading' + s">
          <button class=" custom-accordion-button accordion-button collapsed" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#panelsStayOpen-collapse' + s" aria-expanded="true"
            aria-controls="panelsStayOpen-headingOne">
            <!-- <strong class="mx-1 text-warning" > {{ s+ 1+ ' - '}}</strong> -->
            <div class = "d-flex align-item-center justify-content-between w-100 me-3">
              <div>
                {{ section.sectionname}}
              </div>
              <div>
                {{ section.totaltime  ? section.totaltime : '0 hr(s) 0 min(s)'}}

              </div>
            </div>

          </button>
        </h2>

        <div [id]="'panelsStayOpen-collapse' + s" class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingOne">
          <div class="section-accordion-body">

            <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
              <h2 class="accordion-header"
                *ngIf="(subsection.units.length > 1 && subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                <button
                  class=" custom-accordion-button custom-sub-accordion-button subsectioname accordion-button collapsed"
                  type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#panelsStayOpen-collapseSubsection' + s +'-' + ss" aria-expanded="true"
                  aria-controls="panelsStayOpen-headingOne">
                  <!-- <strong class="mx-1 text-warning" style="letter-spacing: 2px;">{{s+ 1 + '.' + (ss + 1 )+ ' - '}}</strong>  -->
                  <div class = "d-flex align-item-center justify-content-between w-100 me-3">
                    <div>
                      {{subsection.subsectionname}}
                    </div>
                    <div>
                      {{subsection.totaltime ? subsection.totaltime : '0 hr(s) 0 min(s)'}}
                    </div>
                  </div>

                </button>
              </h2>

              <div class="accordion-header"
                *ngIf="(subsection.units.length > 1 && subsection.subsectionname == '') || (subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                <ul>
                  <li *ngFor="let unit of subsection.units;let su = index;" class="d-flex align-items-center justify-content-between">
                    <div *ngIf="unit.contenttype == 1">
                      <i class="fa fa-play-circle-o document" aria-hidden="true"></i>
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Video'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 2">
                      <i class="fa fa-file-o document" aria-hidden="true"></i>
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'File'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 3">
                      <i class="fa fa-file-text-o document" aria-hidden="true"></i>
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Article'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 4">
                      <i class="fa fa-link" aria-hidden="true"></i>
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Url'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 5">
                      <i class="fas fa-paperclip" aria-hidden="true"></i>
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Attachment'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 6">
                      <i class="fas fa-clipboard-list" aria-hidden="true"></i>
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Quizz'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 7">
                      <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Assignment'}}</span>
                    </div>
                    <div *ngIf="unit.contenttype == 8">
                      <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                      <span class="mx-2">{{unit.unitname ? unit.unitname : 'Discussion'}}</span>
                    </div>
                    <div style="margin-right: 45px;">
                      {{unit.totaltime ? unit.totaltime : '0 hr(s) 0 min(s)'}}
                    </div>
                  </li>
                </ul>
              </div>

              <div [id]="'panelsStayOpen-collapseSubsection' + s +'-' + ss" class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingSubsectionOne">
                <div class="accordion-body">
                  <ul>
                    <li *ngFor="let unit of subsection.units;let su = index;" class = "d-flex justify-content-between align-items-center">
                      <div *ngIf="unit.contenttype == 1">
                        <i class="fa fa-play-circle-o document" aria-hidden="true"></i>
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Video'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 2">
                        <i class="fa fa-file-o document" aria-hidden="true"></i>
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'File'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 3">
                        <i class="fa fa-file-text-o document" aria-hidden="true"></i>
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Article'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 4">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Url'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 5">
                        <i class="fas fa-paperclip" aria-hidden="true"></i>
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Attachment'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 6">
                        <i class="fas fa-clipboard-list" aria-hidden="true"></i>
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Quiz'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 7">
                        <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Assignment'}}</span>
                      </div>
                      <div *ngIf="unit.contenttype == 8">
                        <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                        <span class="mx-2">{{unit.unitname ? unit.unitname : 'Discussion'}}</span>
                      </div>
                      <div style="margin-right: 45px;">
                        {{unit.totaltime ? unit.totaltime : '0 hr(s) 0 min(s)'}}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div class = "nodata">
      <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
    </div>
  </ng-container>
</div>