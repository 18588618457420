<h2>
  {{isEditing? 'Edit Template' : 'New Template'}}
</h2>

<div class="form-group mb-3">
  <label class="form-label" for="name">Name</label>
  <input type="text" class="form-control" name="name" autofocus placeholder="General Feedback"
    [(ngModel)]="templateForm.name" #name="ngModel" required
    [ngClass]="{ 'is-invalid': (formSubmitted && name.invalid ) }" autocomplete="off" (keyup.enter)="save()">
  <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
    <div *ngIf="name.errors?.required">
      {{allinoneService.input_err_msg}}
    </div>
  </div>
</div>

<div class="form-group mb-3">
  <label class="form-label" for="description">Description</label>
  <textarea type="text" class="form-control" name="description" [(ngModel)]="templateForm.description"
    autocomplete="off" rows="5">
    </textarea>
</div>

<div class="actions-button">
  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()" [disabled]="isLoading">
    Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="save()">
  </app-button-loader>

</div>