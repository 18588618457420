import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { variable } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-addmembertype',
  templateUrl: './addmembertype.component.html',
  styleUrls: ['./addmembertype.component.scss']
})
export class AddmembertypeComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  tdyDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd')?.toString();
  allCourses: any[] = [];

  membertype = {
    channelid: "",
    name: '',
    description:'',
    memberid: '',
  };

  statusList = [
    {
      name: 'Ongoing',
      id: '002',
    },
    {
      name: 'Upcoming',
      id: '003',
    },
  ];


  imageChangedEvent: any = '';
  croppedImage: boolean = false;

  isEditing: boolean = false;
  isLoading: boolean = false;
  formSubmitted: boolean = false;
  gettingAllCourses: boolean = false;
  isCourseSelected: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddmembertypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data.edit == true) {

      this.isEditing = true;
      this.membertype = {
        channelid: data.channelid,
        name: data.name,
        memberid: data.memberid,
        description:data.description,
      };
      console.log("1111111");

    } else {
      this.isEditing = false;
      this.membertype.channelid = data.channelid;
      
      console.log("22222222222");
    }

  }

  ngOnInit(): void {
    if (this.isEditing) {

    }
    console.log(this.membertype);
  }

  dismiss() {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }




  async submit() {
    this.formSubmitted = true;
    if (
      this.membertype.name.toString().trim() == ''
    ) {
      return;
    }

   
    this.isLoading = true;
    var data = {
      name: this.membertype.name,
      channelid: this.membertype.channelid,
      description:this.membertype.description,
      
    };

    // 
    if(this.isEditing){
      data = Object.assign(data,
        {
          id:this.membertype.memberid,
  
        }
      );
    }

    var fun = this.isEditing
      ? this.courseService.updatemembertype(data)
      : this.courseService.addmembertype(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isLoading = false;
          this.formSubmitted = false;
          this.dialogRef.disableClose = false;
          var schedule = {
            channelid: this.membertype.channelid,
            name: this.membertype.name,
          };
          this.dialogRef.close(schedule);
        } else {
          this.isLoading = false;
          this.formSubmitted = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
        this.formSubmitted = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  showList() {
    this.autocomplete.openPanel();
  }

}
