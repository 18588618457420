import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';
import { UpdatePrerequisitesComponent } from '../update-prerequisites/update-prerequisites.component';

@Component({
  selector: 'app-prerequisites',
  templateUrl: './prerequisites.component.html',
  styleUrls: ['./prerequisites.component.scss'],
})
export class PrerequisitesComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  role: string = '';

  gettingChannels: boolean = false;

  channels: any[] = [];
  courses: any[] = [];

  channelid: string = '';

  coursesTableData!: MatTableDataSource<any>;

  displayedColumns: string[] = ['image', 'coursename', 'prerequisite'];

  gettingCourses: boolean = false;
  gettingmyChannel: boolean = false;
  isFocus: boolean = false;

  searchText: string = '';

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _SEOService: SEOService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }
    this._SEOService.updateTitle('- Course Prerequites');
  }

  ngOnInit(): void {
    this.getChannels();
    this.getCourses();
  }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      this.channelid = this.channels[0].channelid;
    } else {
      this.channels = this.allinoneService.supervisorChannels;
      this.channelid = this.channels[0].channelid;
    }
    this.gettingChannels = false;
  }

  searchItem(event: any) {
    const searchItem = event.target.value;
    this.coursesTableData.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.coursesTableData.filter = '';
    this.searchText = '';
  }

  getCourses() {
    this.gettingCourses = true;
    this.courses = [];
    this.coursesTableData = new MatTableDataSource(this.courses);
    this.courseService.getPrerequisiteCourses(this.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.courses = res.data;
          this.coursesTableData = new MatTableDataSource(this.courses);
          this.coursesTableData.paginator = this.paginator;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCourses = false;
      },
      (err) => {
        this.gettingCourses = false;
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.allinoneService.compare(
            a.coursename,
            b.coursename,
            isAsc
          );
        case 'prerequisite':
          return this.allinoneService.compare(
            a.prerequisites,
            b.prerequisites,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.coursesTableData = new MatTableDataSource(this.courses);
  }


  openPrerequisiteForm(course: any){
    const dialog = this.dialog.open(UpdatePrerequisitesComponent, {
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        course: course,
        allcourses: this.courses,
      }
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if(dialogResult){
        course.prerequisites = dialogResult.prerequisites;
      }
    });
  }
}
