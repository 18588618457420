import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LibraryViewComponent } from '../../library/library-view/library-view.component';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-setup-cms',
  templateUrl: './setup-cms.component.html',
  styleUrls: ['./setup-cms.component.scss'],
})
export class SetupCmsComponent implements OnInit {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  isEditing: boolean = false;
  isLoading: boolean = false;

  videoUrlPreview: string = '';
  imageChangedEvent: any = '';
  croppedImage: boolean = false;

  bannertype: any;

  imageSlide: any = {
    file: '',
    previewurl: '',
    filename: '',
    text: '',
  };

  videoSlide: any = {
    text: '',
    file: '',
    previewurl: '',
    filename: '',
    videourl: '',
    libraryid: '',
    preview: '',
    videotype: '001', // 001 - URL, 002 - Library
  };

  textImageSlide: any = {
    text1: '',
    text2: '',
    file: '',
    previewurl: '',
    filename: ''
  };

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '6rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultFontSize: '2',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        // 'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        // 'heading',
        'fontName',
      ],
      [
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  // modules = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  //     // ['blockquote', 'code-block'],

  //     // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  //     // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  //     // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  //     // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  //     // [{ 'direction': 'rtl' }],                         // text direction

  //     // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  //     [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  //     // [{ 'font': [] }],
  //     // [{ 'align': [] }],

  //     // ['clean'],                                         // remove formatting button

  //     // ['link', 'image', 'video']                         // link and image, video
  //   ]
  // };

  // formats = [
  //   'background',
  //   'bold',
  //   'color',
  //   'font',
  //   'code',
  //   'italic',
  //   'link',
  //   'size',
  //   'strike',
  //   'script',
  //   'underline',
  //   'blockquote',
  //   'header',
  //   'indent',
  //   // 'list',
  //   'align',
  //   'direction',
  //   'code-block',
  //   'formula',
  //   'mention',
  // ];

  constructor(
    private messageService: MessageService,
    private dialogRef: MatDialogRef<SetupCmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    public datepipe: DatePipe,
    private dialog: MatDialog
  ) {
    this.bannertype = data.bannertype;
    this.isEditing = data.isEditing;
    if (this.isEditing) {
      // assign data for edit
      console.log(data.editData);
      if (this.bannertype == '002') {
        this.imageSlide.file = data.editData.file;
        this.imageSlide.previewurl = data.editData.previewurl;
        this.imageSlide.filename = data.editData.filename;
        this.imageSlide.text = data.editData.text;
      } else if (this.bannertype == '003') {
        this.videoSlide.file = data.editData.file;
        this.videoSlide.previewurl = data.editData.previewurl;
        this.videoSlide.filename = data.editData.filename;
        this.videoSlide.text = data.editData.text;
        this.videoSlide.videourl = data.editData.videourl;
        this.videoSlide.libraryid = data.editData.libraryid;
        this.videoSlide.preview = data.editData.preview;
        this.videoSlide.videotype = data.editData.videotype;
        this.checkVideoUrl();
      } else if (this.bannertype == '000') {
        // Edit text and image slide for cmspage '000'
        this.textImageSlide.text1 = data.editData.text1;
        this.textImageSlide.text2 = data.editData.text2;
        this.textImageSlide.file = data.editData.file;
        this.textImageSlide.previewurl = data.editData.previewurl;
        this.textImageSlide.filename = data.editData.filename;
      }
      this.croppedImage = true;
    }
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  videoTypeChange() {}

  onFileSelect(event: any) {
    this.croppedImage = false;
    let file = event.target.files[0];
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (file) {
      if (filetypelist.indexOf(file.type) > -1) {
        this.imageChangedEvent = event;
        let reader = new FileReader();
        reader.onload = (e: any) => {
          if (this.bannertype == '002') {
            this.imageSlide.file = file;
            this.imageSlide.previewurl = e.target.result;
            this.imageSlide.filename = this.allinoneService.getFileName(file);
          } else if (this.bannertype == '003') {
            this.videoSlide.file = file;
            this.videoSlide.previewurl = e.target.result;
            this.videoSlide.filename = this.allinoneService.getFileName(file);
          } else if (this.bannertype == '000') {
            this.textImageSlide.file = file;
            this.textImageSlide.previewurl = e.target.result;
            this.textImageSlide.filename = this.allinoneService.getFileName(file);
          }
        };
        reader.readAsDataURL(file);
      } else {
        this.messageService.openSnackBar(
          "File type '" + file.type + "' is not supported!",
          'warn'
        );
      }
    }
  }

  onDismiss() {
    this.dialogRef.close();
  }

  submit() {
    if (this.bannertype == '002') {

      if (this.imageSlide.previewurl == '') {
        return this.messageService.openSnackBar(
          'Please choose an image',
          'warn'
        );
      } else if (this.imageSlide.previewurl && !this.croppedImage) {
        return this.messageService.openSnackBar(
          'Please crop the image',
          'warn'
        );
      }
    }
    if (this.bannertype == '003') {
      if (this.videoSlide.videourl == ''  && this.videoSlide.videotype == '001') {
        return this.messageService.openSnackBar(
          'Video URL cannot be null.',
          'warn'
        );
      }
      if (this.videoSlide.libraryid == ''  && this.videoSlide.videotype == '002') {
        return this.messageService.openSnackBar(
          'Please choose a video from library.',
          'warn'
        );
      }
      if (this.videoSlide.previewurl && !this.croppedImage) {
        return this.messageService.openSnackBar(
          'Please crop the image',
          'warn'
        );
      }
    }
    if (this.bannertype == '000') {
      // Text and image submission for cmspage '000'
      if (this.textImageSlide.previewurl == '') {
        return this.messageService.openSnackBar('Please choose an image', 'warn');
      }
      if (this.textImageSlide.text1.trim() == '' || this.textImageSlide.text2.trim() == '') {
        return this.messageService.openSnackBar('Please fill both text fields', 'warn');
      }
      this.dialogRef.close(this.textImageSlide);
    }

    this.dialogRef.close(
      this.bannertype == '000' ? this.textImageSlide : this.bannertype === '002' ? this.imageSlide : this.videoSlide
    );
  }

  imageCropped(event: ImageCroppedEvent) {
    if (this.bannertype == '002') {
      this.imageSlide.file = this.allinoneService.base64ToFile(
        event.base64,
        this.imageSlide.filename
      );
      this.imageSlide.previewurl = event.base64;
    } else if (this.bannertype == '003') {
      this.videoSlide.file = this.allinoneService.base64ToFile(
        event.base64,
        this.imageSlide.filename
      );
      this.videoSlide.previewurl = event.base64;
    } else if (this.bannertype == '000') {
      this.textImageSlide.file = this.allinoneService.base64ToFile(event.base64, this.textImageSlide.image.filename);
      this.textImageSlide.previewurl = event.base64;
    }
    console.log(this.imageSlide);
  }

  copyImage() {
    this.croppedImage = true;
    this.hiddenfileinput.nativeElement.value = '';
  }

  imageLoaded() {
    // show cropper
    console.log('image loaded.');
  }

  loadImageFailed() {
    console.log('image load fail.');
  }

  cropperReady() {}

  checkVideoUrl() {
    //No video

    // var reg = new RegExp("^t\=+[0-9]+s$");

    if (this.videoSlide.videotype == '001') {
      if (this.videoSlide.videourl.toString().trim() == '') {
        this.videoUrlPreview = '';
      }
      //youtube
      else if (
        this.videoSlide.videourl.toString().startsWith('https://youtu.be/')
      ) {
        this.videoUrlPreview =
          'https://www.youtube.com/embed/' +
          this.videoSlide.videourl
            .split('/')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        if (
          this.videoUrlPreview.includes('start=') &&
          this.videoUrlPreview.endsWith('s')
        ) {
          this.videoUrlPreview = this.videoUrlPreview.substring(
            0,
            this.videoUrlPreview.length - 1
          );
        }
      } else if (
        this.videoSlide.videourl.toString().startsWith('https://www.youtube.com')
      ) {
        this.videoUrlPreview =
          'https://www.youtube.com/embed/' +
          this.videoSlide.videourl
            .split('v=')
            .pop()
            ?.replace('&', '?')
            .replace('t=', 'start=');
        if (
          this.videoUrlPreview.includes('start=') &&
          this.videoUrlPreview.endsWith('s')
        ) {
          this.videoUrlPreview = this.videoUrlPreview.substring(
            0,
            this.videoUrlPreview.length - 1
          );
        }
      } else {
        //vimeo
        // https://vimeo.com/568270540
        // https://player.vimeo.com/video/568270540
        this.videoUrlPreview =
          'https://player.vimeo.com/video/' +
          this.videoSlide.videourl.split('/').pop();
      }
    } else {
      if (this.videoSlide.preview.toString().trim() == '') {
        this.videoUrlPreview = '';
      } else {
        this.videoUrlPreview = this.videoSlide.preview;
      }
    }

    console.log(this.videoUrlPreview);
  }

  chooseFromLibraryVideo() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: false,
        isvideo: true,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.success == true) {
        this.videoSlide.preview = dialogResult.data.downloadurl;
        this.videoSlide.libraryid = dialogResult.data.id;
        this.checkVideoUrl();
      }
    });
  }
}
