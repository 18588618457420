<div class="new-unit">
  <div class="new-section-label pt-1 pb-2" *ngIf="!isExpandWP">
    <strong *ngIf="action_status == 'new'"> New Unit: </strong>
    <strong *ngIf="action_status != 'new'"> Edit Unit: </strong>
  </div>
  <div class="fx" *ngIf="!isExpandWP">
    <div class="form-group">
      <input type="text" class="form-control form-control-sm" name="unitname" autofocus placeholder="Enter a unit name"
        [(ngModel)]="unitForm.unit_name" #unitname="ngModel"
        [ngClass]="{ 'is-invalid': unitFormSubmitted && unitname.invalid }" (keyup.enter)="addUnit()">
      <div class="invalid-feedback"
        *ngIf="unitname.invalid || (unitname.dirty || unitname.touched) && unitFormSubmitted">
        <div *ngIf="unitname.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
  </div>
  <!-- <div mat-dialog-content> -->
  <div>
    <div class="unit-section d-flex flex-wrap" *ngIf="!isExpandWP">
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 1" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(1)">
        <i class="fa fa-play-circle-o" aria-hidden="true"></i>
        <span>Video</span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 10" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(10)">
        <i class="fas fa-file-audio" aria-hidden="true"></i>
        <span>Audio</span>
      </div>
      <!-- <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 2" class="content-type  mat-elevation-z4" matRipple (click)="orginalfile.click();">
                <input type="file" (change)="onFileSelected($event)" accept=".doc,application/pdf,application/vnd.ms-powerpoint" class="inputfile" #orginalfile>
                <i class="fa fa-file-o" aria-hidden="true"></i> PDF
            </div> -->
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 2" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(2)">
        <i class="fa fa-file-o" aria-hidden="true"></i>
        <span>PDF</span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 3" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(3)">
        <i class="fa fa-file-text" aria-hidden="true"></i>
        <span>Write Up</span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 4" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(4)">
        <i class="fas fa-link" aria-hidden="true"></i>
        <span>URL</span>
      </div>
      <!-- <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 5" class="content-type  mat-elevation-z4" matRipple (click)="attfile.click();">
                <input type="file" (change)="attFile($event)" class="inputfile" #attfile>
                <i class="fas  fa-paperclip"></i> Attachment
            </div> -->
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 5" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(5)">
        <i class="fas  fa-paperclip"></i>
        <span>Attach</span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 6" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(6)">
        <i class="fas fa-clipboard-list"></i>
        <span>Quiz</span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 8" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(8)">
        <img src="../../../assets/images/chat.png" width="18px">
        <span style="font-size: 12px;"> Discussion </span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 7" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(7)">
        <img src="../../../assets/images/approve.png" width="18px">
        <span style="font-size: 12px;">Assignment</span>
      </div>
      <div *ngIf="unitForm.contenttype == 0 || unitForm.contenttype == 9" class="content-type mat-elevation-z4"
        matRipple (click)="chooseType(9)" style="opacity: 0.5;">
        <img src="../../../assets/images/online.png" width="18px">
        <span>Class</span>
      </div>
      <span
        *ngIf="unitForm.contenttype > 0 && unitForm.contenttype != 6 && unitForm.contenttype != 7 && unitForm.contenttype != 8 "
        class="close-content-type" (click)="chooseType(0)">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
      <span
        *ngIf="(unitForm.contenttype == 6 || unitForm.contenttype == 7 || unitForm.contenttype== 8) && action_status == 'new'"
        class="close-content-type" (click)="chooseType(0)">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <!-- <div class="row" *ngIf="unitForm.contenttype != 0 || unitForm.contenttype != 1 || unitForm.contenttype != 10 || unitForm.contenttype != 4 || unitForm.contenttype != 5
    || unitForm.contenttype != 6 || unitForm.contenttype != 8"> -->
    <div class="row" *ngIf="unitForm.contenttype != 0 && unitForm.contenttype != 1 && unitForm.contenttype != 10 && unitForm.contenttype != 4 && unitForm.contenttype != 5
    && unitForm.contenttype != 6 && unitForm.contenttype != 8">
      <div class="form-group mb-2"
        *ngIf="unitForm.contenttype != 2 && unitForm.contenttype != 3 && unitForm.contenttype != 6 && !isExpandWP "
        [ngClass]="unitForm.contenttype == 7 ? 'col-12' : 'col-6' ">
        <div class="new-section-label pt-1 pb-1">
          <strong> Grade </strong>
        </div>
        <input type="number" class="form-control form-control-sm" name="grade" autofocus placeholder=""
          [(ngModel)]="unitForm.grade" #grade="ngModel" required
          [ngClass]="{ 'is-invalid': (unitFormSubmitted && grade.invalid ) }" autocomplete="off"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
        <div class="invalid-feedback" *ngIf="grade.invalid || (grade.dirty || grade.touched) && unitFormSubmitted">
          <div *ngIf="grade.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>




      <div class="form-group mb-2" *ngIf="unitForm.contenttype != 7 && !isExpandWP "
        [ngClass]="unitForm.contenttype == 2 || unitForm.contenttype == 3 ? 'col-12' : 'col-6' ">
        <div class="new-section-label pt-1 pb-1">
          <strong>Study Time (minutes)</strong>
        </div>
        <input type="number" class="form-control form-control-sm" name="targetstudytime" autofocus placeholder=""
          [(ngModel)]="unitForm.targetstudytime" #targetstudytime="ngModel" required
          [ngClass]="{ 'is-invalid': (unitFormSubmitted && targetstudytime.invalid ) }" autocomplete="off"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
        <div class="invalid-feedback"
          *ngIf="targetstudytime.invalid || (targetstudytime.dirty || targetstudytime.touched) && unitFormSubmitted">
          <div *ngIf="targetstudytime.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>

      <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 3" class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->

      <!-- *ngIf="unitForm.contenttype == 2" -->
      <!-- <div class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->
      <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 2" class="mb-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload"
            [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div>

    </div>

    <!-- for audio  -->

    <div class="pdf-editor" *ngIf="unitForm.contenttype == 10">
      <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 10" class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->
      <div class="d-flex flex-column align-items-baseline justify-content-start">
        <div class="d-flex flex-row align-items-center justify-content-start">
          <div class="me-2">
            <input hidden type="file" (change)="audioFileSelected($event)" accept=".mp3" class="inputfile" id="file"
              #file>
            <button type="button" mat-button (click)="file.click()" class="px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 20 17">
                <path
                  d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                </path>
              </svg> Upload Audio
            </button>
          </div>
          <div>
            <button type="button" mat-button (click)="chooseAudioFromLibrary()" class="px-2">
              <svg style="width: 20px; margin-right: 5px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                    <path
                      d="M2488.2,4153.8c-104.2-51.1-145-159.3-145-388.1v-190h1062.3h1062.3V3780c0,234.9-24.5,304.4-128.7,367.7c-61.3,38.8-108.3,40.9-923.4,40.9C2655.7,4188.5,2549.4,4184.5,2488.2,4153.8z" />
                    <path
                      d="M678.2,3669.7c-102.1-47-134.8-100.1-157.3-265.6c-12.3-85.8-18.4-159.3-14.3-163.4c4.1-4.1,343.2-26.6,753.8-51.1c410.6-24.5,796.7-49,862.1-55.2l114.4-12.3l14.3,134.8c12.3,108.3,8.2,147.1-22.5,210.4c-67.4,134.8-100.1,143-692.5,177.7c-292.1,18.4-590.4,36.8-663.9,42.9C784.5,3694.2,719.1,3688.1,678.2,3669.7z" />
                    <path
                      d="M8600.2,3647.2c-392.2-26.6-441.2-40.9-506.6-138.9c-32.7-49-40.8-112.4-34.7-269.7l4.1-104.2l700.7,47c384,24.5,702.7,44.9,706.8,44.9c24.5,4.1-16.4,288-49,337.1C9339.7,3686,9280.5,3692.2,8600.2,3647.2z" />
                    <path
                      d="M6771.9,3400c-126.7-67.4-118.5,112.4-118.5-2908.9v-2757.8h643.5h643.5V491.1c0,3031.5,8.2,2843.6-122.6,2911C7732,3447,6853.6,3445,6771.9,3400z M7756.5,2166.2v-326.8h-459.6h-459.6v326.8V2493h459.6h459.6V2166.2z" />
                    <path
                      d="M2343.1,123.4v-3145.9h1062.3h1062.3V123.4v3145.9H3405.4H2343.1V123.4z M4263.4-2225.8V-2614h-847.8h-847.8v388.1v388.1h847.8h847.8V-2225.8z" />
                    <path
                      d="M5223.5,3020.1c-196.1-8.2-382-26.6-412.6-38.8c-91.9-36.8-143-110.3-153.2-216.5c-8.2-91.9,190-4500.3,202.2-4510.5c6.1-4.1,1291,53.1,1295.1,59.2c2.1,0-42.9,1013.2-98,2249.1c-94,2053-106.2,2255.2-143,2332.9c-44.9,98-136.8,151.2-255.4,147.1C5615.7,3040.5,5419.6,3030.3,5223.5,3020.1z" />
                    <path
                      d="M484.2,2887.3c0-20.4-55.2-960.1-122.6-2089.8C294.2-332.2,214.5-1666.1,185.9-2170.7c-30.6-502.5-51.1-919.3-44.9-925.4c12.3-12.3,1711.9-118.5,1722.1-106.2c10.2,10.2,363.6,6003.8,353.4,6012c-6.1,6.1-1622,112.4-1716,112.4C492.3,2922,484.2,2905.7,484.2,2887.3z" />
                    <path
                      d="M8794.3,2870.9c-380-24.5-694.6-49-700.7-53.1c-8.2-8.2,355.5-6007.9,363.6-6018.1c2.1-2,318.7,16.3,704.8,40.9l698.7,42.9v49c0,138.9-363.6,5973.2-371.8,5977.2C9484.7,2911.8,9172.2,2893.4,8794.3,2870.9z" />
                    <path
                      d="M5438-1972.5c-269.7-14.3-506.6-26.6-525-26.6c-32.7-2-36.8-26.6-36.8-196.1v-194.1h67.4c128.7,0,1068.4,42.9,1154.2,53.1l85.8,10.2v128.7c0,71.5-6.1,159.3-12.2,194.1C6155-1927.6,6210.1-1931.7,5438-1972.5z" />
                    <path d="M6653.4-2777.4v-204.3h643.5h643.5v204.3v204.3h-643.5h-643.5V-2777.4z" />
                    <path
                      d="M5358.3-2885.7l-445.3-20.4l14.3-410.6c14.3-459.6,30.6-518.8,153.2-600.6c65.4-44.9,85.8-47,255.4-36.8c102.1,6.1,306.4,18.4,455.5,24.5c230.9,12.3,281.9,20.4,337.1,57.2c114.4,77.6,120.5,104.2,106.2,543.4c-8.2,218.6-18.4,414.7-22.5,435.1C6199.9-2853,6114.1-2853,5358.3-2885.7z" />
                    <path
                      d="M6653.4-3533.2v-245.1l69.5-77.6l69.4-75.6h504.6h504.6l69.4,75.6l69.5,77.6v245.1v245.1h-643.5h-643.5V-3533.2z" />
                    <path
                      d="M2343.1-3516.9c0-234.9,40.9-339.1,149.1-390.2c63.3-30.6,169.6-34.7,927.4-34.7c811,0,858,2,919.3,40.8c104.2,63.3,128.7,132.8,128.7,367.7v204.3H3405.4H2343.1V-3516.9z" />
                    <path
                      d="M110.3-3486.3c-32.7-198.1,12.2-330.9,132.8-390.2c81.7-38.8,1356.4-114.4,1427.9-85.8c104.2,44.9,143,108.3,165.5,273.7c12.3,85.8,18.4,159.3,14.3,163.4c-4.1,4.1-361.6,26.5-794.6,51.1c-433.1,24.5-819.2,49-860,55.2C124.6-3408.6,122.6-3410.7,110.3-3486.3z" />
                    <path
                      d="M9115-3474c-292.1-18.4-557.7-36.8-588.3-36.8l-59.2-2l14.3-136.9c34.7-326.9,75.6-339.1,878.4-283.9c406.5,26.6,451.4,40.9,508.7,165.5c34.7,71.5,38.8,138.9,20.4,261.5l-14.3,75.6l-114.4-2.1C9697.2-3435.2,9407.1-3453.6,9115-3474z" />
                  </g>
                </g>
              </svg>
              Choose from Library
            </button>
          </div>
        </div>
        <div *ngIf="unitForm.filename"
          class="card w-100 my-2 p-3 d-flex flex-row align-items-center justify-content-between">
          <div class="d-flex">
            <img [src]="allinoneService.fileurl + 'icons/audio.png'" width="20px" class="me-2">
            <span for="file" class="filename">{{unitForm.filename}}</span>
          </div>
          <div *ngIf="isStillFileUpload">
            <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
          </div>
        </div>
      </div>
      <!-- <div class="invalid-feedback" style="display: block !important;"
        *ngIf="unitForm.filename == '' && unitFormSubmitted">
        <div>
          Please choose a file
        </div>
      </div> -->
      <!-- <div class="video-responsive my-2" *ngIf="unitForm.content != '' && !loadingpdf">
        <ngx-extended-pdf-viewer [src]="unitForm.preview" [useBrowserLocale]="true" [textLayer]="true"
          [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false"
          [showOpenFileButton]="false" height="80vh"></ngx-extended-pdf-viewer>
      </div> -->
      <div *ngIf="unitForm.preview">
        <vm-player>
          <vm-audio>
            <source [attr.data-src]="unitForm.preview" type="audio/mpeg" />
          </vm-audio>
          <vm-default-ui no-settings>
          </vm-default-ui>
        </vm-player>
      </div>
    </div>

    <div class="row" *ngIf="unitForm.contenttype == 7 && !isExpandWP">
      <div class="mb-3">
        <div class="new-section-label pt-1 pb-1">
          <strong>Due Date</strong>
        </div>
        <div class="row">
          <div class="col-6">
            <input type="date" class="form-control" id="deadlinedate" name="deadlinedate"
              [(ngModel)]="unitForm.deadlinedate" required #deadlinedate="ngModel" step="1">
          </div>
          <div class="col-6">
            <div class="input-group">
              <!-- [ngClass]="{ 'is-invalid': (unitFormSubmitted ) }" -->
              <input type="time" class="form-control form-control-sm" name="deadlinetime" autofocus placeholder=""
                [(ngModel)]="unitForm.deadlinetime" #deadlinetime="ngModel" autocomplete="off">
              <button class="btn" type="button" (click)="deadlineclear()"
                style="background-color: var(--main-color);color: white;">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class="mb-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="randomassignment" [(ngModel)]="unitForm.randomassignment">
          <label class="form-check-label" for="randomassignment">
            <span class="me-1">Random Assignment</span>
          </label>
        </div>
      </div>
      <div class="form-group mb-3" *ngIf="unitForm.randomassignment">
        <div class="new-section-label pt-1 pb-1">
          <strong> Random Assignment Count </strong>
        </div>
        <input min="1" type="type" class="form-control form-control-sm" name="randomassignmentcount" autofocus
          placeholder="" [(ngModel)]="unitForm.randomassignmentcount" #randomassignmentcount="ngModel" required
          [ngClass]="{ 'is-invalid': (unitFormSubmitted && randomassignmentcount.invalid ) }" autocomplete="off"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
      </div>
      <!-- <div class="mb-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="multifileupload" [(ngModel)]="unitForm.multifileupload">
          <label class="form-check-label" for="multifileupload">
            <span class="me-1">Multiple Files Upload</span>
          </label>
        </div>
      </div>
      <div class="form-group mb-3" *ngIf="unitForm.multifileupload">
        <div class="new-section-label pt-1 pb-1">
          <strong> Multiple Files Count </strong>
        </div>
        <input min="2" type="type" class="form-control form-control-sm" name="multifileuploadcount" autofocus
          placeholder="" [(ngModel)]="unitForm.multifileuploadcount" #multifileuploadcount="ngModel" required
          [ngClass]="{ 'is-invalid': (unitFormSubmitted && multifileuploadcount.invalid ) }" autocomplete="off"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
      </div> -->
    </div>
    <div class="video-editor" *ngIf="unitForm.contenttype == 1">

      <div class="d-flex align-items-center my-2">
        <div class="form-check me-5">
          <input class="form-check-input" type="radio" name="videotype" id="url" [(ngModel)]="unitForm.videotype"
            value="001" [checked]="unitForm.videotype == '001'" (ngModelChange)="videoTypeChange()">
          <label class="form-check-label" for="url">
            URL
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="videotype" id="library" [(ngModel)]="unitForm.videotype"
            value="002" [checked]="unitForm.videotype == '002'" (ngModelChange)="videoTypeChange()">
          <label class="form-check-label" for="library">
            Library
          </label>
        </div>
      </div>
      <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 1" class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->
      <ng-container *ngIf="unitForm.videotype == '001'">
        <div class="new-section-label pt-1 pb-1">
          <strong> Video Url </strong>
        </div>
        <div>
          <div class="form-group">
            <input type="text" class="form-control form-control-sm" name="videourl" autofocus placeholder="Enter a url"
              [(ngModel)]="unitForm.content" #videourl="ngModel" required
              [ngClass]="{ 'is-invalid': (unitFormSubmitted && videourl.invalid ) }" autocomplete="off"
              (keyup.enter)="addUnit()" (ngModelChange)="checkVideoUrl()">
            <div class="invalid-feedback"
              *ngIf="videourl.invalid || (videourl.dirty || videourl.touched) && unitFormSubmitted">
              <div *ngIf="videourl.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
          <!-- video preview -->
          <div class="video-responsive my-2" *ngIf="videoUrlPreview != ''">
            <iframe width="422" height="200" [src]="videoUrlPreview | safe" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="unitForm.videotype == '002'">
        <button type="button" mat-button (click)="chooseFromLibraryVideo()" class="px-2 mb-2">
          <svg style="width: 20px; margin-right: 5px" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
            enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g>
              <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path
                  d="M2488.2,4153.8c-104.2-51.1-145-159.3-145-388.1v-190h1062.3h1062.3V3780c0,234.9-24.5,304.4-128.7,367.7c-61.3,38.8-108.3,40.9-923.4,40.9C2655.7,4188.5,2549.4,4184.5,2488.2,4153.8z" />
                <path
                  d="M678.2,3669.7c-102.1-47-134.8-100.1-157.3-265.6c-12.3-85.8-18.4-159.3-14.3-163.4c4.1-4.1,343.2-26.6,753.8-51.1c410.6-24.5,796.7-49,862.1-55.2l114.4-12.3l14.3,134.8c12.3,108.3,8.2,147.1-22.5,210.4c-67.4,134.8-100.1,143-692.5,177.7c-292.1,18.4-590.4,36.8-663.9,42.9C784.5,3694.2,719.1,3688.1,678.2,3669.7z" />
                <path
                  d="M8600.2,3647.2c-392.2-26.6-441.2-40.9-506.6-138.9c-32.7-49-40.8-112.4-34.7-269.7l4.1-104.2l700.7,47c384,24.5,702.7,44.9,706.8,44.9c24.5,4.1-16.4,288-49,337.1C9339.7,3686,9280.5,3692.2,8600.2,3647.2z" />
                <path
                  d="M6771.9,3400c-126.7-67.4-118.5,112.4-118.5-2908.9v-2757.8h643.5h643.5V491.1c0,3031.5,8.2,2843.6-122.6,2911C7732,3447,6853.6,3445,6771.9,3400z M7756.5,2166.2v-326.8h-459.6h-459.6v326.8V2493h459.6h459.6V2166.2z" />
                <path
                  d="M2343.1,123.4v-3145.9h1062.3h1062.3V123.4v3145.9H3405.4H2343.1V123.4z M4263.4-2225.8V-2614h-847.8h-847.8v388.1v388.1h847.8h847.8V-2225.8z" />
                <path
                  d="M5223.5,3020.1c-196.1-8.2-382-26.6-412.6-38.8c-91.9-36.8-143-110.3-153.2-216.5c-8.2-91.9,190-4500.3,202.2-4510.5c6.1-4.1,1291,53.1,1295.1,59.2c2.1,0-42.9,1013.2-98,2249.1c-94,2053-106.2,2255.2-143,2332.9c-44.9,98-136.8,151.2-255.4,147.1C5615.7,3040.5,5419.6,3030.3,5223.5,3020.1z" />
                <path
                  d="M484.2,2887.3c0-20.4-55.2-960.1-122.6-2089.8C294.2-332.2,214.5-1666.1,185.9-2170.7c-30.6-502.5-51.1-919.3-44.9-925.4c12.3-12.3,1711.9-118.5,1722.1-106.2c10.2,10.2,363.6,6003.8,353.4,6012c-6.1,6.1-1622,112.4-1716,112.4C492.3,2922,484.2,2905.7,484.2,2887.3z" />
                <path
                  d="M8794.3,2870.9c-380-24.5-694.6-49-700.7-53.1c-8.2-8.2,355.5-6007.9,363.6-6018.1c2.1-2,318.7,16.3,704.8,40.9l698.7,42.9v49c0,138.9-363.6,5973.2-371.8,5977.2C9484.7,2911.8,9172.2,2893.4,8794.3,2870.9z" />
                <path
                  d="M5438-1972.5c-269.7-14.3-506.6-26.6-525-26.6c-32.7-2-36.8-26.6-36.8-196.1v-194.1h67.4c128.7,0,1068.4,42.9,1154.2,53.1l85.8,10.2v128.7c0,71.5-6.1,159.3-12.2,194.1C6155-1927.6,6210.1-1931.7,5438-1972.5z" />
                <path d="M6653.4-2777.4v-204.3h643.5h643.5v204.3v204.3h-643.5h-643.5V-2777.4z" />
                <path
                  d="M5358.3-2885.7l-445.3-20.4l14.3-410.6c14.3-459.6,30.6-518.8,153.2-600.6c65.4-44.9,85.8-47,255.4-36.8c102.1,6.1,306.4,18.4,455.5,24.5c230.9,12.3,281.9,20.4,337.1,57.2c114.4,77.6,120.5,104.2,106.2,543.4c-8.2,218.6-18.4,414.7-22.5,435.1C6199.9-2853,6114.1-2853,5358.3-2885.7z" />
                <path
                  d="M6653.4-3533.2v-245.1l69.5-77.6l69.4-75.6h504.6h504.6l69.4,75.6l69.5,77.6v245.1v245.1h-643.5h-643.5V-3533.2z" />
                <path
                  d="M2343.1-3516.9c0-234.9,40.9-339.1,149.1-390.2c63.3-30.6,169.6-34.7,927.4-34.7c811,0,858,2,919.3,40.8c104.2,63.3,128.7,132.8,128.7,367.7v204.3H3405.4H2343.1V-3516.9z" />
                <path
                  d="M110.3-3486.3c-32.7-198.1,12.2-330.9,132.8-390.2c81.7-38.8,1356.4-114.4,1427.9-85.8c104.2,44.9,143,108.3,165.5,273.7c12.3,85.8,18.4,159.3,14.3,163.4c-4.1,4.1-361.6,26.5-794.6,51.1c-433.1,24.5-819.2,49-860,55.2C124.6-3408.6,122.6-3410.7,110.3-3486.3z" />
                <path
                  d="M9115-3474c-292.1-18.4-557.7-36.8-588.3-36.8l-59.2-2l14.3-136.9c34.7-326.9,75.6-339.1,878.4-283.9c406.5,26.6,451.4,40.9,508.7,165.5c34.7,71.5,38.8,138.9,20.4,261.5l-14.3,75.6l-114.4-2.1C9697.2-3435.2,9407.1-3453.6,9115-3474z" />
              </g>
            </g>
          </svg>
          Choose from Library
        </button>
        <div *ngIf="videoUrlPreview">
          <div>
            <vm-player>
              <vm-video>
                <source [attr.data-src]="videoUrlPreview" type="video/mp4" />
              </vm-video>
              <vm-default-ui no-click-to-play>
              </vm-default-ui>
            </vm-player>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="pdf-editor" *ngIf="unitForm.contenttype == 2">
      <div class="d-flex flex-column align-items-baseline justify-content-start">
        <div class="d-flex flex-row align-items-center justify-content-start">
          <div class="me-2">
            <input hidden type="file" (change)="onFileSelected($event)" accept="application/pdf" class="inputfile"
              id="file" #file>
            <button type="button" mat-button (click)="file.click()" class="px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 20 17">
                <path
                  d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                </path>
              </svg> Upload File
            </button>
          </div>
          <div>
            <button type="button" mat-button (click)="chooseFromLibrary()" class="px-2">
              <svg style="width: 20px; margin-right: 5px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                    <path
                      d="M2488.2,4153.8c-104.2-51.1-145-159.3-145-388.1v-190h1062.3h1062.3V3780c0,234.9-24.5,304.4-128.7,367.7c-61.3,38.8-108.3,40.9-923.4,40.9C2655.7,4188.5,2549.4,4184.5,2488.2,4153.8z" />
                    <path
                      d="M678.2,3669.7c-102.1-47-134.8-100.1-157.3-265.6c-12.3-85.8-18.4-159.3-14.3-163.4c4.1-4.1,343.2-26.6,753.8-51.1c410.6-24.5,796.7-49,862.1-55.2l114.4-12.3l14.3,134.8c12.3,108.3,8.2,147.1-22.5,210.4c-67.4,134.8-100.1,143-692.5,177.7c-292.1,18.4-590.4,36.8-663.9,42.9C784.5,3694.2,719.1,3688.1,678.2,3669.7z" />
                    <path
                      d="M8600.2,3647.2c-392.2-26.6-441.2-40.9-506.6-138.9c-32.7-49-40.8-112.4-34.7-269.7l4.1-104.2l700.7,47c384,24.5,702.7,44.9,706.8,44.9c24.5,4.1-16.4,288-49,337.1C9339.7,3686,9280.5,3692.2,8600.2,3647.2z" />
                    <path
                      d="M6771.9,3400c-126.7-67.4-118.5,112.4-118.5-2908.9v-2757.8h643.5h643.5V491.1c0,3031.5,8.2,2843.6-122.6,2911C7732,3447,6853.6,3445,6771.9,3400z M7756.5,2166.2v-326.8h-459.6h-459.6v326.8V2493h459.6h459.6V2166.2z" />
                    <path
                      d="M2343.1,123.4v-3145.9h1062.3h1062.3V123.4v3145.9H3405.4H2343.1V123.4z M4263.4-2225.8V-2614h-847.8h-847.8v388.1v388.1h847.8h847.8V-2225.8z" />
                    <path
                      d="M5223.5,3020.1c-196.1-8.2-382-26.6-412.6-38.8c-91.9-36.8-143-110.3-153.2-216.5c-8.2-91.9,190-4500.3,202.2-4510.5c6.1-4.1,1291,53.1,1295.1,59.2c2.1,0-42.9,1013.2-98,2249.1c-94,2053-106.2,2255.2-143,2332.9c-44.9,98-136.8,151.2-255.4,147.1C5615.7,3040.5,5419.6,3030.3,5223.5,3020.1z" />
                    <path
                      d="M484.2,2887.3c0-20.4-55.2-960.1-122.6-2089.8C294.2-332.2,214.5-1666.1,185.9-2170.7c-30.6-502.5-51.1-919.3-44.9-925.4c12.3-12.3,1711.9-118.5,1722.1-106.2c10.2,10.2,363.6,6003.8,353.4,6012c-6.1,6.1-1622,112.4-1716,112.4C492.3,2922,484.2,2905.7,484.2,2887.3z" />
                    <path
                      d="M8794.3,2870.9c-380-24.5-694.6-49-700.7-53.1c-8.2-8.2,355.5-6007.9,363.6-6018.1c2.1-2,318.7,16.3,704.8,40.9l698.7,42.9v49c0,138.9-363.6,5973.2-371.8,5977.2C9484.7,2911.8,9172.2,2893.4,8794.3,2870.9z" />
                    <path
                      d="M5438-1972.5c-269.7-14.3-506.6-26.6-525-26.6c-32.7-2-36.8-26.6-36.8-196.1v-194.1h67.4c128.7,0,1068.4,42.9,1154.2,53.1l85.8,10.2v128.7c0,71.5-6.1,159.3-12.2,194.1C6155-1927.6,6210.1-1931.7,5438-1972.5z" />
                    <path d="M6653.4-2777.4v-204.3h643.5h643.5v204.3v204.3h-643.5h-643.5V-2777.4z" />
                    <path
                      d="M5358.3-2885.7l-445.3-20.4l14.3-410.6c14.3-459.6,30.6-518.8,153.2-600.6c65.4-44.9,85.8-47,255.4-36.8c102.1,6.1,306.4,18.4,455.5,24.5c230.9,12.3,281.9,20.4,337.1,57.2c114.4,77.6,120.5,104.2,106.2,543.4c-8.2,218.6-18.4,414.7-22.5,435.1C6199.9-2853,6114.1-2853,5358.3-2885.7z" />
                    <path
                      d="M6653.4-3533.2v-245.1l69.5-77.6l69.4-75.6h504.6h504.6l69.4,75.6l69.5,77.6v245.1v245.1h-643.5h-643.5V-3533.2z" />
                    <path
                      d="M2343.1-3516.9c0-234.9,40.9-339.1,149.1-390.2c63.3-30.6,169.6-34.7,927.4-34.7c811,0,858,2,919.3,40.8c104.2,63.3,128.7,132.8,128.7,367.7v204.3H3405.4H2343.1V-3516.9z" />
                    <path
                      d="M110.3-3486.3c-32.7-198.1,12.2-330.9,132.8-390.2c81.7-38.8,1356.4-114.4,1427.9-85.8c104.2,44.9,143,108.3,165.5,273.7c12.3,85.8,18.4,159.3,14.3,163.4c-4.1,4.1-361.6,26.5-794.6,51.1c-433.1,24.5-819.2,49-860,55.2C124.6-3408.6,122.6-3410.7,110.3-3486.3z" />
                    <path
                      d="M9115-3474c-292.1-18.4-557.7-36.8-588.3-36.8l-59.2-2l14.3-136.9c34.7-326.9,75.6-339.1,878.4-283.9c406.5,26.6,451.4,40.9,508.7,165.5c34.7,71.5,38.8,138.9,20.4,261.5l-14.3,75.6l-114.4-2.1C9697.2-3435.2,9407.1-3453.6,9115-3474z" />
                  </g>
                </g>
              </svg>
              Choose from Library
            </button>
          </div>
        </div>
        <!-- (filestatus == '1' || filestatus== '2') &&  -->
        <div *ngIf="unitForm.filename"
          class="card w-100 mt-2 p-3 d-flex flex-row align-items-center justify-content-between">
          <div class="d-flex">
            <img src="../../../assets/images/pdf.png" width="20px" class="me-2">
            <span for="file" class="filename">{{unitForm.filename}}</span>
          </div>
          <!--  -->
          <div *ngIf="isStillFileUpload">
            <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
          </div>
        </div>
      </div>
      <div class="invalid-feedback" style="display: block !important;"
        *ngIf="unitForm.filename == '' && unitFormSubmitted">
        <div>
          Please choose a file
        </div>
      </div>
      <!-- document preview -->
      <div class="video-responsive my-2" *ngIf="unitForm.content != '' && !loadingpdf">
        <ngx-extended-pdf-viewer [src]="unitForm.preview" [useBrowserLocale]="true" [textLayer]="true"
          [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false"
          [showOpenFileButton]="false" height="80vh"></ngx-extended-pdf-viewer>
      </div>
    </div>
    <!-- <div class="article-editor" *ngIf="unitForm.contenttype == 3 || unitForm.contenttype == 7 || unitForm.contenttype == 8">
      <div class="invalid-feedback" style="display: block !important;"
        *ngIf="unitForm.content == '' && unitFormSubmitted">
        <div>
          {{allinoneService.input_err_msg}}
        </div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="isStillFileUpload"></mat-progress-bar>
      <div style="position: relative;">
        <div class="expand-icon" (click)="isExpandWP ? compressWriteUp() :expandWriteUp()">
          <i *ngIf="!isExpandWP" class='fas fa-expand-alt'></i>
          <i *ngIf="isExpandWP" class='fas fa-compress-alt'></i>

        </div>
        <angular-editor [(ngModel)]="unitForm.content" [config]="config"></angular-editor>
      </div>
    </div> -->
    <div class="video-editor" *ngIf="unitForm.contenttype == 4">
      <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 4" class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->
      <div class="new-section-label pt-1 pb-1">
        <strong>URL </strong>
      </div>
      <div>
        <div class="form-group">
          <input type="text" class="form-control form-control-sm" name="domainurl" autofocus placeholder="Enter a url"
            [(ngModel)]="unitForm.content" #durl="ngModel" required
            [ngClass]="{ 'is-invalid': (unitFormSubmitted && durl.invalid ) }" autocomplete="off"
            (keyup.enter)="addUnit()" (ngModelChange)="checkDoaminUrl()">
          <!--  -->
          <div class="invalid-feedback" *ngIf="durl.invalid || (durl.dirty || durl.touched) && unitFormSubmitted">
            <div *ngIf="durl.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <!-- video preview -->
        <div class="video-responsive my-2" *ngIf="domainUrlPreview != ''">
          <iframe width="422" height="200" [src]="domSanitizer.bypassSecurityTrustResourceUrl(domainUrlPreview)"
            title="Domain" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="pdf-editor" *ngIf="unitForm.contenttype == 5">
      <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 5" class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->
      <div class="d-flex flex-column align-items-baseline justify-content-start">
        <div class="d-flex flex-row align-items-center justify-content-start">
          <div class="me-2">
            <input hidden type="file" (change)="attFile($event)" class="inputfile" id="file" #attachfile>
            <button type="button" mat-button (click)="attachfile.click()" class="px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 20 17">
                <path
                  d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                </path>
              </svg> Upload File
            </button>
          </div>
          <div>
            <button type="button" mat-button (click)="chooseFromLibraryAtt()" class="px-2">
              <svg style="width: 20px; margin-right: 5px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                    <path
                      d="M2488.2,4153.8c-104.2-51.1-145-159.3-145-388.1v-190h1062.3h1062.3V3780c0,234.9-24.5,304.4-128.7,367.7c-61.3,38.8-108.3,40.9-923.4,40.9C2655.7,4188.5,2549.4,4184.5,2488.2,4153.8z" />
                    <path
                      d="M678.2,3669.7c-102.1-47-134.8-100.1-157.3-265.6c-12.3-85.8-18.4-159.3-14.3-163.4c4.1-4.1,343.2-26.6,753.8-51.1c410.6-24.5,796.7-49,862.1-55.2l114.4-12.3l14.3,134.8c12.3,108.3,8.2,147.1-22.5,210.4c-67.4,134.8-100.1,143-692.5,177.7c-292.1,18.4-590.4,36.8-663.9,42.9C784.5,3694.2,719.1,3688.1,678.2,3669.7z" />
                    <path
                      d="M8600.2,3647.2c-392.2-26.6-441.2-40.9-506.6-138.9c-32.7-49-40.8-112.4-34.7-269.7l4.1-104.2l700.7,47c384,24.5,702.7,44.9,706.8,44.9c24.5,4.1-16.4,288-49,337.1C9339.7,3686,9280.5,3692.2,8600.2,3647.2z" />
                    <path
                      d="M6771.9,3400c-126.7-67.4-118.5,112.4-118.5-2908.9v-2757.8h643.5h643.5V491.1c0,3031.5,8.2,2843.6-122.6,2911C7732,3447,6853.6,3445,6771.9,3400z M7756.5,2166.2v-326.8h-459.6h-459.6v326.8V2493h459.6h459.6V2166.2z" />
                    <path
                      d="M2343.1,123.4v-3145.9h1062.3h1062.3V123.4v3145.9H3405.4H2343.1V123.4z M4263.4-2225.8V-2614h-847.8h-847.8v388.1v388.1h847.8h847.8V-2225.8z" />
                    <path
                      d="M5223.5,3020.1c-196.1-8.2-382-26.6-412.6-38.8c-91.9-36.8-143-110.3-153.2-216.5c-8.2-91.9,190-4500.3,202.2-4510.5c6.1-4.1,1291,53.1,1295.1,59.2c2.1,0-42.9,1013.2-98,2249.1c-94,2053-106.2,2255.2-143,2332.9c-44.9,98-136.8,151.2-255.4,147.1C5615.7,3040.5,5419.6,3030.3,5223.5,3020.1z" />
                    <path
                      d="M484.2,2887.3c0-20.4-55.2-960.1-122.6-2089.8C294.2-332.2,214.5-1666.1,185.9-2170.7c-30.6-502.5-51.1-919.3-44.9-925.4c12.3-12.3,1711.9-118.5,1722.1-106.2c10.2,10.2,363.6,6003.8,353.4,6012c-6.1,6.1-1622,112.4-1716,112.4C492.3,2922,484.2,2905.7,484.2,2887.3z" />
                    <path
                      d="M8794.3,2870.9c-380-24.5-694.6-49-700.7-53.1c-8.2-8.2,355.5-6007.9,363.6-6018.1c2.1-2,318.7,16.3,704.8,40.9l698.7,42.9v49c0,138.9-363.6,5973.2-371.8,5977.2C9484.7,2911.8,9172.2,2893.4,8794.3,2870.9z" />
                    <path
                      d="M5438-1972.5c-269.7-14.3-506.6-26.6-525-26.6c-32.7-2-36.8-26.6-36.8-196.1v-194.1h67.4c128.7,0,1068.4,42.9,1154.2,53.1l85.8,10.2v128.7c0,71.5-6.1,159.3-12.2,194.1C6155-1927.6,6210.1-1931.7,5438-1972.5z" />
                    <path d="M6653.4-2777.4v-204.3h643.5h643.5v204.3v204.3h-643.5h-643.5V-2777.4z" />
                    <path
                      d="M5358.3-2885.7l-445.3-20.4l14.3-410.6c14.3-459.6,30.6-518.8,153.2-600.6c65.4-44.9,85.8-47,255.4-36.8c102.1,6.1,306.4,18.4,455.5,24.5c230.9,12.3,281.9,20.4,337.1,57.2c114.4,77.6,120.5,104.2,106.2,543.4c-8.2,218.6-18.4,414.7-22.5,435.1C6199.9-2853,6114.1-2853,5358.3-2885.7z" />
                    <path
                      d="M6653.4-3533.2v-245.1l69.5-77.6l69.4-75.6h504.6h504.6l69.4,75.6l69.5,77.6v245.1v245.1h-643.5h-643.5V-3533.2z" />
                    <path
                      d="M2343.1-3516.9c0-234.9,40.9-339.1,149.1-390.2c63.3-30.6,169.6-34.7,927.4-34.7c811,0,858,2,919.3,40.8c104.2,63.3,128.7,132.8,128.7,367.7v204.3H3405.4H2343.1V-3516.9z" />
                    <path
                      d="M110.3-3486.3c-32.7-198.1,12.2-330.9,132.8-390.2c81.7-38.8,1356.4-114.4,1427.9-85.8c104.2,44.9,143,108.3,165.5,273.7c12.3,85.8,18.4,159.3,14.3,163.4c-4.1,4.1-361.6,26.5-794.6,51.1c-433.1,24.5-819.2,49-860,55.2C124.6-3408.6,122.6-3410.7,110.3-3486.3z" />
                    <path
                      d="M9115-3474c-292.1-18.4-557.7-36.8-588.3-36.8l-59.2-2l14.3-136.9c34.7-326.9,75.6-339.1,878.4-283.9c406.5,26.6,451.4,40.9,508.7,165.5c34.7,71.5,38.8,138.9,20.4,261.5l-14.3,75.6l-114.4-2.1C9697.2-3435.2,9407.1-3453.6,9115-3474z" />
                  </g>
                </g>
              </svg>
              Choose from Library
            </button>
          </div>
        </div>
        <!-- *ngIf="filestatusatt == '1' || filestatusatt== '2'" -->
        <div *ngIf="unitForm.filename"
          class="card w-100 mt-2 p-3 d-flex flex-row align-items-center justify-content-between">
          <div>
            <!-- <img src = "../../../assets/images/pdf.png" width="20px" class = "me-2"> -->
            <span for="file" class="filename">{{unitForm.filename}}</span>
          </div>
          <div *ngIf="isStillFileUpload">
            <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
          </div>
        </div>
      </div>
      <div class="invalid-feedback" style="display: block !important;"
        *ngIf="unitForm.filename == '' && unitFormSubmitted">
        <div>
          Please choose a file
        </div>
      </div>
    </div>
    <div class="video-editor" *ngIf="unitForm.contenttype == 6">
      <!-- <div class="new-section-label pt-1 pb-1">
                <strong>Question </strong>
            </div> -->

      <div *ngIf="!isExpandWP">
        <div class="new-section-label pt-1 pb-1">
          <strong> Maximum Attempt <mat-icon style="font-size: 11px !important;"
              matTooltip="Maximum attempts student can take the quiz">help</mat-icon> </strong>
        </div>

        <div class="form-group">
          <input type="number" class="form-control form-control-sm" name="max" autofocus
            placeholder="Enter maximum attempt" [(ngModel)]="unitForm.maximumAttempt" #max="ngModel" required
            [ngClass]="{ 'is-invalid': (unitFormSubmitted && max.invalid ) }" autocomplete="off"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
          <div class="invalid-feedback" *ngIf="max.invalid || (max.dirty || max.touched) && unitFormSubmitted">
            <div *ngIf="max.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>

        <!-- <div class="d-flex align-items-center mt-2"> -->
        <div class="row g-0">
          <div class="col-6 mt-2">

            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="showfeedback" [(ngModel)]="unitForm.showfeedback">
              <label class="form-check-label" for="showfeedback">
                <span class="me-1">Allow to view feedback</span>
                <mat-icon style="font-size: 11px !important;"
                  matTooltip="Whether students can view quiz feedback or not">help</mat-icon>
              </label>
            </div>
          </div>

          <div class="col-6 mt-2">

            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="autonumbering" [(ngModel)]="unitForm.autonumbering">
              <label class="form-check-label" for="autonumbering">
                <span class="me-1">Auto Numbering</span>
                <mat-icon style="font-size: 11px !important;" matTooltip="Auto Numbering">help</mat-icon>
              </label>
            </div>
          </div>
        </div>

        <div class="row g-0">
          <div class="form-check my-2 col-6">
            <input class="form-check-input" type="radio" name="quizconfig" id="all" [(ngModel)]="question"
              [value]="true">
            <label class="form-check-label" for="all">
              All questions
            </label>
          </div>
          <div class="form-check my-2 col-6">
            <input class="form-check-input" type="radio" name="quizconfig" id="random" [(ngModel)]="question"
              [value]="false">
            <label class="form-check-label" for="random">
              Random Questions
            </label>
          </div>
        </div>
        <div class="my-2" *ngIf="!question">
          <label for="ques">
            <strong> Select How Many <mat-icon style="font-size: 11px !important;"
                matTooltip="Questions will be chosen randomly">help</mat-icon> </strong>
          </label>
          <div class="form-group">
            <input type="number" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" [disabled]="question"
              class="form-control" name="ques" id="ques" [(ngModel)]="unitForm.allquestion" #quizconfig="ngModel"
              autocomplete="off" required [ngClass]="{ 'is-invalid': (unitFormSubmitted && quizconfig.invalid ) }">
            <div class="invalid-feedback"
              *ngIf="quizconfig.invalid || (quizconfig.dirty || quizconfig.touched) && unitFormSubmitted">
              <div *ngIf="quizconfig.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
        </div>
        <div class="form-check my-2">
          <input class="form-check-input" type="checkbox" id="quizgroup" [(ngModel)]="unitForm.bygroup">
          <label class="form-check-label" for="quizgroup">
            <span class="">Group</span>
          </label>
        </div>
        <div class="my-2" *ngIf="unitForm.bygroup">
          <label for="group" class="mb-2">
            <strong> Question Count </strong>
          </label>
          <div class="form-group">
            <input type="number" class="form-control" name="group" id="group" [(ngModel)]="unitForm.groupquestionscount"
              #quizconfig="ngModel" autocomplete="off" required>
          </div>
        </div>
        <div class="form-group mb-2">
          <div class="new-section-label pt-1 pb-1">
            <strong>Time Limit (minutes)</strong>
          </div>
          <input type="number" class="form-control form-control-sm" name="targetstudytime" autofocus placeholder=""
            [(ngModel)]="unitForm.targetstudytime" #targetstudytime="ngModel" autocomplete="off"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
        </div>
        <div *ngIf="unitForm.bygroup" class="form-check my-2">
          <input class="form-check-input" type="checkbox" id="grouptime" [(ngModel)]="unitForm.grouptime">
          <label class="form-check-label" for="grouptime">
            <span class="">Individual</span>
          </label>
        </div>
        <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 6" class="mb-2" >
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
          <label class="form-check-label" for="allowdownload">
            <span class="me-1">Allow Download</span>
          </label>
        </div>
      </div> -->
      </div>
    </div>
    <!-- quiz end -->
    <div *ngIf="unitForm.contenttype == 9">
      <div class="row">
        <div class="form-group mb-2 col-6">
          <div class="new-section-label pt-1 pb-1">
            <strong> Date </strong>
          </div>
          <input type="date" class="form-control form-control-sm" name="date" [(ngModel)]="unitForm.date"
            #date="ngModel" required [ngClass]="{ 'is-invalid': (unitFormSubmitted && date.invalid ) }"
            autocomplete="off">
          <div class="invalid-feedback" *ngIf="date.invalid || (date.dirty || date.touched) && unitFormSubmitted">
            <div *ngIf="date.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="form-group mb-2 col-6">
          <div class="new-section-label pt-1 pb-1">
            <strong> Time </strong>
          </div>
          <input type="time" class="form-control form-control-sm" name="time" autofocus placeholder=""
            [(ngModel)]="unitForm.time" #time="ngModel" required
            [ngClass]="{ 'is-invalid': (unitFormSubmitted && time.invalid ) }" autocomplete="off">
          <div class="invalid-feedback" *ngIf="time.invalid || (time.dirty || time.touched) && unitFormSubmitted">
            <div *ngIf="time.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
      <div class="new-section-label pt-1 pb-1">
        <strong>URL</strong>
      </div>
      <div>
        <div class="form-group">
          <input type="text" class="form-control form-control-sm" name="classurl" autofocus placeholder="Enter a url"
            [(ngModel)]="unitForm.content" #classurl="ngModel" required
            [ngClass]="{ 'is-invalid': (unitFormSubmitted && classurl.invalid ) }" autocomplete="off"
            (keyup.enter)="addUnit()" (ngModelChange)="checkDoaminUrl()">
          <!--  -->
          <div class="invalid-feedback"
            *ngIf="classurl.invalid || (classurl.dirty || classurl.touched) && unitFormSubmitted">
            <div *ngIf="classurl.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="unitForm.contenttype == 6 || unitForm.contenttype == 7  || unitForm.contenttype == 8">
      <div *ngIf="!isExpandWP" class="new-section-label py-1">
        <strong> Description </strong>
      </div>
      <div class="invalid-feedback" style="display: block !important;"
        *ngIf="unitForm.content == '' && unitForm.contenttype != 6 && unitFormSubmitted">
        <div>
          {{allinoneService.input_err_msg}}
        </div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="isStillFileUpload"></mat-progress-bar>
      <div style="position: relative;">
        <div class="expand-icon" (click)="isExpandWP ? compressWriteUp() :expandWriteUp()">
          <i *ngIf="!isExpandWP" class='fas fa-expand-alt'></i>
          <i *ngIf="isExpandWP" class='fas fa-compress-alt'></i>
        </div>
      </div>
    </div>

    <div class="article-editor"
      *ngIf="unitForm.contenttype == 3 || unitForm.contenttype == 6 || unitForm.contenttype == 7 || unitForm.contenttype == 8">
      <div class="invalid-feedback" style="display: block !important;"
        *ngIf="unitForm.content == '' && unitFormSubmitted && unitForm.contenttype != 6 && unitForm.contenttype != 7 && unitForm.contenttype != 8">
        <div>
          {{allinoneService.input_err_msg}}
        </div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="isStillFileUpload"></mat-progress-bar>
      <div style="position: relative;">
        <div class="expand-icon" (click)="isExpandWP ? compressWriteUp() :expandWriteUp()">
          <i *ngIf="!isExpandWP" class='fas fa-expand-alt'></i>
          <i *ngIf="isExpandWP" class='fas fa-compress-alt'></i>
        </div>
        <!-- <angular-editor [(ngModel)]="unitForm.contenttype == 6 ? unitForm.quizdescription : unitForm.content"
          [config]="config"></angular-editor> -->
        <div class="wrapper">
          <select [(ngModel)]="fontSelected" (change)="onFontChange($event)">
            <option *ngFor="let option of config.fonts" [value]="option.name">{{ option.name }}</option>
          </select>
          <div #editor (mouseup)="onMouseUp($event)">
            <angular-editor [(ngModel)]="unitForm.content" [config]="config">
            </angular-editor>
          </div>
        </div>
      </div>
    </div>



    <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 8" class="my-2" >
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload" [disabled]="unitForm.contenttype != 2">
        <label class="form-check-label" for="allowdownload">
          <span class="me-1">Allow Download</span>
        </label>
      </div>
    </div> -->

    <div class="pdf-editor my-2" *ngIf="(unitForm.contenttype == 7 || unitForm.contenttype == 8) && !isExpandWP">
      <strong>Attachment</strong>
      <div class="d-flex flex-column align-items-baseline justify-content-start">
        <div class="d-flex flex-row align-items-center justify-content-start w-100">
          <div class="me-2">
            <input hidden type="file"
              (change)="unitForm.contenttype == 7 ? attFileForAssignment($event, this.unitForm.contenttype) : attFileForDisscussion($event, this.unitForm.contenttype)"
              class="inputfile" id="file" #attfileassignment>
            <button type="button" mat-button (click)="attfileassignment.click()" class="px-2"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 20 17">
                <path
                  d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                </path>
              </svg> Upload File
            </button>
          </div>
          <div>
            <button type="button" mat-button
              (click)="unitForm.contenttype == 7 ? chooseFromLibraryAttForAssign() : chooseFromLibraryAttForDisscussion()"
               class="px-2">
              <svg style="width: 20px; margin-right: 5px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                    <path
                      d="M2488.2,4153.8c-104.2-51.1-145-159.3-145-388.1v-190h1062.3h1062.3V3780c0,234.9-24.5,304.4-128.7,367.7c-61.3,38.8-108.3,40.9-923.4,40.9C2655.7,4188.5,2549.4,4184.5,2488.2,4153.8z" />
                    <path
                      d="M678.2,3669.7c-102.1-47-134.8-100.1-157.3-265.6c-12.3-85.8-18.4-159.3-14.3-163.4c4.1-4.1,343.2-26.6,753.8-51.1c410.6-24.5,796.7-49,862.1-55.2l114.4-12.3l14.3,134.8c12.3,108.3,8.2,147.1-22.5,210.4c-67.4,134.8-100.1,143-692.5,177.7c-292.1,18.4-590.4,36.8-663.9,42.9C784.5,3694.2,719.1,3688.1,678.2,3669.7z" />
                    <path
                      d="M8600.2,3647.2c-392.2-26.6-441.2-40.9-506.6-138.9c-32.7-49-40.8-112.4-34.7-269.7l4.1-104.2l700.7,47c384,24.5,702.7,44.9,706.8,44.9c24.5,4.1-16.4,288-49,337.1C9339.7,3686,9280.5,3692.2,8600.2,3647.2z" />
                    <path
                      d="M6771.9,3400c-126.7-67.4-118.5,112.4-118.5-2908.9v-2757.8h643.5h643.5V491.1c0,3031.5,8.2,2843.6-122.6,2911C7732,3447,6853.6,3445,6771.9,3400z M7756.5,2166.2v-326.8h-459.6h-459.6v326.8V2493h459.6h459.6V2166.2z" />
                    <path
                      d="M2343.1,123.4v-3145.9h1062.3h1062.3V123.4v3145.9H3405.4H2343.1V123.4z M4263.4-2225.8V-2614h-847.8h-847.8v388.1v388.1h847.8h847.8V-2225.8z" />
                    <path
                      d="M5223.5,3020.1c-196.1-8.2-382-26.6-412.6-38.8c-91.9-36.8-143-110.3-153.2-216.5c-8.2-91.9,190-4500.3,202.2-4510.5c6.1-4.1,1291,53.1,1295.1,59.2c2.1,0-42.9,1013.2-98,2249.1c-94,2053-106.2,2255.2-143,2332.9c-44.9,98-136.8,151.2-255.4,147.1C5615.7,3040.5,5419.6,3030.3,5223.5,3020.1z" />
                    <path
                      d="M484.2,2887.3c0-20.4-55.2-960.1-122.6-2089.8C294.2-332.2,214.5-1666.1,185.9-2170.7c-30.6-502.5-51.1-919.3-44.9-925.4c12.3-12.3,1711.9-118.5,1722.1-106.2c10.2,10.2,363.6,6003.8,353.4,6012c-6.1,6.1-1622,112.4-1716,112.4C492.3,2922,484.2,2905.7,484.2,2887.3z" />
                    <path
                      d="M8794.3,2870.9c-380-24.5-694.6-49-700.7-53.1c-8.2-8.2,355.5-6007.9,363.6-6018.1c2.1-2,318.7,16.3,704.8,40.9l698.7,42.9v49c0,138.9-363.6,5973.2-371.8,5977.2C9484.7,2911.8,9172.2,2893.4,8794.3,2870.9z" />
                    <path
                      d="M5438-1972.5c-269.7-14.3-506.6-26.6-525-26.6c-32.7-2-36.8-26.6-36.8-196.1v-194.1h67.4c128.7,0,1068.4,42.9,1154.2,53.1l85.8,10.2v128.7c0,71.5-6.1,159.3-12.2,194.1C6155-1927.6,6210.1-1931.7,5438-1972.5z" />
                    <path d="M6653.4-2777.4v-204.3h643.5h643.5v204.3v204.3h-643.5h-643.5V-2777.4z" />
                    <path
                      d="M5358.3-2885.7l-445.3-20.4l14.3-410.6c14.3-459.6,30.6-518.8,153.2-600.6c65.4-44.9,85.8-47,255.4-36.8c102.1,6.1,306.4,18.4,455.5,24.5c230.9,12.3,281.9,20.4,337.1,57.2c114.4,77.6,120.5,104.2,106.2,543.4c-8.2,218.6-18.4,414.7-22.5,435.1C6199.9-2853,6114.1-2853,5358.3-2885.7z" />
                    <path
                      d="M6653.4-3533.2v-245.1l69.5-77.6l69.4-75.6h504.6h504.6l69.4,75.6l69.5,77.6v245.1v245.1h-643.5h-643.5V-3533.2z" />
                    <path
                      d="M2343.1-3516.9c0-234.9,40.9-339.1,149.1-390.2c63.3-30.6,169.6-34.7,927.4-34.7c811,0,858,2,919.3,40.8c104.2,63.3,128.7,132.8,128.7,367.7v204.3H3405.4H2343.1V-3516.9z" />
                    <path
                      d="M110.3-3486.3c-32.7-198.1,12.2-330.9,132.8-390.2c81.7-38.8,1356.4-114.4,1427.9-85.8c104.2,44.9,143,108.3,165.5,273.7c12.3,85.8,18.4,159.3,14.3,163.4c-4.1,4.1-361.6,26.5-794.6,51.1c-433.1,24.5-819.2,49-860,55.2C124.6-3408.6,122.6-3410.7,110.3-3486.3z" />
                    <path
                      d="M9115-3474c-292.1-18.4-557.7-36.8-588.3-36.8l-59.2-2l14.3-136.9c34.7-326.9,75.6-339.1,878.4-283.9c406.5,26.6,451.4,40.9,508.7,165.5c34.7,71.5,38.8,138.9,20.4,261.5l-14.3,75.6l-114.4-2.1C9697.2-3435.2,9407.1-3453.6,9115-3474z" />
                  </g>
                </g>
              </svg>
              Choose from Library
            </button>
          </div>
          <!-- <button (click)="t()">test</button> -->
          <!-- <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 7 && unitForm.filename != '' "
            class="ms-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload"
                [disabled]="unitForm.filetype != 'pdf'">
              <label class="form-check-label" for="allowdownload">
                <span class="me-1">Allow Download</span>
              </label>
            </div>
          </div> -->
          <div *ngIf="unitForm.contenttype != '' && unitForm.contenttype == 7" class="ms-auto">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="allowdownload" [(ngModel)]="unitForm.allowdownload">
              <label class="form-check-label" for="allowdownload">
                <span class="me-1">Allow Download</span>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="attachmentList.length > 0  && unitForm.contenttype == 7" class="w-100">
          <div *ngFor="let i of attachmentList; let index = index" class="w-100">
            <div class="card w-100 mt-2 p-3 d-flex flex-row align-items-center justify-content-between">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div for="file" class="filename me-4">{{i.filename}}</div>

                <div class="d-flex justify-content-center align-items-center file-remove-button"
                  (click)="removeAttachmentFile(index)">
                  <i class="fa fa-trash-o"></i></div>

              </div>
              <div *ngIf="isStillFileUpload">
                <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="unitForm.filename && unitForm.contenttype == 8"
          class="card w-100 mt-2 p-3 d-flex flex-row align-items-center justify-content-between">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div for="file" class="filename me-4">{{unitForm.filename}}</div>

            <div class="d-flex justify-content-center align-items-center file-remove-button" (click)="removeFile()"><i
                class="fa fa-trash-o"></i></div>

          </div>
          <div *ngIf="isStillFileUpload">
            <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>



<mat-dialog-actions *ngIf="!isExpandWP" align="end" class="d-flex">
  <button mat-stroked-button class="cancel-button mt-2" (click)="dismiss()">Cancel</button>
  <app-button-loader (click)="addUnit()" [isStillupload]="isStillUpload" [disabled]="isStillFileUpload"
    [button_text]="action_status == 'new'? 'Add' : 'Save'">
  </app-button-loader>
</mat-dialog-actions>
