import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss'],
})
export class ClassDetailsComponent implements OnInit {
  @ViewChild('classPaginator') classPagniator!: MatPaginator;

  role: string = '';
  courseid: string = '';
  classstatus: string = '';
  daterange: string = '';
  bcname: string = 'Classes';

  displayedColumns: string[] = [
    'classname',
    'classcode',
    'startdate',
    'remark',
    'studentcounts',
    'students',
  ];

  classData: any[] = [];

  classTableData!: MatTableDataSource<any>;

  gettingClasses: boolean = false;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _SEOService: SEOService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }
    this.activatedRoute.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.classstatus = params['status'];
      this.daterange = params['daterange'];
      if (this.classstatus == 'all') {
        this.classstatus = '';
      }
    });
  }

  ngOnInit(): void {
    this.getClassInfo();
  }

  getClassInfo() {
    this.classData = [];
    this.classTableData = new MatTableDataSource(this.classData);
    this.gettingClasses = true;
    var startdate = '';
    var enddate = '';
    if (this.daterange != 'all') {
      startdate = this.daterange.substring(0, 8);
      enddate = this.daterange.substring(8, 16);
    }
    const data = {
      courseid: this.courseid,
      classstatus: this.classstatus,
      startdate: startdate,
      enddate: enddate,
    };
    this.courseService.getClassDetailReport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.classData = res.datalist;
          this.classTableData = new MatTableDataSource(this.classData);
          this.classTableData.paginator = this.classPagniator;
          this.bcname = res.coursename;
          if (this.classstatus == '001') {
            this.bcname = this.bcname + ' - Active Classes';
          } else if (this.classstatus == '002') {
            this.bcname = this.bcname + ' - Inactive Classes';
          } else if (this.classstatus == '003') {
            this.bcname = this.bcname + ' - Completed Classes';
          }
          this.gettingClasses = false;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
          this.gettingClasses = false;
        }
      },
      (err) => {
        this.gettingClasses = false;
      }
    );
  }

  goToReport() {
    this.router.navigateByUrl(`${this.role}/report`);
  }

  sortData(sort: Sort) {
    const data = this.classData;
    if (!sort.active || sort.direction === '') {
      this.classData = data;
      return;
    }

    this.classData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'classname':
          return this.allinoneService.compare(a.classname, b.classname, isAsc);
        case 'classcode':
          return this.allinoneService.compare(a.classcode, b.classcode, isAsc);
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        case 'studentcounts':
          return this.allinoneService.compare(
            a.studentcounts,
            b.studentcounts,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.classTableData = new MatTableDataSource(this.classData);
  }

  stuInfo(status: string, classid: string) {
    this.router.navigateByUrl(
      `${this.role}/report/${this.courseid}/${
        this.classstatus == '' ? 'all' : this.classstatus
      }/${this.daterange}/${classid}/${status}`
    );
  }
}
