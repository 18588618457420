import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-imagecrop',
  templateUrl: './imagecrop.component.html',
  styleUrls: ['./imagecrop.component.scss']
})
export class ImagecropComponent implements OnInit {
  croppedImage: any = "";
  fileToReturn: any;
  image: any = "";
  fileName: string = '';
  imagefiletype: any = "";
  coppedImage: boolean = false;
  imageChangedEvent: any = '';
  imagename: any;
  selectedAspectRatio: number = 1;
  aspectRatio: number = 1;
  maintainAspectRatio: boolean = true;

  aspectRatios = [
    { value: 1, label: '1:1 (Square)' },
    { value: 3 / 2, label: '3:2' },
    { value: 4 / 3, label: '4:3' },
    { value: 5 / 4, label: '5:4' },
    { value: 16 / 9, label: '16:9' },
    { value: 16 / 10, label: '16:10' },
    { value: 21 / 9, label: '21:9' },
    { value: 3 / 4, label: '3:4' },
    { value: 2 / 3, label: '2:3' },
    { value: 4 / 5, label: '4:5' },
  ];

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ImagecropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imageChangedEvent = data.image;
    this.imagename = data.name;
  }

  ngOnInit(): void { }

  onAspectRatioChange(): void {
    this.maintainAspectRatio = true;
    this.aspectRatio = this.selectedAspectRatio;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(event.base64, this.imagename);
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded() {
    // show cropper
  }

  copyImage() {
    this.image = this.fileName;
    this.imagefiletype = 'base64';
    this.coppedImage = true;
    this.dismiss();
  }

  loadImageFailed() {
    // show message
  }

  removeImage() {
    this.image = '';
    this.imagefiletype = '';
    this.coppedImage = false;
    this.imageChangedEvent = '';
  }

  dismiss() {
    var data = {
      image: this.croppedImage,
      file: this.fileToReturn
    }
    this.dialogRef.close(data);
  }
}
