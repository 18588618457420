import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../shared/services/course.service';
import { AllInOneService } from '../shared/services/all-in-one.service';
import { MessageService } from '../shared/services/message.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  role: string = "";
  useritem: any = {
    username: '',
    userid: '',
    membertype: '',
    role: '',
  }
  isImportExcel: boolean = false;
  createUserLoading: boolean = false;
  gettingmembertype: boolean = false;
  channelid: string = "";
  role_list: any[] = [
    {
      id: 1,
      name: 'Instructor',
    },
    {
      id: 2,
      name: 'Supervisor',
    },
    {
      id: 100,
      name: 'Admin',
    },
    {
      id: 3,
      name: 'HR Admin',
    },
  ];
  memberlist: any[] = [];

  dataList: any[] = [];
  importData: any;
  
  loadingFile: boolean = false;
  fileError: string = ''; 

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
  ) {
    this.channelid = this.getChanneId();
    this.route.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
    console.log(this.role);
  }

  ngOnInit(): void {
    this.getMember()
  }

  getChanneId() {
    const channelid = this.route.snapshot.params['channelid'];
    return channelid;
  }

  navigateBack() {
    history.back();
  }

  getMember() {
    this.memberlist = [];
    this.gettingmembertype = true;
    const data = {
      channelid: this.channelid,
    }
    this.courseService.getMembertype(data).subscribe(
      (category_data: any) => {
        if (category_data.returncode == '300') {
          this.memberlist = category_data.datalist;
        } else {
          this.handleError(category_data);
        }
        this.gettingmembertype = false;
      },
      (err: any) => {
        this.messageService.openSnackBar('Server error.', 'warn');
        this.gettingmembertype = false;
      }
    );
  }

  handleSubmit() {
    this.dataList = [{
      id: 1,
      username: this.useritem.username,
      email: this.useritem.userid.toLowerCase(),
      membertype: this.useritem.membertype,
      role: this.useritem.role,
      channelid: this.channelid,
    }];

    this.createUser(this.dataList);
  }

  createUser(dataList: any) {
    this.createUserLoading = true;
    const data = {
      data: dataList,
    };

    this.courseService.createKhubUser(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.createUserLoading = false;
          this.useritem.username = "";
          this.useritem.userid = "";
          this.messageService.openSnackBar("Created successfully!", 'success');
        } else {
          this.createUserLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.createUserLoading = false;
        this.messageService.openSnackBar("Something went wrong!", 'warn');
      }
    );
  }

  handleFileInput(event: any): void {
    this.loadingFile = true;
    this.fileError = '';
    const file: File = event.target.files[0];
    if (file) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.processExcelData(data);
        } catch (error) {
          console.error("import excel err >>", error)
          this.fileError = 'Failed to process the file. Please check the file format and try again.';
        } finally {
          this.loadingFile = false;
        }
      };
      reader.readAsBinaryString(file);
    } else {
      this.loadingFile = false;
      this.fileError = 'No file selected.';
    }
  }

  processExcelData(data: any[]): void {
    console.log('first row', data[0])
    if (data.length === 0 || data[0].length < 1 || data[0][0] != 'username' || data[0][1] != "userid") {
      this.fileError = 'Invalid file format. Please ensure the file has the correct headers.';
      return;
    } else if (!this.useritem.membertype) {
      this.fileError = 'Please select member type!';
      return;
    }
    console.log('create user excel data >>', data)

    this.dataList = data.slice(1).map((row: any, index: number) => ({
      id: index + 1,
      username: row[0] || '',
      email: row[1] && row[1].toLowerCase() || '',
      channelid: this.channelid || '',
      membertype: this.useritem.membertype || '',
      role: this.useritem.role || '',
    }));

    if (this.dataList.some(item => !item.username || !item.email || !item.channelid || !item.membertype)) {
      this.fileError = 'Some rows in the file have missing data. Please check and try again.';
    } else {
      this.fileError = '';
      this.createUser(this.dataList);
      console.log('datalist >>', this.dataList)
    }
  }

  downloadSample() {
    window.open(this.allinoneService.fileurl + 'import/UserTemplate.xlsx', '_blank');
  }

  handleError(response: any) {
    if (response.returncode == '210') {
      setTimeout(() => {
        this.router.navigateByUrl('login');
        this.allinoneService.logout();
      }, 2000);
    } else {
      this.messageService.openSnackBar(response.status || response.message, 'warn');
    }
  }

}
