<app-breadcrumb [s_breadcrumb]="'Reports'" [t_breadcrumb]="'Attendance Details'" (emit)="goReport()"></app-breadcrumb>
<!-- <button (click)="test()" >test</button> -->
<div class="content">

  <div class="d-flex align-items-center justify-content-end mb-3">
    <p class="mb-0 p-0 me-2">Total :<b> {{attendanceData.length}}</b></p>
    <div class="input-group" style="width: 260px !important;">
      <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
      <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
        *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4;color: #00bcd4;">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <button color="primary" class="btn new-chl ms-2" (click)="downloadSheet()">
      <img class="excel-icon" src="../../../assets/images/excel.png">
      Export
    </button>
    <button class="btn new-chl ms-2" matTooltip="Refresh" (click)="refresh()" type="button">
      <i class="fa fa-refresh"></i>
    </button>
  </div>

  <table mat-table #table [dataSource]="attendanceTableList" matSort="categorysort" style="width: 100%;"
    (matSortChange)="sortAttendanceTable($event)">

    <!-- Checkin Date Column -->
    <ng-container matColumnDef="checkindate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="checkindate">Check In Date</th>
      <td mat-cell *matCellDef="let element">
        {{allinoneService.formatDBToShow(element.date)}}
      </td>
    </ng-container>

    <!-- Checkin Time Column -->
    <ng-container matColumnDef="checkintime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="checkintime">Check In Time</th>
      <td mat-cell *matCellDef="let element">
        {{element.starttime}}
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description
      </th>
      <!-- <td mat-cell *matCellDef="let element" style="text-align: center;">{{element.studentcounts}}</td> -->
      <td mat-cell *matCellDef="let element">
        {{element.description}}
      </td>
    </ng-container>


    <!-- Chat Group Column -->
    <ng-container matColumnDef="chatgroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="chatgroup">
        Chat Group
      </th>
      <!-- <td mat-cell *matCellDef="let element" style="color: var(--main-color); text-align: center">
          {{element.totaltime}} </td> -->
      <td mat-cell *matCellDef="let element">
        {{element.groupname}}
      </td>
    </ng-container>

    <!-- Shared Time Column -->
    <ng-container matColumnDef="sharedtime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="sharedtime">Shared Time</th>
      <td mat-cell *matCellDef="let element">
        {{element.sharedtime}}
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="attendanceDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: attendanceDisplayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td colspan="5">
        <span class="nodata" *ngIf="!isLoading">Empty</span>
        <span class="nodata" *ngIf="isLoading">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </span>
      </td>
    </tr>

  </table>
</div>