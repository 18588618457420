<app-breadcrumb [s_breadcrumb]="'Reports'" [t_breadcrumb]="bcname" (emit)="goToReport()"></app-breadcrumb>
<div class="content mt-2">
  <table mat-table #table [dataSource]="classTableData" matSort style="width: 100%;"
      (matSortChange)="sortData($event)">

      <!-- Course Name Column -->
      <ng-container matColumnDef="classname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Class Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.classname}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="classcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element">
          {{element.classcode}}
        </td>
      </ng-container>

      <!-- Startdate Column -->
      <ng-container matColumnDef="startdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">
          {{allinoneService.formatDBToShow(element.startdate)}}
        </td>
      </ng-container>

      <!-- Total Classes Column -->
      <ng-container matColumnDef="remark">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Remark</th>
        <td mat-cell *matCellDef="let element">
          {{element.remark}}
        </td>
      </ng-container>

      <!-- Total Classes Column -->
      <ng-container matColumnDef="studentcounts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Students</th>
        <td mat-cell *matCellDef="let element" class="totalstu" (click)="stuInfo('all', element.classid)">
          {{element.studentcounts}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="students">
        <th mat-header-cell *matHeaderCellDef>Student Status</th>
        <td mat-cell *matCellDef="let element" style="width: 350px !important;">
          <ng-container *ngIf="(element.notstarted > 0 || element.inprogress > 0 || element.complete > 0);else emptyTemplate;">
            <div class = "d-flex align-items-center">
              <span class="priority priorityprimary" *ngIf="element.inprogress > 0" (click)="stuInfo('002', element.classid)">
                In Progress - {{element.inprogress}}
              </span>
              <div class="priority prioritygray" *ngIf="element.notstarted > 0" (click)="stuInfo('001', element.classid)">
                Not Started - {{element.notstarted}}
              </div>
              <div class="priority prioritygreen" *ngIf="element.complete > 0" (click)="stuInfo('003', element.classid)">
                Completed - {{element.complete}}
              </div>
            </div>
          </ng-container>
          <ng-template #emptyTemplate>
            -
          </ng-template>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="6">
          <span class="nodata" *ngIf="!gettingClasses">Empty</span>
          <span class="nodata" *ngIf="gettingClasses">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #classPaginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
</div>