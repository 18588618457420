import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { AllInOneService } from '../../services/all-in-one.service';
import { CourseService } from '../../services/course.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-two',
  templateUrl: './file-upload-two.component.html',
  styleUrls: ['./file-upload-two.component.scss']
})
export class FileUploadTwoComponent implements OnInit, OnDestroy {

  @ViewChild('choosefile') choosefile: any;

  isError: boolean = false;
  saveLoading: boolean = false;
  reupload: boolean = false;

  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  channelid: string = '';
  learningPathid: string = '';
  unitid: string = '';

  courseid: string = '';
  classid: string = '';

  domainid: string = '';
  errorData: any;
  type: string = ''; // 001 - member(instructor) import // 002 classes dropbox

  // appid = this.allinOneService.getAppId();
  // userid = this.allinOneService.getKunyekUserId();

  allowedFiletype = ['xlsx'];

  progress: number = 0;

  isChecking: boolean = false;

  // Import
  importStatus: string = '002'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;
  importData: any;
  statusInterval: any;
  submitted: boolean = false;

  constructor(
    private messageService: MessageService,
    private allinoneService: AllInOneService,
    private courseService: CourseService,
    private allinOneService: AllInOneService,
    public dialogRef: MatDialogRef<FileUploadTwoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.isError = data.isError;
    console.log('Your data');

    console.log(data);

    if (data) {
      this.channelid = data.channelid;
      this.unitid = data.unitid;
      this.type = data.type;
      this.courseid = data.courseid;
    }

    // this.errorData = data.errorData;
    // this.type = data.type

    // this.classid = data.classid
    // this.learningPathid = data.learningpathid
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.statusInterval);
  }

  test() {
    // console.log(this.importStatus);
    this.isChecking = true;

  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      unitid: this.unitid,
      type: '004',
      channelid: this.channelid
    };
    this.courseService.getImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // if (
          //   (this.importStatus == '002' || this.importStatus == '003') &&
          //   res.status != this.importStatus
          // ) {
          //   this.refresh();
          // }
          this.importStatus = res.status;
          this.importData = res;
          this.errorData = this.importData;
          if (this.importStatus == '004') {
            this.isError = true;
          }
          if (this.submitted && this.importStatus == '001') {
            this.messageService.openSnackBar('Import Successful.', 'success')
            this.dialogRef.close({ success: true });
          }
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  uploadfile() {
    if (!this.fileSelected) {
      return this.messageService.openSnackBar('Please upload a file.', 'warn');
    }
    this.saveLoading = true;
    // this.dialogRef.disableClose = true;
    var filename = this.allinoneService.getCurrentDateToDB() + this.allinoneService.getCurrentTimeToDB2() + '_' + this.filename;


    this.allinoneService
      .fileUploadToS3WithPathForImageAndProgress(
        this.fileSelected,
        filename,
        'importfiles/origin'
      )
      .on('httpUploadProgress', (progress) => {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
        this.progress = progressPercentage;
      })
      .send((err: any, data: any) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          //Fail
          this.progress = 0;
          this.saveLoading = false;
          // this.dialogRef.disableClose = false;

          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          return false;
        }
        this.submitImport(filename);
        return true;
      });
  }

  submitImport(filename: string) {
    var type = '';
    this.submitted = true;
    if (this.type == '001') {
      type = '001';
    } else if (this.type == '004') {
      type = '004';
    }
    var data;
    if (type == '001') {
      data = {
        channelid: this.channelid,
        filename: filename,
        type: type,
        importstatusid: this.importData.importstatusid,
      }
    }
    else if (type == '004') {
      data = {
        importstatusid: this.importData.importstatusid,
        channelid: this.channelid,
        unitid: this.unitid,
        type: '004',
        filename: filename,
        courseid: this.courseid
      }
    }
    // *** create fun

    var fun = this.courseService.quizQuestionImport(data);
    if (this.type == '001') {
      fun = this.courseService.addMemberImport(data);
    } else if (this.type == '004') {
      fun = this.courseService.quizQuestionImport(data);
    }

    console.log("Your data");
    console.log(data);

    fun.subscribe((res: any) => {
      if (res.returncode == '300') {
        this.isChecking = true;
        this.importStatus = '002'
        clearInterval(this.statusInterval);
        this.getImportStatus();
        this.statusInterval = setInterval(() => {
          if (
            this.importStatus != '001' &&
            this.importStatus != '005' &&
            this.importStatus != '000'
          ) {
            this.getImportStatus();
          }

        }, 5000);
        this.isChecking = false;

        // if(this.importStatus == '001') {
        //   this.dialogRef.close({success : true});
        // }

      }
      else {
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.saveLoading = false;
      // this.dialogRef.disableClose = false;
    }, err => {
      this.saveLoading = false;
      // this.dialogRef.disableClose = false;
    })
  }

  cancelImport() {
    this.saveLoading = true;
    // this.dialogRef.disableClose = true;
    var data;
    if (this.type == '001') {
      data = {
        channelid: this.channelid,
        importstatusid: this.errorData.importstatusid,
      };
    }
    else if (this.type == '004') {
      data = {
        importstatusid: this.errorData.importstatusid,
        channelid: this.channelid,
        unitid: this.unitid,
      }
    }
    var fun = this.courseService.updateMemberImport(data);
    // if
    fun.subscribe((res: any) => {
      if (res.returncode == '300') {
        this.dialogRef.close(true);
      }
      else {
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.saveLoading = false;
      // this.dialogRef.disableClose = false;
    }, err => {
      this.saveLoading = false;
      // this.dialogRef.disableClose = false;
    })
  }

  onDismiss() {
    if (this.saveLoading) {
      return;
    }
    this.dialogRef.close();
  }

  reuploadFile() {
    this.reupload = true;
  }

  downloadSample() {
    if (this.type == '001') {
      window.open(this.allinOneService.fileurl + 'import/ChannelMemberSampleTemplate.xlsx', '_blank');
    }
    else if (this.type == '004') {
      // https://elearningbucket.s3.ap-southeast-1.amazonaws.com/import/QuizTemplate.xlsx
      window.open(this.allinOneService.fileurl + 'import/QuizTemplate.xlsx', '_blank');
    }
  }

  //1 - origin, 2- error
  downloadFile(type: number) {
    if (type == 1) {
      window.open(this.errorData.originfilelink, '_blank');
    } else {
      window.open(this.errorData.errorfilelink, '_blank');
    }
  }

}
