<div class="content">
  <span class="title">Courses</span>
  <div class="course-header mb-2 d-flex justify-content-between align-items-center">
    <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-3">
      <div>
        <button color="primary" class="btn new-chl new-course me-auto" routerLink="/instructor/courses/course-name">
          <i class="fa fa-plus" aria-hidden="true"></i>
          New Course
        </button>
      </div>

      <div class="d-flex flex-wrap align-items-center">
        <div class="me-2">
          Total :<b> {{ showcourses.filteredData.length }}</b>
        </div>

        <div class="input-group me-2" style="width: 260px !important">
          <input class="form-control" type="search" placeholder="Search" (focus)="isFocus = true"
            (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" (ngModelChange)="searchItem()" />
        </div>
        <button class="btn new-chl" matTooltip="Refresh" (click)="refresh()" type="button">
          <i class="fa fa-refresh"></i>
        </button>

        <div class="line"></div>


        <div class="filtter-btn align-items-center me-2" [matMenuTriggerFor]="channelfilter">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Channel&nbsp;:&nbsp;{{ selectedChannelName.length > 20 ? (selectedChannelName |
            slice:0:20)+'...' : selectedChannelName}}
            <mat-menu #channelfilter="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let chl of channels" (click)="channelChnage(chl)">
                {{ chl.name }}
              </button>
            </mat-menu>
            <span *ngIf="gettingmyChannel" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
              aria-hidden="true"></span>
          </button>
        </div>

        <div class="filtter-btn align-items-center me-2" [matMenuTriggerFor]="coursetypefilter">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Course Type&nbsp;:&nbsp;{{ selectedCourseTypeName.length > 20 ? (selectedCourseTypeName |
            slice:0:20)+'...' : selectedCourseTypeName}}
            <mat-menu #coursetypefilter="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let ct of courseTypeFilter" (click)="courseTypeChange(ct)">
                {{ ct.name }}
              </button>
            </mat-menu>
            <!-- <span *ngIf="gettingmyChannel" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span> -->
          </button>
        </div>

        <!-- btn btn-secondary dropdown-toggle advanced-filter-btn -->
        <div class="filtter-btn align-items-center" [matMenuTriggerFor]="statusfilter"
          [ngClass]="{'me-2' : selectedChannel != 'all'}">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ selectedStatusName.length > 20 ? (selectedStatusName |
            slice:0:20)+'...' : selectedStatusName}}
            <mat-menu #statusfilter="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let st of statusFilterList" (click)="statusChange(st)">
                {{ st.name }}
              </button>
            </mat-menu>
            <!-- <span *ngIf="gettingmyChannel" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span> -->
          </button>
        </div>

        <div *ngIf="selectedChannel != 'all'" class="filtter-btn align-items-center"
          [matMenuTriggerFor]="categoryfilter">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Category&nbsp;:&nbsp;{{ selectedCategoryName.length > 20 ? (selectedCategoryName |
            slice:0:20)+'...' : selectedCategoryName}}
            <mat-menu #categoryfilter="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let category of categoryFilterList" (click)="categoryChange(category)">
                {{ category.categoryname }}
              </button>
            </mat-menu>
            <span *ngIf="gettingCategory" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
              aria-hidden="true"></span>
          </button>
        </div>


      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
    <!-- <mat-spinner [strokeWidth]="10" style="zoom: 0.4"></mat-spinner> -->
    <mat-spinner [strokeWidth]="4" [diameter]="40"></mat-spinner>

  </div>

  <div style="overflow: auto">

    <table mat-table matSort [dataSource]="showcourses" (matSortChange)="announceSortChange($event)"
      class="mat-elevation-z1" style="width: 100%" *ngIf="!isLoading && courses.length > 0">
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goToManage(element.courseid)">
          <img *ngIf="element.courseimage == ''" [src]="allinoneService.defaultImg" class="course-listing-image" />
          <img *ngIf="element.courseimage != ''" [src]="allinoneService.imgurl + element.courseimage"
            class="course-listing-image" />
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
          Course Name
        </th>
        <td mat-cell *matCellDef="let element" (click)="goToManage(element.courseid)">
          <div class="coursename-container">
            <div class="coursename">{{ element.coursename }}</div>
            <div class="instructorname">{{ element.instructorname}}</div>
            <div class="institute">{{element.institute}}</div>
          </div>
        </td>
      </ng-container>

      <!-- studentcount Column -->
      <ng-container matColumnDef="studentcount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by studentcount">
          Total Students
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color)" (click)="goToManage(element.courseid)">
          {{ element.studentcount }}
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Type</th> -->
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element" [style.color]="element.type == 'HIDDEN' ? 'red' : 'var(--main-color)'"
          (click)="goToManage(element.courseid)">
          {{ element.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="duplicate">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; padding-right: 0 !important"></th>
        <td mat-cell *matCellDef="let element; let i = index" style="text-align: center; padding-right: 0 !important">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="" style="width: 20px !important">
            <mat-icon style="font-size: 20px;">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="duplicateCourse(element)" mat-menu-item>Duplicate</button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; padding-right: 0 !important"></th>
        <td mat-cell *matCellDef="let element; let i = index" style="text-align: center; padding-right: 0 !important">
          <button mat-flat-button color="primary" (click)="gotoClass(element)" style="margin-left: 5px">
            View Class
          </button>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [style.display]="paginate == true ? 'block' : 'none'" #paginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <div style="text-align: center; padding: 1rem 0" *ngIf="courses.length === 0 && !isLoading">
    No courses found
  </div>
</div>