import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-class-section-schedule',
  templateUrl: './class-section-schedule.component.html',
  styleUrls: ['./class-section-schedule.component.scss'],
})
export class ClassSectionScheduleComponent implements OnInit {
  saveLoading: boolean = false;
  classid: string = '';
  courseid: string = '';
  sectionid : string = '';
  type : string = '';

  // Date and time

  timeFormat = 'hh:mm';

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  currentTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();

  submitForm = {
    startdate: this.currentDate,
    starttime: this.currentTime,
    enddate: this.currentDate,
    endtime: this.currentTime,
  };

  constructor(
    private dialogRef: MatDialogRef<ClassSectionScheduleComponent>,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data) {
      (this.classid = data.classid), (this.courseid = data.courseid), (this.sectionid = data.sectionid), (this.type = data.type);
      this.submitForm.startdate = data.sd
        ? allinoneService.formatDBToShowInEdit(data.sd)
        : '';
      this.submitForm.enddate = data.ed
        ? allinoneService.formatDBToShowInEdit(data.ed)
        : '';
      this.submitForm.starttime = data.st
        ? allinoneService.f12to24(data.st)
        : '';
      this.submitForm.endtime = data.et ? allinoneService.f12to24(data.et) : '';
    }
  }

  ngOnInit(): void { }

  saveData() {
    if (this.submitForm.startdate != '' && this.submitForm.starttime == '') {
      this.messageService.openSnackBar('Start time cannot be blank.', 'warn');
      return;
    }
    if (this.submitForm.startdate == '' && this.submitForm.starttime != '') {
      this.messageService.openSnackBar('Start date cannot be blank.', 'warn');
      return;
    }

    if (this.submitForm.enddate != '' && this.submitForm.endtime == '') {
      this.messageService.openSnackBar('End time cannot be blank.', 'warn');
      return;
    }
    if (this.submitForm.enddate == '' && this.submitForm.endtime != '') {
      this.messageService.openSnackBar('End date cannot be blank.', 'warn');
      return;
    }
    this.saveLoading = true;
    var data = {
      courseid: this.courseid,
      sectionid : this.type == 'single' ? this.sectionid : '',
      classid: this.classid,
      startdate: this.submitForm.startdate
        ? this.allinoneService.formatDate(this.submitForm.startdate)
        : '',
      enddate: this.submitForm.enddate
        ? this.allinoneService.formatDate(this.submitForm.enddate)
        : '',
      starttime: this.submitForm.starttime
        ? this.allinoneService.f24to12(this.submitForm.starttime)
        : '',
      endtime: this.submitForm.endtime
        ? this.allinoneService.f24to12(this.submitForm.endtime)
        : '',
    };
    // console.log(data);
    // return;

    // this.dialogRef.close({
    //   startdate: data.startdate,
    //   enddate: data.enddate,
    //   starttime: data.starttime,
    //   endtime: data.endtime,
    // });

    this.courseService.addCourseSectionSchedule(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dialogRef.close({
            success: true,
            sectionid : data.sectionid,
            startdate: data.startdate,
            enddate: data.enddate,
            starttime: data.starttime,
            endtime: data.endtime,
          });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err: any) => {
        this.saveLoading = false;
      }
    );
  }

  startDateChange() {
    if (this.submitForm.enddate < this.submitForm.startdate) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }

  startClear() {
    this.submitForm.startdate = '';
    this.submitForm.starttime = '';
  }

  endClear() {
    this.submitForm.enddate = '';
    this.submitForm.endtime = '';
  }

  cancel() {
    this.submitForm = {
      startdate: '',
      starttime: '',
      enddate: '',
      endtime: '',
    };
    this.dialogRef.close();
  }
}
