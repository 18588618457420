<h3 mat-dialog-title><strong>New Folder</strong></h3>

<form [formGroup]="newFolderForm" (ngSubmit)="submitNewFolder()">
    <input type="text" class="form-control form-control-sm" placeholder="Folder Name" formControlName="name" id="name" name="name" [ngClass]="{ 'is-invalid': newFolderFormSubmitted && newFolderFormControl.classcode.errors }" #inputName>

    <div *ngIf="newFolderFormSubmitted && newFolderFormControl.classcode.errors" class="invalid-feedback">
        <div *ngIf="newFolderFormControl.classcode.errors?.required">
            {{allinoneService.input_err_msg}}
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isSaving">
            Cancel
        </button>
        <app-button-loader [button_text]="'Create'" [isStillupload]="isSaving">
        </app-button-loader>
    </div>
</form>