import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
})
export class TemplatePreviewComponent implements OnInit, AfterViewInit {
  elementList: any[] = [];
  backgroundImg: any = '';
  padding: any = 0;

  constructor(
    private dialogRef: MatDialogRef<TemplatePreviewComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.elementList = data.elementList;
    this.backgroundImg = data.backgroundImg;
    this.padding = data.padding;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const app = document.getElementById('template-bg-preview');
    app!.style.fontSize = (app!.clientWidth / 50).toString() + 'px';
    if (this.backgroundImg) {
      const img = document.createElement('img');
      img.id = "bg-img";
      img.crossOrigin = '*';
      img.src = this.backgroundImg;
      img.style.width = '100%';
      img.style.height = 'auto';
      img.style.objectFit = 'cover';
      app?.appendChild(img);
    }
    this.elementList.map((item: any, index: number) => {
      const div = document.createElement('div');
      div.id = index.toString();
      div.textContent = item.content
        .toString()
        .replaceAll('[[name]]', 'John Doe')
        .replaceAll('[[course]]', 'Digital Financial Services and FinTech')
        .replaceAll('[[date]]', this.datePipe.transform(new Date(), 'dd MMMM, yyyy' ))
        ;
      div.style.position = 'absolute';
      div.style.fontSize = item.fontSize + 'em';
      div.style.top = item.top + '%';
      div.style.fontWeight = item.fontWeight;
      div.style.fontFamily = item.fontFamily;
      div.style.color = item.color;
      div.style.paddingLeft = this.padding + 'em';
      div.style.paddingRight = this.padding + 'em';
      div.style.lineHeight =  item.lineHeight ? item.lineHeight : '1';
      if (item.isCenter) {
        div.style.width = '100%';
        div.style.textAlign = 'center';
      } else {
        div.style.left = item.left + '%';
      }

      app?.appendChild(div);
    });
  }
}
