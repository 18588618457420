<div *ngIf="LTName != ''">
    <app-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'Learning Track'" [a_breadcrumb]="LTName" [t_breadcrumb]="'Members'"
        (emit)="goToLT()" (emit1)="goToLT()"></app-breadcrumb>
    <app-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'Learning Track'" [a_breadcrumb]="LTName" [b_breadcrumb]="'Members'"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="goToLT()" (emit1)="goToLT()"
        (emit2)="cancel()"></app-breadcrumb>
</div>
<div class="container-fluid mt-2 mb-2 student-container">
    <div *ngIf="!isAdding">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="d-flex flex-wrap">
                <button (click)="addUser()" class="btn new-chl me-2 my-3">
                    <i class="fa fa-plus" aria-hidden="true"></i> Add Member
                </button>
                <div class="my-3">
                    <button type="button" class="btn btn-custom" title="Import from Excel" (click)="importSheet()"
                        *ngIf="importStatus == '001' || importStatus == '005'">
                        <img class="excel-icon" src="../../../assets/images/excel.png">
                        Import
                    </button>
                    <div class="d-flex align-items-center" *ngIf="importStatus == '002' || importStatus == '003' ">
                        <div class="btn-group" role="group">
                            <button type="button" class="btn btn-outline-black" disabled>
                                <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002' "></i>
                                <img src="../../../assets/images/upload.gif" width="20px" class="me-2"
                                    *ngIf="importStatus == '003' " alt="">
                                {{ importStatus == '002' ? 'Checking' : 'Uploading' }}
                            </button>
                            <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
                                <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
                            </button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-outline-danger" (click)="importSheet(true)"
                        *ngIf="importStatus == '004'" style="width : auto;">
                        <i class="fas fa-exclamation-circle me-2"></i>
                        Import Error
                    </button>
                </div>
                <div class="my-3">
                    <button color="primary" style="height: 32.5px;" class="d-flex align-items-center justify-content-center me-auto" (click)="downloadSheet()" mat-flat-button>
                        <img class="excel-icon" src="../../../assets/images/excel.png">
                        Export
                    </button>
                </div>
            </div>
            <!-- <button color="primary" class="my-3 me-1" (click)="importMembers()" mat-flat-button>
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Import
            </button>
            <button color="primary" class="my-3 me-auto " (click)="exportMembers()" mat-flat-button>
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Export
            </button> -->
            <div class="d-flex align-item-center ms-3">
                <div class="me-2 d-flex align-items-center">
                    Total :&nbsp;<b> {{userList.length}}</b>
                </div>


                <div class="input-group mt-3 me-1 mb-3" style="width: 260px !important;">
                    <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                        (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                    <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                        *ngIf="searchText" (click)="clear()"
                        style="border: 1px solid var(--main-color);color: var(--main-color);">
                        <i class="fa fa-times"></i>
                    </button>
                </div>

                <button class="btn new-chl mt-3 mb-3" matTooltip="Refresh" (click)="refresh()" type="button">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
        <div style="overflow: auto;">
            <table mat-table #table [dataSource]="showUser" multiTemplateDataRows matSort
                (matSortChange)="sortUser($event)" class="mat-elevation-z1" style="width: 100%;">

                <ng-container matColumnDef="userid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by userid">
                        User ID
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{element.userid}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by username">
                        User Name
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{element.username}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="startdate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by startdate">
                        Start Date
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{ element.startdate ? allinoneService.formatDBToShow(element.startdate) : ''}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="enddate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by enddate">
                        End Date
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{ element.enddate ? allinoneService.formatDBToShow(element.enddate) :
                        ''}}
                    </td>
                </ng-container>

                <!-- Enrolled -->
                <!-- In Progress -->
                <!-- Completed -->

                <ng-container matColumnDef="enrolled">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by enrolled">
                        Enrolled
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{element.enrolledcourse}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="inprogress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by in progress">
                        In Progress
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{element.inprogress}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="completed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by in completed">
                        Completed
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;" (click)="goToCoursesByUserAnalytic(element.userid)">
                        {{element.completedcourse}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 120px; padding: 0;"> Action
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index;"
                        style="text-align: center; border: none; padding: 0;">
                        <!-- <button class="btn btn-outline-primary rounded-circle" matTooltip="Edit" >
                                    <i class="fa fa-edit"></i>
                                </button> -->
                        <button class="btn btn-outline-primary rounded-circle" matTooltip="Edit"
                            (click)="editUser(element)" style="font-size: 14px;width:37px;height: 35px;">
                            <i class="fa fa-edit"></i>
                        </button>
                        <button class="btn btn-outline-danger rounded-circle" matTooltip="Delete"
                            (click)="deleteUser(element)"
                            style="font-size: 14px;width:37px;height: 35px;margin-left: 5px;">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedUserColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedUserColumns;">
                </tr>
                <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
                <tr class="mat-row" *matNoDataRow>
                    <td colspan="8">
                        <span class="nodata" *ngIf="!isLoading">Empty</span>
                        <span class="nodata" *ngIf="isLoading">
                            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                        </span>
                    </td>
                </tr>
            </table>
            <!-- <div  *ngIf="paginate == true"> -->
            <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
                [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <!-- </div> -->
        </div>
    </div>
    <div *ngIf="isAdding">
        <div class="card card-body my-card mb-3 p-3">
            <div class="row">
                <div class="mb-3 col-md-12">
                    <label for="userid" class="form-label">User ID</label>
                    <input class="form-control" id="userid" [(ngModel)]="submitForm.userid" name="userid"
                        #userid="ngModel" [ngClass]="{'is-invalid' : formSubmitted && userid.invalid}"
                        [disabled]="isEditing" required />
                    <div class="invalid-feedback"
                        *ngIf="userid.invalid || ((userid.dirty || userid.touched) && formSubmitted)">
                        <div *ngIf="userid.errors?.required">
                            {{ allinoneService.input_err_msg }}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="startdate" class="form-label">Start Date</label>
                    <input type="date" class="form-control" id="startdate" name="startdate"
                        [(ngModel)]="submitForm.startdate"
                        [ngClass]="{ 'is-invalid': formSubmitted && startdate.invalid }" #startdate="ngModel" required>
                    <div class="invalid-feedback"
                        *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) && formSubmitted">
                        <div *ngIf="startdate.errors?.required && formSubmitted">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="enddate" class="form-label">End Date</label>
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="submitForm.enddate"
                        [ngClass]="{ 'is-invalid': formSubmitted && enddate.invalid }" #enddate="ngModel" required>
                    <div class="invalid-feedback"
                        *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && formSubmitted">
                        <div *ngIf="enddate.errors?.required && formSubmitted">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="col-md-12  mb-3">
                    <label for="chl_name" class="form-label">Remark</label>
                    <textarea type="text" rows="3" [(ngModel)]="submitForm.remark" name="remark" class="form-control"
                        id="remark" autocomplete="off"></textarea>
                </div>
            </div>
        </div>
        <div class="actions-button">

            <button style="width: 80px !important;" mat-stroked-button class="mt-2 cancel-button" type="button"
                (click)="cancel()" [disabled]="saveLoading">
                Cancel
            </button>

            <app-button-loader [button_text]="'Save'" (click)="saveData()" [isStillupload]="saveLoading">
            </app-button-loader>

        </div>
    </div>
</div>