<div>
  <h2><strong>{{isEditing ? 'Edit Class' : 'New Class'}}</strong></h2>
</div>
<form [formGroup]="classForm" (ngSubmit)="sumbitClass()">
  <div class="form-group" class="mb-3">
    <label for="classcode" class="mb-2">
      Class Code
    </label>
    <input type="text" class="form-control form-control-sm" placeholder="CS 1011" formControlName="classcode"
      id="classcode" name="classcode"
      [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.classcode.errors }">

    <div *ngIf="classFormSubmitted && classFormControl.classcode.errors" class="invalid-feedback">
      <div *ngIf="classFormControl.classcode.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="form-group" class="mb-3">
    <label for="classname" class="mb-2">
      Class Name
    </label>
    <input type="text" class="form-control form-control-sm" placeholder="Class 1" formControlName="classname"
      id="classname" name="classname"
      [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.classname.errors }">

    <div *ngIf="classFormSubmitted && classFormControl.classname.errors" class="invalid-feedback">
      <div *ngIf="classFormControl.classname.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="form-group" class="mb-3">
    <label for="classname" class="mb-2">
      Start Date
    </label>
    <!-- <input type="date" class="form-control form-control-sm" placeholder="" formControlName="startdate" id="startdate"
      name="startdate" [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.startdate.errors }"> -->
      <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly formControlName="startdate" name="startdate"
            style="min-width: 200px;" required [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.startdate.errors }">

    <div *ngIf="classFormSubmitted && classFormControl.startdate.errors" class="invalid-feedback">
      <div *ngIf="classFormControl.startdate.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="form-group" class="mb-3">
    <label for="status" class="mb-2">
      Status
    </label>
    <select class="form-select" name="status" required formControlName="status">
      <option value="001">
        Active
      </option>
      <option value="002">
        Inactive
      </option>
      <option value="003">
        Completed
      </option>
    </select>
  </div>
  
    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" value="" id="hidesections" formControlName="hidesections">
      <label class="form-check-label" for="hidesections">
        Hide Sections
      </label>
  </div>

  <!-- <div class="form-group" class="mb-3">
    <label for="group" class="mb-2">
      Group
    </label>
    <select class="form-select" name="group" required formControlName="group">
      <option value="">-</option>
      <option *ngFor="let gp of groups" [value]="gp.channelid">{{gp.channelname}}</option>
    </select>
    <mat-progress-bar mode="indeterminate" *ngIf="gettingGroups"></mat-progress-bar>
  </div> -->

  <div class="form-group" class="mb-3">
    <label for="remark" class="mb-2">
      Remark
    </label>
    <textarea class="form-control" rows="3" placeholder="Enter Remark" formControlName="remark" id="remark"
      name="remark"></textarea>
  </div>

  <div class="actions-button">
    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isLoading">
      Cancel
    </button>

    <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading">
    </app-button-loader>

  </div>

</form>