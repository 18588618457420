import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-create-sub-category',
  templateUrl: './create-sub-category.component.html',
  styleUrls: ['./create-sub-category.component.scss'],
})
export class CreateSubCategoryComponent implements OnInit {
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  gettingCategories: boolean = false;
  isEditing: boolean = false;
  gettingCategory: boolean = false;

  categories: any[] = [];

  channelid: string = '';

  category: any = {
    categoryid: '',
    subcatid: '',
    subcatname: '',
    order: 0,
  };

  constructor(
    private dialogRef: MatDialogRef<CreateSubCategoryComponent>,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.categories = data.categories;
    this.channelid = data.channelid;
    if (data.category) {
      this.isEditing = true;
      this.category.subcatid = data.category.categoryname;
      this.category.subcatname = data.category.subcategoryname;
      this.category.order = data.category.sortkey;
      this.category.categoryid = data.category.categoryid;
    }
  }

  ngOnInit(): void {
    if (this.categories.length == 0) {
      this.getCategory();
    } else {
      if (this.category.categoryid == '') {
        this.category.categoryid = this.categories[0].categoryid;
      }
    }
  }

  getCategory() {
    this.gettingCategory = true;

    this.courseService.getCategoryList().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categories = res.data;
          if (this.category.categoryid == '') {
            this.category.categoryid = this.categories[0].categoryid;
          }
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
        this.gettingCategory = false;
      },
      (err) => {
        this.gettingCategory = false;
      }
    );
  }

  onDismiss() {
    this.dialogRef.close({ success: false, data: [] });
  }

  submitSubcat() {
    this.formSubmitted = true;
    if (this.category.subcatname.toString().trim() == '') {
      return;
    }
    if (this.category.order < 0) {
      return this.messageService.openSnackBar(
        'Order should be at least 0.',
        'warn'
      );
    }
    this.isLoading = true;
    const data = {
      categoryid: this.category.categoryid, 
      categoryname: this.isEditing
        ? this.category.subcatid
        : this.categories.find(
            (x: any) => x.categoryid == this.category.categoryid
          ).categoryname,
      channelid: this.channelid,
      sortkey: this.category.order.toString(),
      type: '2',
      subcategoryname: this.category.subcatname,
    };
    var fun = this.isEditing
      ? this.courseService.editSubcategory(data)
      : this.courseService.addSubcategory(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          const data = {
            categoryid: this.category.categoryid,
            categoryname: res.subcategoryid,
            maincatname: this.categories.find(
              (x: any) => x.categoryid == this.category.categoryid
            ).categoryname,
            sortkey: this.category.order.toString(),
            subcategoryname: this.category.subcatname,
          };
          this.dialogRef.close({ success: true, data: data });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
}
