import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrlogin',
  templateUrl: './qrlogin.component.html',
  styleUrls: ['./qrlogin.component.scss']
})
export class QrloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
