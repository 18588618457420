<app-breadcrumb [s_breadcrumb]="'Reports'"></app-breadcrumb>
<div class="content mt-2">
  <!-- <div class="mb-3">
    <h2 class="mb-0">Reports</h2>
  </div> -->
  <div class="d-flex align-items-center justify-content-between flex-wrap">
    <div class="d-flex align-items-center flex-wrap">
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="serachtype" id="category" value="category"
          [(ngModel)]="reportForm.searchtype" (ngModelChange)="searchTypeChange()"
          [checked]="reportForm.searchtype == 'category'">
        <label class="form-check-label" for="category">
          Category
        </label>
      </div>
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="searchtype" id="course" value="course"
          [(ngModel)]="reportForm.searchtype" (ngModelChange)="searchTypeChange()"
          [checked]="reportForm.searchtype == 'course'">
        <label class="form-check-label" for="course">
          Attendance
        </label>
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="searchtype" id="class" value="class"
          [(ngModel)]="reportForm.searchtype" (ngModelChange)="searchTypeChange()"
          [checked]="reportForm.searchtype == 'class'">
        <label class="form-check-label" for="class">
          Class
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <button color="primary" class="me-2" mat-flat-button (click)="filter()">
          <i class="fa fa-filter"></i>
          Filter
        </button>
      </div>
      <div class="line">

      </div>
      <div>
        <button color="primary" class="me-2" mat-flat-button (click)="downloadSheet()">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
        <button mat-flat-button color="primary" (click)="search()">Search</button>
      </div>
    </div>
  </div>
  <div class="chiprow">
    <mat-chip-list>
      <mat-chip *ngIf="reportForm.startdate && reportForm.enddate && reportForm.searchtype != 'class'"
        (click)="filter()">
        <!-- <div class="chip-label">Date Range</div> -->
        {{reportForm.startdate}} - {{reportForm.enddate}}
      </mat-chip>
      <mat-chip (click)="filter()">
        <!-- <div class="chip-label">Channel</div> -->
        {{channelname}}
      </mat-chip>
      <!-- <mat-chip (click)="filter()" *ngIf="reportForm.searchtype == 'course' && reportForm.courseid != ''">
        {{reportForm.coursename}}
      </mat-chip> -->
      <ng-container
        *ngIf="reportForm.searchtype == 'category' || (reportForm.searchtype == 'class' && reportForm.classsearchtype == 'category')">
        <mat-chip *ngIf="reportForm.categoryname">
          <div class="chip-label">Category</div>
          {{reportForm.categoryname}}
          <mat-icon class="chip-remove" (click)="remove('category', 0)">cancel</mat-icon>
        </mat-chip>
      </ng-container>
      <!-- Post -->
      <mat-chip *ngFor="let post of reportForm.postsname; let i = index;">
        <div class="chip-label">Post</div>
        {{post}}
        <mat-icon class="chip-remove" (click)="remove('post', i)">cancel</mat-icon>
      </mat-chip>
      <!-- Branch -->
      <mat-chip *ngFor="let branch of reportForm.branches; let i = index;">
        <div class="chip-label">Branch</div>
        {{branch}}
        <mat-icon class="chip-remove" (click)="remove('branch', i)">cancel</mat-icon>
      </mat-chip>
      <!-- Department -->
      <mat-chip *ngFor="let department of reportForm.departments; let i = index">
        <div class="chip-label">Dept</div>
        {{department}}
        <mat-icon class="chip-remove" (click)="remove('department', i)">cancel</mat-icon>
      </mat-chip>
      <!-- Division -->
      <mat-chip *ngFor="let division of reportForm.divisions; let i = index">
        <div class="chip-label">Div</div>
        {{division}}
        <mat-icon class="chip-remove" (click)="remove('division', i)">cancel</mat-icon>
      </mat-chip>
      <!-- Type -->
      <ng-container
        *ngIf="reportForm.searchtype == 'category' || (reportForm.searchtype == 'class' && reportForm.classsearchtype == 'category')">
        <mat-chip *ngFor="let type of reportForm.coursetypesname; let i = index">
          <div class="chip-label">Type</div>
          {{type}}
          <mat-icon class="chip-remove" (click)="remove('coursetype', i)">cancel</mat-icon>
        </mat-chip>
      </ng-container>
      <mat-chip *ngIf="reportForm.searchtype == 'class' && reportForm.classdaterange"
        (click)="filter()">
        <!-- <div class="chip-label">Date Range</div> -->
        {{reportForm.startdate}} - {{reportForm.enddate}}
        <mat-icon class="chip-remove" (click)="remove('classdaterange', 0)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div *ngIf="reportForm.searchtype == 'category'">
    <table mat-table #table [dataSource]="categoryReportTableData" matSort="categorysort" style="width: 100%;"
      (matSortChange)="sortCategoryData($event)">

      <!-- Course Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="coursename">Course Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.coursename}}
        </td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="coursetype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="coursetype">Course Type</th>
        <td mat-cell *matCellDef="let element">
          {{element.coursetype}}
        </td>
      </ng-container>

      <!-- Student Count Column -->
      <ng-container matColumnDef="studentcounts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="studentcounts" class="center">Students
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">{{element.studentcounts}}</td>
      </ng-container>


      <!-- Time Spent Column -->
      <ng-container matColumnDef="totaltime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totaltime" class="center">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); text-align: center">
          {{element.totaltime}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="categoryDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: categoryDisplayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="4">
          <span class="nodata" *ngIf="!gettingCategoryReport">Empty</span>
          <span class="nodata" *ngIf="gettingCategoryReport">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #categoryPaginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"
      *ngIf="categoryReportData.length > 0">
    </mat-paginator>
  </div>
  <div *ngIf="reportForm.searchtype == 'course'">
    <table mat-table #table [dataSource]="courseReportTableData" matSort style="width: 100%;"
      (matSortChange)="sortCourseData($event)">

      <!-- User Name Column -->
      <ng-container matColumnDef="userid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
        <td mat-cell *matCellDef="let element">
          {{element.userid}}
        </td>
      </ng-container>

      <!-- User ID Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.username}}
        </td>
      </ng-container>

      <!-- User ID Column -->
      <ng-container matColumnDef="post">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Post</th>
        <td mat-cell *matCellDef="let element">
          {{element.typename}}
        </td>
      </ng-container>

      <!-- User ID Column -->
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
        <td mat-cell *matCellDef="let element">
          {{element.department}}
        </td>
      </ng-container>

      <ng-container matColumnDef="attendance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attendance</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.attendance == 0 ? '' : 'can-click'" (click)="goAttendanceDetails(element.attendance, element.userid)">
          {{element.attendance == 0 ? '-' : element.attendance}}
        </td>
      </ng-container>

      <ng-container matColumnDef="mandays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Man-days</th>
        <td mat-cell *matCellDef="let element">
          {{element.manday == 0 ? '-' : element.manday}}
        </td>
      </ng-container>



      <!-- Time Spent Column -->
      <ng-container matColumnDef="totaltime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); text-align: center">
          {{element.timespent}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="courseDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: courseDisplayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="8">
          <span class="nodata" *ngIf="!gettingCourseReport">Empty</span>
          <span class="nodata" *ngIf="gettingCourseReport">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #coursePaginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
  <div *ngIf="reportForm.searchtype == 'class'">
    <table mat-table #table [dataSource]="classReportTableData" matSort style="width: 100%;"
      (matSortChange)="sortClassData($event)">

      <!-- Course Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.coursename}}
        </td>
      </ng-container>

      <!-- Course Name Column -->
      <ng-container matColumnDef="coursetype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Type</th>
        <td mat-cell *matCellDef="let element" class="td-data-type">
          {{element.coursetype}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="studentcounts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Students</th>
        <td mat-cell *matCellDef="let element" class = "td-data-width">
          {{element.studentcounts}}
        </td>
      </ng-container>

      <!-- Total Classes Column -->
      <ng-container matColumnDef="totalclasses">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Classes</th>
        <td mat-cell *matCellDef="let element" class ="td-data-width totalclass" (click)="classInfo('all', element.courseid)">          
            {{element.totalclass}}
        </td>
      </ng-container>



      <!-- Time Spent Column -->
      <ng-container matColumnDef="classes">
        <th mat-header-cell *matHeaderCellDef>
          Class Status
        </th>
        <td mat-cell *matCellDef="let element" style="width: 350px !important;">
          <ng-container *ngIf="(element.activeclass > 0 || element.inactiveclass > 0 || element.completedclass > 0);else emptyTemplate;">
            <div class = "d-flex align-items-center">
              <span class="priority priorityprimary" *ngIf="element.activeclass > 0" (click)="classInfo('001', element.courseid)">
                Active - {{element.activeclass}}
              </span>
              <div class="priority prioritygray" *ngIf="element.inactiveclass > 0" (click)="classInfo('002', element.courseid)">
                Inactive - {{element.inactiveclass}}
              </div>
              <div class="priority prioritygreen" *ngIf="element.completedclass > 0" (click)="classInfo('003', element.courseid)">
                Completed - {{element.completedclass}}
              </div>
            </div>
          </ng-container>
          <ng-template #emptyTemplate>
            -
          </ng-template>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="classDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: classDisplayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="5">
          <span class="nodata" *ngIf="!gettingClassReport">Empty</span>
          <span class="nodata" *ngIf="gettingClassReport">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #classPaginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
</div>