  <div class = "my-2">
    <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
      <div class="d-flex w-100 flex-wrap justify-content-between align-items-center">
        <div class="co-title">
          Students
        </div>
        <div class="d-flex flex-wrap align-items-center">
          <div class="me-2">
            Total :<b> {{datacount}}</b>
          </div>
          <div class="input-group" style="width: 260px !important;">
            <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
              (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
            <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
              *ngIf="searchText" (click)="clear()"
              style="border: 1px solid var(--main-color);color: var(--main-color);">
              <i class="fa fa-times"></i>
            </button>
          </div>

          <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button"
            style="margin-left: 10px;">
            <i class="fa fa-refresh"></i>
          </button>

          <button class="btn new-class" matTooltip="Sections" (click)="goToSections()" type="button"
            style="margin-left: 10px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="18px" class="mx-1" style="fill: white;">
              <path
                d="M 25 2 C 24.83275 2 24.651 2.041 24.5 2.125 L 17.03125 6.28125 L 25 10.625 L 33 6.3125 L 25.5 2.125 C 25.349 2.041 25.16725 2 25 2 z M 16 8 L 16 10.84375 L 6.5 16.125 A 1.0001 1.0001 0 0 0 6 17 L 6 25.625 L 2.96875 27.3125 L 11 31.625 L 18.9375 27.28125 L 11.5 23.125 C 11.197 22.957 10.803 22.957 10.5 23.125 L 8 24.5 L 8 17.59375 L 16 13.15625 L 16 16.34375 C 16 16.70675 16.183 17.04275 16.5 17.21875 L 24 21.375 L 24 12.375 L 16 8 z M 34 8.0625 L 26 12.375 L 26 21.375 L 33.5 17.21875 C 33.818 17.04275 34 16.70675 34 16.34375 L 34 13.15625 L 42 17.59375 L 42 24.53125 L 39.5 23.125 C 39.199 22.957 38.801 22.957 38.5 23.125 L 31.03125 27.28125 L 39 31.625 L 46.9375 27.28125 L 44 25.625 L 44 17 C 44 16.637 43.818 16.301 43.5 16.125 L 34 10.84375 L 34 8.0625 z M 20 28.96875 L 12 33.375 L 12 42.375 L 13.3125 41.65625 L 24.5 47.875 A 1.0001 1.0001 0 0 0 25.5 47.875 L 36.6875 41.65625 L 38 42.375 L 38 33.375 L 30 29 L 30 37.34375 C 30 37.70675 30.182 38.04175 30.5 38.21875 L 34.625 40.5 L 25 45.84375 L 15.375 40.5 L 19.5 38.21875 C 19.817 38.04275 20 37.70675 20 37.34375 L 20 28.96875 z M 48 28.96875 L 40 33.375 L 40 42.375 L 47.5 38.21875 C 47.818 38.04175 48 37.70675 48 37.34375 L 48 28.96875 z M 2 29.0625 L 2 37.34375 C 2 37.70675 2.183 38.04175 2.5 38.21875 L 10 42.375 L 10 33.375 L 2 29.0625 z" />
            </svg>
            
            <!-- <i class="fa fa-cubes" style="color: white; font-size: 16px; margin: 0 4px;"></i> -->
            Sections
          </button>
        </div>
      </div>

    </div>

    <div style="overflow: auto;">
      <table mat-table #table [dataSource]="showstudents" matSort (matSortChange)="sortData($event)" matSort
        class="mat-elevation-z1" style="width: 100%;">

        <ng-container matColumnDef="userid">

          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by classname"
            style="padding-left:0px;">User ID</th>
          <td class="t1" mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
            {{element.userid}}
          </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by classname">
            User Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="classcode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Class
          </th>
          <td mat-cell *matCellDef="let element" px-3>
            <span *ngIf="element.classid" class="badge bg-main">{{element.classcode}}</span>
            <span *ngIf="!element.classid" class = "addtoclass" (click) = "addToClass(element)">Add to class</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="feedback">
          <th mat-header-cell *matHeaderCellDef> Feedback </th>
          <td mat-cell *matCellDef="let element" style="width: 500px;">
            <span *ngIf="element.feedback" class="cus-point feedback-data" (click)="feedback(element)">{{element.feedback}}</span>
            <span *ngIf="!element.feedback" class = "addtoclass" (click)="feedback(element)">Give feedback</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td colspan="5">
            <span class="nodata" *ngIf="!isLoading">Empty</span>
            <span class="nodata" *ngIf="isLoading">
              <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
            </span>
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
        [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

<!-- <app-students *ngIf="student" [classid]="classid" [courseid]="courseid" [classnames]="classname" [classcode]="classcode" (back)="student = false"></app-students> -->