import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { InstructorFilterPipe } from '../../shared/filter/filter.pipe';

@Component({
  selector: 'app-students-by-course',
  templateUrl: './students-by-course.component.html',
  styleUrls: ['./students-by-course.component.scss'],
})
export class StudentsByCourseComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  channelid: string = '';
  courseid: string = '';
  classid: string = '';
  channelname: string = 'Channel Name';
  coursename: string = 'Course Name';

  showstudents!: MatTableDataSource<any>;
  students: any[] = [];
  classlist: any[] = [];

  displayedColumns: any;

  isFocus: boolean = false;
  isLoading: boolean = false;
  paginate: boolean = false;
  gettingClass: boolean = false;
  isfirsttime: boolean = true;

  datacount: string = '0';
  searchText: string = '';
  filter: string = '';
  role: string = '';
  totaltime: any = '0 hr(s) 0 min(s)';

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private route: ActivatedRoute,
    private filterPipe: InstructorFilterPipe
  ) {
    this.route.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) || (this.role == 'supervisor' && !this.allinoneService.isSupervisor())) {
      this.router.navigateByUrl('')
    }
    if (this.route.snapshot.params['channelid'] == 'public') {
      this.channelid = '';
    } else {
      this.channelid = this.route.snapshot.params['channelid'];
    }
    this.courseid = this.route.snapshot.params['courseid'];
  }

  ngOnInit(): void {
    this.displayedColumns = ['userid', 'username', 'timespent', 'grade', 'progress', 'status', 'enrolleddate', 'completeddate']
    this.getAllClass();
    this.getStudents();
  }

  // downloadSheet() {
  //   var name = 'Video tracking.xlsx';
  //   var data : any = [];
  //   this.allData.map((d:any) => {
  //     data.push({
  //       'User ID': d.userid,
  //       'User Name' : d.username,
  //       'Current Time' : d.currenttime,
  //       'Last Viewed Date' : d.lastviewdate != '-' ? this.allinoneService.formatDBToShow(d.lastviewdate) + ' ' + d.lastviewtime : '-' ,
  //     });
  //   });
  //   this.allinoneService.exportExcel(data, name)
  // }

  downloadSheet() {
    var name = this.coursename + '.xlsx';
    var data: any = [];

    var filteredData = this.filterPipe.transform(
      this.students,
      this.searchText,
      'studentsbycourse'
    );

    filteredData.map((d: any) => {
      data.push({
        'User ID': d.userid,
        'User Name': d.username,
        'Time Spent': d.totaltime,
        'Grade': d.grade + '%',
        'Progress': d.percentage + '%',
        'Status': d.completed ? 'Completed' : !d.completed && d.percentage == 0 ? 'Not Started' : 'In Progress',
        'Enrolled Date': d.enrolleddate,
        'Completed Date': d.completeddate
      })
    })
    this.allinoneService.exportExcel(data, name);
  }

  getAllClass() {
    this.classlist = [];
    this.gettingClass = true;
    this.courseService.getAllClass(this.courseid).subscribe(
      (classes: any) => {
        if (classes.returncode == '300') {
          this.classlist = classes.datalist;
          this.gettingClass = false;
        } else {
          if (classes.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(classes.status || classes.message || classes.error, 'warn');
          }
          this.gettingClass = false;
        }
      },
      (err) => {
        this.gettingClass = false;
      }
    );
  }

  getStudents() {
    this.students = [];
    this.totaltime = '0 hr(s) 0 min(s)';
    this.showstudents = new MatTableDataSource(this.students);
    this.isLoading = true;
    this.courseService.getUserAnalytics(this.channelid, this.courseid, this.classid, '', '').subscribe((res: any) => {
      if (res.returncode == "300") {
        this.channelname = res.channelname;
        this.coursename = res.coursename;
        this.totaltime = res.totaltime;
        this.isLoading = false;
        this.students = res.datalist;
        this.showstudents = new MatTableDataSource(this.students);
        if (this.students == undefined || this.students.length == 0) {
          this.paginate = false;
        } else {
          this.paginate = true;
        }

        this.showstudents.sort = this.sort;
        this.showstudents.paginator = this.paginator;
        this.isfirsttime = false;

        setTimeout(() => {
          this.isLoading = false;
        }, 400);
      }
      else {
        if (res.returncode == "210") {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
        }
        else {
          this.messageService.openSnackBar(res.status || res.message || "Server Error", "warn");
        }
        this.isLoading = false;
      }
    }, err => {
      this.isLoading = false;
    })
  }

  announceSortChange(sort: Sort) {
    const data = this.students;
    if (!sort.active || sort.direction === '') {
      this.students = data;
      return;
    }

    this.students = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      var as = a.completed ? 'Completed' : !a.completed && a.percentage == 0 ? 'Not Started' : 'In Progress';
      var bs = b.completed ? 'Completed' : !b.completed && b.percentage == 0 ? 'Not Started' : 'In Progress';
      // var ad = this.allinoneService.formatDateTwo()
      switch (sort.active) {
        case 'userid':
          return this.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.compare(parseInt(a.username), parseInt(b.username), isAsc);
        case 'timespent':
          return this.compare(a.totalsecond, b.totalsecond, isAsc);
        case 'grade':
          return this.compare(a.grade, b.grade, isAsc);
        case 'progress':
          return this.compare(a.percentage, b.percentage, isAsc);
        case 'completeddate':
          return this.compare(this.allinoneService.formatDateTwo(a.completeddate), this.allinoneService.formatDateTwo(b.completeddate), isAsc);
        case 'enrolleddate':
          return this.compare(this.allinoneService.formatDateTwo(a.enrolleddate), this.allinoneService.formatDateTwo(b.enrolleddate), isAsc);
        case 'status':
          return this.compare(as, bs, isAsc);
        default:
          return 0;
      }
    });
    this.showstudents = new MatTableDataSource(this.students);
    this.showstudents.paginator = this.paginator;
  }

  test() {
    // var t = '06/06/2022';
    // var a = this.allinoneService.formatDBToShow(t);
    // var b = this.allinoneService.formatDateTwo(t);

    // console.log(a);
    // console.log(b);

    var date = '06/06/2022';
    var t = date.split('/')
    var d = t[2] + t[1] + t[1]
    // return d;
    var e= this.allinoneService.formatDBToShow(d)
    console.log(e);
    


  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  goTo(route: string) {
    this.router.navigateByUrl(route);
  }

  goToChannel() {
    const channel = this.channelid ? this.channelid : 'public';
    this.router.navigateByUrl(`${this.role}/analytics/${channel}`);
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showstudents.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = "";
    this.showstudents.filter = "";
  }

  refresh() {
    this.clear();
    this.getStudents();
  }

  goToDetails(userid: string) {
    const channel = this.channelid ? this.channelid : 'public';
    this.router.navigateByUrl(`${this.role}/analytics/${channel}/course/${this.courseid}/${userid}`)
  }
}

