<!-- <app-breadcrumb [s_breadcrumb]="'Feedback Questions'"></app-breadcrumb> -->
<app-breadcrumb [t_breadcrumb]="templateName" [s_breadcrumb]="'Feedback Templates'" (emit)="goToFeedback()" *ngIf="!isPopup">
</app-breadcrumb>
<ng-container *ngIf="isPopup">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div>
      <h2 class="mb-0">Feedback Questions</h2>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <mat-icon (click)="dismiss()" class="cus-point">close</mat-icon>
    </div>
  </div>
</ng-container>
<div [ngClass]="{'container-fluid mt-2 mb-2' : !isPopup}">
  <div class="d-flex align-items-center flex-wrap mt-3 mb-3" *ngIf="!isPopup">
    <button (click)="questionSetup()" class="btn new-chl mx-0 me-auto">
      <i class="fa fa-plus" aria-hidden="true"></i> New Question
    </button>
    <div class="me-2">
      Total :<b> {{questionsData.length}}</b>
    </div>
    <!-- <div class="me-2">
      <select class="form-select" [(ngModel)]="channelid" required #chlid="ngModel" style="width: 260px !important"
        (ngModelChange)="channelChange($event)">
        <option value="" *ngIf="role == 'admin'">KHub</option>
        <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.name}}</option>
      </select>
    </div> -->
    <div class="input-group me-2" style="width: 260px !important">
      <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" />
      <button class="btn" type="button" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
        *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4; color: #00bcd4">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button">
      <i class="fa fa-refresh"></i>
    </button>
  </div>
  <div style="overflow: auto;">
    <table mat-table [dataSource]="questionTableData" style="width: 100%;" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="isPopup">
      <!-- Name Column -->
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef>Question </th>
        <td mat-cell *matCellDef="let element" class="cus-point">
          <span [innerHtml]="element.question">

          </span>
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef style="width: 350px;"> Type
        </th>
        <td mat-cell *matCellDef="let element" px-3 class="cus-point"> {{element.type=='001' ? 'Multiple Choice Question (Radio)' : element.type=='002' ? 'Multiple Choice Question (Checkbox)' : element.type=='003' ? 'Textarea' :
          'Rating' }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 115px;"> Action </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">

          <button class="btn btn-outline-primary btn-custom-rounded me-2" matTooltip="Edit Question"
            (click)="questionSetup(element)">
            <i class="fa fa-edit"></i>
          </button>
          <button class="btn btn-outline-danger btn-custom-rounded" matTooltip="Delete Questoin"
            (click)="deleteQuestion(element)">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>
      <tr class="mat-row" *matNoDataRow>
        <td colspan="4">
          <span class="nodata" *ngIf="!gettingQuestions">Empty</span>
          <span class="nodata" *ngIf="gettingQuestions">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>
    </table>
    <mat-paginator #paginator *ngIf="questionsData.length > 0" [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
</div>