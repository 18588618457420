<app-breadcrumb *ngIf="!isLoading" [t_breadcrumb]="classname +'\'s Dropbox'" [b_breadcrumb]="'Class'" [s_breadcrumb]="coursename" (emit)="gotoCourse()" (emit2)="gotoClass()"></app-breadcrumb>
<div class="container-fluid mt-2 mb-2 px-5">

    <div class="co-title d-flex align-items-center">
    </div>
    <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
        <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-3">
            <div class="me-auto">
                <h2><strong>Dropbox Submissions</strong></h2>
            </div>


            <div class="d-flex flex-wrap align-items-center">

                <div class="me-2">
                    Total :<b> {{datacount}}</b>
                </div>
                <div class="input-group me-2" style="width: 260px !important;">
                    <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                    <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}" *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4;color: #00bcd4;">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <div class="form-group " style="width: 260px !important;">
                    <select class="form-select" [(ngModel)]="selectedDropbox" (change)="getDropbox()" matTooltip="Filter By Section" [disabled]="gettingDropbox">
                        <option value="all">All</option>
                        <option value="general">General</option>
                        <option [value]="sec.sectionid" *ngFor="let sec of allsection">
                            {{ sec.sectionname }}
                        </option>
                    </select>
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingDropbox"></mat-progress-bar>
                </div>

                <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button" style="margin-left: 10px;">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>

    </div>


    <div style="overflow: auto;">
        <table mat-table #table [dataSource]="showdropboxdata" matSort (matSortChange)="sortData($event)" matSort class="mat-elevation-z1" style="width: 100%;">

            <ng-container matColumnDef="studentid">

                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by studentid" style="padding-left:0px;">Student ID</th>
                <td class="t1" mat-cell *matCellDef="let element">
                    {{element.userid}}
                </td>
            </ng-container>
            <ng-container matColumnDef="studentname">

                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by studentname" style="padding-left:0px;">Student Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.username}}
                </td>
            </ng-container>
            <ng-container matColumnDef="title">

                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title" style="padding-left:0px;">Title</th>
                <td mat-cell *matCellDef="let element" class="custom-td" [matTooltip]="element.title">
                    {{element.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="file">

                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by file" style="padding-left:0px;">File</th>
                <td mat-cell *matCellDef="let element" class="custom-td" [matTooltip]="element.filename">
                    {{element.filename}}
                </td>
            </ng-container>

            <ng-container matColumnDef="remark">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by remark"> Remark
                </th>
                <td mat-cell *matCellDef="let element" px-3 [matTooltip]="element.remark" class="custom-td">
                    {{element.remark}} </td>
            </ng-container>
            <ng-container matColumnDef="datetime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">Submitted Date
                </th>
                <td mat-cell *matCellDef="let element"> {{ allinoneService.formatDBToShow(element.date) }}&nbsp;{{element.time}}
                </td>
            </ng-container>

            <ng-container matColumnDef="action" style="width: 100px;">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index;" style="text-align: center;">

                    <button class="btn btn-outline-success rounded-circle" matTooltip="Download" (click)="downloadFile(element.fileurl, element.filename)" style="margin-left: 5px; height: 40px !important; width: 42px !important;">
                        <i class="fa fa-download"></i>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td colspan="7">
                    <span class="nodata" *ngIf="!isLoading">Empty</span>
                    <span class="nodata" *ngIf="isLoading">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                    </span>
                </td>
            </tr>
        </table>

        <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

</div>