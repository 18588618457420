import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AngularEditorConfig,AngularEditorService } from '@kolkov/angular-editor';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { Answer, Question } from '../../shared/models/Quiz';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';

import * as S3 from 'aws-sdk/clients/s3';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { DatePipe } from '@angular/common';
import ImageViewer from 'awesome-image-viewer';
import { each } from 'jquery';
import { drop } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-question-editor',
  templateUrl: './question-editor.component.html',
  styleUrls: ['./question-editor.component.scss'],
})
export class QuestionEditorComponent implements OnInit {
  // @Input('unitid') unitid: string = '';
  // @Input('courseid') courseid: string = '';
  // @Input('sortkey') sortkey: string = '-1';
  // @Input('questionForEdit') questionForEdit!: Question;

  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChild('hiddenpdffileinput') hiddenpdffileinput: any;

  unitid: string = '';
  courseid: string = '';
  sortkey: string = '-1';
  questionForEdit!: Question;

  quesitontypes = [
    {
      name: 'Multiple Choice Question',
      id: '001',
    },
    {
      name: 'Fill in the blank',
      id: '003',
    },
    {
      name: 'Matching',
      id: '004',
    },
    {
      name: 'DropDown ',
      id: '005',
    },
  ];

  blankanslist: string[] = [];
  blankexplanation: string = '';
  tempAns: string = '';
  casesensitive: boolean = false;
  fixedsequence:boolean = false;
  mark:number = 1;

  countbyquantity : boolean = false;

  matching: any[] = [{
    question: '',
    answer: '',
    quizimgtype: false,
    ansimgtype: false,
    quizimgid: '',
    ansimgid: '',
    quizimgurl: '',
    ansimgurl: '',
  }];

  deleteimagelist: any = [];
  oldImageList: any = [];

  constructor(
    private messageService: MessageService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private courseService: CourseService,
    private allinoneService: AllInOneService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);

    if (data.unitid) {
      (this.unitid = data.unitid),
        (this.courseid = data.courseid),
        (this.sortkey = data.sortkey),
        (this.questionForEdit = data.questionForEdit);
    }
    this.dialogRef.disableClose = true;
  }

  config:AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    enableToolbar: true,
    showToolbar: true,
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'insertUnorderedList',
        'insertOrderedList',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  // qforedit!: Question;
  qforedit!: any;
  isStillUpload: boolean = false;
  submitted: boolean = false;
  isEditing: boolean = false;
  question: any = '';
  correctanswer: any[] = [];

  // Image 
  imageList: any[] = [];

  type: string = sessionStorage.getItem('qtype') || '001'; // 001 - Single Answer, 002 - Multiple Answers, 003 - Fill in the blank

  answers: any[] = [
    {
      answerid: '0',
      answer: '',
      explanation: '',
      config: {
        editable: true,
        spellcheck: true,
        height: '3rem',
        minHeight: '3rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        showToolbar: false,
      },
    },
    {
      answerid: '1',
      answer: '',
      explanation: '',
      config: {
        editable: true,
        spellcheck: true,
        height: '3rem',
        minHeight: '3rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        showToolbar: false,
      },
    },
  ];

  dropdownanswers: any[] = [
    {
      answerid: '0',
      correctanswer: [],
      explanation: '',
      blankanslist: [],
      inputfield: '',
      config: {
        editable: true,
        spellcheck: true,
        height: '3rem',
        minHeight: '3rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        showToolbar: false,
      },
    }];

  ngOnInit(): void {
    if (this.questionForEdit) {
      this.qforedit = JSON.parse(JSON.stringify(this.questionForEdit));
      this.isEditing = true;

      this.question = this.qforedit.question;
      this.mark = this.qforedit.mark;
      this.type = this.qforedit.type == '002' ? '001' : this.qforedit.type;
      if (this.type == '003') {

        this.blankanslist = this.qforedit.correctanswer;
        this.blankexplanation = this.qforedit.answerlist[0];
        this.casesensitive = this.qforedit.casesensitive == 'true';
        this.fixedsequence = this.qforedit.fixedsequence??false;

        // show image from api
        this.qforedit.imagelist.map((item: any) => {
          var data = {
            filename: item.imagename,
            previewurl: item.imageurl
          }
          this.imageList.push(data);
          this.oldImageList.push(data);
        });
      } else if (this.type == '001' || this.type == '002') {
        this.correctanswer = this.qforedit.correctanswer;
        this.sortkey = this.qforedit.sortkey;
        this.answers = [];
        this.qforedit.answerlist.map((item: any) => {
          item = {
            answerid: item.answerid,
            answer: item.answer,
            explanation: item.explanation,
            correct: this.correctanswer.find((x: any) => x == item.answerid)
              ? true
              : false,
            config: {
              editable: true,
              spellcheck: true,
              height: '3rem',
              minHeight: '3rem',
              placeholder: 'Enter text here...',
              translate: 'no',
              defaultParagraphSeparator: 'p',
              showToolbar: false,
            },
          };
          this.answers.push(item);
        });
        // show image from api
        this.qforedit.imagelist.map((item: any) => {
          var data = {
            filename: item.imagename,
            previewurl: item.imageurl
          }
          this.imageList.push(data);
          this.oldImageList.push(data);
        });

      } else if (this.type == '005') {
        this.correctanswer = this.qforedit.correctanswer;
        this.sortkey = this.qforedit.sortkey;
        this.dropdownanswers = [];
        this.qforedit.answerlist.forEach((item:any, index:number) => {
          let value = {
            answerid: index,
            blankanslist: item.blankanslist,
            explanation: item.explanation,
            correctanswer:this.correctanswer[index],
            inputfield: '',
            config: {
              editable: true,
              spellcheck: true,
              height: '3rem',
              minHeight: '3rem',
              placeholder: 'Enter text here...',
              translate: 'no',
              defaultParagraphSeparator: 'p',
              showToolbar: false,
            },
          };
          this.dropdownanswers.push(value);
        });
        // show image from api
        this.qforedit.imagelist.map((item: any) => {
          var data = {
            filename: item.imagename,
            previewurl: item.imageurl
          }
          this.imageList.push(data);
          this.oldImageList.push(data);
        });

      }
      else {
        console.log('here');

        console.log(this.qforedit);

        this.matching = [];
        this.question = this.qforedit.question.title;
        this.countbyquantity = this.qforedit.countbyquantity;
        this.qforedit.correctanswer.map((item: any, index: number) => {
          this.matching.push({
            question: this.qforedit.question.questions.find((x: any) => x.questionid == item.questionid).question,
            quizimgtype: this.qforedit.question.questions.find((x: any) => x.questionid == item.questionid).type == 'image' ? true : false,
            quizimgurl: this.qforedit.question.questions.find((x: any) => x.questionid == item.questionid).imageurl,
            answer: this.qforedit.answerlist.find((x: any) => x.answerid == item.answerid).answer,
            ansimgtype: this.qforedit.answerlist.find((x: any) => x.answerid == item.answerid).type == 'image' ? true : false,
            ansimgurl: this.qforedit.answerlist.find((x: any) => x.answerid == item.answerid).imageurl,
          })
        })
      }
    }
  }

  // AddQuestionBlank() {
  //   // console.log('caret at >> ', this.question.target.selectionStart)
  //   console.log('question >> ', this.question)
  //   if (this.type !== '004'){
  //       this.question += "___"
  //   }
  // }

  AddQuestionBlank() {
    const editorElement = document.querySelector('angular-editor');
    if (editorElement) {
      const editor = editorElement.querySelector('div[contenteditable="true"]');
      if (editor) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.deleteContents();
  
          const blankNode = document.createTextNode('___');
          range.insertNode(blankNode);
  
          // Trigger change detection
          this.question = editor.innerHTML;
          this.cdr.detectChanges();
        }
      }
    }
  }

  t() {
    console.log(this.matchingImgList);
  }

  // imageViewer(img: any, index: number) {
  //   var imgArray: any = [];
  //   img.map((item: any) => {
  //     imgArray.push({
  //       mainUrl: item.imageurl,
  //       thumbnailUrl: item.imageurl,
  //       description: '',
  //     });
  //   });
  //   const viewer = new ImageViewer({
  //     images: imgArray,
  //     currentSelected: index,
  //     stretchImages: true,
  //     isZoomable: false,
  //   });
  // }

  imageViewer(img: any) {
    var imgArray: any = [];

    imgArray.push({
      mainUrl: img,
      thumbnailUrl: img,
      description: '',
    });

    const viewer = new ImageViewer({
      images: imgArray,
      style : {
        // width : 10,
      },
      // currentSelected: 0,452213252Q34
      stretchImages: true,
      isZoomable: false,
    });
  }

  changeMatchingQuizType(data: any) {

    this.deleteMatchingImg(data.quizimgid);

    data.question = '';
    data.quizimgid = '';
    data.quizimgurl = '';
    data.quizimgtype = !data.quizimgtype;
  }

  changeMatchingAnsType(data: any) {

    this.deleteMatchingImg(data.ansimgid);

    data.answer = '';
    data.ansimgid = '';
    data.ansimgurl = '';
    data.ansimgtype = !data.ansimgtype;
  }

  deleteMatchingImg(id: any) {
    var tempi = this.matchingImgList.findIndex((x: any) => x.id == id);

    if (tempi != '-1') {
      this.matchingImgList.splice(tempi, 1);
    }
  }

  fileSelected?: any;
  matchingImgList: any = [];
  matchingImgId = 1;
  // type 001 - quiz, 002 - ans
  async quizFile(event: any, data: any, type: any) {

    console.log(data);

    var id = '';
    if (type == '001') {
      id = data.quizimgid;
    } else{
      id = data.ansimgid;
    }

    this.deleteMatchingImg(id);

    var filename = '';
    var filepath = '';
    var tempfilename = '';
    var tempfiletype = '';
    var fileSelected: any = '';

    fileSelected = event.target.files[0];
    var filename1 = event.target.files[0].name.toString();
    tempfilename = filename1.substring(0, filename1.lastIndexOf('.'));
    let name = tempfilename.replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    tempfilename = name + '_' + time;
    tempfiletype = filename1.split('.').pop();
    tempfilename =
      tempfilename + '.' + tempfiletype;
    filename = tempfilename.toString();
    filepath = 'references/' + tempfilename;
    this.matchingImgList.push({
      id: this.matchingImgId,
      file: fileSelected,
      filename: filename
    })

    // For show image

    var reader = new FileReader();
    reader.readAsDataURL(fileSelected);
    reader.onload = (_event) => {
      if (type == '001') {
        data.question = filename;
        data.quizimgurl = reader.result;
        data.quizimgid = JSON.parse(JSON.stringify(this.matchingImgId));
      } else {
        data.answer = filename;
        data.ansimgurl = reader.result;
        data.ansimgid = JSON.parse(JSON.stringify(this.matchingImgId));
      }
      this.matchingImgId += 1;
    };

    // console.log('ok');
    // console.log(data.quizimgurl);
    // console.log('complete');
    // console.log(data);




  }

  matchingQuizUpload(data: any) {
    data.question = 'ok par';
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.getFileName(file),
            };
            this.imageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }

  test() {
    console.log(this.countbyquantity);
    


  }

  removeImage(index: number, item: any) {

    this.oldImageList.map((x: any) => {
      if (x.filename == item.filename) {
        this.deleteimagelist.push(item.filename);
      }
    })

    this.imageList.splice(index, 1);

  }

  getFileName(file: any) {
    var name = file.name.trim();
    var splitName = name.split('.');
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  remove(fruit: string): void {
    const index = this.blankanslist.indexOf(fruit);

    if (index >= 0) {
      this.blankanslist.splice(index, 1);
    }
  }
  removefromdroopdown(fruit: string, listindex:number,chipindex:number): void {
    const index = this.dropdownanswers[listindex].blankanslist.indexOf(fruit);
    if (index >= 0) {
      this.dropdownanswers[listindex].blankanslist.splice(index, 1);
    }
    
  }

  toggleCorrectAnswer(chipindex: number, listindex: number): void {
    const correctAnswers = this.dropdownanswers[listindex].correctanswer;
    
    // Convert chipindex to a string to match the data type in the correctAnswers array
    const answerStr = chipindex.toString();
  
    // Toggle logic: if already selected, remove it; if not, add it
    const answerIndex = correctAnswers.indexOf(answerStr);
  
    if (answerIndex === -1) {
      // Add the selected answer if it's not already in the correctanswer array
      correctAnswers.push(answerStr);
    } else {
      // Remove the selected answer if it's already in the correctanswer array
      correctAnswers.splice(answerIndex, 1);
    }
  
    // Debugging the correctanswer array to ensure it updates correctly
    console.log(this.dropdownanswers[listindex].correctanswer, '>> correct answer');
  }

  selecetcorrectansfromchip(chipindex:number,listindex:number) {
    // var checked = this.dropdownanswers[listindex].correctanswer.includes(chipindex.toString())
    // if(checked) {
    //   var selecetedindex= this.dropdownanswers[listindex].correctanswer.indexOf(chipindex.toString())
    //   this.dropdownanswers[listindex].correctanswer.splice(selecetedindex,1)
    // } else {
    //   this.dropdownanswers[listindex].correctanswer.push(chipindex.toString())
    // }
    this.dropdownanswers[listindex].correctanswer=chipindex.toString();
    console.log(this.dropdownanswers[listindex].correctanswer, " >>correct answer")
  }


  removeAns(index: number) {
    if (this.answers.length > 1) {
      this.answers.splice(index, 1);
      this.answers.map((item: Answer, index: number) => {
        item.answerid = index.toString();
      });
    }
  }
  removeChoicefromDropdown(index:number){
    if (this.dropdownanswers.length>1){
      this.dropdownanswers.splice(index,1);
      this.dropdownanswers.map((item: Answer, index: number) => {
        item.answerid = index.toString();
      });
    }
  }

  addAnswer() {
    if (this.tempAns.trim() == '') {
      this.messageService.openSnackBar('Answer should not be blank.', 'warn');
      return;
    }
    this.blankanslist.push(this.tempAns.trim());
    this.tempAns = '';
  }

  addChoicestoDropdowns(index:number){
    if (this.dropdownanswers[index].inputfield.trim() == '') {
      this.messageService.openSnackBar('Answer should not be blank.', 'warn');
      return;
    }
    this.dropdownanswers[index].blankanslist.push(this.dropdownanswers[index].inputfield.trim());
    this.dropdownanswers[index].inputfield = '';
  }

  onfocus() {
    console.log(this.type);
    if (this.answers.length < 5 && (this.type == '001' || this.type == '002')) {
      this.answers.push({
        answerid: this.answers.length.toString(),
        answer: '',
        explanation: '',
        correct: false,
        config: {
          editable: true,
          spellcheck: true,
          height: '3rem',
          minHeight: '3rem',
          placeholder: 'Enter text here...',
          translate: 'no',
          defaultParagraphSeparator: 'p',
          showToolbar: false,
        },
      });
    }
    if ( this.type == '004') {
      this.matching.push({
        answer: '',
        question: '',
        quizimgtype: false,
        ansimgtype: false,
        quizimgid: '',
        ansimgid: '',
        quizimgurl: '',
        ansimgurl: ''
      });
    }
    if ( this.type == '005') {
      this.dropdownanswers.push({
        answerid: this.dropdownanswers.length,
        correctanswer: [],
        explanation: '',
        blankanslist: [],
        inputfield: '',
        config: {
          editable: true,
          spellcheck: true,
          height: '3rem',
          minHeight: '3rem',
          placeholder: 'Enter text here...',
          translate: 'no',
          defaultParagraphSeparator: 'p',
          showToolbar: false,
        },
      });
    }
  }

  cancel() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      this.loadingService.hide();
      if (dialogResult) {
        this.dialogRef.close();
      }
    });
  }

  async save() {
    if (!this.isStillUpload) {
      if (this.question.toString().trim() == '') {
        this.messageService.openSnackBar('Please enter the question.', 'warn');
        return;
      }
      if(Number.isNaN(Number(this.mark))){
        this.messageService.openSnackBar('Mark should be number', 'warn');
        return;
      }

      var finalquestion: any = this.question;
      var finalcasesensitve = 'false';
      var finalanswer: any[] = [];
      if (this.type == '003') {
        if (this.blankanslist.length == 0) {
          this.messageService.openSnackBar(
            'Please add at least one answer.',
            'warn'
          );
          return;
        }
        if(this.fixedsequence && this.blankanslist.length != (this.question.match(new RegExp(`___`, "g")) || []).length) {
          this.messageService.openSnackBar(
            'There should be equal number of questions and answers',
            'warn'
          );
          return;
        }
        this.isStillUpload = true;
        this.correctanswer = this.blankanslist;
        finalanswer = [this.blankexplanation];
        finalcasesensitve = this.casesensitive ? 'true' : 'false';
      } else if (this.type == '001' || this.type == '002') {
        if (this.answers.length < 2) {
          this.messageService.openSnackBar(
            'There should be at least two answers',
            'warn'
          );
          return;
        }
        // Get correct answer here
        this.correctanswer = [];
        this.answers.map((item: any) => {
          // delete item.config;
          if (item.correct) {
            this.correctanswer.push(item.answerid.toString());
          }
          var temp = JSON.parse(JSON.stringify(item));
          delete temp.config;
          finalanswer.push(temp);
        });
        if (this.correctanswer.length == 0) {
          this.messageService.openSnackBar(
            'Please select the answer(s)!',
            'warn'
          );
          return;
        }

        var temp = this.answers.filter(
          (ans: Answer) => ans.answer.toString().trim() == ''
        );
        if (temp.length > 0) {
          this.messageService.openSnackBar(
            'Please fill all the answers.',
            'warn'
          );
          return;
        }

        this.isStillUpload = true;
        this.type = this.correctanswer.length > 1 ? '002' : '001';
      } else if (this.type == '005') {
        // if( this.dropdownanswers.length != (this.question.match(new RegExp(`___`, "g")) || []).length) {
        //   this.messageService.openSnackBar(
        //     'There should be equal number of questions and answers',
        //     'warn'
        //   );
        //   return;
        // }
        for( var eachdropdown of this.dropdownanswers) {
          if (eachdropdown.blankanslist.length < 2) {
            this.messageService.openSnackBar(
              'There should be at least two answers for quiz',
              'warn'
            );
            return;
          }
          if (eachdropdown.correctanswer == '') {
              this.messageService.openSnackBar(
              'There should be at lease one correct answer for quiz',
              'warn'
            );
            return;
          }
        }
        this.correctanswer = []; 
        // this.dropdownanswers.map((item: any) => {
        //   this.correctanswer.push(item.correctanswer.toString());
        //   var temp = JSON.parse(JSON.stringify(item));
        //   delete temp.config;
        //   delete temp.inputfield;
        //   finalanswer.push(temp);
        // });
        this.dropdownanswers.map((item: any) => {
          this.correctanswer.push(item.correctanswer.length > 1 ? item.correctanswer : [item.correctanswer.toString()]);
          
          const temp = JSON.parse(JSON.stringify(item));
          delete temp.config;
          delete temp.inputfield;
          finalanswer.push(temp);
        });
        console.log('Final correct answers:', this.correctanswer);
        this.isStillUpload = true;
      } else {
        if (
          this.matching.filter(
            (x: any) => x.answer.trim() == '' || x.question.trim() == ''
          ).length > 0
        ) {
          this.messageService.openSnackBar(
            'Item or Match cannot be blank.',
            'warn'
          );
          return;
        }
        if (
          this.matching.filter(
            (x: any) => x.answer.trim() != '' && x.question.trim() != ''
          ).length < 2
        ) {
          this.messageService.openSnackBar(
            'There should be at least two correct matches.',
            'warn'
          );
          return;
        }
        this.isStillUpload = true;
        var questionlist: any[] = [];
        this.correctanswer = [];
        this.matching.map((item: any, index: number) => {
          questionlist.push({
            question: item.question,
            type: item.quizimgtype ? 'image' : 'text',
            questionid: index.toString(),
          });
          finalanswer.push({
            answer: item.answer,
            type: item.ansimgtype ? 'image' : 'text',
            answerid: index.toString(),
          });
          this.correctanswer.push({
            questionid: index.toString(),
            answerid: index.toString()
          })
        });
        finalquestion = {
          title: this.question,
          questions: questionlist,
        };
      }
 
      // Upload Image
      var nameList: any[] = [];
      var filestobeuploaded: any[] = [];
      if (this.type != '004' && this.imageList.length != 0) {
        this.imageList.map((data: any) => {
          if (data.file) {
            filestobeuploaded.push(data);
          }
          nameList.push(data.filename);
        });

        // Compress image
        if (filestobeuploaded.length != 0) {
          for (let i = 0; i < filestobeuploaded.length; i++) {
            if (filestobeuploaded[i].file.size >= 307200) {
              filestobeuploaded[i].file = await this.allinoneService.compressImage(
                filestobeuploaded[i].file
              );
              if (filestobeuploaded[i].file == false) {
                this.messageService.openSnackBar(
                  'Error while uploading your file.',
                  'warn'
                );
                this.isStillUpload = false;
                return;
              }
            }
          }
        }

        if (filestobeuploaded.length != 0) {

         


          const result =
            await this.allinoneService.multipleUploadToAWS(
              filestobeuploaded,
              'quiz'
            );
          if (!result) {
            this.messageService.openSnackBar(
              'There was an error uploading your image.',
              'warn'
            );
            return;
          }
        }
      } else if (this.type == '004') {
        var filestobeuploaded2: any[] = [];

        this.matchingImgList.map((data: any) => {
          if (data.file) {
            filestobeuploaded2.push(data);
          }
        });

        // Compress image
        if (filestobeuploaded2.length != 0) {
          for (let i = 0; i < filestobeuploaded2.length; i++) {
            if (filestobeuploaded2[i].file.size >= 307200) {
              filestobeuploaded2[i].file = await this.allinoneService.compressImage(
                filestobeuploaded2[i].file
              );
              if (filestobeuploaded2[i].file == false) {
                this.messageService.openSnackBar(
                  'Error while uploading your file.',
                  'warn'
                );
                this.isStillUpload = false;
                return;
              }
            }
          }
        }

        if (filestobeuploaded2.length != 0) {

       
          const result =
            await this.allinoneService.multipleUploadToAWS(
              filestobeuploaded2,
              'quiz'
            );
          if (!result) {
            this.messageService.openSnackBar(
              'There was an error uploading your image.',
              'warn'
            );
            return;
          }
        }

      }

      var fun = this.isEditing
        ? this.courseService.updateQuestion(
          this.qforedit.quizid,
          finalquestion,
          finalanswer,
          this.correctanswer,
          this.type,
          this.sortkey.toString(),
          finalcasesensitve,
          this.courseid,
          this.type == '004' ? [] : nameList,
          this.type == '004' ? [] : this.deleteimagelist,
          this.countbyquantity,
          this.type == '003' ? this.fixedsequence : false,
          Number(this.mark),
        )
        : this.courseService.addQuestion(
          this.unitid,
          this.courseid,
          finalquestion,
          finalanswer,
          this.correctanswer,
          this.type,
          this.sortkey.toString(),
          finalcasesensitve,
          false,
          this.type == '004' ? [] : nameList,
          this.countbyquantity,
          this.type == '003'? this.fixedsequence : false,
          Number(this.mark),
        );
      console.log("sub >>>>> ", fun);

      fun.subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            sessionStorage.setItem('qtype', this.type);
            this.dialogRef.close(res);
            this.messageService.openSnackBar('Successful.', 'success');
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
            this.isStillUpload = false;
          }
        },
        (err) => {
          this.isStillUpload = false;
        }
      );
    }
  }

  changeCaseSensitive(event: any) {
    this.casesensitive = event.target.checked;
  }

  changeCountByQ(event : any) {
    this.countbyquantity = event.target.checked;
  }

  removeMatching(index: number) {
    // console.log(this.matching[index]);
    this.deleteMatchingImg(this.matching[index].quizimgid);
    this.deleteMatchingImg(this.matching[index].ansimgid);

    if (this.matching.length > 1) {
      this.matching.splice(index, 1);
    }
  }



}
