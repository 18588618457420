import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-grade-book-reset-quiz',
  templateUrl: './grade-book-reset-quiz.component.html',
  styleUrls: ['./grade-book-reset-quiz.component.scss'],
})
export class GradeBookResetQuizComponent implements OnInit {
  quizname: string = '';
  studentname: string = '';
  quizresultid: string = '';
  unitid: string = '';

  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<GradeBookResetQuizComponent>,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data) {
      this.quizname = data.unitname;
      this.studentname = data.student;
      this.quizresultid = data.id;
      this.unitid = data.unitid;
    }
  }

  ngOnInit(): void {}

  save() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      quizresultid: this.quizresultid,
      unitid: this.unitid,
    };
    this.courseService.gradebookResetQuiz(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Successful.', 'success');
          this.dialogRef.close({
            success: true,
          });
          window.location.reload();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  dismiss() {
    this.dialogRef.close();
  }
}
