<app-quiz-pdf (sendData)="receiveDataFromChild($event)" [courseid]="courseid" [unitid]="unitId"
  [studentname]="studentName" [studentId]="studentId" [coursename]="coursename" [element]="elementData" [elementAllData]="elementAllData"
  *ngIf="pdfPrint"></app-quiz-pdf>
<div *ngIf="!pdfPrint">
  <div class="mb-3">
    <h2 class="mb-0">Grade Book</h2>
  </div>
  <!-- <button (click)="t()">t</button> -->
  <div class="d-flex align-items-center flex-row flex-wrap justify-content-between">
    <div class="d-flex align-items-center flex-wrap">
      <div class="form-check me-5 mb-3">
        <input class="form-check-input" type="radio" name="gradetype" id="assignment" value="7" [(ngModel)]="gradetype"
          (ngModelChange)="changeType()">
        <label class="form-check-label" for="assignment">
          Assignments
        </label>
      </div>
      <div class="form-check me-5 mb-3">
        <input class="form-check-input" type="radio" name="gradetype" id="quiz" value="6" [(ngModel)]="gradetype"
          (ngModelChange)="changeType()">
        <label class="form-check-label" for="quiz">
          Quiz
        </label>
      </div>
      <div class="form-check me-auto mb-3">
        <input class="form-check-input" type="radio" name="gradetype" id="total" value="0" [(ngModel)]="gradetype"
          (ngModelChange)="changeType()">
        <label class="form-check-label" for="total">
          Total
        </label>
      </div>
    </div>
    <div class="d-flex flex-wrap align-items-center mb-3">
      <!-- <ng-container *ngIf="alldata.length > 0 && gradetype != '0' && gradetype != '7' && gradetype == '6' ">
        <button color=" primary" class="btn new-class" (click)="downloadPDFSheet()">
          <img class="excel-icon" src="../../../assets/images/pdf.png">
          Export PDF
        </button>
        <div class="line">

        </div>
      </ng-container>
      <ng-container *ngIf="alldata.length > 0">
        <button color="primary" class="btn new-class" (click)="downloadSheet()">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
      </ng-container>
        <div class="line"></div> -->

      <div class="form-group" style="width: 200px !important;">
        <select class="form-select" (change)="changeType()" [(ngModel)]="classid" matTooltip="Filter by Class"
          [disabled]="gettingClass">
          <option value="">All</option>
          <option [value]="class.classid" *ngFor="let class of classlist">
            {{ class.classcode }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingClass"></mat-progress-bar>
      </div>
      <!-- <div class="line"></div> -->

      <div class="input-group ms-2" style="width: 200px !important;">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
        <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
          <i class="fa fa-times"></i>
        </button>
      </div>

      <button class="btn new-class" matTooltip="Refresh" (click)="getGradebook()" type="button"
        style="margin-left: 10px;">
        <i class="fa fa-refresh"></i>
      </button>

      <div *ngIf="alldata.length > 0 && gradetype != '0' && gradetype != '7' && gradetype == '6' ">
        <button class="btn new-class" matTooltip="Filter" (click)="openFilterDialog()" type="button" style="margin-left: 10px;">
          Filter
        </button>
      </div>

      <ng-container *ngIf="alldata.length > 0">
        <div class="line">

        </div>
        <button color="primary" class="btn new-class" (click)="downloadSheet()">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>

      </ng-container>

      <ng-container *ngIf="alldata.length > 0 && gradetype != '0' && gradetype != '7' && gradetype == '6' ">
        <button color=" primary" class="btn new-class ms-2" (click)="downloadPDFSheet()">
          <img class="excel-icon" src="../../../assets/images/pdf.png">
          Export PDF
        </button>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!nogradeableunits; else elseTemplate">
    <section class="table-container" tabindex="0" *ngIf="!gettinggrade && alldata.length > 0">
      <table mat-table [dataSource]="showdata" id="table" [ngClass]="gradetype == '0' ? 'total' : ''">
        <!-- Name Column -->
        <ng-container matColumnDef="name" sticky>
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element;" (click)=" gradetype == '0' ? totalDetails(element) : '' ">
            {{element.username || element.userid}} </td>
        </ng-container>

        <!-- Total percentage -->
        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef> Percentage
          </th>

          <td mat-cell *matCellDef="let element" (click)="totalDetails(element)"> {{element.totalpercentage}} % </td>

        </ng-container>

        <!-- Unit Column -->
        <!-- <ng-container *ngIf="gradetype != '0'">
          <ng-container matColumnDef="{{unit.unitid}}" *ngFor="let unit of unitlist; let i = index">
            <th mat-header-cell *matHeaderCellDef>
              <span class="table-header" *ngIf="gradetype == '6'">
                {{unit.unitname || 'Quiz ['+unit.unitid.slice(0,10)+']'}}
              </span>
              <span class="table-header" *ngIf="gradetype == '7'">
                {{unit.unitname || 'Assignment ['+unit.unitid.slice(0,10)+']'}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element" (click)="editResult(element, element.grade[i])">
              {{element.grade[i].grade}}</td>
          </ng-container>
        </ng-container> -->

        <ng-container *ngIf="gradetype != '0' && gradetype != '7' && gradetype == '6' ">
          <ng-container matColumnDef="{{unit.unitid}}" *ngFor="let unit of unitlist; let i = index">
            <th [ngClass]="(selectedUnitId && selectedUnitId !== unit.unitid) ? 'hidden' : '' " mat-header-cell *matHeaderCellDef>
              <span class="table-header" *ngIf="gradetype == '6'">
                {{unit.unitname}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element" (click)="quizPdfPrintPreView(element.grade[i],element)" [ngClass]="(selectedUnitId && selectedUnitId !== unit.unitid) ? 'hidden' : '' " >
              {{element.grade[i].grade}}
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="gradetype != '0' && gradetype != '6' && gradetype == '7' ">
          <ng-container matColumnDef="{{unit.unitid}}" *ngFor="let unit of unitlist; let i = index">
            <th mat-header-cell *matHeaderCellDef>
              <span class="table-header" *ngIf="gradetype == '7'">
                {{unit.unitname}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element" (click)="editResult(element, element.grade[i])">
              {{element.grade[i].grade}}</td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td colspan="5">
            <span class="nodata">Empty</span>
          </td>
        </tr>

      </table>
    </section>
    <div *ngIf="gettinggrade" class="nodata">
      <div class="spinner-border spinner-border-md main-color">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="!gettinggrade && alldata.length == 0" class="nodata">
      No students
    </div>

  </ng-container>

  <ng-template #elseTemplate>
    <div class="nodata">
      Your course do not have any {{gradetype == '6' ? 'quiz.' : gradetype == '7' ? 'assignments.' : 'total.'}}
    </div>
  </ng-template>
</div>
