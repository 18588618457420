import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-feedback-template',
  templateUrl: './feedback-template.component.html',
  styleUrls: ['./feedback-template.component.scss'],
})
export class FeedbackTemplateComponent implements OnInit {
  role: string = '';

  gettingChannels: boolean = false;
  gettingCourses: boolean = false;
  gettingResult: boolean = false;
  channelname: string = '';
  channelid: string = '';
  courseid: string = '';
  coursename : string = '';

  channels: any[] =[];
  courses: any[] =[];

  feedbackData: any; 
  feedbackTableData! : MatTableDataSource<any>;

  displayedColumns = ['question', '1', '2', '3', '4', '5']

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }
  }

  ngOnInit(): void {
    this.getChannels();
    this.getCourses();

  }

  getResults(){
    this.gettingResult = true;
    this.courseService.getFeedbackData(this.courseid).subscribe((res: any) => {
      if(res.returncode == '300'){
        this.feedbackData = res.datalist;
        this.feedbackTableData = new MatTableDataSource(this.feedbackData);
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.gettingResult = false;
    }, err => {
      this.gettingResult = false;
    })
  }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'admin') {
      this.channelname = 'KHub';
      this.courseService.channelGet().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist.filter(
              (channel: any) => channel.status == '1'
            );
            var schannel = this.channels.find(
              (x: any) => x.channelid == this.allinoneService.selectedChannelid
            );
            this.channelid = schannel
              ? schannel.channelid
              : this.channels[0].channelid;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingChannels = false;
        },
        (err) => {
          this.gettingChannels = false;
        }
      );
    } else if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.channelid = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
    } else {
      this.channels = this.allinoneService.supervisorChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.channelid = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
    }
    this.getCourses();
  }

  channelChange(event: any){
    this.allinoneService.selectedChannelid = this.channelid;
    this.getCourses();
  }

  courseChange(){
    this.getResults();
  }

  getCourses() {
    this.gettingCourses = true;
    this.courseService
      .getCoursesByChannel(this.channelid, '')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.courses = res.datalist;
            if (this.courses.length > 0) {
              this.courses.sort((a,b) => {
                return this.allinoneService.compare(a.coursename , b.coursename , true);
              })
              this.courseid = this.courses[0].courseid;
              this.coursename = this.courses[0].coursename;
              this.getResults();
            }
            else{
              this.courseid = '';
              this.coursename = '';
            }
            
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingCourses = false;
        },
        (err) => {
          this.gettingCourses = false;
        }
      );
  }

  refresh(){
    this.getResults();
  }

}
