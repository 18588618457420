<div class = "h-100 w-100 d-flex align-items-center justify-content-center">
    <div class = "d-flex">
        <div class = "me-3">
            <img src="assets/images/unauthorized.jpg" alt="" class="navbar-brand-logo" width="400px" >
        </div>
        <div class = "d-flex flex-column align-items-start justify-content-center">
            <div class = "my-msg">
                {{allinoneService.unauthorizedMsg}}
            </div>
            <div style="padding-bottom: 20px;">
                Please contact&nbsp;<strong class = "mailto" (click) ="mailto()">system admin</strong>&nbsp;for more details.
            </div>
            <div class = "d-flex align-items-center">
                    <button (click)="allinoneService.logout()" mat-stroked-button color="primary">Sign in to another account</button>
                    <span class ="mx-3">or</span>                
                    <span class = "reload" (click)="allinoneService.reload()">Reload</span>
            </div> 
        </div>  
    </div> 
</div>
