import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-studentslist',
  templateUrl: './studentslist.component.html',
  styleUrls: ['./studentslist.component.scss']
})
export class StudentslistComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;


  channels: any[] = [];
  students: any[] = [];
  displayedColumns: string[] = [];

  channelid: string = '';
  searchText: string = '';

  gettingChannel: boolean = false;
  isStudentLoading: boolean = false;
  studentPaginate: boolean = false;
  isFocus: boolean = false;

  showstudents! : MatTableDataSource<any>;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
  ) { }

  ngOnInit(): void {
    this.displayedColumns = ['userid', 'username', 'role', 'position', 'department', 'division','remark']
    this.getChannel();
    this.getStudents();
  }

  getChannel() {
    this.channels = [];
    this.gettingChannel = true;
    this.courseService.channelGet().subscribe(
      (channel_data: any) => {
        if (channel_data.returncode == '300') {
          this.channels = channel_data.datalist;          
        } else {
          this.gettingChannel = false;
          if (channel_data.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(channel_data.status, 'warn');
          }
        }
        this.gettingChannel = false;
      },
      (err) => {
        this.gettingChannel = false;
      }
    );
  }

  getStudents() {
    this.students = [];
    this.showstudents = new MatTableDataSource(this.students);
    this.isStudentLoading = true;
    const data = {
      "channelid": this.channelid
    }
    this.courseService.getMember(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.isStudentLoading = false;
        this.students = res.datalist;
        this.showstudents = new MatTableDataSource(this.students);
        if (this.students == undefined || this.students.length == 0) {
          this.studentPaginate = false;
        } else {
          this.studentPaginate = true;
        }

        this.showstudents.sort = this.sort;
        this.showstudents.paginator = this.paginator;


        setTimeout(() => {
          this.isStudentLoading = false;
        }, 400);

        // this.table.renderRows();
      }
      else {
        if (res.returncode == "210") {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
        }
        else {
          this.messageService.openSnackBar(res.status || res.message || "Server Error", "warn");
        }
      }
    }, err => {
      this.isStudentLoading = false;
    })
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showstudents.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = "";
    this.showstudents.filter = "";
  }

  refresh() {
    this.clear();
      this.getStudents();
  }

}
