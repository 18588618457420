import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.scss'],
})
export class VideoViewComponent implements OnInit, OnDestroy {
  allData: any[] = [];
  displayedColumns: any;
  showVideos: MatTableDataSource<any> = new MatTableDataSource(this.allData);

  searchText: string = '';
  isFocus: boolean = false;
  isLoading: boolean = false;
  paginate: boolean = false;
  courseid: string = '';

  datacount: string = '0';
  getListSubscription!: Subscription;

  exportLoading: boolean = false;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    const coid = this.route.snapshot.params['courseid'];
    this.courseid = coid;
  }

  ngOnInit(): void {
    this.displayedColumns = ['unitname', 'type'];
    this.getVideoView();
  }

  ngOnDestroy(): void {
    this.getListSubscription && this.getListSubscription.unsubscribe();
  }

  // downloadAssignment() {
  //   this.downloadDropBoxUnit = true;
  //   var data = {
  //     unitid : this.selectedUnit,
  //     courseid : this.courseid,
  //     classid : this.classid,
  //   }
  //   this.courseService.downloadAssignment(data).subscribe(
  //     (res:any) => {
  //       console.log(res);

  //       if(res.returncode == '300') {
  //         if(res.url) {
  //           window.open(res.url, 'download')
  //         }
  //       } else {
  // this.messageService.openSnackBar(
  //   res.status || res.message || res.error,
  //   'warn'
  // );
  //       }
  //       this.downloadDropBoxUnit = false;
  //     },
  //     (err:any) => {
  //       this.downloadDropBoxUnit = false;
  //     }
  //   )
  // }

  downloadSheet() {
    this.exportLoading = true;
    var data = {
      courseid: this.courseid,
      unitid: '',
    };
    this.courseService.getVideoTracking(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (res.url) {
            // await this.downloadFile(res.url, res.filename);
            var linkToFile = document.createElement('a');
            linkToFile.download = res.filename;
            linkToFile.href = res.url;
            linkToFile.click();
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
          }
          this.exportLoading = false;
        }
      },
      (err: any) => {
        this.exportLoading = false;
      }
    );
  }

  downloadFile(url: string, name: string) {
    // window.open(url, '_blank');

    return new Promise((resolve) => {
      this.courseService.downloadFile(url).subscribe((response: any) => {
        saveAs(response, name);
        resolve(true);
      }),
        (error: any) => {
          this.messageService.openSnackBar('Download failed.', 'warn');
          resolve(false);
        };
    });
  }

  getVideoView() {
    this.isLoading = true;
    const data = {
      courseid: this.courseid,
      unitid: '',
      contenttype: '1',
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.courseService
      .getAssignmentDeadline(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allData = res.datalist;
            this.datacount = this.allData.length.toString();
            this.showVideos = new MatTableDataSource(this.allData);
            console.log('your data is');

            console.log(this.allData);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isLoading = false;
        },
        (err: any) => {
          this.isLoading = false;
        }
      );
  }

  gotoDetails(data: any) {
    console.log('call this fun');

    // console.log(data);
    // 4200/instructor/courses/20526197495244692181/videos
    // this.router.navigateByUrl(`instructor/courses/videos/${data.courseid}/${data.unitid}`);
    this.router.navigateByUrl(
      `videos/${data.courseid}/${data.unitid}/${data.unitname}`
    );
  }

  checkType(data: any) {
    var type = 'Library';
    if (
      data.toString().startsWith('https://youtu.be/') ||
      data.toString().startsWith('https://www.youtube.com')
    ) {
      type = 'Youtube';
    } else if (
      // https://vimeo.com/347119375
      data.toString().startsWith('https://vimeo.com')
    ) {
      type = 'Vimeo';
    } else {
      type = 'Library';
    }
    return type;
  }

  sortData(sort: Sort) {
    const data = this.allData;
    if (!sort.active || sort.direction === '') {
      this.allData = data;
      return;
    }

    this.allData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unitname':
          return this.allinoneService.compare(a.unitname, b.unitname, isAsc);
        case 'type':
          return this.allinoneService.compare(
            this.checkType(a.content),
            this.checkType(b.content),
            isAsc
          );
        default:
          return 0;
      }
    });
    this.showVideos = new MatTableDataSource(this.allData);
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showVideos.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = '';
    this.showVideos.filter = '';
  }
}
