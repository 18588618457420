<!-- <div class="custom-web-breadcrumb">
    <span class="active-breadcrumb" routerLink="/home">Home</span>
    <i class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb}" (click)="goToMyCourses()"> {{s_breadcrumb}}</span>
    <i *ngIf="t_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span *ngIf="t_breadcrumb"> {{t_breadcrumb}}</span>
</div> -->

<!-- 
<div class="custom-web-breadcrumb">
    <span class="active-breadcrumb" routerLink="/home">Home</span>
    <i class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb }" (click)="emitfunction1()"> {{s_breadcrumb}}</span>
    <i *ngIf="a_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb}" (click)="emitfunction2()"> {{a_breadcrumb}}</span>
    <i *ngIf="t_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span *ngIf="t_breadcrumb"> {{t_breadcrumb}}</span>
</div> -->

<div class="custom-web-breadcrumb">
    <span class="active-breadcrumb" routerLink="/home">Home</span>
    <i class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb }" (click)="emitfunction1()"> {{s_breadcrumb}}</span>
    <i *ngIf="a_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb}" (click)="emitfunction2()"> {{a_breadcrumb}}</span>
    <i *ngIf="b_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb}" (click)="emitfunction3()"> {{b_breadcrumb}}</span>
    <i *ngIf="t_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span *ngIf="t_breadcrumb"> {{t_breadcrumb}}</span>
</div>