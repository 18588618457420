<div mat-dialog-title>
    <h3><strong>Upload Options</strong></h3>
</div>
<div mat-dialog-content>
    <div class="mb-2">The follwing files already exists in this location.</div>
    <ul>
        <li *ngFor="let item of filelist">
            {{item}}
        </li>
    </ul>
</div>

<div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="onDismiss()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onConfirm()">Replace</button>
</div>