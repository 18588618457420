import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Schedule } from '../../shared/models/Schedule';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-coures-schedule',
  templateUrl: './coures-schedule.component.html',
  styleUrls: ['./coures-schedule.component.scss'],
})
export class CouresScheduleComponent implements OnInit {
  @Input('schedule') schedule!: Schedule;
  @Output('updateSchedule')
  save: EventEmitter<any> = new EventEmitter<any>();

  isLoading: boolean = false;
  formSubmitted: boolean = false;

  formattedSchedule: Schedule = {
    startdate: '',
    enddate: '',
    starttime: '',
    endtime: '',
  };

  courseid: string = '';

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
  }

  ngOnInit(): void {
    console.log(this.schedule);
    this.formattedSchedule.startdate = this.schedule.startdate
      ? this.datePipe
          .transform(
            this.allinoneService.formatDateForDiscussionDatePipe(
              this.schedule.startdate
            ),
            'yyyy-MM-dd'
          )
          ?.toString()
      : '';
    this.formattedSchedule.enddate = this.schedule.enddate
      ? this.datePipe
          .transform(
            this.allinoneService.formatDateForDiscussionDatePipe(
              this.schedule.enddate
            ),
            'yyyy-MM-dd'
          )
          ?.toString()
      : '';
    this.formattedSchedule.starttime = this.schedule.starttime || '';
    this.formattedSchedule.endtime = this.schedule.endtime || '';
  }

  updateSchedule() {
    if (
      (this.formattedSchedule.startdate ||
        this.formattedSchedule.enddate ||
        this.formattedSchedule.starttime ||
        this.formattedSchedule.endtime) &&
      (!this.formattedSchedule.startdate ||
        !this.formattedSchedule.enddate ||
        !this.formattedSchedule.starttime ||
        !this.formattedSchedule.endtime)
    ) {
      this.formSubmitted = true;
      return;
    }

    if(this.formattedSchedule.startdate &&
      this.formattedSchedule.enddate &&
      this.formattedSchedule.starttime &&
      this.formattedSchedule.endtime){
      if (this.formattedSchedule.startdate! > this.formattedSchedule.enddate!) {
        this.messageService.openSnackBar(
          'Please select a valid end date.',
          'warn'
        );
        return;
      }
      if (
        this.formattedSchedule.startdate! == this.formattedSchedule.enddate! &&
        this.formattedSchedule.starttime >= this.formattedSchedule.endtime
      ) {
        this.messageService.openSnackBar(
          'Please select a valid end time.',
          'warn'
        );
        return;
      }
    }
    this.isLoading = true;
    var startdate : any= '';
    var enddate : any= '';
    var starttime: any = '';
    var endtime : any= '';
    if (this.formattedSchedule.startdate) {
      startdate = this.datePipe.transform(
        this.formattedSchedule.startdate,
        'yyyyMMdd'
      );
      enddate = this.datePipe.transform(
        this.formattedSchedule.enddate,
        'yyyyMMdd'
      );
      starttime = this.formattedSchedule.starttime
      endtime = this.formattedSchedule.endtime
    }
    this.courseService
      .updateSchedule(this.courseid, startdate!,enddate!,starttime!, endtime!)
      .subscribe((res: any) => {
        if(res.returncode == '300'){
          // emit 
          var schedule: Schedule= {
            startdate: startdate,
            enddate: enddate,
            starttime: starttime,
            endtime: endtime
          }
          this.save.emit(schedule);
          this.messageService.openSnackBar('Updated successfully.', 'success');
        }
        else{
          this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      });
  }

  clear() {
    this.formSubmitted = false;
    this.formattedSchedule = {
      startdate: '',
      starttime: '',
      enddate: '',
      endtime: '',
    };
  }
}
