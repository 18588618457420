import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CourseService } from '../services/course.service';
// import { Co } from './services/all-in-one.service';
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'filter',
  pure: false,
})
export class InstructorFilterPipe implements PipeTransform {
  constructor(private allinoneService: CourseService) {}

  /**
   * Transform
   *
   * @param {any[]} items
   * @param {any} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string, type: any): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    var returnItems: any[];

    switch (type) {
      //search in chat list
      // case 'campaign':
      //   returnItems = items.filter((it) => {
      //     return it.camname.toString().toLocaleLowerCase().includes(searchText);
      //   });
      //   return returnItems;
       
        case 'studentsbycourse':
          returnItems = items.filter((it) => {
            return (
              it.userid?.toString().toLocaleLowerCase().includes(searchText) || 
              it.username?.toString().toLocaleLowerCase().includes(searchText) || 
              it.totaltime?.toString().toLocaleLowerCase().includes(searchText) || 
              it.grade?.toString().toLocaleLowerCase().includes(searchText) || 
              it.percentage?.toString().toLocaleLowerCase().includes(searchText) || 
              it.completed?.toString().toLocaleLowerCase().includes(searchText)
            );
          });
          return returnItems;


          case 'coursesbystudent':
          returnItems = items.filter((it) => {
            return (
              it.coursename?.toString().toLocaleLowerCase().includes(searchText) || 
              it.totaltime?.toString().toLocaleLowerCase().includes(searchText) || 
              it.grade?.toString().toLocaleLowerCase().includes(searchText) || 
              it.percentage?.toString().toLocaleLowerCase().includes(searchText) || 
              it.completed?.toString().toLocaleLowerCase().includes(searchText)
            );
          });
          return returnItems;

          case 16:
            returnItems = items.filter((it) => {          
              return (it.username
                .toString()
                .toLocaleLowerCase()
                .includes(searchText)
                 ||
                it.userid
                .toString()
                .toLocaleLowerCase()
                .includes(searchText)
                );
            });
            return returnItems;
        
          

      default:
        returnItems = items.filter((it) => {
          return it.name.toString().toLocaleLowerCase().includes(searchText);
        });
        return returnItems;
    }
  }
}


// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'filter',
// })
// export class StudentFilterPipe implements PipeTransform {
//   transform(items: any[], searchText: string, type: any): any[] {
//     if (!items) {
//       return [];
//     }
//     if (!searchText) {
//       return items;
//     }
//     searchText = searchText.toLocaleLowerCase();

//     var returnItems: any[];

//     switch (type) {
//       case 'deadline':
//         returnItems = items.filter((it) => {
//           return (
//             it.classname?.toString().toLocaleLowerCase().includes(searchText) ||
//             it.studentname?.toString().toLocaleLowerCase().includes(searchText) ||
//             it.userid?.toString().toLocaleLowerCase().includes(searchText)
//           );
//         });
//         return returnItems;

        // case 'studentsbycourse':
        // returnItems = items.filter((it) => {
        //   return (
        //     it.userid?.toString().toLocaleLowerCase().includes(searchText) || 
        //     it.username?.toString().toLocaleLowerCase().includes(searchText) || 
        //     it.totaltime?.toString().toLocaleLowerCase().includes(searchText) || 
        //     it.grade?.toString().toLocaleLowerCase().includes(searchText) || 
        //     it.percentage?.toString().toLocaleLowerCase().includes(searchText) || 
        //     it.completed?.toString().toLocaleLowerCase().includes(searchText)
        //   );
        // });
        // return returnItems;
      

//       default:
    //     returnItems = items.filter((it) => {          
    //       return (it.username
    //         .toString()
    //         .toLocaleLowerCase()
    //         .includes(searchText)
    //          ||
    //         it.userid
    //         .toString()
    //         .toLocaleLowerCase()
    //         .includes(searchText)
    //         );
    //     });
    //     return returnItems;
    // }
//   }
// }
