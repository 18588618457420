import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { UpdateScheduleComponent } from '../update-schedule/update-schedule.component';

@Component({
  selector: 'app-admin-schedule',
  templateUrl: './admin-schedule.component.html',
  styleUrls: ['./admin-schedule.component.scss']
})
export class AdminScheduleComponent implements OnInit {

  @ViewChild('paginator') paginator!: MatPaginator;

  courses: any[] = [];
  channels: any[] = [];
  displayedColumns: string[] = ['image', 'coursename','status', 'startdate', 'enddate']

  gettingCourses: boolean = false;
  gettingmyChannel: boolean = false;
  isFocus: boolean = false;
  gettingChannels: boolean = false;

  searchText: string = '';
  selectedChannel: string = '';
  role: string = 'supervisor'; // supervisor , chladmin
  

  coursesTableData!: MatTableDataSource<any>;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute : ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) || (this.role == 'supervisor' && !this.allinoneService.isSupervisor())) {
      this.router.navigateByUrl('')
    }
   }


  ngOnInit(): void {
    this.getChannels();
    if(this.channels.length > 0){
      this.getCourses();
    }
  }

  clear(){
    this.coursesTableData.filter = '';
    this.searchText = '';
  }

  sortCategoryData(sort: Sort) {
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.allinoneService.compare(a.coursename, b.coursename, isAsc);
          case 'status':
          return this.allinoneService.compare(a.schedulestatus, b.schedulestatus, isAsc);
          case 'startdate':
          return this.allinoneService.compare(a.startdate + a.starttime, b.startdate + b.starttime, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate + a.endtime, b.enddate + b.endtime, isAsc);
        default:
          return 0;
      }
    });
    this.coursesTableData = new MatTableDataSource(this.courses);
  }

  getCourses(){
    this.gettingCourses = true;
    this.allinoneService.selectedChannelid = this.selectedChannel;
    this.courses = [];
    this.coursesTableData = new MatTableDataSource(this.courses);
    this.courseService.getInsctructorledCourse(this.selectedChannel).subscribe((res: any) => {
      if(res.returncode =="300"){
        this.courses = res.datalist;
        this.coursesTableData = new MatTableDataSource(this.courses);
        this.coursesTableData.paginator = this.paginator;
      } 
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.gettingCourses = false;
    }, err => {
      this.gettingCourses = false;
    })
  }

  goToSetting(){
    this.router.navigateByUrl('admin/settings');
  }

  searchItem(event: any){
    const searchItem = event.target.value;
    this.coursesTableData.filter = searchItem.trim().toLowerCase();
  }

  getChannels(){
    this.gettingChannels = true;
    if(this.role == 'supervisor'){
      this.channels = this.allinoneService.supervisorChannels;
    }
    else{
      this.channels = this.allinoneService.adminChannels;
    }
    if(this.channels.length > 0){
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.selectedChannel = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
    }
    this.gettingChannels = false;
    // this.courseService.channelGet().subscribe((res: any) => {
    //   if(res.returncode == "300"){
    //     this.channels = res.datalist.filter((channel: any) => channel.status == '1');
    //   }
    //   else{
    //     this.messageService.openSnackBar(res.message || res.status || "Server Error", 'warn');
    //   }
    //   this.gettingChannels = false;
    // }, err => {
    //   this.gettingChannels = false;
    // })
  }


  editSchedule(course: any){
    const dialog = this.dialog.open(UpdateScheduleComponent, {
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        course: course
      }
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if(dialogResult){
        course.startdate = dialogResult.startdate;
        course.enddate = dialogResult.enddate;
        course.starttime = dialogResult.starttime;
        course.endtime = dialogResult.endtime;
        course.schedulestatus = dialogResult.schedulestatus;
      }
    })
  }
}
