import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MessageService } from '../../shared/services/message.service';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import { CourseService } from '../../shared/services/course.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LoadingService } from '../../shared/services/loading.service';
import { TemplatePreviewComponent } from '../template-preview/template-preview.component';
import { DatePipe } from '@angular/common';
import { base64ToFile } from 'ngx-image-cropper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { ComponentCanDeactivate } from '../../shared/guards/can-deactivate-forms.guard';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit, AfterViewInit, ComponentCanDeactivate  {

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    var check = this.checkChangesFields();
    return check;
  }



  @ViewChild('hiddenFileInput') choosefile: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const app = document.getElementById(
      this.fullScreen ? 'template-bg-preview' : 'template-bg'
    );
    if (app) {
      app!.style.fontSize = (app!.clientWidth / 50).toString() + 'px';
    }
  }

  isNew: boolean = false;
  fullScreen: boolean = false;
  isLoading: boolean = false;
  gettingTemplate: boolean = false;
  templateForEdit: any = '';
  role: string = '';
  previewImg: any;

  fontWeightList = [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'bold',
    'bolder',
  ];

  fontFamilyList = [
    'unset',
    'Carattere',
    'Bispo',
    'Celandine',
    'DroidSerif',
    'KelvinchRoman',
    'LoraItalic',
    'TheSeaSons',
    'GreatVibes',
    'RobotoCondensed',
    'AlexBrush',
  ];

  fileSelected: any;
  backgroundImg: any;
  padding: number = 0;
  selectedIndex: any = -1;

  defaultList: any[] = [
    {
      id: '0',
      content: 'Certificate of Completion',
      left: '10',
      top: '13',
      fontSize: '3.5',
      fontWeight: '900',
      isCenter: true,
      color: '#000000',
      fontFamily: 'Carattere',
      lineHeight: '1',
    },
    {
      id: '1',
      content: 'This certificate is awarded to',
      left: '10',
      top: '30',
      fontSize: '2',
      fontWeight: '900',
      isCenter: true,
      color: '#000000',
      fontFamily: 'Carattere',
      lineHeight: '1',
    },
    {
      id: '2',
      content: '[[name]]',
      left: '10',
      top: '45',
      fontSize: '3',
      fontWeight: '900',
      isCenter: true,
      color: '#000000',
      fontFamily: 'unset',
      lineHeight: '1',
    },
    {
      id: '3',
      content: 'for the completion of [[course]] on KHub.',
      left: '10',
      top: '64',
      fontSize: '2',
      fontWeight: '900',
      isCenter: true,
      color: '#000000',
      fontFamily: 'Bispo',
      lineHeight: '1',
    },
    {
      id: '4',
      content: '[[date]]',
      left: '10',
      top: '77',
      fontSize: '1',
      fontWeight: '500',
      isCenter: true,
      color: '#000000',
      fontFamily: 'unset',
      lineHeight: '1',
    },
  ];

  elementList: any[] = [];

  allowedImageType = ['image/png', 'image/jpg', 'image/jpeg'];

  templateList: any[] = [];

  channelid: string = '';
  gettingChannels: boolean = false;
  channelname: string = '';
  channels: any[] = [];

  array = [
    {
      "padding": "",
      "paddingvalue": "",
      "value": [
        {
          ccontent: "",
          ctop: "",
          cfontsize: "",
          cfontweight: "",
          ciscenter: "",
          ccolor: "",
          cfontfamily: "",
          clineheight:'',

          id: "0",
          content: 'Certificate of Completion',
          top: '13',
          fontSize: '3.5',
          fontWeight: '900',
          isCenter: true,
          color: "#000000",
          fontFamily: 'Carattere',
          lineHeight:'1'
        },
        {
          ccontent: "",
          ctop: "",
          cfontsize: "",
          cfontweight: "",
          ciscenter: "",
          ccolor: "",
          cfontfamily: "",
          clineheight:'',
          id: "1",
          content: 'This certificate is awarded to',
          top: '30',
          fontSize: '2',
          fontWeight: '900',
          isCenter: true,
          color: '#000000',
          fontFamily: 'Carattere',
          lineHeight:'1'
        },
        {
          ccontent: "",
          ctop: "",
          cfontsize: "",
          cfontweight: "",
          ciscenter: "",
          ccolor: "",
          cfontfamily: "",
          clineheight:'',
          id: "2",
          content: '[[name]]',
          top: '45',
          fontSize: '3',
          fontWeight: '900',
          isCenter: true,
          color: '#000000',
          fontFamily: 'unset',
          lineHeight:'1'
        },
        {
          ccontent: "",
          ctop: "",
          cfontsize: "",
          cfontweight: "",
          ciscenter: "",
          ccolor: "",
          cfontfamily: "",
          clineheight:'',
          id: "3",
          content: 'for the completion of [[course]] on KHub.',
          top: '64',
          fontSize: '2',
          fontWeight: '900',
          isCenter: true,
          color: '#000000',
          fontFamily: 'Bispo',
          lineHeight:'1'
        },
        {
          ccontent: "",
          ctop: "",
          cfontsize: "",
          cfontweight: "",
          ciscenter: "",
          ccolor: "",
          cfontfamily: "",
          clineheight:'',
          id: "4",
          content: '[[date]]',
          top: '77',
          fontSize: '1',
          fontWeight: '500',
          isCenter: true,
          color: '#000000',
          fontFamily: 'unset',
          lineHeight:'1'
        },
      ],
    }

  ];

  constructor(
    private messageService: MessageService,
    private courseService: CourseService,
    private allinoneService: AllInOneService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
  }

  ngOnInit(): void {
    this.getChannels();
    this.getTemplate();
  }

  ngAfterViewInit(): void { }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'admin') {
      this.channelname = 'KHub';
      this.courseService.channelGet().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist.filter(
              (channel: any) => channel.status == '1'
            );
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingChannels = false;
        },
        (err) => {
          this.gettingChannels = false;
        }
      );
    } else if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      this.channelid = this.channels[0].channelid;
      this.gettingChannels = false;
      this.channelname = this.channels.find(
        (x: any) => x.channelid == this.channelid
      ).name;
    } else {
      this.channels = this.allinoneService.supervisorChannels;
      this.channelid = this.channels[0].channelid;
      this.gettingChannels = false;
      this.channelname = this.channels.find(
        (x: any) => x.channelid == this.channelid
      ).name;
    }
  }

  channelChange(event: any) {
    this.channelid = event;
    this.getTemplate();
  }

  getTemplate() {
    this.gettingTemplate = true;
    this.courseService.getTemplates(this.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.templateList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingTemplate = false;
      },
      (err) => {
        this.gettingTemplate = false;
      }
    );
  }

  refresh() {
    this.getTemplate();
  }

  updateHTML(id: number, value: any, index: any) {
    if (value == 2) {
      this.array[0]['value'][index]['ccontent'] = "2";

    }
    if (value == 3) {
      this.array[0]['value'][index]['ciscenter'] = "3";

    }
    if (value == 4) {
      this.array[0]['value'][index]['ctop'] = "4";

    }
    if (value == 5) {

      this.array[0]['value'][index]['cfontsize'] = "5";

    }

    if (value == 6) {

      this.array[0]['value'][index]['cfontweight'] = "6";

    }
    if (value == 7) {

      this.array[0]['value'][index]['cfontfamily'] = "7";

    }
    if (value == 8) {

      this.array[0]['value'][index]['ccolor'] = "8";

    }
    if (value == 9) {

      this.array[0]['value'][index]['clineheight'] = "9";

    }

    console.log(this.array);



    const mydiv = document.getElementById(this.elementList[id].id);
    mydiv!.textContent = this.elementList[id].content;
    mydiv!.style.fontSize = this.elementList[id].fontSize + 'em';
    mydiv!.style.top = this.elementList[id].top + '%';
    mydiv!.style.fontWeight = this.elementList[id].fontWeight;
    mydiv!.style.fontFamily = this.elementList[id].fontFamily;
    mydiv!.style.lineHeight = this.elementList[id].lineHeight;
    mydiv!.style.color = this.elementList[id].color;

    if (this.elementList[id].isCenter) {
      mydiv!.style.width = '100%';
      mydiv!.style.textAlign = 'center';
      mydiv!.style.removeProperty('left');
    } else {
      mydiv!.style.removeProperty('width');
      mydiv!.style.left = this.elementList[id].left + '%';
    }
  }

  newTemplate() {
    this.elementList = this.defaultList;
    this.fullScreen = false;
    this.isNew = true;
    this.padding = 0;
    this.array[0]['paddingvalue'] = "0";
    setTimeout(() => {
      const app = document.getElementById('template-bg');
      app!.style.fontSize = (app!.clientWidth / 50).toString() + 'px';
      this.elementList.map((item: any, index: number) => {
        const div = document.createElement('div');
        div.id = item.id;
        div.textContent = item.content;
        div.style.position = 'absolute';
        div.style.fontSize = item.fontSize + 'em';
        div.style.top = item.top + '%';
        div.style.fontWeight = item.fontWeight;
        div.style.fontFamily = item.fontFamily;
        div.style.paddingLeft = this.padding + 'em';
        div.style.paddingRight = this.padding + 'em';
        div.style.color = item.color;
        div.style.lineHeight = item.lineHeight;
        if (item.isCenter) {
          div.style.width = '100%';
          div.style.textAlign = 'center';
        } else {
          div.style.left = item.left + '%';
        }

        app?.appendChild(div);
      });
    }, 10);
  }

  editTemplate(template: any) {
    this.templateForEdit = template;
    this.elementList = template.rawjson;
    this.fullScreen = false;
    this.isNew = true;
    this.padding = template.padding;
    this.array[0]['paddingvalue'] = template.padding.toString();
    this.backgroundImg = template.backgroundimageurl;
    console.log(template);
    console.log(this.array);
    for (let i = 0; i < template.rawjson.length; i++) {
      for (let j = 0; j < this.array[0]['value'].length; j++) {
        if (template.rawjson[i]['id'] == this.array[0]['value'][j]['id']) {
          this.array[0]['value'][j]['content'] = template.rawjson[i]['content'];
          this.array[0]['value'][j]['color'] = template.rawjson[i]['color'];
          this.array[0]['value'][j]['isCenter'] = template.rawjson[i]['isCenter'];
          this.array[0]['value'][j]['fontSize'] = template.rawjson[i]['fontSize'];
          this.array[0]['value'][j]['fontFamily'] = template.rawjson[i]['fontFamily'];
          this.array[0]['value'][j]['fontWeight'] = template.rawjson[i]['fontWeight'];
          this.array[0]['value'][j]['lineHeight'] =  template.rawjson[i]['lineHeight'] ? template.rawjson[i]['lineHeight'] : '1';
          this.array[0]['value'][j]['top'] = template.rawjson[i]['top'];
        }
      }
    }
    this.elementList.map((item: any) => {
      item["lineHeight"] = item["lineHeight"] ? item["lineHeight"] : '1';
    })
    setTimeout(() => {
      const app = document.getElementById('template-bg');
      const imgtoremove = document.getElementById('bg-img');
      imgtoremove?.remove();
      const img = document.createElement('img');
      img.id = "bg-img";
      img.crossOrigin = '*';
      img.src = this.backgroundImg;
      img.style.width = '100%';
      img.style.height = 'auto';
      img.style.objectFit = 'cover';
      app?.appendChild(img);
      app!.style.fontSize = (app!.clientWidth / 50).toString() + 'px';
      // app!.style.backgroundImage = 'url(' + this.backgroundImg + ')';
      // app!.style.backgroundSize = 'cover';
      this.elementList.map((item: any, index: number) => {
        const div = document.createElement('div');
        div.id = item.id;
        div.textContent = item.content;
        div.style.position = 'absolute';
        div.style.fontSize = item.fontSize + 'em';
        div.style.top = item.top + '%';
        div.style.fontWeight = item.fontWeight;
        div.style.fontFamily = item.fontFamily;
        div.style.paddingLeft = this.padding + '%';
        div.style.paddingRight = this.padding + '%';
        div.style.color = item.color;
        div.style.lineHeight = item.lineHeight ? item.lineHeight : "1";
        if (item.isCenter) {
          div.style.width = '100%';
          div.style.textAlign = 'center';
        } else {
          div.style.left = item.left + '%';
        }

        app?.appendChild(div);
      });
    }, 10);
    console.log(this.array)

  }

  cancel() {
    var check = this.checkChangesFields();
    if (check == false) {
      if (confirm('Changes you made may not be saved.')) {
        this.elementList = [];
        this.fileSelected = '';
        this.backgroundImg = '';
        this.padding = 0;
        this.selectedIndex = -1;
        this.templateForEdit = '';
        this.isNew = false;
      } 
    } else {
      this.elementList = [];
      this.fileSelected = '';
      this.backgroundImg = '';
      this.padding = 0;
      this.selectedIndex = -1;
      this.templateForEdit = '';
      this.isNew = false;
    }

  }

  centerChange(id: number) {
    this.elementList[id].isCenter = !this.elementList[id].isCenter;
    this.updateHTML(id, "", "");
  }

  onFileSelected(event: any, drag: boolean) {
    this.fileSelected = drag ? event[0] : event.target.files[0];

    if (this.allowedImageType.includes(this.fileSelected.type)) {
      var reader = new FileReader();
      reader.readAsDataURL(this.fileSelected);
      reader.onload = (e: any) => {
        console.log(e);
        console.log(reader);
        var image = new Image();
        image.src = e.target.result;
        var width = 0, height = 0;
        image.onload = (rs: any) => {
          height = rs.currentTarget['height'];
          width = rs.currentTarget['width'];

          if (width > 1200 && height > 900) {
            this.messageService.openSnackBar(
              'Maximum dimentions allowed ' +
              1200 +
              ' x ' +
              900 +
              ' pixels',
              'warn'
            );
            return;
          }
          this.backgroundImg = reader.result;
          const mydiv = document.getElementById('template-bg');
          const imgtoremove = document.getElementById('bg-img');
          imgtoremove?.remove();
          const img = document.createElement('img');
          img.id = 'bg-img';
          img.crossOrigin = '*';
          img.src = this.backgroundImg;
          img.style.width = '100%';
          img.style.height = 'auto';
          img.style.objectFit = 'cover';
          mydiv?.appendChild(img);
        }

      };
    } else {
      this.fileSelected = '';
      this.messageService.openSnackBar(
        'Only image file types are allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement = '';
  }


  paddingChange(value: any) {
    if (value == 1) {
      this.array[0]['padding'] = "1"
    }
    console.log("jaejae");
    console.log(this.array)
    this.elementList.map((item: any, index: number) => {
      const div = document.getElementById(item.id);
      div!.style.paddingLeft = this.padding.toString() + 'em';
      div!.style.paddingRight = this.padding.toString() + 'em';
    });
  }

  fullScreenView() {
    const dialog = this.dialog.open(TemplatePreviewComponent, {
      maxWidth: '95vw',
      width: '900px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        elementList: this.elementList,
        backgroundImg: this.backgroundImg,
        padding: this.padding,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      this.loadingService.hide();
      if (dialogResult) {
      }
    });
  }

  generateImg() {
    return new Promise((resolve) => {
      var node: any = document.getElementById('template-bg');
      html2canvas(node, { allowTaint: true, useCORS: true }).then(function (
        canvas: any
      ) {
        const b64 = canvas.toDataURL();
        const blob = base64ToFile(b64);
        const imageFile: File = new File([blob], 'preview.png', {
          type: blob.type,
        });
        resolve(imageFile);
      });
    });
  }
  setStep(index: any) {
    this.selectedIndex = index;
  }
  removeElement(index: any, id: any) {
    if (this.elementList.length == 1) {
      return;
    }
    const div = document.getElementById(id);
    this.elementList.splice(index, 1);
    div?.remove();
  }
  addnewElement() {
    if (this.elementList.length == 10) {
      return;
    }
    var tempObj = {
      id: Date.now().toString(),
      content: 'New Content',
      left: '10',
      top: '10',
      fontSize: '1',
      fontWeight: '300',
      isCenter: true,
      color: '#000000',
      fontFamily: 'unset',
      lineHeight:'1'
    };
    console.log(tempObj);
    this.elementList.push(tempObj);
    const app = document.getElementById('template-bg');
    const div = document.createElement('div');
    div.id = tempObj.id;
    div.textContent = tempObj.content;
    div.style.position = 'absolute';
    div.style.fontSize = tempObj.fontSize + 'em';
    div.style.top = tempObj.top + '%';
    div.style.fontWeight = tempObj.fontWeight;
    div.style.paddingLeft = this.padding + 'em';
    div.style.paddingRight = this.padding + 'em';
    div.style.lineHeight =tempObj.lineHeight;
    if (tempObj.isCenter) {
      div.style.width = '100%';
      div.style.textAlign = 'center';
    } else {
      div.style.left = tempObj.left + '%';
    }
    app?.appendChild(div);
  }

  submitTemplate() {

    if (!this.backgroundImg) {
      this.messageService.openSnackBar(
        'Please upload a background image for the template.',
        'warn'
      );
      return;
    }
    var nameInclude: boolean = false;
    var courseInclude: boolean = false;
    this.elementList.map((item: any) => {
      if (nameInclude == false) {
        nameInclude = item.content.toString().includes('[[name]]');
      }
      if (courseInclude == false) {
        courseInclude = item.content.toString().includes('[[course]]');
      }
    });
    if (!nameInclude || !courseInclude) {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '500px',
        minWidth: '450px',
        closeOnNavigation: true,
        scrollStrategy: new NoopScrollStrategy(),
        data: {
          message:
            "Your template doesn't include " +
            (!nameInclude
              ? !courseInclude
                ? 'student name and course name'
                : 'student name'
              : 'course name') +
            '. Are you sure to continue?',
        },
      });

      dialog.afterClosed().subscribe((dialogResult) => {
        this.loadingService.hide();
        if (dialogResult) {
          this.saveTemplate();
        }
      });
    } else {
      this.saveTemplate();
    }
  }

  async saveTemplate() {
    this.isLoading = true;
    var filename = '';
    var previewfilename = '';
    this.previewImg = await this.generateImg();
    if (this.fileSelected) {
      filename = this.generateFileName(this.fileSelected);

      const fileupuload = await this.allinoneService.fileUploadToS3WithPath(
        this.fileSelected,
        filename,
        'templates'
      );
      if (!fileupuload) {
        this.messageService.openSnackBar(
          'Error while uploading image.',
          'warn'
        );
        return;
      }
    } else {
      filename = this.templateForEdit.backgroundimage;
    }

    previewfilename = this.generateFileName(this.previewImg);
    const previewfileupuload =
      await this.allinoneService.fileUploadToS3WithPath(
        this.previewImg,
        previewfilename,
        'templates'
      );
    if (!previewfileupuload) {
      this.messageService.openSnackBar('Error while uploading image.', 'warn');
      return;
    }
    // const data = {
    //   rawjson: this.elementList,
    //   backgroundimage: filename,
    //   padding: this.padding,
    //   previewimage: '',
    // };

    this.elementList.map((item: any) => {
      item.lineHeight = item.lineHeight.toString();
      item.left = item.left.toString();
      item.top = item.top.toString();
      item.fontSize = item.fontSize.toString();
    })
    var fun = this.templateForEdit
      ? this.courseService.editTemplate(
        this.templateForEdit.templateid,
        this.elementList,
        filename,
        this.padding.toString(),
        previewfilename,
        this.channelid
      )
      : this.courseService.addTemplate(
        this.elementList,
        filename,
        this.padding.toString(),
        previewfilename,
        this.channelid
      );
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.templateForEdit) {
            this.templateList.map((x: any) => {
              if (x.templateid == this.templateForEdit.templateid) {
                x.rawjson = this.elementList;
                x.backgroundimage = filename;
                x.backgroundimageurl = res.backgroundimageurl;
                x.padding = this.padding;
                x.previewimage = previewfilename;
                x.previewimageurl = res.previewimageurl;
              }
            });
          } else {
            this.templateList.push({
              templateid: res.templateid,
              rawjson: this.elementList,
              backgroundimage: filename,
              backgroundimageurl: res.backgroundimageurl,
              padding: this.padding.toString(),
              previewimage: previewfilename,
              previewimageurl: res.previewimageurl,
            });
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
   
  }

  generateFileName(file: File) {
    var filetype = file.name.split('.').pop();
    var filename = file.name
      .substring(0, file.name.lastIndexOf('.'))
      .replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    filename = filename + '_' + time + '.' + filetype;
    return filename;
  }


  checkChangesFields() {
    var checvalue = "0";
    var check = false;
    for (let i = 0; i < this.array.length; i++) {
      if (this.array[i]['padding'] == "1" && parseInt(this.array[i]['paddingvalue']) != this.padding) {
        checvalue = "1";
      }
    }
    for (let i = 0; i < this.array[0]['value'].length; i++) {
      for (let j = 0; j < this.elementList.length; j++) {
        if (this.array[0]['value'][i]['id'] == this.elementList[j]['id']) {

          if (this.array[0]['value'][i]['ccontent'] == "2" && this.array[0]['value'][i]['content'] != this.elementList[j]['content']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['ciscenter'] == "3" && this.array[0]['value'][i]['isCenter'] != this.elementList[j]['isCenter']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['ctop'] == "4" && this.array[0]['value'][i]['top'] != this.elementList[j]['top']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['cfontsize'] == "5" && this.array[0]['value'][i]['fontSize'] != this.elementList[j]['fontSize']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['cfontweight'] == "6" && this.array[0]['value'][i]['fontWeight'] != this.elementList[j]['fontWeight']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['cfontfamily'] == "7" && this.array[0]['value'][i]['fontFamily'] != this.elementList[j]['fontFamily']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['ccolor'] == "8" && this.array[0]['value'][i]['color'] != this.elementList[j]['color']) {
            checvalue = "1";
          } if (this.array[0]['value'][i]['clineheight'] == "9" && this.array[0]['value'][i]['lineHeight'] != this.elementList[j]['lineHeight']) {
            checvalue = "1";
          }
        }
      }
    }


    if (checvalue == "1") {
      check = false; //changes > found
    } else {
      check = true;
    }

    return check;
  }
}
