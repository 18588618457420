import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Profile } from '../../shared/models/Profile';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
})
export class ProfileViewComponent implements OnInit {
  gettingProfiles: boolean = true;

  primaryInstructor! : Profile;

  profiles: Profile[] = [];

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private _seoService: SEOService,
  ) {
    this._seoService.updateTitle('- Profiles');
  }

  ngOnInit(): void {
    this.getProfiles();
  }

  getProfiles(){
    this.gettingProfiles = true;
    this.courseService.getInstructorProfiles().subscribe((res: any) => {
      if(res.returncode == '300'){
        this.primaryInstructor = res.maininstructor;
        this.primaryInstructor.profileid = '';
        this.profiles = res.datalist;
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn')
      }
      this.gettingProfiles = false;
    }, err => {
      this.gettingProfiles = false;
    })
  }

  openProfileDialog(profile?: Profile){
    const dialog = this.dialog.open(ProfileComponent, {
      width: '600px',
      maxHeight: '90vh',
      closeOnNavigation: true,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        profile: profile
      }
    });

    dialog.afterClosed().subscribe((result: {status: boolean, profile: Profile}) => {
      if (result && result.status) {
        if(profile){
          console.log(result.profile);
          profile.name = result.profile.name;
          profile.institute = result.profile.institute;
          profile.description = result.profile.description;
          profile.profileimage = result.profile.profileimage;
          profile.profileimageurl = result.profile.profileimageurl;
        }
        else{
          this.profiles.push(result.profile);
        }
      }
    });
  }
}
