import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export interface Courses {
  channelid: string;
  channelname: string;
  filename: string;
  totalsecond: string;
  totaltime: string;
  url: string;

}


@Component({
  selector: 'app-course-analytics',
  templateUrl: './course-analytics.component.html',
  styleUrls: ['./course-analytics.component.scss'],
})
export class CourseAnalyticsComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;

  @ViewChild('stutable') stutable: MatTable<any> | any;
  @ViewChild('stusort', { static: false }) stusort!: MatSort;
  @ViewChild('stupaginator') stupaginator!: MatPaginator;

  courses: any[] = [];
  students: any[] = [];
  channels: any[] = [];
  classcodelist: any[] = [];
  branchlist: any[] = [];
  divisionlist: any[] = [];
  departmentlist: any[] = [];
  publicstudents: any[] = [

  ];
  showcourses!: MatTableDataSource<any>;
  showstudents!: MatTableDataSource<any>;
  showpublicstudents!: MatTableDataSource<any>;
  displayedColumns: any;
  studentsDisplayedColumns: any;
  publicstudentsDisplayedColumns: any;
  deptid: string = '';
  postid: string = '';
  postname: string = '';

  isFocus: boolean = false;
  isStudentFocus: boolean = false;
  isLoading: boolean = false;
  isStudentLoading: boolean = false;
  publicisStudentLoading: boolean = false;
  paginate: boolean = false;
  studentPaginate: boolean = false;
  publicPaginate: boolean = false;
  gettingmyChannel: boolean = false;
  gettingCategoryAnalytic: boolean = false;
  gettingTypes: boolean = false;

  gettingCourseAnalytic: boolean = false;

  datacount: string = '0';
  searchText: string = '';
  filter: string = '';
  selectedChannel: any = 'all';
  channelid: any = '';
  channelname: any = '';
  selectedCategory: string = '';
  role: string = '';
  totaltime: any = '0 hr(s) 0 min(s)';

  viewby: string = 'dashboard';
  //dashboard - Chart View, courses - View By Course, students - View By Stdents

  public studentCountChartOptions: ChartOptions = {
    legend: {
      position: 'left',
      labels: {
        padding: 30,
        usePointStyle: true,
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: (tooltipItem: any, data: any) => {
          var tooltipLabel = data['labels'][tooltipItem[0]['index']];
          if (tooltipLabel !== null) {
            return tooltipLabel;
          } else {
            return '';
          }
        },
        label: (tooltipItem: any, data: any) => {
          var allData = data.datasets[tooltipItem.datasetIndex].data;
          var tooltipLabel = data.labels[tooltipItem.index];
          var tooltipData = allData[tooltipItem.index];
          var total_attritions: number = 0;
          for (let i in allData) {
            total_attritions = total_attritions + Number(allData[i]);
          }
          let tooltipPercentage = (
            (Number(tooltipData) / total_attritions) *
            100
          ).toFixed(2);
          let tooltipPercentageFormat = this.getAttritionPercentage(
            total_attritions,
            tooltipData
          ).toFixed(1);
          // let tooltipPercentageFormat = "10";
          return [
            ' Count : ' + tooltipData,
            ' Percentage : ' + tooltipPercentageFormat + '%',
            ' Total Counts : ' + total_attritions,
          ];
        },
        // }
      },
      titleFontSize: 15,
    },
    plugins: {
      datalabels: {
        // anchor: 'end',
        display: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data ? context.dataset.data[index] : 0;
          return value != 0; // display labels with an odd index
        },
        // backgroundColor: 'white',
        color: 'white',
        borderRadius: 5,
        font: {
          size: 14,
        },
      },
    },
  };
  public timeSpentChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Time Spent (hrs)',
          },
        },
      ],
    },
  };

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartPlugins = [ChartDataLabels];

  public barChartColors: Color[] = [
    {
      backgroundColor: [
        '#5588bb',
        '#66bbbb',
        '#aa6644',
        '#99bb55',
        '#ee9944',
        '#444466',
        '#bb5555',
      ],
    },
    { backgroundColor: '#66bbbb' },
    { backgroundColor: '#aa6644' },
    { backgroundColor: '#99bb55' },
    { backgroundColor: '#ee9944' },
    { backgroundColor: '#444466' },
    { backgroundColor: '#bb5555' },
  ];

  public newbarChartColors: Color[] = [
    { backgroundColor: '#66bbbb' },
  ];

  public newbarChartLabels: Label[] = [];
  public newbarChartType: ChartType = 'horizontalBar';
  public newbarChartLegend = true;
  public newbarChartPlugins = [ChartDataLabels];

  public deptChartLabels: Label[] = [];
  public deptChartType: ChartType = 'horizontalBar';
  public deptChartLegend = true;
  public deptChartPlugins = [ChartDataLabels];


  public newbarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Enrolled Students',
          },
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Courses',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 20) {
                return value.toString().substr(0, 20) + '...';
              }
              else {
                return value.toString();
              }
            },
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };
  public deptChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Students',
          },
          ticks: {
            beginAtZero: true,
          }
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Departments',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 20) {
                return value.toString().substr(0, 20) + '...';
              }
              else {
                return value.toString();
              }
            },
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };

  public courseCount: ChartDataSets[] = [{ data: [0] }];
  public studentCount: ChartDataSets[] = [{ data: [0] }];
  public timeSpent: ChartDataSets[] = [{ data: [0] }];
  public deptStuCount: ChartDataSets[] = [{ data: [0] }];

  gettingCategories: boolean = false;
  categories: any[] = [];

  publicValue: any = "";
  public: any = "";
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe((data) => {
      this.role = data.role;
      console.log(data);

    });
    this.public = this.route.snapshot.queryParamMap.get('type') || "";
    this.publicValue = this.allinoneService.decrypt(this.public);
    console.log(this.publicValue);

    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }





  }

  ngOnInit() {

    if (this.route.snapshot.params['channelid'] == 'public') {
      this.channelid = '';
    } else {
      this.channelid = this.route.snapshot.params['channelid'];
    }
    this.getTypes();
    this.getCategoryAnalytic();
    this.getCourseAnalytic();
    this.getCourse();
    this.getStudents();
    this.getCategories();
    // this.getPublicStudents();
    this.displayedColumns = [
      'image',
      'coursename',
      'studentcounts',
      'timespent',
      'nexticon',
    ];
    this.studentsDisplayedColumns = [
      'userid',
      'username',
      'post',
      'department',
      'nexticon',
    ];
    this.publicstudentsDisplayedColumns = [
      'profileimage',
      'userid',
      'username',
      'enrolledcourses',
    ]
  }

  getTypes() {
    this.gettingTypes = true;
    this.courseService.getType(this.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.classcodelist = res.classcodelist;
          this.classcodelist.sort((a, b) => {
            return this.allinoneService.compare(a.name, b.name, true);
          });
          this.branchlist = res.branchlist;
          this.divisionlist = res.divisionlist;
          this.departmentlist = res.departmentlist;
          this.departmentlist.sort();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingTypes = false;
      },
      (err) => {
        this.gettingTypes = false;
      }
    );
  }

  changeClassCode(event: any) {
    this.postid = event;
    this.postname = this.classcodelist.find(
      (item: any) => item.id == this.postid
    )?.name;
    this.getCourseAnalytic();
  }

  changeDepartment(event: any) {
    this.deptid = event;
    this.getCourseAnalytic();
  }

  public getAttritionPercentage(total: any, count: any) {
    let calculated_perc = (count / total) * 100;
    return calculated_perc;
  }

  categoryChange() {
    console.log(this.selectedCategory);
    this.showcourses.filter = this.selectedCategory;
  }

  getCategories() {
    this.gettingCategories = true;
    this.courseService.getCategoryList().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categories = res.data;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
          }
        }
        this.gettingCategories = false;
      },
      (err) => {
        this.gettingCategories = false;
      }
    );
  }

  getCategoryAnalytic() {
    this.gettingCategoryAnalytic = true;
    // this.barChartLabels = [];
    // this.studentCount[0].data = [];
    this.timeSpent[0].data = [];
    this.courseService.getCategoryAnalytic(this.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            // this.barChartLabels.push(item.categoryname);
            // this.studentCount[0].data?.push(parseInt(item.categorystudent));
            this.timeSpent[0].data?.push(
              parseFloat((parseInt(item.totalsecond) / 360).toFixed(2))
            );
          });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCategoryAnalytic = false;
      },
      (err) => {
        this.gettingCategoryAnalytic = false;
      }
    );
  }

  getCourseAnalytic() {
    this.gettingCourseAnalytic = true;
    this.barChartLabels = [];
    this.newbarChartLabels = [];
    this.deptChartLabels = [];
    this.studentCount[0].data = [];
    this.courseCount[0].data = [];
    this.deptStuCount[0].data = [];
    this.courseService
      .getCourseAnalytic(this.channelid, this.deptid, this.postid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.categorydatalist.map((item: any) => {
              this.barChartLabels.push(item.categoryname);
              this.studentCount[0].data?.push(parseInt(item.enrolledstudent));
            });

            res.coursedatalist.map((item: any) => {
              this.newbarChartLabels.push(item.coursename);
              this.courseCount[0].data?.push(parseInt(item.enrolledstudent));
            });

            res.departmentdatalist.map((item: any) => {
              this.deptChartLabels.push(item.department);
              this.deptStuCount[0].data?.push(parseInt(item.enrolledstudent));
            });

          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingCourseAnalytic = false;
        },
        (err) => {
          this.gettingCourseAnalytic = false;
        }
      );
  }

  // Random_Color() {
  //   // setInterval(() => {
  //     return "rgb(" + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + ","
  //       +
  //       Math.floor(Math.random() * 255) + ")";
  //   // }, 1000);
  // }
  // Color() {

  //   //change the text color with the new random color
  //   // document.getElementById("content").style.color = color;
  // }

  goTo(route: string) {
    this.router.navigateByUrl(route);
  }

  viewbyChange() {
    if (this.viewby == 'courses') {
      this.showcourses = new MatTableDataSource(this.courses);
      setTimeout(() => {
        this.showcourses.sort = this.sort;
        this.showcourses.paginator = this.paginator;
      }, 100);
      this.clear();
    } else if (this.viewby == 'students') {
      this.showstudents = new MatTableDataSource(this.students);
      this.showstudents.sort = this.stusort;
      this.showpublicstudents = new MatTableDataSource(this.publicstudents);

      setTimeout(() => {
        this.showcourses.sort = this.sort;
        this.showstudents.paginator = this.stupaginator;
      }, 100);
      this.clear();
    }
  }

  announceSortChange(sort: Sort) {
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.compare(a.coursename, b.coursename, isAsc);
        case 'studentcounts':
          return this.compare(
            parseInt(a.studentcounts),
            parseInt(b.studentcounts),
            isAsc
          );
        case 'timespent':
          return this.compare(a.totalsecond, b.totalsecond, isAsc);
        default:
          return 0;
      }
    });
    this.showcourses = new MatTableDataSource(this.courses);
    this.showcourses.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // getStudents() {
  //   this.students = [];
  //   this.showstudents = new MatTableDataSource(this.students);
  //   this.isStudentLoading = true;
  //   this.courseService.getOrgMemberForAnalytic(this.channelid).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.isStudentLoading = false;
  //         this.students = res.datalist;
  //         this.showstudents = new MatTableDataSource(this.students);
  //         if (this.students == undefined || this.students.length == 0) {
  //           this.studentPaginate = false;
  //         } else {
  //           this.studentPaginate = true;
  //         }

  //         // this.showstudents.sort = this.stusort;
  //         this.showstudents.paginator = this.stupaginator;

  //         setTimeout(() => {
  //           this.isStudentLoading = false;
  //         }, 400);

  //         // this.table.renderRows();
  //       } else {
  //         if (res.returncode == '210') {
  //           setTimeout(() => {
  //             this.router.navigateByUrl('login');
  //             this.allinoneService.logout();
  //           }, 2000);
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.status || res.message || 'Server Error',
  //             'warn'
  //           );
  //         }
  //       }
  //     },
  //     (err) => {
  //       this.isStudentLoading = false;
  //     }
  //   );
  // }

  getStudents() {
    this.students = [];
    this.showstudents = new MatTableDataSource(this.students);
    this.isStudentLoading = true;

    this.publicstudents = [];
    this.showpublicstudents = new MatTableDataSource(this.publicstudents);
    this.publicisStudentLoading = true;

    this.courseService.getStudentForAnalytic(this.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          this.isStudentLoading = false;
          this.students = res.datalist;
          this.showstudents = new MatTableDataSource(this.students);
          if (this.students == undefined || this.students.length == 0) {
            this.studentPaginate = false;
          } else {
            this.studentPaginate = true;
          }

          // this.showstudents.sort = this.stusort;
          this.showstudents.paginator = this.stupaginator;

          setTimeout(() => {
            this.isStudentLoading = false;
          }, 400);


          // public student
          this.publicisStudentLoading = false;
          this.publicstudents = res.datalist;
          this.showpublicstudents = new MatTableDataSource(this.publicstudents);
          if (this.publicstudents == undefined || this.publicstudents.length == 0) {
            this.publicPaginate = false;
          } else {
            this.publicPaginate = true;
          }

          // this.showstudents.sort = this.stusort;
          this.showpublicstudents.paginator = this.stupaginator;

          setTimeout(() => {
            this.publicisStudentLoading = false;
          }, 400);

          // this.table.renderRows();
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
        }
      },
      (err) => {
        this.isStudentLoading = false;
        this.publicisStudentLoading = false;
      }
    );
  }

  // getPublicStudents() {
  //   this.publicstudents = [];
  //   this.showpublicstudents = new MatTableDataSource(this.publicstudents);
  //   this.publicisStudentLoading = true;
  //   this.courseService.getStudentForAnalytic(this.channelid).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.publicisStudentLoading = false;
  //         this.publicstudents = res.datalist;
  //         this.showpublicstudents = new MatTableDataSource(this.publicstudents);
  //         if (this.publicstudents == undefined || this.publicstudents.length == 0) {
  //           this.publicPaginate = false;
  //         } else {
  //           this.publicPaginate = true;
  //         }

  //         // this.showstudents.sort = this.stusort;
  //         this.showpublicstudents.paginator = this.stupaginator;

  //         setTimeout(() => {
  //           this.publicisStudentLoading = false;
  //         }, 400);

  //         // this.table.renderRows();
  //       } else {
  //         if (res.returncode == '210') {
  //           setTimeout(() => {
  //             this.router.navigateByUrl('login');
  //             this.allinoneService.logout();
  //           }, 2000);
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.status || res.message || 'Server Error',
  //             'warn'
  //           );
  //         }
  //       }
  //     },
  //     (err) => {
  //       this.publicisStudentLoading = false;
  //     }
  //   );
  // }


  getCourse() {
    this.courses = [];
    this.showcourses = new MatTableDataSource(this.courses);
    this.isLoading = true;
    this.courseService
      .getAnalyticsCourse(this.channelid, '', '', '', '')
      .subscribe(
        (courses: any) => {
          if (courses.returncode == '300') {
            this.isLoading = false;
            this.channelname = courses.channelname;
            courses.datalist.map(async (course: any) => {
              await this.courses.push({
                courseid: course.courseid,
                coursename: course.coursename,
                courseimage: course.courseimage,
                totalsecond: course.totalsecond,
                totaltime: course.totaltime,
                studentcounts: course.studentcounts,
                category: course.category,
              });
              this.totaltime = courses.totaltime;
              this.datacount = courses.datalist.length;
            });
            this.showcourses = new MatTableDataSource(this.courses);
            this.showcourses.filterPredicate = function (
              f,
              filter: string
            ): boolean {
              return (
                f.category.toLowerCase().includes(filter) ||
                f.coursename.toLowerCase().includes(filter) ||
                f.coursename.toLowerCase().includes(filter)
              );
            };
            if (this.courses == undefined || this.courses.length == 0) {
              this.paginate = false;
            } else {
              this.paginate = true;
            }

            this.showcourses.sort = this.sort;
            this.showcourses.paginator = this.paginator;

            setTimeout(() => {
              this.isLoading = false;
            }, 400);

            // this.table.renderRows();
          } else {
            if (courses.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                courses.status || courses.message || 'Server Error',
                'warn'
              );
            }
            // setTimeout(() => {
            // }, 400);
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  clear() {
    this.searchText = '';
    this.showcourses.filter = this.selectedCategory;
    this.showstudents.filter = '';
    this.showpublicstudents.filter = '';
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showcourses.filter = searchItem.trim().toLowerCase();
    this.showstudents.filter = searchItem.trim().toLowerCase();
    this.showpublicstudents.filter = searchItem.trim().toLowerCase();
  }

  refresh() {
    this.clear();
    console.log(this.publicValue)
    console.log(typeof (this.publicValue));
    if (this.viewby == 'courses') {
      this.totaltime = '0 hr(s) 0 min(s)';
      this.paginate = false;
      this.getCourse();
      this.getCategoryAnalytic();
    } else {
      this.isStudentLoading = true;
      this.studentPaginate = false;

      // if (this.publicValue == '001') {
      //   this.getPublicStudents();
      // } else {
      //   this.getStudents();
      // }
      this.getStudents();

    }
  }

  onChange(data: any) {
    const searchItem = data.target.value;
    if (data.target.value == 'all') {
      this.getCourse();
    } else {
      this.showcourses.filter = searchItem.trim().toLowerCase();
    }
  }

  goToUserAnalytic(course: any) {
    const channel = this.channelid ? this.channelid : 'public';
    this.router.navigateByUrl(
      `${this.role}/analytics/${channel}/course/${course.courseid}`
    );
  }

  goToCoursesByUserAnalytic(userid: any) {
    const channel = this.channelid ? this.channelid : 'public';
    this.router.navigateByUrl(
      `${this.role}/analytics/${channel}/user/${userid}?type=${this.allinoneService.encrypt(this.publicValue)}`
    );
  }

  sortData(sort: Sort) {
    const data = this.students;
    if (!sort.active || sort.direction === '') {
      this.students = data;
      return;
    }
    this.students = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        case 'post':
          return this.compare(a.typename, b.typename, isAsc);
        case 'department':
          return this.compare(a.department, b.department, isAsc);
        default:
          return 0;
      }
    });
    this.showstudents = new MatTableDataSource(this.students);
  }

  sortDataPublicStudent(sort: Sort) {
    const data = this.students;
    console.log(data);
    console.log(sort.active);
    
    if (!sort.active || sort.direction === '') {
      this.students = data;
      return;
    }
    this.students = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        case 'enrolledcourses':
          return this.compare(Number(a.count),Number(b.count), isAsc);
        default:
          return 0;
      }
    });
    this.showpublicstudents = new MatTableDataSource(this.students);
  }
}
