

<app-breadcrumb *ngIf="!getChannelName" [t_breadcrumb]="'Add Member'" [b_breadcrumb]="'Members'" [a_breadcrumb]="channelname" [s_breadcrumb]="chl_text" (emit2)="goToMembers()" (emit)="goToChannels()" (emit1)="gotoChannelName()">
</app-breadcrumb>
<div class="container-fluid mt-4">
    <div class="content">
        <div class="card card-body my-card">
            <form #f="ngForm" (ngSubmit)="f.form.valid && addMember()" name="form" novalidate>
                <div class="row p-3">
                    <div class="form-group col-md-6  mb-3">
                        <label for="chl_name" class="form-label">User ID</label>
                        <input type="text" [(ngModel)]="user_id" name="user_id"
                         class="form-control" id="user_id" required #userid="ngModel" 
                         autocomplete="off" 
                         [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }" 
                         placeholder="john@domain.com, +959*********">
                        <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>

                    <div class="form-group col-md-6 mb-3">
                        <label for="status" class="form-label">Status</label>
                        <select class="form-select" id="status" [(ngModel)]="status" (change)="statusChange($event)" [ngModelOptions]="{standalone: true}">
                            <option value = '001' selected >Active</option>
                            <option value = '002' >Inactive</option>
                        </select>
                    </div> 
        
                    <div class="form-group col-md-6 mb-3">
                        <!-- <input type="text" [(ngModel)]="user_id" name="user_id" class="form-control" id="user_id" required #userid="ngModel" autocomplete="off" [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }" placeholder="john@domain.com, +959*********">
                        <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
                            {{allinoneService.input_err_msg}}
                        </div> -->
                        <label for="membertype" class="form-label">Member Type</label>
                        <!-- <select class="form-select" [(ngModel)]="membertype" (change)="memberChange($event)" [ngModelOptions]="{standalone: true}" required #membertype="ngModel" [ngClass]="{ 'is-invalid': f.submitted && membertype.invalid }" > -->
                        <select class="form-select" [(ngModel)]="membertype" (change)="memberChange($event)" [ngModelOptions]="{standalone: true}" [ngClass]="{ 'is-invalid': f.submitted && (membertype == '' || membertype == null) }">
                            <option value = '' selected >-</option>
                            <option *ngFor="let item of memberlist" [value]="item.id">{{item.name}}</option>
                            <!-- <option *ngFor="let item of role_list" [value]="item.id" [selected]="item.id == role">{{item.name}}</option> -->
                        </select>
                        
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingmembertype"></mat-progress-bar>
                        <div role="alert" *ngIf="f.submitted && (membertype == '' || membertype == null)" style="color: red;padding: 6px;">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>

                    <div class="form-group col-md-6 mb-3">
                        <label for="role" class="form-label">Role</label>
                        <select class="form-select" [(ngModel)]="role" (change)="onChange($event)" [ngModelOptions]="{standalone: true}">
                            <option value = '' selected >-</option>
                            <option *ngFor="let item of role_list" [value]="item.id">{{item.name}}</option>
                            <!-- <option *ngFor="let item of role_list" [value]="item.id" [selected]="item.id == role">{{item.name}}</option> -->
                        </select>
                    </div> 
                      
                    <div class="form-group col-md-12  mb-3">
                        <label for="chl_name" class="form-label">Remark</label>
                        <textarea type="text" rows="3" [(ngModel)]="remark" name="remark" class="form-control" id="remark"  #remark_id="ngModel" autocomplete="off"></textarea>
                  
                    </div>
                    <div class="d-flex justify-content-end">
                        <button [disabled]="isadding" (click)="goToMembers()" mat-flat-button color="primary" class="mt-2">
                            Cancel
                        </button>
                        <app-button-loader [button_text]="'Add'" [isStillupload]="isadding">
                        </app-button-loader>
        
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</div>

<!-- <app-breadcrumb *ngIf="!getChannelName" [t_breadcrumb]="'Add Member'" [b_breadcrumb]="'Members'" [a_breadcrumb]="channelname" [s_breadcrumb]="chl_text" (emit2)=goToMembers() (emit)="goToChannels()" (emit1)="gotoChannelName()">
</app-breadcrumb>
<div class="p-2">

    <div class="container mt-3 mb-3">
        <mat-card>

            <form #f="ngForm" (ngSubmit)="f.form.valid && addMember()" name="form" novalidate>
                <div class="form-group  mb-3">
                    <label for="chl_name" class="mb-2">User ID</label>
                    <input type="text" [(ngModel)]="user_id" name="user_id" class="form-control" id="user_id" required #userid="ngModel" autocomplete="off" [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }" placeholder="john@domain.com, +959*********">
                    <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

                <div class="form-group mb-3">
                    <label for="role" class="mb-2">Role</label>
                    <select class="form-select" [(ngModel)]="role" (change)="onChange($event)" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let item of role_list" [value]="item.id" [selected]="item.id == role">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group  mb-3">
                    <label for="chl_name" class="mb-2">Remark</label>
                    <textarea type="text" [(ngModel)]="remark" name="remark" class="form-control" id="remark"  #remark_id="ngModel" autocomplete="off"></textarea>
              
                </div>
                <div class="d-flex justify-content-end">
                    <button [disabled]="isadding" (click)="goToMembers()" mat-flat-button color="primary" class="mt-2">
                        Cancel
                    </button>
                    <app-button-loader [button_text]="'Add'" [isStillupload]="isadding">
                    </app-button-loader>

                </div>
            </form>
        </mat-card>
    </div>
</div> -->