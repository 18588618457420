<div>

  <div>
    <h2><strong>Add To Class</strong></h2>
  </div>
  <form [formGroup]="studentForm" (ngSubmit)="sumbitForm()">
    <div class="form-group" class="mb-3">
      <label for="classcode" class="mb-2">
        User ID
      </label>
      <input readonly type="text" class="form-control form-control-sm"
        placeholder="john@domain.com, +959*********" formControlName="adduser" id="adduser" name="adduser"
        [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.adduser.errors }">

      <div *ngIf="classFormSubmitted && classFormControl.adduser.errors" class="invalid-feedback">
        <div *ngIf="classFormControl.adduser.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>

    <div class="form-group" class="mb-3">
      <label for="studentid" class="mb-2">
        Student ID
      </label>
      <input type="text" class="form-control form-control-sm" placeholder="SD 001" formControlName="studentid"
        id="studentid" name="studentid">
    </div>

    <div class="form-group" class="mb-3">
      <label for="studentname" class="mb-2">
        Student Name
      </label>
      <input type="text" class="form-control form-control-sm" placeholder="Enter Student Name" formControlName="studentname" id="studentname"
        name="studentname">
    </div>


    <div class = "form-group" class = "mb-3">
      <label for="class" class="mb-2">
        Class
      </label>
      <select class="form-select" id = "class" name = "class" formControlName = "classid" required
      [ngClass]="{ 'is-invalid': classFormSubmitted && classFormControl.classid.errors }">
        <option selected value="">-</option>
        <option *ngFor="let class of classlist" [value]="class.classid">
          {{class.classcode}}{{class.classname ? '- ' + class.classname : ''}}
        </option>
      </select>

      <div *ngIf="classFormSubmitted && classFormControl.classid.errors" class="invalid-feedback">
        <div *ngIf="classFormControl.classid.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf = "gettingClass"></mat-progress-bar>
    </div>


    <div class="form-group" class="mb-3">
      <label for="remark" class="mb-2">
        Remark
      </label>
      <textarea class="form-control" rows="3" placeholder="Enter Remark" formControlName="remark" id="remark"
        name="remark"></textarea>
    </div>

    <div class="actions-button">
      <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isLoading">
        Cancel
      </button>

      <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading">
      </app-button-loader>

    </div>

  </form>
</div>