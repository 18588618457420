<div class="mb-3 d-flex align-items-center justify-content-between">
  <div>
    <h2 class="mb-0">Filter By:</h2>
  </div>
  <div class="d-flex align-items-center">
    <mat-icon (click)="close()" class="cus-point">close</mat-icon>
  </div>
</div>
<div class="row">
  <div class="col-md-4 mb-3" *ngIf="reportForm.searchtype!='class'">
    <label for="startdate" class="form-label">Start Date</label>
    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="reportForm.startdate" required
      #startdate="ngModel" step="1">
  </div>
  <div class="col-md-4 mb-3" *ngIf="reportForm.searchtype!='class'">
    <label for="enddate" class="form-label">End Date</label>
    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="reportForm.enddate" required
      #enddate="ngModel" step="1">

  </div>
  <div class="mb-3"
    [ngClass]="{'col-md-4': reportForm.searchtype != 'class', 'col-md-12': reportForm.searchType == 'class'}">
    <label for="channelid" class="form-label">Channel</label>

    <select class="form-select" [(ngModel)]="reportForm.channelid" required #channelid="ngModel"
      (ngModelChange)="channelChange($event)">
      <option value="" *ngIf="role == 'admin'">KHub</option>
      <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.name}}</option>
    </select>
  </div>
  <ng-container *ngIf="reportForm.searchtype =='class'">
    <div class="d-flex align-items-center">
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="classserachtype" id="classcategory" value="category"
          [(ngModel)]="reportForm.classsearchtype" [checked]="reportForm.classsearchtype == 'category'">
        <label class="form-check-label" for="classcategory">
          Category
        </label>
      </div>
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="classserachtype" id="classcourse" value="course"
          [(ngModel)]="reportForm.classsearchtype" [checked]="reportForm.classsearchtype == 'course'">
        <label class="form-check-label" for="classcourse">
          Course
        </label>
      </div>
    </div>
    <div class="col-md-12 mb-3" *ngIf="reportForm.classsearchtype == 'course'">
      <label for="channelid" class="form-label">Course</label>

      <ng-select placeholder="All Courses" [multiple]="true" [(ngModel)]="reportForm.classcourses" name="channelid"
        id="channelid" appearance="outline" [searchable]="true" [clearable]="true" [loading]="gettingCourses">
        <ng-option *ngFor="let course of courses" [value]="course.courseid">{{course.coursename}}</ng-option>
      </ng-select>
    </div>
  </ng-container>
  <div class="col-md-12 mb-3"
    *ngIf="reportForm.searchtype == 'category' || (reportForm.searchtype == 'class' && reportForm.classsearchtype == 'category')">
    <label for="channelid" class="form-label">Category</label>

    <!-- (ngModelChange)="categoryChange()" -->
    <select class="form-select" [(ngModel)]="reportForm.categoryid" required #categoryid="ngModel"
       [disabled]="gettingCategory" >
      <option value="">All</option>
      <!-- <option *ngFor="let category of categories" [value]="category.categoryid">{{category.categoryname}}</option> -->
      <option *ngFor="let category of categroyList" [value]="category.categoryid">{{category.categoryname}}</option>
    </select>
    <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>
  </div>
  <!-- <div class="col-md-12 mb-3" *ngIf="reportForm.searchtype == 'course'">
    <label for="courseid" class="form-label">Course</label>

    <select class="form-select" [(ngModel)]="reportForm.courseid" #courseid="ngModel" id="courseid" name="courseid"
      (ngModelChange)="courseChange()" required [disabled]="gettingCourses">
      <option value="" *ngIf="courses.length == 0">-</option>
      <option *ngFor="let course of courses" [value]="course.courseid">{{course.coursename}}</option>
    </select>
    <mat-progress-bar mode="indeterminate" *ngIf="gettingCourses"></mat-progress-bar>
  </div> -->
  <ng-container *ngIf="reportForm.searchtype!='class'">
    <div class="col-md-12 mb-3">
      <label for="channelid" class="form-label">Post</label>

      <ng-select placeholder="All Posts" [multiple]="true" [(ngModel)]="reportForm.posts" name="channelid"
        id="channelid" appearance="outline" [searchable]="true" [clearable]="true" [loading]="gettingTypes">
        <ng-option *ngFor="let classcode of classcodelist" [value]="classcode.id">{{classcode.name}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-12 mb-3">
      <label for="channelid" class="form-label">Branch</label>

      <ng-select placeholder="All Branches" [multiple]="true" [(ngModel)]="reportForm.branches" name="channelid"
        id="channelid" appearance="outline" [searchable]="true" [clearable]="true" [loading]="gettingTypes">
        <ng-option *ngFor="let branch of branchlist" [value]="branch">{{branch}}</ng-option>
      </ng-select>

    </div>
    <div class="col-md-12 mb-3">
      <label for="channelid" class="form-label">Department</label>

      <ng-select placeholder="All Departments" [multiple]="true" [(ngModel)]="reportForm.departments" name="channelid"
        id="channelid" appearance="outline" [searchable]="true" [clearable]="true" [loading]="gettingTypes">
        <ng-option *ngFor="let department of departmentlist" [value]="department">{{department}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-12 mb-3">
      <label for="channelid" class="form-label">Division</label>

      <ng-select placeholder="All Divisions" [multiple]="true" [(ngModel)]="reportForm.divisions" name="channelid"
        id="channelid" appearance="outline" [searchable]="true" [clearable]="true" [loading]="gettingTypes">
        <ng-option *ngFor="let division of divisionlist" [value]="division">{{division}}</ng-option>
      </ng-select>
    </div>
  </ng-container>
  <div class="col-md-12 mb-3"
    *ngIf="reportForm.searchtype == 'category' || (reportForm.searchtype == 'class' && reportForm.classsearchtype == 'category')">
    <label for="channelid" class="form-label">Course Type</label>

    <ng-select placeholder="All Course Types" [multiple]="true" [(ngModel)]="reportForm.coursetypes" name="channelid"
      id="channelid" appearance="outline" [searchable]="true" [clearable]="true" dropdownPosition="top">
      <ng-option *ngFor="let coursetype of coursetypes" [value]="coursetype.id">{{coursetype.name}}</ng-option>
    </ng-select>
  </div>
  <ng-container *ngIf="reportForm.searchtype=='class'">
    
    <div class="col-md-12 mb-3">
      <div class = "form-check ">
        <input class="form-check-input" type="checkbox" id="daterange" [(ngModel)]="reportForm.classdaterange">
      <label class="form-check-label" for="daterange">
        Date Range
      </label>
      </div>
    </div>
    <div class="col-md-6 mb-3" *ngIf="reportForm.classdaterange">
      <label for="startdate" class="form-label">Start Date</label>
      <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="reportForm.startdate"
        required #startdate="ngModel" step="1">
    </div>
    <div class="col-md-6 mb-3" *ngIf="reportForm.classdaterange">
      <label for="enddate" class="form-label">End Date</label>
      <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="reportForm.enddate" required
        #enddate="ngModel" step="1">
    </div>
  </ng-container>

  <div class="col-12 d-flex justify-content-end">
    <div>
      <button mat-button (click)="clearall()">Clear</button>
    </div>
    <div>
      <button mat-flat-button color="primary" (click)="save()">Apply</button>
    </div>
  </div>
</div>