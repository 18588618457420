<app-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'Learning Track'"></app-breadcrumb>
<app-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'Learning Track'" [t_breadcrumb]="isEditing ? 'Edit' : 'New' "
    (emit)="cancel()"></app-breadcrumb>

<!-- <button (click)="test()">Test</button> -->

<div class="container-fluid mt-2 mb-2 student-container">
    <div *ngIf="!isAdding">
        <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
            <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-2">
                <div>
                    <button color="primary" class="me-1 ms-0" (click)="newLT()" mat-flat-button>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        New Learning Track
                    </button>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                    <div class="me-2">
                        Total :<b> {{LTList.length}}</b>
                    </div>
                    <div class="input-group me-2" style="width: 260px !important;">
                        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                            (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search"
                            [(ngModel)]="searchText">
                        <button class="btn" type="button"
                            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}" *ngIf="searchText"
                            (click)="clear()">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="form-group me-2" style="width: 260px !important">
                        <select class="form-select" [(ngModel)]="selectedChannel" (ngModelChange)="channelChange()"
                            matTooltip="Filter By Channel" [disabled]="gettingChannels">
                            <option [value]="chl.channelid" *ngFor="let chl of channels">
                                {{ chl.name }}
                            </option>
                        </select>
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingChannels"></mat-progress-bar>
                    </div>
                    <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button"
                        style="margin-left: 10px;">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
        <div style="overflow: auto;">
            <table mat-table #table [dataSource]="showLT" multiTemplateDataRows matSort
                (matSortChange)="sortData($event)" class="mat-elevation-z1" style="width: 100%;">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;">
                        {{element.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">
                        Description
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;">
                        {{element.description}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="courses">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by courses">
                        Courses
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;">
                        {{element.courseidlist.length}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by duration">
                        Duration
                    </th>
                    <td mat-cell *matCellDef="let element" style="border: none;">
                        {{element.duration}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action" >
                    <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 155px;padding: 0;"> Action </th>
                    <td mat-cell *matCellDef="let element;let i = index;" style="text-align: center; border: none;padding: 0;">
                        <button class="btn btn-outline-primary btn-custom-rounded me-2 align-item-center" matTooltip="Members" (click)="gotoMember(element)">
                            <i class="fa fa-users mem-icon" aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-outline-primary btn-custom-rounded" matTooltip="Edit" (click)="editLT(element)">
                            <i class="fa fa-edit"></i>
                        </button>
                        <button class="btn btn-outline-danger rounded-circle" matTooltip="Delete"
                            (click)="deleteLT(element)"
                            style="font-size: 14px;width:37px;height: 35px;margin-left: 5px;">
                            <i class="fa fa-trash"></i>
                        </button>
                        <!-- <button class="btn btn-outline-primary rounded-circle" matTooltip="Edit" >
                                    <i class="fa fa-edit"></i>
                                </button> -->
                        <!-- <button class="btn btn-outline-primary rounded-circle" matTooltip="Edit"
                            (click)="editLT(element)" style="font-size: 14px;width:37px;height: 35px;">
                            <i class="fa fa-edit"></i>
                        </button> -->
                        <!-- <button class="btn btn-outline-danger rounded-circle" matTooltip="Delete"
                            (click)="deleteLT(element,i)"
                            style="font-size: 14px;width:37px;height: 35px;margin-left: 5px;">
                            <i class="fa fa-trash"></i>
                        </button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                </tr>
                <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
                <tr class="mat-row" *matNoDataRow>
                    <td colspan="6">
                        <span class="nodata" *ngIf="!isLoading">Empty</span>
                        <span class="nodata" *ngIf="isLoading">
                            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                        </span>
                    </td>
                </tr>
            </table>
            <!-- <div  *ngIf="paginate == true"> -->
            <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
                [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <!-- </div> -->
        </div>
    </div>
    <div *ngIf="isAdding">

        <div class="card card-body my-card mb-3 p-3">
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="name" class="form-label">Name</label>
                    <input class="form-control" id="name" [(ngModel)]="submitForm.name" name="name" #name="ngModel"
                        [ngClass]="{'is-invalid' : formSubmitted && name.invalid}" required />
                    <div class="invalid-feedback"
                        *ngIf="name.invalid || ((name.dirty || name.touched) && formSubmitted)">
                        <div *ngIf="name.errors?.required">
                            {{ allinoneService.input_err_msg }}
                        </div>
                    </div>
                </div>

                <div class="mb-3 col-md-6">
                    <label for="duration" class="form-label">Duration (Days)</label>
                    <input type="number" class="form-control" id="duration" [(ngModel)]="submitForm.duration"
                        name="duration" #duration="ngModel" [min]="1"
                        [ngClass]="{'is-invalid' : formSubmitted && duration.invalid}" required />
                    <div class="invalid-feedback"
                        *ngIf="duration.invalid || ((duration.dirty || duration.touched) && formSubmitted)">
                        <div *ngIf="duration.errors?.required">
                            {{ allinoneService.input_err_msg }}
                        </div>
                    </div>
                </div>

                <div class="mb-3 col-md-12">
                    <label for="description" class="form-label">Description</label>
                    <!-- <input class="form-control" id="description" [(ngModel)]="submitForm.description"
                        name="description" /> -->
                    <textarea type="text" rows="3" [(ngModel)]="submitForm.description" name="description"
                        class="form-control" id="description" autocomplete="off"></textarea>
                </div>

                <!-- <div class="col-md-12 mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="order"
                        name="order"
                        [(ngModel)]="submitForm.order"
                      />
                      <label class="form-check-label" for="order"> Order Course </label>
                    </div>
                  </div> -->

                <!-- <div class="col-md-6 mb-3">
                    <label for="startdate" class="form-label">Start Date</label>
                    <input class="form-control" type="date" id="startdate" [(ngModel)]="submitForm.startdate"
                        name="startdate" max="9999-12-31" />

                </div>
                <div class="col-md-6 mb-3">
                    <label for="enddate" class="form-label">End Date</label>
                    <input class="form-control" type="date" id="enddate" [(ngModel)]="submitForm.enddate" name="enddate"
                        max="9999-12-31" [min]="submitForm.startdate" />

                </div> -->

            </div>

        </div>

        <div class="card mb-3 card-body my-card p-3">
            <div class="mb-2 d-flex align-items-center justify-content-between">
                <div>
                    <h2 class="info mb-0">Course</h2>
                </div>
                <button color="primary" class="me-1 ms-0 d-flex justify-content-center align-items-center "
                    (click)="plusCourse()" [disabled]="gettingCourse" mat-flat-button>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Add
                </button>
            </div>
            <div class="row" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="selectedCourseList.length > 0">
                <div class="col-md-6 mb-3" *ngFor="let course of selectedCourseList;let i = index">

                    <mat-card class="instructor-card" cdkDrag>
                        <div class="d-flex align-items-center justify-content-between ">
                            <div class="d-flex align-items-center">
                                <svg cdkDragHandle class="drag me-3" width="16" height="16" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z"
                                        fill="CurrentColor"></path>
                                </svg>
                                <!-- *ngIf="course.courseimageurl != ''" -->
                                <!-- [src]="course.courseimageurl" -->
                                <div class="img-wrapper">
                                    <div class="courseimage">
                                        <img class="courseimage" 
                                            [src]="course.courseimage== '' ? allinoneService.defaultImg : course.courseimageurl"
                                             alt="">

                                    </div>
                                </div>
                                <div class="details d-flex flex-column ps-3">
                                    <div class="name">{{course.coursename}}</div>
                                    <!-- <div class="userid">{{course.instructorname}}</div>
                                    <div class="institute">{{course.institute}}</div> -->
                                </div>
                            </div>
                            <!-- <mat-icon>circle-minus</mat-icon> -->
                            <div>
                                <button class="btn btn-outline-danger rounded-circle" matTooltip="Remove"
                                    (click)="deleteSelectedCourse(course, i)"
                                    style="font-size: 14px;width:32px;height: 30px;margin-left: 5px;">
                                    <i class="fa fa-trash"></i>
                                    <!-- <i class="fa fa-minus"></i> -->
                                </button>
                            </div>
                        </div>


                    </mat-card>
                </div>
            </div>
            <div class="nodata" *ngIf="selectedCourseList.length == 0">
                Empty
            </div>
        </div>

        <div class="actions-button">

            <button style="width: 80px !important;" mat-stroked-button class="mt-2 cancel-button" type="button"
                (click)="cancel()" [disabled]="saveLoading">
                Cancel
            </button>

            <app-button-loader [button_text]="'Save'" (click)="saveData()" [isStillupload]="saveLoading">
            </app-button-loader>

        </div>
    </div>
</div>