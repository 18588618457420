<div class ="d-flex justify-content-center">
  <h2><strong>Profile</strong></h2>
</div>
<div>
  <div class="profile-img-container mt-3">
    <div style="position: relative;">
        <div class="uploadfilecontainer" (click)="hiddenfileinput.click()" appDragDrop
            (onFileDropped)="fileChangeEvent($event, true)">
            <img class="drag-image me-3" [defaultImage]="'../../../assets/images/uploadphoto.png'"
            [lazyLoad]="userform.profileimageurl? userform.profileimageurl : '../../../assets/images/uploadphoto.png'">
        </div>
        <input hidden class="form-control" type="file" accept="image/png, image/jpeg" id="formFile"
      (change)="fileChangeEvent($event)" #hiddenfileinput>
        <button mat-icon-button class="remove-drag-image" *ngIf="userform.profileimageurl" (click)="removeImage()">
            <mat-icon>remove_circle</mat-icon>
        </button>
    </div>
</div>
  <!-- <div class="mb-3">
    image-crop
    <div *ngIf="imageChangedEvent != '' && !coppedImage" class="img-cropper">
      <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [roundCropper]="true" [aspectRatio]="4 / 4" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
      <button mat-flat-button color="primary" class="text-end" (click)="copyImage()">Crop
        Image</button>
    </div>


    <ng-container *ngIf="coppedImage">
      <img [src]="croppedImage" class="banner-img" />
    </ng-container>

    <ng-container *ngIf="imageChangedEvent == '' && !coppedImage && userform.profileimageurl != '' ">
      <img [src]="userform.profileimageurl" class="banner-img" />
    </ng-container>
  </div> -->

  <!-- <ng-container *ngIf="imageChangedEvent == '' && !coppedImage && userform.profileimageurl == '' ">
    <img src="../../../assets/images/uploadphoto.png" class="banner-img" />
  </ng-container> -->

  <div class="form-group">
    <label for="username" class="mb-2">Name</label>
    <input type="text" [(ngModel)]="userform.username" class="form-control" id="username"
      [ngClass]="{ 'is-invalid': userFormSubmitted && username.invalid }" placeholder="John Doe" required
      #username="ngModel" autocomplete="off" (keyup.enter)="updateUser()">

    <div class="invalid-feedback" *ngIf="username.invalid || (username.dirty || username.touched) && userFormSubmitted">
      <div *ngIf="username.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="form-group my-3">
    <label for="institute" class="mb-2">Institute</label>
    <input type="text" [(ngModel)]="userform.institute" class="form-control" id="institute"
      placeholder="Harvard University" [ngClass]="{ 'is-invalid': userFormSubmitted && institute.invalid }" required
      #institute="ngModel" (keyup.enter)="updateUser()">

    <div class="invalid-feedback"
      *ngIf="institute.invalid || (institute.dirty || institute.touched) && userFormSubmitted">
      <div *ngIf="institute.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label for="username" class="mb-2">Description</label>
    <angular-editor [(ngModel)]="userform.biography" [config]="config"></angular-editor>
  </div>




  <div class="d-flex justify-content-end">
    <button class = "btn btn-outline-secondary mt-2" (click)="dismiss()" [disabled]="isStillUpdating">Cancel</button>
    <app-button-loader (click)="updateUser()" [button_text]="'Save'" [isStillupload]="isStillUpdating">
    </app-button-loader>
  </div>

</div>