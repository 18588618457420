import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.scss']
})
export class AddcategoryComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  tdyDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd')?.toString();
  allCourses: any[] = [];

  categoryList: any[] = [];
  category = {
    channelid: "",
    name: '',
    categoryid: '',
    order: 0
  };

  statusList = [
    {
      name: 'Ongoing',
      id: '002',
    },
    {
      name: 'Upcoming',
      id: '003',
    },
  ];


  imageChangedEvent: any = '';
  croppedImage: boolean = false;

  isEditing: boolean = false;
  isLoading: boolean = false;
  formSubmitted: boolean = false;
  gettingAllCourses: boolean = false;
  isCourseSelected: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddcategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.categoryList = data?.categoryList;
    if (data.edit == true) {

      this.isEditing = true;
      this.category = {
        channelid: data.channelid,
        name: data.name,
        categoryid: data.categoryid,
        order: data.order
      };
      console.log("1111111");

    } else {
      this.isEditing = false;
      this.category.channelid = data.channelid;
      this.category.order = data.order;
      console.log("22222222222");
    }

  }

  ngOnInit(): void {
    if (this.isEditing) {

    }
    console.log(this.category);
  }

  dismiss() {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }




  async submit() {
    this.formSubmitted = true;
    if (
      this.category.name.toString().trim() == ''
    ) {
      return;
    }

    if (!this.category.order ||this.category.order < 0) {
      return this.messageService.openSnackBar(
        'Order should be at least 0.',
        'warn'
      );
    }
    if (this.categoryList.some(cat => 
      this.isEditing ? (cat.sortkey == this.category.order && cat.categoryid != this.category.categoryid) : (cat.sortkey == this.category.order)
    )) {
      this.messageService.openSnackBar('Error: Duplicate sortkey exists. Please choose a unique sortkey.', 'warn');
      return;
    }

    this.isLoading = true;
    const data = {
      categoryid: this.category.categoryid,
      categoryname: this.category.name,
      channelid: this.category.channelid,
      sortkey: this.category.order.toString(),
      type: '1',
      subcategoryname: "",
    };

    var fun = this.isEditing
      ? this.courseService.editSubcategory(data)
      : this.courseService.addSubcategory(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isLoading = false;
          this.formSubmitted = false;
          this.dialogRef.disableClose = false;
          var schedule = {
            channelid: this.category.channelid,
            name: this.category.name,
          };
          this.dialogRef.close(schedule);
        } else {
          this.isLoading = false;
          this.formSubmitted = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
        this.formSubmitted = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  showList() {
    this.autocomplete.openPanel();
  }

}
