import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
// import * as jwt from 'jsonwebtoken';
// import * as moment from 'moment';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy{


  signupForm = this.formBuider.group({
    userid: ["", Validators.required,],
    password: ["", Validators.required],
    isrememberme: false
  });

  signupFormSubmitted: boolean = false;

  width: number = 1024;
  isLoading: boolean = true;
  retry: boolean = false;

  isStillSigningIn: boolean = false;
  isStillUserCheckingForForgot: boolean = false;

  fieldTextType: boolean = false;

  signinType: boolean =false; //true for email or phone | false for qr
  
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  qrLoading: boolean = false;
  showRefresh: boolean = false;

  createdDate = ""
  formatTime = ""
  value: any = "";

  countGetQr = 1;

  getQrInterval: any;
  checkQrInterval: any;

  dateFormat = 'yyyyMMddhhmmss';
  secretkey = '@s3cr#CTKeyF0rQRL)g1nT0ken';

  form = {
    "appid": "kunyek",
    "uniquekey": "",
  }

  constructor(private formBuider: FormBuilder,
    private router: Router,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private cookieService: CookieService) { 
      if (this.allinoneService.getAtoken()) {
        clearInterval(this.getQrInterval)
        clearInterval(this.checkQrInterval)
        this.router.navigateByUrl('/home');
      }
    }

  ngOnInit(): void {
   this.qrLogin();
  }

  ngOnDestroy(): void {
    clearInterval(this.getQrInterval)
    clearInterval(this.checkQrInterval)
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  onRememberMeChange(e: any) {
    this.signupForm.value.isrememberme = e.target.checked;
  }
  get signupFormControl() { return this.signupForm.controls; }



  async signUp() {
    this.signupFormSubmitted = true;

    if (this.signupForm.invalid) {
      return;
    }
    else {
      this.isStillSigningIn = true;
      this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.signupForm.value.userid));

      const checkres: any = await this.checkUser();

      if (checkres != "err") {
        //instructor account
        sessionStorage.setItem("rememberme", this.signupForm.value.isrememberme ? "TRUE" : "FALSE")

        this.courseService.signIn(this.allinoneService.checkUserId(this.signupForm.value.userid), this.signupForm.value.password).subscribe((res: any) => {
          if (res.returncode == "310") {
            this.allinoneService.setCookie("kunyek_password", (this.signupForm.value.password));
            this.allinoneService.setCookie("userid", checkres.usersyskey);
            this.allinoneService.setCookie("atoken", res.atoken);

            sessionStorage.setItem("session", res.session);
            sessionStorage.setItem("existsuser", res.type);
            sessionStorage.setItem("kunyekusername", res.username);

            this.isStillSigningIn = false;

            // this.router.navigateByUrl('/');
            // setTimeout(() => {
              window.location.reload();
            // }, 1000);
          }
          else {
            // if (res.returncode == "300") {
            this.messageService.openSnackBar("Check your (email or phone number) and password or create an account. ", 'warn');
            // }
            // else {
            //   this.messageService.openSnackBar(res.message, 'warn');
            // }
            this.isStillSigningIn = false;
          }
        }, err => {
          this.isStillSigningIn = false;
        })
      }

    }
  }

  async forgotPassword() {
    this.router.navigateByUrl('instructor/forgot-password/1');
  }

    checkUser() {
      return new Promise(resolve => {
        this.courseService.checkUser().subscribe(async (checkres: any): Promise<any> => {
          if (checkres.returncode == "300") {
            //user does not exists
            if (checkres.status == 0) {
              this.messageService.openSnackBar('User is not found.Please create an account', 'warn');
              this.isStillSigningIn = false;
              this.isStillUserCheckingForForgot = false;
              resolve("err");
            }
            else {
              if (checkres.userrole == "0") {
                //student account
                this.messageService.openSnackBar('Please sign up to become an instructor.', 'warn');
                this.isStillSigningIn = false;
                this.isStillUserCheckingForForgot = false;
                resolve("err");
              }
              else {
                this.isStillUserCheckingForForgot = false;
                resolve(checkres);
              }
            }
          }
          else if (checkres.returncode == "210") {
            this.isStillUserCheckingForForgot = false;
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
            resolve("err");
          }

        }, err => {
          this.isStillSigningIn = false;
          this.isStillUserCheckingForForgot = false;
          resolve("err");
        });
      })

    }


  ///
  qrLogin(){
    this.signinType = false;
    this.qrLoading = true;

    if (this.allinoneService.getAtoken()) {
      clearInterval(this.getQrInterval)
      clearInterval(this.checkQrInterval)
      this.router.navigateByUrl('/home');
    } else {
      this.getQR()
      this.checkQrInterval = setInterval(() => {
        this.checkQr()
        console.log("checking qr");
      }, 10000);
      // }, 1000);
    }
  }


  phLogin() {
    clearInterval(this.checkQrInterval)
    clearInterval(this.getQrInterval)
    this.router.navigateByUrl('/login')
    this.signinType = true;
  }

  getQR() {
    console.log('getting qr')

    this.qrLoading = true;
    this.showRefresh = false;

    this.courseService.requestQR(this.value).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.value = res.qr;
        console.log(this.value);
        

        this.qrLoading = false;

        if (this.countGetQr == 1) {
          this.getQrInterval = setInterval(() => {
            if (this.countGetQr == 2) {
              clearInterval(this.getQrInterval)
              this.phLogin()
            }
            else {
              this.getQR();
            }
            this.countGetQr++
          }, (1000 * 60 * 2) + 50);
          // }, 10000 );
        }
      } else {
        this.qrLoading = false
        this.showRefresh = true
      }
    }, (err: any) => {
      this.qrLoading = false
      this.showRefresh = true
    });


  }



  checkQr() {
    this.courseService.checkQR(this.value).subscribe(async (res: any) => {
      if (res.returncode == "300") {
        clearInterval(this.checkQrInterval)
        clearInterval(this.getQrInterval)
        this.allinoneService.setCookie("kunyek_userid", res.userid);
        const checkres: any = await this.checkUser();
        if(checkres != "err"){
          this.allinoneService.setCookie("atoken", res.atoken);
          this.allinoneService.setCookie("userid", checkres.usersyskey);
          sessionStorage.setItem("kunyekusername", res.username);
          this.isStillSigningIn = false;
          window.location.reload();
        }
        else{
          this.countGetQr = 1;
          this.getQR()
          this.checkQrInterval = setInterval(() => {
            this.checkQr()
            console.log("checking qr");
          }, 10000);
        }
      }
    })
  }


}
