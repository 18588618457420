<app-breadcrumb *ngIf="!gettingChannel" [t_breadcrumb]="channel.name" [s_breadcrumb]="chl_text" (emit)="goToChannels()">
</app-breadcrumb>

<div class="container mt-3 mb-3">

  <div *ngIf="gettingChannel" class="nodata">
    <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
  </div>
  <div *ngIf="!gettingChannel">
    <!-- <form #f="ngForm" name="form" novalidate *ngIf="!gettingChannel" (ngSubmit)="f.form.valid && updateEditChannel()"> -->
    <div>
      <div>
        <label class="form-label"><strong>Logo</strong></label>
      </div>

      <div style="position: relative;">
        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">

          <img class="drag-image me-3" [defaultImage]="'../../../assets/images/uploadphoto.png'"
            [lazyLoad]="imgURL ? imgURL  : '../../../assets/images/uploadphoto.png'">

          <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
        </div>
        <!-- <button mat-icon-button class="remove-drag-image" *ngIf="imgURL" (click)="removeImage()">
          <mat-icon>remove_circle</mat-icon>
        </button> -->
      </div>
    </div>

    <div class="form-group  mb-3">
      <label for="chl_name" class="form-label"><strong>Channel Name</strong></label>
      <input type="text" [(ngModel)]="channel.name" name="name" class="form-control" id="name" #chlname="ngModel"
        autocomplete="off" [ngClass]="{ 'is-invalid': formSubmitted && chlname.invalid }" required>
      <div class="invalid-feedback" *ngIf="chlname.invalid || (chlname.dirty || chlname.touched) && formSubmitted">
        <div *ngIf="chlname.errors?.required && formSubmitted">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="edit_stat">
      <label for="chl_status" class="form-label"><strong>Channel Status</strong></label>
      <select class="form-select" name="chl_status" [(ngModel)]="channel.status" required>
        <option *ngFor="let stu of chl_status" [selected]="stu.value === channel.status" value="{{stu.value}}">
          {{stu.status}}</option>
      </select>
    </div>



    <div class=" mb-3">
      <label for="chl_desc" class="form-label"><strong>Channel Description</strong></label>
      <textarea type="text" class="form-control" [(ngModel)]="channel.description" name="chl_desc" id="chl_desc"
        style="height: 100px" #chldesc="ngModel" autocomplete="off"></textarea>
    </div>

    <ng-container *ngIf="role == 'admin'">
      <div class="mt-3">
        <label class="form-label"><strong>Channel Type</strong></label>
      </div>
      <div class="d-flex align-items-center mb-3">
        <div class="form-check me-5">
          <input class="form-check-input" type="radio" name="channeltype" id="public" [(ngModel)]="channel.type"
            value="001">
          <label class="form-check-label" for="public">
            Public
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="channeltype" id="private" [(ngModel)]="channel.type"
            value="002">
          <label class="form-check-label" for="private">
            Private
          </label>
        </div>
      </div>
    </ng-container>

    <div class="form-group mb-3" *ngIf="role == 'admin'">
      <label for="orgid" class="form-label">Organization</label>
      <select class="form-select" [(ngModel)]="this.channel.orgid" name="orgid" id="orgid" #org_id="ngModel" required>
        <option value="">-</option>
        <option *ngFor="let org of orgs" value="{{org.orgid}}">
          {{org.name}}</option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgs"></mat-progress-bar>
    </div>

    <!-- <div class="form-group mb-3" *ngIf="channel.type == '002' && role == 'admin'">
      <label for="orgid" class="form-label"><strong>Organization</strong></label>
      <select class="form-select" [(ngModel)]="this.channel.orgid" [disabled]="gettingOrgs" name="orgid" id="orgid"
        #org_id="ngModel" required>
        <option value="">-</option>
        <option *ngFor="let org of orgs" value="{{org.orgid}}">
          {{org.name}}</option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgs"></mat-progress-bar>
    </div> -->

    <div class="mt-3">
      <label class="form-label"><strong>Settings</strong></label>
    </div>
    <div class="container">
      <div class="row" style="width: 80%;">
        <div class="col-md-6 form-check mb-3" *ngIf="role == 'admin'">
          <input class="form-check-input" type="checkbox" id="publichide" [(ngModel)]="this.channel.hidden">
          <label class="form-check-label" for="publichide">
            Make only accessable from domain
          </label>
        </div>

        <div class="col-md-6 form-check mb-3">
          <input class="form-check-input" type="checkbox" id="sharecertificate"
            [(ngModel)]="this.channel.sharecertificate">
          <label class="form-check-label" for="sharecertificate">
            Show Share Certificate Button
          </label>
        </div>

        <div class="col-md-6 form-check mb-3">
          <input class="form-check-input" type="checkbox" id="allowselfsignup"
            [(ngModel)]="this.channel.allowselfsignup">
          <label class="form-check-label" for="allowselfsignup">
            Allow Self Sign Up
          </label>
        </div>

        <div class="col-md-6 form-check mb-3">
          <input class="form-check-input" type="checkbox" id="showstudiobutton"
            [(ngModel)]="this.channel.showstudiobutton">
          <label class="form-check-label" for="showstudiobutton">
            Show Studio Button
          </label>
        </div>

        <div class="col-md-6 form-check mb-3">
          <input class="form-check-input" type="checkbox" id="privatecourse" [(ngModel)]="this.channel.privatecourse">
          <label class="form-check-label" for="privatecourse">
            Private Course
          </label>
        </div>

      </div>
    </div>

    <div class="mt-3">
      <label class="form-label"><strong>Home Page Banner</strong></label>
    </div>
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div class="d-flex align-items-center flex-wrap">
        <div class="form-check me-5 mb-3">
          <input class="form-check-input" type="radio" name="banner" id="none" [(ngModel)]="channel.cmspage"
            value="001">
          <label class="form-check-label" for="none">
            None
          </label>
        </div>
        <div class="form-check me-5">
          <input class="form-check-input mb-3" type="radio" name="banner" id="image" [(ngModel)]="channel.cmspage"
            value="002">
          <label class="form-check-label" for="image">
            Image Slider
          </label>
        </div>
        <div class="form-check me-5">
          <input class="form-check-input mb-3" type="radio" name="banner" id="video" [(ngModel)]="channel.cmspage"
            value="003">
          <label class="form-check-label" for="video">
            Video
          </label>
        </div>

        <div class="form-check">
          <input class="form-check-input mb-3" type="radio" name="banner" id="image-text" [(ngModel)]="channel.cmspage"
            value="000">
          <label class="form-check-label" for="image-text">
            Image + Text
          </label>
        </div>
      </div>
  </div>

  <div *ngIf="channel.cmspage == '000'">
      <ng-container>
        <div class="row img-slide-row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-12 course-card mb-3"
            *ngFor="let slid of imageTextList; let i = index;">
            <mat-card class="imgslider-card">
              <div class="editicon">
                <button class="btn btn-outline-secondary" (click)="addImageToSlide(i)"><i
                    class="fa fa-edit"></i></button>
                <div style="width: 20px;"></div>
                <button class="btn btn-outline-secondary" (click)="removeFromImageSlide(i)"><i
                    class="fa fa-trash"></i></button>
              </div>
              <div class="img-container">
                <img [src]="slid.previewurl" alt="Banner Image">
              </div>
            </mat-card>
          </div>
        </div>
      </ng-container>
  </div>

  <div *ngIf="channel.cmspage == '002'">
    <ng-container>
      <div class="row img-slide-row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 course-card mb-3"
          *ngFor="let slid of imageSliderList; let i = index;">
          <mat-card class="imgslider-card">
            <div class="editicon">
              <button class="btn btn-outline-secondary" (click)="addImageToSlide(i)"><i
                  class="fa fa-edit"></i></button>
              <div style="width: 20px;"></div>
              <button class="btn btn-outline-secondary" (click)="removeFromImageSlide(i)"><i
                  class="fa fa-trash"></i></button>
            </div>
            <div class="img-container">
              <img [src]="slid.previewurl" alt="Banner Image">
            </div>
          </mat-card>
        </div>
      </div>
    </ng-container>
</div>


  <div *ngIf="channel.cmspage == '003' && videoSlide">

      <div class="row img-slide-row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 course-card">
          <ng-container *ngIf="videoSlide.videotype == '001'">
            <iframe [src]="videoUrlPreview | safe"></iframe>
          </ng-container>
          <div *ngIf="videoSlide.videotype == '002'">
            <div>
              <vm-player>
                <vm-video>
                  <source [attr.data-src]="videoUrlPreview" type="video/mp4" />
                </vm-video>
                <vm-default-ui no-click-to-play>
                </vm-default-ui>
              </vm-player>
            </div>
          </div>
        </div>
      </div>
  </div>

  <ng-container *ngIf="channel.cmspage != '001'">

      <button mat-flat-button color="primary" class="ms-0 mt-2 me-auto" (click)="addImageToSlide()"
        *ngIf="channel.cmspage == '002'">
        <i class="fa fa-desktop"></i>
        Add Slide </button>

      <button mat-flat-button color="primary" class="ms-0 mt-2 me-auto"
        (click)="videoSlide ? addImageToSlide(0): addImageToSlide()" *ngIf="channel.cmspage == '003'">
        <i class="fa fa-desktop"></i>
        Set Up Video </button>

        <button mat-flat-button color="primary" class="ms-0 mt-2 me-auto" (click)="addImageToSlide()"
        *ngIf="channel.cmspage == '000'">
        <i class="fa fa-desktop"></i>
        Add Image Text </button>
  </ng-container>


  <div class="mt-2">
      <label for="chl_name" class="form-label "><strong>Home Page</strong></label>
  </div>

  <div class="d-flex align-items-center mb-3">
      <div class="form-check me-5">
        <input class="form-check-input" type="radio" name="default" id="default" [(ngModel)]="homePageType" value="001">
        <label class="form-check-label" for="default">
          Default
        </label>
      </div>
      <div class="form-check me-5">
        <input class="form-check-input" type="radio" name="custom" id="custom" [(ngModel)]="homePageType" value="002">
        <label class="form-check-label" for="custom">
          Custom
        </label>
      </div>
  </div>

  <div *ngIf="homePageType == '002'" class="status-container card">
      <ng-container *ngFor="let statusdata of homePageUiList; let i=index; let first=first">
        <div class="status-input-container">
          <div class="status-input">
            <label *ngIf="first" [attr.for]="'status'+i" class="form-label can-click cc-label">Title</label>
            <input type="text" [attr.id]="'title'+i" [attr.name]="'title'+i" [(ngModel)]="statusdata.title"
              class="form-control">
            <!-- <mat-progress-bar mode="indeterminate" *ngIf="gettingStatusList"></mat-progress-bar> -->
          </div>
          <div class="status-input">
            <label *ngIf="first" [attr.for]="'c'+i" class="form-label"> Categories </label>
            <!-- <ng-select class="role-select" [items]="categoriesList" [bindLabel]="'c'+i" placeholder=""
              appendTo="body" [multiple]="true" [(ngModel)]="statusdata.categories">
              <ng-option *ngFor="let c of categoriesList" [value]="c.categoryid">{{c.categoryname}}></ng-option>
            </ng-select> -->
            <ng-select placeholder="" [multiple]="true" [(ngModel)]="statusdata.categories" [name]="'c'+i" [id]="'c'+i"
              appearance="outline" [searchable]="true" [clearable]="true">
              <ng-option *ngFor="let coursetype of categoriesList"
                [value]="coursetype.categoryid">{{coursetype.categoryname}}</ng-option>
            </ng-select>
          </div>
          <div class="">
            <button class="btn prize-btn remove-btn-custom" (click)="removeSubmitConfig(i)">
              <mat-icon>remove_circle</mat-icon>
            </button>
          </div>
        </div>

        <!-- btn-custom -->
      </ng-container>
      <button (click)="addNewStatus()" [disabled]="homePageUiList.length >= 10" class="btn new-btn mt-1 mb-1 ms-0">
        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
      </button>
  </div>


  <div class="d-flex justify-content-end">

      <button [disabled]="isCreating" (click)="goToChannels()" mat-flat-button color="primary" class="mt-2">
        Cancel </button>
      <app-button-loader [button_text]="'Update'" [isStillupload]="isCreating" (click)="updateEditChannel()">
      </app-button-loader>
  </div>
    <!-- </form> -->
</div>