import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-grade',
  templateUrl: './filter-grade.component.html',
  styleUrls: ['./filter-grade.component.scss'],
})
export class FilterGradeComponent {
  selectedUnitId: string = '';
  gradeFilterValue: number = 0;
  filterGradeOp: string = "=";

  constructor(
    public dialogRef: MatDialogRef<FilterGradeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close({
      selectedUnitId: this.selectedUnitId,
      gradeFilterValue: this.gradeFilterValue,
      filterGradeOp: this.filterGradeOp,
    });
  }
}
