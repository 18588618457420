<app-breadcrumb [s_breadcrumb]="'Categories'"></app-breadcrumb>
<div class="content">
  <div class="d-flex align-items-center">
    <div class="me-auto mb-3">
      <button mat-flat-button color="primary" class="ms-0" (click)="newSubcategory()">
        <i class="fas fa-plus me-2"></i>New Subcategory
      </button>
    </div>
    <div class="me-2 mb-3">
      Total :<b> {{categoryData.length}}</b>
    </div>
    <div class="input-group mb-3" style="width: 260px !important;">
      <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
      <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
        *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="form-group mb-3" style="width: 260px !important; margin-left: 16px">
      <select class="form-select" [(ngModel)]="channelid" (ngModelChange)="channelChange()"
        matTooltip="Filter By Channel">
        <option [value]="chl.channelid" *ngFor="let chl of channels">
          {{ chl.name }}
        </option>
      </select>
    </div>
    <button class="btn new-class ms-2 mb-3" matTooltip="Refresh" type="button" (click)="getSubCategories()">
      <i class="fa fa-refresh"></i>
    </button>
  </div>

  <table mat-table #table [dataSource]="categoryTableData" matSort style="width: 100%;"
    (matSortChange)="sortData($event)">

    <!-- Sub Category Name Column -->
    <ng-container matColumnDef="subcategory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Subcategory Name</th>
      <td mat-cell *matCellDef="let element">
        {{element.subcategoryname}}
      </td>
    </ng-container>

    <!-- Category Name Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
      <td mat-cell *matCellDef="let element">
        {{element.maincatname}}
      </td>
    </ng-container>

    <!-- Order Column -->
    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>
      <td mat-cell *matCellDef="let element">
        {{element.sortkey}}
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
      <td mat-cell *matCellDef="let element">
        <span class="d-flex align-items-center">
          <button class="btn btn-outline-primary btn-custom-rounded rounded-circle me-2" matTooltip="Edit Subcategory"
          style="font-size: 14px;width:42px;height: 40px;" (click)="editSubcategory(element)">
          <i class="fa fa-edit"></i>
        </button>
        <button class="btn btn-outline-danger btn-custom-rounded rounded-circle " matTooltip="Delete Subcategory"
          style="font-size: 14px;width:42px;height: 40px;" (click)="deleteSubcategory(element)">
          <i class="fa fa-trash"></i>
        </button>
        </span>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td colspan="4">
        <span class="nodata" *ngIf="!gettingCategories">Empty</span>
        <span class="nodata" *ngIf="gettingCategories">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </span>
      </td>
    </tr>


  </table>

  <mat-paginator #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>

</div>