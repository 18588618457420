import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-grade-assignment',
  templateUrl: './grade-assignment.component.html',
  styleUrls: ['./grade-assignment.component.scss'],
})
export class GradeAssignmentComponent implements OnInit {
  assignment: any;

  isLoading: boolean = false;
  grade: number;
  feedback: string = '';

  constructor(
    private dialogRef: MatDialogRef<GradeAssignmentComponent>,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.assignment = this.data.assignment;
    this.grade = this.assignment.grade;
    this.feedback = this.assignment.feedback;
  }

  ngOnInit(): void {
    console.log(this.assignment);
  }

  dismiss() {
    if (!this.isLoading) {
      this.dialogRef.close({
        success: false,
        data: {}
      });
    }
  }

  downloadFile() {
    window.open(this.assignment.fileurl, '_blank');
  }

  save() {
    if (this.isLoading) {
      return;
    }

    if (
      (this.grade && this.grade < 0) ||
      (this.assignment.unitid &&  this.grade && 
        parseInt(this.assignment.totalgrade) < this.grade)
    ) {
      this.messageService.openSnackBar('Please enter a proper grade.', 'warn');
      return;
    }
    const temp = this.grade;
    const grade = temp.toString();
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    this.courseService
      .gradeAssignment(this.assignment.classdbid, grade, this.feedback)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.dialogRef.close({
              success: true,
              data: {
                grade: grade,
                feedback : this.feedback
              }
            })
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        },
        (err) => {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        }
      );
  }
}
