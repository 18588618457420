<div class="d-flex align-items-center justify-content-between">
  <div class="mb-3">
    <h2 class="mb-0">
      {{assignment.studentname || assignment.username || assignment.useid}}
    </h2>
  </div>
  <div class="mb-3">
    <mat-icon style="cursor: pointer;" (click)="dismiss()">close</mat-icon>
  </div>
</div>
<ul class="list-group mb-3">
  <li class="list-group-item">
    <div class="row">
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Title
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{assignment.title || '-'}}
        </div>
      </div>
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          File
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          <span>
            <span class="cus-point filename" (click)="downloadFile()">{{assignment.filename}}</span>
            <i class="fa fa-download ms-2 cus-point" (click)="downloadFile()"></i>
          </span>
        </div>
      </div>
    </div>
  </li>
  <li class="list-group-item">
    <div class="row">
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Remark
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{assignment.remark || '-'}}
        </div>
      </div>
      <div class="col-6">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          Submitted Date
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mr-text">
          {{ allinoneService.formatDBToShow(assignment.date)
          }}&nbsp;{{assignment.time}}
        </div>
      </div>
    </div>
  </li>

</ul>

<div class="form-group" class="mb-3">
  <label for="grade" class="mb-2">
    Grade
  </label>
  <div class="input-group mb-3">
    <input type="number" class="form-control" id="grade" name="grade" [(ngModel)]="grade" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
    <div class="input-group-append" *ngIf="assignment.unitid">
      <span class="input-group-text">/&nbsp;{{assignment.totalgrade}}</span>
    </div>
  </div>
</div>


<div class="form-group" class="mb-3">
  <label for="feedback" class="mb-2">
    Feedback
  </label>
  <textarea type="text" class="form-control" placeholder="" id="feedback" name="feedback" rows="3"
    [(ngModel)]="feedback"></textarea>
</div>

<div class="actions-button">
  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled]="isLoading">
    Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="save()">
  </app-button-loader>

</div>