import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Library } from '../../shared/models/Library';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @ViewChild('foldername') foldername: ElementRef | undefined;
  @ViewChild('choosefile') choosefile: any;

  newSubmission: boolean = false;
  submitted: boolean = false;
  isEditing: boolean = false;
  isLoading: boolean = false;
  isSaving: boolean = false;
  isFolder: boolean = true;

  fileSelected: any = '';
  idForEdit: string = '';
  currentFilePath: string = '';

  progress: number = 0;

  form = this.formBuider.group({
    fileid: ['', Validators.required],
    filedesc: [''],
    filename: [''],
    uploadfilename: [''],
    filepath: [''],
    filesize: [''],
    filetype: [''],
  });

  get formControl() {
    return this.form.controls;
  }

  constructor(
    public dialogRef: MatDialogRef<UploadComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.currentFilePath = data.currentFilePath;
      this.isFolder = data.isFolder;
      this.isEditing = data.isEditing;
      if (this.isEditing) {
        var file = data.file;
        this.idForEdit = file.id;
        this.form.setValue({
          fileid: file.fileid,
          filedesc: file.filedescription,
          filename: file.filename,
          uploadfilename: file.uploadfilename,
          filepath: file.filepath,
          filesize: file.filesize,
          filetype: file.filetype,
        });
      }
    }
  }

  ngOnInit(): void {
    this.form.patchValue({
      filepath: this.currentFilePath,
    });
    if (this.isFolder && !this.isEditing) {
      this.form.patchValue({
        filename: 'Untitled Folder',
        filetype: 'folder',
        filesize: '',
      });
      setTimeout(() => {
        const inputElem = <HTMLInputElement>this.foldername?.nativeElement;
        inputElem.select();
      }, 100);
    }
  }

  uploadFile(event: any) {
    var filesize = event[0].size / (1024 * 1024);
    if(filesize > 200){
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    if (this.fileSelected) {
      this.choosefile.nativeElement.value = '';
    }
    var filetype = event[0].name.split('.').pop();
    this.fileSelected = event[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      uploadfilename: this.getUploadFileName(this.fileSelected),
      filetype: filetype,
      filesize: this.fileSelected.size / 1000,
    });
  }

  onFileSelected(event: any) {
    var filesize = event.target.files[0].size / (1024 * 1024);
    if(filesize > 200){
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event.target.files[0].name.split('.').pop();
    this.fileSelected = event.target.files[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      uploadfilename: this.getUploadFileName(this.fileSelected),
      filetype: filetype,
      filesize: this.fileSelected.size / 1000,
    });

    console.log(this.formControl.filename.value.toString().trim());

    this.choosefile.nativeElement.value = '';
  }

  cancel() {
    this.dialogRef.close({ success: false, data: {} });
  }

  getUploadFileName(file: File) {
    var filetype = file.name.split('.').pop();
    var filename = file.name
      .substring(0, file.name.lastIndexOf('.'))
      .replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    return (filename =
      filename + '_' + time + '.' + filetype?.toLocaleLowerCase());
  }

  sumbitForm() {
    this.submitted = true;
    // console.log(this.form);
    if (
      this.formControl.fileid.invalid ||
      (!this.isFolder && !this.isEditing && !this.fileSelected)
    ) {
      return;
    }
    this.isSaving = true;
    this.formControl.fileid.disable();
    this.formControl.filedesc.disable();
    this.dialogRef.disableClose = true;
    var mydata = {
      fileid: this.formControl.fileid.value.toString().trim(),
      filename: this.formControl.filename.value.toString().trim(),
      filedescription: this.formControl.filedesc.value.toString().trim(),
      uploadfilename: this.formControl.uploadfilename.value.toString().trim(),
      filepath: this.formControl.filepath.value.toString().trim(),
      filesize: this.formControl.filesize.value.toString().trim(),
      filetype: this.formControl.filetype.value
        .toString()
        .toLocaleLowerCase()
        .trim(),
    };

    // return;
    if (this.fileSelected) {

      this.allinoneService
        .fileUploadToS3WithPathForImageAndProgress(
          this.fileSelected,
          mydata.uploadfilename,
          'library/' + mydata.filepath.slice(0, -1)
        )
        .on('httpUploadProgress', (progress) => {
          let progressPercentage = Math.round(
            (progress.loaded / progress.total) * 100
          );
          console.log(progressPercentage);
          this.progress = progressPercentage;
        })
        .send((err: any, data: any) => {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            //Fail
            this.progress = 0;
            this.isSaving = false;
            this.dialogRef.disableClose = false;
            this.formControl.fileid.enable();
            this.formControl.filedesc.enable();
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            return false;
          }
          console.log('Successfully uploaded file.', data);
          this.saveFile(mydata);
          return true;
        });






      
      // .then(
      //   () => {
      //     //Success
      //     this.saveFile(data);
      //   },
      //   () => {
      //     //Fail
      //     this.isSaving = false;
      //     this.dialogRef.disableClose = false;
      //     this.messageService.openSnackBar(
      //       'Error while uploading your file.',
      //       'warn'
      //     );
      //   }
      // );
    } else {
      this.saveFile(mydata);
    }
  }
  saveFile(data: any) {
    var fun = this.isEditing
      ? this.courseService.editLibraryFile(
          this.idForEdit,
          data.fileid,
          data.filename,
          data.uploadfilename,
          data.filedescription,
          data.filepath,
          data.filesize,
          data.filetype
        )
      : this.courseService.addFileToLibrary(
          data.fileid,
          data.filename,
          data.uploadfilename,
          data.filedescription,
          data.filepath,
          data.filesize,
          data.filetype
        );
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var file: Library = {
            ...data,
            ...res,
          };
          this.dialogRef.close({ success: true, data: file });
        } else {
          this.isSaving = false;
          this.dialogRef.disableClose = false;
          this.formControl.fileid.enable();
          this.formControl.filedesc.enable();
          this.messageService.openSnackBar(
            res.error || res.status || res.message,
            'warn'
          );
        }
      },
      (err) => {
        this.isSaving = false;
        this.dialogRef.disableClose = false;
        this.formControl.fileid.enable();
        this.formControl.filedesc.enable();
      }
    );
  }
}
