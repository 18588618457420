<app-breadcrumb [s_breadcrumb]="'Feedback Data'"></app-breadcrumb>
<div class="container-fluid mt-2 mb-2">
  <div class="d-flex align-items-center flex-wrap mt-3 mb-3 justify-content-end">
    <div class="me-2">
      Total :<b> {{coursesData.length}}</b>
    </div>
    <div class="input-group me-2" style="width: 260px !important">
      <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" />
      <button class="btn" type="button" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
        *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4; color: #00bcd4">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="me-2">
      <select class="form-select" [(ngModel)]="channelid" required #chlid="ngModel" style="width: 260px !important"
        (ngModelChange)="channelChange($event)">
        <option value="" *ngIf="role == 'admin'">KHub</option>
        <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.name}}</option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingChannels"></mat-progress-bar>
    </div>
    <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button">
      <i class="fa fa-refresh"></i>
    </button>
  </div>
  <div style="overflow: auto;">
    <table mat-table [dataSource]="coursesTableData" matSort style="width: 100%;">

      <!-- Course Image Column -->
      <ng-container matColumnDef="courseimage">
        <th mat-header-cell *matHeaderCellDef style="width: 200px;"></th>
        <td mat-cell *matCellDef="let element" class="cus-point">
          <img *ngIf="element.courseimage == ''" [src]="allinoneService.defaultImg" class="course-listing-image" />
          <img *ngIf="element.courseimage != ''" [src]="allinoneService.imgurl + element.courseimage"
            class="course-listing-image" />
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name </th>
        <td mat-cell *matCellDef="let element" class="cus-point"> {{element.coursename}} </td>
      </ng-container>

      <!-- Instructor Column -->
      <ng-container matColumnDef="instructorname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Instructor
        </th>
        <td mat-cell *matCellDef="let element" px-3 class="cus-point"> {{element.instructorname}}
        </td>
      </ng-container>

      <!-- Feedback Count Column -->
      <ng-container matColumnDef="feedbackcount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Feedback Count
        </th>
        <td mat-cell *matCellDef="let element" px-3 class="cus-point">
          {{element.feedbackcount}}
        </td>
      </ng-container>

      <!-- Action Column -->
     

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewFeedback(row)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td colspan="4">
          <span class="nodata" *ngIf="!gettingCourses">Empty</span>
          <span class="nodata" *ngIf="gettingCourses">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>
    </table>
    <mat-paginator #paginator *ngIf="coursesData.length > 0" [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
</div>