import { DropboxSubmissionsComponent } from './class/dropbox-submissions/dropbox-submissions.component';
import { StudentsComponent } from './class/students/students.component';
import { ClassviewComponent } from './class/classview/classview.component';
import { MemberComponent } from './instructor/member/member.component';
import { AddmemberComponent } from './instructor/addmember/addmember.component';
import { EditChannelComponent } from './channel/edit-channel/edit-channel.component';
import { ChannelComponent } from './channel/channel/channel.component';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CourseNameComponent } from './instructor/course-name/course-name.component';
import { CourseAddComponent } from './instructor/course-add/course-add.component';
import { DashboardComponent } from './instructor/dashboard/dashboard.component';
import { InstructorSignupComponent } from './instructor/instructor-signup/instructor-signup.component';
import { LoginComponent } from './instructor/login/login.component';
import { VerifyComponent } from './instructor/verify/verify.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { ProfileComponent } from './profiles/profile/profile.component';
import { ForgotPasswordComponent } from './instructor/forgot-password/forgot-password.component';
import { CreateChannelComponent } from './channel/create-channel/create-channel.component';
import { MyChannelComponent } from './instructor/my-channel/my-channel.component';
import { ChannelAnalyticsComponent } from './analytic/channel-analytics/channel-analytics.component';
import { CourseAnalyticsComponent } from './analytic/course-analytics/course-analytics.component';
import { CoursePreviewComponent } from './instructor/course-preview/course-preview.component';
import { CourseContentPreviewComponent } from './instructor/course-content-preview/course-content-preview.component';
import { ApproveInstructorComponent } from './instructor/approve-instructor/approve-instructor.component';
import { LibraryViewComponent } from './library/library-view/library-view.component';
import { QuestionEditorComponent } from './quizz/question-editor/question-editor.component';
import { CheckurlComponent } from './instructor/checkurl/checkurl.component';
import { PaidStudentsComponent } from './class/paid-students/paid-students.component';
import { ProfileViewComponent } from './profiles/profile-view/profile-view.component';
import { UserAnalyticsComponent } from './instructor/user-analytics/user-analytics.component';
import { StudentsByCourseComponent } from './analytic/students-by-course/students-by-course.component';
import { CoursesByStudentComponent } from './analytic/courses-by-student/courses-by-student.component';
import { SingleStudentSingleCourseComponent } from './analytic/single-student-single-course/single-student-single-course.component';
import { TemplatesComponent } from './certificates/templates/templates.component';
import { SettingDashboardComponent } from './settings/setting-dashboard/setting-dashboard.component';
import { StudentslistComponent } from './instructor/studentslist/studentslist.component';
import { ViewLiveDiscussionComponent } from './discussion/view-live-discussion/view-live-discussion.component';
import { ReportByCategoryComponent } from './reporting/report-by-category/report-by-category.component';
import { AdminScheduleComponent } from './schedules/admin-schedule/admin-schedule.component';
import { CurtomRouteReuseStrategy } from './shared/services/route-reuse.service';
import { ClassDetailsComponent } from './reporting/class-details/class-details.component';
import { ClassStudentDetailsComponent } from './reporting/class-student-details/class-student-details.component';
import { PrerequisitesComponent } from './settings/prerequisites/prerequisites.component';
import { FeedbackTemplateComponent } from './feedback/feedback-template/feedback-template.component';
import { FeedbackDataComponent } from './feedback/feedback-data/feedback-data.component';
import { SubCategoryComponent } from './category/sub-category/sub-category.component';
import { InstructorReportDashboardComponent } from './instructor/instructor-report-dashboard/instructor-report-dashboard.component';
import { TemplateViewComponent } from './feedback/template-view/template-view.component';
import { TemplateQuestionsViewComponent } from './feedback/template-questions-view/template-questions-view.component';
import { FeedbackCourseViewComponent } from './feedback/feedback-course-view/feedback-course-view.component';
import { CustomSchedulesComponent } from './schedules/custom-schedules/custom-schedules.component';
import { CategorylistComponent } from './category/categorylist/categorylist.component';
import { CanDeactivateFormsGuard } from './shared/guards/can-deactivate-forms.guard';
import { MembertypeComponent } from './member/membertype/membertype.component';
import { VideoDetailsComponent } from './videos/video-details/video-details.component';
import { LearningTrackComponent } from './instructor/learning-track/learning-track.component';
import { LearningTrackUserAddComponent } from './instructor/learning-track-user-add/learning-track-user-add.component';
import { ReportByCategoryDetailsComponent } from './reporting/report-by-category-details/report-by-category-details.component';
import { CourseLearnComponent } from 'projects/e-learning/src/app/students/course-learn/course-learn.component';
import { QuestionComponent } from 'projects/e-learning/src/app/quiz/question/question.component';
import { QuizPdfComponent } from './instructor/quiz-pdf/quiz-pdf.component';
import { CreateUserComponent } from './create-user/create-user.component';

const routes: Routes = [
  {
    path: 'studio',
    component: CheckurlComponent,
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: '*',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: ':site/forgot-password/:pageno',
    component: ForgotPasswordComponent,
  },
  {
    path: 'signup',
    component: InstructorSignupComponent,
  },
  {
    path: 'instructor/profiles',
    component: ProfileViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'instructor/profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'instructor/courses',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: '*',
    },
  },
  {
    path: 'instructor/analytics',
    component: InstructorReportDashboardComponent,
    canActivate: [AuthGuard],
  },
  // Video Details
  {
    // // 4200/instructor/courses/20526197495244692181/videos
    path: 'videos/:courseid/:unitid/:unitname',
    component: VideoDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'instructor/courses/:page',
    component: CourseNameComponent,
    canActivate: [AuthGuard],
  },
  // instructor/courses/72351130320148762224/
  {
    path: 'instructor/courses/:courseid/classes',
    component: ClassviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'instructor/courses/:courseid/classes/:classid',
    component: StudentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'students/courses/:courseid/sections',
    component: StudentsComponent,
    canActivate: [AuthGuard],
    data: {
      isFromStudentsPage: true,
    },
  },
  {
    path: 'instructor/courses/:courseid/:classid/dropbox',
    component: DropboxSubmissionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'instructor/courses/:courseid/:tab',
    component: CourseAddComponent,
    canActivate: [AuthGuard],
    // data: {
    //   shouldReuseRoute: true,
    //   reuseRoutesFrom: '*',
    //   shouldNotReuseRoutesFrom: ['', 'instructor/courses'],
    // },
  },
  {
    path: 'instructor/courses/:courseid/:tab/:unitid',
    component: ViewLiveDiscussionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'preview/information/instructor/courses/:courseid',
    component: CoursePreviewComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'preview/content/instructor/courses/:courseid',
    component: CourseContentPreviewComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'admin/channel',
    component: ChannelComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'chladmin/channel',
    component: ChannelComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'admin/analytics',
    component: ChannelAnalyticsComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'chladmin/analytics',
    component: ChannelAnalyticsComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'supervisor/analytics',
    component: ChannelAnalyticsComponent,
    canActivate: [AuthGuard],
    data: { role: 'supervisor' },
  },
  {
    path: 'hradmin/analytics',
    component: ChannelAnalyticsComponent,
    canActivate: [AuthGuard],
    data: { role: 'hradmin' },
  },
  {
    path: 'chladmin/prerequisites',
    component: PrerequisitesComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'supervisor/prerequisites',
    component: PrerequisitesComponent,
    canActivate: [AuthGuard],
    data: { role: 'supervisor' },
  },
  {
    path: 'admin/certificates',
    component: TemplatesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateFormsGuard],
    data: { role: 'admin' },
  },
  {
    path: 'chladmin/certificates',
    component: TemplatesComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'admin/analytics/:channelid',
    component: CourseAnalyticsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'admin/analytics/:channelid/user/:userid',
    component: CoursesByStudentComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'admin/analytics/:channelid/user/:userid/:courseid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'student', role: 'admin' },
  },

  {
    path: 'admin/analytics/:channelid/course/:courseid',
    component: StudentsByCourseComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'admin/analytics/:channelid/course/:courseid/:userid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'course', role: 'admin' },
  },

  // -------------------
  {
    path: 'chladmin/analytics/:channelid',
    component: CourseAnalyticsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'chladmin/analytics/:channelid/user/:userid',
    component: CoursesByStudentComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'chladmin/analytics/:channelid/user/:userid/:courseid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'student', role: 'chladmin' },
  },

  {
    path: 'chladmin/analytics/:channelid/course/:courseid',
    component: StudentsByCourseComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'chladmin/analytics/:channelid/course/:courseid/:userid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'course', role: 'chladmin' },
  },
  // -------------------
  // -------------------
  {
    path: 'hradmin/analytics/:channelid',
    component: CourseAnalyticsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },

  {
    path: 'hradmin/analytics/:channelid/user/:userid',
    component: CoursesByStudentComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },

  {
    path: 'hradmin/learningtrack/:channelid/:ltid/user/:userid',
    component: CoursesByStudentComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
      type: 'learningtrack',
    },
  },

  {
    path: 'hradmin/analytics/:channelid/user/:userid/:courseid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'student', role: 'hradmin' },
  },

  {
    path: 'hradmin/analytics/:channelid/course/:courseid',
    component: StudentsByCourseComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },

  {
    path: 'hradmin/analytics/:channelid/course/:courseid/:userid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'course', role: 'hradmin' },
  },
  // -------------------

  {
    path: 'supervisor/analytics/:channelid',
    component: CourseAnalyticsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
    },
  },

  {
    path: 'supervisor/analytics/:channelid/user/:userid',
    component: CoursesByStudentComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
    },
  },

  {
    path: 'supervisor/analytics/:channelid/user/:userid/:courseid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'student', role: 'supervisor' },
  },

  {
    path: 'supervisor/analytics/:channelid/course/:courseid',
    component: StudentsByCourseComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
    },
  },

  {
    path: 'supervisor/analytics/:channelid/course/:courseid/:userid',
    component: SingleStudentSingleCourseComponent,
    canActivate: [AuthGuard],
    data: { type: 'course', role: 'supervisor' },
  },

  // -------------------

  {
    path: 'admin/instructor',
    component: ApproveInstructorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/students',
    component: StudentslistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/settings',
    component: SettingDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/report',
    component: ReportByCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'admin/report/:courseid/:status/:daterange',
        'admin/report/:courseid/:status/:daterange/:classid/:studentstatus',
        'admin/report/details/:channelid/:searchuser/:sd/:ed',
      ],
    },
  },
  {
    path: 'admin/report/details/:channelid/:searchuser/:sd/:ed',
    component: ReportByCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },
  {
    path: 'hradmin/report/details/:channelid/:searchuser/:sd/:ed',
    component: ReportByCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },
  {
    path: 'chladmin/report/details/:channelid/:searchuser/:sd/:ed',
    component: ReportByCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },
  {
    path: 'supervisor/report/details/:channelid/:searchuser/:sd/:ed',
    component: ReportByCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
    },
  },
  {
    path: 'chladmin/report',
    component: ReportByCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'chladmin/report/:courseid/:status/:daterange',
        'chladmin/report/:courseid/:status/:daterange/:classid/:studentstatus',
        'chladmin/report/details/:channelid/:searchuser/:sd/:ed',
      ],
    },
  },
  {
    path: 'supervisor/report',
    component: ReportByCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'supervisor/report/:courseid/:status/:daterange',
        'supervisor/report/:courseid/:status/:daterange/:classid/:studentstatus',
        'supervisor/report/details/:channelid/:searchuser/:sd/:ed',
      ],
    },
  },
  {
    path: 'hradmin/report',
    component: ReportByCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'hradmin/report/:courseid/:status/:daterange',
        'hradmin/report/:courseid/:status/:daterange/:classid/:studentstatus',
        'hradmin/report/details/:channelid/:searchuser/:sd/:ed',
      ],
    },
  },
  {
    path: 'admin/report/:courseid/:status/:daterange',
    component: ClassDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'admin/report/:courseid/:status/:daterange/:classid/:studentstatus',
      ],
    },
  },
  {
    path: 'hradmin/report/:courseid/:status/:daterange',
    component: ClassDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'hradmin/report/:courseid/:status/:daterange/:classid/:studentstatus',
      ],
    },
  },
  {
    path: 'chladmin/report/:courseid/:status/:daterange',
    component: ClassDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'supervisor/report/:courseid/:status/:daterange',
    component: ClassDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: 'supervisor' },
  },
  {
    path: 'admin/report/:courseid/:status/:daterange/:classid/:studentstatus',
    component: ClassStudentDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'hradmin/report/:courseid/:status/:daterange/:classid/:studentstatus',
    component: ClassStudentDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: 'hradmin' },
  },
  {
    path: 'chladmin/report/:courseid/:status/:daterange/:classid/:studentstatus',
    component: ClassStudentDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'supervisor/report/:courseid/:status/:daterange/:classid/:studentstatus',
    component: ClassStudentDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: 'supervisor' },
  },

  // Old Schedules

  // {
  //   path: 'chladmin/schedule',
  //   component: AdminScheduleComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: 'chladmin' },
  // },
  // {
  //   path: 'supervisor/schedule',
  //   component: AdminScheduleComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: 'supervisor' },
  // },

  // New Schedules
  {
    path: 'chladmin/schedule',
    component: CustomSchedulesComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'supervisor/schedule',
    component: CustomSchedulesComponent,
    canActivate: [AuthGuard],
    data: { role: 'supervisor' },
  },

  {
    path: 'admin/channel/create',
    component: CreateChannelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/channel/:edit/:channelid/member/addmember',
    component: AddmemberComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'admin/channel/:edit/:channelid/member',
    component: MemberComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'chladmin/channel/:edit/:channelid/member/addmember',
    component: AddmemberComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'admin/channel/:edit/:channelid/member/create-user',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'chladmin/channel/:edit/:channelid/member/create-user',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },
  {
    path: 'chladmin/channel/:edit/:channelid/member',
    component: MemberComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },

  {
    path: 'admin/channel/:edit/:id',
    component: EditChannelComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'chladmin/channel/:edit/:id',
    component: EditChannelComponent,
    canActivate: [AuthGuard],
    data: { role: 'chladmin' },
  },

  {
    path: 'instructor/mychannel',
    component: MyChannelComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'instructor/library',
    component: LibraryViewComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'instructor/analytics/:channelid',
    component: CourseAnalyticsComponent,
    canActivate: [AuthGuard],
    data: { type: 'instructor' },
  },

  {
    path: 'instructor/analytics/:channelid/:courseid',
    component: UserAnalyticsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'eLearning', redirectTo: 'eLearning/dashboard' },

  {
    path: 'admin/feedback',
    component: TemplateViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'chladmin/feedback',
    component: TemplateViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'admin/course/feedback',
    component: FeedbackCourseViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'chladmin/course/feedback',
    component: FeedbackCourseViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'admin/course/feedback/:courseid',
    component: FeedbackDataComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'chladmin/course/feedback/:courseid',
    component: FeedbackDataComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'admin/feedback/:templateid',
    component: TemplateQuestionsViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'chladmin/feedback/:templateid',
    component: TemplateQuestionsViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'admin/feedbacktemplate',
    component: TemplateViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'chladmin/feedbacktemplate',
    component: TemplateViewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'supervisor/feedback',
    component: FeedbackTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
    },
  },

  {
    path: 'admin/feedback/:courseid',
    component: FeedbackDataComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'chladmin/feedback/:courseid',
    component: FeedbackDataComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'supervisor/feedback/:courseid',
    component: FeedbackDataComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'supervisor',
    },
  },

  {
    path: 'chladmin/categories',
    // component: SubCategoryComponent,
    component: CategorylistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chladmin/membertype',
    // component: SubCategoryComponent,
    component: MembertypeComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'chladmin',
    },
  },

  {
    path: 'admin/membertype',
    // component: SubCategoryComponent,
    component: MembertypeComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'hradmin/learningtrack',
    component: LearningTrackComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'admin/channel/:edit/:channelid/member',
  //   component: MemberComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: 'admin' },
  // },

  {
    path: 'hradmin/learningtrack/:channelid/:ltid/user',
    component: LearningTrackUserAddComponent,
    canActivate: [AuthGuard],
  },

  // Student Route------------------------------------------------------------------------------
  // {
  //   path: 'courses/:courseid/lectures/:lectureid',
  //   component: CourseLearnComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     isFromInstructor: true,
  //   },
  // },

  {
    path: 'preview/courses/:courseid/lectures/:lectureid/published',
    component: CourseLearnComponent,
    canActivate: [AuthGuard],
    data: {
      isFromInstructor: true,
      isPublished: true,
    },
  },

  {
    path: 'preview/courses/:courseid/lectures/:lectureid/unpublished',
    component: CourseLearnComponent,
    canActivate: [AuthGuard],
    data: {
      isFromInstructor: true,
      isPublished: false,
    },
  },

  {
    path: 'preview/courses/:courseid/lectures/published',
    component: CourseLearnComponent,
    canActivate: [AuthGuard],
    data: {
      isFromInstructor: true,
      isPublished: true,
    },
  },

  {
    path: 'preview/courses/:courseid/lectures/unpublished',
    component: CourseLearnComponent,
    canActivate: [AuthGuard],
    data: {
      isFromInstructor: true,
      isPublished: false,
    },
  },

  {
    path: 'preview/courses/:courseid/lectures/:sectionid/:subsectionid/:lectureid/quiz/:resultid/published',
    component: QuestionComponent,
    data: {
      isFromInstructor: true,
      isPublished: true,
    },
  },
  {
    path: 'preview/courses/:courseid/lectures/:sectionid/:subsectionid/:lectureid/quiz/:resultid/unpublished',
    component: QuestionComponent,
    data: {
      isFromInstructor: true,
      isPublished: false,
    },
  },
  {
    path: 'preview/courses/:courseid/lectures/:sectionid/:subsectionid/:lectureid/quiz/:resultid/unpublished',
    component: QuestionComponent,
    data: {
      isFromInstructor: true,
      isPublished: false,
    },
  },
  {
    path: 'instructor/courses/:courseid/:tab/:unitid/:resultid',
    component: QuizPdfComponent,
    canActivate: [AuthGuard],
    data: {
      isFromInstructor: true,
      isPublished: false,
    },
  },
  // Student Route End------------------------------------------------------------------------------

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CurtomRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
