import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { FeedbackTextareaDataComponent } from '../feedback-textarea-data/feedback-textarea-data.component';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';

@Component({
  selector: 'app-feedback-data',
  templateUrl: './feedback-data.component.html',
  styleUrls: ['./feedback-data.component.scss'],
})
export class FeedbackDataComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;

  isLoading: boolean = false;
  isStudentLoading: boolean = false;
  isSubmitting: boolean = false;
  isGivingFeedback: boolean = false;
  isFocus: boolean = false;
  isViewingFeedback: boolean = false;

  courseid: string = '';
  role: string = '';
  coursename: string = '';
  totalfeedback: string = '';
  searchText: string = '';
  currentStudentData: any = '';
  currentStudentInfo: any = '';

  answerList: any[] = [];
  question: any[] = [];

  studentData: any[] = [];
  userid: any = "";
  studentTableData!: MatTableDataSource<any>;

  displayedColumns = ['userid', 'username', 'studentcode', 'studentname','action'];

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private san: DomSanitizer
  ) {

    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())
    ) {
      this.router.navigateByUrl('');
    }
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.courseid = params['courseid'];
    });
    this.getFeedbackForm();
  }

  goToFeedback() {
    this.router.navigateByUrl(`${this.role}/course/feedback`);
  }

  getFeedbackForm() {
    this.isLoading = true;
    this.studentData = [];
    this.studentTableData = new MatTableDataSource(this.studentData);
    this.courseService.getFeedbackAnalyticsDetails(this.courseid, '').subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.question = res.datalist;
          this.coursename = res.coursename;
          this.totalfeedback = res.totalfeedback;
          for (let i = 0; i < this.question.length; i++) {
            this.question[i].checkboxans = [];
            if (this.question[i].type != '003') {
              this.question[i].selected = '-1';
            } else {
              this.question[i].selected = '';
            }
          }
          this.studentData = res.studentlist;
          this.feedbackData = res.feedbackexport;
          this.studentTableData = new MatTableDataSource(this.studentData);
          setTimeout(() => {
            this.studentTableData.paginator = this.paginator;
            this.studentTableData.sort = this.sort;
          }, 100);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  changeRate(count: any, total: any) {
    return Math.floor(count / total * 100).toString()

  }

  viewAnswers(question: any) {
    const dialog = this.dialog.open(FeedbackTextareaDataComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        question: question,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
      }
    });
  }

  viewDetail(student: any) {
    this.isViewingFeedback = true;
    this.currentStudentInfo = student;
    this.userid = student.userid;
    this.getFeedbackFormByUser(student.userid);
  }

  backToListView() {
    this.isViewingFeedback = false;
    this.currentStudentData = '';
    this.currentStudentInfo = '';
    setTimeout(() => {
      this.studentTableData.paginator = this.paginator;
      this.studentTableData.sort = this.sort;
    }, 100);
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.studentTableData.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = '';
    this.studentTableData.filter = '';
  }

  feedbackData: any = [];
  exportLoading: boolean = false;


  getFeedbackFormByUser(userid: string) {
    this.isStudentLoading = true;
    this.courseService
      .getFeedbackAnalyticsDetails(this.courseid, userid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.currentStudentData = res.datalist;
            this.feedbackData = res.feedbackexport;
            console.log(this.currentStudentData);
            setTimeout(() => {
              this.isStudentLoading = false;
            }, 1000);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );

            this.isStudentLoading = false;
          }
        },
        (err) => {
          this.isStudentLoading = false;
        }
      );
  }



  async downloadSheet() {

    var startdate = formatDate(
      Date.now(),
      'dd/MM/yyyy',
      'en'
    );
    var enddate = formatDate(
      Date.now(),
      'dd/MM/yyyy',
      'en'
    );
    var data: any = [

    ];
    var name = '';
    name = this.coursename + ' Feedback Data - ' + ' ( ' + startdate + '-' + enddate + ' ).xlsx';
    console.log(this.feedbackData);
    // Userid UserName Question 1 Question 2
    for (let j = 0; j < this.feedbackData.length; j++) {
      data.push({
        "User ID": this.feedbackData[j]['userid'],
        "User Name": this.feedbackData[j]['username'],
      })
      for (let i = 0; i < this.feedbackData[j]['feedback'].length; i++) {
        if (data[j]['User ID'] == this.feedbackData[j]['userid']) {
          var type = data.map((x: any) => x['User ID']).indexOf(this.feedbackData[j]['userid']);
          console.log(data[type][this.feedbackData[j]['feedback'][i]['question']]);
          data[type][this.convertToPlain(this.feedbackData[j]['feedback'][i]['question'])] = this.feedbackData[j]['feedback'][i]['answer'] ? this.convertToPlain(this.feedbackData[j]['feedback'][i]['answer']) : ""
        }
      }
    }
    console.log(data);
    this.allinoneService.exportExcel(data, name);
  }


  convertToPlain(html: string) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  deleteFeedback(data:any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        message:
          'Are you sure?',
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.courseService
          .deleteFeedBack(data.courseid,data.userid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {

                // this.messageService.openSnackBar(
                //   'Deleted successfully.',
                //   'success'
                // );
                this.getFeedbackForm();
                this.loadingService.hide();
              } else {
                this.loadingService.hide();
                this.messageService.openSnackBar(
                  res.status || res.message || res.error,
                  'warn'
                );
                
              }
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }

    });

  }
}
