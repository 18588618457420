import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-instructor-report-dashboard',
  templateUrl: './instructor-report-dashboard.component.html',
  styleUrls: ['./instructor-report-dashboard.component.scss'],
})
export class InstructorReportDashboardComponent implements OnInit {
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss';
  apitimeFormat = 'hh:mm:ss aa';

  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);

  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  gettingCourses: boolean = false;
  gettingmyChannel: boolean = false;
  isLoading: boolean = false;

  channels: any[] = [];
  courses: any[] = [];
  userlist: any[] = [];
  displayedColumns = [
    'userid',
    'username',
    'employeeid',
    'department',
    'post',
    'timespent',
  ];

  tableData!: MatTableDataSource<any>;

  reportForm = {
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
    channelid: '',
    courseid: '',
    viewtype: 'chart',
  };

  public positionChartLabels: Label[] = [];
  public positionChartType: ChartType = 'line';
  public positionChartLegend = false;
  // public positionChartPlugins = [ChartDataLabels];
  public positionChartPlugins = [];

  public deptChartLabels: Label[] = [];
  public deptChartType: ChartType = 'horizontalBar';
  public deptChartLegend = false;
  // public deptChartPlugins = [ChartDataLabels];
  public deptChartPlugins = [];

  public positionChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Students',
          },
          ticks: {
            beginAtZero: true,
          },
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Post',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 10) {
                return value.toString().substr(0, 10) + '...';
              } else {
                return value.toString();
              }
            },
            maxRotation: 90,
            minRotation: 70,
            autoSkip: false,
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };
  public deptChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Students',
          },
          // ticks: {
          //   callback: function (value) {
          //     return value.toString().substr(0, 20) + '...';
          //   },
          // },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Departments',
          },
          ticks: {
            callback: function (value) {
              if (value.toString().length > 20) {
                return value.toString().substr(0, 20) + '...';
              } else {
                return value.toString();
              }
            },
          },
          // ticks: {
          //   beginAtZero: true,
          // },
        },
      ],
    },
  };

  public positionStuCount: ChartDataSets[] = [{
    data: [0], fill: true,
    borderColor: 'rgb(75, 192, 192)',
    backgroundColor: 'rgb(51, 145, 194, 0.3)',
  }];
  public deptStuCount: ChartDataSets[] = [{ data: [0] }];
  public barChartColors: Color[] = [{ borderColor: '#66bbbb' }];

  public deptChartColors: Color[] = [{ backgroundColor: '#66bbbb' }];

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.userlist);
    this.getMyChannel();
  }

  getMyChannel() {
    this.gettingmyChannel = true;
    const data = {
      type: '2',
    };
    this.courseService.getMychannel(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.channels = res.datalist;
          // find and select the private channel
          // otherwise set blank to channelid
          var temp = this.channels.find((item: any) => item.type == '002');
          this.reportForm.channelid = temp ? temp.channelid : 'blank';
          if (this.reportForm.channelid != 'blank') {
            this.getCourses();
          }
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
        this.gettingmyChannel = false;
      },
      (err) => {
        this.gettingmyChannel = false;
      }
    );
  }

  getCourses() {
    this.gettingCourses = true;
    this.courseService
      .getCoursesByChannel(this.reportForm.channelid, '', '002')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.courses = res.datalist;
            if (this.courses.length > 0) {
              this.reportForm.courseid = this.courses[0].courseid;
            }
            else {
              this.reportForm.courseid = '';
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingCourses = false;
        },
        (err) => {
          this.gettingCourses = false;
        }
      );
  }

  channelChange(event: any) {
    this.getCourses();
  }

  // get_random_hue_color() {
  //   let h = Math.floor(Math.random() * 361);
  //   let s =60;
  //   let l = 75;
  //   h /= 360;
  //   s /= 100;
  //   l /= 100;

  //   let r, g, b;
  //   if (s === 0) {
  //     r = g = b = l;
  //   } else {
  //     const hue2rgb = function (p: any, q: any, t: any) {
  //       if (t < 0) t += 1;
  //       if (t > 1) t -= 1;
  //       if (t < 1 / 6) return p + (q - p) * 6 * t;
  //       if (t < 1 / 2) return q;
  //       if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  //       return p;
  //     };
  //     const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  //     const p = 2 * l - q;
  //     r = hue2rgb(p, q, h + 1 / 3);
  //     g = hue2rgb(p, q, h);
  //     b = hue2rgb(p, q, h - 1 / 3);
  //   }
  //   const toHex = function (x: any) {
  //     const hex = Math.round(x * 255).toString(16);
  //     return hex.length === 1 ? '0' + hex : hex;
  //   };
  //   return '#' + toHex(r) + toHex(g) + toHex(b);
  // }
  //   get_random_hex_color() {
  //   let letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }
  courseChange() { }

  search() {
    if (this.reportForm.channelid == 'blank') {
      return this.messageService.openSnackBar('Please select a channel.', 'warn');
    }
    if (this.reportForm.courseid == '') {
      return this.messageService.openSnackBar("You don't have any course in this channel.", 'warn');
    }
    var startdate = formatDate(
      new Date(this.reportForm.startdate),
      'yyyyMMdd',
      'en'
    );
    var enddate = formatDate(
      new Date(this.reportForm.enddate),
      'yyyyMMdd',
      'en'
    );
    this.isLoading = true;
    this.positionChartLabels = [];
    this.deptChartLabels = [];
    this.positionStuCount[0].data = [];
    this.deptStuCount[0].data = [];
    this.userlist = [];
    this.tableData = new MatTableDataSource(this.userlist);
    this.courseService
      .getInstructorAnalytic(
        startdate,
        enddate,
        this.reportForm.channelid,
        this.reportForm.courseid
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            setTimeout(() => {
              var tempColorArray: any = []


              res.departmentdata.map((item: any) => {
                this.deptChartLabels.push(item.name);
                this.deptStuCount[0].data?.push(parseInt(item.count));
                // var randomColor=this.get_random_hue_color()
                // if(tempColorArray.length==0){
                // tempColorArray.push(randomColor);

                // }else{
                //   var res=tempColorArray.filter((x:any)=>this.isSimilar(this.getRGB(x), this.getRGB(randomColor)))
                //   if(res.length==0){
                //     tempColorArray.push(randomColor);
                //   }else{
                //     var randomColor=this.get_random_hue_color()

                //     tempColorArray.push(randomColor);


                //   }
                // 
                // }



              });
              console.log(tempColorArray)
              // this.deptChartColors[0].backgroundColor = tempColorArray;

            }, 500);
            res.positiondata.map((item: any) => {
              this.positionChartLabels.push(item.name);
              this.positionStuCount[0].data?.push(parseInt(item.count));
            });



            this.userlist = res.userlist;
            this.tableData = new MatTableDataSource(this.userlist);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  getRGB(color: any) {
    color = parseInt(color.substring(1), 16);
    var r = color >> 16;
    var g = (color - (r << 16)) >> 8;
    var b = color - (r << 16) - (g << 8);
    return [r, g, b];
  }
  isSimilar([r1, g1, b1]: any, [r2, g2, b2]: any) {
    console.log(Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2));
    return Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2) < 50;
  }


  downloadSheet() {
    if (this.userlist.length == 0) {
      this.messageService.openSnackBar('No data to export.', 'warn');
      return;
    }
    var startdate = formatDate(
      new Date(this.reportForm.startdate),
      'dd/MM/yyyy',
      'en'
    );
    var enddate = formatDate(
      new Date(this.reportForm.enddate),
      'dd/MM/yyyy',
      'en'
    );
    var exportdata: any = [];
    var name = '';

    var coursename = this.courses.find(
      (x: any) => x.courseid == this.reportForm.courseid
    ).coursename;
    name =
      'Reports - ' + coursename + ' ( ' + startdate + '-' + enddate + ' ).csv';
    this.userlist.map((student: any) => {
      exportdata.push({
        'User ID': student.userid,
        'User Name': student.username,
        'Employee ID': student.employeeid,
        Department: student.department,
        Position: student.typename,
        'Time Spent': student.totaltime,
      });
    });
    this.allinoneService.exportCSV(exportdata, name);
  }

  sortData(sort: Sort) {
    const data = this.userlist;
    if (!sort.active || sort.direction === '') {
      this.userlist = data;
      return;
    }

    this.userlist = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'department':
          return this.allinoneService.compare(
            parseInt(a.department),
            parseInt(b.department),
            isAsc
          );
        case 'post':
          return this.allinoneService.compare(
            parseInt(a.typename),
            parseInt(b.typename),
            isAsc
          );
        case 'timespent':
          return this.allinoneService.compare(
            a.totalsecond,
            b.totalsecond,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.tableData = new MatTableDataSource(this.userlist);
  }
}
