<!-- <button (click)="test()">test</button> -->
<div class="d-flex align-items-center justify-content-between mb-4">
    <div>
        <h2 class="mb-0">Duplicate Course</h2>
    </div>
    <div class="d-flex align-items-center">
        <mat-icon (click)="dismiss()" class="cus-point">close</mat-icon>
    </div>
</div>

<div class="row">
    <div class="mb-3 col-md-12">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control form-control-sm" aria-label="Number" matInput class="form-control"
            id="name" name="name" [(ngModel)]="submitForm.name"
            [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" #name="ngModel" required>

        <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
            <div *ngIf="name.errors?.required && formSubmitted">
                {{allinoneService.input_err_msg}}
            </div>
        </div>

    </div>
    <div class="mb-3 col-md-12">

        <label for="name" class="form-label">Channel</label>
  
        <!-- [ngClass]="{ 'is-invalid' :formSubmitted &&  chl.value == 'choose'  }" -->
          <select class="form-select" [(ngModel)]="submitForm.channelid" required #chl="ngModel"
             [disabled]="gettingmyChannel">
            <!-- <option value="choose">Choose a channel</option> -->
            <option [value]="chl.channelid" *ngFor="let chl of channel"
              [selected]="chl.channelid == submitForm.channelid">{{chl.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingmyChannel"></mat-progress-bar>
          <!-- <div class="invalid-feedback" *ngIf="chl.value == 'choose' && formSubmitted">
            {{allinoneService.input_err_msg}}
          </div> -->
        
      </div>
      <div class="mb-3 col-md-12">
        <label for="duplicatefrom" class="form-label">Duplicate From</label>
        <input type="text" class="form-control form-control-sm" aria-label="Number" matInput class="form-control"
            id="duplicatefrom" name="duplicatefrom" [(ngModel)]="submitForm.duplicatefrom" disabled
            >
    </div>
</div>

<div class="actions-button">

    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled]="isLoading">
        Cancel
    </button>

    <app-button-loader [button_text]="'Confirm'" [isStillupload]="isLoading" (click)="submit()">
    </app-button-loader>

</div>