<section *ngIf="!verified">
    <div class="signup-wrapper otp-wrapper">
        <div class="mb-2">
            <!-- <img src="assets/images/khub.png" alt="" class="navbar-brand-logo"> -->
            <h3 style="font-weight: 600;" class="mb-4">Verification
                <span style="    font-size: 0.7rem;
                opacity: 0.8;">( {{allinOneService.getKunyekUserId()}} )</span></h3>
            <span>Enter verification code</span>
        </div>
        <ng-otp-input class="mb-1" (inputStyles)="{'width': '20px'}" #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config">
        </ng-otp-input>
        <div class="d-flex align-items-center">
            <strong class="text-danger my-3" (click)="resend()">Resend Code?</strong>
            <mat-spinner class="mx-3" [strokeWidth]="3" [diameter]="33" *ngIf="isStillResend"></mat-spinner>


        </div>
        <app-button-loader (click)="verify()" [isStillupload]="isStillVerifying" [button_text]="'Next'" [width]="'100'" [margin_left]="'0'">
        </app-button-loader>
    </div>
</section>


<section *ngIf="verified" style="height: 80vh;">
    <div class="signup-wrapper">
        <div class="mb-2  verified-wrapper">
            <img src="assets/images/illotv_usercheck.webp" alt="" class="navbar-brand-logo">
            <h3 style="font-weight: 600;" class="mb-4">You have successfully verified account.</h3>
        </div>
    </div>
</section>