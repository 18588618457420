import { InfoLibraryComponent } from './../info-library/info-library.component';
import { ShareLibraryComponent } from './../share-library/share-library.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { LibraryNewFolderComponent } from '../library-new-folder/library-new-folder.component';
import * as S3 from 'aws-sdk/clients/s3';
import { MessageService } from '../../shared/services/message.service';
import { AnyARecord } from 'dns';
import { LibraryConfirmBoxComponent } from '../library-confirm-box/library-confirm-box.component';
import { CourseService } from '../../shared/services/course.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { LoadingService } from '../../shared/services/loading.service';
import { DuplicateFilesComponent } from '../duplicate-files/duplicate-files.component';
import { Sort } from '@angular/material/sort';
import { UploadComponent } from '../upload/upload.component';
import { Library } from '../../shared/models/Library';

@Component({
  selector: 'app-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.scss'],
})
export class LibraryViewComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (this.uploading) {
      event.returnValue = 'Your progress is not completed yet! Leave anyway?';
      return event;
    }
  }

  @ViewChild('fileInput') fileInput: any;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;

  getFileSubscription!: Subscription;
  getSharedFileSubscription!: Subscription;

  datatoshow: any;
  file: any;
  displayedColumns: any;
  getAddedFileSubscription!: Subscription;
  selectedRow: any = '';

  sharedWithMe: boolean = false;
  isLoading: boolean = false;
  uploading: boolean = false;
  close: boolean = true;
  hide: boolean = false;
  ispopup: boolean = false;
  isattachment: boolean = false;
  isvideo: boolean = false;
  isaudio : boolean = false;
  isSearching: boolean = false;
  afterSearch: boolean = false;

  fileList: any[] = [];
  navBarList: any[] = [];
  repeatedFileList: any[] = [];
  uploadingFileList: any[] = [];
  datalist: Library[] = [];
  searchItem: string = '';
  isFocus: boolean = false;

  currentFilePath = '';
  activeFolderID = '';
  count: number = 0;

  menuTopLeftPosition = { x: '0', y: '0' };

  // videoFileType = ['mp4', 'mkv', 'mov', 'avi', 'flv', 'wmv', 'avchd', 'webm'];
  videoFileType = ['mp4'];

  constructor(
    private dialog: MatDialog,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<LibraryNewFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentFilePath = this.allinoneService.getUserId().toString() + '/';
    console.log(data);
    if (data.isattachment != null && data.isattachment != undefined) {
      this.isattachment = data.isattachment;
      this.isvideo = data.isvideo;
      this.ispopup = true;
      console.log(this.ispopup);
      if(data.isaudio != null && data.isaudio != undefined) {
        this.isaudio = data.isaudio
      }
    }
    console.log('Your audio');
    console.log(this.isaudio);
    
  }

  ngOnDestroy(): void {
    this.uploadingFileList.map((item: any) => {
      item.request.abort();
    });
  }

  ngOnInit(): void {
    this.navBarList.push({
      filepath: this.currentFilePath,
      filename: 'My Library',
    });
    this.displayedColumns = [
      'type',
      'filename',
      'fileid',
      'filetype',
      'filesize',
      'modifieddate',
    ];
    this.datatoshow = new MatTableDataSource([]);
    // this.addLibrary();
    this.getFiles();
  }

  addLibrary() {
    this.isLoading = true;
    this.courseService.addLibrary('', this.currentFilePath, '0').subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getFiles();
        } else {
          this.isLoading = false;
          this.messageService.openSnackBar(
            res.message || res.error || res.status,
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  libraryChange() {
    this.sharedWithMe = !this.sharedWithMe;
    this.currentFilePath = '';
    this.activeFolderID = '';
    this.navBarList = [];
    this.datalist = [];
    this.datatoshow = new MatTableDataSource(this.datalist);
    if (this.sharedWithMe) {
      this.navBarList.push({
        filepath: this.currentFilePath,
        filename: 'Shared With Me',
      });
      this.clearSearch();
    } else {
      this.currentFilePath = this.allinoneService.getUserId().toString() + '/';
      this.navBarList.push({
        filepath: this.currentFilePath,
        filename: 'My Library',
      });
      this.clearSearch();
    }
  }

  selectRow(event: any, item: any) {
    if (
      this.ispopup &&
      ((this.isattachment && item.filetype != 'folder') ||
        (!this.isattachment && !this.isvideo && !this.isaudio && item.filetype == 'pdf') ||
        (!this.isattachment && !this.isvideo && this.isaudio && item.filetype == 'mp3') ||
        (this.isvideo && this.videoFileType.includes(item.filetype)))
    ) {
      this.selectedRow = item;
      console.log('>>>12345');
      console.log(this.selectedRow);
    }
  }

  onRightClick(event: MouseEvent, item: any, index: any) {
    // if (item.filetype != 'folder') {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.matMenuTrigger.menuData = { item: item, index: index };
    this.matMenuTrigger.openMenu();
    // }
  }

  download(item: any) {
    window.open(item.downloadurl, '_blank');
  }
  delete(item: any, index: number) {
    console.log('1');
    console.log(item);
    console.log(index);
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // Delete
        this.courseService.deleteLibraryFile(item.id).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              if (this.datalist[index].filename == this.selectedRow.filename) {
                this.selectedRow = '';
              }
              this.datalist.splice(index, 1);
              this.datatoshow = new MatTableDataSource(this.datalist);
              this.loadingService.hide();

              // If the row is selected, remove select
              console.log('1234');
            } else if (res.returncode == '240') {
              this.loadingService.hide();
              this.messageService.openSnackBar(
                item.filename + ' is in use.',
                'warn'
              );
            } else {
              this.loadingService.hide();
              this.messageService.openSnackBar(
                res.message || res.status || res.error,
                'warn'
              );
            }
          },
          (err) => {
            this.loadingService.hide();
          }
        );
      }
    });
  }

  getFiles() {
    this.isLoading = true;
    this.datatoshow = new MatTableDataSource([]);
    this.selectedRow = '';
    this.getSharedFileSubscription &&
      this.getSharedFileSubscription.unsubscribe();
    this.getFileSubscription = this.courseService
      .getLibraryFile(this.currentFilePath, '')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.datalist = res.datalist;
            console.log(this.datalist);
            this.datatoshow = new MatTableDataSource(this.datalist);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.messageService.openSnackBar(
              res.message || res.error || res.status,
              'warn'
            );
          }
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  getSharedFiles() {
    this.isLoading = true;
    this.datatoshow = new MatTableDataSource([]);
    this.selectedRow = '';
    this.getFileSubscription && this.getFileSubscription.unsubscribe();
    this.getSharedFileSubscription = this.courseService
      .getSharedFile(this.currentFilePath, this.activeFolderID)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.datalist = res.datalist;
            this.datatoshow = new MatTableDataSource(this.datalist);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.messageService.openSnackBar(
              res.message || res.error || res.status,
              'warn'
            );
          }
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  goToFolder(event: any, data: any) {
    event.preventDefault();
    if (data.filetype == 'folder') {
      if (this.navBarList.length == 1 && this.sharedWithMe) {
        this.activeFolderID = data.shareid;
        this.currentFilePath =
          this.currentFilePath + data.ownersyskey + '/' + data.id + '/';
      } else {
        this.currentFilePath = this.currentFilePath + data.id + '/';
      }
      var temp2 = {
        filepath: this.currentFilePath,
        filename: data.filename,
      };
      this.navBarList.push(temp2);
      if (this.sharedWithMe) {
        this.getSharedFiles();
      } else {
        this.getFiles();
      }
    } else if (this.ispopup) {
      if (
        this.isattachment ||
        (!this.isattachment && !this.isaudio && data.filetype == 'pdf') ||
        (!this.isattachment && data.filetype != 'pdf' && !this.isvideo  && data.filetype == 'mp3') || 
        (!this.isattachment &&
          !this.isaudio &&
          this.isvideo &&
          this.videoFileType.includes(data.filetype))
      ) {
        this.selectedRow = data;
        this.confirmFileSelect();
      }
    }
  }

  navClick(index: number) {
    this.currentFilePath = this.navBarList[index].filepath;
    this.navBarList = this.navBarList.slice(0, index + 1);
    if (this.sharedWithMe) {
      if (index == 0) {
        this.activeFolderID = '';
      }
      this.getSharedFiles();
    } else {
      this.getFiles();
    }
  }

  newFolder(newfolder: boolean) {
    console.log('Here');
    this.uploading = true;
    let dialogRef = this.dialog.open(UploadComponent, {
      minWidth: '350px',
      width: '600px',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      data: {
        isFolder: newfolder,
        isEditing: false,
        currentFilePath: this.currentFilePath,
        file: {},
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: Library }) => {
        if (result) {
          if (result.success) {
            if (result.data.filetype == 'folder') {
              this.datalist.unshift(result.data);
            } else {
              this.datalist.push(result.data);
            }
            this.datatoshow = new MatTableDataSource(this.datalist);
          }
        }
        this.uploading = false;
      });
  }

  sortData(sort: Sort) {
    const data = this.datalist;
    if (!sort.active || sort.direction === '') {
      this.datalist = data;
      return;
    }
    this.datalist = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'filename':
          return this.allinoneService.compare(a.filename, b.filename, isAsc);
        case 'fileid':
          return this.allinoneService.compare(a.fileid, b.fileid, isAsc);
        case 'filetype':
          return this.allinoneService.compare(a.filetype, b.filetype, isAsc);
        case 'filesize':
          return this.allinoneService.compare(a.filesize, b.filesize, isAsc);
        case 'modifieddate':
          return this.allinoneService.compare(
            a.modifieddate,
            b.modifieddate,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.datatoshow = new MatTableDataSource(this.datalist);
  }

  cancelFileSelect() {
    this.dialogRef.close({ success: false, data: '' });
  }
  confirmFileSelect() {
    if (this.selectedRow) {
      this.selectedRow.filepath =
        'library/' + this.currentFilePath + this.selectedRow.id;
      console.log(this.selectedRow);
      this.dialogRef.close({
        success: true,
        data: this.selectedRow,
      });
      console.log('>...Lejfijefij');
      console.log(this.selectedRow);
    } else {
      this.messageService.openSnackBar('Please choose a file.', 'warn');
    }
  }

  clearSearch() {
    this.searchItem = '';
    this.afterSearch = false;
    this.isSearching = false;
    if (this.sharedWithMe) {
      this.getSharedFiles();
    } else {
      this.getFiles();
    }
  }

  search() {
    // searchLibraryFile

    if (this.sharedWithMe) {
      this.afterSearch = true;
      this.datatoshow.filter = this.searchItem.trim().toLowerCase();
    } else {
      this.isSearching = true;
      this.courseService.getLibraryFile('', this.searchItem).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (res.datalist.length > 0) {
              this.afterSearch = true;
              this.datalist = res.datalist;
              this.datatoshow = new MatTableDataSource(this.datalist);
            } else {
              this.messageService.openSnackBar('No result found.', 'warn');
            }
          } else {
          }
          this.isSearching = false;
        },
        (err) => {
          this.isSearching = false;
        }
      );
    }
  }

  share(data: any, index: any) {
    let dialogRef = this.dialog.open(ShareLibraryComponent, {
      minWidth: '350px',
      width: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      data: {
        datalist: data,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: Library }) => {
        if (result) {
          if (result.success) {
            console.log(result.data);

            this.datalist[index]['sharetype'] = '1';
            //  sharetype
          }
        }
      });
  }

  viewInfo(data: any, index: any) {
    let dialogRef = this.dialog.open(InfoLibraryComponent, {
      minWidth: '350px',
      width: '550px',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      data: {
        datalist: data,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: Library }) => {
        if (result) {
          if (result.success) {
          }
        }
      });
  }

  edit(data: any, index: any) {
    let dialogRef = this.dialog.open(UploadComponent, {
      minWidth: '350px',
      width: '600px',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      data: {
        isFolder: data.filetype == 'folder',
        isEditing: true,
        currentFilePath: this.currentFilePath,
        file: data,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: Library }) => {
        if (result) {
          if (result.success) {
            this.datalist[index]['filedescription'] =
              result.data.filedescription;
            this.datalist[index]['filename'] = result.data.filename;
            this.datalist[index]['fileid'] = result.data.fileid;
            this.datalist[index]['filepath'] = result.data.filepath;
            this.datalist[index]['filesize'] = result.data.filesize;
            this.datalist[index]['filetype'] = result.data.filetype;
            this.datalist[index]['uploadfilename'] = result.data.uploadfilename;
            this.datalist[index]['iconurl'] = result.data.iconurl;
            this.datalist[index]['downloadurl'] = result.data.downloadurl;

            this.datatoshow = new MatTableDataSource(this.datalist);
          }
        }
      });
  }
}
