import { Component, OnInit, ViewChild } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';
import { CourseService } from '../../shared/services/course.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotFormSubmitted: boolean = false;
  forgotForm = this.formBuilder.group({
    userid: ["", Validators.required,],
  });

  resetForm = this.formBuilder.group({
    password: ["", Validators.required,],
    co_password: ["", Validators.required,]
  });

  pageno: string = "1";
  checksite: string = "";

  showOtpComponent: boolean = true;
  otp: string = "";
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = { allowNumbersOnly: true, length: 6, isPasswordInput: false, disableAutoFocus: false, placeholder: "" };
  resetFormSubmitted: boolean = false;
  isStillUserCheckingForForgot: boolean = false;
  isStillVerifying: boolean = false;
  isStillResend: boolean = false;
  isStillReset: boolean = false;

  passwordIsValid: boolean = false;
  showPassInput: boolean = false;

  constructor(public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private courseService: CourseService,
    private router: Router,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pageno = params['pageno'];
      this.checksite = params['site'];
    });

    // check
    if (this.forgotForm.value.userid == '') {
      if (this.checksite.toString().includes("student")) {
        this.router.navigateByUrl('student/forgot-password/1');
      }
      else {
        this.router.navigateByUrl('instructor/forgot-password/1');
      }
    }
  }

  get forgotFormControl() { return this.forgotForm.controls; }

  async forgotPasswrodSubmit() {
    this.forgotFormSubmitted = true;
    if (this.forgotForm.invalid) {
      return;
    }

    this.isStillUserCheckingForForgot = true;
    this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.forgotForm.value.userid));
    const awaitCheckUser = await this.checkUser();
    if (awaitCheckUser != "err") {
      this.courseService.signIn(this.allinoneService.checkUserId(this.forgotForm.value.userid)).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.forgotForm.value.userid));

          sessionStorage.setItem("session", res.session);
          sessionStorage.setItem("existsuser", res.type);
          sessionStorage.setItem("kunyekusername", res.username);


          if (this.checksite.toString().includes("student")) {
            this.router.navigateByUrl('student/forgot-password/2');
          }
          else {
            this.router.navigateByUrl('instructor/forgot-password/2');
          }
        }
        else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.isStillUserCheckingForForgot = false;

      }, err => {
        this.isStillUserCheckingForForgot = false;
      })
    }
    else {
      this.forgotFormSubmitted = false;
    }
  }


  checkUser() {
    return new Promise(resolve => {
      this.courseService.checkUser().subscribe(async (checkres: any): Promise<any> => {
        if (checkres.returncode == "300") {
          //user does not exists
          if (checkres.status == 0) {
            this.messageService.openSnackBar('User is not found', 'warn');
            this.isStillUserCheckingForForgot = false;
            resolve("err");
          }
          else {

            if (this.checksite.toString().includes("student")) {
              resolve(checkres);
            }
            else {

              if (checkres.userrole == "0") {
                //student account
                this.messageService.openSnackBar('User is not found', 'warn');
                this.isStillUserCheckingForForgot = false;
                resolve("err");
              }
              else {
                resolve(checkres);
              }
            }

          }
        }
        else if (checkres.returncode == "210") {
          this.isStillUserCheckingForForgot = false;
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
          resolve("err");
        }

      }, err => {
        this.isStillUserCheckingForForgot = false;
        resolve("err");
      });
    })

  }

  onOtpChange(otp: any) {
    this.otp = otp;
  }
  // verify() {
  // }


  verify() {

    if (this.otp.length == 6) {
      this.isStillVerifying = true;
      const username = sessionStorage.getItem("kunyekusername") || "";
      this.courseService.verify(username, this.otp).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.allinoneService.setCookie("atoken", res.atoken);
          if (this.checksite.toString().includes("student")) {
            this.router.navigateByUrl('student/forgot-password/3');
          }
          else {
            this.router.navigateByUrl('instructor/forgot-password/3');
          }
        }
        else {
          if (res.returncode == "202") {
            this.messageService.openSnackBar('Invalid OTP. Please try again.', 'warn');
          }
          else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
        }
        this.isStillVerifying = false;

      }, err => {
        this.isStillVerifying = false;
      });
    }
  }

  resend() {
    if (!this.isStillResend) {
      this.isStillResend = true;
      const userid = this.allinoneService.getKunyekUserId();
      this.courseService.signIn(userid).subscribe((res: any) => {
        if (res.returncode == "300") {
          sessionStorage.setItem("session", res.session);
          this.isStillResend = false;
        }
        else {
          this.isStillResend = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      }, err => {
        this.isStillResend = false;
      })
    }
  }

  passwordValid(event: boolean) {
    this.passwordIsValid = event;
  }
  get resetFormControl() { return this.resetForm.controls; }

  resetPassword() {
    this.resetFormSubmitted = true;
    if (this.resetForm.invalid || !this.passwordIsValid) {
      if (!this.passwordIsValid) {
        this.showPassInput = true;
      }
      return;
    }
    else {
      if (this.resetForm.value.password != this.resetForm.value.co_password) {
        this.messageService.openSnackBar("Passwords do not match", 'warn');
      }
      else {
        this.isStillReset = true;
        this.courseService.forgotpassword(this.resetForm.value.password).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.allinoneService.logout();
          }
          else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
          this.resetFormSubmitted = false;
          this.isStillReset = false;
        }, err => {
          this.resetFormSubmitted = false;
          this.isStillReset = false;
        });
      }
    }


  }
}
