<h2>
  Overall Feedback for {{student.username || student.userid}}
</h2>

<div class="mb-3 form-group">
  <textarea class="form-control" rows="5" [(ngModel)]="student.feedback"></textarea>
</div>

<div class="actions-button">
  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isLoading">
    Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="save()">
  </app-button-loader>

</div>