import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-classview-qr',
  templateUrl: './classview-qr.component.html',
  styleUrls: ['./classview-qr.component.scss']
})
export class ClassviewQrComponent implements OnInit {

  element!: HTMLImageElement;
  
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  classID : string = '';
  className : string ='';

  test='https://www.youtube.com/watch?v=pBR01ndtids'

  expDayList = [1, 3, 5, 10]
  isLoading : boolean = false;

  qrUrl : string = '';

  submitform = {
    expday : 1
  }

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.classID = data.classid
    this.className = data.classname
  }

  ngOnInit(): void {
    this.getClassQr();
  }

  getClassQr() {
    this.isLoading = true;
    var data = {
      classid : this.classID,
      days : this.submitform.expday.toString()
    }
    this.courseService.getClassQr(data).subscribe(
      (res:any) => {
        console.log(res);
        
        if(res.returncode == '300') {
          // this.qrUrl = 'class://' + res.qrdata;
          this.qrUrl = res.url;
          
        } else {
          this.messageService.openSnackBar( res.message || res.status || res.error, 'warn');
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    )
  }

  expDayChange() {
    this.getClassQr();
  }

  // downloadQRCode() {
    
    
  //   const fileNameToDownload = this.className;
  //   const htmlstring =
  //     document.getElementsByClassName('bshadow')[0].children[0];

  //   const base64Img = this.getImgSrc(htmlstring);

  //   fetch(base64Img)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       // IE
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
  //       } else {
  //         // Chrome
  //         // add background color and some margin for the following image download

  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.download = fileNameToDownload;
  //         link.click();
          

  //       }
  //     });
  // }

  downloadQRCode() {
    const fileNameToDownload = this.className;
    const element = document.querySelector('.bshadow') as HTMLElement;
    html2canvas(element, { backgroundColor: '#ffffff'}).then(canvas => {
      canvas.toBlob((blob : any) => {
        saveAs(blob, fileNameToDownload);
      });
    });
  }

  getImgSrc(htmlString: any) {
    this.element = <HTMLImageElement>htmlString;
    return this.element.src;
  }

}
