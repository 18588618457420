import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
    isNormal: boolean = true;
    title?: string;
    message?: string;
    deleteFromDb: boolean = false; 
    isRootAdmin: boolean = false; 

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private loadingService: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            if (!data) {
                this.message = "Are you sure?";
            }
            else{
                this.isNormal = data.isNormal;
                this.isRootAdmin = data.adminrole === 'admin';
                this.message = data.message
            }
    }

    ngOnInit() {

    }


    onConfirm(): void {
        this.loadingService.show();
        this.dialogRef.close(
            this.isNormal ? true : { confirmed: true, deleteFromDb: this.deleteFromDb }
        );
    }

    onDismiss(): void {
        this.dialogRef.close(
            this.isNormal ? false : { confirmed: false }
        );
    }
}