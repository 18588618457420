import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Class } from '../../shared/models/Class';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.scss'],
})
export class CreateClassComponent implements OnInit {
  courseid: string = '';
  classid: string = '';

  isEditing: boolean = false;
  isLoading: boolean = false;
  classFormSubmitted: boolean = false;
  gettingGroups: boolean = false;

  groups: any[] = [];

  generalDatePickerConfig : any = this.allinoneService.datePickerConfig;

  classForm = this.formBuider.group({
    classcode: ['', Validators.required],
    classname: ['', Validators.required],
    status: ['001', Validators.required], // 001 - active, 002- inactive, 003- completed
    remark: [''],
    startdate: ['', Validators.required],
    group: [''],
    hidesections: [false]
  });

  currentdate!: any;

  get classFormControl() {
    return this.classForm.controls;
  }

  constructor(
    private dialogRef: MatDialogRef<CreateClassComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.courseid = data.courseid;
    this.isEditing = data.update;
    // this.currentdate = this.datePipe
    //   .transform(new Date(), 'yyyy-MM-dd')
    //   ?.toString();
    this.currentdate = new Date(Date.now());
    if (this.isEditing) {
      console.log(data);
      // const tempDate = data.class.startdate
      //   ? this.datePipe
      //       .transform(
      //         this.allinoneService.formatDateForDiscussionDatePipe(
      //           data.class.startdate
      //         ),
      //         'yyyy-MM-dd'
      //       )
      //       ?.toString()
      //   : '';
      const tempDate = data.class.startdate
        ? allinoneService.parseDateToShowInEdit(data.class.startdate)
        : '';
      this.classid = data.class.classid;
      this.classForm.setValue({
        classcode: data.class.classcode,
        classname: data.class.classname,
        remark: data.class.remark,
        status: data.class.classstatus || '001',
        startdate: tempDate,
        group: data.class.kunyekchannelid || '',
        hidesections: data.class.hidesections || false
      });
    } else {
      this.classForm.patchValue({
        startdate: this.currentdate,
      });
    }
  }

  ngOnInit(): void {
    // this.getGroups();
  }

  getGroups() {
    this.gettingGroups = true;
    this.classFormControl.group.disable();
    this.courseService.getKunyekChannels(this.courseid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.groups = res.datalist;
          if (this.isEditing) {
            const channel = this.groups.filter(
              (x: any) => x.channelid == this.classFormControl.group.value
            );
            if (channel.length == 0) {
              this.classForm.patchValue({
                group: '',
              });
            }
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        if (
          !this.isEditing ||
          (this.isEditing && this.classFormControl.group.value == '')
        ) {
          this.classFormControl.group.enable();
        }
        this.gettingGroups = false;
      },
      (err) => {
        // this.classFormControl.group.enable();
        this.gettingGroups = false;
      }
    );
  }

  sumbitClass() {
    this.classFormSubmitted = true;
    if (
      this.classFormControl.classcode.invalid ||
      this.classFormControl.classname.invalid
    ) {
      return;
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;

    const classcode = this.classFormControl.classcode.value.toString().trim();
    const classname = this.classFormControl.classname.value.toString().trim();
    const remark = this.classFormControl.remark.value.toString().trim();
    const status = this.classFormControl.status.value.toString().trim();
    const hidesections = this.classFormControl.hidesections.value;
    console.log(this.classFormControl.startdate.value.toString().trim());
    
    const startdate = this.datePipe
      .transform(
        this.classFormControl.startdate.value.toString().trim(),
        'yyyyMMdd'
      )
      ?.toString();
      // ***
      // open group field in create/update api
    // const kunyekchannelid = this.classFormControl.group.value.toString().trim();

    // console.log(startdate);
    // return;

    var fun = this.isEditing
      ? this.courseService.updateClass(
          this.classid,
          classcode,
          classname,
          remark,
          status,
          startdate!,
          hidesections
          // ***
          // kunyekchannelid
        )
      : this.courseService.createClass(
          classcode,
          classname,
          remark,
          status,
          this.courseid,
          startdate!,
          hidesections
          // ***
          // kunyekchannelid
        );

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // var tempClass: Class = {
          //   classid: this.isEditing ? this.classid : res.classid,
          //   classcode: classcode,
          //   classname: classname,
          //   remark: remark,
          //   classstatus: status,
          //   courseid: this.courseid,
          //   startdate: startdate!,
          //   // kunyekchannelid: kunyekchannelid,
          //   // kunyekchannelname: kunyekchannelid ? this.groups.find(
          //   //   (x: any) => x.channelid == kunyekchannelid
          //   // ).channelname : '',
          // };
          // ***
          var tempClass = {
            classid: this.isEditing ? this.classid : res.classid,
            classcode: classcode,
            classname: classname,
            remark: remark,
            classstatus: status,
            courseid: this.courseid,
            startdate: startdate!,
            kunyekchannelid: '',
            kunyekchannelname: '',
            // kunyekchannelid: kunyekchannelid,
            // kunyekchannelname: kunyekchannelid ? this.groups.find(
            //   (x: any) => x.channelid == kunyekchannelid
            // ).channelname : '',
            hidesections: hidesections,
          };
          this.isLoading = false;
          this.dialogRef.close({
            success: true,
            data: tempClass,
          });
        } else {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(res.message || res.error, 'warn');
        }
      },
      (err) => {
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  onDismiss(): void {
    this.dialogRef.close({ success: false, data: {} });
  }
}
