import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-feedback-course-view',
  templateUrl: './feedback-course-view.component.html',
  styleUrls: ['./feedback-course-view.component.scss'],
})
export class FeedbackCourseViewComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;

  gettingChannels: boolean = false;
  gettingCourses: boolean = false;
  isFocus: boolean = false;

  channelname: string = '';
  channelid: string = '';
  role: string = '';
  searchText: string = '';

  channels: any[] = [];
  displayedColumns: string[] = [
    'courseimage',
    'coursename',
    'instructorname',
    'feedbackcount',
  ];

  coursesData: any[] = [];
  coursesTableData!: MatTableDataSource<any>;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())
    ) {
      this.router.navigateByUrl('');
    }
  }

  ngOnInit(): void {
    console.log(this.allinoneService.selectedChannelid);
    this.getChannels();
  }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'admin') {
      this.channelname = 'KHub';
      this.courseService.channelGet().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist.filter(
              (channel: any) => channel.status == '1'
            );
            var schannel = this.channels.find(
              (x: any) => x.channelid == this.allinoneService.selectedChannelid
            );
            this.channelid = schannel
              ? schannel.channelid
              : this.channels[0].channelid;
            this.getCourses();
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingChannels = false;
        },
        (err) => {
          this.gettingChannels = false;
        }
      );
    } else if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      console.log(schannel);
      this.channelid = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
      this.getCourses();
    } else {
      this.channels = this.allinoneService.supervisorChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.channelid = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
      this.getCourses();
    }
  }

  channelChange(event: any) {
    this.allinoneService.selectedChannelid = this.channelid;
    this.getCourses();
  }

  clear() {
    this.searchText = '';
    this.coursesTableData.filter = '';
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.coursesTableData.filter = searchItem.trim().toLowerCase();
  }

  refresh() {
    this.getCourses();
  }

  getCourses() {
    this.gettingCourses = true;
    this.coursesData = [];
    this.coursesTableData = new MatTableDataSource(this.coursesData);
    this.courseService.getFeedbackAnalytics(this.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.coursesData = res.datalist;
          this.coursesTableData = new MatTableDataSource(this.coursesData);
          setTimeout(() => {
            this.coursesTableData.paginator = this.paginator;
            this.coursesTableData.sort = this.sort;
          }, 100);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCourses = false;
      },
      (err) => {
        this.gettingCourses = false;
      }
    );
  }

  viewFeedback(course: any) {
    this.router.navigateByUrl(
      `${this.role}/course/feedback/${course.courseid}`
    );
  }

  
}
