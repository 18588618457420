import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { MatDialog } from '@angular/material/dialog';
import { ChannelQrViewComponent } from '../channel-qr-view/channel-qr-view.component';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements OnInit {
  displayedColumns: any;

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  gettingChannel: boolean = false;
  channels: any[] = [];
  showchannels !: MatTableDataSource<any>;
  edit: string = "editchannel";
  paginate: boolean = false;
  searchText:string="";
  role: string = "";
  isFocus: boolean = false;
  datacount:string = "0";
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    private _liveAnnouncer: LiveAnnouncer,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
    console.log(this.role);
  }

  ngOnInit(): void {
    this.displayedColumns = ['image', 'name', 'description', 'type', 'action'];
    this.getChannel();
  }

  getChannel() {
    this.channels = [];
    this.gettingChannel = true;
    this.showchannels =new MatTableDataSource();
    var fun = this.role == 'admin' ? this.courseService.channelGet(): this.courseService.getMychannel({type: '1'}) 
    fun.subscribe(
      (channel_data: any) => {
        if (channel_data.returncode == '300') {
          const dataSource = new MatTableDataSource(channel_data.datalist);
          this.channels = channel_data.datalist;
          this.datacount = channel_data.datalist.length;
          if(this.channels == undefined || this.channels.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }
          this.showchannels = new MatTableDataSource(channel_data.datalist);
          this.showchannels.sort = this.sort;
          this.showchannels.paginator = this.paginator;
          this.gettingChannel = false;
        } else {
          this.gettingChannel = false;
          if (channel_data.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.gettingChannel = false;
            this.messageService.openSnackBar(channel_data.status || channel_data.message, 'warn');
          }
        }
      },
      (err) => {
        this.gettingChannel = false;
      }
    );
  }

  openQR(data:any) {
    console.log(data);
    const dialog = this.dialog.open(ChannelQrViewComponent, {
      maxWidth: '95vw',
      width: '275px',
      // height: '565px',
      maxHeight: '95vh',
      // panelClass: ['mat-typography'],
      panelClass: 'my-panel-class',
      closeOnNavigation: true,
      autoFocus: false,
      data : {
        channelname : data.name,
        channelid : data.channelid
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if(dialogResult) {

      }
    })
  }

  editChannel(channelid: any) {
    this.router.navigateByUrl(`/${this.role}/channel/${this.edit}/${channelid}`)

  }
  newChannel() {
    this.router.navigateByUrl(`/admin/channel/create`);
  }
  gotoMember(channelid: any) {
    this.router.navigateByUrl(`/${this.role}/channel/${this.edit}/${channelid}/member`);
  }

  announceSortChange(sortState: Sort, data: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    console.log(data)
  }

  clear(){
    this.searchText = "";
    this.showchannels.filter = "";
  }
  
  searchItem(data :any){
    const searchItem = data.target.value;
  this.showchannels.filter = searchItem.trim().toLowerCase();

  }
  refresh(){
    this.gettingChannel = true;
    this.channels = [];
    if(this.channels == undefined || this.channels.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showchannels = new MatTableDataSource();
      this.getChannel();

  }
}
