<!-- <button (click)="test()" >test</button> -->
<div class="my-2">
    <div class="course-header mb-2 d-flex justify-content-between align-items-center">
        <div class="d-flex w-100 flex-wrap justify-content-between align-items-center">
            <div class="co-title">
                Assignments
            </div>
            <div class="d-flex flex-wrap align-items-center">
                <div class="me-2">
                  Total :<b> {{datacount}}</b>
                </div>
                <div class="input-group" style="width: 260px !important;">
                  <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                    (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                  <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                    *ngIf="searchText" (click)="clear()"
                    style="border: 1px solid var(--main-color);color: var(--main-color);">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
        </div>
    </div>
    <div style="overflow: auto;">
        <table mat-table #table [dataSource]="showAssignments" matSort (matSortChange)="sortData($event)" matSort
          class="mat-elevation-z1" style="width: 100%;">
  
          <ng-container matColumnDef="assignments">
  
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by assignments"
              style="padding-left:0px;">Assignment Name</th>
            <td class="t1" mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
              {{element.unitname ? element.unitname : 'Assignment'}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="duedate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by duedate">
              Due Date
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.deadlinedate ? allinoneService.formatDBToShow(element.deadlinedate) + ' ' + element.deadlinetime : '-'}} </td>
          </ng-container>

          <ng-container matColumnDef="action" style="width: 100px;">
            <th mat-header-cell *matHeaderCellDef style="text-align: right; padding-right: 40px;"> Action </th>
            <td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 10px;">
              
              <button class="btn btn-outline-primary btn-custom-rounded rounded-circle me-2" matTooltip="Edit Class" style="font-size: 14px;width:42px;height: 40px;" (click)="editAssignment(element)">
                <i class="fa fa-edit"></i>
            </button>

            <button class="btn btn-outline-primary btn-custom-rounded rounded-circle" matTooltip="Due Date for Individuals" style="font-size: 14px;width:42px;height: 40px;" (click)="open(element)">
                <i class="fa fa-info"></i>
            </button>
  
            </td>
          </ng-container>
  
          <!-- <ng-container matColumnDef="classcode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Class
            </th>
            <td mat-cell *matCellDef="let element" px-3>
              <span *ngIf="element.classid" class="badge bg-main">{{element.classcode}}</span>
              <span *ngIf="!element.classid" class = "addtoclass" (click) = "addToClass(element)">Add to class</span>
            </td>
          </ng-container>
  
  
          <ng-container matColumnDef="feedback">
            <th mat-header-cell *matHeaderCellDef> Feedback </th>
            <td mat-cell *matCellDef="let element" style="width: 500px;">
              <span *ngIf="element.feedback" class="cus-point feedback-data" (click)="feedback(element)">{{element.feedback}}</span>
              <span *ngIf="!element.feedback" class = "addtoclass" (click)="feedback(element)">Give feedback</span>
            </td>
          </ng-container> -->
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td colspan="5">
              <span class="nodata" *ngIf="!isLoading">Empty</span>
              <span class="nodata" *ngIf="isLoading">
                <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
              </span>
            </td>
          </tr>
        </table>
        <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
          [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
</div>