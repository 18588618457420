<div class="container-fluid my-3 card card-body px-5">
  <div class="question-container">
    <!-- No. of students - {{totalfeedback}} -->
    <div class="question-list mt-3" *ngFor="let ques of studentData;let i = index;">

      <ul class="questino-radio">

        <li class="question-li">
          <div class="d-flex w-100">
            <div>
              {{i+1}}.&nbsp;
            </div>
            <div class="d-flex flex-column w-100">
              <div class="d-flex justify-content-between w-100">
                <div>
                  <span [innerHtml]="ques.question">
                  </span>
                </div>
              </div>
              <div>

                <div class="questino-radio" *ngIf="ques.type=='001' || ques.type == '002'">
                  <span *ngFor="let ans of ques.answerlist;let j = index;" class="mb-3">
                    <div class="form-check mt-3">

                      <input *ngIf="ques.type=='001'" class="form-check-input" type="radio" [name]="'question'+i"
                        [id]="'question'+i+j" [checked]="ques.answerid.includes(ans.answerid.toString())" disabled readonly>
                      
                        <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox" [name]="'question'+i"
                        [id]="'question'+i+j" [checked]="ques.answerid.includes(ans.answerid.toString())"
                        disabled readonly>

                      <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                      <label class="w-100" [for]="'question'+i+j">
                        <span [innerHtml]="ans.answer"></span>
                      </label>

                    </div>
                  </span>
                </div>

                <div *ngIf="ques.type == '003'" class="mt-3 viewans">
                  <textarea style="max-width: 600px;" rows="5" [value]="ques.answerid" readonly disabled class="form-control">

                  </textarea>
                </div>
                <div class="rate" *ngIf="ques.type == '004'">
                  <input type="radio" [id]="'question' + i + '1'" [name]="'question' + i" value="5" disabled
                    [checked]="ques.answerid == '5'" />
                  <label [for]="'question' + i + '1'" title="text">
                    <mat-icon class="star-icon">star</mat-icon>
                  </label>
                  <input type="radio" [id]="'question' + i + '2'" [name]="'question' + i" value="4" disabled
                    [checked]="ques.answerid == '4'" />
                  <label [for]="'question' + i + '2'" title="text">
                    <mat-icon class="star-icon">star</mat-icon>
                  </label>
                  <input type="radio" [id]="'question' + i + '3'" [name]="'question' + i" value="3" disabled
                    [checked]="ques.answerid == '3'" />
                  <label [for]="'question' + i + '3'" title="text">
                    <mat-icon class="star-icon">star</mat-icon>
                  </label>
                  <input type="radio" [id]="'question' + i + '4'" [name]="'question' + i" value="2" disabled
                    [checked]="ques.answerid == '2'" />
                  <label [for]="'question' + i + '4'" title="text">
                    <mat-icon class="star-icon">star</mat-icon>
                  </label>
                  <input type="radio" [id]="'question' + i + '5'" [name]="'question' + i" value="1" disabled
                    [checked]="ques.answerid == '1'" />
                  <label [for]="'question' + i + '5'" title="text">
                    <mat-icon class="star-icon">star</mat-icon>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

    </div>
  </div>
</div>