import { CreateStudentComponent } from './../create-student/create-student.component';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { LoadingService } from '../../shared/services/loading.service';
import { HttpClient } from '@angular/common/http';
import { ThemePalette } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { GradeAssignmentComponent } from '../grade-assignment/grade-assignment.component';
import { FileUploadComponent } from '../../shared/components/file-upload/file-upload.component';
import * as saveAs from 'file-saver';
import { ClassSectionScheduleComponent } from '../class-section-schedule/class-section-schedule.component';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit, OnDestroy {
  // @Input() courseid!: string;
  classname: string = '';
  classcode: string = '';
  coursename: string = '';
  channelid: string = '';
  color: ThemePalette = 'accent';

  // @Input() classid!: string;
  @Output() back = new EventEmitter();

  goBackToClass() {
    this.back.emit();
  }

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('dbpaginator') dbpaginator!: MatPaginator;

  classV: boolean = false;
  filter: string = '';
  allStudent: any[] = [];

  scheduleList: any[] = [];

  studentPageView: boolean = false;

  classUrl: string = '';
  classid: string = '';
  courseid: string = '';
  updateClassSub!: Subscription;

  showstudents: MatTableDataSource<any> = new MatTableDataSource(
    this.allStudent
  );

  displayedColumns: any;
  dropboxDisplayedColumns: any;
  sectionsDisplayedColumns: any;
  studentSectionsDisplayedColumns: any;

  isLoading: boolean = false;
  isDropboxLoading: boolean = false;
  paginate: boolean = false;
  gettingmyChannel: boolean = false;
  datacount: string = '0';
  searchText: string = '';
  searchDropboxText: string = '';
  searchSectionText: string = '';
  isFocus: boolean = false;
  isDropboxFocus: boolean = false;
  isSectionFocus: boolean = false;

  alldropboxdata: any[] = [];
  dropboxtotalcount: any = 0;

  allsection: any[] = [];
  allunit: any[] = [];
  openedSections: string[] = [];
  units: any[] = [];
  gettingmyDropbox: boolean = false;
  openedall: boolean = false;
  selectedDropbox: string = 'all';

  selectedUnit: string = '';
  generalarray: any[] = [];

  showdropboxdata: MatTableDataSource<any> = new MatTableDataSource(
    this.alldropboxdata
  );
  gettingDropbox: boolean = false;

  showSections: MatTableDataSource<any> = new MatTableDataSource(
    this.allsection
  );

  downloadDropBoxUnit: boolean = false;

  isSchedule: boolean = false;
  isStart: boolean = false;
  isEnd: boolean = false;

  scheduleSD: string = '';
  scheduleST: string = '';
  scheduleED: string = '';
  scheduleET: string = '';

  // Excel import
  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;
  statusInterval: any;
  importData: any;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {

    this.activatedRoute.data.subscribe((data) =>
      this.studentPageView = data.isFromStudentsPage ? data.isFromStudentsPage : false,
    );

    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.classUrl = `/instructor/courses/${this.courseid}/class`;
    });

    const cid = this.route.snapshot.params['classid'];
    this.classid = cid;
  }

  ngOnInit() {
    this.displayedColumns = [
      'userid',
      'username',
      'studentcode',
      'studentname',
      'remark',
      'action',
    ];
    this.sectionsDisplayedColumns = ['sectionname', 'schedule', 'action'];
    this.studentSectionsDisplayedColumns = ['sectionname', 'action'];

    this.dropboxDisplayedColumns = [
      'userid',
      'studentid',
      'studentname',
      'unitname',
      'title',
      'grade',
      'feedback',
      'datetime',
      // 'action',
    ];

    // this.getSection();
    if (this.studentPageView) {
      this.getStudentSection();
    }
    else {
      this.getAllStudents();
      this.getAllDropBoxData();
      this.getSection();
      this.getImportStatus();
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    }

  }

  ngOnDestroy(): void {
    clearInterval(this.statusInterval);
  }

  openScheduleForAll() {

    let sdialog = this.dialog.open(ClassSectionScheduleComponent, {
      minWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        type: 'all',
        sectionid: '',
        classid: this.classid,
        courseid: this.courseid,
        sd: this.scheduleSD,
        st: this.scheduleST,
        ed: this.scheduleED,
        et: this.scheduleET
      },
    });

    sdialog
      .afterClosed().subscribe((dialogResult: any) => {
        console.log('DR');
        console.log(dialogResult);
        if (dialogResult) {
          this.isSchedule = false;
          this.isStart = false;
          this.isEnd = false;

          this.scheduleSD = dialogResult.startdate;
          this.scheduleED = dialogResult.enddate;
          this.scheduleST = dialogResult.starttime;
          this.scheduleET = dialogResult.endtime;
          if (this.scheduleSD || this.scheduleED) {
            this.isSchedule = true;
          }
          if (this.scheduleSD) {
            this.isStart = true;
          }
          if (this.scheduleED) {
            this.isEnd = true;
          }
        }
      })
  }

  openSchedule(data: any) {

    let sdialog = this.dialog.open(ClassSectionScheduleComponent, {
      minWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        type: 'single',
        sectionid: data.sectionid,
        classid: this.classid,
        courseid: this.courseid,
        sd: data.startdate,
        st: data.starttime,
        ed: data.enddate,
        et: data.endtime
        // sd: this.scheduleSD,
        // st: this.scheduleST,
        // ed: this.scheduleED,
        // et: this.scheduleET
      },
    });

    sdialog
      .afterClosed().subscribe((dialogResult: any) => {
        console.log('DR');
        console.log(dialogResult);
        if (dialogResult) {

          data.startdate = dialogResult.startdate;
          data.enddate = dialogResult.enddate;
          data.starttime = dialogResult.starttime;
          data.endtime = dialogResult.endtime;
        }
      })

    // sdialog
    //   .afterClosed().subscribe((dialogResult: any) => {
    //     console.log('DR');
    //     console.log(dialogResult);
    // if(dialogResult) {
    //   this.isSchedule = false;
    //   this.isStart = false;
    //   this.isEnd = false;

    //   this.scheduleSD = dialogResult.startdate;
    //   this.scheduleED = dialogResult.enddate;
    //   this.scheduleST = dialogResult.starttime;
    //   this.scheduleET = dialogResult.endtime; 
    //   if(this.scheduleSD || this.scheduleED) {
    //     this.isSchedule = true;
    //   }
    //   if(this.scheduleSD) {
    //     this.isStart = true;
    //   }
    //   if(this.scheduleED) {
    //     this.isEnd = true;
    //   }
    // }
    // })


  }

  downloadAssignment() {
    this.downloadDropBoxUnit = true;
    var data = {
      unitid: this.selectedUnit,
      courseid: this.courseid,
      classid: this.classid,
    }
    this.courseService.downloadAssignment(data).subscribe(
      async (res: any) => {
        console.log(res);

        if (res.returncode == '300') {
          if (res.url) {
            // window.open(res.url, '_blank');
            await this.downloadFile(res.url, res.filename)
          }
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || res.error,
            'warn'
          );
        }
        this.downloadDropBoxUnit = false;
      },
      (err: any) => {
        this.downloadDropBoxUnit = false;
      }
    )
  }

  test() {
    var testScheduleList = [
      {
        "11256061444180250505": {
          "endtime": "03:10 PM",
          "enddate": "20240208",
          "starttime": "03:05 PM",
          "startdate": "20240208"
        }
      },
      {
        "75393928629693962200": {
          "endtime": "03:10 PM",
          "enddate": "20240208",
          "starttime": "03:05 PM",
          "startdate": "20240208"
        }
      },
      {
        "11256061444180250505": {
          "endtime": "03:10 PM",
          "enddate": "20240208",
          "starttime": "03:08 PM",
          "startdate": "20240208"
        }
      }
    ]
    // for(var i = 0; i < testScheduleList.length; i++ ) {
    //   console.log(testScheduleList[i].startdate);
    //   // if(Object.keys(testScheduleList[i]) == '11256061444180250505') {

    //   // }
    // }
  }

  getStudentOpenedSections() {
    return new Promise((resolve) => {
      this.courseService.getStudentSections(this.courseid).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.openedSections = res.datalist;

            this.scheduleList = res.schedule

            resolve(true);
          } else {
            resolve(false);
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  getOpenedSections() {
    return new Promise((resolve) => {
      this.courseService.getClassSections(this.classid, this.courseid).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.openedSections = res.datalist;
            // res.startdate = '202020';
            // res.enddate = '';
            // res.starttime = '10:00 AM'
            // res.endtime = '11:00 PM'

            // if (res.startdate || res.enddate) {
            //   this.isSchedule = true;
            // }
            // if (res.startdate) {
            //   this.isStart = true;
            // }
            // if (res.enddate) {
            //   this.isEnd = true;
            // }
            // this.scheduleSD = res.startdate;
            // this.scheduleED = res.enddate;
            // this.scheduleST = res.starttime;
            // this.scheduleET = res.endtime;

            // *** test
            //   res.scheduleList = [
            //     {
            //     sectionid : '57617339025231537200',
            //     startdate : '20231201',
            //     enddate : '20231230',
            //     starttime : '02:35 PM',
            //     endtime : '02:40 PM'
            //   },
            //   {
            //     sectionid : '60412745492626063333',
            //     startdate : '20231201',
            //     enddate : '20231230',
            //     starttime : '01:00 PM',
            //     endtime : '02:00 PM'
            //   },
            // ]

            //   this.scheduleList = res.scheduleList;


            this.scheduleList = res.schedule


            resolve(true);
          } else if (res.returncode == "403") {
            this.messageService.openSnackBar(
              res?.message,
              'warn'
            );
            resolve(false);
            this.router.navigateByUrl('home');
          } else {
            resolve(false);
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  sortData(sort: Sort) {
    const data = this.allStudent;
    if (!sort.active || sort.direction === '') {
      this.allStudent = data;
      return;
    }

    this.allStudent = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        case 'studentcode':
          return this.compare(a.studentcode, b.studentcode, isAsc);
        case 'studentname':
          return this.compare(a.studentname, b.studentname, isAsc);
        case 'remark':
          return this.compare(a.remark, b.remark, isAsc);
        default:
          return 0;
      }
    });
    this.showstudents = new MatTableDataSource(this.allStudent);
    this.showstudents.sort = this.sort;
    this.showstudents.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getAllStudents() {
    this.allStudent = [];
    this.isLoading = true;
    this.courseService.getstudentclass(this.classid, this.courseid).subscribe(
      (students: any) => {
        if (students.returncode == '300') {
          this.allStudent = students.datalist;
          this.allStudent.map((item: any) => {
            item = {
              ...item,
              expanded: false,
              gotdropbox: false,
              gettingdropbox: false,
              submissions: [],
            };
          });
          this.datacount = this.allStudent.length.toString();
          this.classname = students.classname;
          this.classcode = students.classcode;
          this.coursename = students.coursename;
          this.channelid = students.channelid;
          this.showstudents = new MatTableDataSource(this.allStudent);
          if (this.allStudent == undefined || this.allStudent.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }
          this.showstudents.sort = this.sort;
          this.showstudents.paginator = this.paginator;
          this.isLoading = false;
        } else {
          if (students.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              students.status || students.message || students.error,
              'warn'
            );
          }
          this.isLoading = false;
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  clear() {
    this.searchText = '';
    this.showstudents.filter = '';
  }

  clearSectionSearch() {
    this.searchSectionText = '';
    this.showstudents.filter = '';
    this.showSections = new MatTableDataSource(this.allsection);
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showstudents.filter = searchItem.trim().toLowerCase();
  }

  searchSection(data: any) {
    const searchItem = data.target.value;
    this.showSections.filter = searchItem.trim().toLowerCase();
  }

  refresh() {
    this.isLoading = true;
    this.allStudent = [];
    this.datacount = '0';
    if (this.allStudent == undefined || this.allStudent.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showstudents = new MatTableDataSource();
    this.getAllStudents();
  }

  onChange(data: any) {
    const searchItem = data.target.value;
    if (data.target.value == 'all') {
      this.getAllStudents();
    } else {
      this.showstudents.filter = searchItem.trim().toLowerCase();
    }
  }

  InputStudent(value: any) {
    let dialogRef = this.dialog.open(CreateStudentComponent, {
      minWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        input: value,
        courseid: this.courseid,
        classid: this.classid,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: any }) => {
        if (result.success) {
          this.refresh();
        }
      });
  }

  NewStudents(students?: any) {
    let dialogRef = this.dialog.open(CreateStudentComponent, {
      minWidth: '500px',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        classid: this.classid,
        courseid: this.courseid,
        update: students ? true : false,
        student: students ? students : {},
        channelid: this.channelid
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: any }) => {
        if (result.success) {
          var tempStudent = result.data;
          if (students) {
            console.log('1');
            students.id = tempStudent.id;
            students.userid = tempStudent.userid;
            students.studentcode = tempStudent.studentcode;
            students.studentname = tempStudent.studentname;
            students.username = tempStudent.username;
            students.remark = tempStudent.remark;
          } else {
            console.log('2');
            this.allStudent.push({
              ...tempStudent,
              expanded: false,
              gotdropbox: false,
              gettingdropbox: false,
              submissions: [],
            });
            this.showstudents = new MatTableDataSource(this.allStudent);
            this.showstudents.paginator = this.paginator;
            this.datacount = this.allStudent.length.toString();
          }
        }
      });
  }

  deleteStudent(data: any, idx: any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result) {
        this.courseService.updateStudent(data.userid, data.id, 'true', '', '', '').subscribe(
          (classes: any) => {
            if (classes.returncode == '300') {
              this.loadingService.hide();
              var index = this.allStudent.findIndex(
                (x: any) => x.id == data.id
              );
              this.allStudent.splice(index, 1);
              this.showstudents = new MatTableDataSource(this.allStudent);
              this.showstudents.paginator = this.paginator;
              this.datacount = this.allStudent.length.toString();
            } else {
              this.loadingService.hide();
              if (classes.returncode == '210') {
                this.loadingService.hide();
                setTimeout(() => {
                  this.router.navigateByUrl('login');
                  this.allinoneService.logout();
                }, 2000);
              } else {
                this.loadingService.hide();
                this.messageService.openSnackBar(
                  classes.status || classes.message || classes.error,
                  'warn'
                );
              }
              this.loadingService.hide();
              this.isLoading = false;
            }
          },
          (err) => {
            this.loadingService.hide();
            this.isLoading = false;
          }
        );
      }
    });
  }

  downloadSheet() {
    const classname = this.classname;
    const classcode = this.classcode;
    var data: any = [];
    var name =
      classname + ' ' + '(' + classcode + ')' + ' ' + 'Students' + '.xlsx';
    this.allStudent.map((student: any) => {
      data.push({
        'User ID': student.userid,
        'Student ID': student.studentcode,
        'Student Name': student.studentname,
        Remark: student.remark,
      });
    });
    this.allinoneService.exportExcel(data, name);
  }

  goToStudent() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/students`);
  }

  goToCourse() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/class`);
  }
  goToClassList() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/classes`);
  }

  getSubmittedFiles(student: any) {
    student.submissions = [];
    student.gettingdropbox = true;
    this.courseService
      .getStudentDropboxList(this.courseid, this.classid, student.studentid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.datalist.map((item: any) => {
              student.submissions.push({
                id: item.classdbid,
                title: item.title,
                subTitle: '',
                mark: false,
                classdbid: item.classdbid,
                date: item.date,
                filename: item.filename,
                fileurl: item.fileurl,
                time: item.time,
                type: item.type,
                remark: item.remark,
                sectionid: item.sectionid,
              });
            });
            student.submissions.sort(
              (a: any, b: any) => b.date + b.time - (a.date + a.time) > -1
            );
            student.gotdropbox = true;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || res.error,
              'warn'
            );
          }
          student.gettingdropbox = false;
        },
        (err: any) => {
          student.gettingdropbox = false;
        }
      );
  }
  toggleRow(element: any) {
    element.expanded = !element.expanded;
    if (element.expanded) {
      if (!element.gotdropbox) {
        this.getSubmittedFiles(element);
      }
    }
  }

  getAllDropBoxData() {
    this.gettingDropbox = true;
    this.alldropboxdata = [];
    this.isDropboxLoading = true;
    this.courseService
      .getInstructorDropboxList(this.classid, this.courseid)
      .subscribe(
        (students: any) => {
          if (students.returncode == '300') {
            this.alldropboxdata = students.datalist;
            this.dropboxtotalcount = this.alldropboxdata.length;
            this.alldropboxdata = this.alldropboxdata.sort((a, b) => {
              return this.compare(a.date + a.time, b.date + b.time, false);
            });
            this.coursename = students.coursename;
            this.classname = students.classname;
            console.log(this.alldropboxdata);
            // this.datacount = this.alldropboxdata.length.toString();
            if (
              this.alldropboxdata == undefined ||
              this.alldropboxdata.length == 0
            ) {
              this.paginate = false;
            } else {
              this.paginate = true;
            }
            if (this.selectedUnit) {
              this.getDropbox();
            } else {
              this.showdropboxdata = new MatTableDataSource(this.alldropboxdata);
              this.showdropboxdata.sort = this.sort;
              this.showdropboxdata.paginator = this.dbpaginator;
            }
            this.isDropboxLoading = false;
            this.gettingDropbox = false;
          } else {
            if (students.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                students.status || students.message || students.error,
                'warn'
              );
            }
            this.isDropboxLoading = false;
            this.gettingDropbox = false;
          }
        },
        (err) => {
          this.isDropboxLoading = false;
          this.gettingDropbox = false;
        }
      );
  }

  // excel import
  importSheet(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isError: isError,
        errorData: this.importData,
        type: '002',
        classid: this.classid,
        courseid: this.courseid
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getImportStatus(true);
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  getImportStatus(firstTime: boolean = false) {
    this.refreshingImportStatus = true;
    var data = {
      type: '002',
      channelid: '',
      classid: this.classid
    };
    // 27*** change api
    this.courseService.getImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (firstTime && this.importStatus == '001') {
            this.refreshDropBox();
          }
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            this.refreshDropBox();
          }
          // if(this.importStatus == '001') {
          //   this.refreshDropBox();
          // }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  async getStudentSection() {
    this.gettingDropbox = true;
    this.allsection = [];
    this.showSections = new MatTableDataSource(this.allsection);
    const await_opened_sections = await this.getStudentOpenedSections();
    this.courseService.getSection(this.courseid).subscribe(
      (students: any) => {
        if (students.returncode == '300') {

          this.allsection = students.datalist;
          // this.allsection.map((d: any) => {

          // if (d.sectionid in this.scheduleList) {

          //   d = Object.assign(d, {
          //     startdate: this.scheduleList[d.sectionid].startdate, enddate: this.scheduleList[d.sectionid].enddate, starttime: this.scheduleList[d.sectionid].starttime, endtime: this.scheduleList[d.sectionid].endtime
          //   })

          // } else {
          //   d = Object.assign(d, {
          //     startdate: '', enddate: '', starttime: '', endtime: ''
          //   })
          // }
          // }
          // )

          console.log('enter');
          console.log(this.allsection);



          var count = 0;
          this.allsection.map((item: any, index: number) => {
            item = Object.assign(item, {
              no: index + 1,
            });
            if (this.openedSections.includes(item.sectionid)) {
              count++;
            }
          });
          if (count == this.allsection.length) {
            this.openedall = true;
          }
          this.showSections = new MatTableDataSource(this.allsection);

          // get all unit
          this.allsection.map((item: any) => {
            for (var i = 0; i < item.assignmentunits.length; i++) {
              this.allunit.push({
                unitname: item.assignmentunits[i].unitname,
                unitid: item.assignmentunits[i].unitid,
              })
            }
          })

          console.log('your all unit');
          console.log(this.allunit);


          this.gettingDropbox = false;

        } else {
          if (students.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              students.status || students.message || students.error,
              'warn'
            );
          }

          this.gettingDropbox = false;
        }
      },
      (err) => {
        this.gettingDropbox = false;
      }
    );
  }

  async getSection() {
    this.gettingDropbox = true;
    this.allsection = [];
    this.showSections = new MatTableDataSource(this.allsection);
    const await_opened_sections = await this.getOpenedSections();
    this.courseService.getSection(this.courseid).subscribe(
      (students: any) => {
        if (students.returncode == '300') {

          this.allsection = students.datalist;


          // res.scheduleList = [{
          //   sectionid : '57617339025231537200',
          //   startdate : '20231201',
          //   enddate : '20231230',
          //   starttime : '02:35 PM',
          //   endtime : '02:40 PM'
          // }]


          // this.allsection.map((d: any) => {
          //   for (var i = 0; i < this.scheduleList.length; i++) {
          //     if (d.sectionid == this.scheduleList[i].sectionid) {
          //       console.log('same con');

          //       d = Object.assign(d, {
          //         startdate: this.scheduleList[i].startdate,
          //         enddate: this.scheduleList[i].enddate,
          //         starttime: this.scheduleList[i].starttime,
          //         endtime: this.scheduleList[i].endtime
          //       })
          //     }
          //   }
          // }
          this.allsection.map((d: any) => {
            // for (var i = 0; i < this.scheduleList.length; i++) {
            //   if (d.sectionid == this.scheduleList[i]) {
            //     console.log('same con');

            //     d = Object.assign(d, {
            //       startdate: this.scheduleList[i].startdate,
            //       enddate: this.scheduleList[i].enddate,
            //       starttime: this.scheduleList[i].starttime,
            //       endtime: this.scheduleList[i].endtime
            //     })
            //   }
            // }

            if (d.sectionid in this.scheduleList) {

              // d = { ...d, startdate: this.scheduleList[d.sectionid].startdate, enddate: this.scheduleList[d.sectionid].enddate, starttime: this.scheduleList[d.sectionid].starttime, endtime: this.scheduleList[d.sectionid].endtime }

              d = Object.assign(d, {
                startdate: this.scheduleList[d.sectionid].startdate, enddate: this.scheduleList[d.sectionid].enddate, starttime: this.scheduleList[d.sectionid].starttime, endtime: this.scheduleList[d.sectionid].endtime
              })

            } else {
              // d = { ...d, startdate: '', enddate: '', starttime: '', endtime: '' }
              d = Object.assign(d, {
                startdate: '', enddate: '', starttime: '', endtime: ''
              })
            }
          }
          )

          console.log('enter');
          console.log(this.allsection);



          var count = 0;
          this.allsection.map((item: any, index: number) => {
            item = Object.assign(item, {
              no: index + 1,
            });
            if (this.openedSections.includes(item.sectionid)) {
              count++;
            }
          });
          if (count == this.allsection.length) {
            this.openedall = true;
          }
          this.showSections = new MatTableDataSource(this.allsection);

          // get all unit
          this.allsection.map((item: any) => {
            for (var i = 0; i < item.assignmentunits.length; i++) {
              this.allunit.push({
                unitname: item.assignmentunits[i].unitname,
                unitid: item.assignmentunits[i].unitid,
              })
            }
          })

          console.log('your all unit');
          console.log(this.allunit);


          this.gettingDropbox = false;

        } else {
          if (students.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              students.status || students.message || students.error,
              'warn'
            );
          }

          this.gettingDropbox = false;
        }
      },
      (err) => {
        this.gettingDropbox = false;
      }
    );
  }

  downloadFile(url: string, name: string) {
    // window.open(url, '_blank');

    return new Promise((resolve) => {
      this.courseService.downloadFile(url).subscribe((response: any) => {
        saveAs(response, name);
        resolve(true);
      }),
        (error: any) => {
          this.messageService.openSnackBar('Download failed.', 'warn');
          resolve(false);
        };
    })
  }

  clearDropBox() {
    this.searchDropboxText = '';
    this.showdropboxdata.filter = '';
  }
  searchItemDropBox(data: any) {
    const searchItem = data.target.value;
    this.showdropboxdata.filter = searchItem.trim().toLowerCase();
  }

  refreshDropBox() {
    this.isDropboxLoading = true;
    this.alldropboxdata = [];
    this.selectedDropbox = 'all';
    this.selectedUnit = '';
    // this.datacount = '0';
    this.dropboxtotalcount = 0;
    if (this.alldropboxdata == undefined || this.alldropboxdata.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showdropboxdata = new MatTableDataSource();
    this.getAllDropBoxData();
  }

  refreshDropBox2() {
    this.isDropboxLoading = true;
    this.alldropboxdata = [];
    this.selectedDropbox = 'all';
    // this.datacount = '0';
    this.dropboxtotalcount = 0;
    if (this.alldropboxdata == undefined || this.alldropboxdata.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showdropboxdata = new MatTableDataSource();
    this.getAllDropBoxData();
  }

  onChangeDropBox(data: any) {
    const searchItem = data.target.value;
    if (data.target.value == 'all') {
      this.getAllDropBoxData();
    } else {
      this.showdropboxdata.filter = searchItem.trim().toLowerCase();
    }
  }


  // getDropbox() {
  //   this.router.navigate([]);
  //   this.generalarray = [];
  //   if (this.selectedDropbox == 'general') {
  //     for (let i = 0; i < this.alldropboxdata.length; i++) {
  //       if (this.alldropboxdata[i].sectionid == '') {
  //         this.generalarray.push(this.alldropboxdata[i]);
  //       }
  //     }
  //   } else if (this.selectedDropbox == 'all') {
  //     // this.getAllDropBoxData();
  //     this.generalarray = this.alldropboxdata;
  //   } else {
  //     this.units =
  //       this.allsection.find((x: any) => x.sectionid == this.selectedDropbox)
  //         .assignmentunits || [];
  //     for (let i = 0; i < this.alldropboxdata.length; i++) {
  //       if (
  //         (this.alldropboxdata[i].sectionid == this.selectedDropbox &&
  //           this.selectedUnit == '') ||
  //         (this.alldropboxdata[i].sectionid == this.selectedDropbox &&
  //           this.selectedUnit &&
  //           this.selectedUnit == this.alldropboxdata[i].unitid)
  //       ) {
  //         this.generalarray.push(this.alldropboxdata[i]);
  //       }
  //     }
  //   }
  //   this.datacount = this.generalarray.length.toString();
  //   this.showdropboxdata = new MatTableDataSource(this.generalarray);
  //   this.showdropboxdata.sort = this.sort;
  //   this.showdropboxdata.paginator = this.dbpaginator;
  // }

  getDropbox() {
    this.router.navigate([]);
    this.generalarray = [];
    console.log(this.selectedUnit);
    if (this.selectedUnit == '') {
      this.generalarray = this.alldropboxdata;
      // this.refreshDropBox();
    } else {
      console.log('else con');
      for (let i = 0; i < this.alldropboxdata.length; i++) {
        if (this.alldropboxdata[i].unitid == this.selectedUnit) {
          this.generalarray.push(this.alldropboxdata[i]);
        }
      }
    }
    // this.datacount = this.generalarray.length.toString();
    this.dropboxtotalcount = this.generalarray.length.toString();
    this.showdropboxdata = new MatTableDataSource(this.generalarray);
    this.showdropboxdata.sort = this.sort;
    this.showdropboxdata.paginator = this.dbpaginator;
  }

  sortDataDropBox(sort: Sort) {
    const data = this.selectedUnit ? this.generalarray : this.alldropboxdata;

    if (!sort.active || sort.direction === '') {
      this.alldropboxdata = data;
      return;
    }
    this.alldropboxdata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.compare(a.userid, b.userid, isAsc);
        case 'studentid':
          return this.compare(a.sudentcode, b.studentcode, isAsc);
        case 'studentname':
          return this.compare(a.studentname, b.studentname, isAsc);
        case 'unitname':
          return this.compare(a.unitname, b.unitname, isAsc);
        case 'title':
          return this.compare(a.title, b.title, isAsc);
        case 'file':
          return this.compare(a.file, b.file, isAsc);
        case 'remark':
          return this.compare(a.remark, b.remark, isAsc);
        case 'grade':
          return this.compare(a.grade, b.grade, isAsc);
        case 'feedback':
          return this.compare(a.feedback, b.feedback, isAsc);
        case 'datetime':
          return this.compare(a.date + a.time, b.date + b.time, isAsc);
        default:
          return 0;
      }
    });
    this.showdropboxdata = new MatTableDataSource(this.alldropboxdata);
    this.showdropboxdata.sort = this.sort;
    this.showdropboxdata.paginator = this.dbpaginator;
  }

  sortSections(sort: Sort) {
    const data = this.allsection;
    if (!sort.active || sort.direction === '') {
      this.allsection = data;
      return;
    }
    this.allsection = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sectionname':
          return this.compare(a.no, b.no, isAsc);
        default:
          return 0;
      }
    });
    this.showSections = new MatTableDataSource(this.allsection);
    this.showSections.sort = this.sort;
    // this.showSections.paginator = this.paginator;
  }

  sectionsUpdate(sectionid: string) {
    var index = this.openedSections.findIndex((x: any) => x == sectionid);
    if (index > -1) {
      this.openedSections.splice(index, 1);
    } else {
      this.openedSections.push(sectionid);
    }
    this.openedall = this.openedSections.length == this.allsection.length;
    if (this.updateClassSub) {
      this.updateClassSub.unsubscribe();
    }
    // call api here

    var fun = this.studentPageView ? this.courseService.updateStudentSection(this.courseid, this.openedSections) : this.courseService.updateClassSection(this.classid, this.openedSections);
    // this.updateClassSub = this.courseService
    //   .updateClassSection(this.classid, this.openedSections)
    fun.subscribe(
      (res: any) => {
        // Success
        if (res.returncode == '300') {
          this.getSection();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || res.error,
            'warn'
          );
        }
      },
      (err) => { }
    );
  }

  openallsections() {
    this.openedall = true;
    this.openedSections = [];
    this.allsection.map((item: any) => {
      this.openedSections.push(item.sectionid);
    });
    var fun = this.studentPageView ? this.courseService.updateStudentSection(this.courseid, this.openedSections) : this.courseService.updateClassSection(this.classid, this.openedSections);
    // var fun = this.studentPageView ? :
    // this.updateClassSub = this.courseService
    //   .updateClassSection(this.classid, this.openedSections)
    this.updateClassSub = fun.subscribe(
      (res: any) => {
        // Success
        if (res.returncode == 300) {
          this.getSection();
        }
      },
      (err) => { }
    );
  }
  closeallsections() {
    this.openedall = false;
    this.openedSections = [];
    // this.updateClassSub = this.courseService
    //   .updateClassSection(this.classid, this.openedSections)
    var fun = this.studentPageView ? this.courseService.updateStudentSection(this.courseid, this.openedSections) : this.courseService.updateClassSection(this.classid, this.openedSections);
    this.updateClassSub = fun.subscribe(
      (res: any) => {
        // Success
        if (res.returncode == 300) {
          this.getSection();
        }
      },
      (err) => { }
    );
  }

  gradeAssignment(dropboxdata: any) {
    // if (dropboxdata.unitid) {
    let dialogRef = this.dialog.open(GradeAssignmentComponent, {
      width: '650px',
      maxWidth: '95vw',
      height: 'auto',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        assignment: dropboxdata,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { success: boolean; data: any }) => {
        if (result.success) {
          dropboxdata.grade = result.data.grade;
          dropboxdata.feedback = result.data.feedback;
        }
      });
    // }
  }
}
