<app-breadcrumb *ngIf="!isloading" [s_breadcrumb]="unit.coursename" [t_breadcrumb]="unit.unitname || 'Discussion'"
  (emit)="goBackToCourse()"></app-breadcrumb>
<div *ngIf="isloading" style="margin: auto;
width: 1rem; margin-top: 20rem;height: 1rem;">
  <div class="spinner-border" role="status" style="width: 3rem !important;height: 3rem !important;">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="mx-5" *ngIf="!isloading">
  <div class="d-flex flex-column justify-content-start">
    <div class="d-flex flex-column justify-content-start discussion-parent">
      <div class="p-2 ">
        <div class="px-3">
          <div class="instructor my-2">
            <div class="instructor-default-img" *ngIf="unit.profileimage == ''">
              {{unit.instructorname.charAt(0)}}
            </div>
            <img width="40" height="40" class="rounded-circle" *ngIf="unit.profileimage != ''"
              [src]="unit.profileimageurl" alt="">
            <div class="d-flex flex-column">
              <span class="instructor-name fw-bold mx-2">{{unit.instructorname}}<span
                  class="ms-2 badge bg-main">Instructor</span></span>
              <span class="institute mx-2">{{unit.institute}}</span>
            </div>
          </div>
          <div>

          </div>
        </div>
        <div>
          <p [innerHTML]="unit.content" class="p-3"></p>

        </div>

      </div>
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-start">

        <button mat-button color="primary" style="width: 100%;" (click)="firstreply = !firstreply">
          <i class="fa fa-comments-o me-2" aria-hidden="true"></i>Reply</button>
      </div>
    </div>
    <div *ngIf="firstreply" class="my-3">
      <angular-editor [(ngModel)]="firstreplyconent" [config]="config"></angular-editor>
      <div class="w-100 d-flex justify-content-end my-3">
        <button mat-flat-button color="primary" class="px-2" (click)="reply()" [disabled]="firstreplying">
          <span *ngIf="firstreplying">
            <div class="spinner-border spinner-border-sm" role="status" style="color: white !important;">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
          <span *ngIf="!firstreplying">
            Reply
          </span>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <div class="d-flex justify-content-between mt-2">
        <div>
          Replies
        </div>
        <div class="main-color">
          Sort by: Latest
        </div>
      </div>
      <div class="d-flex flex-column my-2">

        <div *ngIf="!gettingreply && replylist.length == 0" class="nodata">
          No reply yet.
        </div>
        <div *ngIf="replylist.length > 0">
          <ng-container *ngFor="let discussion of replylist">
            <ng-container *ngIf="!discussion.deleting; else loadingTemplate">
              <div class="reply-box d-flex flex-column">
                <div class="px-3 d-flex justify-content-between">
                  <div class="instructor my-2">
                    <div class="instructor-default-img" *ngIf="discussion.profileimage == ''">
                      {{discussion.username.charAt(0)}}
                    </div>
                    <img width="40" height="40" class="rounded-circle" *ngIf="discussion.profileimage != ''"
                      [src]="discussion.profileimage" alt="">
                    <div class="d-flex flex-column justify-content-center">
                      <span class="instructor-name fw-bold mx-2">{{discussion.username}}<span class="ms-2 badge bg-main"
                          *ngIf="discussion.instructor">Instructor</span></span>
                      <span class="institute mx-2" *ngIf="discussion.instructor">{{discussion.institute}}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      {{(discussion.date + ' '+ discussion.time) | timeAgo}}
                    </div>
                    <div class="line">
  
                    </div>
                    <div>
                      <i class="fa fa-trash-o text-danger cursor-pointer" aria-hidden="true" (click)="deleteDiscussion(discussion)"></i>
                    </div>
                  </div>
  
                </div>
                <div class="px-3 py-2">
                  <span [innerHTML]="discussion.message"></span>
                </div>
                <mat-divider></mat-divider>
  
                <div class="d-flex justify-content-end">
  
                  <button mat-button color="primary" *ngIf="discussion.totalreplies && discussion.totalreplies!= 0 "
                    (click)="openReplies(discussion)">
                    <i class="fa fa-comments-o me-2"></i>
                    {{discussion.totalreplies}} Replies</button>
  
                  <button mat-button color="primary" (click)="discussion.reply = !discussion.reply">
                    Reply</button>
  
  
                </div>
              </div>
              <div class="second-reply-box" *ngIf="discussion.reply">
                <angular-editor [(ngModel)]="discussion.replycontent" [config]="config"></angular-editor>
                <div class="w-100 d-flex justify-content-end my-3">
                  <button mat-flat-button color="primary" class="px-2" (click)="reply(discussion)"
                    [disabled]="discussion.replying">
                    <span *ngIf="discussion.replying">
                      <div class="spinner-border spinner-border-sm" role="status" style="color: white !important;">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </span>
                    <span *ngIf="!discussion.replying">
                      Reply
                    </span>
                  </button>
                </div>
                <mat-divider></mat-divider>
              </div>
              <div class="second-reply-box" *ngIf="discussion.open">
                <!-- <div *ngIf="!discussion.gettingreply && discussion.replylist.length == 0" class="nodata">
                  No reply yet
                </div> -->
                <div *ngIf="discussion.replylist.length > 0">
                  <ng-container *ngFor="let reply of discussion.replylist">
                    <div class="reply-box d-flex flex-column">
                      <div class="px-3 d-flex justify-content-between">
                        <div class="instructor my-2">
                          <div class="instructor-default-img" *ngIf="reply.profileimage == ''">
                            {{reply.username.charAt(0)}}
                          </div>
                          <img width="40" height="40" class="rounded-circle" *ngIf="reply.profileimage != ''"
                            [src]="reply.profileimage" alt="">
                          <div class="d-flex flex-column justify-content-center">
                            <span class="instructor-name fw-bold mx-2">{{reply.username}}<span class="ms-2 badge bg-main"
                                *ngIf="reply.instructor">Instructor</span></span>
                            <span class="institute mx-2" *ngIf="reply.instructor">{{reply.institute}}</span>
                          </div>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                          <div>
                            {{(reply.date +' '+reply.time) | timeAgo}}
                          </div>
                          <div class="line">
  
                          </div>
                          <div>
                            <i class="fa fa-trash-o text-danger cursor-pointer" aria-hidden="true" (click)="deleteDiscussion(discussion, reply)"></i>
                          </div>
                        </div>
                      </div>
                      <div class="px-3 py-2">
                        <span [innerHTML]="reply.message"></span>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div *ngIf="discussion.gettingreply" class="nodata">
                  <div class="spinner-border spinner-border-md main-color">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="view-more-replies"
                  *ngIf="discussion.replylist.length == discussion.currentcount && !discussion.gettingreply"
                  (click)="getDiscussionReplies(discussion, true)">
                  View more replies...
                </div>
              </div>
            </ng-container>

          </ng-container>
          <div class="view-more-replies" *ngIf="!gettingreply && replylist.length == firstreplycurrentcount"
            (click)="getDiscussion(true)">
            View more replies...
          </div>
        </div>
        <div *ngIf="gettingreply" class="nodata">
          <div class="spinner-border spinner-border-md main-color">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

      </div>
    </div>


  </div>
</div>

<ng-template #loadingTemplate>
  <div class="d-flex justify-content-center align-items-center" style="height: 10rem;">
    <div class="spinner-border" role="status" style="width: 3rem !important;height: 3rem !important;">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>