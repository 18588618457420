import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-student-class',
  templateUrl: './add-student-class.component.html',
  styleUrls: ['./add-student-class.component.scss'],
})
export class AddStudentClassComponent implements OnInit {
  courseid: string = '';

  classFormSubmitted: boolean = false;
  isLoading: boolean = false;
  gettingClass: boolean = false;

  classlist: any[] = [];

  studentForm = this.formBuider.group({
    adduser: ['', Validators.required],
    classid: ['', Validators.required],
    studentid: [''],
    studentname: [''],
    username: [''],
    remark: [''],
  });

  get classFormControl() {
    return this.studentForm.controls;
  }

  constructor(
    private dialogRef: MatDialogRef<AddStudentClassComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private http: HttpClient,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.courseid = data.courseid;

    this.studentForm.patchValue({
      adduser: data.student.userid,
      studentname: data.student.username,
    });
  }

  ngOnInit(): void {
    this.getAllClass();
  }

  getAllClass() {
    this.gettingClass = true;
    this.classFormControl.classid.disable();
    this.courseService.getAllClass(this.courseid).subscribe(
      (classes: any) => {
        if (classes.returncode == '300') {
          this.classlist = classes.datalist;
          this.gettingClass = false;
          this.classFormControl.classid.enable();
        } else {
          if (classes.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 1000);
          } else {
            this.messageService.openSnackBar(classes.status || classes.message || classes.error, 'warn');
          }
          this.gettingClass = false;
        }
      },
      (err) => {
        this.gettingClass = false;
      }
    );
  }

  onDismiss(): void {
    this.dialogRef.close({ success: false, classid: '' , classcode : ''});
  }

  sumbitForm() {
    this.classFormSubmitted = true;
    if (this.classFormControl.adduser.invalid || this.classFormControl.classid.invalid) {
      return;
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;

    const adduser = this.allinoneService
      .checkUserId(this.classFormControl.adduser.value.toString())
      .trim();

    const classid = this.classFormControl.classid.value.toString().trim();
    const studentcode = this.classFormControl.studentid.value.toString().trim();
    const studentname = this.classFormControl.studentname.value
      .toString()
      .trim();
    const remark = this.classFormControl.remark.value.toString().trim();
    this.courseService
      .createStudent(
        this.courseid,
        adduser,
        classid,
        studentcode,
        studentname,
        remark
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.isLoading = false;
            const classcode = this.classlist.find((x: any) => x.classid == classid)?.classcode || '';
            this.dialogRef.close({
              success: true,
              classid: classid,
              classcode: classcode,
            });
          } else {
            this.isLoading = false;
            this.dialogRef.disableClose = false;
            this.messageService.openSnackBar(
              res.message || res.error || res.status,
              'warn'
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        }
      );
  }
}
