<app-breadcrumb [s_breadcrumb]="'Analytics'"></app-breadcrumb>
<section class="content">
  <div class="d-flex align-items-center justify-content-between flex-wrap">
    <div class="d-flex align-items-center flex-wrap">
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="viewtype" id="chart" value="chart"
          [(ngModel)]="reportForm.viewtype" [checked]="reportForm.viewtype == 'chart'">
        <label class="form-check-label" for="chart">
          Chart
        </label>
      </div>
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="viewtype" id="table" value="table"
          [(ngModel)]="reportForm.viewtype" [checked]="reportForm.viewtype == 'table'">
        <label class="form-check-label" for="table">
          Table
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <ng-container *ngIf="reportForm.viewtype == 'table'">
        <button color="primary" class="me-2" mat-flat-button (click)="downloadSheet()">
          <!-- <img class="excel-icon" src="../../../assets/images/excel.png"> -->
          Export CSV
        </button>
        <div class="line">
        </div>
      </ng-container>
      <button mat-flat-button color="primary" (click)="search()">Search</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 mb-3">
      <label for="startdate" class="form-label">Start Date</label>
      <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="reportForm.startdate"
        required #startdate="ngModel" step="1">
    </div>
    <div class="col-md-3 mb-3">
      <label for="enddate" class="form-label">End Date</label>
      <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="reportForm.enddate" required
        #enddate="ngModel" step="1">

    </div>
    <div class="col-md-3 mb-3">
      <label for="channelid" class="form-label">Channel</label>

      <select class="form-select" [(ngModel)]="reportForm.channelid" required #channelid="ngModel"
        (ngModelChange)="channelChange($event)" [disabled]="gettingmyChannel">
        <option value="blank">-</option>
        <option *ngFor="let channel of channels" [value]="channel.channelid" [disabled]="channel.type != '002'">
          {{channel.name}}</option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingmyChannel"></mat-progress-bar>
    </div>
    <div class="col-md-3 mb-3">
      <label for="courseid" class="form-label">Course</label>

      <select class="form-select" [(ngModel)]="reportForm.courseid" #courseid="ngModel" id="courseid" name="courseid"
        (ngModelChange)="courseChange()" required [disabled]="gettingCourses">
        <option value="" *ngIf="courses.length == 0">-</option>
        <option *ngFor="let course of courses" [value]="course.courseid">{{course.coursename}}</option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingCourses"></mat-progress-bar>
    </div>
  </div>

  <!-- View as chart -->
  <div *ngIf="reportForm.viewtype == 'chart'" class="row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
      <div class="card card-body">
        <h4>Departments
        </h4>
        <canvas *ngIf="!isLoading" baseChart [datasets]="deptStuCount" [labels]="deptChartLabels"
          [options]="deptChartOptions" [colors]="deptChartColors" [plugins]="deptChartPlugins"
          [legend]="deptChartLegend" [chartType]="deptChartType">
        </canvas>
        <div class="nodata" *ngIf="isLoading">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
      <div class="card card-body">
        <h4>Posts</h4>
        <canvas *ngIf="!isLoading" baseChart [datasets]="positionStuCount" [labels]="positionChartLabels"
          [options]="positionChartOptions" [colors]="barChartColors" [plugins]="positionChartPlugins"
          [legend]="positionChartLegend" [chartType]="positionChartType">
        </canvas>

        <div class="nodata" *ngIf="isLoading">
          <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
        </div>
      </div>
    </div>
  </div>

  <!-- View as table -->
  <div *ngIf="reportForm.viewtype == 'table'">
    <table mat-table #table [dataSource]="tableData" matSort="categorysort" style="width: 100%;"
      (matSortChange)="sortData($event)">

      <!-- userid Column -->
      <ng-container matColumnDef="userid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="userid">User ID</th>
        <td mat-cell *matCellDef="let element">
          {{element.userid}}
        </td>
      </ng-container>

      <!-- userid Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="username">User Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.username}}
        </td>
      </ng-container>

      <!-- employeeid Column -->
      <ng-container matColumnDef="employeeid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="employeeid">Employee ID</th>
        <td mat-cell *matCellDef="let element">
          {{element.employeeid}}
        </td>
      </ng-container>

      <!-- department Column -->
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="department">Department</th>
        <td mat-cell *matCellDef="let element">
          {{element.department}}
        </td>
      </ng-container>

      <!-- post Column -->
      <ng-container matColumnDef="post">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="post">Post</th>
        <td mat-cell *matCellDef="let element">
          {{element.typename}}
        </td>
      </ng-container>


      <!-- Time Spent Column -->
      <ng-container matColumnDef="timespent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="timespent" class="center">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color);">
          {{element.totaltime}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="6">
          <span class="nodata" *ngIf="!isLoading">Empty</span>
          <span class="nodata" *ngIf="isLoading">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]" *ngIf="userlist.length > 0">
    </mat-paginator>
  </div>
</section>