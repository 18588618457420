import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-setting-dashboard',
  templateUrl: './setting-dashboard.component.html',
  styleUrls: ['./setting-dashboard.component.scss'],
})
export class SettingDashboardComponent implements OnInit {
  menus: any[] = [
    {
      name: 'Channels',
      icon: 'https://assets.khub.cloud/policyicons/channels.png',
      path: 'admin/channel'
    },
    {
      name: 'Certificates',
      icon: 'https://assets.khub.cloud/policyicons/certificates.png',
      path: 'admin/certificates'
    },
    {
      name: 'User Roles',
      icon: 'https://assets.khub.cloud/policyicons/userroles.png',
    },
    {
      name: 'Program',
      icon: 'https://assets.khub.cloud/policyicons/program.png',
    },
    {
      name: 'Schedule',
      icon: 'https://assets.khub.cloud/policyicons/schedule.png',
      path: 'admin/settings/schedule'
    },
    {
      name: 'Data Imports',
      icon: 'https://assets.khub.cloud/policyicons/dataimports.png',
    },
    {
      name: 'Report',
      icon: 'https://assets.khub.cloud/policyicons/report.png',
      path: 'admin/settings/report'
    },
  ];

  constructor(
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  goTo(menu: any){
    if(menu.path){
      this.router.navigateByUrl(menu.path);
    }
    else{
      this.messageService.openSnackBar('This feature is not available yet.', 'info')
    }
  }
}
