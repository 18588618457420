<app-breadcrumb [s_breadcrumb]="'Member Type'"></app-breadcrumb>

<div class="content">
    <div class="d-flex">
        <!-- *ngIf="role == 'admin'" -->
        <button (click)="newmember()" class="btn new-chl mt-3 mb-3 ms-0">
            <i class="fa fa-plus" aria-hidden="true"></i> New Member Type
        </button>
        <div class="mt-4 mb-3 me-2 ms-auto">
            Total :<b> {{datacount}}</b>
        </div>
        <div class="input-group mt-3 mb-3 me-2" style="width: 260px !important;">
            <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
            <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                *ngIf="searchText" (click)="clear()" style="border: 1px solid #00bcd4;color: #00bcd4;">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="form-group mt-3 mb-3 me-2" style="width: 260px !important">
            <select class="form-select" [(ngModel)]="selectedChannel" (ngModelChange)="channelChange()"
                matTooltip="Filter By Channel" [disabled]="gettingChannels">
                <option [value]="chl.channelid" *ngFor="let chl of channels">
                    {{ chl.name }}
                </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingChannels"></mat-progress-bar>
        </div>

        <button class="btn new-chl mt-3 mb-3 ms-2" matTooltip="Refresh" (click)="refresh()" type="button">
            <i class="fa fa-refresh"></i>
        </button>
    </div>
    <div style="overflow: auto;">
        <table mat-table #table [dataSource]="showmember" matSort
            (matSortChange)="announceSortChange($event, showmember)" matSort class="mat-elevation-z1"
            style="width: 100%;">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name
                </th>
                <td mat-cell *matCellDef="let element" (click)="editMember(element)">
                    {{element.name}} </td>
            </ng-container>

            <!-- Order Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by order">Description
                </th>
                <td mat-cell *matCellDef="let element" (click)="editMember(element)">
                    {{element.description}} </td>
            </ng-container>


            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
                <td mat-cell *matCellDef="let element" px-3 style="text-align: center;">
                    <button class="btn btn-outline-primary btn-custom-rounded" matTooltip="Edit Channel"
                        (click)="editMember(element)">
                        <i class="fa fa-edit"></i>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td colspan="4">
                    <span class="nodata" *ngIf="!gettingCategory">Empty</span>
                    <span class="nodata" *ngIf="gettingCategory">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                    </span>
                </td>
            </tr>
        </table>
        <!-- <div  *ngIf="paginate == true"> -->
        <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        <!-- </div> -->
    </div>
</div>