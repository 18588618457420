import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateScheduleComponent } from '../../schedules/update-schedule/update-schedule.component';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-update-prerequisites',
  templateUrl: './update-prerequisites.component.html',
  styleUrls: ['./update-prerequisites.component.scss'],
})
export class UpdatePrerequisitesComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  course: any;
  allcourses: any;

  isLoading: boolean = false;

  myControl = new FormControl('');

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<UpdateScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.course = JSON.parse(JSON.stringify(data.course));
    this.allcourses = JSON.parse(JSON.stringify(data.allcourses));
    const temp: any[] = [];
    this.allcourses.map((item: any) => {
      const find = this.course.prerequisites.find(
        (pitem: any) => pitem.courseid == item.courseid
      );
      if (!find) {
        temp.push(item);
      }
    });

    const rmCurrent = temp.findIndex((item: any) => item.courseid == this.course.courseid);
    if(rmCurrent > -1){
      temp.splice(rmCurrent, 1);
    }

    this.allcourses = temp;
  }

  ngOnInit() {}

  dismiss() {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }

  selectCourse(course: any) {
    const checkexist = this.allcourses.findIndex(
      (item: any) => item.courseid == course.courseid
    );
    this.allcourses.splice(checkexist, 1);
    const temp = JSON.parse(JSON.stringify(course));
    delete temp['prerequisites'];
    this.course.prerequisites.unshift(temp);
  }

  showList() {
    this.autocomplete.openPanel();
  }

  remove(index: number) {
    this.allcourses.push(this.course.prerequisites[index]);
    this.course.prerequisites.splice(index, 1);
  }

  save(){
    if(this.isLoading){
      return;
    }
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    let preqList: string[] =[];
    this.course.prerequisites.map((item: any) => {
      preqList.push(item.courseid);
    })
    this.courseService.addPrerequisiteCourses(this.course.courseid, preqList).subscribe((res: any) => {
      if(res.returncode == '300'){
        this.dialogRef.close({
          prerequisites: this.course.prerequisites
        })
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.dialogRef.disableClose = false;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dialogRef.disableClose = false;
    })
  }
}
