<nav class="navbar navbar-expand-lg sticky-top px-3  navbar-light">

  <div class="container-fluid" *ngIf="width > 768">

    <div>
      <a href="/">
        <img src="assets/images/new-khub.png" alt="" class="navbar-brand-logo">
      </a>
      <!-- <span class="type">Instructor</span> -->
    </div>

    <ng-container *ngIf="authenticate && !isLoading;else elseTemplate">
      <div class="d-flex align-items-center justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto d-flex list-group list-group-horizontal ">
          <!-- <li class="nav-item dropdown" *ngIf="isAdmin" class="nav-item ">
            <a class="nav-link  dropdown-toggle d-flex align-items-center">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABl0lEQVRIie2UsUoDQRCGv4hFLC0ECVgFtBUuD6CNnFY+gS8QWz1fQHwAsU8EC7WxslArxcZCmxRqawrBJIIgUTCeRTZkPSe52bgBCwcGlp3Z/9uZm1v4tz9qeWAHuAdegSfgCJgZJnQZaAKx4I9AEZjwDc3TrlCC2l4FJn2CtxXQjm/6BN86gCs+wb2+reQvGsERJVgl5mJa8LWD5pVP8IcDuOmQm2pV9N/4QSOorfjA4ZL7DrkqOyO92hPfUIBQAV4YBhhgqw90w0UoMwA89qGlHa40e7PWY0BgLlIGSmZdALKDAg6R21y0chaBFlCz4jWzF7rARoF5YK8HtEK7c1kDPe2RF5vYEimVz9FuVV0Q2BXyC6aqGHgH1oGc8cjsxSYnkIBTKbfuHJ5NnMsADROPBN3IxBoIgzgO3KVAO76SOFuyYjmzdwlcmHXOipeTU70KTAu3TVoLOFbkfSL/fj/2btBVey6IpbV6w8TqCK1+VoLXBOGA78MV4Thcg5r2dwr5xUPSz0I8PSCulqX7ZJboPpkBVqVfBqLlcLFlUpUAAAAASUVORK5CYII="
                width="20px" class="me-2" />
              Admin
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li>
                <a class="dropdown-item d-flex" routerLink="admin/students">
                  <img src="https://assets.khub.cloud/policyicons/student.png"
                    width="20px" class="me-2" />
                  Students
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex" routerLink="admin/instructor">
                  <img src="https://assets.khub.cloud/policyicons/instructors.png"
                    width="20px" class="me-2" />
                  Instructors
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex" routerLink="admin/analytics">
                  <img src="https://assets.khub.cloud/policyicons/analytic.png"
                    width="18px" class="me-2" />
                  Analytics
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex" routerLink="admin/settings">

                  <img src="https://assets.khub.cloud/policyicons/setting.png"
                    width="20px" class="me-2" />
                  Settings
                </a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item" *ngFor="let menu of policy_menus; let i = index">
            <a class="nav-link d-flex align-items-center" aria-current="page" [routerLink]="menu.path"
              *ngIf="!menu.children">
              <img [src]="menu.icon" width="16px" height="auto" class="me-2" />
              {{menu.name}}
            </a>
            <!-- <a class="nav-link  dropdown-toggle d-flex align-items-center" *ngIf="menu.children">
              <img [src]="menu.icon" width="16px" height="auto" class="me-2" [ngClass]="{'supervisor-icon' : menu.name == 'Supervisor'}"/>
              {{menu.name}}
            </a> -->

            <a class="nav-link d-flex align-items-center custom-dropdown" *ngIf="menu.children"
              [matMenuTriggerFor]="dropdown">
              <img [src]="menu.icon" width="16px" height="auto" class="me-2"
                [ngClass]="{'supervisor-icon' : menu.name == 'Supervisor'}" />
              {{menu.name}}
              <mat-icon>arrow_drop_down</mat-icon>
            </a>

            <mat-menu #dropdown="matMenu" class="mymenu">
              <ng-container *ngFor="let c_menu of menu.children">
                <div class="nav-item" mat-menu-item [routerLink]="c_menu.path" *ngIf="c_menu.children.length == 0">
                  <img [src]="c_menu.icon" width="16px" height="auto" class="me-2" />
                  {{c_menu.name}}
                </div>
                <div class="nav-item" mat-menu-item [matMenuTriggerFor]="subcat" *ngIf="c_menu.children.length > 0">
                  <img [src]="c_menu.icon" width="16px" height="auto" class="me-2" />
                  {{c_menu.name}}
                  <mat-menu #subcat="matMenu" class="mymenu">
                    <div class="nav-item" mat-menu-item [routerLink]="subcategory.path"
                      *ngFor="let subcategory of c_menu.children">
                      <img [src]="subcategory.icon" width="16px" height="auto" class="me-2" />
                      {{subcategory.name}}
                    </div>
                  </mat-menu>
                </div>





              </ng-container>

            </mat-menu>





            <!-- <ul class="dropdown-menu dropdown-menu-right" *ngIf="menu.children">
              <li class="nav-item" *ngFor="let c_menu of menu.children">
                <a class="dropdown-item d-flex align-items-center" [routerLink]="c_menu.path">
                  <img [src]="c_menu.icon" width="16px" height="auto" class="me-2" />
                  {{c_menu.name}}
                </a>
              </li>
            </ul> -->
          </li>

        </ul>
        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>Redial</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>Check voice mail</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
        </mat-menu> -->
        <div *ngIf="user">
          <div [ngClass]="{ 'img-profile': user.profileimage == ''}" [matMenuTriggerFor]="menu">
            <img class="profileimage" *ngIf="user.profileimage != ''" [src]="allinOneService.imgurl + user.profileimage"
              alt="">
            <span *ngIf="user.profileimage == ''"> {{ user.username.charAt(0)}}</span>
          </div>


          <mat-menu #menu="matMenu" class="profile-menu">
            <div class="img-wrapper" routerLink="instructor/profiles">
              <div [ngClass]="{ 'menu-img-profile': user.profileimage == ''}">
                <span *ngIf="user.profileimage == ''"> {{ user.username.charAt(0)}}</span>
                <img class="profileimage menu-img-profile" *ngIf="user.profileimage != ''"
                  [src]="allinOneService.imgurl + user.profileimage" alt="">

              </div>
              <div class="profile-name mx-2">
                <strong> {{user.username}} </strong>
                <span> {{user.userid}} </span>
                <span> Version {{version}} </span>
              </div>
            </div>
            <!-- <mat-divider></mat-divider> -->
            <div class="profile-footer">
              <div class="signout-btn" (click)="openapp()">
                <img src="assets/images/learning.png" alt="Instructor" class="profile-item-icon">
                <span class="profile-item-label">Student</span>
              </div>
              <div class="signout-btn" (click)="allinOneService.logout()">
                <img src="assets/images/signout.png" alt="Instructor" class="profile-item-icon">
                <span class="profile-item-label">Sign Out</span>
              </div>
            </div>

            <!-- <div class="d-flex align-items-center justify-content-between">
              <div>
                <button (click)="allinOneService.logout()" *ngIf="authenticate" mat-stroked-button color="primary"
                  class="mx-3">Sign Out</button>
              </div>
              <div style="color: gray;" class="me-2">
                Version 1.21
              </div>
            </div> -->
            <!-- <button mat-menu-item class="signout-btn" autofocus="false" (click)="allinOneService.logout()">Sign out</button> -->
          </mat-menu>
        </div>

        <div *ngIf="!user">
          <button (click)="allinOneService.logout()" *ngIf="authenticate" mat-stroked-button color="primary"
            class="mx-3">Logout</button>
        </div>

        <!-- <button *ngIf="!authenticate" mat-stroked-button color="primary" routerLink="login">Login</button>
            <button *ngIf="!authenticate" mat-flat-button color="primary" routerLink="login">SignUp</button> -->


      </div>
    </ng-container>

    <!-- Loading Skeleton -->
    <ng-template #elseTemplate>
      <div class="d-flex align-items-center" *ngIf="authenticate">
        <ngx-skeleton-loader style="width: auto;height: 10px;margin-right: 10px;"
          [theme]="{ height: '10px',width: '50px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '40px',width: '40px'}">
        </ngx-skeleton-loader>
      </div>
    </ng-template>

  </div>
  <div class="container-fluid" *ngIf="width <= 768">
    <div class="d-flex justify-content-between w-100">

      <div class="d-flex align-items-center menu-mb-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
      aria-controls="offcanvasExample">
          <mat-icon>
            menu
          </mat-icon>
      </div>

      

      <div *ngIf="user">
        <div [ngClass]="{ 'img-profile': user.profileimage == ''}" [matMenuTriggerFor]="menu">
          <img class="profileimage" *ngIf="user.profileimage != ''" [src]="allinOneService.imgurl + user.profileimage"
            alt="">
          <span *ngIf="user.profileimage == ''"> {{ user.username.charAt(0)}}</span>
        </div>


        <mat-menu #menu="matMenu" class="profile-menu">
          <div class="img-wrapper" routerLink="instructor/profiles">
            <div [ngClass]="{ 'menu-img-profile': user.profileimage == ''}">
              <span *ngIf="user.profileimage == ''"> {{ user.username.charAt(0)}}</span>
              <img class="profileimage menu-img-profile" *ngIf="user.profileimage != ''"
                [src]="allinOneService.imgurl + user.profileimage" alt="">

            </div>
            <div class="profile-name mx-2">
              <strong> {{user.username}} </strong>
              <span> {{user.userid}} </span>
              <span> Version {{version}} </span>
            </div>
          </div>
          <!-- <mat-divider></mat-divider> -->
          <div class="profile-footer">
            <div class="signout-btn" (click)="openapp()">
              <img src="assets/images/learning.png" alt="Instructor" class="profile-item-icon">
              <span class="profile-item-label">Student</span>
            </div>
            <div class="signout-btn" (click)="allinOneService.logout()">
              <img src="assets/images/signout.png" alt="Instructor" class="profile-item-icon">
              <span class="profile-item-label">Sign Out</span>
            </div>
          </div>

          <!-- <div class="d-flex align-items-center justify-content-between">
            <div>
              <button (click)="allinOneService.logout()" *ngIf="authenticate" mat-stroked-button color="primary"
                class="mx-3">Sign Out</button>
            </div>
            <div style="color: gray;" class="me-2">
              Version 1.21
            </div>
          </div> -->
          <!-- <button mat-menu-item class="signout-btn" autofocus="false" (click)="allinOneService.logout()">Sign out</button> -->
        </mat-menu>
      </div>

    </div>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header ">
        <div class="d-flex" style="align-items: center;">
          <div class="me-2">
          <a href="/">
            <img src="assets/images/new-khub.png" alt="" class="navbar-brand-logo">
          </a>
          <!-- <span class="type">Instructor</span> -->
        </div>
        <div  id="offcanvasExampleLabel" style="font-size: 1.5rem;">Knowledge Hub</div>
        </div>
        
        <div>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
      </div>
      <!-- <hr class="m-0 p-0"> -->

      <div class="offcanvas-body mt-3">
        <ul class="navbar-nav mr-auto d-flex flex-column list-group list-group-horizontal ">
          <div class="nav-item" *ngFor="let menu of policy_menus; let i = index">
            <div class="nav-div d-flex " aria-current="page" [routerLink]="menu.path"
              *ngIf="!menu.children" data-bs-dismiss="offcanvas" aria-label="Close">
              <!-- <img [src]="menu.icon" width="16px" height="auto" class="me-2" />
              {{menu.name}} -->

                <div class="d-flex align-items-center">
                  <img [src]="menu.icon" width="16px" height="auto" class="me-2"
                    [ngClass]="{'supervisor-icon' : menu.name == 'Supervisor'}" />
                  {{menu.name}}
                </div>
                
            </div>
            <div class="nav-div d-flex flex-column " *ngIf="menu.children" type="button"
              data-bs-toggle="collapse" [attr.data-bs-target]="'#id'+menu.syskey+'collapse'" aria-expanded="false"
              [attr.aria-controls]="'id'+menu.syskey+'collapse'">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <img [src]="menu.icon" width="16px" height="auto" class="me-2"
                    [ngClass]="{'supervisor-icon' : menu.name == 'Supervisor'}" />
                  {{menu.name}}
                </div>
                <div class="d-flex">
                  <mat-icon>keyboard_arrow_down</mat-icon>

                </div>
              </div>

              <div style="padding-left: 5px;margin-top: 10px;" class="collapse" [id]="'id'+menu.syskey+'collapse'">
                <ng-container *ngFor="let c_menu of menu.children">
                  <div class="c-item" [routerLink]="c_menu.path" *ngIf="c_menu.children.length == 0"
                  data-bs-dismiss="offcanvas" aria-label="Close">
                    <!-- <img [src]="c_menu.icon" width="16px" height="auto" class="me-2" /> -->
                    {{c_menu.name}}
                  </div>
                  <div class="c-item  d-flex flex-column "  *ngIf="c_menu.children.length > 0"  data-bs-toggle="collapse" [attr.data-bs-target]="'#child'+c_menu.syskey+'collapse'" aria-expanded="false"
                    [attr.aria-controls]="'child'+c_menu.syskey+'collapse'">
                    <div class="d-flex justify-content-between">

                    <div class="d-flex align-items-center">
                      <!-- <img [src]="menu.icon" width="16px" height="auto" class="me-2"
                        [ngClass]="{'supervisor-icon' : menu.name == 'Supervisor'}" /> -->
                      {{c_menu.name}}
                    </div>
                    <div class="d-flex">
                      <mat-icon>keyboard_arrow_down</mat-icon>
    
                    </div>
                  </div>

                    
                 
                  </div>
                  <div style="padding-left: 5px;margin-top: 10px;" class="collapse" [id]="'child'+c_menu.syskey+'collapse'">
                    <div class="cc-item" [routerLink]="c_c_menu.path"
                      *ngFor="let c_c_menu of c_menu.children" data-bs-dismiss="offcanvas" aria-label="Close">
                      <!-- <img [src]="c_c_menu.icon" width="16px" height="auto" class="me-2" /> -->
                      {{c_c_menu.name}}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>


            <!-- <mat-menu #dropdown="matMenu" class="mymenu">
              <ng-container *ngFor="let c_menu of menu.children">
                <div class="nav-item" mat-menu-item [routerLink]="c_menu.path" *ngIf="c_menu.children.length == 0">
                  <img [src]="c_menu.icon" width="16px" height="auto" class="me-2" />
                  {{c_menu.name}}
                </div>
                <div class="nav-item" mat-menu-item  [matMenuTriggerFor]="subcat"  *ngIf="c_menu.children.length > 0">
                  <img [src]="c_menu.icon" width="16px" height="auto" class="me-2" />
                  {{c_menu.name}}
                  <mat-menu #subcat="matMenu" class="mymenu">
                    <div class="nav-item" mat-menu-item [routerLink]="subcategory.path" *ngFor="let subcategory of c_menu.children">
                      <img [src]="subcategory.icon" width="16px" height="auto" class="me-2" />
                      {{subcategory.name}}
                    </div>
                  </mat-menu>
                </div>
              </ng-container>
            </mat-menu> -->
          </div>


        </ul>


      </div>
    </div>


  </div>
</nav>