import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { SEOService } from '../../shared/services/seo.service';
import { ReportFilterComponent } from '../report-filter/report-filter.component';

@Component({
  selector: 'app-report-by-category',
  templateUrl: './report-by-category.component.html',
  styleUrls: ['./report-by-category.component.scss'],
})
export class ReportByCategoryComponent implements OnInit {
  @ViewChild('categoryPaginator') categoryPaginator!: MatPaginator;
  @ViewChild('coursePaginator') coursePagniator!: MatPaginator;
  @ViewChild('classPaginator') classPagniator!: MatPaginator;

  dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

  role: string = 'supervisor';
  tasktype = 'project';
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss';
  apitimeFormat = 'hh:mm:ss aa';

  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);
  checkInStartDate =
    this.aweekBeforeDate.getFullYear() +
    (this.aweekBeforeDate.getMonth() + 1).toString().padStart(2, '0') +
    this.aweekBeforeDate.getDate().toString().padStart(2, '0') +
    '';
  checkInEndDate = this.d.getFullYear() + this.month + this.day + '';
  //show today date
  showTodayDate =
    this.day +
    '/' +
    this.month +
    '/' +
    this.d.getFullYear() +
    ' ' +
    this.dayNames[this.d.getDay()];

  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
  atFormStartTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  atFormEndTime = formatDate(
    new Date(this.d).setHours(new Date(this.d).getHours() + 1),
    this.timeFormat,
    'en-US'
  ).toString();

  channelname: string = '';
  reportForm = {
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
    channelid: '',
    categoryid: '',
    categoryname: '',
    courseid: '',
    coursename: '',
    searchtype: 'category',
    branches: [],
    divisions: [],
    posts: [],
    postsname: [],
    departments: [],
    coursetypes: [],
    coursetypesname: [],
    classsearchtype: 'category',
    classcourses: [],
    classdaterange: false,
  };

  channels: any[] = [];
  categories: any[] = [];
  courses: any[] = [];

  categoryReportData: any[] = [];
  courseReportData: any[] = [];
  classReportData: any[] = [];

  categoryReportTableData!: MatTableDataSource<any>;
  courseReportTableData!: MatTableDataSource<any>;
  classReportTableData!: MatTableDataSource<any>;

  gettingChannels: boolean = false;
  gettingCategories: boolean = false;
  gettingCourses: boolean = false;
  formSubmitted: boolean = false;
  gettingCategoryReport: boolean = false;
  gettingCourseReport: boolean = false;
  gettingClassReport: boolean = false;

  categoryDisplayedColumns = [
    'coursename',
    'coursetype',
    'studentcounts',
    'totaltime',
  ];
  courseDisplayedColumns = [
    'userid',
    'username',
    'post',
    'department',
    'attendance',
    'mandays',
    'totaltime',
  ];
  classDisplayedColumns = [
    'coursename',
    'coursetype',
    'studentcounts',
    'totalclasses',
    'classes',
  ];

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _SEOService: SEOService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) ||
      (this.role == 'supervisor' && !this.allinoneService.isSupervisor())
    ) {
      this.router.navigateByUrl('');
    }
    this._SEOService.updateTitle('- Report');
  }

  ngOnInit(): void {
    const temp: any[] = [];
    this.categoryReportTableData = new MatTableDataSource(temp);
    this.courseReportTableData = new MatTableDataSource(temp);
    this.getChannels();
    this.getCategories();
    // this.getCourses();
  }

  searchTypeChange() {
    this.formSubmitted = false;
  }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'admin') {
      this.channelname = 'KHub';
      this.courseService.channelGet().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist.filter(
              (channel: any) => channel.status == '1'
            );
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingChannels = false;
        },
        (err) => {
          this.gettingChannels = false;
        }
      );
    } else if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      this.reportForm.channelid = this.channels[0].channelid;
      this.gettingChannels = false;
      this.channelname = this.channels.find(
        (x: any) => x.channelid == this.reportForm.channelid
      ).name;
    } else if (this.role == 'supervisor'){
      this.channels = this.allinoneService.supervisorChannels;
      this.reportForm.channelid = this.channels[0].channelid;
      this.gettingChannels = false;
      this.channelname = this.channels.find(
        (x: any) => x.channelid == this.reportForm.channelid
      ).name;
    }
    else{
      this.channels = this.allinoneService.hradminChannels;
      this.reportForm.channelid = this.channels[0].channelid;
      this.gettingChannels = false;
      this.channelname = this.channels.find(
        (x: any) => x.channelid == this.reportForm.channelid
      ).name;
    }
  }

  goAttendanceDetails(data:any, searchuser:any) {
    
    if(data > 0) {
      console.log('go');
      this.router.navigateByUrl(`${this.role}/report/details/${this.reportForm.channelid}/${searchuser}/${this.allinoneService.formatDate(this.reportForm.startdate)}/${this.allinoneService.formatDate(this.reportForm.enddate)}`);
    }
  }

  sortCategoryData(sort: Sort) {
    const data = this.categoryReportData;
    if (!sort.active || sort.direction === '') {
      this.categoryReportData = data;
      return;
    }

    this.categoryReportData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.allinoneService.compare(
            a.coursename,
            b.coursename,
            isAsc
          );
        case 'coursetype':
          return this.allinoneService.compare(
            a.coursetype,
            b.coursetype,
            isAsc
          );
        case 'studentcounts':
          return this.allinoneService.compare(
            parseInt(a.studentcounts),
            parseInt(b.studentcounts),
            isAsc
          );
        case 'totaltime':
          return this.allinoneService.compare(
            a.totalsecond,
            b.totalsecond,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.categoryReportTableData = new MatTableDataSource(
      this.categoryReportData
    );
  }
  sortCourseData(sort: Sort) {
    const data = this.courseReportData;
    if (!sort.active || sort.direction === '') {
      this.courseReportData = data;
      return;
    }

    this.courseReportData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'post':
          return this.allinoneService.compare(a.post, b.post, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'attendance':
          return this.allinoneService.compare(
            a.attendance,
            b.attendance,
            isAsc
          );
        case 'mandays':
          return this.allinoneService.compare(a.manday, b.manday, isAsc);
        case 'totaltime':
          return this.allinoneService.compare(
            a.totalsecond,
            b.totalsecond,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.courseReportTableData = new MatTableDataSource(this.courseReportData);
  }
  sortClassData(sort: Sort) {
    const data = this.classReportData;
    if (!sort.active || sort.direction === '') {
      this.classReportData = data;
      return;
    }

    this.classReportData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.allinoneService.compare(
            a.coursename,
            b.coursename,
            isAsc
          );
        case 'coursetype':
          return this.allinoneService.compare(
            a.coursetype,
            b.coursetype,
            isAsc
          );
        case 'studentcounts':
          return this.allinoneService.compare(
            a.studentcounts,
            b.studentcounts,
            isAsc
          );
        case 'totalclasses':
          return this.allinoneService.compare(
            a.totalclass,
            b.totalclass,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.classReportTableData = new MatTableDataSource(this.classReportData);
  }

  getCategories() {
    this.gettingCategories = true;
    this.courseService.getCategoryList().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categories = res.data;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
          }
        }
        this.gettingCategories = false;
      },
      (err) => {
        this.gettingCategories = false;
      }
    );
  }

  getCourses() {
    this.gettingCourses = true;
    this.courseService
      .getCoursesByChannel(this.reportForm.channelid, '')
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.courses = res.datalist;
            if (this.courses.length > 0) {
              this.reportForm.courseid = this.courses[0].courseid;
              this.reportForm.coursename = this.courses[0].coursename;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingCourses = false;
        },
        (err) => {
          this.gettingCourses = false;
        }
      );
  }

  goToReport() {
    this.router.navigateByUrl('admin/settings');
  }

  search() {
    this.formSubmitted = true;
    // Search by category
    var startdate = formatDate(
      new Date(this.reportForm.startdate),
      'yyyyMMdd',
      'en'
    );
    var enddate = formatDate(
      new Date(this.reportForm.enddate),
      'yyyyMMdd',
      'en'
    );
    if (this.reportForm.searchtype == 'category') {
      this.categoryReportData = [];
      this.categoryReportTableData = new MatTableDataSource(
        this.categoryReportData
      );
      this.gettingCategoryReport = true;
      const data = {
        categoryid: this.reportForm.categoryid,
        post: this.reportForm.posts,
        division: this.reportForm.divisions,
        branch: this.reportForm.branches,
        department: this.reportForm.departments,
        coursetype: this.reportForm.coursetypes,
        startdate: startdate,
        enddate: enddate,
        channelid: this.reportForm.channelid,
        schedule: '',
      };
      this.courseService.getCourseReport(data).subscribe(
        (courses: any) => {
          if (courses.returncode == '300') {
            courses.datalist.map(async (course: any) => {
              await this.categoryReportData.push({
                courseid: course.courseid,
                coursename: course.coursename,
                courseimage: course.courseimage,
                coursetype:
                  course.coursetype == '001'
                    ? 'Self-paced'
                    : course.coursetype == '002'
                    ? 'Instructor-led'
                    : 'Hybrid',
                totalsecond: course.totalsecond,
                totaltime: course.totaltime,
                studentcounts: course.studentcounts,
                category: course.category,
              });
            });
            this.categoryReportTableData = new MatTableDataSource(
              this.categoryReportData
            );
            this.categoryReportTableData.paginator = this.categoryPaginator;
          } else {
            if (courses.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                courses.status || courses.message || 'Server Error',
                'warn'
              );
            }
          }
          this.gettingCategoryReport = false;
        },
        (err) => {
          this.gettingCategoryReport = false;
        }
      );
    }
    // Search by course
    else if (this.reportForm.searchtype == 'course') {
      this.courseReportData = [];
      this.courseReportTableData = new MatTableDataSource(
        this.courseReportData
      );
      this.gettingCourseReport = true;
      const data = {
        post: this.reportForm.posts,
        division: this.reportForm.divisions,
        branch: this.reportForm.branches,
        department: this.reportForm.departments,
        startdate: startdate,
        enddate: enddate,
        channelid: this.reportForm.channelid,
        // courseid: this.reportForm.courseid,
        // classid: '',
      };
      this.courseService.getAttendanceReport(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.courseReportData = res.datalist;
            this.courseReportTableData = new MatTableDataSource(
              this.courseReportData
            );
            this.courseReportTableData.paginator = this.coursePagniator;
            this.gettingCourseReport = false;
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || 'Server Error',
                'warn'
              );
            }
            this.gettingCourseReport = false;
          }
        },
        (err) => {
          this.gettingCourseReport = false;
        }
      );
    } else if (this.reportForm.searchtype == 'class') {
      this.classReportData = [];
      this.classReportTableData = new MatTableDataSource(this.classReportData);
      this.gettingClassReport = true;
      const data = {
        channelid: this.reportForm.channelid,
        categoryid: this.reportForm.categoryid,
        coursetype: this.reportForm.coursetypes,
        courseid: this.reportForm.classcourses,
        searchtype: this.reportForm.classsearchtype == 'category' ? '2' : '1',
        startdate: this.reportForm.classdaterange ? startdate : '',
        enddate: this.reportForm.classdaterange ? enddate : '',
      };
      this.courseService.getClassReport(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.classReportData = res.datalist;
            this.classReportData.map((item: any) => {
              item.coursetype =
                item.coursetype == '001'
                  ? 'Self-paced'
                  : item.coursetype == '002'
                  ? 'Instructor-led'
                  : 'Hybrid';
            });
            this.classReportTableData = new MatTableDataSource(
              this.classReportData
            );
            this.classReportTableData.paginator = this.classPagniator;
            this.gettingClassReport = false;
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || 'Server Error',
                'warn'
              );
            }
            this.gettingClassReport = false;
          }
        },
        (err) => {
          this.gettingClassReport = false;
        }
      );
    }
  }

  channelChange(event: any) {
    // this.getCategories();
    this.getCourses();
  }

  downloadSheet() {
    if (
      (this.reportForm.searchtype == 'category' &&
        this.categoryReportData.length == 0) ||
      (this.reportForm.searchtype == 'course' &&
        this.courseReportData.length == 0)
    ) {
      this.messageService.openSnackBar('No data to export.', 'warn');
      return;
    }
    var startdate = formatDate(
      new Date(this.reportForm.startdate),
      'dd/MM/yyyy',
      'en'
    );
    var enddate = formatDate(
      new Date(this.reportForm.enddate),
      'dd/MM/yyyy',
      'en'
    );
    var data: any = [];
    var name = '';
    if (this.reportForm.searchtype == 'category') {
      // console.log(this.categories)
      // console.log(this.reportForm.categoryid)
      // console.log(this.categories.find((x: any) => x.categoryid == this.reportForm.categoryid).categoryname)
      var catname =
        this.reportForm.categoryid == ''
          ? 'All Categories'
          : this.categories.find(
              (x: any) => x.categoryid == this.reportForm.categoryid
            ).categoryname;
      name =
        'Reports - ' + catname + ' ( ' + startdate + '-' + enddate + ' ).xlsx';
      this.categoryReportData.map((course: any) => {
        data.push({
          'Course Name': course.coursename,
          'Course Type': course.coursetype,
          Students: course.studentcounts,
          'Time Spent': course.totaltime,
        });
      });
    } else if (this.reportForm.searchtype == 'course') {
      name =
        'Attendance Reports - ' +
        ' ( ' +
        startdate +
        '-' +
        enddate +
        ' ).xlsx';
      this.courseReportData.map((student: any) => {
        data.push({
          'User ID': student.userid,
          'User Name': student.username,
          'Post': student.typename,
          'Department': student.department,
          'Attendance': student.attendance,
          'Man-days': student.manday,
          'Time Spent': student.timespent,
        });
      });
    } else {
      if (this.reportForm.classdaterange) {
        name = 'Reports - ' + ' ( ' + startdate + '-' + enddate + ' ).xlsx';
      } else {
        name = 'Reports.xlsx';
      }

      this.classReportData.map((item: any) => {
        data.push({
          'Course Name': item.coursename,
          'Course Type': item.coursetype,
          Students: item.studentcounts,
          'Active Classes': item.activeclass,
          'Inactive Classses': item.inactiveclass,
          'Completed Classes': item.completedclass,
          'Total Classes': item.totalclass,
        });
      });
    }
    this.allinoneService.exportExcel(data, name);
  }

  filter() {
    let dialogRef = this.dialog.open(ReportFilterComponent, {
      minWidth: '600px',
      width: '700px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        role: this.role,
        reportForm: this.reportForm,
        channels: this.channels,
        categories: this.categories,
        courses: this.courses,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reportForm = result.report;
        this.courses = result.courses;
        if (this.reportForm.channelid) {
          this.channelname = this.channels.find(
            (x: any) => x.channelid == this.reportForm.channelid
          ).name;
        } else {
          this.channelname = 'KHub';
        }
        // console.log(this.reportForm);
        this.search();
      }
    });
  }

  remove(type: string, index: number) {
    if (type == 'coursetype') {
      this.reportForm.coursetypes.splice(index, 1);
      this.reportForm.coursetypesname.splice(index, 1);
    } else if (type == 'division') {
      this.reportForm.divisions.splice(index, 1);
    } else if (type == 'department') {
      this.reportForm.departments.splice(index, 1);
    } else if (type == 'branch') {
      this.reportForm.branches.splice(index, 1);
    } else if (type == 'post') {
      this.reportForm.posts.splice(index, 1);
      this.reportForm.postsname.splice(index, 1);
    } else if (type == 'category') {
      this.reportForm.categoryid = '';
      this.reportForm.categoryname = '';
    } else if (type == 'classdaterange') {
      this.reportForm.classdaterange = false;
    }
  }

  classInfo(status: string, courseid: string) {
    if (this.reportForm.classdaterange) {
      var startdate = formatDate(
        new Date(this.reportForm.startdate),
        'yyyyMMdd',
        'en'
      );
      var enddate = formatDate(
        new Date(this.reportForm.enddate),
        'yyyyMMdd',
        'en'
      );
      var daterange = startdate.toString() + enddate.toString();
      this.router.navigateByUrl(
        `${this.role}/report/${courseid}/${status}/${daterange}`
      );
    } else {
      this.router.navigateByUrl(
        `${this.role}/report/${courseid}/${status}/all`
      );
    }
  }
}
