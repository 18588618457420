<div class="mt-2 mb-2">

  <div>
    <div class="course-header mb-2 d-flex justify-content-between  align-items-center">
      <div class="d-flex w-100 flex-wrap justify-content-between align-items-center mt-3">
        <div>
          <button color="primary" class=" btn new-class me-1" (click)="newClass()">
            <i class="fa fa-plus" aria-hidden="true"></i>
            New Class
          </button>

        </div>
        <div class="d-flex flex-wrap align-items-center">
          <div class="me-2">
            Total :<b> {{datacount}}</b>
          </div>
          <div class="input-group" style="width: 260px !important;">
            <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
              (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
            <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
              *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
              <i class="fa fa-times"></i>
            </button>
          </div>

          <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button"
            style="margin-left: 10px;">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>

    </div>


    <div style="overflow: auto;">
      <table mat-table #table [dataSource]="showclass" matSort (matSortChange)="sortData($event)" matSort
        class="mat-elevation-z1" style="width: 100%;">

        <ng-container matColumnDef="classcode">

          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by classname"
            style="padding-left:0px;">Class Code</th>
          <td class="t1" mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;"
            (click)="gotoStudents(element)">
            {{element.classcode}}
          </td>
        </ng-container>

        <ng-container matColumnDef="classname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by classname">
            Class Name
          </th>
          <td mat-cell *matCellDef="let element" (click)="gotoStudents(element)"> {{element.classname}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status
          </th>
          <td mat-cell *matCellDef="let element" px-3 (click)="newClass(element)">
             <span *ngIf="element.classstatus == '001'" class = "badge bg-primary">Active</span>
             <span *ngIf="element.classstatus == '002'" class = "badge bg-danger">Inactive</span>
             <span *ngIf="element.classstatus == '003'" class = "badge bg-success">Completed</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="startdate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by startdate"> Start Date
          </th>
          <td mat-cell *matCellDef="let element" px-3 (click)="gotoStudents(element)"> {{allinoneService.formatDBToShow(element.startdate)}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by group"> Group
          </th>
          <td mat-cell *matCellDef="let element" px-3 (click)="gotoStudents(element)"> {{element.kunyekchannelid ? element.kunyekchannelname : '-'}}</td>
        </ng-container> -->

        <ng-container matColumnDef="remark">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by remark"> Remark
          </th>
          <td mat-cell *matCellDef="let element" px-3 (click)="gotoStudents(element)"> {{element.remark}} </td>
        </ng-container>

        <ng-container matColumnDef="action" style="width: 100px;">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
          <td mat-cell *matCellDef="let element" style="text-align: center;">
            <button class="btn btn-outline-primary btn-custom-rounded rounded-circle me-2" matTooltip="Class QR" style="font-size: 14px;width:42px;height: 40px;" [disabled]="disableClassQr(element.classstatus)" (click)="openClassQR(element)">
              <i class="fa fa-qrcode"></i>
          </button>
            <button class="btn btn-outline-primary btn-custom-rounded rounded-circle " matTooltip="Edit Class" style="font-size: 14px;width:42px;height: 40px;" (click)="newClass(element)">
              <i class="fa fa-edit"></i>
          </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td colspan="7">
            <span class="nodata" *ngIf="!isLoading">Empty</span>
            <span class="nodata" *ngIf="isLoading">
              <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
            </span>
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
        [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>