import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Class } from '../../shared/models/Class';
import { AddStudentClassComponent } from '../add-student-class/add-student-class.component';
import { OverallFeedbackComponent } from '../../feedback/overall-feedback/overall-feedback.component';

@Component({
  selector: 'app-paid-students',
  templateUrl: './paid-students.component.html',
  styleUrls: ['./paid-students.component.scss']
})
export class PaidStudentsComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  filter: string = '';
  allStudents: any[] = [];
  chl: any = '';

  showstudents: MatTableDataSource<any> = new MatTableDataSource(this.allStudents);

  displayedColumns: any;

  isLoading: boolean = false;
  paginate: boolean = false;
  datacount: string = '0';
  searchText: string = '';
  isFocus: boolean = false;
  courseid: string = "";

  classname: string = "";
  classcode: string = "";
  classid: string = "";
  student: boolean = false;
  coursename: string = "";


  displayColumnsList = [
    ['userid', 'username', 'classcode', 'feedback'],
    ['userid', 'username', 'feedback'],
  ]

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) { 
    const coid = this.route.snapshot.params['courseid'];
    this.courseid = coid;
  }

  ngOnInit(): void {
    this.displayedColumns = ['userid', 'username', 'classcode'];
    this.getallStudents();
  }

  goToSections() {
    this.router.navigateByUrl(`students/courses/${this.courseid}/sections`);
  }

  clear() {
    this.searchText = '';
    this.showstudents.filter = '';
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showstudents.filter = searchItem.trim().toLowerCase();
  }
  refresh() {
    this.isLoading = true;
    this.allStudents = [];
    this.datacount = "0";
    if (
      this.allStudents == undefined ||
      this.allStudents.length == 0
    ) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showstudents = new MatTableDataSource();
    this.getallStudents();
  }

  sortData(sort: Sort) {
    const data = this.allStudents;
    if (!sort.active || sort.direction === '') {
      this.allStudents = data;
      return;
    }

    this.allStudents = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'classcode':
          return this.allinoneService.compare(a.classcode, b.classcode, isAsc);
        default:
          return 0;
      }
    });
    this.showstudents = new MatTableDataSource(this.allStudents);
    this.showstudents.sort = this.sort;
    this.showstudents.paginator = this.paginator;
  }


  getallStudents(){
    this.allStudents = [];
    this.isLoading = true;
    this.courseService.getPaidStudents(this.courseid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.displayedColumns = res.coursetype == '001' ? this.displayColumnsList[1] : this.displayColumnsList[0];
          this.allStudents = res.datalist;
          
          this.datacount = this.allStudents.length.toString();
          this.coursename = res.coursename;
          this.showstudents = new MatTableDataSource(this.allStudents);
          if (
            this.allStudents == undefined ||
            this.allStudents.length == 0
          ) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }

          this.showstudents.sort = this.sort;
          this.showstudents.paginator = this.paginator;
          this.isLoading = false;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 1000);
          } else {
            this.messageService.openSnackBar("Server Error", 'warn');
          }
          this.isLoading = false;
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  
  addToClass(student: any){
    if(student.classid){
      return;
    }
    // Show dialog here
    let dialogRef = this.dialog.open(AddStudentClassComponent, {
      minWidth: '500px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        student: student,
        courseid: this.courseid,
      },
    });

    dialogRef.afterClosed().subscribe((result: { success: boolean, classid: string, classcode: string }) => {
      if (result.success) {
        student.classid = result.classid,
        student.classcode = result.classcode
      }
    })
  }

  feedback(student: any){
    // Show dialog here
    let dialogRef = this.dialog.open(OverallFeedbackComponent, {
      minWidth: '600px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        student: student,
        courseid: this.courseid,
      },
    });

    dialogRef.afterClosed().subscribe((result: { success: boolean, feedback: string }) => {
      if (result.success) {
        student.feedback = result.feedback
      }
    })
  }
}
