<app-breadcrumb [s_breadcrumb]="'Reports'" [a_breadcrumb]="bcname" [t_breadcrumb]="classname" (emit)="goToReport()" (emit1)="goToClass()"></app-breadcrumb>
<div class="content mt-2">
  <table mat-table #table [dataSource]="studentTableData" matSort style="width: 100%;"
      (matSortChange)="sortData($event)">

      <!-- Course Name Column -->
      <ng-container matColumnDef="userid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
        <td mat-cell *matCellDef="let element">
          {{element.userid}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.username}}
        </td>
      </ng-container>

      <!-- Total Classes Column -->
      <ng-container matColumnDef="studentcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Student ID</th>
        <td mat-cell *matCellDef="let element">
          {{element.studentcode || '-'}}
        </td>
      </ng-container>

      <!-- Total Classes Column -->
      <ng-container matColumnDef="studentname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.studentname  || '-'}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="post">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Remark</th>
        <td mat-cell *matCellDef="let element">
          {{element.typename || "-"}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
        <td mat-cell *matCellDef="let element">
          {{element.department || "-"}}
        </td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="remark">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Remark</th>
        <td mat-cell *matCellDef="let element">
          {{element.remark  || '-'}}
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="7">
          <span class="nodata" *ngIf="!gettingStudents">Empty</span>
          <span class="nodata" *ngIf="gettingStudents">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
</div>