<div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
        <div>
            <h2 class="info mb-0">Course</h2>
        </div>
        <div mat-dialog-close>
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="mb-3">
        <input type="text" class="form-control form-control-sm" placeholder="Select Course" aria-label="Number" matInput
            class="form-control" [matAutocomplete]="auto" id="course" name="course" #trigger="matAutocompleteTrigger"
            [(ngModel)]="courseSearchText" (click)="showList()">

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let course of courseList | appFilter:courseSearchText" (click)="selectCourse(course)">
                {{course.coursename}}
            </mat-option>
        </mat-autocomplete>
    </div>

    <div class="mb-3 pre-title" *ngIf="selectedCourse.length > 0">
        Courses
    </div>
    <div class="prerequisite-container mb-3" *ngIf="selectedCourse.length > 0">
        <div *ngFor="let course of selectedCourse; let i = index"
            class="card card-body d-flex flex-row justify-content-between align-items-center mb-2">
            <div class="d-flex align-items-center">
                <div class="me-2">
                    <img style="width: 100px;" [src]="course.courseimageurl ? course.courseimageurl :  allinoneService.defaultImg" alt="">
                </div>
                <div>
                    {{course.coursename}}
                </div>
            </div>
            <div>
                <mat-icon (click)="remove(i)">delete</mat-icon>
            </div>
        </div>
    </div>

    <div class="actions-button">

        <button style="width: 80px !important;" mat-stroked-button class="mt-2 cancel-button" type="button"
            (click)="cancel()">
            Cancel
        </button>

        <app-button-loader [button_text]="'Save'" (click)="saveData()" [isStillupload]="saveLoading">
        </app-button-loader>

    </div>
</div>