import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-single-student-single-course',
  templateUrl: './single-student-single-course.component.html',
  styleUrls: ['./single-student-single-course.component.scss'],
})
export class SingleStudentSingleCourseComponent implements OnInit {

  isFromCourse: boolean =false;

  role: string = '';
  channelid: string = '';
  courseid: string = '';
  userid: string = '';
  channelname: string = 'Channel';
  coursename: string = 'Course';
  username: string = 'User';

  totaltime: string = '0 hr(s) 0 min(s)'

  course: any;

  isLoading: boolean = false;
  public:any = "";
  publicValue:any = "";


  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe((data) => {
      this.role = data.role;
      if (data.type == 'course') {
        this.isFromCourse = true;
      }
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) || (this.role == 'supervisor' && !this.allinoneService.isSupervisor())) {
      this.router.navigateByUrl('')
    }
    if (this.route.snapshot.params['channelid'] == 'public') {
      this.channelid = '';
    } else {
      this.channelid = this.route.snapshot.params['channelid'];
    }
    this.courseid = this.route.snapshot.params['courseid'];
    this.userid = this.route.snapshot.params['userid'];
    this.public = this.route.snapshot.queryParamMap.get('type') || "";
    this.publicValue = this.allinoneService.decrypt(this.public);
  }

  ngOnInit(): void {
    this.getUserDetailAnalytic();
  }


  getUserDetailAnalytic(){
    this.isLoading = true;
    this.courseService.getUserDetailAnalytics(this.channelid, this.courseid, this.userid).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.channelname = res.channelname;
        this.username = res.username;
        this.course = res.data;
        this.coursename = res.coursename;
        this.totaltime = res.totaltime;
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || "Server Error", 'warn');
      }
      this.isLoading = false;
    }, err=> {
      this.isLoading = false;
    }
    )
  }

  goToAnalytics(){
    this.router.navigateByUrl(`${this.role}/analytics`);
  }
  goToChannel(){
    const channel = this.channelid ? this.channelid: 'public';
    this.router.navigateByUrl(`${this.role}/analytics/${channel}`); 
  }

  goToCourse(){
    const channel = this.channelid ? this.channelid: 'public';
    this.router.navigateByUrl(`${this.role}/analytics/${channel}/course/${this.courseid}?type=${this.allinoneService.encrypt(this.publicValue)}`); 
  }

  goToUser(){
    const channel = this.channelid ? this.channelid: 'public';
    this.router.navigateByUrl(`${this.role}/analytics/${channel}/user/${this.userid}?type=${this.allinoneService.encrypt(this.publicValue)}`); 
  }
}
