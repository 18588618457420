
import { CreateStudentComponent } from './../create-student/create-student.component';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { LoadingService } from '../../shared/services/loading.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-dropbox-submissions',
  templateUrl: './dropbox-submissions.component.html',
  styleUrls: ['./dropbox-submissions.component.scss']
})
export class DropboxSubmissionsComponent implements OnInit {


  // @Input() courseid!: string;
  @Input() classnames!: string;
  @Input() classcode!: string;
  // @Input() classid!: string;
  @Output() back = new EventEmitter();



  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;

  classV: boolean = false;
  filter: string = '';
  alldropboxdata: any[] = [];
  allsection: any[] = [];

  classUrl: string = "";
  classid: string = "";
  courseid: string = "";
  coursename: string = "";
  classname: string = "";
  gettingmyDropbox: boolean = false;

  selectedDropbox: string = "all";

  showdropboxdata: MatTableDataSource<any> = new MatTableDataSource(this.alldropboxdata);

  displayedColumns: any;

  isLoading: boolean = false;
  paginate: boolean = false;
  gettingDropbox: boolean = false;
  datacount: string = '0';
  searchText: string = '';
  isFocus: boolean = false;
  // section:any[] = []
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private httpClient: HttpClient,

  ) {

    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.classUrl = `/instructor/courses/${this.courseid}/class`;
    });

    const cid = this.route.snapshot.params['classid'];
    this.classid = cid;

    // this.section = [
    //   {"sectionid":"32332","sectionname":"Nyi"},
    //   {"sectionid":"32332","sectionname":"ye"}


    // ]
  }

  ngOnInit() {
    this.displayedColumns = ['studentid', 'studentname', 'title', 'file', 'remark', 'datetime', 'action'];

    this.getAllDropBoxData();
    this.getSection();

  }

  sortData(sort: Sort) {
    const data = this.alldropboxdata;
    if (!sort.active || sort.direction === '') {
      this.alldropboxdata = data;
      return;
    }
    this.alldropboxdata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'studentid':
          return this.compare(a.studentid, b.studentid, isAsc);
        case 'studentname':
          return this.compare(a.studentname, b.studentname, isAsc);
        case 'title':
          return this.compare(a.title, b.title, isAsc);
        case 'file':
          return this.compare(a.file, b.file, isAsc);
        case 'remark':
          return this.compare(a.remark, b.remark, isAsc);
        case 'datetime':
          return this.compare(a.datatime, b.datatime, isAsc);
        default:
          return 0;
      }
    });
    this.showdropboxdata = new MatTableDataSource(this.alldropboxdata);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getSection() {
    this.gettingDropbox = true;
    this.allsection = [];

    this.courseService.getSection(this.courseid).subscribe((students: any) => {
      if (students.returncode == '300') {
        this.allsection = students.datalist;
        console.log(this.allsection);
        this.gettingDropbox = false;
      } else {
        if (students.returncode == '210') {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
        } else {
          this.messageService.openSnackBar(students.status || students.message || students.error, 'warn');
        }

        this.gettingDropbox = false;
      }
    },
      (err) => {
        this.gettingDropbox = false;
      }
    );
  }
  //  allsection:any [] = [];

  getAllDropBoxData() {
    this.gettingDropbox = true;
    this.alldropboxdata = [];
    this.isLoading = true;
    this.courseService.getInstructorDropboxList(this.classid, this.courseid).subscribe((students: any) => {
      if (students.returncode == '300') {
        this.alldropboxdata = students.datalist;
        this.coursename = students.coursename;
        this.classname = students.classname;
        console.log(this.alldropboxdata);
        this.datacount = this.alldropboxdata.length.toString();
        if (
          this.alldropboxdata == undefined ||
          this.alldropboxdata.length == 0
        ) {
          this.paginate = false;
        } else {
          this.paginate = true;
        }

        this.showdropboxdata = new MatTableDataSource(this.alldropboxdata);
        this.showdropboxdata.sort = this.sort;
        this.showdropboxdata.paginator = this.paginator;
        this.isLoading = false;
        this.gettingDropbox = false;
      } else {
        if (students.returncode == '210') {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinoneService.logout();
          }, 2000);
        } else {
          this.messageService.openSnackBar(students.status || students.message || students.error, 'warn');
        }
        this.isLoading = false;
        this.gettingDropbox = false;
      }
    },
      (err) => {
        this.isLoading = false;
        this.gettingDropbox = false;
      }
    );
  }

  downloadFile(url: string, name: string) {

    this.courseService.downloadFile(url).subscribe((response: any) => {
      saveAs(response, name);
    }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  clear() {
    this.searchText = '';
    this.showdropboxdata.filter = '';
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showdropboxdata.filter = searchItem.trim().toLowerCase();
  }
  refresh() {
    this.isLoading = true;
    this.alldropboxdata = [];
    this.datacount = "0";
    if (
      this.alldropboxdata == undefined ||
      this.alldropboxdata.length == 0
    ) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showdropboxdata = new MatTableDataSource();
    this.getAllDropBoxData();
  }

  onChange(data: any) {
    const searchItem = data.target.value;
    if (data.target.value == 'all') {
      this.getAllDropBoxData();
    } else {
      this.showdropboxdata.filter = searchItem.trim().toLowerCase();
    }
  }

  // download(data: any) {

  // }


  //  download (filename: any) {
  //   const element = document.createElement('a')
  //   element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent("C:\Users\HP\Pictures\bamar.pdf"))
  //   element.setAttribute('download', "nyi")
  //   element.setAttribute('target', '_blank')
  //   element.style.display = 'none'
  //   element.click()
  // }
  //   download(data:any) {
  //     this.downloads
  //       .download('/downloads/archive.zip')
  //       .subscribe(blob => saveAs(blob, 'archive.zip'))
  // }

  download(data: any) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', data.fileurl);
    link.setAttribute('download', data.filename);
    // document.body.appendChild(link);
    link.click();
    // link.remove();

  }

  generalarray: any[] = [];
  getDropbox() {
    this.router.navigate([]);
    this.getWithSection();
    this.generalarray = [];
    if (this.selectedDropbox == "general") {
      for (let i = 0; i < this.alldropboxdata.length; i++) {
        if (this.alldropboxdata[i].sectionid == "") {
          this.generalarray.push(this.alldropboxdata[i]);
          this.datacount = this.generalarray.length.toString();
          this.showdropboxdata = new MatTableDataSource(this.generalarray);
          this.showdropboxdata.sort = this.sort;
          this.showdropboxdata.paginator = this.paginator;
        }
      }
    } else if (this.selectedDropbox == "all") {
      this.getAllDropBoxData();

    } else {
      

      for (let i = 0; i < this.alldropboxdata.length; i++) {
        if (this.alldropboxdata[i].sectionid == this.selectedDropbox) {
          this.generalarray.push(this.alldropboxdata[i]);
          this.datacount = this.generalarray.length.toString();
          this.showdropboxdata = new MatTableDataSource(this.generalarray);
          this.showdropboxdata.sort = this.sort;
          this.showdropboxdata.paginator = this.paginator;
        }else{
          this.datacount = this.generalarray.length.toString();
          this.showdropboxdata = new MatTableDataSource(this.generalarray);
          this.showdropboxdata.sort = this.sort;
          this.showdropboxdata.paginator = this.paginator;
        }
      }
    }

  }
  getWithSection() {

    console.log(this.selectedDropbox);

    // this.alldropboxdata = []
    // this.isLoading = true
    // this.courseService.getAllCourseByInstructor(this.selectedDropbox).subscribe((dropbox: any) => {
    //   if (dropbox.returncode == "300") {
    //     // console.log(dropbox);

    //       // await this.courses.push({
    //       //   courseid: course.courseid,
    //       //   coursename: course.coursename,
    //       //   courseimage: course.courseimage,
    //       //   type: course.coursestatus == "0" ? "DRAFT" : "PUBLISHED",
    //       //   studentcount: course.studentcount
    //       // })
    //       this.datacount = dropbox.data.length;

    //       // console.log(courses);



    //     if (this.alldropboxdata == [] || this.alldropboxdata == undefined || this.alldropboxdata.length == 0) {
    //       this.paginate = false;
    //     } else {
    //       this.paginate = true;
    //     }

    //     setTimeout(() => {
    //       this.isLoading = false;
    //     }, 400);

    //     // this.table.renderRows();
    //   }
    //   else {
    //     if (dropbox.returncode == "210") {
    //       setTimeout(() => {
    //         this.router.navigateByUrl('login');
    //         this.allinoneService.logout();
    //       }, 2000);
    //     }
    //     else {
    //       this.messageService.openSnackBar(dropbox.status, "warn");
    //     }
    //     setTimeout(() => {
    //       this.isLoading = false;
    //     }, 400);
    //   }
    // }, err => {
    //   this.isLoading = false;
    // })

  }

  // deleteStudent(data: any, idx: any) {


  //   let dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     minWidth: "300px",
  //     scrollStrategy: new NoopScrollStrategy(),
  //   },
  //   );

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result == true) {
  //       this.courseService.updateStudent(data.id, "true", data.remark).subscribe(
  //         (classes: any) => {
  //           if (classes.returncode == '300') {
  //             this.loadingService.hide();
  //             this.alldropboxdata.splice(idx, 1);
  //             this.showdropboxdata = new MatTableDataSource(this.alldropboxdata);
  //             this.showdropboxdata.paginator = this.paginator;
  //             this.datacount = this.alldropboxdata.length.toString();
  //           } else {
  //             this.loadingService.hide();
  //             if (classes.returncode == '210') {
  //               this.loadingService.hide();
  //               setTimeout(() => {
  //                 this.router.navigateByUrl('login');
  //                 this.allinoneService.logout();
  //               }, 2000);
  //             } else {
  //               this.loadingService.hide();
  //               this.messageService.openSnackBar(classes.status || classes.message || classes.error, 'warn');
  //             }
  //             this.loadingService.hide();
  //             this.isLoading = false;
  //           }
  //         },
  //         (err) => {
  //           this.loadingService.hide();
  //           this.isLoading = false;
  //         }
  //       );

  //     }
  //   })
  // }
  gotoCourse() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/info`)
  }
  gotoClass() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/classes`);
  }
}
