<div *ngIf="!fromltuser">
    <app-breadcrumb s_breadcrumb="Analytics" [a_breadcrumb]="channelname" [t_breadcrumb]="username || userid"
    (emit)="goTo(role+'/analytics')" (emit1)="goTo1()"></app-breadcrumb>
</div>

<div *ngIf="fromltuser && LTName != '' ">
    <app-breadcrumb [s_breadcrumb]="'Learning Track'" [a_breadcrumb]="LTName" [b_breadcrumb]="'Members'"
        [t_breadcrumb]="userid" (emit)="goToLT()" (emit1)="goToLT()"
        (emit2)="goToLTUser()"></app-breadcrumb>
</div>

<div class="content">

    <!-- <h2 (click)="test()">Test</h2> -->

    <div class="d-flex flex-wrap align-items-center mt-3 justify-content-between">
        <div class="d-flex align-items-center flex-wrap mb-3">
            <!-- <div class="me-3">
          View By
        </div> -->

        </div>
        <div class="d-flex align-items-center mb-3">
            <div class="me-2 d-flex align-items-center">
                Total : &nbsp;<b> {{totaltime}}</b>
            </div>
            <div class="input-group" style="width: 260px !important;">
                <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                    (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
                <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                    *ngIf="searchText" (click)="clear()"
                    style="border: 1px solid var(--main-color);color: var(--main-color);">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <button class="btn new-chl me-2" matTooltip="Refresh" (click)="refresh()" type="button">
                <i class="fa fa-refresh"></i>
            </button>
            <button type="button" class="btn new-class" (click)="downloadSheet()"
        matTooltip="Export Student To Excel">
        <img class="excel-icon" src="../../../assets/images/excel.png">
        Export
      </button>
        </div>
    </div>

    <div style="overflow: auto;">
        <table mat-table #table [dataSource]="showcourses" matSort (matSortChange)="announceSortChange($event)"
            class="mat-elevation-z1" style="width: 100%;">

            <!-- Image Column -->
            <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef style="min-width: 140px;"></th>
                <td mat-cell *matCellDef="let element" (click)="goToUserAnalytic(element)">

                    <img *ngIf="element.courseimage == ''" [src]="allinoneService.defaultImg"
                        class="course-listing-image" />
                    <img *ngIf="element.courseimage != ''" [src]="allinoneService.imgurl + element.courseimage"
                        class="course-listing-image" />

                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="coursename">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Course Name
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
                    {{element.coursename}}</td>
            </ng-container>

            <!-- Students Column -->
            <!-- <ng-container matColumnDef="students">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Students
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
                    {{element.students || 0}}</td>
            </ng-container> -->


            <!-- Time spent Column -->
            <ng-container matColumnDef="timespent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"
                    sortActionDescription="Sort by studentcount">
                    Time Spent
                </th>
                <td mat-cell *matCellDef="let element" style="color: var(--main-color); text-align: center"
                    (click)="goToUserAnalytic(element)">
                    {{element.totaltime}} </td>
            </ng-container>

            <!-- Grade Column -->
            <ng-container matColumnDef="grade">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="grade"> Grade
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
                    {{element.grade}} %</td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="progress"> Progress
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
                    {{element.percentage}} %</td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)"
               
                >
                    <span class="status" [ngClass]="{
                        'status-green': element.completed,
                        'status-gray': !element.completed && element.percentage == 0,
                        'status-yellow': !element.completed && element.percentage != 0
                    }" >{{element.completed ? 'Completed' : !element.completed && element.percentage == 0  ? 'Not Started' : 'In Progress' }}</span></td>
            </ng-container>

            <!-- Completed Date Column -->
            <ng-container matColumnDef="completeddate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="completeddate"> Completed Date
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
                    {{element.completeddate}}</td>
            </ng-container>

            <!-- Enrolled Date Column -->
            <ng-container matColumnDef="enrolleddate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="enrolleddate"> Enrolled Date
                </th>
                <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
                    {{element.enrolleddate}}</td>
            </ng-container>
            <!-- [ngClass]="{
                'status-green': element.completed == true,
                'status-yellow': element.completed == false,
                'status-red': element.status == false && element.percentage < 1 ,
            }" -->

            

            <!-- <td class="td-data td-edit">
                <span [title]="data.status" class="status" [ngClass]="{
                    'status-green': data.status == '002',
                    'status-red': data.status == '003',
                    'status-gray': data.status == '001',
                    'status-main': data.status == '004'
                  }">{{ data.status == '001' ? 'Submitted' : data.status == '002' ? 'Approved' : data.status == '003' ?
                  'Rejected' : 'Completed' }}</span>
            
              </td> -->


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td colspan="9">
                    <span class="nodata" *ngIf="!isLoading">Empty</span>
                    <span class="nodata" *ngIf="isLoading">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
                    </span>
                </td>
            </tr>

        </table>

        <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
            [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>

    </div>


    <!-- <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
      </div> -->

    <!-- <div style="text-align: center;padding: 1rem 0;" *ngIf="courses.length === 0 && !isLoading">No courses found</div> -->


</div>