import {
  Component,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  ViewChild,
  OnDestroy,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LoadingService } from '../../shared/services/loading.service';
import WebViewer from '@pdftron/webviewer';
import { MatDialog } from '@angular/material/dialog';
// import { AnonymousLearningLoginComponent } from '../../shared/components/anonymous-learning-login/anonymous-learning-login.component';
// import { SEOService } from '../../shared/services/seo.service';


@Component({
  selector: 'app-course-content-preview',
  templateUrl: './course-content-preview.component.html',
  styleUrls: ['./course-content-preview.component.scss']
})
export class CourseContentPreviewComponent implements OnInit {

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    //Clear Interval Here
    console.log('popstate => clear interval');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
      this.tabClick(1);
    }
  }
  hideTopbar: boolean =false;

  @ViewChild('viewer')
  viewerRef!: ElementRef;

  webviewer: any = '';
  webviewercanvas: any = '';

  autoid: any = '';
  isMobileView: boolean = false;

  course: any = {}; //Student side used as "lecture" and Teacher side used as "unit"
  instructor: any;
  courseid: string = '';
  unitid: string = '';
  lecture: any = {};
  mobileViewWidth: number = 501;

  isLoading: boolean = true;
  isDocumentLoading: boolean = false;
  timeIntervalFrame: any;

  isFirst: boolean = false;
  isLast: boolean = false;
  lecturelist: any = [];

  tab_active: number = 1;
  currentIndex: any = 0;

  @ViewChild(PlyrComponent)
  plyr!: PlyrComponent;
  videoid: string = '';
  videotype: Plyr.Provider = 'vimeo';

  // or get it from plyrInit event
  player!: Plyr;

  videoSources: Plyr.Source[] = [
    {
      src: this.videoid,
      provider: this.videotype,
    },
  ];
  show: boolean = false;

  plyrevent: any;

  completedunitlist: any = [];

  isError: boolean = false;

  YT: any;
  video: any;

  timeAnalyticInterval: any;

  // Anonymous Access Variables
  // 001 - Don't have public access - Should Redirect to Home
  // 002 - Already enrolled this course - Just normal
  // 003 - Not enrolled but have public access - Show Enroll Button - Nav Bar hide My Courses
  // 004 - Not log in and have pubilc access - Show Log In button - Nav Bar hide My Courses
  coursestatus = '';
  enrolling: boolean = false;

  constructor(
    // private _seoService: SEOService,
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnDestroy() {
    // if (this.coursestatus == '002' || this.coursestatus == '003') {
    //   this.clearTimeAInterval();
    // }
  }

  nextLecture() {
    if (!this.isLast) {
      const index = this.lecturelist.findIndex(
        (unit: any) => unit.unitid === this.lecture.unitid
      );
      if (!this.lecture.checked) {
        this.lecturelist[index].checked = true;
        this.course.sections.map((section: any) => {
          section.subsections.map((subsection: any) => {
            subsection.units.map((unit: any) => {
              if (unit.unitid == this.lecture.unitid) {
                unit.checked = true;
              }
            });
          });
        });
      }
      this.isLast = this.lecturelist.length - 1 == index + 1;
      this.viewLecture(this.lecturelist[index + 1]);
    }
  }
  previousLecture() {
    if (!this.isFirst) {
      const index = this.lecturelist.findIndex(
        (unit: any) => unit.unitid === this.lecture.unitid
      );
      this.isFirst = index - 1 == 0;
      console.log('last => ' + this.isFirst);
      this.viewLecture(this.lecturelist[index - 1]);
    }
  }

  setTime(status: string) {
    const data = {
      id: this.autoid,
      courseid: this.courseid,
      userid: this.allinOneService.getKunyekUserId(),
      channelid: this.course.t2,
      status: status,
    };
    // this.courseService.timeAnalytic(data).subscribe((res: any) => {
    //   if (res.returncode == '300') {
    //     if (this.autoid == '') {
    //       this.autoid = res.id;
    //     }
    //   }
    // });
  }

  async ngOnInit(): Promise<any> {
    // this.init();

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.tabClick(3);
    }

    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.unitid = params['lectureid'];
    });
    const awaitGetcourse = await this.getCourse();
    console.log(this.lecturelist);
    if (awaitGetcourse == 'success') {
      this.getInstructor();
    } else {
      this.isLoading = false;
    }
    // if (this.coursestatus == '002' || this.coursestatus == '003') {
    //   this.setTime('1');
    //   this.timeAnalyticInterval = setInterval(() => {
    //     this.setTime('1');
    //   }, 60 * 1000);
    // }
  }

  clearTimeAInterval() {
    console.log('Cleared Interval');
    clearInterval(this.timeAnalyticInterval);
    this.setTime('2');
  }

  // The API calls this function when the player's state changes.
  onPlayerStateChange(event: any) {
    console.log('onPlayerStateChange');
    console.log(event.data);
  }

  // The API will call this function when the video player is ready
  onPlayerReady(event: any) {
    console.log(event);

    const videoId = this.getVideo();
    event.target.cueVideoById({
      videoId: videoId,
    });
    event.target.playVideo();
  }

  getVideo() {
    return '60ItHLz5WEA';
  }

  navigateBack() {
    this.location.back();
  }

  getCourse() {
    return new Promise((resolve) => {
      this.courseService.getCourseByCourseID(this.courseid).subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            if (res.coursestatus == '001') {
              this.router.navigateByUrl('/home');
              return;
            }
            this.coursestatus = res.coursestatus;
            this.course = res.data;

            // this._seoService.updateTitle('- ' + this.course.coursename);

            // this._seoService.updateImg(
            //   this.allinOneService.imgurl + this.course.courseimage
            // );

            this.course.sections.map((section: any) => {
              section.subsections.map((subsection: any) => {
                subsection.units.map((unit: any) => {
                  if (unit.contenttype == '1') {
                    if (
                      unit.content.toString().startsWith('https://youtu.be/') ||
                      unit.content
                        .toString()
                        .startsWith('https://www.youtube.com')
                    ) {
                      unit.icon = 'youtube';
                    } else {
                      unit.icon = 'vimeo';
                    }
                  } else if (unit.contenttype == '2') {
                    const filetype = unit.content.split('.').pop();
                    if (filetype == 'pdf') {
                      unit.icon = 'pdf';
                    } else if (filetype == 'docx') {
                      unit.icon = 'word';
                    } else if (filetype == 'pptx') {
                      unit.icon = 'powerpoint';
                    } else if (filetype == 'xlsx') {
                      unit.icon = 'excel';
                    }
                  } else {
                    unit = { ...unit, icon: '' };
                  }
                  unit = { ...unit, checked: false };
                  this.lecturelist.push(unit);
                });
              });
            });
           

            if (this.coursestatus == '002') {
              const awaitProgressList = await this.getUnitProgress();
            }
            //progress
            this.completedunitlist.map((unitid: any) => {
              this.course.sections.map((section: any) => {
                section.subsections.map((subsection: any) => {
                  subsection.units
                    .filter((unit: any) => unit.unitid == unitid)
                    .map((unitval: any) => {
                      unitval.checked = true;
                    });
                });
              });
            });
            if(Object.keys(this.lecture).length === 0){
              this.viewLecture(this.lecturelist[0]);
            }
            resolve('success');
          } else if (res.returncode == "403") {
            this.messageService.openSnackBar(
              res?.message,
              'warn'
            );
            this.router.navigateByUrl('home');
          } else {
            if (res.returncode == '240') {
              this.router.navigateByUrl('home');
            } else if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(res.status, 'warn');
              if (res.statuscode == '200') {
                setTimeout(() => {
                  this.router.navigateByUrl(`course/${this.courseid}`);
                }, 3000);
              }
            }
            this.isError = true;
            setTimeout(() => {
              if (res.returncode == '404') {
                this.location.back();
              }
            }, 3000);
            resolve('error');
          }
        },
        (err) => {
          this.isError = true;

          resolve('error');
        }
      );
    });
  }

  getInstructor() {
    this.courseService.getInstructor(this.course.instructorid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.instructor = res;
          this.isLoading = false;

          // this._seoService.updateDescription(this.instructor.username);
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
          setTimeout(() => {
            if (res.returncode == '404') {
              this.location.back();
            }
          }, 3000);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  getUnitProgress() {
    // return new Promise((resolve) => {
    //   this.courseService.getCompletedUnits(this.course.pkey).subscribe(
    //     (res: any) => {
    //       if (res.returncode == '300') {
    //         this.completedunitlist = res.unitlist == '' ? [] : res.unitlist;
    //         resolve('success');
    //       } else {
    //         if (res.returncode == '210') {
    //           setTimeout(() => {
    //             this.router.navigateByUrl('login');
    //             this.allinOneService.logout();
    //           }, 2000);
    //         } else {
    //           this.messageService.openSnackBar(res.status, 'warn');
    //         }
    //         resolve('error');
    //       }
    //     },
    //     (err) => {
    //       resolve('error');
    //     }
    //   );
    // });
  }


  ngAfterViewInit() {
    // const element = document.getElementById('viewer');
    // WebViewer({
    //   path: '../../assets/public',
    //   initialDoc:'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/references/10_Sales_12_Process_Order2Cash_1626926093573.pptx'
    //   }, this.viewerRef.nativeElement).then(instance => {
    //   console.log("SUCCES>>>>>>>");
    // });
  }

  reload() {
    window.location.reload();
  }

 
  goBackPreview() {
    this.router.navigateByUrl(`instructor/courses/${this.courseid}/curriculum`)
}

  viewLecture(lecture: any, click?: boolean) {
    this.changeDetectorRef.detectChanges();
    // this.location.replaceState(`courses/${this.courseid}/lectures/${lecture.unitid}`)
    //fina a lecture [matching with url]
    // this.location.replaceState(
    //   `courses/${this.courseid}/lectures/${lecture.unitid}`
    // );

    if (click) {
      if(!lecture.checked){
        this.course.sections.map((section: any) => {
          section.subsections.map((subsection: any) => {
            subsection.units.map((unit: any) => {
              if (unit.unitid == lecture.unitid) {
                unit.checked = true;
              }
            });
          });
        });
      }
      if(this.isMobileView){
        this.tabClick(3);
      }
    }

    this.course.sections.map((section: any) => {
      section.show = '';
      section.active = '';
      section.subsections.map((subsection: any) => {
        subsection.show = '';
        section.active = '';
        subsection.units
          .filter((unit: any) => unit.unitid == lecture.unitid)
          .map((unit: any) => {
            console.log(unit);
            this.lecture = JSON.parse(JSON.stringify(unit));
            lecture.active = unit.unitid;
            section.show = 'show';
            subsection.show = 'show';
            section.active = 'active-arrow';
            subsection.active = 'active-arrow';
          });
      });
    });

    const index = this.lecturelist.findIndex(
      (unit: any) => unit.unitid === this.lecture.unitid
    );
    this.currentIndex = index + 1;
    this.isLast = this.lecturelist.length - 1 == index;
    this.isFirst = index == 0;

    // this.lecture = lecture;

    if (this.lecture.contenttype == 1) {
      //youtube
      if (this.lecture.content.toString().startsWith('https://youtu.be/')) {
        this.lecture.content =
          'https://www.youtube.com/embed/' +
          this.lecture.content.split('/').pop();
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = 'youtube';
      } else if (
        this.lecture.content.toString().startsWith('https://www.youtube.com')
      ) {
        this.lecture.content =
          'https://www.youtube.com/embed/' +
          this.lecture.content.split('v=').pop()?.replace('&', '?');
        this.videoid = this.lecture.content
          .split('v=')
          .pop()
          ?.replace('&', '?');
        this.videotype = 'youtube';
      }
      //vimeo
      else if (
        this.lecture.content.toString().startsWith('https://vimeo.com/')
      ) {
        //vimeo
        this.lecture.content =
          'https://player.vimeo.com/video/' +
          this.lecture.content.split('/').pop();
        this.videoid = this.lecture.content.split('/').pop();
        this.videotype = 'vimeo';
      }

      this.videoSources = [
        {
          src: this.videoid,
          provider: this.videotype,
        },
      ];
    }

    if (this.lecture.contenttype == 2) {
      // setTimeout(() => {
      //   const element = document.getElementById('viewer') as HTMLElement;
      //   if (element &&  !this.webviewer) {
      //     WebViewer(
      //       {
      //         path: '../../assets/public',
      //         initialDoc: this.allinOneService.docurl + this.lecture.content,
      //         fullAPI: true,
      //       },
      //       element
      //     ).then((instance) => {
      //       this.webviewer = instance;
      //       instance.UI.disableElements(['toolbarGroup-Annotate']);
      //       instance.UI.disableElements(['toolbarGroup-Shapes']);
      //       instance.UI.disableElements(['toolbarGroup-FillAndSign']);
      //       instance.UI.disableElements(['toolbarGroup-Forms']);
      //       instance.UI.disableElements(['toolbarGroup-Edit']);
      //       instance.UI.disableElements(['toolbarGroup-Insert']);
      //     });
      //   } else {
      //     console.log('-----------')
      //     console.log(this.lecture.content);
      //     this.webviewer.UI.loadDocument(
      //       this.allinOneService.docurl + this.lecture.content
      //     );
      //   }
      // }, 1000);
    } else {
      this.webviewer = '';
      this.webviewercanvas = '';
    }
  }


  tabClick(tab: number) {
    this.tab_active = tab;
    // var myOffcanvas = document.getElementById('offcanvasTop')
    // var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

    // bsOffcanvas.show();
  }
  played(event: any) {
    // console.log(event.target.type);
    // this.plyrevent = event;
    // var logger = document.querySelector('#log');
    // if (logger) {
    //   if (typeof this.plyrevent == 'object') {
    //     logger.innerHTML += JSON.stringify(this.plyrevent) + '<br/>';
    //   } else {
    //     // logger.innerHTML += this.plyrevent + '<br />';
    //   }
    // }
  }
  plyrError(event: Plyr.PlyrEvent) {
    console.log('plyrError: ', event);
    this.plyrevent = JSON.stringify(event);
    var logger = document.querySelector('#log');
    if (logger) {
      if (typeof this.plyrevent == 'object') {
        logger.innerHTML += JSON.stringify(this.plyrevent) + '<br/>';
      } else {
        // logger.innerHTML += this.plyrevent + '<br />';
      }
    }
  }
  play(): void {
    this.plyr.player.muted = true;
    this.plyr.player.muted = false;
    this.plyr.player.play();
  }

  plyrEnded(event: Plyr.PlyrEvent): void {
    var unit: any = [];
    this.course.sections.map((section: any) => {
      section.subsections.map((subsection: any) => {
        unit = subsection.units.filter((el: any) => el.unitid == this.unitid);
        if (unit.length == 1) {
          unit[0].checked = true;

          this.videoSources = [
            {
              src: this.videoid,
              provider: this.videotype,
            },
          ];
        }
      });
    });

    // this.player.currentTime = 20;
    // this.player.muted = false;
    // this.player.play();
  }
  documentLoaded() {
    // this.isDocumentLoading = false;
    // const element = document.getElementById("progressBar") as HTMLElement;
    // const doc = document.getElementById("doc") as HTMLElement;
    // element.style.display = "none";
    // if (doc) {
    //   doc.style.display = "block";
    // }
    // document.getElementById("progressBar").display = "none";
  }

  openCollapse(idx: any) {
    for (let a = 0; a < this.course.sections[idx].subsections.length; a++) {
      this.course.sections[idx].subsections[a].show = true;
    }
    this.course.sections[idx].show = !this.course.sections[idx].show;
  }
  closeCollpased(setidx: any, subidx: any) {
    this.course.sections[setidx].subsections[subidx].show =
      !this.course.sections[setidx].subsections[subidx].show;
  }

  closeCourseContent() {
    const content = document.querySelector('.course-content');
    this.renderer.setStyle(
      content,
      'width',
      '0px',
      RendererStyleFlags2.Important
    );

    const learn = document.querySelector('.learn-content');
    this.renderer.setStyle(
      learn,
      'width',
      '100%',
      RendererStyleFlags2.Important
    );
    this.renderer.setStyle(
      learn,
      'margin-left',
      '0px',
      RendererStyleFlags2.Important
    );
  }
  openCourseContent() {
    const content = document.querySelector('.course-content');
    this.renderer.setStyle(
      content,
      'width',
      '25%',
      RendererStyleFlags2.Important
    );

    const learn = document.querySelector('.learn-content');
    this.renderer.setStyle(
      learn,
      'width',
      '75%',
      RendererStyleFlags2.Important
    );
    this.renderer.setStyle(
      learn,
      'margin-left',
      '26%',
      RendererStyleFlags2.Important
    );
  }

  enroll() {
    this.enrolling = true;
    // this.courseService
    //   .enrollCourse(this.course.courseid, this.completedunitlist)
    //   .subscribe(
    //     (res: any) => {
    //       if (res.returncode == '300') {
    //         this.enrolling = false;
    //         let currentUrl =
    //           'courses/' +
    //           this.course.courseid +
    //           '/lectures/' +
    //           this.lecture.unitid;
    //         console.log(currentUrl);
    //         this.router
    //           .navigateByUrl('/', { skipLocationChange: true })
    //           .then(() => {
    //             this.router.navigateByUrl(currentUrl);
    //           });
    //       } else {
    //         this.enrolling = false;
    //         this.messageService.openSnackBar(res.status || res.message, 'warn');
    //       }
    //     },
    //     (err) => {
    //       this.enrolling = false;
    //     }
    //   );
  }


  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc =
      'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg';
    source.src = imgSrc;
  }
}
