import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Profile } from '../../shared/models/Profile';

import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-change-profile',
  templateUrl: './change-profile.component.html',
  styleUrls: ['./change-profile.component.scss']
})
export class ChangeProfileComponent implements OnInit {

  isStillUpdating: boolean = false;
  gettingProfiles: boolean = false;

  profiles: Profile[] = [];

  primaryInstructor = {
    userid: "H w y",
    username : "username",
    institute: 'paradis island',
    profileimage: '',
    profileid: '1', 
  }

  

  profileid: string = '';
  courseid: string = '';

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private dialogRef: MatDialogRef<ChangeProfileComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { 
    this.profileid = data.profileid
    this.courseid = data.courseid
  }

  ngOnInit(): void {
    this.getProfiles();
  }

  getProfiles(){
    this.gettingProfiles = true;
    this.courseService.getInstructorProfiles().subscribe((res: any) => {
      if(res.returncode == '300'){
        this.primaryInstructor = res.maininstructor;
        this.primaryInstructor.profileid = '';
        this.profiles = [this.primaryInstructor, ...res.datalist];
        console.log(this.profiles);
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn')
      }
      this.gettingProfiles = false;
    }, err => {
      this.gettingProfiles = false;
    })
  }

  dismiss(){
    this.dialogRef.close()
  }

  changeProfile(profileid: string){
    this.profileid = profileid;
  }

  saveProfile(){
    this.isStillUpdating = true;
    this.dialogRef.disableClose = true;
    this.courseService.changeCourseProfile(this.profileid, this.courseid).subscribe((res: any) => {
      if(res.returncode == '300'){
        const profile = this.profiles.find((x: any) => x.profileid == this.profileid);
        this.dialogRef.close(profile);
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.dialogRef.disableClose = false;
      this.isStillUpdating = false;
    }, err => {
      this.dialogRef.disableClose = false;
      this.isStillUpdating = false;
    })
  }

}
