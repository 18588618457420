<section class="container-fluid">
    <div class="content">
        <ng-container>
            <div class="admin-title mt-3">
                Settings
            </div>
            <div class="row g-0">
                <ng-container *ngFor="let item of menus; let index = index">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12 p-2 ps-0">
                        <div class="card d-flex p-2 custom-card" (click)="goTo(item)">
                            <div class="mb-2">
                                <img [src]="item.icon" width="30px">
                            </div>
                            <div class="name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</section>