import { Component, OnDestroy, OnInit } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../shared/services/loading.service';
import { FileUploadComponent } from '../../shared/components/file-upload/file-upload.component';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';

@Component({
  selector: 'app-learning-track-user-add',
  templateUrl: './learning-track-user-add.component.html',
  styleUrls: ['./learning-track-user-add.component.scss']
})
export class LearningTrackUserAddComponent implements OnInit, OnDestroy {

  isAdding: boolean = false;
  isEditing: boolean = false;
  isFocus: boolean = false;
  saveLoading: boolean = false;

  formSubmitted: boolean = false;

  userList: any = [];
  searchText: string = "";
  showUser: MatTableDataSource<any> = new MatTableDataSource(
    this.userList
  );
  displayedUserColumns: any;

  duration : any = '';
  learningpathid: string = '';

  isLoading: boolean = true;
  paginate: boolean = false;

  getListSub!: Subscription;
  subscriptions = new SubSink();

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  d = new Date();
  formatToShow = 'yyyy-MM-dd';
  // endDateWithD = new Date(this.d).setDate(new Date(this.d).getDate() + 5);
  // currentEndDate = formatDate(
  //   new Date(this.endDateWithD),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  currentEndDate : any = '';

  LTName : string = '';

  submitForm = {
    id: '',
    userid: '',
    startdate: this.currentDate,
    enddate: this.currentEndDate,
    remark: ''
  }

  // Import
  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;
  importData: any;
  statusInterval: any;

  constructor(
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private router: Router
  ) { 
    this.learningpathid = this.getLTId();
  }

  ngOnInit(): void {
    this.displayedUserColumns = [
      'userid',
      'username',
      'startdate',
      'enddate',
      'enrolled',
      'inprogress',
      'completed',
      'action'
    ];
    this.getUser();
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.statusInterval);
    this.subscriptions.unsubscribe();
    this.getListSub.unsubscribe();
  }

  goToCoursesByUserAnalytic(userid: any) {
    const channelid = this.getChannelId();
    const channel = channelid ? channelid : 'public';
    this.router.navigateByUrl(`hradmin/learningtrack/${channel}/${this.learningpathid}/user/${userid}`)
  }

  downloadSheet() {
    var data : any = [];
    var name = this.LTName + ' ' + 'Learning Track.xlsx';
    this.userList.map((user:any) => {
      data.push({
        'User Id' : user.userid,
        'User Name' : user.username,
        'Start Date' : user.startdate ? this.allinoneService.formatDBToShow(user.startdate) : '',
        'End Date' : user.enddate ? this.allinoneService.formatDBToShow(user.enddate) : '',
        'Enrolled' : user.enrolledcourse,
        'In Progress' : user.inprogress,
        'Completed' : user.completedcourse
      });
    });
    this.allinoneService.exportExcel(data, name);
  }

  addUser() {
    this.isAdding = true;
  }

  getUser() {
    this.isLoading = true;
    const channelid = this.getChannelId();
    const ltid = this.getLTId();
    var data = {
      channelid: channelid.toString(),
      learningpathid: ltid.toString()
    }
    this.getListSub && this.getListSub.unsubscribe();
    this.getListSub = this.courseService.getLTUser(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.userList = res.datalist;
          this.showUser = new MatTableDataSource(this.userList);
          this.duration = res.duration;
          this.LTName = res.pathname;
          var tempED = new Date(this.d).setDate(new Date(this.d).getDate() + Number(this.duration));
          this.currentEndDate = formatDate(
            new Date(tempED),
            this.formatToShow,
            'en-US'
          ).toString();
          console.log('ce');
          console.log(this.currentEndDate);
          this.submitForm.enddate = this.currentEndDate;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
      }
    )
  }

  goToLT() {
    this.router.navigateByUrl(`hradmin/learningtrack`)
  }

  saveData() {
    this.formSubmitted = true;
    if (!this.submitForm.userid || !this.submitForm.startdate || !this.submitForm.enddate) {
      return
    }

    this.saveLoading = true;

    const channelid = this.getChannelId();
    const ltid = this.getLTId();
    var data = {
      id: this.submitForm.id,
      adduser: this.submitForm.userid,
      channelid: channelid.toString(),
      startdate: this.allinoneService.formatDate(this.submitForm.startdate),
      enddate: this.allinoneService.formatDate(this.submitForm.enddate),
      remark: this.submitForm.remark,
      learningpathid: ltid.toString()
    }

    var fun = this.isEditing ?
      this.courseService.updateLTUser(data)
      : this.courseService.addLTUser(data)

    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.userList = [];
          this.showUser = new MatTableDataSource(this.userList);
          this.getUser();
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.formSubmitted = false;
      },
      (err: any) => {
        this.saveLoading = false;
        this.formSubmitted = false;
      }
    )

  }

  getChannelId() {
    const channelid = this.route.snapshot.params['channelid'];
    return channelid;
  }

  getLTId() {
    const ltid = this.route.snapshot.params['ltid'];
    return ltid;
  }

  clear() {
    this.searchText = "";
    this.showUser.filter = "";
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showUser.filter = searchItem.trim().toLowerCase();

  }

  refresh() {
    this.isLoading = true;
    this.userList = [];
    if (this.userList == undefined || this.userList.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showUser = new MatTableDataSource();
    this.getUser();
  }

  sortUser(sort: Sort) {
    const data = this.userList;
    console.log(data);

    if (!sort.active || sort.direction === '') {
      this.userList = data;
      return;
    }

    this.userList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate, b.enddate, isAsc);
        default:
          return 0;
      }
    });
    this.showUser = new MatTableDataSource(this.userList);
  }

  editUser(data: any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm = {
      id: data.id,
      userid: data.userid,
      startdate: this.allinoneService.formatDBToShowInEdit(data.startdate),
      enddate: this.allinoneService.formatDBToShowInEdit(data.enddate),
      remark: data.remark
    }
  }

  deleteUser(d: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.isLoading = true;
        const cid = this.getChannelId();
        const ltid = this.getLTId();
        const data = {
          id: d.id,
          adduser: d.userid,
          channelid: cid.toString(),
          learningpathid: ltid.toString()
        }
        this.courseService.deleteLTUser(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.userList.findIndex(
                (x: any) => x.id == d.id
              );
              this.userList.splice(i, 1);
              this.showUser = new MatTableDataSource(this.userList);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.isLoading = false;
            this.loadingService.hide();
          },
          (err) => {
            this.isLoading = false;
            this.loadingService.hide();
          }
        )
      }
    })
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    const channelid = this.getChannelId();
    var data = {
      // *** check type and check data
      type: '003',
      channelid: channelid.toString(),
      learningpathid: this.learningpathid

    };
    this.courseService.getImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            this.refresh();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  importSheet(isError: boolean = false) {
    const channelid = this.getChannelId();
    const LTid = this.getLTId();
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        // *** check data
        isError: isError,
        errorData: this.importData,
        type: '003',
        channelid: channelid.toString(),
        learningpathid: LTid.toString()
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      // clearInterval(this.statusInterval);
      this.getImportStatus();
      if(dialogResult.success) {
        this.getUser();
      }
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.saveLoading = false;
    this.submitForm = {
      id: '',
      userid: '',
      startdate: this.currentDate,
      enddate: this.currentEndDate,
      remark: ''
    }
  }

}
