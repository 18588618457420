import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-channel-qr-view',
  templateUrl: './channel-qr-view.component.html',
  styleUrls: ['./channel-qr-view.component.scss']
})
export class ChannelQrViewComponent implements OnInit {


  element!: HTMLImageElement;
  
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  channelName : string = '';
  channelID : string = '';

  test='https://www.youtube.com/watch?v=pBR01ndtids'

  constructor(
    public dialogRef: MatDialogRef<ChannelQrViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.channelName = data.channelname;
    // this.channelID = data.channelid
    this.channelID = "khub://" + data.channelid
    // this.channelID = "https://studio.khub.cloud/admin/channel/editchannel/" + data.channelid
    console.log('your id');
    console.log(this.channelID);
    
    
   }

  ngOnInit(): void {
  }

  downloadQRCode() {
    const fileNameToDownload = 'qr360';
    const htmlstring =
      document.getElementsByClassName('bshadow')[0].children[0];

    const base64Img = this.getImgSrc(htmlstring);

    fetch(base64Img)
      .then((res) => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else {
          // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      });
  }

  getImgSrc(htmlString: any) {
    this.element = <HTMLImageElement>htmlString;
    return this.element.src;
  }

}
