import { Domain } from './../../../../../../src/app/model/Domain.d';
import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  Optional,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AngularEditorConfig, UploadResponse } from '@kolkov/angular-editor';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogConfig,
} from '@angular/material/dialog';
import { HttpClient, HttpResponse, HttpEvent } from '@angular/common/http';
import { timeStamp } from 'console';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { Router } from '@angular/router';
import WebViewer from '@pdftron/webviewer';
import { DatePipe, formatDate } from '@angular/common';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LibraryViewComponent } from '../../library/library-view/library-view.component';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { LoadingService } from '../../shared/services/loading.service';
import { ImagecropComponent } from '../../channel/imagecrop/imagecrop.component';

@Component({
  selector: 'app-course-unit-editor',
  templateUrl: './course-unit-editor.component.html',
  styleUrls: ['./course-unit-editor.component.scss'],
})
export class CourseUnitEditorComponent implements OnInit {
  // @ViewChild('viewer')
  // viewerRef!: ElementRef;
  @ViewChild('viewer') viewerRef!: ElementRef;

  isExpandWP: boolean = false;

  attachmentList: any = [];

  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '10rem',
    // minHeight: '5rem',
    // height:  this.isExpandWP ? '100vh' : '10rem',
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    sanitize: true,
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        // 'fontName',
      ],
      [
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
        'customClasses',
        'insertVideo',
        'insertHorizontalRule',
        // 'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    upload: (file: File): Observable<any> => {
      return this.uploadImage(file);
    },
  };

  fontSelected: string = 'Arial';

  timeFormat = 'hh:mm';
  contenttype: any = 0;
  // currentDate = this.datepipe.transform(new Date(), 'mm/dd/yyyy')?.toString();
  // currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd')?.toString();
  // currentTime = this.datepipe.transform(new Date(), 'h:mm')?.toString();

  // currentDate = this.allinoneService.formatDBToShowInEdit(
  //   this.allinoneService.getCurrentDateToDB()
  // );

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  currentTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();

  unitForm = {
    unitid: '',
    unit_name: '',
    contenttype: this.contenttype,
    videotype: '001',
    content: '',
    sortkey: 0,
    filename: '',
    filetype: '',
    libraryid: '',
    filepath: '',
    preview: '',
    maximumAttempt: '3',
    allquestion: '3',
    showfeedback: true,
    autonumbering: true,
    allowdownload: true,
    grade: 0, // default
    targetstudytime: 0, // minutes,
    date: this.currentDate,
    time: this.currentDate,
    deadlinedate: this.currentDate,
    deadlinetime: this.currentTime,
    attachment: '',
    bygroup: false,
    groupquestionscount: '',
    grouptime: false,
    randomassignment: false,
    randomassignmentcount: 1,
    multifileupload: false,
    multifileuploadcount: 2,
  };

  maximunAtt: any;
  // filestatus:string = "";
  filestatusatt: string = '';
  url: string = '';

  videoUrlPreview: string = '';
  domainUrlPreview: string = '';
  isStillDocumentLoading: boolean = true;
  uploadImagelist: any = [];

  uploadFilelist: any = []; // Pdf,ppt,doc...

  subsectionid: any;
  unit: any = [];
  unit_total_length: number = 0;
  action_status: string = '';
  courseid: string = '';

  oldgrademark: any = [];

  isStillUpload: boolean = false;
  isStillFileUpload: boolean = false;
  unitFormSubmitted: boolean = false;
  choosecontentTyperequired: boolean = false;
  unitNameControl = new FormControl();

  fileSelected?: any;
  base64?: string;
  documentUrlPreview: string = '';
  dialogId: string = '';
  loadingpdf: boolean = false;
  question: boolean = true;

  constructor(
    private formBuider: FormBuilder,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CourseUnitEditorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private router: Router,
    public domSanitizer: DomSanitizer,
    public renderer2: Renderer2,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private loadingService: LoadingService
  ) {
    this.dialogRef.updateSize('600px');
    console.log('your data');
    console.log(data);
    this.courseid = data.courseid;
    this.subsectionid = data.subsectionid;
    this.action_status = data.action_status;
    this.unit = data.unit;
    this.unit_total_length = data.unit_total_length;
    if (this.action_status == 'new') {
      this.unitForm.contenttype = 0;
      this.unitForm.sortkey = this.unit_total_length;
    } else {
      if (this.unit.libraryid == '') {
        this.unitForm.preview = this.allinoneService.docurl + this.unit.content;
      } else {
        this.unitForm.preview = this.unit.downloadurl;
        if (this.unit.contenttype == 1) {
          this.unitForm.videotype = '002';
        }
      }

      // ***
      // if (this.unit.contenttype == 6) {
      //   this.unitForm.content = 'content';
      // } else {
      //   this.unitForm.content = this.unit.content;
      // }

      this.unitForm.content = this.unit.content;

      this.unitForm.contenttype = this.unit.contenttype;
      this.unitForm.libraryid = this.unit.libraryid;

      this.unitForm.unit_name = this.unit.unit_name;
      this.unitForm.sortkey = this.unit.sortkey;
      // this.unitForm.filename = this.unit.content;
      console.log('***///');

      // this.attachmentList = this.unit.attachmentlist ? this.unit.attachmentlist : [];
      if (this.unit.attachmentlist) {
        for (var f = 0; f < this.unit.attachmentlist.length; f++) {
          this.attachmentList.push({
            filename: this.unit.attachmentlist[f].libraryid
              ? this.unit.attachmentlist[f].libraryfilename
                ? this.unit.attachmentlist[f].libraryfilename
                : this.unit.attachmentlist[f].filename
              : this.unit.attachmentlist[f].filename,
            // filename: this.unit.attachmentlist[f].libraryid ? this.unit.attachmentlist[f].libraryfilename : this.unit.attachmentlist[f].filename,
            libraryid: this.unit.attachmentlist[f].libraryid,
          });
        }
      } else {
        this.attachmentList = [];
      }

      this.unitForm.filename =
        this.unit.contenttype == 7 || this.unit.contenttype == 8
          ? this.unit.libraryid
            ? this.unit.libraryfilename
            : this.unit.attachment
          : this.unit.content;
      if (this.unit.quizconfig) {
        this.question = false;
      } else {
        this.question = true;
      }
      this.unitForm.allquestion = this.unit.quizconfig
        ? this.unit.quizconfig
        : '3';
      this.unitForm.maximumAttempt = this.unit.maxattempt
        ? this.unit.maxattempt
        : '3';
      this.unitForm.filetype = this.unit.content.split('.').pop();
      this.unitForm.grade = parseInt(this.unit.grade) || 0;
      this.oldgrademark = parseInt(this.unit.grade) || 0;
      this.unitForm.targetstudytime = parseInt(this.unit.studytime) || 0;
      this.unitForm.deadlinedate = this.unit.deadlinedate
        ? this.allinoneService.formatDBToShowInEdit(this.unit.deadlinedate) ||
          ''
        : '';
      this.unitForm.deadlinetime = this.unit.deadlinetime
        ? this.allinoneService.f12to24(this.unit.deadlinetime) || ''
        : '';
      this.unitForm.attachment = this.unit.libraryid
        ? this.unit.libraryfilename
        : this.unit.attachment;
      this.unitForm.showfeedback = this.unit.showfeedback;
      this.unitForm.autonumbering = this.unit.autonumbering;
      this.unitForm.allowdownload = this.unit.allowdownload;
      this.unitForm.bygroup = this.unit.bygroup;
      this.unitForm.groupquestionscount = this.unit.groupquestionscount;
      this.unitForm.grouptime = this.unit.grouptime;
      this.unitForm.randomassignment = this.unit.randomassignment;
      this.unitForm.randomassignmentcount = parseInt(
        this.unit.randomassignmentcount || 1
      );
      this.unitForm.multifileupload = this.unit.multifileupload;
      this.unitForm.multifileuploadcount = parseInt(this.unit.multifileuploadcount || 2);

      // this.attachmentList =

      this.checkVideoUrl();
      this.checkDoaminUrl();
    }
  }

  ngOnInit(): void {
    console.log('Your unit form');

    console.log(this.unitForm);
  }

  ngAfterViewInit() {
    if (this.unitForm.contenttype == 0) {
      this.dialogRef.updateSize('600px');
    } else if (
      this.unitForm.contenttype == 3 ||
      this.unitForm.contenttype == 7 ||
      this.unitForm.contenttype == 6 ||
      this.unitForm.contenttype == 8
    ) {
      this.dialogRef.updateSize('70vw');
    } else if (this.unitForm.contenttype != 0) {
      this.dialogRef.updateSize('500px');
    }
  }

  expandWriteUp() {
    this.isExpandWP = true;
    this.config.height = '85vh';
    this.dialogRef.updateSize('100vw', '100vh');
  }

  compressWriteUp() {
    this.isExpandWP = false;
    this.config.height = '10rem';
    this.dialogRef.updateSize('70vw');
  }

  chooseType(type: number) {
    if (type == 9) {
      this.messageService.openSnackBar(
        'This feature is not available yet.',
        'info'
      );
      return;
    }

    if (this.unitForm.contenttype == type) {
      return;
    }
    this.unitFormSubmitted = false;
    this.videoUrlPreview = '';
    this.domainUrlPreview = '';

    this.unitForm = {
      unitid: this.unitForm.unitid,
      unit_name: this.unitForm.unit_name,
      contenttype: type,
      videotype: '001',
      content: '',
      sortkey: this.unitForm.sortkey,
      filename: '',
      filetype: '',
      filepath: '',
      libraryid: '',
      preview: '',
      maximumAttempt: this.unitForm.maximumAttempt,
      allquestion: this.unitForm.allquestion,
      grade: this.unitForm.grade,
      showfeedback: this.unitForm.showfeedback,
      autonumbering: this.unitForm.autonumbering,
      allowdownload: this.unitForm.allowdownload,
      targetstudytime: this.unitForm.targetstudytime,
      date: this.unitForm.date,
      time: this.unitForm.time,
      deadlinedate: this.unitForm.deadlinedate,
      deadlinetime: this.unitForm.deadlinetime,
      attachment: this.unitForm.attachment,
      bygroup: this.unitForm.bygroup,
      groupquestionscount: this.unitForm.groupquestionscount,
      grouptime: this.unitForm.grouptime,
      randomassignment: this.unitForm.randomassignment,
      randomassignmentcount: this.unitForm.randomassignmentcount,
      multifileupload: this.unitForm.multifileupload,
      multifileuploadcount: this.unitForm.multifileuploadcount,
    };

    if (type == 2) {
      this.unitForm.contenttype = type;
      this.dialogRef.updateSize('500px');
    } else if (type == 10) {
      this.unitForm.contenttype = type;
      this.dialogRef.updateSize('500px');
    } else if (type == 3 || type == 7 || type == 8 || type == 6) {
      this.dialogRef.updateSize('70vw');
    } else if (type == 4) {
      this.dialogRef.updateSize('500px');
    } else if (type == 5) {
      this.dialogRef.updateSize('500px');
      this.unitForm.contenttype = type;
    } else if (type == 1) {
      this.dialogRef.updateSize('500px');
    } else {
      this.dialogRef.updateSize('600px');
    }
  }

  async attFile(event: any) {
    this.chooseType(5);
    if (event.target.files[0].size > 1000 * 1000 * 1024) {
      this.messageService.openSnackBar('Maximum file size is 1 Gb.', 'warn');
      return;
    }
    this.filestatusatt = '1';
    this.fileSelected = event.target.files[0];
    const filename = event.target.files[0].name.toString();
    this.unitForm.filename = filename.substring(0, filename.lastIndexOf('.'));
    this.unitForm.filename = filename.substring(0, filename.lastIndexOf('.'));
    let name = this.unitForm.filename.replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    this.unitForm.filename = name + '_' + time;
    this.unitForm.filetype = filename.split('.').pop();
    this.unitForm.filename =
      this.unitForm.filename + '.' + this.unitForm.filetype;
    console.log(this.unitForm.libraryid + '...' + this.dialogId);
    this.unitForm.libraryid = '';
    this.dialogId = '';
    this.unitForm.content = this.unitForm.filename.toString();
    this.unitForm.filepath = 'references/' + this.unitForm.filename;
    this.loadingpdf = true;

    if (this.unitForm.filetype != 'pdf') {
      this.unitForm.allowdownload = true;
    }

    setTimeout(() => {
      this.loadingpdf = false;
    }, 100);
  }

  async attFileForDisscussion(event: any, type: number) {
    this.chooseType(type);
    if (event.target.files[0].size > 1000 * 1000 * 1024) {
      this.messageService.openSnackBar('Maximum file size is 1 Gb.', 'warn');
      return;
    }
    this.filestatusatt = '1';
    this.fileSelected = event.target.files[0];
    const filename = event.target.files[0].name.toString();
    this.unitForm.filename = filename.substring(0, filename.lastIndexOf('.'));
    this.unitForm.filename = filename.substring(0, filename.lastIndexOf('.'));
    let name = this.unitForm.filename.replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    this.unitForm.filename = name + '_' + time;
    this.unitForm.filetype = filename.split('.').pop();
    this.unitForm.filename =
      this.unitForm.filename + '.' + this.unitForm.filetype;
    console.log(this.unitForm.libraryid + '...' + this.dialogId);
    this.unitForm.libraryid = '';
    this.dialogId = '';
    // this.unitForm.content = this.unitForm.filename.toString();
    this.unitForm.attachment = this.unitForm.filename.toString();
    this.unitForm.filepath = 'references/' + this.unitForm.filename;
    this.loadingpdf = true;
    if (this.unitForm.filetype != 'pdf') {
      this.unitForm.allowdownload = true;
    }
    setTimeout(() => {
      this.loadingpdf = false;
    }, 100);
  }

  t() {
    console.log(this.attachmentList);
    console.log(this.attachmentList.length);
  }

  async attFileForAssignment(event: any, type: number) {
    this.chooseType(type);
    if (event.target.files[0].size > 1000 * 1000 * 1024) {
      this.messageService.openSnackBar('Maximum file size is 1 Gb.', 'warn');
      return;
    }

    // if (this.attachmentList.length < 3) {
    this.filestatusatt = '1';
    var filename = '';
    var filepath = '';
    var tempfilename = '';
    var tempfiletype = '';
    var fileSelected = '';
    fileSelected = event.target.files[0];
    var filename1 = event.target.files[0].name.toString();
    tempfilename = filename1.substring(0, filename1.lastIndexOf('.'));
    tempfilename = filename1.substring(0, filename1.lastIndexOf('.'));
    let name = tempfilename.replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    tempfilename = name + '_' + time;
    tempfiletype = filename1.split('.').pop();
    tempfilename = tempfilename + '.' + tempfiletype;
    this.unitForm.libraryid = '';
    this.dialogId = '';
    filename = tempfilename.toString();
    filepath = 'references/' + tempfilename;
    this.attachmentList.push({
      fileselected: fileSelected,
      filename: filename,
      libraryid: this.unitForm.libraryid,
    });
    this.loadingpdf = true;
    setTimeout(() => {
      this.loadingpdf = false;
    }, 100);
    // }
  }

  removeFile() {
    this.unitForm.filename = '';
    this.unitForm.filetype = '';
    this.unitForm.filepath = '';
    this.unitForm.attachment = '';
    this.fileSelected = '';
    this.filestatusatt = '';
    this.unitForm.libraryid = '';
    this.unitForm.allowdownload = true;
  }

  removeAttachmentFile(index: any) {
    this.attachmentList.splice(index, 1);
  }

  chooseFromLibraryAttForDisscussion() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: true,
        isvideo: false,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.success == true) {
        this.unitForm.filename = dialogResult.data.filename;
        this.unitForm.filepath = dialogResult.data.filepath;
        this.unitForm.filetype = dialogResult.data.filetype;
        // this.unitForm.attachment = dialogResult.data.filename;
        this.unitForm.attachment = '';
        this.unitForm.libraryid = dialogResult.data.id;
        this.unitForm.unit_name = this.unitForm.unit_name
          ? this.unitForm.unit_name
          : dialogResult.data.filedescription;

        this.dialogId = dialogResult.data.id;
        // if (this.unitForm.contenttype == 2) {
        this.loadingpdf = true;
        setTimeout(() => {
          this.loadingpdf = false;
        }, 100);
        // }

        console.log(this.dialogId);
        this.filestatusatt = '2';

        if (this.unitForm.filetype != 'pdf') {
          this.unitForm.allowdownload = true;
        }
      }
    });

    console.log('***/////');
    console.log(this.unitForm.content);
  }

  chooseFromLibraryAttForAssign() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: true,
        isvideo: false,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.success == true) {
        // if (this.attachmentList.length < 3) {
        var tempfilename = dialogResult.data.filename;
        var tempfilepath = dialogResult.data.filepath;
        var tempfiletype = dialogResult.data.filetype;
        var templibraryid = dialogResult.data.id;
        this.unitForm.attachment = '';
        this.attachmentList.push({
          filename: tempfilename,
          libraryid: templibraryid,
        });
        this.loadingpdf = true;
        setTimeout(() => {
          this.loadingpdf = false;
        }, 100);
        // }
      }
    });

    console.log('***/////');
    console.log(this.unitForm.content);
  }

  chooseFromLibraryAtt() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: true,
        isvideo: false,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.success == true) {
        this.unitForm.filename = dialogResult.data.filename;
        this.unitForm.filepath = dialogResult.data.filepath;
        this.unitForm.filetype = dialogResult.data.filetype;
        this.unitForm.content = dialogResult.data.filename;
        this.unitForm.libraryid = dialogResult.data.id;
        this.unitForm.unit_name = this.unitForm.unit_name
          ? this.unitForm.unit_name
          : dialogResult.data.filedescription;

        this.dialogId = dialogResult.data.id;
        // if (this.unitForm.contenttype == 2) {
        this.loadingpdf = true;
        setTimeout(() => {
          this.loadingpdf = false;
        }, 100);
        // }

        console.log(this.dialogId);
        this.filestatusatt = '2';
      }
    });
  }
  async onFileSelected(event: any) {
    this.chooseType(2);
    this.isStillFileUpload = true;
    console.log(event.target.files[0]);
    this.fileSelected = event.target.files[0];
    const filename = event.target.files[0].name.toString();
    // this.documentUrlPreview = URL.createObjectURL(event.target.files[0]);

    this.unitForm.filename = filename.substring(0, filename.lastIndexOf('.'));
    let name = this.unitForm.filename.replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    this.unitForm.filename = name + '_' + time;
    this.unitForm.filetype = filename.split('.').pop();
    this.unitForm.filename =
      this.unitForm.filename + '.' + this.unitForm.filetype;
    this.unitForm.libraryid = '';
    // this.filestatus = "1";
   
    const fileUpload = await this.allinoneService.fileUploadToS3WithPath(
      this.fileSelected,
      this.unitForm.filename,
      'references'
    );
    console.log('......');
    console.log(fileUpload)
    
    if (fileUpload == false) {
      this.messageService.openSnackBar(
        'Error while uploading your file.',
        'warn'
      );
      this.isStillFileUpload = false;
      // this.filestatus = "";
      this.fileSelected = null;
      this.unitForm.filename = '';
      return;
    }

    this.uploadFilelist.push(this.unitForm.filename);
    this.unitForm.content = this.unitForm.filename.toString();
    this.unitForm.libraryid = '';
    console.log('libraryid fileselected');
    this.dialogId = '';
    console.log(this.unitForm.libraryid + '...' + this.dialogId);
    console.log(this.unitForm.content);
    this.unitForm.filepath = 'references/' + this.unitForm.filename;
    this.unitForm.preview =
      this.allinoneService.docurl + this.unitForm.filename;

    this.isStillFileUpload = false;
    this.loadingpdf = true;
    setTimeout(() => {
      this.loadingpdf = false;
    }, 100);
   
  }
  async audioFileSelected(event: any) {
    const filename = event.target.files[0].name.toString();
    // check audio
    var audioornot = filename.split('.').pop();

    if (audioornot?.toLowerCase() != 'mp3') {
      this.messageService.openSnackBar('Incorrect file type.', 'warn');
      return;
    }

    this.chooseType(10);
    this.isStillFileUpload = true;
    console.log(event.target.files[0]);
    this.fileSelected = event.target.files[0];

    // this.documentUrlPreview = URL.createObjectURL(event.target.files[0]);

    this.unitForm.filename = filename.substring(0, filename.lastIndexOf('.'));
    let name = this.unitForm.filename.replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    this.unitForm.filename = name + '_' + time;
    this.unitForm.filetype = filename.split('.').pop();
    this.unitForm.filename =
      this.unitForm.filename + '.' + this.unitForm.filetype;
    this.unitForm.libraryid = '';
    // this.filestatus = "1";

    
    const fileUpload = await this.allinoneService.fileUploadToS3WithPath(
      this.fileSelected,
      this.unitForm.filename,
      'references'
    );
    console.log('......');
    console.log(fileUpload);
    if (fileUpload == false) {
      this.messageService.openSnackBar(
        'Error while uploading your file.',
        'warn'
      );
      this.isStillFileUpload = false;
      // this.filestatus = "";
      this.fileSelected = null;
      this.unitForm.filename = '';
      return;
    }
   
  }

  chooseFromLibraryVideo() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: false,
        isvideo: true,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.success == true) {
        console.log(dialogResult);
        this.unitForm.filename = dialogResult.data.filename;
        this.unitForm.filepath = dialogResult.data.filepath;
        this.unitForm.filetype = dialogResult.data.filetype;
        this.unitForm.content = dialogResult.data.filename;
        this.unitForm.preview = dialogResult.data.downloadurl;
        this.unitForm.libraryid = dialogResult.data.id;
        this.dialogId = dialogResult.data.id;
        this.unitForm.unit_name = this.unitForm.unit_name
          ? this.unitForm.unit_name
          : dialogResult.data.filedescription;
        this.checkVideoUrl();
      }
    });
  }

  chooseFromLibrary() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: false,
        isvideo: false,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      console.log('============= >');
      console.log(dialogResult);
      if (dialogResult.success == true) {
        this.unitForm.filename = dialogResult.data.filename;
        this.unitForm.filepath = dialogResult.data.filepath;
        this.unitForm.filetype = dialogResult.data.filetype;
        this.unitForm.content = dialogResult.data.filename;
        this.unitForm.preview = dialogResult.data.downloadurl;
        this.unitForm.libraryid = dialogResult.data.id;
        this.dialogId = dialogResult.data.id;
        this.unitForm.unit_name = this.unitForm.unit_name
          ? this.unitForm.unit_name
          : dialogResult.data.filedescription;
        this.loadingpdf = true;
        setTimeout(() => {
          this.loadingpdf = false;
        }, 100);
        // this.filestatus = "2";
      }
    });
  }

  chooseAudioFromLibrary() {
    const dialog = this.dialog.open(LibraryViewComponent, {
      minWidth: '80vw',
      height: '90vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isattachment: false,
        isvideo: false,
        isaudio: true,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      console.log('============= >');
      console.log(dialogResult);
      if (dialogResult.success == true) {
        this.unitForm.filename = dialogResult.data.filename;
        this.unitForm.filepath = dialogResult.data.filepath;
        this.unitForm.filetype = dialogResult.data.filetype;
        this.unitForm.content = dialogResult.data.filename;
        this.unitForm.preview = dialogResult.data.downloadurl;
        this.unitForm.libraryid = dialogResult.data.id;
        this.dialogId = dialogResult.data.id;
        this.unitForm.unit_name = this.unitForm.unit_name
          ? this.unitForm.unit_name
          : dialogResult.data.filedescription;
        this.loadingpdf = true;
        setTimeout(() => {
          this.loadingpdf = false;
        }, 100);
        // this.filestatus = "2";
      }
    });
  }

  convertFileToBase64() {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(this.fileSelected as Blob);
      reader.onloadend = () => {
        this.unitForm.content = reader.result as string;
        resolve('');
      };
    });
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  uploadImage(file: any) {
    var filetype = file.name.split('.').pop();
    var filename = file.name
      .substring(0, file.name.lastIndexOf('.'))
      .replace(/\s/g, '');
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    filename = filename + '_' + time + '.' + filetype;

    const changeEvent = new Event('change', { bubbles: true });

    // Attach the file to the event object
    const fileevent = Object.defineProperty(changeEvent, 'target', {
      value: { files: [file] },
      writable: false,
    });
    const dialog = this.dialog.open(ImagecropComponent, {
      maxWidth: '90vw',
      maxHeight: '80vh',
      width: '500px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        image: fileevent,
        name: filename,
      },
    });
    console.log(file);
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.isStillFileUpload = true;
        console.log('cropimage');
        console.log(dialogResult);

     

        this.allinoneService
          .fileUploadToS3WithPathForImage(dialogResult.file, filename, 'images')
          .then(
            () => {
              this.unitForm.content =
                this.unitForm.content +
                `<img width="auto" height="auto" style="max-width: 400px; max-height: 400px;" class="mb-2 unitimg" src='${
                  this.allinoneService.imgurl + filename
                }'>`;
              this.uploadImagelist.push(filename);

              console.log(this.unitForm.content);
              this.isStillFileUpload = false;
            },
            () => {
              this.messageService.openSnackBar(
                'Error while uploading your file.',
                'warn'
              );
              this.isStillFileUpload = false;
            }
          );
      }
    });

    return this.http.options('');
  }

  checkDoaminUrl() {
    if (this.unitForm.contenttype == 4) {
      if (this.unitForm.content.toString().trim() == '') {
        this.domainUrlPreview = '';
      } else if (this.unitForm.content.toString().startsWith('https://')) {
        this.domainUrlPreview = this.unitForm.content;
      } else {
        this.domainUrlPreview = 'https://' + this.unitForm.content;
      }
    }
  }

  checkVideoUrl() {
    if (this.unitForm.contenttype == 1) {
      //No video

      // var reg = new RegExp("^t\=+[0-9]+s$");

      if (this.unitForm.videotype == '001') {
        if (this.unitForm.content.toString().trim() == '') {
          this.videoUrlPreview = '';
        }
        //youtube
        else if (
          this.unitForm.content.toString().startsWith('https://youtu.be/')
        ) {
          this.videoUrlPreview =
            'https://www.youtube.com/embed/' +
            this.unitForm.content
              .split('/')
              .pop()
              ?.replace('&', '?')
              .replace('t=', 'start=');
          if (
            this.videoUrlPreview.includes('start=') &&
            this.videoUrlPreview.endsWith('s')
          ) {
            this.videoUrlPreview = this.videoUrlPreview.substring(
              0,
              this.videoUrlPreview.length - 1
            );
          }
        } else if (
          this.unitForm.content.toString().startsWith('https://www.youtube.com')
        ) {
          this.videoUrlPreview =
            'https://www.youtube.com/embed/' +
            this.unitForm.content
              .split('v=')
              .pop()
              ?.replace('&', '?')
              .replace('t=', 'start=');
          if (
            this.videoUrlPreview.includes('start=') &&
            this.videoUrlPreview.endsWith('s')
          ) {
            this.videoUrlPreview = this.videoUrlPreview.substring(
              0,
              this.videoUrlPreview.length - 1
            );
          }
        } else {
          //vimeo
          // https://vimeo.com/568270540
          // https://player.vimeo.com/video/568270540
          this.videoUrlPreview =
            'https://player.vimeo.com/video/' +
            this.unitForm.content.split('/').pop();
        }
      } else {
        if (this.unitForm.preview.toString().trim() == '') {
          this.videoUrlPreview = '';
        } else {
          this.videoUrlPreview = this.unitForm.preview;
        }
      }

      console.log(this.videoUrlPreview);
    }
  }
  documentLoaded() {
    this.isStillDocumentLoading = false;
  }

  deadlineclear() {
    this.unitForm.deadlinedate = '';
    this.unitForm.deadlinetime = '';
  }

  dismiss() {
    if (this.uploadFilelist.length > 0) {
      this.courseService
        .deleteUnitUploadedFile(this.uploadFilelist)
        .subscribe((res: any) => {});
    }

    if (this.uploadImagelist.length > 0) {
      this.courseService
        .deleteUnitUploadedFile(this.uploadImagelist)
        .subscribe((res: any) => {});
    }
    this.dialogRef.close();
  }

  async addUnit() {
    console.log('this fun');
    console.log(this.unitForm.attachment);

    var attachmentDataList: any = [];
    this.unitFormSubmitted = true;
    if (this.unitForm.contenttype == 0) {
      this.choosecontentTyperequired = true;
      this.messageService.openSnackBar('Choose your content type', 'warn');
      return;
    } else if (this.unitForm.contenttype == 9) {
      // else if(this.unitForm.contenttype == 8 || this.unitForm.contenttype == 9 ){
      this.unitFormSubmitted = false;
      this.messageService.openSnackBar(
        'This feature is not available yet.',
        'info'
      );
      return;
    }

    if (this.unitForm.grade < 0) {
      this.messageService.openSnackBar('Grade should be at least 0.', 'warn');
      return;
    }

    if (this.unitForm.targetstudytime < 0) {
      this.messageService.openSnackBar(
        'Study Time should be at least 0.',
        'warn'
      );
      return;
    } else {
      this.unitForm.targetstudytime =
        this.unitForm.targetstudytime == null
          ? 0
          : this.unitForm.targetstudytime;
    }

    if (this.unitForm.contenttype == 6) {
      if (
        this.unitForm.maximumAttempt == null ||
        this.unitForm.maximumAttempt.toString().trim() == '' ||
        parseInt(this.unitForm.maximumAttempt.toString().trim()) < 1
      ) {
        this.messageService.openSnackBar(
          'Maximum Attempt should be at least 1!',
          'warn'
        );
        return;
      }
      if (
        (this.unitForm.groupquestionscount == null ||
          this.unitForm.groupquestionscount.toString().trim() == '' ||
          parseInt(this.unitForm.groupquestionscount.toString().trim()) < 1) &&
        this.unitForm.bygroup
      ) {
        this.messageService.openSnackBar(
          'Question Count should be at least 1!',
          'warn'
        );
        return;
      }
      if (this.question == false) {
        if (this.unitForm.allquestion.toString().trim() == '') {
          return;
        }
        if (parseInt(this.unitForm.allquestion.toString().trim()) < 1) {
          this.messageService.openSnackBar(
            'No. of questiona should be at least 1!',
            'warn'
          );
          return;
        }
      }
    }

    if (this.unitForm.contenttype == 7) {
      if (
        this.unitForm.deadlinedate != '' &&
        this.unitForm.deadlinetime == ''
      ) {
        this.messageService.openSnackBar(
          'Deadline time cannot be blank.',
          'warn'
        );
        return;
      }

      if (
        this.unitForm.deadlinedate == '' &&
        this.unitForm.deadlinetime != ''
      ) {
        this.messageService.openSnackBar(
          'Deadline date cannot be blank.',
          'warn'
        );
        return;
      }
      if (this.unitForm.randomassignment && this.attachmentList.length == 0) {
        this.messageService.openSnackBar(
          `Please attach the relevant document or file.`,
          'warn'
        );
        return;
      }
      if (
        this.unitForm.randomassignment &&
        this.unitForm.randomassignmentcount == 0
      ) {
        this.messageService.openSnackBar(
          'Random Assignment Count should be at least 1!',
          'warn'
        );
        return;
      }

      if (
        this.unitForm.randomassignment &&
        this.unitForm.randomassignmentcount > this.attachmentList.length
      ) {
        this.messageService.openSnackBar(
          'More random assignment count than available attachments.',
          'warn'
        );
        return;
      }
    }

    if (this.unitForm.contenttype == 1 && this.unitForm.videotype == '001') {
      if (
        this.unitForm.content.toString().startsWith('https://youtu.be/') ||
        this.unitForm.content.toString().startsWith('https://vimeo.com/') ||
        this.unitForm.content.toString().startsWith('https://www.youtube.com')
      ) {
      } else {
        this.messageService.openSnackBar('Video url is not valid.', 'warn');
        return;
      }
    } else if (
      this.unitForm.contenttype == 1 &&
      this.unitForm.videotype == '002' &&
      this.unitForm.libraryid == ''
    ) {
      return this.messageService.openSnackBar(
        'Please choose a video from library.',
        'warn'
      );
    } else if (this.unitForm.contenttype == 4) {
      if (!this.unitForm.content.toString().startsWith('https://')) {
        this.messageService.openSnackBar('Url is not valid.', 'warn');
        return;
      }
    }

    if (this.unitForm.content == '' && this.unitForm.contenttype != 6) {
      console.log('here return');

      return;
    } else {
      if (
        this.unitForm.contenttype == 7 &&
        this.unitForm.grade != this.oldgrademark &&
        this.action_status == 'edit'
      ) {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
          // maxWidth: '400px',
          closeOnNavigation: true,
          scrollStrategy: new NoopScrollStrategy(),
          data: {
            message: 'Are you sure to change the grade?',
          },
        });
        dialog.afterClosed().subscribe(async (dialogResult: any) => {
          if (dialogResult) {
            // this.messageService.openSnackBar(
            //   'Assignment grade changed!',
            //   'warn'
            // );
            this.isStillUpload = true;
            this.dialogRef.disableClose = true;
            // upload multiple file for attachment
            if (this.unitForm.contenttype == 7 && this.filestatusatt == '1') {
              for (var i = 0; i < this.attachmentList.length; i++) {
                if (this.attachmentList[i].fileselected) {

                  
            
                  const fileUpload =
                    await this.allinoneService.fileUploadToS3WithPath(
                      this.attachmentList[i].fileselected,
                      this.attachmentList[i].filename,
                      'references'
                    );
                  if (fileUpload == false) {
                    this.messageService.openSnackBar(
                      'Error while uploading your file.',
                      'warn'
                    );
                    this.isStillFileUpload = false;
                    return;
                  }
                }
              }
            }

            if (this.unitForm.contenttype == 7) {
              for (var i = 0; i < this.attachmentList.length; i++) {
                attachmentDataList.push({
                  filename: this.attachmentList[i].filename,
                  libraryid: this.attachmentList[i].libraryid
                    ? this.attachmentList[i].libraryid
                    : '',
                });
              }
            }
            this.courseService
              .updateUnit(
                this.courseid,
                this.unit.unitid,
                this.unitForm.unit_name.toString().trim(),
                this.subsectionid,
                this.unitForm.sortkey,
                this.unitForm.contenttype,
                this.unitForm.contenttype == 7 ||
                  this.unitForm.contenttype == 8 ||
                  this.unitForm.contenttype == 6
                  ? this.unitForm.content
                  : this.dialogId != ''
                  ? ''
                  : this.unitForm.libraryid != ''
                  ? ''
                  : this.unitForm.content.toString().trim(),
                this.unitForm.filename,
                this.unitForm.filetype,
                this.uploadFilelist,
                this.uploadImagelist,
                this.unitForm.filepath,
                this.unitForm.libraryid,
                this.unitForm.maximumAttempt.toString().trim(),
                this.question
                  ? ''
                  : this.unitForm.allquestion.toString().trim(),
                this.unitForm.grade.toString(),
                this.unitForm.targetstudytime.toString(),
                // this.unitForm.deadlinedate,
                // this.unitForm.deadlinetime.toString(),
                this.unitForm.deadlinedate
                  ? this.allinoneService.formatDate(this.unitForm.deadlinedate)
                  : '',
                this.unitForm.contenttype == 7
                  ? this.unitForm.deadlinetime
                    ? this.allinoneService.f24to12(this.unitForm.deadlinetime)
                    : ''
                  : '',
                this.unitForm.attachment,
                this.unitForm.showfeedback,
                this.unitForm.allowdownload,
                this.unitForm.autonumbering,
                this.unitForm.bygroup,
                this.unitForm.groupquestionscount,
                this.unitForm.grouptime,
                attachmentDataList,
                this.unitForm.randomassignment,
                this.unitForm.randomassignmentcount,
                this.unitForm.multifileupload,
                this.unitForm.multifileuploadcount,
              )
              .subscribe(
                (res: any) => {
                  console.log('1');

                  if (res.returncode == '300') {
                    res.data.content = this.unitForm.content;
                    res.data.downloadurl = this.unitForm.preview;
                    res.data.libraryid = this.unitForm.libraryid;
                    res.data.grade = this.unitForm.grade.toString();
                    res.data.studytime =
                      this.unitForm.targetstudytime.toString();
                    res.data.attachment = this.unitForm.attachment;

                    res.data.libraryfilename = this.unitForm.filename;
                    res.data.showfeedback = this.unitForm.showfeedback;
                    res.data.autonumbering = this.unitForm.autonumbering;
                    res.data.allowdownload = this.unitForm.allowdownload;
                    res.data.bygroup = this.unitForm.bygroup;
                    res.data.groupquestionscount =
                      this.unitForm.groupquestionscount;
                    res.data.grouptime = this.unitForm.grouptime;

                    this.dialogRef.close({
                      data: res.data,
                      action_status: this.action_status,
                    });
                  } else {
                    if (res.returncode == '210') {
                      setTimeout(() => {
                        this.router.navigateByUrl('login');
                        this.allinoneService.logout();
                      }, 2000);
                    } else {
                      this.messageService.openSnackBar(
                        res.status || res.message || res.error,
                        'warn'
                      );
                    }
                  }
                  this.isStillUpload = false;
                  this.loadingService.hide();
                  this.dialogRef.disableClose = false;
                },
                (err) => {
                  this.isStillUpload = false;
                  this.loadingService.hide();
                  this.dialogRef.disableClose = false;
                }
              );
          }
        });
      } else {
        this.isStillUpload = true;
        this.dialogRef.disableClose = true;
        if (this.unitForm.contenttype == 5 && this.filestatusatt == '1') {

          
        


          const fileUpload = await this.allinoneService.fileUploadToS3WithPath(
            this.fileSelected,
            this.unitForm.filename,
            'references'
          );
          if (fileUpload == false) {
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            this.isStillFileUpload = false;
            return;
          }
        }

        // ***
        // if (
        //   (this.unitForm.contenttype == 7 || this.unitForm.contenttype == 8) &&
        //   this.filestatusatt == '1'
        // ) {
        //   const fileUpload = await this.allinoneService.fileUploadToS3WithPath(
        //     this.fileSelected,
        //     this.unitForm.filename,
        //     'references'
        //   );
        //   if (fileUpload == false) {
        //     this.messageService.openSnackBar(
        //       'Error while uploading your file.',
        //       'warn'
        //     );
        //     this.isStillFileUpload = false;
        //     return;
        //   }
        // }

        if (this.unitForm.contenttype == 8 && this.filestatusatt == '1') {

          
          


          const fileUpload = await this.allinoneService.fileUploadToS3WithPath(
            this.fileSelected,
            this.unitForm.filename,
            'references'
          );
          if (fileUpload == false) {
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            this.isStillFileUpload = false;
            return;
          }
        }

        // upload multiple file for attachment
        if (this.unitForm.contenttype == 7 && this.filestatusatt == '1') {
          for (var i = 0; i < this.attachmentList.length; i++) {
            if (this.attachmentList[i].fileselected) {
              const fileUpload =
                await this.allinoneService.fileUploadToS3WithPath(
                  this.attachmentList[i].fileselected,
                  this.attachmentList[i].filename,
                  'references'
                );
              if (fileUpload == false) {
                this.messageService.openSnackBar(
                  'Error while uploading your file.',
                  'warn'
                );
                this.isStillFileUpload = false;
                return;
              }
            }
          }
        }

        if (this.unitForm.contenttype == 7) {
          for (var i = 0; i < this.attachmentList.length; i++) {
            attachmentDataList.push({
              filename: this.attachmentList[i].filename,
              libraryid: this.attachmentList[i].libraryid
                ? this.attachmentList[i].libraryid
                : '',
            });
          }
        }

        // if (this.unitForm.contenttype == 6) {
        //   this.unitForm.content = '';
        // }
        if (this.action_status == 'new') {
          console.log('add unit');

          console.log(
            this.unitForm.maximumAttempt +
              '>' +
              this.unitForm.allquestion +
              '>>' +
              this.unitForm.filetype
          );
          this.courseService
            .addUnit(
              this.unitForm.unit_name.toString().trim(),
              this.subsectionid,
              this.unitForm.sortkey,
              this.unitForm.contenttype,
              this.unitForm.contenttype == 7 ||
                this.unitForm.contenttype == 8 ||
                this.unitForm.contenttype == 6
                ? this.unitForm.content
                : this.dialogId == ''
                ? this.unitForm.content.toString().trim()
                : '',
              this.unitForm.filename,
              this.unitForm.filetype,
              this.uploadImagelist,
              this.uploadFilelist,
              this.courseid,
              this.unitForm.libraryid,
              this.unitForm.maximumAttempt.toString().trim(),
              this.question ? '' : this.unitForm.allquestion.toString().trim(),
              this.unitForm.grade.toString(),
              this.unitForm.targetstudytime.toString(),
              // this.allinoneService.formatDate(this.unitForm.deadlinedate),
              // // this.allinoneService.formatTimeAmpm(this.unitForm.deadlinetime).toString()
              // this.unitForm.contenttype == 7 ? this.allinoneService.f24to12(this.unitForm.deadlinetime) : '',
              this.unitForm.deadlinedate
                ? this.allinoneService.formatDate(this.unitForm.deadlinedate)
                : '',
              this.unitForm.contenttype == 7
                ? this.unitForm.deadlinetime
                  ? this.allinoneService.f24to12(this.unitForm.deadlinetime)
                  : ''
                : '',
              this.unitForm.attachment,
              this.unitForm.showfeedback,
              this.unitForm.allowdownload,
              this.unitForm.autonumbering,
              this.unitForm.bygroup,
              this.unitForm.groupquestionscount,
              this.unitForm.grouptime,
              attachmentDataList,
              this.unitForm.randomassignment,
              this.unitForm.randomassignmentcount,
              this.unitForm.multifileupload,
              this.unitForm.multifileuploadcount,
            )
            .subscribe(
              async (res: any) => {
                if (res.returncode == '300') {
                  console.log('l.....');
                  res.data.content = this.unitForm.content;
                  res.data.downloadurl = this.unitForm.preview;
                  res.data.libraryid = this.unitForm.libraryid;
                  res.data.grade = this.unitForm.grade.toString();
                  res.data.studytime = this.unitForm.targetstudytime.toString();
                  res.data.deadlinedate = this.allinoneService.formatDate(
                    this.unitForm.deadlinedate
                  );
                  res.data.deadlinetime = this.unitForm.deadlinetime;

                  res.data.attachment = this.unitForm.libraryid
                    ? this.unitForm.filename
                    : this.unitForm.attachment;
                  res.data.libraryfilename = this.unitForm.filename;
                  res.data.showfeedback = this.unitForm.showfeedback;
                  res.data.autonumbering = this.unitForm.autonumbering;
                  res.data.allowdownload = this.unitForm.allowdownload;
                  res.data.bygroup = this.unitForm.bygroup;
                  res.data.groupquestionscount =
                    this.unitForm.groupquestionscount;
                  res.data.grouptime = this.unitForm.grouptime;
                  res.data.attachmentlist = attachmentDataList;
                  res.data.randomassignment = this.unitForm.randomassignment;
                  res.data.randomassignmentcount =
                    this.unitForm.randomassignmentcount;
                  res.data.multifileupload = this.unitForm.multifileupload;
                  res.data.multifileuploadcount = this.unitForm.multifileuploadcount;

                  console.log(res.data);
                  this.dialogRef.close({
                    data: res.data,
                    action_status: this.action_status,
                  });
                  console.log('res data');

                  console.log(res.data);
                } else {
                  if (res.returncode == '210') {
                    setTimeout(() => {
                      this.router.navigateByUrl('login');
                      this.allinoneService.logout();
                    }, 2000);
                  } else {
                    this.messageService.openSnackBar(
                      res.status || res.message || res.error,
                      'warn'
                    );
                  }
                }
                this.isStillUpload = false;
                this.dialogRef.disableClose = false;
              },
              (err) => {
                this.isStillUpload = false;
                this.dialogRef.disableClose = false;
              }
            );
        } else {
          console.log('update unit');

          this.courseService
            .updateUnit(
              this.courseid,
              this.unit.unitid,
              this.unitForm.unit_name.toString().trim(),
              this.subsectionid,
              this.unitForm.sortkey,
              this.unitForm.contenttype,
              this.unitForm.contenttype == 7 ||
                this.unitForm.contenttype == 8 ||
                this.unitForm.contenttype == 6
                ? this.unitForm.content
                : this.dialogId != ''
                ? ''
                : this.unitForm.libraryid != ''
                ? ''
                : this.unitForm.content.toString().trim(),
              this.unitForm.filename,
              this.unitForm.filetype,
              this.uploadFilelist,
              this.uploadImagelist,
              this.unitForm.filepath,
              this.unitForm.libraryid,
              this.unitForm.maximumAttempt.toString().trim(),
              this.question ? '' : this.unitForm.allquestion.toString().trim(),
              this.unitForm.grade.toString(),
              this.unitForm.targetstudytime.toString(),
              // this.allinoneService.formatDate(this.unitForm.deadlinedate),
              // // this.allinoneService.formatTimeAmpm(this.unitForm.deadlinetime).toString()
              // this.unitForm.contenttype == 7 ? this.allinoneService.f24to12(this.unitForm.deadlinetime) : '',
              this.unitForm.deadlinedate
                ? this.allinoneService.formatDate(this.unitForm.deadlinedate)
                : '',
              this.unitForm.contenttype == 7
                ? this.unitForm.deadlinetime
                  ? this.allinoneService.f24to12(this.unitForm.deadlinetime)
                  : ''
                : '',
              this.unitForm.attachment,
              this.unitForm.showfeedback,
              this.unitForm.allowdownload,
              this.unitForm.autonumbering,
              this.unitForm.bygroup,
              this.unitForm.groupquestionscount,
              this.unitForm.grouptime,
              attachmentDataList,
              this.unitForm.randomassignment,
              this.unitForm.randomassignmentcount,
              this.unitForm.multifileupload,
              this.unitForm.multifileuploadcount,
            )
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  res.data.content = this.unitForm.content;
                  res.data.downloadurl = this.unitForm.preview;
                  res.data.libraryid = this.unitForm.libraryid;
                  res.data.grade = this.unitForm.grade.toString();
                  res.data.studytime = this.unitForm.targetstudytime.toString();
                  res.data.deadlinedate = this.allinoneService.formatDate(
                    this.unitForm.deadlinedate
                  );
                  res.data.deadlinetime = this.unitForm.deadlinetime;

                  res.data.attachment = this.unitForm.attachment;

                  res.data.libraryfilename = this.unitForm.filename;
                  res.data.showfeedback = this.unitForm.showfeedback;
                  res.data.autonumbering = this.unitForm.autonumbering;
                  res.data.allowdownload = this.unitForm.allowdownload;
                  res.data.bygroup = this.unitForm.bygroup;
                  res.data.groupquestionscount =
                    this.unitForm.groupquestionscount;
                  res.data.grouptime = this.unitForm.grouptime;

                  this.dialogRef.close({
                    data: res.data,
                    action_status: this.action_status,
                  });
                } else {
                  if (res.returncode == '210') {
                    setTimeout(() => {
                      this.router.navigateByUrl('login');
                      this.allinoneService.logout();
                    }, 2000);
                  } else {
                    this.messageService.openSnackBar(
                      res.status || res.message || res.error,
                      'warn'
                    );
                  }
                }
                this.isStillUpload = false;
                this.dialogRef.disableClose = false;
              },
              (err) => {
                this.isStillUpload = false;
                this.dialogRef.disableClose = false;
              }
            );
        }
      }
    }
  }

  allQuestion(event: any) {
    console.log(event.target.value);
    if (event.target.value) {
      this.question = true;
      console.log('1');
    } else {
      this.question = false;
      console.log('2');
    }
  }
  testHtml() {
    var param = {
      content: this.htmlContent,
    };

    console.log(JSON.stringify(param));
  }

  videoTypeChange() {
    console.log('here');
    if (this.unitForm.videotype == '001') {
      this.unitForm.content = '';
      this.unitForm.preview = '';
      this.unitForm.libraryid = '';
    }
    this.checkVideoUrl();
  }
  // onRandomAssignmentChange() {
  //   this.unitForm.randomassignmentcount = 1;
  // }

  // onFontChange(event: any) {
  //   if (this.unitForm.content != '') {
  //     const selection = window.getSelection();
  //     if (selection) {
  //       const focusNode = selection!.focusNode!;
  //       if (focusNode && focusNode.nodeType === Node.TEXT_NODE) {
  //         const partentElement = focusNode.parentNode! as Element;
  //         console.log(partentElement.attributes);
  //         for (let i = 0; i < partentElement.attributes.length; i++) {
  //           const attr = partentElement.attributes[i];
  //           if (attr.name == 'face') {
  //             partentElement.attributes[i].value = `${event.target.value}`;
  //           }
  //         }
  //       } else if (focusNode && focusNode.nodeType === Node.ELEMENT_NODE) {
  //         // If focusNode is an element node
  //         const elementNode = focusNode as Element;
  //         const attributes = elementNode.attributes;

  //         for (let i = 0; i < attributes.length; i++) {
  //           const attribute = attributes[i];
  //           if (attribute.name == 'face') {
  //             attributes[i].value = `${event.target.value}`;
  //           }
  //         }
  //       } else {
  //         console.error(
  //           'Focus node is neither a text node nor an element node'
  //         );
  //       }
  //     }
  //   }
  // }

  onFontChange(event: any) {
    if (this.unitForm.content !== '') {
      const selectedFont = event.target.value;
      const selection = window.getSelection();
  
      if (selection && selection.rangeCount > 0) {
        // Apply font face to the selected text
        document.execCommand('fontName', false, selectedFont);
      } else {
        console.error('No text is selected');
      }
    }
  }
  

  onMouseUp(event: MouseEvent) {
    const font = event.target as HTMLElement;
    if (font) {
      const attributes = font.attributes;
      for (let i = 0; i < attributes.length; i++) {
        const attr = attributes[i];
        if (attr.name == 'face') {
          this.fontSelected = attr.value;
        }
      }
    }
  }
}
