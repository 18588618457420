import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Profile } from '../../shared/models/Profile';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  userform: any = {
    profileid: '',
    institute: '',
    profileimage: '',
    profileimageurl: '',
    username: '',
    userid: '',
    biography: '',
    userrole: 1,
  };
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Tell about yourself',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  userFormSubmitted: boolean = false;

  imageChangedEvent: any = '';
  croppedImage?: any = '';
  coppedImage: boolean = false;
  isLoading: boolean = false;
  isStillUpdating: boolean = false;
  isEditing: boolean = false;
  fileselected: any = '';

  profile!: Profile;

  @ViewChild('fileInput') fileInput: any;

  constructor(
    private router: Router,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private datepipe: DatePipe,
    private dialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (data.profile) {
      this.profile = data.profile;
      this.isEditing = true;
    }
  }

  ngOnInit(): void {
    if (this.isEditing) {
      this.userform.profileid = this.profile.profileid;
      this.userform.username = this.profile.name;
      this.userform.institute = this.profile.institute;
      this.userform.profileimage = this.profile.profileimage;
      this.userform.profileimageurl = this.profile.profileimageurl;
      this.userform.biography = this.profile.description;
    }
    console.log(this.userform);
  }

  fileChangeEvent(event: any, drag?: boolean) {
    const date = new Date();
    const filename =
      'profile_image_' +
      this.datepipe.transform(date, 'yyyyMMddhhmmssSSS')?.toString();
    this.fileselected = drag ? event[0] : event.target.files[0];
    const filetype = this.fileselected.name.split('.').pop();
    var reader = new FileReader();
    reader.readAsDataURL(this.fileselected);
    reader.onload = (_event) => {
      this.userform.profileimageurl = reader.result;
      this.userform.profileimage = filename + '.' + filetype;
    };
    this.hiddenfileinput.nativeElement = '';
  }

  // fileChangeEvent(file: any) {
  //   this.coppedImage = false;
  //   this.imageChangedEvent = file;
  // }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event.base64);
  }
  imageLoaded() {
    // show cropper
  }
  copyImage() {
    // cropper ready
    this.coppedImage = true;
    this.userform.profileimage = this.croppedImage;
    this.fileInput.nativeElement.value = '';
  }
  loadImageFailed() {
    // show message
  }

  async updateUser() {
    if (this.isStillUpdating) {
      return;
    }

    this.userFormSubmitted = true;

    if (this.userform.username == '' && this.userform.institute == '') {
      return;
    } else {
      this.isStillUpdating = true;
      this.dialogRef.disableClose = true;
      const profileid = this.userform.profileid;
      const name = this.userform.username.trim();
      const institute = this.userform.institute.trim();
      const description = this.userform.biography;
      const profileimage = this.userform.profileimage;

      if (this.fileselected && profileimage) {
        if (this.fileselected.size > 102400) {
          this.fileselected = await this.allinoneService.compressImage(
            this.fileselected,
            0.1
          );
          if (this.fileselected == false) {
            this.messageService.openSnackBar(
              'Something went wrong! Try again later.',
              'warn'
            );
            this.dialogRef.disableClose = false;
            this.isStillUpdating = false;
            return;
          }
        }
        const await_upload = await this.allinoneService.fileUploadToS3WithPath(
          this.fileselected,
          profileimage,
          'images'
        );
        if (!await_upload) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.dialogRef.disableClose = false;
          this.isStillUpdating = false;
          return;
        }
      }

      const fun = this.isEditing
        ? this.courseService.updateInstructorProfile(
            profileid,
            name,
            institute,
            description,
            profileimage
          )
        : this.courseService.createInstructorProfile(
            name,
            institute,
            description,
            profileimage
          );
      fun.subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            var temp: Profile = res;
            if(this.isEditing && profileid == ''){
              this.allinoneService.user.profileimage = profileimage;
              this.allinoneService.user.username = name;
            }
            this.dialogRef.close({ status: true, profile: temp });
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.dialogRef.disableClose = false;
          this.isStillUpdating = false;
        },
        (err) => {
          this.dialogRef.disableClose = false;
          this.isStillUpdating = false;
        }
      );
    }
  }

  removeImage() {
    this.fileselected = '';
    this.userform.profileimage = '';
    this.userform.profileimageurl = '';
  }

  dismiss() {
    this.dialogRef.close({ status: false, profile: null });
  }
}
