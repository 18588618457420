<div *ngIf="!gettingGrade" class="d-flex justify-content-between align-items-center mb-3">
  <h2 style="margin: 0; padding: 0;"> {{user}}'s Grades</h2>
  <div mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</div>
<section class="table-container" tabindex="0">
  <table class="table align-middle mb-0 bg-white">
    <thead class="bg-light" sticky>
      <tr>
        <th style="min-width: 170px;"></th>
        <th>Status</th>
        <th>Grade</th>
        <!-- <th>Out of</th> -->
      </tr>
    </thead>
    <tbody *ngIf="!gettingGrade">
      <tr *ngFor="let grade of grades; let index = index" class="grade-tr">
        <td>
          <div *ngIf="!grade.isTotal" class="d-flex align-items-center flex-row">
            <div class="me-3 d-flex align-items-center justify-content-center" style="width: 20px;">
              <span *ngIf="grade.gradestatus == 'false'">
                <img [src]="'./../../assets/images/assignment.png'" class="unit-icon" *ngIf="grade.contenttype == '7'">
                <i class="fas fa-clipboard-list unit-icon d-flex justify-content-center"
                  *ngIf="grade.contenttype == '6'"></i>
              </span>
              <span *ngIf="grade.gradestatus == 'true'">
                <i class="fas fa-check-circle unit-icon d-flex justify-content-center" style="color: green;"></i>
              </span>
            </div>
            <div class="d-flex flex-column unit-container">
              <div>
                {{grade.unitname}}
              </div>
              <div class="unit-type">
                {{grade.contenttype == '6' ? 'Quiz' : 'Assignment'}}
              </div>
            </div>
          </div>
        </td>
        <td>
          <span *ngIf="grade.gradestatus == 'false'" class="incomplete">Incomplete</span>
          <span *ngIf="grade.gradestatus == 'true'" class="completed">Completed</span>
        </td>
        <td> {{grade.isTotal ? grade.totalPercentage+'%' : grade.grade + ' / ' + grade.totalgrade }}</td>
      </tr>
      <tr *ngIf="grades.length == 0">
        <td colspan="4">
          <span class="nodata">
            This course does not have any graded units.
          </span>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="gettingGrade">
      <tr>
        <td colspan="4">
          <span class="nodata">
            <div class="spinner-border spinner-border-md" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</section>
<!-- <div *ngIf="!gettingGrade" class="d-flex w-100 justify-content-end align-items-center mt-3" style="margin-right: 70px;">
    <p>{{ totalPercentage }} %</p>
  </div> -->