import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';
import { TemplateQuestionsSetupComponent } from '../template-questions-setup/template-questions-setup.component';
import { TemplateSetupComponent } from '../template-setup/template-setup.component';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss'],
})
export class TemplateViewComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;

  gettingChannels: boolean = false;
  gettingTemplate: boolean = false;
  gettingCourses: boolean = false;
  gettingResult: boolean = false;
  isFocus: boolean = false;

  channelname: string = '';
  channelid: string = '';
  role: string = '';
  searchText: string = '';

  channels: any[] = [];
  displayedColumns: string[] = ['name', 'description', 'action'];

  templateData: any[] = [];
  templateTableData!: MatTableDataSource<any>;

  subscription!: Subscription;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if (
      (this.role == 'admin' && !this.allinoneService.isRootAdmin()) ||
      (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())
    ) {
      this.router.navigateByUrl('');
    }
  }

  ngOnInit(): void {
    this.getChannels();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'admin') {
      this.channelname = 'KHub';
      this.courseService.channelGet().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist.filter(
              (channel: any) => channel.status == '1'
            );
            var schannel = this.channels.find(
              (x: any) => x.channelid == this.allinoneService.selectedChannelid
            );
            this.channelid = schannel
              ? schannel.channelid
              : this.channels[0].channelid;
            this.getTemplates();
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingChannels = false;
        },
        (err) => {
          this.gettingChannels = false;
        }
      );
    } else if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.channelid = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
      this.getTemplates();
    } else {
      this.channels = this.allinoneService.supervisorChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.channelid = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
      this.getTemplates();
    }
  }

  channelChange(event: any) {
    this.allinoneService.selectedChannelid = this.channelid;
    this.getTemplates();
  }

  getTemplates() {
    this.templateData = [];
    this.gettingTemplate = true;
    this.templateTableData = new MatTableDataSource();
    this.subscription = this.courseService
      .getFeedbackTemplate(this.channelid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.templateData = res.datalist;
            this.templateTableData = new MatTableDataSource(this.templateData);
            setTimeout(() => {
              this.templateTableData.paginator = this.paginator;
              this.templateTableData.sort = this.sort;
            }, 100);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingTemplate = false;
        },
        (err) => {
          this.gettingTemplate = false;
        }
      );
  }

  refresh() {
    this.getTemplates();
  }

  viewQuestions(templateid: any) {
    this.router.navigateByUrl(`${this.role}/feedback/${templateid}`);
  }

  setUpForm(template?: any) {
    const dialog = this.dialog.open(TemplateSetupComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '500px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        isEditing: template ? true : false,
        channelid: this.channelid,
        template: template,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (template) {
          template.name = dialogResult.name;
          template.description = dialogResult.description;
        } else {
          this.templateData.push({
            fbtmpid: dialogResult.templateid,
            name: dialogResult.name,
            description: dialogResult.description,
          });
          this.templateTableData = new MatTableDataSource(this.templateData);
        }
        setTimeout(() => {
          this.templateTableData.paginator = this.paginator;
          this.templateTableData.sort = this.sort;
        }, 100);
      }
    });
  }

  deleteTemplate(template: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService.deleteFeedbackTemplate(template.fbtmpid).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var index = this.templateData.findIndex(
                (x: any) => x.fbtmpid == template.fbtmpid
              );
              this.templateData.splice(index, 1);
              this.templateTableData = new MatTableDataSource(
                this.templateData
              );
              setTimeout(() => {
                this.templateTableData.paginator = this.paginator;
                this.templateTableData.sort = this.sort;
              }, 100);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.loadingService.hide();
          },
          (err) => {
            this.loadingService.hide();
          }
        );
      }
    });
  }

  clear() {
    this.searchText = '';
    this.templateTableData.filter = '';
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.templateTableData.filter = searchItem.trim().toLowerCase();
  }
  toQuestion() {
    this.router.navigateByUrl('admin/feedback/' + this.channelid);
  }
}
