import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { CourseService } from '../../shared/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-instructor-signup',
  templateUrl: './instructor-signup.component.html',
  styleUrls: ['./instructor-signup.component.scss']
})
export class InstructorSignupComponent implements OnInit {


  signupForm = this.formBuider.group({
    userid: ["", Validators.required],
    password: ["", Validators.required],
    username: ["", Validators.required],
    institute: ["", Validators.required]
  });

  signupFormSubmitted: boolean = false;
  isStillSigningIn: boolean = false;
  passwordIsValid: boolean = false;
  showPassInput: boolean = false;
  fieldTextType: boolean = false;

  constructor(private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService) { }

  ngOnInit(): void {
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  passwordValid(event: boolean) {
    this.passwordIsValid = event;
  }
  get signupFormControl() { return this.signupForm.controls; }

  signUp() {
    this.signupFormSubmitted = true;
    console.log(this.passwordIsValid);


    if (this.signupForm.invalid || !this.passwordIsValid) {
      if (!this.passwordIsValid) {
        this.showPassInput = true;
      }
      return;
    }
    else {
      this.showPassInput = false;
      this.isStillSigningIn = true;

      this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.signupForm.value.userid));
      this.allinoneService.setCookie("kunyek_password", this.signupForm.value.password);

      this.courseService.checkUser().subscribe(async (res: any): Promise<any> => {
        if (res.returncode == "300") {
          //user does not exists

          //userrole - 1
          if (res.status == 0) {
            const awaitcreateUser = await this.createUser();
            if (awaitcreateUser == "success") {
              const awaitIamSignin = await this.iamSignIn();
              if (awaitIamSignin == "success") {
                this.router.navigateByUrl('/verify');
              }
            } else {
              this.isStillSigningIn = false;
              this.signupFormSubmitted = false;
            }
          }
          else {
            if (res.userrole == "0") {
              this.allinoneService.setCookie('userid', res.usersyskey);
              const awaitupdateUser = await this.updateUser();
              if (awaitupdateUser == "success") {
                const awaitIamSignin = await this.iamSignIn();
                if (awaitIamSignin == "success") {
                  this.router.navigateByUrl('/verify');
                }
              } else {
                this.isStillSigningIn = false;
                this.signupFormSubmitted = false;
              }
            }
            else {
              this.messageService.openSnackBar("User already exists,please login", 'warn');
              this.router.navigateByUrl('login');
            }

          }
        }

        else {
          this.messageService.openSnackBar(res.status, 'warn');
          this.signupFormSubmitted = false;
          if (res.returncode == "210") {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          }
        }
      }, err => {
        this.signupFormSubmitted = false;
        this.isStillSigningIn = false;
      });
    }
  }

  createUser() {
    return new Promise(resolve => {
      this.courseService.createUser(this.signupForm.value.username, this.signupForm.value.institute).subscribe((learn_res: any) => {
        if (learn_res.returncode == "300") {
          this.allinoneService.setCookie("userid", learn_res.data.usersyskey);
          resolve('success');
        }
        else {
          this.messageService.openSnackBar(learn_res.status, 'warn');
          resolve('error');
        }
      }, err => {
        resolve('error');
      });
    });
  }

  updateUser() {
    return new Promise(resolve => {
      this.courseService.updateUser(this.signupForm.value.username, this.signupForm.value.institute, "", "", "signup").subscribe((learn_res: any) => {
        if (learn_res.returncode == "300") {
          this.allinoneService.setCookie("userid", learn_res.data.usersyskey);
          resolve('success');
        }
        else {
          this.messageService.openSnackBar(learn_res.status, 'warn');
          resolve('error');
        }
      }, err => {
        resolve('error');
      });
    });
  }

  iamSignIn() {
    return new Promise(resolve => {
      this.courseService.signIn(this.allinoneService.checkUserId(this.signupForm.value.userid), "").subscribe((res: any) => {
        if (res.returncode == "300") {

          this.allinoneService.setCookie("kunyek_userid", this.allinoneService.checkUserId(this.signupForm.value.userid));
          this.allinoneService.setCookie("kunyek_password", (this.signupForm.value.password));
          sessionStorage.setItem("session", res.session);
          sessionStorage.setItem("existsuser", res.type);
          sessionStorage.setItem("kunyekusername", res.username);

          this.isStillSigningIn = false;
          resolve('success');
        }
        else {
          if (res.returncode == "310") {
            this.messageService.openSnackBar("User already exists,please login", 'warn');
            this.router.navigateByUrl('login');
          }
          else {
            this.isStillSigningIn = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }

          resolve('error');

        }
      }, err => {
        this.isStillSigningIn = false;
        resolve('error');
      })
    })

  }

}
