<app-breadcrumb [s_breadcrumb]="'Schedule'"></app-breadcrumb>
<div class="content">
  <div class="d-flex justify-content-between flex-wrap mt-3">
    <div>
      <button (click)="openScheduleSetup()" class="btn new-chl ms-0">
        <i class="fa fa-plus" aria-hidden="true"></i> New Schedule
      </button>
    </div>
    <div class="d-flex flex-wrap align-items-center">
      <div class="mb-3 me-2">
        Total :<b> {{ courses.length }}</b>
      </div>

      <div class="input-group mb-3 me-2" style="width: 260px !important">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" />
        <button class="btn" type="button" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color); color: var(--main-color)">
          <i class="fa fa-times"></i>
        </button>
      </div>

      <div class="form-group mb-3 me-2" style="width: 260px !important">
        <select class="form-select" [(ngModel)]="selectedChannel" (ngModelChange)="channelChange()"
          matTooltip="Filter By Channel" [disabled]="gettingmyChannel">
          <option [value]="chl.channelid" *ngFor="let chl of channels">
            {{ chl.name }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingmyChannel"></mat-progress-bar>
      </div>

      <button class="mb-3 me-2 btn new-class" matTooltip="Refresh" (click)="getCourses()">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>
  <div>
    <table mat-table #table [dataSource]="coursesTableData" matSort style="width: 100%;"
      (matSortChange)="sortCategoryData($event)">

      <!-- Course Image Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="openScheduleSetup(element)">
          <img [src]="element.imageurl || element.courseimage" class="course-listing-image" (error)="allinoneService.handleImgError($event)"/>
        </td>
      </ng-container>

      <!-- Cours Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" >Name</th>
        <td mat-cell *matCellDef="let element" (click)="openScheduleSetup(element)">
          {{element.name}}
        </td>
      </ng-container>

      <!--  Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 110px !important;" (click)="openScheduleSetup(element)">
          <!-- {{element.status}} -->
          <!-- 001 - incomplete / 002 - ongoing / 003 - upcoming / 004 - complete -->
          <span *ngIf="element.status == '001'" class="badge bg-secondary p-2">
            Unscheduled
          </span>
          <span *ngIf="element.status == '002'" class="badge bg-main p-2">
            Ongoing
          </span>
          <span *ngIf="element.status == '003'" class="badge bg-orange p-2">
            Upcoming
          </span>
          <span *ngIf="element.status == '004'" class="badge bg-green p-2">
            Completed
          </span>
        </td>
      </ng-container>


      <!-- Start Date Column -->
      <ng-container matColumnDef="startdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="startdate" class="center">
          Start Date
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 140px !important;" (click)="openScheduleSetup(element)">
          <!-- {{element.startdate? ((allinoneService.formatDateForDiscussionDatePipe(element.startdate)+' '+
          element.starttime) | date:'short') : '--'}} -->

          {{allinoneService.formatDBToShow(element.startdate)}}
        </td>

      </ng-container>

      <!-- End Date Column -->
      <ng-container matColumnDef="enddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="enddate" class="center">
          End Date
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 140px !important;" (click)="openScheduleSetup(element)">
          <!-- {{element.enddate? ((allinoneService.formatDateForDiscussionDatePipe(element.enddate)+' '+ element.endtime) |
          date:'short') : '--'}} -->
          {{allinoneService.formatDBToShow(element.enddate)}}
        </td>
      </ng-container>


      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element" px-3 style="text-align: center;">

          <button class="btn btn-outline-danger btn-custom-rounded me-2 align-item-center" matTooltip="Delete"
            (click)="deleteSchedule(element)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="6">
          <span class="nodata" *ngIf="!gettingCourses">Empty</span>
          <span class="nodata" *ngIf="gettingCourses">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>


    </table>

    <mat-paginator #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]" *ngIf="courses.length > 0">
    </mat-paginator>
  </div>
</div>