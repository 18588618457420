<app-breadcrumb [s_breadcrumb]="'Certificate Templates'" *ngIf="!isNew"></app-breadcrumb>
<app-breadcrumb [s_breadcrumb]="'Certificate Templates'"
  [t_breadcrumb]="templateForEdit ? 'Edit Template' : 'Template Setup'" (emit)="cancel()" *ngIf="isNew">
</app-breadcrumb>

<div class="content" *ngIf="!isNew">
  <div class="d-flex align-items-center justify-content-between flex-wrap">
    <div class="d-flex align-items-center flex-wrap">
      <div>
        <h2 class="mb-0">
          <strong>Certificate Templates</strong>
        </h2>
      </div>
      <div>
        <button mat-flat-button color="primary" class="px-2" (click)="newTemplate()"><i
            class="fa fa-plus me-2"></i>New</button>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="me-2">
        <select class="form-select" [(ngModel)]="channelid" required #chlid="ngModel"
          (ngModelChange)="channelChange($event)">
          <option value="" *ngIf="role == 'admin'">KHub</option>
          <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.name}}</option>
        </select>
      </div>
      <button class="btn new-class" matTooltip="Refresh" (click)="refresh()" type="button">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>

  <div class="d-flex flex-row flex-wrap">
    <ng-container *ngIf="gettingTemplate">
      <div *ngFor="let x of [1,2,3]" class="me-3">
        <ngx-skeleton-loader [theme]="{ height: '200px',width: '250px'}">
        </ngx-skeleton-loader>
      </div>
    </ng-container>
    <ng-container *ngIf="!gettingTemplate">
      <div *ngFor="let template of templateList" class="me-3 mb-3 cus-point template-list-container"
        (click)="editTemplate(template)">
        <img [src]="template.previewimageurl" width="250px">
        <!-- {{template.templateid}} -->
      </div>
    </ng-container>
  </div>
</div>

<div class="content" *ngIf="isNew">
  <div class="d-flex align-items-center mb-3 justify-content-between">
    <div>
      <h2 class="mb-0">
        <strong>{{templateForEdit ? 'Edit Template' : 'Template Setup'}}</strong>
      </h2>
    </div>

    <div class="actions-button">
      <button mat-stroked-button class="cancel-button mt-2" type="button" (click)="cancel()">
        Cancel
      </button>
      <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="submitTemplate()">
      </app-button-loader>


    </div>
  </div>
  <div class="row g-0">
    <div class="col-md-6 pe-3">
      <div class="d-flex align-items-center mb-3">
        <div class="me-2 d-flex align-items-center">
          <mat-icon class="info-icon">info</mat-icon>
        </div>
        <div class="info">
          Please use <strong>[[name]]</strong> for student name,&nbsp;<strong>[[course]]</strong> for the course name
          and <strong>[[date]]</strong> for the date.
        </div>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel (opened)="setStep(-1)" [expanded]="selectedIndex == -1">
          <mat-expansion-panel-header>
            <mat-panel-title>
              General
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="d-flex justify-content-between mb-3">
            <div>
              <strong>Background Image</strong>
            </div>
            <div style="width: 250px;">
              <div class="d-flex flex-column mb-2">
                <div>
                  Recommended format
                </div>
                <div>
                  <li>
                    Image format: JPG/PNG
                  </li>
                  <li>
                    Recommended size: 1200 x 900 pixels
                  </li>
                </div>
              </div>
              <input type="file" hidden (change)="onFileSelected($event, false)" #hiddenFileInput accept="image/*">
              <button mat-flat-button class="w-100 ms-0" (click)="hiddenFileInput.click()"
                color="primary">Upload</button>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <strong>Padding(%)</strong>
            </div>
            <div style="width: 250px;">
              <input type="number" class="form-control" [(ngModel)]="padding" (ngModelChange)="paddingChange(1)">
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngFor="let item of elementList;let index = index" (opened)="setStep(index)"
          [expanded]="selectedIndex == index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="text-content">
                  {{item.content}}
                </div>
                <div>
                  <mat-icon (click)="removeElement(index,item.id)">delete</mat-icon>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Content
              </div>
              <div class="col-8">
                <input type="text" class="form-control" [(ngModel)]="item.content" (ngModelChange)="updateHTML(index,2,index)">
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
              </div>
              <div class="col-8">
                <input class="form-check-input" type="checkbox" id="center0" [checked]="item.isCenter"
                  (change)="centerChange(index)">
                <label for="center0" class="ms-2">Center</label>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Left(%)
              </div>
              <div class="col-8">
                <input type="number" class="form-control" [disabled]="item.isCenter" [(ngModel)]="item.left"
                  (ngModelChange)="updateHTML(index,3,index)">
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Top(%)
              </div>
              <div class="col-8">
                <input type="number" class="form-control" [(ngModel)]="item.top" (ngModelChange)="updateHTML(index,4,index)">
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Font Size(px)
              </div>
              <div class="col-8">
                <input type="number" class="form-control" [(ngModel)]="item.fontSize"
                  (ngModelChange)="updateHTML(index,5,index)">
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Font Weight
              </div>
              <div class="col-8">
                <select class="form-select" [(ngModel)]="item.fontWeight" (ngModelChange)="updateHTML(index,6,index)">
                  <option *ngFor="let fw of fontWeightList" [value]="fw">{{fw}}</option>
                </select>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Font Family
              </div>
              <div class="col-8">
                <select class="form-select" [(ngModel)]="item.fontFamily" (ngModelChange)="updateHTML(index,7,index)">
                  <option *ngFor="let ff of fontFamilyList" [value]="ff">{{ff}}</option>
                </select>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Line Height
              </div>
              <div class="col-8">
                <input type="number" class="form-control" [(ngModel)]="item.lineHeight"
                  (ngModelChange)="updateHTML(index,9,index)">
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div>
                Color
              </div>
              <div class="col-8">
                <input type="color" class="form-control form-control-color" id="color" [(ngModel)]="item.color"
                  (ngModelChange)="updateHTML(index,8,index)">
              </div>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
      <div class="d-flex align-items-center justify-content-end mt-2">
        <button mat-stroked-button class="cancel-button me-2" type="button" (click)="fullScreenView()">
          Preview
        </button>
        <button type="button" [disabled]="elementList.length == 10" (click)="addnewElement()" mat-flat-button
          color="primary">Add New
          Content</button>
      </div>

    </div>
    <div class="col-md-6" *ngIf="!fullScreen">
      <div class="template-bg" id="template-bg" appDragDrop (onFileDropped)="onFileSelected($event, true)">
      </div>
    </div>
  </div>
</div>