<app-breadcrumb s_breadcrumb="My Channels" [t_breadcrumb]="'Channel name - Analytics'" [a_breadcrumb] = "'Course Name'" (emit)="goToMyChannels()" (emit1)="goToChannelAnalytics()"></app-breadcrumb>

<div class="content">
  <div class="d-flex justify-content-end">
    <div class="mt-4 mb-3 me-2">
      Total :<b> {{totaltime}}</b>
    </div>
    <div class="input-group mt-3 mb-3" style="width: 260px !important;">
      <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
        (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
      <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
        *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <button class="btn new-chl mt-3 mb-3" matTooltip="Refresh" (click)="refresh()" type="button">
      <i class="fa fa-refresh"></i>
    </button>
  </div>





  <div style="overflow: auto;">
    <table mat-table #table [dataSource]="showcourses" matSort (matSortChange)="announceSortChange($event)"
      class="mat-elevation-z1" style="width: 100%;">

      <!-- Image Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">

          <div *ngIf="element.courseimage == ''"
            style="background-image: url(https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:340,height:192/https://www.filepicker.io/api/file/VnHPUS7BQnKCiVqglcAL)"
            class="course-listing-image"></div>

          <div *ngIf="element.courseimage != ''"
            [style.background-image]="'url(' + allinoneService.imgurl + element.courseimage + ')'"
            class="course-listing-image"></div>

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Course Name
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element">{{element.coursename}}</td>
      </ng-container>


      <!-- studentcount Column -->
      <ng-container matColumnDef="timespent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"
          sortActionDescription="Sort by studentcount">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); text-align: center">
          {{element.totaltime}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    </table>

    <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div>

  <div class="d-flex justify-content-center m-5" *ngIf="isLoading">
    <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
  </div>

  <div style="text-align: center;padding: 1rem 0;" *ngIf="courses.length === 0 && !isLoading">No courses found</div>

</div>