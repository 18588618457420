import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-report-by-category-details',
  templateUrl: './report-by-category-details.component.html',
  styleUrls: ['./report-by-category-details.component.scss'],
})
export class ReportByCategoryDetailsComponent implements OnInit {
  attendanceTableList!: MatTableDataSource<any>;

  attendanceData: any[] = [];

  isLoading: boolean = false;

  showchannels!: MatTableDataSource<any>;

  attendanceDisplayedColumns = [
    'checkindate',
    'checkintime',
    'description',
    'chatgroup',
    'sharedtime',
  ];

  channelid: string = '';
  startdate: string = '';
  enddate: string = '';
  searchuser: string = '';

  isFocus: boolean = false;
  searchText: string = '';

  // Web Socket
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  role: string = '';

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    private router: Router,
    public allinoneService: AllInOneService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });

    this.activatedRoute.params.subscribe((data) => {
      console.log(data);

      if (data) {
        this.channelid = data.channelid;
        this.searchuser = data.searchuser;
        this.startdate = data.sd;
        this.enddate = data.ed;
      }
    });
  }

  ngOnInit(): void {
    const temp: any[] = [];
    this.attendanceTableList = new MatTableDataSource(temp);
    this.getSocketData();
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.attendanceTableList.filter = searchItem.trim().toLowerCase();
  }

  refresh() {
    const temp: any[] = [];
    this.attendanceTableList = new MatTableDataSource(temp);
    this.getSocketData();
  }

  clear() {
    this.searchText = '';
    this.attendanceTableList.filter = '';
  }

  downloadSheet() {
    var data: any = [];
    var name = 'Attendance Details.xlsx';
    this.attendanceData.map((item: any) => {
      data.push({
        'Check In Date': this.allinoneService.formatDBToShow(item.date),
        'Check In Time': item.starttime,
        Description: item.description,
        'Chat Group': item.groupname,
        'Shared Time': item.sharedtime
      });
    });
    this.allinoneService.exportExcel(data, name);
  }

  test() {
    this.socket?.close(3001);

    // console.log(this.channelid);
    // console.log(this.searchuser);
    // console.log(this.startdate);
    // console.log(this.enddate);
  }

  getSocketData() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  getAttendanceDetails() {
    var data = {
      channelid: this.channelid,
      startdate: this.startdate,
      enddate: this.enddate,
      searchuser: this.searchuser,
      connectionid: this.connectionId,
    };
    this.courseService.getAttendanceDetails(data).subscribe((res: any) => {
      if (res.returncode == '300') {
      } else {
      }
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.isLoading = true;
    this.connectSocketLoading = true;
    // const webSocketURL = this.allinoneService.attendanceSocketUrl;
    const webSocketURL = this.allinoneService.socketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      console.log('Changed data');
      console.log(tempData);
      if (this.connectionId == '') {
        this.connectionId = tempData.connectionId;
      }

      if (this.updateCId) {
        this.updateConnectionID();
      }

      if (tempData.message == 'Forbidden') {
        // this.getCourseByUser();
        this.getAttendanceDetails();
      }

      this.drsocketfileurl = tempData.fileurl;

      console.log('file url');
      console.log(this.drsocketfileurl);

      if (this.drsocketfileurl != '' && this.drsocketfileurl != undefined) {
        console.log('call file url');
        console.log(this.drsocketfileurl);

        this.http.get(this.drsocketfileurl).subscribe((res: any) => {
          console.log('Your get data res');
          console.log(res);

          if (res.returncode == '300') {
            this.attendanceData = res.datalist;
            this.attendanceTableList = new MatTableDataSource(
              this.attendanceData
            );
          }

          this.isLoading = false;
          this.connectionId = '';
          this.socket?.close(3001);
          clearTimeout(this.socketConnectTime);
        });
      }
      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.isLoading = false;
      }
    }, 180 * 1000);
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '004',
    };
    this.courseService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  goReport() {
    this.router.navigateByUrl(`${this.role}/report`);
  }

  sortAttendanceTable(sort: Sort) {
    const data = this.attendanceData;
    if (!sort.active || sort.direction === '') {
      this.attendanceData = data;
      return;
    }

    this.attendanceData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'checkindate':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'checkintime':
          return this.allinoneService.compare(a.starttime, b.starttime, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'chatgroup':
          return this.allinoneService.compare(a.groupname, b.groupname, isAsc);
        case 'sharedtime':
          return this.allinoneService.compare(
            a.sharedtime,
            b.sharedtime,
            isAsc
          );
        default:
          return 0;
      }
    });
    this.attendanceTableList = new MatTableDataSource(this.attendanceData);
  }
}
