import { Component, OnInit } from '@angular/core';
import { AllInOneService } from '../../services/all-in-one.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    public allinoneService: AllInOneService,
  ) { }

  ngOnInit(): void {
  }

  mailto(){
    window.open('mailto:'+ this.allinoneService.adminmail+'?subject=Account Verification', "_blank")
  }
}
