import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditChannelComponent } from '../../channel/edit-channel/edit-channel.component';
import { AssignmentsEditComponent } from '../assignments-edit/assignments-edit.component';
import { Subscription } from 'rxjs';
import { EventEmitter } from 'stream';
import { IndividualDeadlinesComponent } from '../individual-deadlines/individual-deadlines.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-assignments-view',
  templateUrl: './assignments-view.component.html',
  styleUrls: ['./assignments-view.component.scss']
})
export class AssignmentsViewComponent implements OnInit, OnDestroy {

  @Input() assList: any[] = [];
  
  // @Output() assListChildData = new EventEmitter();

  allData : any[] = [];
  // allData : any[] = [
  //   {
  //     assignmentname : 'Testing Assignment',
  //     duedate : '10-09-2023'
  //   }
  // ];
  displayedColumns: any;

  showAssignments: MatTableDataSource<any> = new MatTableDataSource(this.allData);

  searchText: string = '';
  isFocus: boolean = false;
  isLoading: boolean = false;
  paginate: boolean = false;
  courseid: string = "";

  datacount: string = '0';
  getListSubscription!: Subscription;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    const coid = this.route.snapshot.params['courseid'];
    this.courseid = coid;
   }

  ngOnInit(): void {
    this.displayedColumns = ['assignments', 'duedate', 'action'];
    this.getAssignmentList();
  }

  ngOnDestroy(): void {
    this.getListSubscription && this.getListSubscription.unsubscribe();
  }

  test() {
    // this.assListChildData.emit('test');
  }

  getAssignmentList() {
    this.isLoading = true;
    const data = {
      courseid : this.courseid,
      unitid : '',
      contenttype: '7',
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.courseService.getAssignmentDeadline(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.allData = res.datalist;
          this.datacount = this.allData.length.toString();
          this.showAssignments = new MatTableDataSource(this.allData);
          console.log('your data is');
          
          console.log(this.allData);
          
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err : any) => {
        this.isLoading = false;
      }
    )
  }

  editAssignment(data : any) {
    console.log(data);
    const dialog = this.dialog.open(AssignmentsEditComponent, {
      maxWidth: '95vw',
      width: '450px',
      // height: '565px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data : {
        assignmentname : data.unitname,
        courseid : this.courseid,
        unitid  : data.unitid,
        deadlinedate : data.deadlinedate,
        deadlinetime : data.deadlinetime
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if(dialogResult) {
        console.log('dia resl');
        
        console.log(dialogResult);
        
        if(data) {
          data.deadlinedate = dialogResult.deadlinedate,
          data.deadlinetime = dialogResult.deadlinetime
        }
      }
    });
  }

  open(data:any) {
    const dialog = this.dialog.open(IndividualDeadlinesComponent, {
      maxWidth: '95vw',
      width: '800px',
      maxHeight: '95vh',
      minHeight: '310px',
      height: 'auto',
      closeOnNavigation: true,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid: this.courseid,
        unitid: data.unitid
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {

      }
    });
  }

  sortData(sort: Sort) {
    const data = this.allData;
    if (!sort.active || sort.direction === '') {
      this.allData = data;
      return;
    }

    this.allData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'assignments':
          return this.allinoneService.compare(a.unitname, b.unitname, isAsc);
        case 'duedate':
          return this.allinoneService.compare(a.deadlinedate, b.deadlinedate, isAsc);
        default:
          return 0;
      }
    });
    this.showAssignments = new MatTableDataSource(this.allData);
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showAssignments.filter = searchItem.trim().toLowerCase();
  }

  clear() {
    this.searchText = '';
    this.showAssignments.filter = '';
  }

}
