<app-breadcrumb [s_breadcrumb]="'Students'"></app-breadcrumb>
<div class = "content">
  <div class = "d-flex align-item-center justify-content-between flex-wrap mt-2">
    <div class="form-group mb-3" style="width: 260px !important;">
      <select class="form-select" [(ngModel)]="channelid" (change)="getStudents()" matTooltip="Filter by Channel"
        [disabled]="gettingChannel">
        <option value="">Public Channel</option>
        <option [value]="channel.channelid" *ngFor="let channel of channels">
          {{ channel.name }}
        </option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingChannel"></mat-progress-bar>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="me-2 d-flex align-items-center">
        Total :&nbsp;<b> {{students.length}}</b>
      </div>
      <div class="input-group" style="width: 260px !important;">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
        <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <button class="btn new-chl" matTooltip="Refresh" (click)="refresh()" type="button">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>
  <div style="overflow: auto;">
    <table mat-table [dataSource]="showstudents" matSort class="mat-elevation-z1" style="width: 100%;">


        <!-- ID Column -->
        <ng-container matColumnDef="userid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
          <td mat-cell *matCellDef="let element"> {{element.userid}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by user_name">User Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by role"> Role </th>
          <ng-container *matCellDef="let element">
            <td mat-cell *ngIf="(element.role == '1')">Instructor</td>
            <td mat-cell *ngIf="(element.role == '0' || element.role == '')">Student</td>
          </ng-container>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <ng-container *matCellDef="let element">
            <td mat-cell></td>
          </ng-container>
        </ng-container>

        <!-- Department Column -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef> Department </th>
          <ng-container *matCellDef="let element">
            <td mat-cell></td>
          </ng-container>
        </ng-container>

        <!-- Division Column -->
        <ng-container matColumnDef="division">
          <th mat-header-cell *matHeaderCellDef> Division </th>
          <ng-container *matCellDef="let element">
            <td mat-cell></td>
          </ng-container>
        </ng-container>

        <!-- Remark Column -->
        <ng-container matColumnDef="remark">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by remark"> Remark </th>
          <td mat-cell *matCellDef="let element" px-3> {{element.remark}} </td>
        </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">

      </tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="7">
          <span class="nodata" *ngIf="!isStudentLoading">Empty</span>
          <span class="nodata" *ngIf="isStudentLoading">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>

    </table>

    <mat-paginator [style.display]="studentPaginate == true ? 'block': 'none'" #paginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div>
</div>
