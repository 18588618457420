import { MessageService } from 'projects/e-learning/src/app/shared/services/message.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  authenticate: boolean = false;
  // @Input() authenticate: boolean = false;
  @Input() user: any;
  @Input() isLoading: boolean = false;
  @Input() isAdmin: boolean = false;
  
  version: string = '1.89';

  width: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
  }

  admin: boolean = false;
  isMobile: boolean =false;
  instructor: boolean = false;
  constructor(
    public allinOneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    public deviceService: DeviceDetectorService,

    private router: Router
  ) {
    const atoken = this.allinOneService.getAtoken();
    this.isMobile = this.deviceService.isMobile();
    console.log(this.isMobile)

    if (atoken) {
      this.authenticate = true;
      this.user = this.user ? this.user : this.allinOneService.user;
    }
  }

  policy_menus : any[] = [];

  ngOnInit(): void {
    this.width = window.innerWidth;
    this.policy_menus = this.allinOneService.policy_menus;
  }
  openapp() {
    const uid = this.allinOneService.user.userid;
    const url =
      'https://www.khub.cloud' +
      '/?userid=' +
      (uid.startsWith('+') ? uid.substring(1, uid.length) : uid) +
      '&username=' +
      this.allinOneService.user.username +
      '&atoken=' +
      this.allinOneService.getAtoken();
    window.open(url, '_blank');
  }
}
