<!-- <div #viewer id="viewer"></div> -->
<div class="all">
    <div class="loading-center" *ngIf="isLoading">
        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
    </div>

    <div *ngIf="!isLoading && !isError">
        <nav class="navbar navbar-expand-lg  px-3  navbar-light d-flex">
            <div class="container-fluid">
                <div class=" d-flex align-items-center mr-auto">
                    <a href="/">
                        <img src="assets/images/khub.png" alt="" class="navbar-brand-logo">
                    </a>
                    <!-- <div class="vertical-divider"></div> -->
                    <!-- <span class="course-title">{{course.coursename}}</span> -->
                    <app-breadcrumb [s_breadcrumb]="course.coursename" [t_breadcrumb]="'Course Content Preview'" (emit)="goBackPreview()"></app-breadcrumb>

                </div>

                <!-- <app-button-loader *ngIf="coursestatus == '003'" [isStillupload]="enrolling" [button_text]="'Enroll Now'"
                [margin_left]="'0'" (click)="enroll()">
            </app-button-loader> -->

                <button *ngIf="coursestatus == '003'" mat-flat-button color="primary" class="ms-3" (click)="enroll()" [disabled]="enrolling">
                    <span *ngIf="enrolling">
                        <div class="spinner-border spinner-border-sm" role="status" style="color: white !important;">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    <span *ngIf="!enrolling">
                        Enroll Now
                    </span>
                </button>

                <button *ngIf="coursestatus == '004'" mat-flat-button color="primary" class="ms-3">Sign In</button>


                <!-- <div class="unit-progress">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <div class="progress-per">
                    <strong>25%</strong> COMPLETE
                </div>
            </div> -->
            </div>
        </nav>




        <!-- <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
        <mat-icon (click)="navigateBack()">keyboard_backspace</mat-icon>
        <span class="course-title mx-2">{{course.coursename}}</span>
    </div> -->

        <div class="main-content d-flex " *ngIf="!isLoading">
            <perfect-scrollbar [scrollIndicators]="true" class="course-content w-25" *ngIf="!isMobileView">
                <!-- <div>Scrollable content</div> -->
                <div>
                    <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
                        <span class="course-title mx-2">{{course.coursename}}</span>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="tab-section d-flex px-2 py-2">
                            <div matRipple [ngClass]="{'tab-active': tab_active == 1}" (click)="tabClick(1)">
                                Content
                            </div>
                            <div matRipple [ngClass]="{'tab-active': tab_active == 3}" (click)="tabClick(3)" *ngIf="isMobileView">
                                Lecture
                            </div>
                            <div matRipple [ngClass]="{'tab-active': tab_active == 2}" (click)="tabClick(2)">
                                About
                            </div>
                        </div>
                        <div class="d-flex align-items-center px-2 me-2">
                            <span id="currentLect"> <b>({{currentIndex}} of {{lecturelist.length}}) </b></span>
                        </div>
                    </div>


                    <!-- course content -->
                    <div *ngIf="tab_active == 1" class=" custom-accordion  accordion" [ngClass]="{'tabview': isMobileView}">
                        <div class="accordion-item" *ngFor="let section of course.sections;let s = index;">
                            <div>
                                <h2 class="accordion-header" [id]="'heading' + s">
                                    <div (click)="openCollapse(s)" class="d-flex flex-row custom-accordion-button  sectionname" style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;">

                                        <!-- [ngClass]="section.active" for active section -->
                                        <div class="p-2 me-auto position-relative" style="text-align: start;">
                                            <span class="index-label">
                                                {{s+1}}.0
                                            </span>
                                            <span class="me-auto">
                                                {{section.sectionname}}
                                            </span>
                                        </div>
                                        <div class="p-2"> <i *ngIf="!section.show" id="Dropicon" class="fa fa-chevron-down" aria-hidden="true"></i>
                                            <i *ngIf="section.show" id="Dropicon" class="fa fa-chevron-up" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </h2>

                                <div *ngIf="section.show">
                                    <div class="section-accordion-body">
                                        <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                                            <h2 class="accordion-header" id="headingSubsectionOne">
                                                <div (click)="closeCollpased(s,ss)" class=" d-flex flex-row custom-accordion-button   subsectioname" style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;" *ngIf="subsection.units.length > 1 || subsection.units.length == 0 || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                                                    <div class="p-2 me-auto position-relative" style="text-align: start;">
                                                        <span *ngIf="subsection.subsectionname == '' " style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                                            {{s+1}}.{{ss+1}}
                                                        </span>
                                                        <span *ngIf="subsection.subsectionname != '' ">
                                                            <span
                                                                *ngIf="subsection.subsectionname == section.sectionname"
                                                                style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                                                {{s+1}}.{{ss+1}}

                                                            </span>
                                                        <span *ngIf="subsection.subsectionname != section.sectionname">
                                                                <span class="index-label"> {{s+1}}.{{ss+1}} </span>
                                                        <span>{{subsection.subsectionname}}</span>
                                                        </span>
                                                        </span>


                                                    </div>
                                                    <div class="p-2"> <i *ngIf="!subsection.show" id="Dropicon" class="fa fa-chevron-down subsection-icon" aria-hidden="true"></i>
                                                        <i *ngIf="subsection.show" id="Dropicon" class="fa fa-chevron-up subsection-icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div *ngIf="subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname)">
                                                    <div class="accordion-body">
                                                        <ul class="unit-list">
                                                            <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between" style="position: relative;">
                                                                <div style="position: absolute; height: 100%; left: 1rem; top: 0;" class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                                                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                                                </div>
                                                                <div class="mx-2 unit" (click)="viewLecture(unit, true)">
                                                                    <div *ngIf="unit.contenttype == 1" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span><img
                                                                                    [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                    class="unit-icon"
                                                                                    [ngClass]="unit.icon"></span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-play-circle-o me-1"
                                                                            aria-hidden="true"></i>Video</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 2" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span><img
                                                                                    [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                    class="unit-icon"></span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                        subsection.subsectionname}}
                                                                                    </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-o me-1"
                                                                            aria-hidden="true"></i>File</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 3" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span>
                                                                                <i class="fa fa-file-text me-1"
                                                                                    aria-hidden="true"></i>
                                                                                <!-- <img [src]="'../../../assets/images/writeup.png'" class="unit-icon"> -->
                                                                            </span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-text me-1"
                                                                            aria-hidden="true"></i>Article</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 4" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span>
                                                                                <i class="fas fa-link me-1"
                                                                                    aria-hidden="true"></i>
    
                                                                            </span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-text me-1"
                                                                            aria-hidden="true"></i>Article</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 5" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                                {{s+1}}.{{ss+1}}<span
                                                                                    *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                                                        </span>
                                                                        <p>
                                                                            <span>
                                                                                    <!-- <img [src]="'./../../assets/images/attach-file.png'" class = "unit-icon"> -->
                                                                                <i class = "fas fa-paperclip me-1"></i>
                                                                                </span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                    subsection.subsectionname}}
                                                                                </span>

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <mat-checkbox class="example-margin">
                                                                </mat-checkbox>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </h2>
                                            <div *ngIf="subsection.show && (subsection.units.length > 1 || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname)))">
                                                <!-- <div  class="accordion-collapse collapse" collapseSubsec" [ngClass]="section.show" aria-labelledby="headingSubsectionOne"> -->
                                                <div class="accordion-body">
                                                    <ul class="unit-list">
                                                        <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between" style="position: relative;">
                                                            <div style="position: absolute; height: 100%; left: 1rem; top: 0;" class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                                                <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                                            </div>
                                                            <div class="mx-2 unit" (click)="viewLecture(unit, true)">
                                                                <div *ngIf="unit.contenttype == 1" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span><img
                                                                                [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                class="unit-icon"
                                                                                [ngClass]="unit.icon"></span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-play-circle-o me-1"
                                                                        aria-hidden="true"></i>Video</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 2" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span><img
                                                                                [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                class="unit-icon"></span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-file-o me-1"
                                                                        aria-hidden="true"></i>File</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 3" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span>
                                                                            <i class="fa fa-file-text me-1"
                                                                                aria-hidden="true"></i>
                                                                            <!-- <img [src]="'../../../assets/images/writeup.png'" class="unit-icon"> -->
                                                                        </span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-file-text me-1"
                                                                        aria-hidden="true"></i>Article</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 4" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span>
                                                                            <i class="fas fa-link me-1"
                                                                                aria-hidden="true"></i>

                                                                        </span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-file-text me-1"
                                                                        aria-hidden="true"></i>Article</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 5" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}<span
                                                                                *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                                                    </span>
                                                                    <p>
                                                                        <span>
                                                                                <!-- <img [src]="'./../../assets/images/attach-file.png'" class = "unit-icon"> -->
                                                                            <i class = "fas fa-paperclip me-1"></i>
                                                                            </span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>

                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <mat-checkbox class="example-margin">
                                                            </mat-checkbox>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tab_active == 2" [ngClass]="{'tabview': isMobileView}">

                        <div class=" p-3 pt-lg-2 p-sm-0 pt-sm-2 mb-2">
                            <div class="container">
                                <h3 style="font-weight: 700;">About this course</h3>

                                <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                                    <p class="fs-6" [innerHTML]="course.description">
                                    </p>
                                </div>

                                <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show" *ngIf="course.description">
                                    <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                            aria-hidden="true"></i></span>
                                    <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                            aria-hidden="true"></i></span>
                                </button>
                            </div>

                        </div>

                        <div class=" p-3  p-sm-0 mb-2">
                            <div class="container">
                                <h3 style="font-weight: 700;">Instructor</h3>


                                <div class="instructor my-2" *ngIf="instructor">
                                    <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                        {{instructor.username.charAt(0)}}
                                    </div>
                                    <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                                    <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="tab_active == 3" [ngClass]="{'tabview': isMobileView}">
                        <div *ngIf="lecture.contenttype == 1">
                            <div class="video-responsive">
                                <iframe width="420" height="315" [src]="lecture.content | safe" scrolling="no" frameborder="0" allowfullscreen></iframe>
                            </div>
                            <div>
                                <div class=" p-2 pt-lg-4 p-sm-0 pt-sm-2 mb-2">
                                    <div class="container">
                                        <h3 style="font-weight: 700;">About this course</h3>

                                        <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                                            <p class="fs-6 my-2" [innerHTML]="course.description">
                                            </p>
                                        </div>

                                        <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show" *ngIf="course.description">
                                            <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                                    aria-hidden="true"></i></span>
                                            <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                                    aria-hidden="true"></i></span>
                                        </button>
                                    </div>

                                </div>

                                <section class=" p-3  p-sm-0 mb-2">
                                    <div class="container">
                                        <h3 style="font-weight: 700;">Instructor</h3>

                                        <div class="instructor my-2" *ngIf="instructor">
                                            <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                                {{instructor.username.charAt(0)}}
                                            </div>
                                            <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                                            <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                                        </div>
                                    </div>

                                </section>
                            </div>
                        </div>

                        <div *ngIf="lecture.contenttype == 2">
                            <ngx-extended-pdf-viewer *ngIf="isMobileView" [src]="allinOneService.docurl + lecture.content" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false" [showOpenFileButton]="false"
                                height="calc(100vh - 201px)">
                            </ngx-extended-pdf-viewer>
                        </div>

                        <div *ngIf="lecture.contenttype == 3" class="p-2">
                            <p [innerHTML]="lecture.content"></p>
                        </div>

                        <div *ngIf="lecture.contenttype == 4">
                            <div class="url-responsive">
                                <iframe width="100%" height="calc(100vh - 210px)" [src]="lecture.content | safe" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div *ngIf="lecture.contenttype == 5" class="attachment-responsive h-100 p-3">
                            <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                                <div class="mb-3 attachment-name">
                                    {{lecture.content}}
                                </div>
                                <div>
                                    <button mat-flat-button color="primary">
                                            <i class="fa fa-download me-2"></i>Download
                                        </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </perfect-scrollbar>
            <div class="course-content w-25" *ngIf="isMobileView">
                <!-- <div>Scrollable content</div> -->
                <div>
                    <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
                        <span class="course-title mx-2">{{course.coursename}}</span>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="tab-section d-flex px-2 py-2">
                            <div matRipple [ngClass]="{'tab-active': tab_active == 1}" (click)="tabClick(1)">
                                Content
                            </div>
                            <div matRipple [ngClass]="{'tab-active': tab_active == 3}" (click)="tabClick(3)" *ngIf="isMobileView">
                                Lecture
                            </div>
                            <div matRipple [ngClass]="{'tab-active': tab_active == 2}" (click)="tabClick(2)">
                                About
                            </div>
                        </div>
                        <div class="d-flex align-items-center px-2 me-2">
                            <span id="currentLect"> <b>({{currentIndex}} of {{lecturelist.length}}) </b></span>
                        </div>
                    </div>


                    <!-- course content -->
                    <div *ngIf="tab_active == 1" class=" custom-accordion  accordion" [ngClass]="{'tabview': isMobileView}">
                        <div class="accordion-item" *ngFor="let section of course.sections;let s = index;">
                            <div>
                                <h2 class="accordion-header" [id]="'heading' + s">
                                    <div (click)="openCollapse(s)" class="d-flex flex-row custom-accordion-button  sectionname" style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;">

                                        <!-- [ngClass]="section.active" for active section -->
                                        <div class="p-2 me-auto position-relative" style="text-align: start;">
                                            <span class="index-label">
                                                {{s+1}}.0
                                            </span>
                                            <span class="me-auto">
                                                {{section.sectionname}}
                                            </span>
                                        </div>
                                        <div class="p-2"> <i *ngIf="!section.show" id="Dropicon" class="fa fa-chevron-down" aria-hidden="true"></i>
                                            <i *ngIf="section.show" id="Dropicon" class="fa fa-chevron-up" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </h2>

                                <div *ngIf="section.show">
                                    <div class="section-accordion-body">
                                        <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                                            <h2 class="accordion-header" id="headingSubsectionOne">
                                                <div (click)="closeCollpased(s,ss)" class=" d-flex flex-row custom-accordion-button   subsectioname" style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;" *ngIf="subsection.units.length > 1 || subsection.units.length == 0 || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                                                    <div class="p-2 me-auto position-relative" style="text-align: start;">
                                                        <span *ngIf="subsection.subsectionname == '' " style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                                            {{s+1}}.{{ss+1}}
                                                        </span>
                                                        <span *ngIf="subsection.subsectionname != '' ">
                                                            <span
                                                                *ngIf="subsection.subsectionname == section.sectionname"
                                                                style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                                                {{s+1}}.{{ss+1}}

                                                            </span>
                                                        <span *ngIf="subsection.subsectionname != section.sectionname">
                                                                <span class="index-label"> {{s+1}}.{{ss+1}} </span>
                                                        <span>{{subsection.subsectionname}}</span>
                                                        </span>
                                                        </span>


                                                    </div>
                                                    <div class="p-2"> <i *ngIf="!subsection.show" id="Dropicon" class="fa fa-chevron-down subsection-icon" aria-hidden="true"></i>
                                                        <i *ngIf="subsection.show" id="Dropicon" class="fa fa-chevron-up subsection-icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div *ngIf="subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname)">
                                                    <div class="accordion-body">
                                                        <ul class="unit-list">
                                                            <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between" style="position: relative;">
                                                                <div style="position: absolute; height: 100%; left: 1rem; top: 0;" class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                                                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                                                </div>
                                                                <div class="mx-2 unit" (click)="viewLecture(unit, true)">
                                                                    <div *ngIf="unit.contenttype == 1" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span><img
                                                                                    [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                    class="unit-icon"
                                                                                    [ngClass]="unit.icon"></span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-play-circle-o me-1"
                                                                            aria-hidden="true"></i>Video</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 2" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span><img
                                                                                    [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                    class="unit-icon"></span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                        subsection.subsectionname}}
                                                                                    </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-o me-1"
                                                                            aria-hidden="true"></i>File</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 3" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span>
                                                                                <i class="fa fa-file-text me-1"
                                                                                    aria-hidden="true"></i>
                                                                                <!-- <img [src]="'../../../assets/images/writeup.png'" class="unit-icon"> -->
                                                                            </span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-text me-1"
                                                                            aria-hidden="true"></i>Article</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 4" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}.{{su+1}}
                                                                        </span>
                                                                        <p>
                                                                            <span>
                                                                                <i class="fas fa-link me-1"
                                                                                    aria-hidden="true"></i>
    
                                                                            </span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>
                                                                            <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-text me-1"
                                                                            aria-hidden="true"></i>Article</span> -->
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="unit.contenttype == 5" class="d-flex align-items-center position-relative">
                                                                        <span class="unit-index-label">
                                                                                {{s+1}}.{{ss+1}}<span
                                                                                    *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                                                        </span>
                                                                        <p>
                                                                            <span>
                                                                                    <!-- <img [src]="'./../../assets/images/attach-file.png'" class = "unit-icon"> -->
                                                                                <i class = "fas fa-paperclip me-1"></i>
                                                                                </span>
                                                                            <span> {{unit.unitname ? unit.unitname :
                                                                                    subsection.subsectionname}}
                                                                                </span>

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <mat-checkbox class="example-margin">
                                                                </mat-checkbox>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </h2>
                                            <div *ngIf="subsection.show && (subsection.units.length > 1 || subsection.units.length == 0 || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname)))">
                                                <!-- <div  class="accordion-collapse collapse" collapseSubsec" [ngClass]="section.show" aria-labelledby="headingSubsectionOne"> -->
                                                <div class="accordion-body">
                                                    <ul class="unit-list">
                                                        <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between" style="position: relative;">
                                                            <div style="position: absolute; height: 100%; left: 1rem; top: 0;" class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                                                <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                                            </div>
                                                            <div class="mx-2 unit" (click)="viewLecture(unit, true)">
                                                                <div *ngIf="unit.contenttype == 1" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span><img
                                                                                [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                class="unit-icon"
                                                                                [ngClass]="unit.icon"></span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-play-circle-o me-1"
                                                                        aria-hidden="true"></i>Video</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 2" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span><img
                                                                                [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                                                                class="unit-icon"></span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-file-o me-1"
                                                                        aria-hidden="true"></i>File</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 3" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span>
                                                                            <i class="fa fa-file-text me-1"
                                                                                aria-hidden="true"></i>
                                                                            <!-- <img [src]="'../../../assets/images/writeup.png'" class="unit-icon"> -->
                                                                        </span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-file-text me-1"
                                                                        aria-hidden="true"></i>Article</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 4" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                                                    </span>
                                                                    <p>
                                                                        <span>
                                                                            <i class="fas fa-link me-1"
                                                                                aria-hidden="true"></i>

                                                                        </span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                            ''}}
                                                                        </span>
                                                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                        class="fa fa-file-text me-1"
                                                                        aria-hidden="true"></i>Article</span> -->
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="unit.contenttype == 5" class="d-flex align-items-center position-relative">
                                                                    <span class="unit-index-label">
                                                                            {{s+1}}.{{ss+1}}<span
                                                                                *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                                                    </span>
                                                                    <p>
                                                                        <span>
                                                                                <!-- <img [src]="'./../../assets/images/attach-file.png'" class = "unit-icon"> -->
                                                                            <i class = "fas fa-paperclip me-1"></i>
                                                                            </span>
                                                                        <span> {{unit.unitname ? unit.unitname :
                                                                                subsection.subsectionname}}
                                                                            </span>

                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <mat-checkbox class="example-margin">
                                                            </mat-checkbox>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tab_active == 2" [ngClass]="{'tabview': isMobileView}">

                        <div class=" p-3 pt-lg-2 p-sm-0 pt-sm-2 mb-2">
                            <div class="container">
                                <h3 style="font-weight: 700;">About this course</h3>

                                <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                                    <p class="fs-6" [innerHTML]="course.description">
                                    </p>
                                </div>

                                <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show" *ngIf="course.description">
                                    <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                            aria-hidden="true"></i></span>
                                    <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                            aria-hidden="true"></i></span>
                                </button>
                            </div>

                        </div>

                        <div class=" p-3  p-sm-0 mb-2">
                            <div class="container">
                                <h3 style="font-weight: 700;">Instructor</h3>


                                <div class="instructor my-2" *ngIf="instructor">
                                    <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                        {{instructor.username.charAt(0)}}
                                    </div>
                                    <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                                    <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="tab_active == 3" [ngClass]="{'tabview': isMobileView}">
                        <div *ngIf="lecture.contenttype == 1">
                            <div class="video-responsive">
                                <iframe width="420" height="315" [src]="lecture.content | safe" scrolling="no" frameborder="0" allowfullscreen></iframe>
                            </div>
                            <div>
                                <div class=" p-2 pt-lg-4 p-sm-0 pt-sm-2 mb-2">
                                    <div class="container">
                                        <h3 style="font-weight: 700;">About this course</h3>

                                        <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                                            <p class="fs-6 my-2" [innerHTML]="course.description">
                                            </p>
                                        </div>

                                        <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show" *ngIf="course.description">
                                            <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                                    aria-hidden="true"></i></span>
                                            <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                                    aria-hidden="true"></i></span>
                                        </button>
                                    </div>

                                </div>

                                <section class=" p-3  p-sm-0 mb-2">
                                    <div class="container">
                                        <h3 style="font-weight: 700;">Instructor</h3>

                                        <div class="instructor my-2" *ngIf="instructor">
                                            <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                                {{instructor.username.charAt(0)}}
                                            </div>
                                            <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                                            <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                                        </div>
                                    </div>

                                </section>
                            </div>
                        </div>

                        <div *ngIf="lecture.contenttype == 2">
                            <ngx-extended-pdf-viewer *ngIf="isMobileView" [src]="allinOneService.docurl + lecture.content" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false" [showOpenFileButton]="false"
                                height="calc(100vh - 201px)">
                            </ngx-extended-pdf-viewer>
                        </div>

                        <div *ngIf="lecture.contenttype == 3" class="p-2">
                            <p [innerHTML]="lecture.content"></p>
                        </div>

                        <div *ngIf="lecture.contenttype == 4" class="h-100">
                            <div class="h-100 url-responsive">
                                <iframe width="100%" height="100%" [src]="lecture.content | safe" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>

                        <div *ngIf="lecture.contenttype == 5" class="attachment-responsive h-100 p-3">
                            <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                                <div class="mb-3 attachment-name">
                                    {{lecture.content}}
                                </div>
                                <div>
                                    <button mat-flat-button color="primary">
                                            <i class="fa fa-download me-2"></i>Download
                                        </button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>


            <div class="learn-content w-75">
                <div class="web-view  p-3">
                    <div class="d-flex justify-content-between align-items-center mb-4 mt-2 d-none d-sm-flex">
                        <div>
                            <div *ngIf="!isFirst" class="d-flex align-items-baseline d-none d-sm-flex">
                                <a href='javascript:;' (click)='previousLecture();' class="previous-next">
                                    <i class="fa fa-chevron-left"> </i>
                                    <span id="previousNext" class="mx-2">Previous</span>
                                </a>
                            </div>
                        </div>



                        <div class="d-flex align-items-center d-none d-sm-flex" *ngIf="lecture.unitname != '' && lecture.contenttype == 1">

                            <img [src]="'../../../assets/images/' +lecture.icon+ '.png'" class="title-icon">
                            <span class="course-title ms-2"> {{lecture.unitname }}
                                </span>
                        </div>

                        <div class="d-flex align-items-center d-none d-sm-flex" *ngIf="lecture.unitname != '' &&lecture.contenttype == 2">

                            <img [src]="'../../../assets/images/' +lecture.icon+ '.png'" class="title-icon">
                            <span class="course-title ms-2"> {{lecture.unitname }}
                                </span>
                        </div>
                        <div class="d-flex align-items-center d-none d-sm-flex" *ngIf="lecture.unitname != '' &&lecture.contenttype == 3">

                            <i class="fa fa-file-text me-1" aria-hidden="true"></i>
                            <span class="course-title ms-2"> {{lecture.unitname }}
                                </span>
                        </div>

                        <div class="d-flex align-items-center d-none d-sm-flex" *ngIf="lecture.unitname != '' &&lecture.contenttype == 4">

                            <i class="fa fa-link me-1" aria-hidden="true"></i>
                            <span class="course-title ms-2"> {{lecture.unitname }}
                        </span>
                        </div>


                        <span *ngIf="lecture.unitname == ''">
                                <span class="current-no1 align-items-center">({{currentIndex}} of
                                    {{lecturelist.length}})</span>
                        </span>


                        <div class="current-unit">
                            <span class="current-no" *ngIf="lecture.unitname != '' ">({{currentIndex}} of
                                {{lecturelist.length}})</span>
                            <div *ngIf="!isLast" class="d-flex align-items-baseline d-none d-sm-flex">
                                <a href='javascript:;' (click)='nextLecture();' class="previous-next" style="text-align: right;">
                                    <span id="previousNext" class="mx-2">Next</span>
                                    <i class="fa fa-chevron-right"> </i>
                                </a>
                            </div>
                        </div>

                    </div>


                    <div class="video-responsive" *ngIf="lecture.contenttype == 1 && !isMobileView">
                        <iframe width="420" height="315" [src]="lecture.content | safe" scrolling="no" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div *ngIf="lecture.contenttype == 2" class="learn-content-web">
                        <ngx-extended-pdf-viewer *ngIf="!isMobileView" [src]="allinOneService.docurl + lecture.content" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false" [showOpenFileButton]="false"
                            height="calc(100vh - 75px)"></ngx-extended-pdf-viewer>
                    </div>

                    <div *ngIf="lecture.contenttype == 3" class="pt-1 learn-content-web">
                        <p [innerHTML]="lecture.content"></p>
                    </div>

                    <div class="url-responsive" *ngIf="lecture.contenttype == 4 && !isMobileView">
                        <iframe class="url-res" width="420" height="395" [src]="lecture.content | safe" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div *ngIf="lecture.contenttype == 5 && !isMobileView" class="attachment-responsive">
                        <div class="w-100 d-flex flex-column align-items-center justify-content-center" style="height: 300px;">
                            <div class="mb-3 attachment-name">
                                {{lecture.content}}
                            </div>
                            <div>
                                <button mat-flat-button color="primary">
                                        <i class="fa fa-download me-2"></i>Download
                                    </button>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="web-view-about  p-3">
                    <div class=" p-3 pt-lg-4 p-sm-0 pt-sm-2 mb-2">
                        <div class="container">
                            <h3 style="font-weight: 700;">About this course</h3>

                            <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                                <p class="fs-6 my-2" [innerHTML]="course.description">
                                </p>
                            </div>

                            <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show" *ngIf="course.description">
                                <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                        aria-hidden="true"></i></span>
                                <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                        aria-hidden="true"></i></span>
                            </button>
                        </div>

                    </div>

                    <section class=" p-3  p-sm-0 mb-2">
                        <div class="container">
                            <h3 style="font-weight: 700;">Instructor</h3>

                            <div class="instructor my-2" *ngIf="instructor">
                                <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                    {{instructor.username.charAt(0)}}
                                </div>
                                <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                                <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                            </div>
                        </div>

                    </section>
                </div>

            </div>


            <footer class="small-view">
                <nav class="navbar fixed-bottom navbar-light bg-light ">
                    <!-- <div class="container-fluid d-flex"> -->
                    <button href='javascript:;' (click)='previousLecture();' class="btn next-prev prev" [disabled]="isFirst">
                        <i class="fa fa-chevron-left left-right"> </i>
                        <span style="color: white;" class="mx-2">Previous</span>
                    </button>

                    <button href='javascript:;' (click)='nextLecture();' class="btn next-prev next" [disabled]="isLast">
                        <span style="color: white;" class="mx-2">Next</span>
                        <i class="fa fa-chevron-right left-right"> </i>
                    </button>
                    <!-- </div> -->
                </nav>
            </footer>
        </div>



        <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
            <div class="offcanvas-header">
                <button mat-flat-button data-bs-dismiss="offcanvas" color="primary" style="    position: absolute;
            top: 10px;
            right: 15px;
            z-index: 3;">Close</button>
                <!-- <nav class="breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="/courses">My Courses</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> {{lecture.unitname}}</li>
                </ol>
            </nav> -->
            </div>
            <div class="offcanvas-body">

                <div *ngIf="lecture.contenttype == 2" style=" z-index: 2; height: 100vh;
            position: absolute;
            left: 0;
            right: 0;
            top: 53px;background-color: #fff;">
                    <!-- <div class="loading-center" *ngIf="lecture.contenttype == 2 && isDocumentLoading">
                    <mat-spinner [strokeWidth]="10" style="zoom: 0.4;"></mat-spinner>
                </div> -->
                    <!-- <div #viewercanvas [id]="'viewercanvas'" style="width:100%; height:calc(100vh - 75px);"></div> -->
                    <ngx-extended-pdf-viewer *ngIf="isMobileView" [src]="allinOneService.docurl + lecture.content" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [showPresentationModeButton]="true" [showDownloadButton]="false" [showOpenFileButton]="false"
                        height="calc(100vh - 75px)">
                    </ngx-extended-pdf-viewer>
                </div>

                <span *ngIf="lecture.contenttype == 2" style="    position: absolute;
            top: 50%;
            left: 40%;">Loading...
                </span>



                <div class="d-flex" *ngIf="lecture.contenttype == 3">
                    <div class="pt-1">
                        <p [innerHTML]="lecture.content"></p>
                    </div>
                </div>

                <button mat-flat-button data-bs-dismiss="offcanvas" color="primary" style="    position: absolute;
            top: 10px;
            right: 15px;
            z-index: 3;">Close</button>
            </div>
        </div>
    </div>


    <div class="check-error" *ngIf="isError && !isLoading">
        <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
        <button type="button" (click)="reload()" class="btn btn-outline-secondary">
            Reload
        </button>
    </div>
</div>