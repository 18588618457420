import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-template-setup',
  templateUrl: './template-setup.component.html',
  styleUrls: ['./template-setup.component.scss'],
})
export class TemplateSetupComponent implements OnInit, OnDestroy {
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  isEditing: boolean = false;

  templateForm = {
    templateid: '',
    name: '',
    description: '',
    channelid: '',
  };

  subscription!: Subscription;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private dialogRef: MatDialogRef<TemplateSetupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.isEditing) {
        this.isEditing = true;
        this.templateForm = {
          templateid: data.template.fbtmpid,
          name: data.template.name,
          description: data.template.description,
          channelid: '',
        };
      }
      this.templateForm.channelid = data.channelid;
    }
  }
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit(): void {}

  save() {
    this.formSubmitted = true;
    if (this.templateForm.name == '') {
      return;
    }
    this.isLoading = true;
    this.dialogRef.disableClose = true;

    var fun = this.isEditing
      ? this.courseService.editFeedbackTemplate(
          this.templateForm.templateid,
          this.templateForm.name,
          this.templateForm.description,
          this.templateForm.channelid
        )
      : this.courseService.createFeedbackTemplate(
          this.templateForm.name,
          this.templateForm.description,
          this.templateForm.channelid
        );
    this.subscription = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if(!this.isEditing){
            this.templateForm.templateid = res.fbtmpid
          }
          this.dialogRef.close(this.templateForm);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
