<div id="main-page" class="ss">
  <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" style="height: 500px;">
    <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
  </div>

  <section *ngIf="!isLoading" style="user-select: none;" id="container">

    <div class="container container-button">
      <div style="color: var(--main-color);margin-top: 5px;">
        <mat-icon (click)="cancel()"
          style="width: 23px !important; height: 23px !important; font-size: 23px !important;cursor: pointer;">
          arrow_back
        </mat-icon>
      </div>
      <div>
        <button color="primary" style="padding-top: 7px;" class="btn new-class ms-2 m-3"
          (click)="resetQuiz(elementAllData,element,unitname)">
          <!-- <img class="excel-icon" src="../../../assets/images/pdf.png"> -->
          Reset
        </button>
        <button color="primary" class="btn new-class ms-2 m-3" (click)="downloadQuizPDF()">
          <img class="excel-icon" src="../../../assets/images/pdf.png">
          Export PDF
        </button>
      </div>
    </div>

    <div class="container" id="quiz-pdf-print" [ngClass]="{'question' : !smallscreenview && !isfeedback, 'question-small': smallscreenview  && !isfeedback,
        'question-f' : !smallscreenview && isfeedback, 'question-small-f': smallscreenview  && isfeedback
      }">
      <div id="main-page-t">
        <!-- [ngClass]="{'sticky-title' : smallscreenview , 'sticky-title-mobile' : !smallscreenview}" -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="mb-1">{{coursename}}</h2>
            <!-- <i class="fas fa-clipboard-list me-2" aria-hidden="true"></i> -->
            <h3 class="mb-1">{{unitname == '' ? "Quiz"
        :
        unitname}}
            </h3>
            <h3 class="mb-1">{{studentname}}</h3>
            <h3 class="mb-1" *ngIf="isfeedback">
              <strong>
                Grade : 
                <span *ngIf="grading == '001'">{{ mark }}/{{ totalmark}} points</span>
                <span *ngIf="grading == '002'" style="color: green;">{{score | number:"1.1"}}%</span>
              </strong>
            </h3>
            <h3>{{question.length}} Questions</h3>
          </div>
          <!-- <div class="d-flex justify-content-end" *ngIf="!isfeedback">

  <button [disabled]="issaveing" mat-flat-button color="primary" class="mt-2 " type="submit"
    style="height: 40px;" (click)="submit()">
    <span style="padding: 0;margin: 0;" *ngIf="issaveing"
      class="d-flex align-items-center justify-content-center p-2">
      <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>

    </span>
    <span *ngIf="!issaveing">Submit</span>
  </button>

</div> -->
        </div>

        <div class="question-list mt-3" *ngFor="let ques of question;let i = index;">

          <ul class="questino-radio">

            <li class="question-li">
              <div class="d-flex w-100">
                <div [ngClass]="{dropdwonnumber : ques.type == '005', quiznumber : ques.type != '005' }">
                  {{i+1}}.&nbsp;
                </div>
                <div class="d-flex flex-column w-100 ">
                  <div class="d-flex justify-content-between w-100">
                    <div *ngIf="ques.type != '005' && ques.type !== '003'">
                      <span [innerHtml]="ques.type == '004' ? ques.question.title : ques.question">
                      </span>
                      <!-- <span *ngIf="ques.type != '005'" [innerHtml]="ques.type == '004' ? ques.question.title : ques.question">
                </span> -->
                      <!-- <span *ngIf="ques.type == '005'" [innerHtml]="displayDropDownQuiz(ques)"></span> -->
                      <!-- <span *ngIf="ques.type == '005'" [innerHtml]="changeDropDown(ques.question)"></span> -->
                      <!-- <span *ngIf="ques.type == '005'">{{changeDropDown(ques.question)}}</span> -->
                    </div>
                    <div *ngIf="ques.type == '005'">
                      <div class="align-items-center row " style="padding-left: 15px;">
                        <span *ngFor="let item of ques.formattedQuiz"
                          style="width: fit-content;padding: 0px !important;align-self: center;">
                          <!-- <p  *ngIf="item.type == '001';else dropDownDisplay">{{item.text}}</p> -->
                          <p *ngIf="item.type == '001';else dropDownDisplay" [innerHtml]="item.text"></p>
                          <ng-template #dropDownDisplay>
                            <span>
                              <select [disabled]="isfeedback" style="margin: 0 10px; margin-bottom: 10px; padding: 6px 8px; cursor: pointer; border-radius: 5px;">
                                <option [value]="null">-</option>
                                <option [selected]="item.selectOption == i"
                                  *ngFor="let dropdown of item.option;let i = index" [ngValue]="i">{{dropdown}}
                                </option>
                              </select>
                            </span>
                          </ng-template>
                        </span></div>
                    </div>
                    <div *ngIf="ques.type == '003'">
                      <div style="padding-right: 5px;padding-left: 5px;padding-bottom: 5px;">
                        <span *ngFor="let item of ques.formattedQuiz;let j = index">
                          <span *ngIf="item.type == '001';else fillinblankDisplay" [innerHtml]="item.text"></span>
                          <ng-template #fillinblankDisplay>
                            <!-- <input type="text" class="form-control blank" style="width: fit-content;" [id]="'question'+i+j"
                      [(ngModel)]="item.selected" [disabled]="isfeedback" (ngModelChange)="outPut(ques)"> -->
                            <span class="me-2">
                              <span class="fillinblankformat text-primary">{{intToRomanInt(item.index+1)}}</span>
                            </span>
                          </ng-template>
                        </span>
                      </div>
                      <div *ngFor="let item of ques.formattedQuiz;let j = index" class="pt-2">
                        <div *ngIf="item.type == '002'" class='d-flex align-items-baseline'>
                          <span class="me-2">{{intToRomanInt(item.index+1)}}.</span>
                          <input type='text' class='form-control blank' [id]="'question'+i+j"
                            [(ngModel)]="item.selected" [disabled]="isfeedback">
                        </div>
                      </div>
                    </div>

                    <div class="point-container" *ngIf="isfeedback && !ques.countbyquantity &&  ques.type != '004'">
                      <span class="point"> {{ques.score}}/{{ques.mark}}
                        {{ques.score > 1 ? 'points' : 'point' }}</span>
                    </div>
                    <div class="point-container" *ngIf="isfeedback && ques.countbyquantity && ques.type == '004'">
                      <span class="point"> {{ques.score}}/{{ques.mark*ques.question.questions.length}}
                        {{ques.question.questions.length > 1 ? 'points' : 'point' }}</span>
                    </div>

                    <!-- <div class="point-container" *ngIf="isfeedback && !countbyquantity">
                <span class="point"> {{ques.correct ? '1' : '0'}}/1 point</span>
              </div>

              <div class="point-container" *ngIf="isfeedback && countbyquantity">
                <span class="point"> {{ques.correct ? '1' : '0'}}/{{ques.question.questions.length}} point</span>
              </div> -->
                  </div>

                  <div class="row mt-3" *ngIf="ques.imagelist.length > 0">
                    <div class="col-md-6 px-3 mb-3" *ngFor="let img of ques.imagelist; let index = index">
                      <div class="quiz-img-container" (click)="imageViewer(ques.imagelist, index)">
                        <img [src]="img.imageurl">
                      </div>
                    </div>
                  </div>

                  <div *ngIf="ques.type == '004'">
                    <div cdkDropListGroup>
                      <div class="anlist-example-container">
                        <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="ques.answerlist"
                          class="answer-list" (cdkDropListDropped)="restoreDrop($event)"
                          [cdkDropListDisabled]="isfeedback">

                          <div *ngFor="let item of ques.answerlist; let aindex = index"
                            class="d-flex align-items-center" cdkDrag [cdkDragData]="aindex">

                            <div *ngIf="item.type != 'image'" class="answer-box">
                              <div class="custom-placeholder" *cdkDragPlaceholder></div>
                              <span>{{ item.answer}}</span>
                            </div>

                            <div *ngIf="item.type == 'image'" class="answer-box">
                              <div class="custom-placeholder" *cdkDragPlaceholder></div>
                              <div class="" (click)="matchingImageViewer(item.imageurl)">
                                <img [src]="item.imageurl"
                                  style="max-width: 80px; max-height: 80px; object-fit: contain;">
                              </div>
                            </div>

                          </div>

                          <!-- <div class="answer-box" *ngFor="let item of ques.answerlist; let aindex = index" cdkDrag
                  [cdkDragData]="aindex">
                  <div class="custom-placeholder" *cdkDragPlaceholder></div>

                  <span>{{ item.answer}}</span>

                  <div class="" (click)="matchingImageViewer(item.imageurl)">
                    <img [src]="item.imageurl"
                      style="max-width: 80px; max-height: 80px; object-fit: contain;">
                  </div>
                </div> -->

                        </div>
                      </div>
                      <div class="example-container">
                        <div *ngFor="let ans of ques.question.questions; let qindex = index"
                          class="d-flex justify-content-between" [ngClass]="{'background-gray': qindex % 2 == 0}">
                          <div class="matching-ques">
                            <!-- {{ans.question}} -->
                            <span *ngIf="ans.type != 'image' ">{{ans.question}}</span>
                            <!-- <img *ngIf="ans.type == 'image'" [src]="ans.imageurl"
                    style="max-width: 80px; max-height: 80px; object-fit: contain;" alt=""> -->
                            <div *ngIf="ans.type == 'image'" (click)="matchingImageViewer(ans.imageurl)">
                              <img [src]="ans.imageurl" style="max-width: 80px; max-height: 80px; object-fit: contain;">
                            </div>
                          </div>
                          <div cdkDropList [cdkDropListData]="ans.anscontanier" class="example-list col-6 col-md-6"
                            (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="isfeedback">
                            <div class="example-box" *ngFor="let item of ans.anscontanier" cdkDrag>
                              <div class="custom-placeholder" *cdkDragPlaceholder></div>
                              <!-- {{item.answer}} -->
                              <span *ngIf="item.type != 'image'">{{ item.answer}}</span>
                              <!-- <img *ngIf="item.type == 'image'" [src]="item.imageurl"
                      style="max-width: 80px; max-height: 80px; object-fit: contain;" alt=""> -->

                              <div *ngIf="item.type == 'image'" (click)="matchingImageViewer(item.imageurl)">
                                <img [src]="item.imageurl"
                                  style="max-width: 80px; max-height: 80px; object-fit: contain;">
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="isfeedback && ques.type != '004'">
                      <div class="alert alert-danger feedback feedback-danger" *ngIf="!ques.correct">
                        <div class="d-flex">
                          <mat-icon>cancel</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Incorrect
                          </div>
                        </div>
                      </div>
                      <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                        <div class="d-flex">
                          <mat-icon>check_circle</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Correct
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="isfeedback && ques.type == '004'">
                      <div class="alert alert-danger feedback feedback-danger" *ngIf="!ques.correct && ques.score == 0">
                        <div class="d-flex">
                          <mat-icon>cancel</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Incorrect
                          </div>
                        </div>
                      </div>

                      <div class="alert alert-primary feedback" *ngIf="!ques.correct && ques.score > 0">
                        <div class="d-flex">
                          <mat-icon>check_circle</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            {{ ques.score }} {{ ques.score > 1 ? 'questions' : 'question' }} correct
                          </div>
                        </div>
                      </div>

                      <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                        <div class="d-flex">
                          <mat-icon>check_circle</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Correct
                          </div>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                  <div *ngIf="ques.type != '004'">
                    <ol class="questino-radio">
                      <li *ngFor="let ans of ques.answerlist;let j = index;">
                        <div class="form-check " *ngIf="ques.type=='001' || ques.type == '002'">

                          <input *ngIf="ques.type=='002'" class="form-check-input" type="checkbox" [name]="'question'+i"
                            [id]="'question'+i+j" [checked]="ques.checkboxans.includes(ans.answerid.toString())"
                            [disabled]="isfeedback">

                          <input *ngIf="ques.type=='001'" class="form-check-input" type="radio" [name]="'question'+i"
                            [id]="'question'+i+j" [checked]="j == ques.selected" [disabled]="isfeedback">

                          <!-- <input *ngIf="ques.type=='003'" class="form-check-input" type="text" [name]="'question'+i" [id]="'question'+i+j"  [disabled]="isfeedback"> -->

                          <label class="form-check-label w-100" [for]="'question'+i+j">
                            <span [innerHtml]="ans.answer"></span>
                          </label>
                        </div>
                        <!-- <div *ngIf="ques.type=='003'">
                    <input type="text" class="form-control blank" id="formGroupExampleInput"
                      [placeholder]="isfeedback ? '' :'Fill the answer.'" [id]="'question'+i+j"
                      [(ngModel)]="ques.selected" [disabled]="isfeedback"
                      (keyup.enter)="outPut(ques)">
                  </div> -->
                        <ng-container *ngIf="ques.type == '002' && isfeedback">
                          <div class="alert alert-danger feedback feedback-danger m-0 mt-2"
                            *ngIf="!ques.correctanswer.includes(ans.answerid.toString()) && ques.checkboxans.includes(ans.answerid.toString())">
                            <div class="d-flex">
                              <mat-icon>cancel</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div class="d-flex">
                                Incorrect
                              </div>
                              <div class="mt-2" *ngIf="ans.explanation">
                                {{ans.explanation}}
                              </div>
                            </div>
                          </div>
                          <div class="alert alert-success feedback feedback-success m-0 mt-2"
                            *ngIf="ques.correctanswer.includes(ans.answerid.toString()) && ques.checkboxans.includes(ans.answerid.toString())">
                            <div class="d-flex">
                              <mat-icon>check_circle</mat-icon>
                            </div>
                            <div class="d-flex flex-column">
                              <div>
                                Correct
                              </div>
                              <div class="mt-2" *ngIf="ans.explanation">
                                {{ans.explanation}}
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </li>
                    </ol>
                    <ng-container *ngIf="(ques.type == '001' || ques.type == '003') && isfeedback">
                      <div class="alert alert-danger feedback feedback-danger" *ngIf="!ques.correct">
                        <div class="d-flex">
                          <mat-icon>cancel</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Incorrect
                          </div>
                          <div class="mt-2" *ngIf="ques.explaination">
                            {{ques.explaination}}
                          </div>
                        </div>
                      </div>
                      <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                        <div class="d-flex">
                          <mat-icon>check_circle</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Correct
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="ques.type == '002' && isfeedback && !ques.selectall">
                      <div class="alert alert-secondary feedback feedback-secondary">
                        <div class="d-flex">
                          <mat-icon></mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            You did not select all the correct answers.
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="ques.type == '005' && isfeedback">
                      <div *ngIf="!ques.correct" class="alert alert-danger feedback feedback-danger">
                        <div class="d-flex">
                          <mat-icon>cancel</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Incorrect
                          </div>
                          <!-- <ng-container *ngFor="let item of ques.correctanswer;let ind = index">
                            <div class="mt-2" *ngIf="item != ques.answerid[ind]">
                              At Dropdwon No.{{ind+1}}, {{ques.answerlist[ind].explanation}}
                            </div>
                          </ng-container> -->

                        </div>
                      </div>
                      <div class="alert alert-success feedback feedback-success" *ngIf="ques.correct">
                        <div class="d-flex">
                          <mat-icon>check_circle</mat-icon>
                        </div>
                        <div class="d-flex flex-column">
                          <div>
                            Correct
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
