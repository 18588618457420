<h2>
  {{student}} - {{unitname}}
</h2>
<div class="form-group" class="mb-3">
  <label for="grade" class="mb-2">
    Grade
  </label>
  <div class="input-group mb-3">
    <input type="number" class="form-control" id="grade" name="grade" [(ngModel)]="grade" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
    <div class="input-group-append">
      <span class="input-group-text">/&nbsp;{{totalgrade}}</span>
    </div>
  </div>
</div>

<div class="actions-button">
  <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="dismiss()" [disabled]="isLoading">
    Cancel
  </button>

  <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="save()">
  </app-button-loader>

</div>