import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Library } from '../../shared/models/Library';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-share-library',
  templateUrl: './share-library.component.html',
  styleUrls: ['./share-library.component.scss'],
})
export class ShareLibraryComponent implements OnInit {
  isSaving: boolean = false;

  shareList: any[] = [];
  id: string = '';
  submitted: boolean = false;
  isLoading: boolean = false;
  filename: string = '';
  index: string = '';

  form = this.formBuider.group({
    userid: ['', Validators.required],
  });

  get formControl() {
    return this.form.controls;
  }
  constructor(
    public dialogRef: MatDialogRef<ShareLibraryComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      console.log('share');
      console.log(data);
      this.id = data.datalist.id;
      this.filename = data.datalist.filename;
      console.log('share' + this.id);
    }
  }

  ngOnInit(): void {
    this.getShareUser();
  }

  getShareUser() {
    this.isLoading = true;

    this.courseService.getShareUser(this.id).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.shareList = res.shareduserlist;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.messageService.openSnackBar(
            res.message || res.error || res.status,
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  sumbitForm() {
    this.submitted = true;
    if (this.formControl.userid.invalid) {
      return;
    }

    this.isSaving = true;
    const data = {
      id: this.id.toString(),
      shareuserid: this.allinoneService.checkUserId(
        this.formControl.userid.value.toString().trim()
      ),
    };
    console.log(data);
    this.shareLibrary(data);
  }

  shareLibrary(data: any) {
    this.isSaving = true;

    this.courseService.shareLibrary(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Successful', 'success');
          this.dialogRef.close({ success: true, data: res.data });
        } else {
          this.isSaving = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(
            res.error || res.status || res.message,
            'warn'
          );
        }
      },
      (err) => {
        this.isSaving = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  cancel() {
    this.dialogRef.close({ success: false, data: {} });
  }

  deleteshareuser(share: any, index: any) {
    this.index = index;

    console.log(share.userid);
    console.log(this.id);
    this.courseService.deleteShareUser(this.id, share.userid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.shareList.splice(index, 1);
          this.index = '';
        } else {
          this.index = '';
          this.messageService.openSnackBar(
            res.error || res.status || res.message,
            'warn'
          );
        }
      },
      (err) => {
        this.index = '';
      }
    );
  }
}
