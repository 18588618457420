<app-breadcrumb s_breadcrumb="Analytics" [t_breadcrumb]="channelname" (emit)="goTo(role + '/analytics')">
</app-breadcrumb>
<div class="content">

  <div class="d-flex flex-wrap align-items-center mt-3 justify-content-between">
    <div class="d-flex align-items-center flex-wrap mb-3">
      <!-- <div class="me-3">
        View By
      </div> -->
      <div class="form-check me-5">
        <input class="form-check-input" type="radio" name="viewby" id="dashboard" value="dashboard" [(ngModel)]="viewby"
          (ngModelChange)="viewbyChange()">
        <label class="form-check-label" for="dashboard">
          Dashboard
        </label>
      </div>
      <div class="form-check me-5">
        <input class="form-check-input" type="radio" name="viewby" id="courses" value="courses" [(ngModel)]="viewby"
          (ngModelChange)="viewbyChange()">
        <label class="form-check-label" for="courses">
          Courses
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="viewby" id="students" value="students" [(ngModel)]="viewby"
          (ngModelChange)="viewbyChange()">
        <label class="form-check-label" for="students">
          Students
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3" *ngIf="viewby == 'dashboard'">
      <div>
        <select class="form-select" required (ngModelChange)="changeDepartment($event)" [(ngModel)]="deptid"
          #dep="ngModel">
          <option value="">All Departments</option>
          <option *ngFor="let dept of departmentlist" [value]="dept">{{dept}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingTypes"></mat-progress-bar>
      </div>

      <!-- <div class = "ms-2">
        <select class="form-select" required (ngModelChange)="changeClassCode($event)" [(ngModel)]="postid">
          <option value="">All Posts</option>
          <option *ngFor="let classcode of classcodelist" [value]="classcode.id">{{classcode.name}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingTypes"></mat-progress-bar>
      </div> -->

    </div>
    <div class="d-flex align-items-center mb-3" *ngIf="viewby != 'dashboard'">
      <div class="me-2 d-flex align-items-center" *ngIf="viewby == 'courses'">
        Total Course : &nbsp; <b> {{viewby == 'courses' ? courses.length : ''}} </b> &nbsp;| Total Time Spent : &nbsp;
        <b>{{viewby ==
          'courses' ? totaltime : students.length }}</b>
      </div>
      <div class="me-2 d-flex align-items-center" *ngIf="viewby != 'courses'">
        Total -&nbsp; <b>{{viewby ==
          'courses' ? totaltime : publicValue == "001"? publicstudents.length: students.length }}</b>
      </div>
      <div class="input-group" style="width: 260px !important;">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
        <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color);color: var(--main-color);">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <button class="btn new-chl" matTooltip="Refresh" (click)="refresh()" type="button">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>

  <div *ngIf="viewby == 'dashboard'">
    <!-- <div class="d-flex row flex-wrap mb-3">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-2">
        <div class="card card-body">
          <h3>Enroll Counts by Courses</h3>
          <canvas *ngIf="!gettingCourseAnalytic" baseChart [datasets]="courseCount" [labels]="newbarChartLabels"
            [options]="newbarChartOptions" [colors]="newbarChartColors" [legend]="false" [chartType]="newbarChartType">
          </canvas>
          
          <div class="nodata" *ngIf="gettingCourseAnalytic">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </div>
        </div>
      </div>
    </div> -->
    <div class="d-flex row flex-wrap mb-3">
      <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-2">
        <div class="card card-body">
          <h3>Students per Category</h3>
          <canvas *ngIf="!gettingCategoryAnalytic" baseChart [datasets]="studentCount" [labels]="barChartLabels"
            [options]="studentCountChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
            [legend]="barChartLegend" [chartType]="'doughnut'">
          </canvas>
          <div class="nodata" *ngIf="gettingCategoryAnalytic">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </div>
        </div>
      </div> -->



      <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
        <div class="card card-body">
          <h3>Students per Category 
            <span *ngIf="deptid && postid == ''">({{deptid}})</span>
            <span *ngIf="deptid == '' && postid">({{postname}})</span>
            <span *ngIf="deptid && postid">({{deptid}}, {{postname}})</span>
          </h3>
          <canvas *ngIf="!gettingCourseAnalytic" baseChart [datasets]="studentCount" [labels]="barChartLabels"
            [options]="studentCountChartOptions" [colors]="barChartColors" [plugins]="barChartPlugins"
            [legend]="barChartLegend" [chartType]="'doughnut'">
          </canvas>
          <div class="nodata" *ngIf="gettingCourseAnalytic">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2" *ngIf="deptid == ''">
        <div class="card card-body">
          <h3>Students by Departments</h3>
          <canvas *ngIf="!gettingCourseAnalytic" baseChart [datasets]="deptStuCount" [labels]="deptChartLabels"
            [options]="deptChartOptions" [colors]="newbarChartColors" [legend]="false" [chartType]="newbarChartType">
          </canvas>

          <div class="nodata" *ngIf="gettingCourseAnalytic">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
        <div class="card card-body">
          <h3>Students by Courses
            <span *ngIf="deptid && postid == ''">({{deptid}})</span>
            <span *ngIf="deptid == '' && postid">({{postname}})</span>
            <span *ngIf="deptid && postid">({{deptid}}, {{postname}})</span>
          </h3>
          <canvas *ngIf="!gettingCourseAnalytic" baseChart [datasets]="courseCount" [labels]="newbarChartLabels"
            [options]="newbarChartOptions" [colors]="newbarChartColors" [legend]="false" [chartType]="newbarChartType">
          </canvas>

          <div class="nodata" *ngIf="gettingCourseAnalytic">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 p-2">
        <div class="card card-body">
          <h3>Time Spent per Category</h3>
          <canvas *ngIf="!gettingCategoryAnalytic" baseChart [datasets]="timeSpent" [labels]="barChartLabels"
            [options]="timeSpentChartOptions" [colors]="barChartColors" [legend]="false" [chartType]="barChartType">
          </canvas>
          <div class="nodata" *ngIf="gettingCategoryAnalytic">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div *ngIf="viewby == 'courses'">

    <table mat-table #table [dataSource]="showcourses" matSort (matSortChange)="announceSortChange($event)"
      style="width: 100%;">

      <!-- Image Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef style="min-width: 140px;"></th>
        <td mat-cell *matCellDef="let element" (click)="goToUserAnalytic(element)">

          <img *ngIf="element.courseimage == ''" [src]="allinoneService.defaultImg" class="course-listing-image" />
          <img *ngIf="element.courseimage != ''" [src]="allinoneService.imgurl + element.courseimage"
            class="course-listing-image" />

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Course Name
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
          {{element.coursename}}</td>
      </ng-container>

      <!-- Students Column -->
      <ng-container matColumnDef="studentcounts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Students
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element" (click)="goToUserAnalytic(element)">
          {{element.studentcounts}}</td>
      </ng-container>


      <!-- Time spent Column -->
      <ng-container matColumnDef="timespent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center" sortActionDescription="Sort by time spent">
          Time Spent
        </th>
        <td mat-cell *matCellDef="let element" style="color: var(--main-color); text-align: center"
          (click)="goToUserAnalytic(element)">
          {{element.totaltime}} </td>
      </ng-container>

      <!-- Next Icon Column -->
      <ng-container matColumnDef="nexticon">
        <th mat-header-cell *matHeaderCellDef style="width: 20px;">
        </th>
        <td mat-cell style="margin-right:30px" style="width: 20px;" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">
          <i class="fa fa-arrow-right nexticon" aria-hidden="true"></i>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="4">
          <span class="nodata" *ngIf="!isLoading">Empty</span>
          <span class="nodata" *ngIf="isLoading">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>

    </table>


    <mat-paginator [style.display]="paginate == true ? 'block': 'none'" #paginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div>

  <!-- <div style="overflow: auto;" *ngIf="viewby == 'students'  && publicValue != '001'">
    <table mat-table #stutable [dataSource]="showstudents" class="mat-elevation-z1" style="width: 100%;" matSort
      (matSortChange)="sortData($event)">


      <ng-container matColumnDef="userid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="userid">User ID
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.userid}}</td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.username}}</td>
      </ng-container>

      <ng-container matColumnDef="post">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Post
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.typename}}</td>
      </ng-container>

      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.department}}</td>
      </ng-container>

      <ng-container matColumnDef="nexticon">
        <th mat-header-cell *matHeaderCellDef style="width: 20px;">
        </th>
        <td mat-cell style="margin-right:30px" style="width: 20px;" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">
          <i class="fa fa-arrow-right nexticon" aria-hidden="true"></i>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="studentsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: studentsDisplayedColumns;">

      </tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="4">
          <span class="nodata" *ngIf="!isStudentLoading">Empty</span>
          <span class="nodata" *ngIf="isStudentLoading">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>

    </table>

    <mat-paginator [style.display]="studentPaginate == true ? 'block': 'none'" #stupaginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div> -->
  <!-- <div style="overflow: auto;" *ngIf="viewby == 'students' && publicValue == '001'"> -->
  <div style="overflow: auto;" *ngIf="viewby == 'students'">
    <table mat-table #publicstutable  [dataSource]="showpublicstudents" class="mat-elevation-z1" style="width: 100%;" matSort
      (matSortChange)="sortDataPublicStudent($event)">
      <ng-container matColumnDef="profileimage">

        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goToCoursesByUserAnalytic(element.userid)" style="position: relative; overflow: hidden;">
          
            <span *ngIf="element.profileimage == ''" class="menu-img-profile"> {{ element.username.charAt(0)}}</span>
            <img *ngIf="element.profileimage != ''" [src]="allinoneService.imgurl + element.profileimage" class="channel-listing-image">
        </td>
    </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="userid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="userid">User ID
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.userid}}</td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.username}}</td>
      </ng-container>

      <!-- Enrolled Courses Column -->
      <ng-container matColumnDef="enrolledcourses">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolled Courses
        </th>
        <td mat-cell style="margin-right:30px" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">{{element.count}}</td>
      </ng-container>

      <!-- Username Column -->
     

      <!-- Next Icon Column -->
      <!-- <ng-container matColumnDef="nexticon">
        <th mat-header-cell *matHeaderCellDef style="width: 20px;">
        </th>
        <td mat-cell style="margin-right:30px" style="width: 20px;" *matCellDef="let element"
          (click)="goToCoursesByUserAnalytic(element.userid)">
          <i class="fa fa-arrow-right nexticon" aria-hidden="true"></i>
        </td>
      </ng-container> -->



      <tr mat-header-row *matHeaderRowDef="publicstudentsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: publicstudentsDisplayedColumns;">

      </tr>

      <tr class="mat-row" *matNoDataRow>
        <td colspan="5">
          <span class="nodata" *ngIf="!publicisStudentLoading">Empty</span>
          <span class="nodata" *ngIf="publicisStudentLoading">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>

    </table>

    <mat-paginator [style.display]="publicPaginate == true ? 'block': 'none'" #stupaginator [pageSize]="100"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

  </div>

</div>