<h2>
  <strong>Profiles</strong>
</h2>
<div class = "profile-list-container">
  <ng-container *ngIf = "!gettingProfiles">
    <div *ngFor="let profile of profiles">
      <mat-card class="d-flex align-items-center instructor-card mb-2" (click)="changeProfile(profile.profileid)">
        <div class = "selected" *ngIf="profile.profileid == profileid">
          <mat-icon>check_circle</mat-icon>
        </div>
        <div class="img-wrapper">
          <div [ngClass]="{ 'img-profile': profile.profileimage == ''}">
            <img class="profileimage" *ngIf="profile.profileimage != ''"
              [src]="allinoneService.imgurl + profile.profileimage" alt="">
            <span *ngIf="profile.profileimage == ''"> {{
              profile.name.charAt(0)}}</span>
          </div>
        </div>
        <div class="details d-flex flex-column ps-3">
          <div class="name">{{profile.name}}</div>
          <div class="institute">{{profile.institute}}</div>
        </div>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="gettingProfiles">
    <div *ngFor="let x of [1,2,3]">
      <mat-card class="d-flex align-items-center instructor-card mb-2">
        <div>
          <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '50px',width: '50px'}" style="margin-bottom: -10px">
          </ngx-skeleton-loader>
        </div>
        <div class="details d-flex flex-column ps-3">
          <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -10px">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '10px',width: '60px'}" style="margin-bottom: -10px">
          </ngx-skeleton-loader>
        </div>
      </mat-card>
    </div>
  </ng-container>
</div>

<div class="d-flex justify-content-end">
  <button class = "btn btn-outline-secondary mt-2" (click)="dismiss()" [disabled]="isStillUpdating">Cancel</button>
  <app-button-loader (click)="saveProfile()" [button_text]="'Save'" [isStillupload]="isStillUpdating">
  </app-button-loader>
</div>
