
<div class="d-flex align-items-center justify-content-between">
  <div class="mb-3">
    <h2 class="mb-0">Feedback Answers</h2>
  </div>
  <div>
    <mat-icon class="cus-point" (click)="cancel()">close</mat-icon>
  </div>
</div>
<div class="mb-3">
  <strong>{{question.question}}</strong>
</div>
<div *ngFor="let ans of question.answerlist" class="card card-body mt-2">
  <div class="date">
    <i class="fas fa-calendar-day me-2"></i>{{ans.createddate}}
  </div>
  <div>
    {{ans.text}}
  </div>
</div>
<!-- <div class="d-flex align-items-center justify-content-end">
  <button mat-flat-button class="mt-2 cancel-button" type="button" (click)="cancel()" color="primary">
    Close
  </button>
</div> -->