import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { LoadingService } from '../../shared/services/loading.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-approve-instructor',
  templateUrl: './approve-instructor.component.html',
  styleUrls: ['./approve-instructor.component.scss']
})
export class ApproveInstructorComponent implements OnInit {

  displayedColumns: any;

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  gettingInstructor: boolean = false;
  allinstructor: any[] = [];
  instructor !: MatTableDataSource<any>;
  edit: string = "editchannel";
  paginate: boolean = false;
  searchText: string = "";
  isFocus: boolean = false;
  datacount: string = "0";
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    private _liveAnnouncer: LiveAnnouncer,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
  ) {
    if (this.allinoneService.getUserRole() != '100') {
      this.router.navigateByUrl('')
    }
  }

  ngOnInit(): void {
    this.displayedColumns = ['userid', 'username', 'institute', 'status', 'actions'];
    this.getInstructor();
  }

  getInstructor() {
    this.allinstructor = [];
    this.gettingInstructor = true;

    this.instructor = new MatTableDataSource();
    this.courseService.checkApprovedinstructor().subscribe(
      (instructordata: any) => {
        if (instructordata.returncode == '300') {
          const dataSource = new MatTableDataSource(instructordata.datalist);
          this.allinstructor = instructordata.datalist;
          this.datacount = instructordata.datalist.length;
          if (this.allinstructor == undefined || this.allinstructor.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }
          this.instructor = new MatTableDataSource(instructordata.datalist);
          this.instructor.sort = this.sort;
          this.instructor.paginator = this.paginator;
          this.gettingInstructor = false;
        } else {
          this.gettingInstructor = false;
          if (instructordata.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.gettingInstructor = false;
            this.messageService.openSnackBar(instructordata.status, 'warn');
          }
        }
      },
      (err) => {
        this.gettingInstructor = false;
      }
    );
  }

  clickApprove(data: any, status: any, idx: any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: "300px",
      scrollStrategy: new NoopScrollStrategy(),
      data : {
        message: 'Are you sure to ' + (status == 1 ? 'approve' : 'reject')+ ' this user ' + data.userid + '?'
      }
    },
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {


        this.courseService.approverUSer(data.usersyskey, status).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.loadingService.hide();
        console.log(">>" + status);
        if (status == 1) {
          this.allinstructor.splice(idx, 1);
          this.instructor = new MatTableDataSource(this.allinstructor);
          this.datacount = this.allinstructor.length.toString();
        } else {
          this.allinstructor[idx].approved = "2";
          this.instructor = new MatTableDataSource(this.allinstructor);

        }

          }
          else {
            this.loadingService.hide();

            if (res.returncode == "210") {
              this.loadingService.hide();

              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            }
            else {
              this.loadingService.hide();

              this.messageService.openSnackBar(res.message, 'warn');
            }
          }
        }, err => {
        this.loadingService.hide();

        });

      }
    })
  }


  // announceSortChange(sortState: Sort, data: any) {
  //   console.log(sortState);
  //   if (sortState.direction) {
  //     console.log("1");
  //     this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  //   } else {
  //     console.log("2");
  //     this._liveAnnouncer.announce('Sorting cleared');
  //   }
  //   console.log(data)
  // }


  sortData(sort: any) {
    const data = this.allinstructor;
    if (!sort.active || sort.direction === '') {
      this.allinstructor = data;
      return;
    }
    this.allinstructor = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'institute':
          return this.allinoneService.compare(a.institute, b.institute, isAsc);
        case 'status':
          return this.allinoneService.compare(a.approved, b.approved, isAsc);

        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = "";
    this.instructor.filter = "";
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.instructor.filter = searchItem.trim().toLowerCase();

  }
  refresh() {
    this.gettingInstructor = true;
    this.allinstructor = [];
    if (this.allinstructor == undefined || this.allinstructor.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.instructor = new MatTableDataSource();
    this.getInstructor();

  }

}
