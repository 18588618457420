<app-breadcrumb [s_breadcrumb]="'Schedule'"></app-breadcrumb>
<div class="content">
  <div class="d-flex justify-content-end mt-3">
    <div class="d-flex flex-wrap align-items-center">
      <div class="mb-3 me-2">
        Total :<b> {{ courses.length }}</b>
      </div>
  
      <div class="input-group mb-3 me-2" style="width: 260px !important">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search" (focus)="isFocus = true"
          (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" />
        <button class="btn" type="button" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color); color: var(--main-color)">
          <i class="fa fa-times"></i>
        </button>
      </div>
  
      <div class="form-group mb-3 me-2" style="width: 260px !important">
        <select class="form-select" [(ngModel)]="selectedChannel" (ngModelChange)="getCourses()"
          matTooltip="Filter By Channel" [disabled]="gettingmyChannel">
          <option [value]="chl.channelid" *ngFor="let chl of channels">
            {{ chl.name }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingmyChannel"></mat-progress-bar>
      </div>
  
      <button class="mb-3 me-2 btn new-class" matTooltip="Refresh" (click)="getCourses()">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>
  <div>
    <table mat-table #table [dataSource]="coursesTableData" matSort style="width: 100%;"
      (matSortChange)="sortCategoryData($event)">
  
      <!-- Course Image Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="element.courseimage == ''" [src]="allinoneService.defaultImg" class="course-listing-image" />
          <img *ngIf="element.courseimage != ''" [src]="allinoneService.imgurl + element.courseimage"
            class="course-listing-image" />
        </td>
      </ng-container>
  
      <!-- Cours Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="coursename">Course Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.coursename}}
        </td>
      </ng-container>
  
      <!--  Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 110px !important;">
          <!-- {{element.schedulestatus}} -->
          <!-- 001 - incomplete / 002 - ongoing / 003 - upcoming / 004 - complete -->
          <span *ngIf="element.schedulestatus == '001'" class="badge bg-secondary p-2">
            Unscheduled
          </span>
          <span *ngIf="element.schedulestatus == '002'" class="badge bg-main p-2">
            Ongoing
          </span>
          <span *ngIf="element.schedulestatus == '003'" class="badge bg-orange p-2">
            Upcoming
          </span>
          <span *ngIf="element.schedulestatus == '004'" class="badge bg-green p-2">
            Completed
          </span>
        </td>
      </ng-container>
  
  
      <!-- Start Date Column -->
      <ng-container matColumnDef="startdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="startdate" class="center">
          Start Date
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 140px !important;">
          {{element.startdate? ((allinoneService.formatDateForDiscussionDatePipe(element.startdate)+' '+
          element.starttime) | date:'short') : '--'}}
        </td>
  
      </ng-container>
  
      <!-- End Date Column -->
      <ng-container matColumnDef="enddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="enddate" class="center">
          End Date
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 140px !important;">
          {{element.enddate? ((allinoneService.formatDateForDiscussionDatePipe(element.enddate)+' '+ element.endtime) |
          date:'short') : '--'}}
        </td>
      </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editSchedule(row)"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td colspan="5">
          <span class="nodata" *ngIf="!gettingCourses">Empty</span>
          <span class="nodata" *ngIf="gettingCourses">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>
  
  
    </table>
  
    <mat-paginator #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]" *ngIf="courses.length > 0">
    </mat-paginator>
  </div>
</div>