<h2><strong>{{isEditing? 'Edit' : 'New'}}&nbsp;{{isFolder? 'Folder' : 'File'}}</strong></h2>
<form [formGroup]="form" (ngSubmit)="sumbitForm()">
  <div class="form-group mb-3">
    <label for="fileid" class="mb-2">{{isFolder? 'Folder ID' : 'File ID'}}</label>
    <input type="text" class="form-control form-control-sm" placeholder="F001" formControlName="fileid" id="fileid"
      name="fileid" [ngClass]="{ 'is-invalid': submitted && formControl.fileid.errors }" />

    <div *ngIf="submitted && formControl.fileid.errors" class="invalid-feedback">
      <div *ngIf="formControl.fileid.errors?.required">
        {{ allinoneService.input_err_msg }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isFolder; else newFolderTemplate">
    <div class="form-group mb-3">
      <label for="description" class="mb-2"> Description </label>
      <input type="text" class="form-control form-control-sm" placeholder="Enter Description" formControlName="filedesc"
        id="description" name="description" [ngClass]="{ 'is-invalid': submitted && formControl.filedesc.errors }" />

      <div *ngIf="submitted && formControl.filedesc.errors" class="invalid-feedback">
        <div *ngIf="formControl.filedesc.errors?.required">
          {{ allinoneService.input_err_msg }}
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="file" class="mb-2"> File </label>
      <div class="dragdrop form-control p-0" name="file" id="file"
        [ngClass]="{ 'is-invalid': submitted && ((!isEditing &&!fileSelected) || (isEditing && !formControl.filename.value)) }">
        <ng-container *ngIf="(fileSelected != '' || (isEditing && formControl.filename.value)); else uploadTemplate">
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3" appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div class="ms-2">
              <strong>{{ formControl.filename.value }}</strong>
            </div>
          </div>
        </ng-container>
        <ng-template #uploadTemplate>
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div class="me-3">Drag and drop file here</div>
            <div class="me-3">or</div>
            <div>
              <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile />
              <button type="button" class="ms-0" mat-flat-button color="primary" (click)="choosefile.click()">
                Choose File
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #newFolderTemplate>
    <div class="form-group mb-3">
      <label for="filename" class="mb-2"> Folder Name </label>
      <input type="text" class="form-control form-control-sm" placeholder="Folder Name" formControlName="filename"
        id="filename" name="filename" [ngClass]="{ 'is-invalid': submitted && formControl.filename.errors }"
        #foldername />

      <div *ngIf="submitted && formControl.filename.errors" class="invalid-feedback">
        <div *ngIf="formControl.filename.errors?.required">
          {{ allinoneService.input_err_msg }}
        </div>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="isSaving">
    <mat-progress-bar class="example-margin" color="primary" mode="determinate" [value]="progress">
    </mat-progress-bar>
    <div class="d-flex align-items-center justify-content-center">
      {{progress}}%
    </div>
  </ng-container>
  <div mat-dialog-actions>
    <div class="mb-3 w-100">
      <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
        <button type="button" mat-stroked-button class="ms-0" (click)="cancel()" [disabled]="isSaving">
          Cancel
        </button>
        <button type="submit" mat-flat-button class="ms-2" color="primary" [disabled]="isSaving">
          <span *ngIf="isSaving">
            <div class="spinner-border spinner-border-sm text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
          <span *ngIf="!isSaving"> Save </span>
        </button>
      </div>
    </div>
  </div>
</form>