<!-- <div #viewer id="viewer"></div> -->
<!-- <button (click)="test()">test</button> -->
<!-- test git -->
<div class="all">
  <div class="loading-center" *ngIf="isLoading">
    <!-- <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner> -->
    <span class="loader"></span>
  </div>

  <div *ngIf="!isLoading && !isError">
    <nav class="navbar navbar-expand-lg  px-3  navbar-light d-flex" *ngIf="pageview != '1'">
      <div class="container-fluid pe-0">
        <div class=" d-flex align-items-center mr-auto">
          <a href="/home" *ngIf="allinOneService.currentDomainType == '001'">
            <img src="assets/images/khub.png" alt="" class="navbar-brand-logo" />
          </a>
          <a href="/home" *ngIf="allinOneService.currentDomainType != '001'">
            <img [src]="allinOneService.channeldata.url" alt="" class="navbar-brand-logo" />
          </a>
          <div class="vertical-divider"></div>
          <!-- <span class="course-title">{{course.coursename}}</span> -->
          <!-- <span *ngIf="!isMobileView">
            <app-breadcrumb [s_breadcrumb]="isFromInstructor ? 'Contents' :'My Courses'" [a_breadcrumb]="course.coursename"
              *ngIf="coursestatus == '002'" (emit)="isFromInstructor ? goToContents() : mycourse()"></app-breadcrumb>
            <app-breadcrumb [s_breadcrumb]="course.coursename" *ngIf="coursestatus != '002'">
            </app-breadcrumb>
          </span> -->
          <span *ngIf="!isMobileView && !isFromInstructor">
            <app-breadcrumb [s_breadcrumb]="'My Courses'" [a_breadcrumb]="course.coursename"
              *ngIf="coursestatus == '002'" (emit)="mycourse()"></app-breadcrumb>
            <app-breadcrumb [s_breadcrumb]="course.coursename" *ngIf="coursestatus != '002'">
            </app-breadcrumb>
          </span>
          <span *ngIf="isFromInstructor && !isMobileView">
            <app-breadcrumb [t_breadcrumb]="course.coursename" [s_breadcrumb]="'Contents'" (emit)="goToContents()"></app-breadcrumb>
          </span>
          <!-- <app-breadcrumb [t_breadcrumb]="'New Channel'" [s_breadcrumb]="'Channels'" (emit)="goToChannels()"></app-breadcrumb> -->
          <span *ngIf="isMobileView">
            <div class="custom-web-breadcrumb-mb" *ngIf="coursestatus == '002'">
              <span class="active-breadcrumb" routerLink="/home">Home</span>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
              <span style="min-width: fit-content;" class="active-breadcrumb" routerLink="/my-learning"> My
                Courses</span>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
              <span> {{course.coursename}}</span>
            </div>
            <div class="custom-web-breadcrumb-mb" *ngIf="coursestatus != '002'">
              <span class="active-breadcrumb" routerLink="/home">Home</span>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
              <span> {{course.coursename}}</span>
            </div>
          </span>

        </div>

        <!-- <app-button-loader *ngIf="coursestatus == '003'" [isStillupload]="enrolling" [button_text]="'Enroll Now'"
                [margin_left]="'0'" (click)="enroll()">
            </app-button-loader> -->




        <div class="bread-right">
          <button *ngIf="coursestatus == '003'" mat-flat-button color="primary" class="ms-3" (click)="enroll()"
            [disabled]="enrolling">
            <span *ngIf="enrolling">
              <div class="spinner-border spinner-border-sm" role="status" style="color: white !important;">
                <span class="sr-only">Loading...</span>
              </div>
            </span>
            <span *ngIf="!enrolling">
              Enroll Now
            </span>
          </button>

          <button *ngIf="coursestatus == '004'" mat-flat-button color="primary" class="ms-3 me-2" (click)="login()">Sign
            In</button>
          <!-- <button mat-flat-button color="primary" class=""
            *ngIf="!isMobileView && course.class && course.classstatus == '001'" (click)="openDropbox()"
            style="margin-right: 10px;">
            <mat-icon class="mx-1">move_to_inbox</mat-icon> Dropbox
          </button> -->

          <!-- <div class="layout-container" *ngIf="!isMobileView && course.class && course.classstatus == '001'"
            (click)="openDropbox()">
            <div class="d-flex justify-content-center">
              <mat-icon class="mx-1 layout-view-logo">move_to_inbox</mat-icon>
            </div>
            <span class="layout-label" for="flexRadioDefault1" style="cursor: pointer;">
              Dropbox
            </span>
          </div> -->


          <!-- <button *ngIf="!isMobileView" mat-icon-button [matMenuTriggerFor]="menu1">
            <mat-icon>more_vert</mat-icon>
          </button> -->

          <div class="layout-container" *ngIf="!isMobileView && pageview == '2'" (click)="changeLayoutView()">
            <div class="d-flex justify-content-center">
              <!-- <img src="assets/images/view1.png" alt="Learning View" matTooltip="Learning View" class="layout-view-logo"
                *ngIf="pageview == '0'">
              <img src="assets/images/view2.png" alt="Full Screen View" matTooltip="Full Screen View"
                class="layout-view-logo" *ngIf="pageview == '1'"> -->
              <img src="assets/images/view1.png" alt="Page View" matTooltip="Learning View" class="layout-view-logo">
            </div>
            <!-- <span class="layout-label" for="flexRadioDefault1" style="cursor: pointer;">
              View
            </span> -->
          </div>


          <mat-menu #menu1="matMenu" class="category-menu" style="padding: 8px;width: 100px;">
            <div style="margin-left:5px;cursor: pointer;">
              <!-- <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <span style="margin-left: 10px;">Learning View</span> -->
              <div class="form-check">
                <input class="form-check-input" [checked]="pageview == '0'" type="radio" name="flexRadioDefault"
                  id="flexRadioDefault1" (change)="openDestopPage('0')">
                <label class="form-check-label" for="flexRadioDefault1" style="cursor: pointer;">
                  Learning View
                </label>
              </div>
            </div>
            <div style="margin-left:5px;cursor: pointer;">
              <div class="form-check">
                <input class="form-check-input" [checked]="pageview == '1'" type="radio" name="flexRadioDefault"
                  id="flexRadioDefault3" (change)="openDestopPage('1')">
                <label class="form-check-label" for="flexRadioDefault3" style="cursor: pointer;">
                  Full Screen View
                </label>
              </div>
            </div>
            <div style="margin-left:5px;cursor: pointer;">
              <!-- <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <span style="margin-left: 10px;">Page View</span> -->
              <div class="form-check">
                <input class="form-check-input" [checked]="pageview == '2'" type="radio" name="flexRadioDefault"
                  id="flexRadioDefault2" (change)="openDestopPage('2')">
                <label class="form-check-label" for="flexRadioDefault2" style="cursor: pointer;">
                  Page View
                </label>
              </div>
            </div>

          </mat-menu>
        </div>
      </div>
    </nav>




    <!-- <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
        <mat-icon (click)="navigateBack()">keyboard_backspace</mat-icon>
        <span class="course-title mx-2">{{course.coursename}}</span>
    </div> -->
    <div *ngIf="pageview == '0' || pageview == '1'">
      <div class="main-content d-flex " *ngIf="!isLoading">
        <perfect-scrollbar [scrollIndicators]="true" class="course-content w-25"
          *ngIf="!isMobileView && pageview == '0'">
          <!-- <div>Scrollable content</div> -->
          <div>
            <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
              <span class="course-title mx-2">{{course.coursename}}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="tab-section d-flex px-2 py-2">
                <div matRipple [ngClass]="{'tab-active': tab_active == 1}" (click)="tabClick(1)">
                  Content
                </div>
                <div matRipple [ngClass]="{'tab-active': tab_active == 3}" (click)="tabClick(3)" *ngIf="isMobileView">
                  Lecture
                </div>
                <div matRipple [ngClass]="{'tab-active': tab_active == 2}" (click)="tabClick(2)">
                  About
                </div>
              </div>
              <div class="d-flex align-items-center px-2 me-2">
                <span id="currentLect"> <b>({{currentIndex}} of {{lecturelist.length}}) </b></span>
              </div>
            </div>


            <!-- course content -->
            <div *ngIf="tab_active == 1" class=" custom-accordion  accordion" [ngClass]="{'tabview': isMobileView}">
              <div class="grade-title"
                *ngIf="coursestatus == '002' && (course.gradepage || (course.certificatepage && completed))">
                <span class="p-2">
                  Contents
                </span>
              </div>
              <div class="accordion-item" *ngFor="let section of course.sections;let s = index;"
                [ngClass]="{'section-lock' : !section.unlock}">
                <div>
                  <h2 class="accordion-header" [id]="'heading' + s">
                    <div (click)="openCollapse(s)" class="d-flex flex-row custom-accordion-button  sectionname"
                      style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;">

                      <!-- [ngClass]="section.active" for active section -->
                      <div class="p-2 me-auto position-relative" style="text-align: start;">
                        <span class="index-label">
                          {{s+1}}.0
                        </span>
                        <span class="me-auto">
                          {{section.sectionname}}
                        </span>
                      </div>
                      <div class="p-2" [ngClass]="{'lock-container' : !section.unlock}">
                        <i class="fa fa-lock me-2" *ngIf="!section.unlock"></i>
                        <i *ngIf="!section.show" id="Dropicon" class="fa fa-chevron-down" aria-hidden="true"></i>
                        <i *ngIf="section.show" id="Dropicon" class="fa fa-chevron-up" aria-hidden="true"></i>
                      </div>
                    </div>

                  </h2>

                  <div *ngIf="section.show">
                    <div class="section-accordion-body">
                      <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                        <h2 class="accordion-header" id="headingSubsectionOne">
                          <div (click)="closeCollpased(s,ss)"
                            class=" d-flex flex-row custom-accordion-button   subsectioname"
                            style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;"
                            *ngIf="((subsection.units.length > 1 || subsection.units.length == 0 )&& subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                            <div class="p-2 me-auto position-relative" style="text-align: start;">
                              <span *ngIf="subsection.subsectionname == '' " style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                {{s+1}}.{{ss+1}}
                              </span>
                              <span *ngIf="subsection.subsectionname != '' ">
                                <span *ngIf="subsection.subsectionname == section.sectionname" style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                  {{s+1}}.{{ss+1}}

                                </span>
                                <span *ngIf="subsection.subsectionname != section.sectionname">
                                  <span class="index-label"> {{s+1}}.{{ss+1}} </span>
                                  <span>{{subsection.subsectionname}}</span>
                                </span>
                              </span>


                            </div>
                            <div class="p-2" [ngClass]="{'lock-container' : !section.unlock}">
                              <i class="fa fa-lock me-2" *ngIf="!section.unlock"></i>
                              <i *ngIf="!subsection.show" id="Dropicon" class="fa fa-chevron-down subsection-icon"
                                aria-hidden="true"></i>
                              <i *ngIf="subsection.show" id="Dropicon" class="fa fa-chevron-up subsection-icon"
                                aria-hidden="true"></i>
                            </div>
                          </div>
                          <div
                            *ngIf="((subsection.units.length > 1 || subsection.units.length == 0) && subsection.subsectionname == '') || (subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                            <div class="accordion-body">
                              <ul class="unit-list">
                                <li *ngFor="let unit of subsection.units;let su = index;"
                                  class="justify-content-between pad-right" style="position: relative;">
                                  <div style="position: absolute; height: 100%; left: 1rem; top: 0;"
                                    class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                  </div>
                                  <div class="mx-2 unit" (click)="viewLecture(unit, true, section.unlock)">
                                    <div *ngIf="unit.contenttype == 1"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <!-- <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                            class="unit-icon" [ngClass]="unit.icon"></span> -->
                                        <span *ngIf="unit.libraryid == ''"><img
                                            [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                                            [ngClass]="unit.icon"></span>
                                        <span *ngIf="unit.libraryid != ''"><img
                                            [src]="allinOneService.fileurl + 'icons/video.png'" class="unit-icon"
                                            [ngClass]="unit.icon"></span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>
                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-play-circle-o me-1"
                                                                            aria-hidden="true"></i>Video</span> -->
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 2"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                            class="unit-icon"></span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>
                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-o me-1"
                                                                            aria-hidden="true"></i>File</span> -->
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 3"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <i class="fa fa-file-text me-1" aria-hidden="true"></i>
                                          <!-- <img [src]="'../../../assets/images/writeup.png'" class="unit-icon"> -->
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>
                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-text me-1"
                                                                            aria-hidden="true"></i>Article</span> -->
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 4"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <i class="fas fa-link me-1" aria-hidden="true"></i>

                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>
                                        <!-- <span [ngClass]="{'gray' : unit.unitname}"><i
                                                                            class="fa fa-file-text me-1"
                                                                            aria-hidden="true"></i>Article</span> -->
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 5"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <!-- <img [src]="'./../../assets/images/attach-file.png'" class = "unit-icon"> -->
                                          <i class="fas fa-paperclip me-1"></i>
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 6"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <!-- <img [src]="'./../../assets/images/attach-file.png'" class = "unit-icon"> -->
                                          <i class="fas fa-clipboard-list me-1"></i>
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 7"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 8"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 10"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <img [src]="'./../../assets/images/audio.png'" class="unit-icon">
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                  </div>
                                  <i class="fa fa-lock me-3" *ngIf="!section.unlock"></i>
                                  <!-- [disabled]="unit.contenttype == 6 || unit.contenttype == 7 || unit.contenttype == 8" -->
                                  <mat-checkbox disabled class="example-margin" *ngIf="section.unlock"
                                    [(ngModel)]="unit.checked" [checked]="unit.checked"
                                    (click)="updateCompletedUnits(unit, true)">
                                  </mat-checkbox>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </h2>
                        <div
                          *ngIf="subsection.show && (((subsection.units.length > 1 || subsection.units.length == 0) && subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname)))">
                          <!-- <div  class="accordion-collapse collapse" collapseSubsec" [ngClass]="section.show" aria-labelledby="headingSubsectionOne"> -->
                          <div class="accordion-body">
                            <ul class="unit-list">
                              <li *ngFor="let unit of subsection.units;let su = index;"
                                class="justify-content-between pad-right" style="position: relative;">
                                <div style="position: absolute; height: 100%; left: 1rem; top: 0;"
                                  class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                  <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                </div>
                                <div class="mx-2 unit" (click)="viewLecture(unit, true, section.unlock)">
                                  <div *ngIf="unit.contenttype == 1"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span *ngIf="unit.libraryid == ''"><img
                                          [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                                          [ngClass]="unit.icon"></span>
                                      <span *ngIf="unit.libraryid != ''"><img
                                          [src]="allinOneService.fileurl + 'icons/video.png'" class="unit-icon"
                                          [ngClass]="unit.icon"></span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 2"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                          class="unit-icon"></span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 3"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fa fa-file-text me-1" aria-hidden="true"></i>
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 4"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fas fa-link me-1" aria-hidden="true"></i>

                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 5"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fas fa-paperclip me-1"></i>
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 6"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fas fa-clipboard-list me-1"></i>
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 7"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 8"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 10"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <img [src]="'./../../assets/images/audio.png'" class="unit-icon">
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <i class="fa fa-lock me-3" *ngIf="!section.unlock"></i>
                                <mat-checkbox class="example-margin" [(ngModel)]="unit.checked" [checked]="unit.checked"
                                  *ngIf="section.unlock" (click)="updateCompletedUnits(unit, true)" disabled>
                                  <!-- [disabled]="unit.contenttype == 6 || unit.contenttype == 7 || unit.contenttype == 8" -->
                                </mat-checkbox>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div
                *ngIf="coursestatus == '002' && (course.gradepage || course.feedbackpage || (course.certificatepage && completed))"
                class="grades">
                <div class="grade-title">
                  <span class="p-2">
                    Others
                  </span>
                </div>
                <div class="d-flex flex-row custom-accordion-button  sectionname" (click)="viewGrade()"
                  style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer; position: relative;"
                  *ngIf="course.gradepage">
                  <div style="position: absolute; height: 100%; left: 8px; top: 0;" class="d-flex align-items-center"
                    *ngIf="isGrade">
                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                  </div>
                  <div class="p-2 me-auto position-relative" style="text-align: start;" [ngClass]="{'ps-3': isGrade}">
                    <span class="me-auto">
                      Grades
                    </span>
                  </div>
                  <div class="p-2">
                    <i id="Dropicon" class="fas fa-angle-right" aria-hidden="true" style="font-size: 20px;"></i>
                  </div>
                </div>

                <div class="d-flex flex-row custom-accordion-button  sectionname" (click)="viewFeedback()"
                  style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer; position: relative;"
                  *ngIf="course.feedbackpage">
                  <div style="position: absolute; height: 100%; left: 8px; top: 0;" class="d-flex align-items-center"
                    *ngIf="isFeedback">
                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                  </div>
                  <div class="p-2 me-auto position-relative" style="text-align: start;"
                    [ngClass]="{'ps-3': isFeedback}">
                    <span class="me-auto">
                      Feedback
                    </span>
                  </div>
                  <div class="p-2">
                    <i id="Dropicon" class="fas fa-angle-right" aria-hidden="true" style="font-size: 20px;"></i>
                  </div>
                </div>
                <div class="d-flex flex-row custom-accordion-button  sectionname" (click)="viewCertificate()"
                  style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer; position: relative;"
                  *ngIf="course.certificatepage && completed && classTemplate">
                  <div style="position: absolute; height: 100%; left: 8px; top: 0;" class="d-flex align-items-center"
                    *ngIf="isCertificate">
                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                    <!-- <i class="fa fa-caret-right" style="color: red; font-size: 30px;"></i> -->
                  </div>
                  <div class="p-2 me-auto position-relative" style="text-align: start;"
                    [ngClass]="{'ps-3': isCertificate}">
                    <span class="me-auto">
                      Certificate
                    </span>
                  </div>
                  <div class="p-2">
                    <i id="Dropicon" class="fas fa-angle-right" aria-hidden="true" style="font-size: 20px;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
        <div class="course-content w-25" *ngIf="isMobileView" [ngClass]="{'new' : tab_active == 2 || tab_active == 4}">
          <!-- <div>Scrollable content</div> -->
          <div>
            <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
              <span class="course-title mx-1">{{course.coursename}}</span>
              <div class="d-flex align-items-center px-1">
                <!-- <p>123</p> -->
                <span
                  *ngIf="!gettingstudytimer && (lecture.contenttype == 2 || lecture.contenttype == 3) && havestudytime && !lecture.checked"
                  style="color: var(--main-color);font-weight: bold;">
                  {{studytimer}} </span>
                <!-- <div *ngIf="lecture.contenttype == 6 && havequiztimer" class="timer">
                    <div class="me-2 d-flex align-items-center">
                      <mat-icon>access_time</mat-icon>
                    </div>
                    <div>
                      {{quiztimer}}
                    </div> <span class="mx-2">|</span>
                  </div> -->
                <!-- <span
                  *ngIf="(lecture.contenttype == 2 || lecture.contenttype == 3) && havestudytime && !lecture.checked"
                  class="mx-1">|</span> -->
                <span id="currentLect"> <b>({{currentIndex}} of {{lecturelist.length}}) </b></span>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <div class="tab-section d-flex px-2 py-2">
                <div *ngIf="!showPageView" matRipple [ngClass]="{'tab-active': tab_active == 1}" (click)="tabClick(1)">
                  Contents
                </div>
                <!-- *ngIf="" -->
                <div *ngIf="!showPageView && lecture.unitid" matRipple [ngClass]="{'tab-active': tab_active == 3}"
                  (click)="tabClick(3)">
                  Lecture
                </div>
                <div *ngIf="showPageView" matRipple [ngClass]="{'tab-active': tab_active == 5}" (click)="tabClick(5)">
                  Learning
                </div>
                <div matRipple [ngClass]="{'tab-active': tab_active == 2}" (click)="tabClick(2)">
                  About
                </div>
                <!-- <div matRipple [ngClass]="{'tab-active': tab_active == 4}" (click)="tabClick(4)"
                  *ngIf="course.class && course.classstatus == '001'">
                  Dropbox
                </div> -->
                <div matRipple [ngClass]="{'tab-active': tab_active == 6}" (click)="tabClick(6)"
                  *ngIf="coursestatus == '002' && course.gradepage">
                  Grades
                </div>
                <div matRipple [ngClass]="{'tab-active': tab_active == 7}" (click)="tabClick(7)"
                  *ngIf="coursestatus == '002' && course.certificatepage && completed">
                  Certificate
                </div>
              </div>
              <!-- <div class="d-flex align-items-center px-2 me-2">
                <span id="currentLect"> <b>({{currentIndex}} of {{lecturelist.length}}) </b></span>
              </div> -->
            </div>


            <!-- course content -->
            <div *ngIf="tab_active == 1" class=" custom-accordion  accordion"
              [ngClass]="{'tabview': isMobileView && lecture.unitid , 'new_tab': isMobileView && !lecture.unitid}">
              <div class="accordion-item" *ngFor="let section of course.sections;let s = index;"
                [ngClass]="{'section-lock' : !section.unlock}">
                <div>
                  <h2 class="accordion-header" [id]="'heading' + s">
                    <div (click)="openCollapse(s)" class="d-flex flex-row custom-accordion-button  sectionname"
                      style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;">

                      <!-- [ngClass]="section.active" for active section -->
                      <div class="p-2 me-auto position-relative" style="text-align: start;">
                        <span class="index-label">
                          {{s+1}}.0
                        </span>
                        <span class="me-auto">
                          {{section.sectionname}}
                        </span>
                      </div>

                      <div class="p-2" [ngClass]="{'lock-container' : !section.unlock}">
                        <i class="fa fa-lock me-2" *ngIf="!section.unlock"></i>
                        <i *ngIf="!section.show" id="Dropicon" class="fa fa-chevron-down" aria-hidden="true"></i>
                        <i *ngIf="section.show" id="Dropicon" class="fa fa-chevron-up" aria-hidden="true"></i>
                      </div>
                    </div>

                  </h2>

                  <div *ngIf="section.show">
                    <div class="section-accordion-body">
                      <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                        <h2 class="accordion-header" id="headingSubsectionOne">
                          <div (click)="closeCollpased(s,ss)"
                            class=" d-flex flex-row custom-accordion-button   subsectioname"
                            style="padding: 0.5rem 0.5rem;text-align: center;cursor: pointer;"
                            *ngIf="((subsection.units.length > 1 || subsection.units.length == 0 )&& subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                            <div class="p-2 me-auto position-relative" style="text-align: start;">
                              <span *ngIf="subsection.subsectionname == '' " style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                {{s+1}}.{{ss+1}}
                              </span>
                              <span *ngIf="subsection.subsectionname != '' ">
                                <span *ngIf="subsection.subsectionname == section.sectionname" style="position: absolute;
                                                        font-size: 10px;
                                                        color: gray;
                                                        font-weight: normal;">
                                  {{s+1}}.{{ss+1}}

                                </span>
                                <span *ngIf="subsection.subsectionname != section.sectionname">
                                  <span class="index-label"> {{s+1}}.{{ss+1}} </span>
                                  <span>{{subsection.subsectionname}}</span>
                                </span>
                              </span>


                            </div>
                            <div class="p-2" [ngClass]="{'lock-container' : !section.unlock}">
                              <i class="fa fa-lock me-2" *ngIf="!section.unlock"></i>
                              <i *ngIf="!subsection.show" id="Dropicon" class="fa fa-chevron-down subsection-icon"
                                aria-hidden="true"></i>
                              <i *ngIf="subsection.show" id="Dropicon" class="fa fa-chevron-up subsection-icon"
                                aria-hidden="true"></i>
                            </div>
                          </div>
                          <div
                            *ngIf="((subsection.units.length > 1 || subsection.units.length == 0 )&& subsection.subsectionname == '') || (subsection.units.length == 1 && (subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname))">
                            <div class="accordion-body">
                              <ul class="unit-list">
                                <li *ngFor="let unit of subsection.units;let su = index;"
                                  class="justify-content-between"
                                  style="position: relative; padding-right: 0.7rem !important;">
                                  <div style="position: absolute; height: 100%; left: 1rem; top: 0;"
                                    class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                    <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                  </div>
                                  <div class="mx-2 unit" (click)="viewLecture(unit, true, section.unlock)">
                                    <div *ngIf="unit.contenttype == 1"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <!-- <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                            class="unit-icon" [ngClass]="unit.icon"></span> -->
                                        <span *ngIf="unit.libraryid == ''"><img
                                            [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                                            [ngClass]="unit.icon"></span>
                                        <span *ngIf="unit.libraryid != ''"><img
                                            [src]="allinOneService.fileurl + 'icons/video.png'" class="unit-icon"
                                            [ngClass]="unit.icon"></span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 2"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                            class="unit-icon"></span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 3"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <i class="fa fa-file-text me-1" aria-hidden="true"></i>

                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 4"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <i class="fas fa-link me-1" aria-hidden="true"></i>

                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 5"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}<span *ngIf="subsection.units.length > 1">.{{su+1}}</span>
                                      </span>
                                      <p>
                                        <span>
                                          <!-- <img [src]="'../../../assets/images/attach-file.png'"
                                                                                    class="unit-icon"> -->
                                          <i class="fas fa-paperclip me-1"></i>

                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          subsection.subsectionname}}
                                        </span>

                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 6"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                      </span>
                                      <p>
                                        <span>
                                          <i class="fas fa-clipboard-list me-1"></i>
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          ''}}
                                        </span>
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 7"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                      </span>
                                      <p>
                                        <span>
                                          <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          ''}}
                                        </span>
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 8"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                      </span>
                                      <p>
                                        <span>
                                          <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          ''}}
                                        </span>
                                      </p>
                                    </div>
                                    <div *ngIf="unit.contenttype == 10"
                                      class="d-flex align-items-center position-relative">
                                      <span class="unit-index-label">
                                        {{s+1}}.{{ss+1}}.{{su+1}}
                                      </span>
                                      <p>
                                        <span>
                                          <img [src]="'./../../assets/images/audio.png'" class="unit-icon">
                                        </span>
                                        <span> {{unit.unitname ? unit.unitname :
                                          ''}}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <i class="fa fa-lock me-3" *ngIf="!section.unlock"></i>
                                  <mat-checkbox class="example-margin" [(ngModel)]="unit.checked" *ngIf="section.unlock"
                                    [checked]="unit.checked" (click)="updateCompletedUnits(unit, true)" disabled>
                                    <!-- [disabled]="unit.contenttype == 6 || unit.contenttype == 7 || unit.contenttype == 8" -->
                                  </mat-checkbox>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </h2>
                        <div
                          *ngIf="subsection.show && (((subsection.units.length > 1 || subsection.units.length == 0) && subsection.subsectionname != '') || (subsection.units.length == 1 && !(subsection.units[0].unitname == '' || subsection.subsectionname == '' || subsection.units[0].unitname == subsection.subsectionname)))">
                          <!-- <div  class="accordion-collapse collapse" collapseSubsec" [ngClass]="section.show" aria-labelledby="headingSubsectionOne"> -->
                          <div class="accordion-body">
                            <ul class="unit-list">
                              <li *ngFor="let unit of subsection.units;let su = index;" class="justify-content-between"
                                style="position: relative;  padding-right: 0.7rem !important;">
                                <div style="position: absolute; height: 100%; left: 1rem; top: 0;"
                                  class="d-flex align-items-center" *ngIf="unit.unitid == lecture.unitid">
                                  <i class="fa fa-caret-right" style="color: var(--main-color); font-size: 30px;"></i>
                                </div>
                                <div class="mx-2 unit" (click)="viewLecture(unit, true, section.unlock)">
                                  <div *ngIf="unit.contenttype == 1"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <!-- <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                                          [ngClass]="unit.icon"></span> -->
                                      <span *ngIf="unit.libraryid == ''"><img
                                          [src]="'../../../assets/images/' +unit.icon+ '.png'" class="unit-icon"
                                          [ngClass]="unit.icon"></span>
                                      <span *ngIf="unit.libraryid != ''"><img
                                          [src]="allinOneService.fileurl + 'icons/video.png'" class="unit-icon"
                                          [ngClass]="unit.icon"></span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>

                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 2"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span><img [src]="'../../../assets/images/' +unit.icon+ '.png'"
                                          class="unit-icon"></span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>

                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 3"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fa fa-file-text me-1" aria-hidden="true"></i>

                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>

                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 4"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fas fa-link me-1" aria-hidden="true"></i>

                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>

                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 5"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fas fa-paperclip me-1"></i>
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>

                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 6"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <i class="fas fa-clipboard-list me-1"></i>
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 7"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 8"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                  <div *ngIf="unit.contenttype == 10"
                                    class="d-flex align-items-center position-relative">
                                    <span class="unit-index-label">
                                      {{s+1}}.{{ss+1}}.{{su+1}}
                                    </span>
                                    <p>
                                      <span>
                                        <img [src]="'./../../assets/images/audio.png'" class="unit-icon">
                                      </span>
                                      <span> {{unit.unitname ? unit.unitname :
                                        ''}}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <i class="fa fa-lock me-3" *ngIf="!section.unlock"></i>
                                <mat-checkbox class="example-margin" [(ngModel)]="unit.checked" [checked]="unit.checked"
                                  *ngIf="section.unlock" (click)="updateCompletedUnits(unit, true)" disabled>
                                  <!-- [disabled]="unit.contenttype == 6 || unit.contenttype == 7 || unit.contenttype == 8" -->
                                </mat-checkbox>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="tab_active == 5" [ngClass]="{'new_tab': isMobileView}">
              <app-course-learn-pageview [course]="course"></app-course-learn-pageview>
            </div>
            <!-- About -->
            <div *ngIf="tab_active == 2" [ngClass]="{'new_tab': isMobileView}">

              <div class=" p-3 pt-lg-2 p-sm-0 pt-sm-2 mb-2">
                <div class="container">
                  <h3 style="font-weight: 700;">About this course</h3>

                  <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                    <p class="fs-6" [innerHTML]="getSafeHtml(course.description)">
                    </p>
                  </div>


                  <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;"
                    (click)="show = !show" *ngIf="course.description">
                    <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                        aria-hidden="true"></i></span>
                    <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up" aria-hidden="true"></i></span>
                  </button>
                </div>

              </div>

              <div class=" p-3  p-sm-0 mb-2">
                <div clapss="container">
                  <h3 style="font-weight: 700;">Instructor</h3>


                  <div class="instructor my-2">
                    <div class="instructor-default-img" *ngIf="course.profileimage == ''">
                      {{course.instructorname.charAt(0)}}
                    </div>
                    <img width="40" height="40" class="rounded-circle" *ngIf="course.profileimage != ''"
                      [src]="course.profileimageurl" alt="">
                    <div class="d-flex flex-column justify-content-center">
                      <span class="instructor-name fw-bold mx-2">{{course.instructorname}}</span>
                      <span class="institute mx-2" *ngIf="course.institute">{{course.institute}}</span>
                    </div>
                  </div>
                </div>

              </div>
              <!-- <button mat-flat-button color="primary" class="ms-3" (click)="openMobile()">
                                Page
                            </button> -->
              <div style="padding-left: 14px;padding-right: 14px;">
                <h3 style="font-weight: 700;">Mode</h3>

                <div class="d-flex">
                  <label class="form-check-label me-auto" for="flexSwitchCheckDefault">Page View</label>
                  <div class="form-check form-switch ms-3" style="font-size: 16px;">
                    <!--  -->
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [checked]="showPageView"
                      (change)="openMobile()">

                  </div>
                </div>
              </div>

            </div>
            <!-- Lecture View -->
            <div *ngIf="tab_active == 3"
              [ngClass]="{'tabview': isMobileView && lecture.unitid , 'new_tab': isMobileView && !lecture.unitid}">


              <ng-container *ngIf="lecture.contenttype == 1 || lecture.contenttype == 10">
                <div *ngIf="lecture.contenttype == 1">
                  <div *ngIf="lecture.libraryid==''">
                    <!-- <iframe width="420" height="315" [src]="lecture.content | safe" scrolling="no" frameborder="0"
                      allowfullscreen></iframe> -->
                    <iframe #vimeoPlayer width="420" height="315" [src]="lecture.content | safe" scrolling="no"
                      frameborder="0" allowfullscreen *ngIf="videotype == 'vimeo'"></iframe>

                    <vm-player #videoPlayer *ngIf="videotype == 'youtube'"
                      (vmCurrentTimeChange)="onVMCurrentTimeChange($event)">
                      <vm-youtube cookies="true" [attr.video-id]="lecture.content | getVideoId:videotype"
                        *ngIf="videotype =='youtube'"></vm-youtube>
                      <vm-default-ui controls no-loading-screen [noLoadingScreen]="true">
                        <vm-caption-control hideIcon="captions-off"></vm-caption-control>
                        <vm-scrim></vm-scrim>
                      </vm-default-ui>
                      <vm-ui>
                        <vm-loading-screen [hideDots]="true">
                        </vm-loading-screen>
                      </vm-ui>
                    </vm-player>
                  </div>

                  <div class="video-player" (click)="playedvideo()" *ngIf="lecture.libraryid!='' && !loadingVIMEplayer">
                    <vm-player #videoPlayer (vmCurrentTimeChange)="onVMCurrentTimeChange($event)">
                      <!-- <vm-loading-screen hideDots='true' >

                        </vm-loading-screen> -->
                      <vm-video>
                        <source [attr.data-src]="lecture.downloadurl" type="video/mp4" />
                      </vm-video>
                      <vm-default-ui controls no-loading-screen noLoadingScreen="true">

                        <vm-caption-control hideIcon="captions-off"></vm-caption-control>


                        <vm-scrim></vm-scrim>
                      </vm-default-ui>
                      <vm-ui>
                        <!-- <div  *ngIf="!playedVid" style="    font-size: 1.5rem;
                        padding: 1rem;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        border-radius: 25px;
                        background-color: rgba(182, 182, 182, 0.432);
                        margin: auto;
                        top: 45%;
                        position: absolute;
                        left: 45%;">
                          <i class='fas fa-play'></i>
                        </div> -->
                      </vm-ui>



                    </vm-player>
                  </div>

                </div>
                <div *ngIf="lecture.contenttype == 10" class="attachment-responsive p-2">
                  <div class="audio-container-mobile">
                    <!-- <audio controls>
                        <source [src]="lecture.libraryid ? lecture.downloadurl : allinOneService.docurl + lecture.content" type="audio/mpeg">
                        Your browser does not support the audio element.
                      </audio> -->

                  </div>
                  <div class="audio-player">
                    <vm-player #videoPlayer (vmCurrentTimeChange)="onVMCurrentTimeChange($event)">
                      <vm-audio>
                        <source
                          [attr.data-src]="lecture.libraryid ? lecture.downloadurl : allinOneService.docurl + lecture.content"
                          type="audio/mpeg" />
                      </vm-audio>
                      <vm-default-ui no-settings>
                      </vm-default-ui>
                    </vm-player>
                  </div>
                </div>
                <div>
                  <div class=" p-2 pt-lg-4 p-sm-0 pt-sm-2 mb-2">
                    <div class="container">
                      <h3 style="font-weight: 700;">About this course</h3>

                      <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                        <p class="fs-6 my-2" [innerHTML]="getSafeHtml(course.description)">
                        </p>
                      </div>

                      <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;"
                        (click)="show = !show" *ngIf="course.description">
                        <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                            aria-hidden="true"></i></span>
                        <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                            aria-hidden="true"></i></span>
                      </button>
                    </div>

                  </div>

                  <section class=" p-2  p-sm-0 mb-2">
                    <div class="container">
                      <h3 style="font-weight: 700;">Instructor</h3>


                      <div class="instructor my-2">
                        <div class="instructor-default-img" *ngIf="course.profileimage == ''">
                          {{course.instructorname.charAt(0)}}
                        </div>
                        <img width="40" height="40" class="rounded-circle" *ngIf="course.profileimage != ''"
                          [src]="course.profileimageurl" alt="">
                        <div class="d-flex flex-column justify-content-center">
                          <span class="instructor-name fw-bold mx-2">{{course.instructorname}}</span>
                          <span class="institute mx-2" *ngIf="course.institute">{{course.institute}}</span>
                        </div>
                      </div>
                    </div>

                  </section>
                </div>
              </ng-container>

              <div *ngIf="lecture.contenttype == 2 && !loadingpdf">
                <!-- <div style="text-align:right" class="my-3" *ngIf="havestudytime && !lecture.checked">
                  <span style="color: var(--main-color);font-weight: bold;">Study Time - </span> {{studytimer}}
                </div> -->
                <ngx-extended-pdf-viewer *ngIf="lecture.libraryid == ''"
                  [src]="allinOneService.docurl + lecture.content" [useBrowserLocale]="true" [textLayer]="true"
                  [zoom]="'page-width'" [showHandToolButton]="true" [showPresentationModeButton]="true"
                  [showDownloadButton]="lecture.allowdownload" [showPrintButton]="lecture.allowdownload" [showHandToolButton]="false"
                  [showRotateButton]="false" [showOpenFileButton]="false" [showBookmarkButton]="false"
                  [showFindButton]="false" [showSidebarButton]="false" height="calc(100vh - 193px)">
                </ngx-extended-pdf-viewer>
                <ngx-extended-pdf-viewer *ngIf="lecture.libraryid != ''" [src]="lecture.downloadurl"
                  [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [zoom]="'page-width'"
                  [showPresentationModeButton]="true" [showPrintButton]="lecture.allowdownload"
                  [showHandToolButton]="false" [showRotateButton]="false" [showDownloadButton]="lecture.allowdownload"
                  [showOpenFileButton]="false" [showBookmarkButton]="false" [showFindButton]="false"
                  [showSidebarButton]="false" height="calc(100vh - 193px)">
                </ngx-extended-pdf-viewer>
              </div>

              <div *ngIf="lecture.contenttype == 3" class="p-2" style="overflow: auto;">
                <p [innerHTML]="getSafeHtml(lecture.content)"></p>
              </div>

              <div *ngIf="lecture.contenttype == 4">
                <div class="url-responsive h-100">
                  <iframe width="100%" height="100%" [src]="lecture.content | safe" frameborder="0"
                    allowfullscreen></iframe>
                </div>
              </div>

              <div *ngIf="lecture.contenttype == 5" class="attachment-responsive h-100 p-3">
                <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                  <div class="mb-3 attachment-name">
                    {{lecture.content}}
                  </div>
                  <div>
                    <button mat-flat-button color="primary" (click)="downloadFile(lecture)">
                      <i class="fa fa-download me-2"></i>Download
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="lecture.contenttype == 6" class="attachment-responsive h-100 p-3">
                <div *ngIf="loadingquizz"
                  class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                  <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

                </div>
                <div *ngIf="!loadingquizz">

                  <div class="h-100 w-100 d-flex flex-column align-items-center">
                    <div class="d-flex justify-content-start w-100 mb-4 flex-column">
                      <div>
                        <h2 class="mb-0"><strong>
                            <i class="fas fa-clipboard-list me-1" aria-hidden="true"></i> {{lecture.unitname || 'Quiz'}}
                          </strong></h2>
                      </div>
                      <div>
                        {{questioncount ? questioncount + ' Questions' : ''}}
                      </div>
                    </div>

                    <div class="mb-4 w-100" [innerHTML]="lecture.content"></div>

                    <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-4">
                      <div class="d-flex flex-column ">
                        <div class="mb-2">
                          Attempt
                        </div>
                        <div>

                          <strong *ngIf="score != ''">{{attempt}}</strong>
                          <strong *ngIf="score == ''">-</strong>
                        </div>
                      </div>
                      <div class="tryagain" *ngIf="tryagain && score != ''" (click)="startQuiz(lecture)">
                        {{resumeQuiz ? 'Resume Quiz': 'Try Again'}}
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-between w-100">
                      <div class="d-flex flex-column" *ngIf="lecture.showfeedback">
                        <div class="mb-2">
                          Grade
                        </div>
                        <div *ngIf="course.grading == '001'" style="font-size: 20px;">
                          <div *ngIf="totalmark == '0'">
                            -
                          </div>
                          <div *ngIf="totalmark != '0'">
                            {{mark}}/{{totalmark}} points
                          </div>
                        </div>
                        <div *ngIf="course.grading == '002'">
                          <div *ngIf="score != ''" style="font-size: 20px; color: green;">
                            {{score | number:"1.1"}}%
                          </div>
                          <div *ngIf="score == ''" style="font-size: 20px;">
                            -
                          </div>
                        </div>
                      </div>
                      <div>
                        <button class="ms-0" *ngIf="score != '' && lecture.showfeedback && !isFromInstructor" mat-flat-button color="primary"
                          (click)="ViewQuizFeedBack()">
                          View Feedback
                        </button>
                        <button class="ms-0" *ngIf="score == '' && coursestatus == '002'" mat-flat-button
                          color="primary" (click)="startQuiz(lecture)">
                          {{resumeQuiz ? 'Resume Quiz': 'Start Quiz'}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="lecture.contenttype == 7" class="attachment-responsive h-100 p-3 pt-0">
                <mat-tab-group animationDuration="0ms" class="custom-tab">
                  <mat-tab>
                    <ng-template mat-tab-label>
                      {{assignment_label}}
                    </ng-template>
                    <div class="pt-3" [innerHtml]="getSafeHtml(lecture.content)"></div>

                    <div *ngIf="lecture.attachmentlist.length > 0" class="mb-3">
                      <div>
                        <div *ngFor="let attachment of lecture.attachmentlist">
                          <a class="text-break cus-point"
                            (click)="viewAttachmentFile(attachment.showfilename, attachment.libraryid ? attachment.downloadurl : attachment.fileurl)"
                            target="_blank">
                            <i class="fas fa-paperclip me-2"></i>
                            {{attachment.showfilename}}
                          </a>
                        </div>
                      </div>
                    </div>

                  </mat-tab>
                  <mat-tab *ngIf="course.class && course.classstatus == '001'">
                    <ng-template mat-tab-label>
                      My Submission
                    </ng-template>
                    <div class="mt-3">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h2 class="mb-0">
                            <strong>
                              <img src="../../../assets/images/assignment.png" width="20px">
                              {{lecture.unitname ||
                              'Assignment'}}
                            </strong>
                          </h2>
                        </div>
                        <ng-container *ngIf="!loadingassignment && deadlinedate">
                          <div *ngIf="!overdeadline" class="due-date-style">
                            <strong>
                              Due Date : {{deadlinedate }}, {{deadlinetime}}
                            </strong>
                          </div>
                          <div *ngIf="overdeadline" class="pass-due-style">
                            <strong> Past Due</strong>
                          </div>
                        </ng-container>

                      </div>
                      <div *ngIf="loadingassignment"
                        class="w-100 d-flex flex-column align-items-center justify-content-center"
                        style="height: 300px;">
                        <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

                      </div>
                      <div class="card p-3 my-3" *ngIf="!loadingassignment">
                        <div class="d-flex justify-content-between my-2 ">
                          <div class="">
                            <div *ngIf="assignmentResult.grade!='' && assignmentResult.filename!=''"><strong
                                style="color: green;text-align: end;">Graded</strong></div>
                            <div *ngIf="assignmentResult.grade=='' && assignmentResult.filename!=''"><strong
                                style="color: var(--main-color);text-align: end;">Submitted</strong></div>
                            <div *ngIf="assignmentResult.filename==''"><strong style="color: green;text-align: end;">Not
                                Submitted</strong></div>

                          </div>
                          <div class="file-title" style="font-size: .8rem;color: gray;">
                            {{assignmentResult.date ? allinOneService.formatDBToShow(assignmentResult.date) : ""}}
                            {{assignmentResult.time}}
                          </div>
                        </div>
                        <div class="fw-normal d-flex my-3" *ngIf="assignmentResult.filename">
                          <img _ngcontent-wax-c141="" width="20px" *ngIf="assignmentResult.filename!=''"
                            [src]="assignmentResult.iconurl" class="me-2">
                          <div class="file-title " (click)="downloadAssignmentFile(assignmentResult.fileurl)"
                            title="Download file">
                            {{assignmentResult.filename || '-'}}
                          </div>
                        </div>
                        <div class="mb-2" *ngIf="assignmentResult.grade!=''"><strong>Your grade</strong></div>
                        <div *ngIf="assignmentResult.grade!=''">
                          <span
                            style="color: green; font-size: 16px;">{{assignmentResult.grade}}/{{assignmentResult.totalgrade}}</span>
                          <!-- <span class="badge rounded-pill px-2"
                            style="background-color: var(--main-color);padding: 0.5rem;">{{assignmentResult.grade}}/{{assignmentResult.totalgrade}}</span> -->

                        </div>
                        <div class="d-flex flex-column mt-3"
                          *ngIf="assignmentResult.grade!= '' && assignmentResult.feedback">
                          <div class="mb-2"><strong>Feedback</strong></div>
                          <div>{{assignmentResult.feedback}}</div>
                        </div>
                        <div *ngIf="assignmentResult.grade==''" class="mt-1">
                          <div *ngIf="!overdeadline && !isFromInstructor">
                            <button mat-flat-button color="primary" style="margin: 0 !important;"
                              (click)="openAssignmentSubmissionBox(lecture)">
                              {{assignmentResult.filename ? 'Resubmit' : 'Submit Assignment'}}
                            </button>
                          </div>
                          <div *ngIf="overdeadline">
                            The due date for this assignment is {{deadlinedate }}, {{deadlinetime}}.
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
              <div *ngIf="lecture.contenttype == 8" class="attachment-responsive h-100 p-2">
                <div class="d-flex flex-column justify-content-start">
                  <div class="d-flex flex-column justify-content-start discussion-parent">
                    <div class="p-2 ">
                      <div class="container d-flex justify-content-between">
                        <div class="instructor my-2">
                          <div class="instructor-default-img" *ngIf="course.profileimage == ''">
                            {{course.instructorname.charAt(0)}}
                          </div>
                          <img width="40" height="40" class="rounded-circle" *ngIf="course.profileimage != ''"
                            [src]="course.profileimageurl" alt="">
                          <div class="d-flex flex-column justify-content-center">
                            <span class="instructor-name fw-bold mx-2">{{course.instructorname}}<span
                                class="ms-2 badge bg-main">Instructor</span></span>
                            <span class="institute mx-2" *ngIf="course.institute">{{course.institute}}</span>
                          </div>
                        </div>
                        <div>

                        </div>
                      </div>
                      <div>
                        <p [innerHTML]="getSafeHtml(lecture.content)" class="p-3"></p>
                        <div *ngIf="lecture.attachment || lecture.libraryid" class="p-3 pt-0">
                          <div>
                            <a [href]="lecture.attachment ? lecture.attachmenturl : lecture.downloadurl"
                              target="_blank"><i class="fas fa-paperclip me-2"></i>{{lecture.showfilename}}</a>
                          </div>
                        </div>
                      </div>

                    </div>
                    <mat-divider></mat-divider>
                    <div class="d-flex justify-content-start">

                      <button mat-button color="primary" style="width: 100%;" (click)="openFirstReply()"
                        [disabled]="coursestatus != '002'">
                        <i class="fa fa-comments-o me-2" aria-hidden="true"></i>Reply</button>
                    </div>
                  </div>
                  <div *ngIf="firstreply" class="my-3">
                    <angular-editor [(ngModel)]="firstreplyconent" [config]="config"></angular-editor>
                    <div class="w-100 d-flex justify-content-end my-3">
                      <button mat-flat-button color="primary" class="px-2" (click)="reply()" [disabled]="firstreplying">
                        <span *ngIf="firstreplying">
                          <div class="spinner-border spinner-border-sm" role="status" style="color: white !important;">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </span>
                        <span *ngIf="!firstreplying">
                          Reply
                        </span>
                      </button>
                    </div>
                    <mat-divider></mat-divider>
                  </div>
                  <div>
                    <div class="d-flex justify-content-between mt-2">
                      <div class="reply-title">
                        Replies
                      </div>
                      <div class="main-color">
                        Sort by: Latest
                      </div>
                    </div>
                    <div class="d-flex flex-column my-2">

                      <div *ngIf="!lecture.gettingreply && lecture.replylist.length == 0" class="nodata">
                        Reply to see other learners' responses.
                      </div>
                      <div *ngIf="lecture.replylist.length > 0">
                        <ng-container *ngFor="let discussion of lecture.replylist">
                          <div class="reply-box d-flex flex-column">
                            <div class="container d-flex justify-content-between">
                              <div class="instructor my-2">
                                <div class="instructor-default-img" *ngIf="discussion.profileimage == ''">
                                  {{discussion.username.charAt(0)}}
                                </div>
                                <img width="40" height="40" class="rounded-circle" *ngIf="discussion.profileimage != ''"
                                  [src]="discussion.profileimage" alt="">
                                <div class="d-flex flex-column justify-content-center">
                                  <span class="instructor-name fw-bold mx-2">{{discussion.username}}<span
                                      class="ms-2 badge bg-main" *ngIf="discussion.instructor">Instructor</span></span>
                                  <span class="institute mx-2"
                                    *ngIf="discussion.instructor">{{discussion.institute}}</span>
                                </div>
                              </div>
                              <div>
                                {{(discussion.date + ' '+ discussion.time) | timeAgo}}
                              </div>
                            </div>
                            <div class="px-3 py-2">
                              <span [innerHTML]="getSafeHtml(discussion.message)"></span>
                            </div>
                            <mat-divider></mat-divider>

                            <div class="d-flex justify-content-end">

                              <button mat-button color="primary"
                                *ngIf="discussion.totalreplies && discussion.totalreplies!= 0 "
                                (click)="openReplies(discussion)">
                                <i class="fa fa-comments-o me-2"></i>
                                {{discussion.totalreplies}} Replies</button>

                              <button mat-button color="primary" (click)="discussion.reply = !discussion.reply">
                                Reply</button>


                            </div>
                          </div>
                          <div class="second-reply-box" *ngIf="discussion.reply">
                            <angular-editor [(ngModel)]="discussion.replycontent" [config]="config"></angular-editor>
                            <div class="w-100 d-flex justify-content-end my-3">
                              <button mat-flat-button color="primary" class="px-2" (click)="reply(discussion)"
                                [disabled]="discussion.replying">
                                <span *ngIf="discussion.replying">
                                  <div class="spinner-border spinner-border-sm" role="status"
                                    style="color: white !important;">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </span>
                                <span *ngIf="!discussion.replying">
                                  Reply
                                </span>
                              </button>
                            </div>
                            <mat-divider></mat-divider>
                          </div>
                          <div class="second-reply-box" *ngIf="discussion.open">
                            <!-- <div *ngIf="!discussion.gettingreply && discussion.replylist.length == 0" class="nodata">
                              No reply yet
                            </div> -->
                            <div *ngIf="discussion.replylist.length > 0">
                              <ng-container *ngFor="let reply of discussion.replylist">
                                <div class="reply-box d-flex flex-column">
                                  <div class="container d-flex justify-content-between">
                                    <div class="instructor my-2">
                                      <div class="instructor-default-img" *ngIf="reply.profileimage == ''">
                                        {{reply.username.charAt(0)}}
                                      </div>
                                      <img width="40" height="40" class="rounded-circle"
                                        *ngIf="reply.profileimage != ''" [src]="reply.profileimage" alt="">
                                      <div class="d-flex flex-column justify-content-center">
                                        <span class="instructor-name fw-bold mx-2">{{reply.username}}<span
                                            class="ms-2 badge bg-main" *ngIf="reply.instructor">Instructor</span></span>
                                        <span class="institute mx-2" *ngIf="reply.instructor">{{reply.institute}}</span>
                                      </div>
                                    </div>
                                    <div>
                                      {{(reply.date +' '+reply.time) | timeAgo}}
                                    </div>
                                  </div>
                                  <div class="px-3 py-2">
                                    <span [innerHTML]="getSafeHtml(reply.message)"></span>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                            <div *ngIf="discussion.gettingreply" class="nodata">
                              <div class="spinner-border spinner-border-md main-color">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                            <div class="view-more-replies"
                              *ngIf="discussion.replylist.length == discussion.currentcount && !discussion.gettingreply"
                              (click)="getDiscussionReplies(discussion, true)">
                              View more replies...
                            </div>
                          </div>
                        </ng-container>
                        <div class="view-more-replies"
                          *ngIf="!lecture.gettingreply && lecture.replylist.length == firstreplycurrentcount"
                          (click)="getDiscussion(true)">
                          View more replies...
                        </div>
                      </div>
                      <div *ngIf="lecture.gettingreply" class="nodata">
                        <div class="spinner-border spinner-border-md main-color">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>
              </div>

            </div>
            <!-- Dropbox -->
            <div *ngIf="tab_active == 4" [ngClass]="{'new_tab': isMobileView}">
              <div class="p-3">
                <app-dropbox [classid]="course.classid" [courseid]="course.courseid" [sections]="sectionsForDropbox"
                  [units]="assignmentUnits">
                </app-dropbox>
              </div>
            </div>
            <!-- Grade Page View -->
            <div *ngIf="tab_active == 6" [ngClass]="{'new_tab': isMobileView}">
              <div class="p-2">
                <app-grades [isMobileView]="isMobileView" (viewLecture)="viewLectureFromGrade($event)"></app-grades>
              </div>
            </div>
            <!-- Certificate Page View -->
            <div *ngIf="tab_active == 7" [ngClass]="{'new_tab': isMobileView}">
              <div class="p-2">
                <app-certificate [coursename]="course.coursename" [templateid]="classTemplate?.templateid" ></app-certificate>
              </div>
            </div>
          </div>
        </div>


        <div class="learn-content {{pageview == '1' ? 'w-100' : 'w-75' }}" *ngIf="!isMobileView">
          <div class="web-view p-3 pt-1" *ngIf="lecture.unitid">
            <div class="d-flex justify-content-between align-items-center mb-4 mt-2 d-none d-sm-flex sticky-header" style="position: relative;">
              <div style="min-width: 80px;">
                <div *ngIf="!isFirst" class="d-flex align-items-baseline d-none d-sm-flex">
                  <a href='javascript:;' (click)='previousLecture();' class="previous-next">
                    <i class="fa fa-chevron-left"> </i>
                    <span id="previousNext" class="mx-2">Previous</span>
                  </a>
                </div>
              </div>


              <div class="center-unitname"
                *ngIf="lecture.unitname != '' && lecture.contenttype == 1">

                <img *ngIf="lecture.libraryid == ''" [src]="'../../../assets/images/' +lecture.icon+ '.png'"
                  class="title-icon">
                <img *ngIf="lecture.libraryid != ''" [src]="allinOneService.fileurl + 'icons/video.png'"
                  class="title-icon">


                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 2">

                <img [src]="'../../../assets/images/' +lecture.icon+ '.png'" class="title-icon">
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>
              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 3">

                <i class="fa fa-file-text me-1" aria-hidden="true"></i>
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 4">

                <i class="fas fa-link me-1" aria-hidden="true"></i>
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 5">

                <i class="fas fa-paperclip me-1" aria-hidden="true"></i>
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.subsectionname != '' &&lecture.contenttype == 6">

                <i class="fas fa-clipboard-list me-1" aria-hidden="true"></i>
                <span class="course-title"> {{lecture.subsectionname }} </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 7">

                <img [src]="'./../../assets/images/assignment.png'" class="unit-icon">
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 8">

                <img [src]="'./../../assets/images/discussion.png'" class="unit-icon">
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <div class="center-unitname"
                *ngIf="lecture.unitname != '' &&lecture.contenttype == 10">

                <img [src]="'./../../assets/images/audio.png'" class="unit-icon">
                <span class="course-title ms-2"> {{lecture.unitname }}
                </span>
              </div>

              <span *ngIf="lecture.unitname == ''">
                <span class="current-no1 align-items-center">
                  ({{currentIndex}} of {{lecturelist.length}})
                </span>
              </span>

              <div class="current-unit justify-content-end" style="min-width: 80px;">
                <!-- <p>123</p> -->
                <span
                  *ngIf="!gettingstudytimer && (lecture.contenttype == 2 || lecture.contenttype == 3 ) && havestudytime && !lecture.checked">
                  <span style="color: var(--main-color);font-weight: bold;">{{studytimer}}</span> <span
                    class="mx-2">|</span> </span>
                <div *ngIf="lecture.contenttype == 6 && havequiztimer" class="timer">
                  <div class="me-2 d-flex align-items-center">
                    <mat-icon>access_time</mat-icon>
                  </div>
                  <div>
                    {{quiztimer}}
                  </div> <span class="mx-2">|</span>
                </div>
                <!-- <span class="mx-2">|</span> -->
                <span class="current-no" *ngIf="lecture.unitname != '' ">
                  ({{currentIndex}} of {{lecturelist.length}})
                </span>

                <div class="layout-full-container" *ngIf="!isMobileView" (click)="changeLayoutView()">
                  <div class="d-flex justify-content-center">
                    <img src="assets/images/view2.png" alt="Full Screen View" matTooltip="Full Screen View"
                      class="layout-fullview-logo" *ngIf="pageview == '0'">
                    <img src="assets/images/view3.png" alt="Page View" matTooltip="Page View"
                      class="layout-fullview-logo" *ngIf="pageview == '1' && !this.lecture.quizview">
                    <img src="assets/images/view1.png" alt="Learning View" matTooltip="Learning View"
                      class="layout-fullview-logo"
                      *ngIf="pageview == '1' && this.lecture.quizview && this.lecture.resultid == 'start'">
                  </div>
                  <!-- <span class="layout-label" for="flexRadioDefault1" style="cursor: pointer;">
                    View
                  </span> -->
                </div>

                <div *ngIf="!isLast" class="d-flex align-items-baseline d-none d-sm-flex">
                  <a href='javascript:;' (click)='nextLecture();' class="previous-next" style="text-align: right;">
                    <span id="previousNext" class="mx-2">Next</span>
                    <i class="fa fa-chevron-right"> </i>
                  </a>
                </div>
              </div>

            </div>




            <div class="video-responsive" *ngIf="lecture.contenttype == 1 && !isMobileView">
              <div *ngIf="lecture.libraryid=='' && !loadingVIMEplayer">
                <iframe #vimeoPlayer width="420" height="315" [src]="lecture.content | safe" scrolling="no"
                  frameborder="0" allowfullscreen *ngIf="videotype == 'vimeo'"></iframe>

                <vm-player #videoPlayer *ngIf="videotype == 'youtube'" [autoplay]="false" (vmPlay)="onVideoPlay()"
                  (vmCurrentTimeChange)="onVMCurrentTimeChange($event)">
                  <vm-youtube cookies="true" [attr.video-id]="lecture.content | getVideoId:videotype"
                    *ngIf="videotype =='youtube'"></vm-youtube>
                  <vm-default-ui controls no-loading-screen [noLoadingScreen]="true">
                    <vm-caption-control hideIcon="captions-off"></vm-caption-control>
                    <vm-scrim></vm-scrim>
                  </vm-default-ui>
                  <vm-ui>
                    <vm-loading-screen [hideDots]="true">
                    </vm-loading-screen>
                  </vm-ui>
                </vm-player>
              </div>

              <div class="video-player" (click)="playedvideo()" *ngIf="lecture.libraryid!='' && !loadingVIMEplayer">
                <vm-player #videoPlayer [autoplay]="false" (vmPlay)="onVideoPlay()"
                  (vmCurrentTimeChange)="onVMCurrentTimeChange($event)">
                  <vm-video>
                    <source [attr.data-src]="lecture.downloadurl" type="video/mp4" />
                  </vm-video>
                  <vm-default-ui controls no-loading-screen noLoadingScreen="true">

                    <vm-caption-control hideIcon="captions-off"></vm-caption-control>


                    <vm-scrim></vm-scrim>
                  </vm-default-ui>
                  <vm-ui>
                    <div *ngIf="!playedVid" style="    font-size: 1.5rem;
                    padding: 1rem;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    border-radius: 25px;
                    background-color: rgba(182, 182, 182, 0.432);
                    margin: auto;
                    top: 45%;
                    position: absolute;
                    left: 45%;">
                      <i class='fas fa-play'></i>
                    </div>
                  </vm-ui>
                </vm-player>
              </div>
            </div>
            <div *ngIf="lecture.contenttype == 2 && !loadingpdf" class="learn-content-web">
              <ngx-extended-pdf-viewer *ngIf="!isMobileView && lecture.libraryid == ''"
                [src]="allinOneService.docurl + lecture.content" [useBrowserLocale]="true" [textLayer]="true"
                [zoom]="'page-width'" [showHandToolButton]="true" [showPresentationModeButton]="true"
                [showDownloadButton]="lecture.allowdownload" [showPrintButton]="lecture.allowdownload" [showHandToolButton]="false"
                [showRotateButton]="false" [showOpenFileButton]="false" [showBookmarkButton]="false"
                [showFindButton]="false" [showSidebarButton]="false" height="calc(100vh - 75px)">
              </ngx-extended-pdf-viewer>
              <ngx-extended-pdf-viewer *ngIf="!isMobileView && lecture.libraryid != ''" [src]="lecture.downloadurl"
                [zoom]="'page-width'" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true"
                [showPresentationModeButton]="true" [showPrintButton]="lecture.allowdownload"
                [showHandToolButton]="false" [showRotateButton]="false" [showDownloadButton]="lecture.allowdownload"
                [showOpenFileButton]="false" [showBookmarkButton]="false" [showFindButton]="false"
                [showSidebarButton]="false" height="calc(100vh - 75px)">
              </ngx-extended-pdf-viewer>
            </div>

            <div *ngIf="lecture.contenttype == 3" class="pt-1 learn-content-web" style="overflow: auto;">
              <!-- <div [innerHTML]="lecture.content"></div> -->
              <div [innerHTML]="getSafeHtml(lecture.content)"></div>
            </div>

            <div class="url-responsive" *ngIf="lecture.contenttype == 4 && !isMobileView">
              <iframe class="url-res" width="420" height="395" [src]="lecture.content | safe" frameborder="0"
                allowfullscreen></iframe>
            </div>

            <div *ngIf="lecture.contenttype == 5 && !isMobileView" class="attachment-responsive">
              <div class="w-100 d-flex flex-column align-items-center justify-content-center" style="height: 300px;">
                <div class="mb-3 attachment-name">
                  {{lecture.content}}
                </div>
                <div>
                  <!-- test here -->
                  <button mat-flat-button color="primary" (click)="downloadFile(lecture)">
                    <i class="fa fa-download me-2"></i>Download
                  </button>
                </div>
              </div>
            </div>

            <!-- Quizz -->
            <div *ngIf="lecture.contenttype == 6 && !lecture.quizview &&!isMobileView">

              <div *ngIf="loadingquizz" class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="height: 300px;">
                <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

              </div>

              <div *ngIf="!loadingquizz">

                <div class="h-100 w-100 d-flex flex-column align-items-center p-5">
                  <div class="d-flex justify-content-start w-100 mb-4 flex-column">
                    <div>
                      <h2 class="mb-0"><strong>
                          <i class="fas fa-clipboard-list me-1" aria-hidden="true"></i> {{lecture.unitname || 'Quiz'}}
                        </strong></h2>
                    </div>
                    <div>
                      {{questioncount ? questioncount + ' Questions' : ''}}
                    </div>
                  </div>

                  <div class="mb-4 w-100" [innerHTML]="getSafeHtml(lecture.content)"></div>

                  <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-4">
                    <div class="d-flex flex-column">
                      <div class="mb-2">
                        Attempt
                      </div>
                      <div>

                        <strong *ngIf="score != ''">{{attempt}}</strong>
                        <strong *ngIf="score == ''">-</strong>
                      </div>
                    </div>
                    <div class="tryagain" *ngIf="tryagain && score != ''" (click)="startQuiz(lecture)">
                      {{resumeQuiz ? 'Resume Quiz': 'Try Again'}}
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center justify-content-between w-100">
                    <div class="d-flex flex-column" *ngIf="lecture.showfeedback">
                      <div class="mb-2">
                        Grade
                      </div>
                      <div *ngIf="course.grading == '001'" style="font-size: 20px;">
                        <div *ngIf="totalmark == '0'">
                          -
                        </div>
                        <div *ngIf="totalmark != '0'">
                          {{mark}}/{{totalmark}} points
                        </div>
                      </div>
                      <div *ngIf="course.grading == '002'">
                        <div *ngIf="score != ''" style="font-size: 20px; color: green;">
                          {{score | number:"1.0-2"}}%
                        </div>
                        <div *ngIf="score == ''" style="font-size: 20px;">
                          -
                        </div>
                      </div>
                    </div>
                    <div>
                      <button class="ms-0" *ngIf="score != '' && lecture.showfeedback && !isFromInstructor" mat-flat-button color="primary"
                        (click)="ViewQuizFeedBack()">
                        View Feedback
                      </button>
                      <button class="ms-0" *ngIf="score == '' && coursestatus == '002'" mat-flat-button color="primary"
                        (click)="startQuiz(lecture)">
                        {{resumeQuiz ? 'Resume Quiz': 'Start Quiz'}}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- pageview == '0' ? '60': '0' -->
            <div class="web-view" *ngIf="lecture.contenttype == 6 && lecture.quizview && !isMobileView"
              [ngStyle]="{'margin-bottom.px': pageview == '0' ? '60' : '0'}">
              <app-question [unitid]="lecture.unitid" [courseid]="course.courseid" [status]="lecture.resultid" [grading]="course.grading"
                [sectionid]="lecture.sectionid" [subsectionid]="lecture.subsectionid" [smallscreenview]="true"
                [autonumbering]="lecture.autonumbering" [isFromInstructor]="isFromInstructor" (cancel)="cancelQuiz()" (complete)="completeQuiz($event)"
                (startTimer)="startQuizTimer($event)" (stopTimer)="stopQuizTimer()"
                (changedAnsList)="getQuisAnsFromChild($event)" (callQuizCache)="callQuizCacheAPI()"></app-question>
            </div>

            <!-- Assignment -->
            <div *ngIf="lecture.contenttype == 7 && !isMobileView">
              <mat-tab-group animationDuration="0ms" class="custom-tab">
                <mat-tab>
                  <ng-template mat-tab-label>
                    {{assignment_label}}
                  </ng-template>
                  <div class="pt-3" [innerHtml]="getSafeHtml(lecture.content)"></div>
                  <div *ngIf="lecture.attachmentlist.length > 0" class="mb-3">
                    <div>
                      <div *ngFor="let attachment of lecture.attachmentlist">
                        <a class="text-break cus-point"
                          (click)="viewAttachmentFile(attachment.showfilename, attachment.libraryid ? attachment.downloadurl : attachment.fileurl)"
                          target="_blank">
                          <i class="fas fa-paperclip me-2"></i>
                          {{attachment.showfilename}}
                        </a>
                      </div>
                    </div>

                  </div>
                </mat-tab>
                <mat-tab *ngIf="course.class && course.classstatus == '001'">
                  <ng-template mat-tab-label>
                    My Submission
                  </ng-template>
                  <div>
                    <div *ngIf="loadingassignment"
                      class="w-100 d-flex flex-column align-items-center justify-content-center" style="height: 300px;">
                      <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>

                    </div>

                    <div *ngIf="!loadingassignment && assignmentResult" class="w-80 px-3">
                      <div class="d-flex justify-content-between align-items-center mt-3 mb-1">
                        <div>
                          <h2 class="mb-0">
                            <strong>
                              <img src="../../../assets/images/assignment.png" width="20px">
                              {{lecture.unitname ||
                              'Assignment'}}
                            </strong>
                          </h2>
                        </div>
                        <div class="d-flex flex-column align-items-end justify-content-end">
                          <ng-container *ngIf="!loadingassignment && !deadlinedate">
                            <div style="text-align: end;">Status</div>
                            <div *ngIf="assignmentResult.grade!='' && assignmentResult.filename!=''"><strong
                                style="color: green;text-align: end;">Graded</strong></div>
                            <div *ngIf="assignmentResult.grade=='' && assignmentResult.filename!=''"><strong
                                style="color: var(--main-color);text-align: end;">Submitted</strong></div>
                            <div *ngIf="assignmentResult.filename==''"><strong style="color: green;text-align: end;">Not
                                Submitted</strong></div>
                          </ng-container>
                          <ng-container *ngIf="!loadingassignment && deadlinedate">
                            <div *ngIf="!overdeadline" class="due-date-style"><strong>
                                Due Date : {{
                                deadlinedate }}, {{deadlinetime}}</strong></div>
                            <div *ngIf="overdeadline" class="pass-due-style"><strong> Past Due
                              </strong></div>
                          </ng-container>
                          <!-- <div style="color : red;"><strong> Pass date</strong></div> -->
                        </div>
                      </div>
                      <div class="d-flex mb-2 flex-column justify-content-center align-items-end" *ngIf="deadlinedate">
                        <div style="text-align: end;">Status</div>
                        <div *ngIf="assignmentResult.grade!='' && assignmentResult.filename!=''"><strong
                            style="color: green;text-align: end;">Graded</strong></div>
                        <div *ngIf="assignmentResult.grade=='' && assignmentResult.filename!=''"><strong
                            style="color: var(--main-color);text-align: end;">Submitted</strong></div>
                        <div *ngIf="assignmentResult.filename==''"><strong style="color: green;text-align: end;">Not
                            Submitted</strong></div>
                      </div>
                      
                      <!-- <div *ngIf="
                        (this.lecture.multifileupload == false && assignmentResult.filename=='' && course.class && course.classstatus == '001' && !isFromInstructor) || 
                        (this.lecture.multifileupload === true)
                        "
                        class="my-3" style="  padding: 2rem;
                        border: 1px solid #dedcdc;"
                      >
                        <div class="d-flex justify-content-center">
                          <div *ngIf="!overdeadline && !isFromInstructor">
                            <button mat-flat-button color="primary" (click)="openAssignmentSubmissionBox(lecture)">
                              Submit Assignment
                            </button>
                          </div>
                          <div *ngIf="overdeadline">
                            The due date for this assignment is {{deadlinedate }}, {{deadlinetime}}.
                          </div>
                        </div>
                      </div> -->

                      <div *ngIf="assignmentResult.filename=='' && course.class && course.classstatus == '001' && !isFromInstructor"
                        class="my-5" style="  padding: 2rem;
                      border: 1px solid #dedcdc;">
                        <div class="d-flex justify-content-center">
                          <div *ngIf="!overdeadline && !isFromInstructor">
                            <button mat-flat-button color="primary" (click)="openAssignmentSubmissionBox(lecture)">
                              Submit Assignment
                            </button>
                          </div>
                          <div *ngIf="overdeadline">
                            The due date for this assignment is {{deadlinedate }}, {{deadlinetime}}.
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-row align-items-center justify-content-between w-100 mb-4"
                        *ngIf="assignmentResult.filename!=''">
                        <div style="margin-top: 20px;width: 100%;">
                          <table class="table align-middle mb-0 bg-white">
                            <thead class="bg-light">
                              <tr>
                                <th>Submitted File</th>
                                <th>Submitted Date</th>
                                <th>Grade</th>
                                <th>Feedback</th>
                                <th></th>
                                <!-- <th>Action</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="fw-normal mb-1 d-flex">
                                    <img _ngcontent-wax-c141="" width="20px" *ngIf="assignmentResult.filename!=''"
                                      [src]="assignmentResult.iconurl" class="me-2">
                                    <div class="file-title " (click)="downloadAssignmentFile(assignmentResult.fileurl)"
                                      title="Download file">
                                      {{assignmentResult.filename || '-'}}
                                    </div>
                                  </div>
                                </td>
                                <td style="width: 25%;">
                                  <p class="fw-normal mb-1"> {{allinOneService.formatDBToShow(assignmentResult.date)}}
                                    {{assignmentResult.time}}
                                  </p>
                                </td>
                                <td style="width: 10%;">{{assignmentResult.grade || '-'}} /
                                  {{assignmentResult.totalgrade}} </td>
                                <td style="width: 25%;">{{assignmentResult.feedback || '-'}} </td>
                                <td>
                                  <i class="fa fa-check-circle-o ms-2" style="font-size: 20px; color: green;"></i>

                                  <!-- <i *ngIf="assignmentResult.grade != '' " class="fa fa-check-circle-o ms-2" style="font-size: 20px; color: green;"></i>

                                  <div class="d-flex justify-content-end"
                                    *ngIf="assignmentResult.grade=='' && assignmentResult.filename !=''">
                                    <div class="resubmit-container" title="If you submitted wrong file, please resubmit" *ngIf="!overdeadline">
                                      <span class="resubmit" (click)="openAssignmentSubmissionBox(lecture)">
                                        Resubmit
                                      </span>
                                    </div>
                                  </div> -->
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end"
                        *ngIf="assignmentResult.grade=='' && assignmentResult.filename !=''">
                        <div class="resubmit-container" *ngIf="!overdeadline">

                          <span class="text">If you submitted wrong file, please </span><span class="resubmit"
                            (click)="openAssignmentSubmissionBox(lecture)">Resubmit</span>
                        </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <!-- Discussion -->
            <div *ngIf="lecture.contenttype == 8 && !isMobileView"
              [ngStyle]="{'margin-bottom.px': pageview == '0' ? '60': '0'}">
              <div class="d-flex flex-column justify-content-start">
                <div class="d-flex flex-column justify-content-start discussion-parent">
                  <div class="p-2 ">
                    <div class="container d-flex justify-content-between">
                      <div class="instructor my-2">
                        <div class="instructor-default-img" *ngIf="course.profileimage == ''">
                          {{course.instructorname.charAt(0)}}
                        </div>
                        <img width="40" height="40" class="rounded-circle" *ngIf="course.profileimage != ''"
                          [src]="course.profileimageurl" alt="">
                        <div class="d-flex flex-column justify-content-center">
                          <span class="instructor-name fw-bold mx-2">{{course.instructorname}}<span
                              class="ms-2 badge bg-main">Instructor</span></span>
                          <span class="institute mx-2" *ngIf="course.institute">{{course.institute}}</span>
                        </div>
                      </div>
                      <div>

                      </div>
                    </div>
                    <div>
                      <p [innerHTML]="getSafeHtml(lecture.content)" class="p-3"></p>
                      <div *ngIf="lecture.attachment || lecture.libraryid" class="p-3 pt-0">
                        <div>
                          <a [href]="lecture.attachment ? lecture.attachmenturl : lecture.downloadurl"
                            target="_blank"><i class="fas fa-paperclip me-2"></i>{{lecture.showfilename}}</a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div *ngIf="!isFromInstructor">
                    <mat-divider></mat-divider>
                    <div class="d-flex justify-content-start">

                      <button mat-button color="primary" style="width: 100%;" (click)="openFirstReply()"
                        [disabled]="coursestatus != '002'">
                        <i class="fa fa-comments-o me-2" aria-hidden="true"></i>Reply</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="firstreply" class="my-3">
                  <angular-editor [(ngModel)]="firstreplyconent" [config]="config"></angular-editor>
                  <div class="w-100 d-flex justify-content-end my-3">
                    <button mat-flat-button color="primary" class="px-2" (click)="reply()" [disabled]="firstreplying">
                      <span *ngIf="firstreplying">
                        <div class="spinner-border spinner-border-sm" role="status" style="color: white !important;">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </span>
                      <span *ngIf="!firstreplying">
                        Reply
                      </span>
                    </button>
                  </div>
                  <mat-divider></mat-divider>
                </div>
                <div>
                  <div class="d-flex justify-content-between mt-2">
                    <div class="reply-title">
                      Replies
                    </div>
                    <div class="main-color">
                      Sort by: Latest
                    </div>
                  </div>
                  <div class="d-flex flex-column my-2">

                    <div *ngIf="!lecture.gettingreply && lecture.replylist.length == 0" class="nodata">
                      Reply to see other learners' responses.
                    </div>
                    <div *ngIf="lecture.replylist.length > 0">
                      <ng-container *ngFor="let discussion of lecture.replylist">
                        <div class="reply-box d-flex flex-column">
                          <div class="container d-flex justify-content-between">
                            <div class="instructor my-2">
                              <div class="instructor-default-img" *ngIf="discussion.profileimage == ''">
                                {{discussion.username.charAt(0)}}
                              </div>
                              <img width="40" height="40" class="rounded-circle" *ngIf="discussion.profileimage != ''"
                                [src]="discussion.profileimage" alt="">
                              <div class="d-flex flex-column justify-content-center">
                                <span class="instructor-name fw-bold mx-2">{{discussion.username}}<span
                                    class="ms-2 badge bg-main" *ngIf="discussion.instructor">Instructor</span></span>
                                <span class="institute mx-2"
                                  *ngIf="discussion.instructor">{{discussion.institute}}</span>
                              </div>
                            </div>
                            <div>
                              {{(discussion.date + ' '+ discussion.time) | timeAgo}}
                            </div>
                          </div>
                          <div class="px-3 py-2">
                            <span [innerHTML]="getSafeHtml(discussion.message)"></span>
                          </div>
                          <mat-divider></mat-divider>

                          <div class="d-flex justify-content-end">

                            <button mat-button color="primary"
                              *ngIf="discussion.totalreplies && discussion.totalreplies!= 0 "
                              (click)="openReplies(discussion)">
                              <i class="fa fa-comments-o me-2"></i>
                              {{discussion.totalreplies}} Replies</button>

                            <button mat-button color="primary" (click)="discussion.reply = !discussion.reply">
                              Reply</button>


                          </div>
                        </div>
                        <div class="second-reply-box" *ngIf="discussion.reply">
                          <angular-editor [(ngModel)]="discussion.replycontent" [config]="config"></angular-editor>
                          <div class="w-100 d-flex justify-content-end my-3">
                            <button mat-flat-button color="primary" class="px-2" (click)="reply(discussion)"
                              [disabled]="discussion.replying">
                              <span *ngIf="discussion.replying">
                                <div class="spinner-border spinner-border-sm" role="status"
                                  style="color: white !important;">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>
                              <span *ngIf="!discussion.replying">
                                Reply
                              </span>
                            </button>
                          </div>
                          <mat-divider></mat-divider>
                        </div>
                        <div class="second-reply-box" *ngIf="discussion.open">
                          <!-- <div *ngIf="!discussion.gettingreply && discussion.replylist.length == 0" class="nodata">
                            No reply yet
                          </div> -->
                          <div *ngIf="discussion.replylist.length > 0">
                            <ng-container *ngFor="let reply of discussion.replylist">
                              <div class="reply-box d-flex flex-column">
                                <div class="container d-flex justify-content-between">
                                  <div class="instructor my-2">
                                    <div class="instructor-default-img" *ngIf="reply.profileimage == ''">
                                      {{reply.username.charAt(0)}}
                                    </div>
                                    <img width="40" height="40" class="rounded-circle" *ngIf="reply.profileimage != ''"
                                      [src]="reply.profileimage" alt="">
                                    <div class="d-flex flex-column justify-content-center">
                                      <span class="instructor-name fw-bold mx-2">{{reply.username}}<span
                                          class="ms-2 badge bg-main" *ngIf="reply.instructor">Instructor</span></span>
                                      <span class="institute mx-2" *ngIf="reply.instructor">{{reply.institute}}</span>
                                    </div>
                                  </div>
                                  <div>
                                    {{(reply.date +' '+reply.time) | timeAgo}}
                                  </div>
                                </div>
                                <div class="px-3 py-2">
                                  <span [innerHTML]="getSafeHtml(reply.message)"></span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <div *ngIf="discussion.gettingreply" class="nodata">
                            <div class="spinner-border spinner-border-md main-color">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                          <div class="view-more-replies"
                            *ngIf="discussion.replylist.length == discussion.currentcount && !discussion.gettingreply"
                            (click)="getDiscussionReplies(discussion, true)">
                            View more replies...
                          </div>
                        </div>
                      </ng-container>
                      <div class="view-more-replies"
                        *ngIf="!lecture.gettingreply && lecture.replylist.length == firstreplycurrentcount"
                        (click)="getDiscussion(true)">
                        View more replies...
                      </div>
                    </div>
                    <div *ngIf="lecture.gettingreply" class="nodata">
                      <div class="spinner-border spinner-border-md main-color">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>

            <div *ngIf="lecture.contenttype == 10 && !isMobileView">
              <div class="w-100 d-flex flex-column justify-content-end audio-container" style="height: 400px;">
                <!-- <audio controls>
                    <source [src]="lecture.libraryid ? lecture.downloadurl : allinOneService.docurl + lecture.content" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio> -->

              </div>
              <div class="audio-player">
                <vm-player #videoPlayer (vmCurrentTimeChange)="onVMCurrentTimeChange($event)">
                  <vm-audio>
                    <source
                      [attr.data-src]="lecture.libraryid ? lecture.downloadurl : allinOneService.docurl + lecture.content"
                      type="audio/mpeg" />
                  </vm-audio>
                  <vm-default-ui no-settings>
                  </vm-default-ui>
                </vm-player>
              </div>
            </div>
          </div>
          <div class="web-view p-3" *ngIf="isGrade">
            <app-grades [isMobileView]="isMobileView" [isFromInstructor]="isFromInstructor" (viewLecture)="viewLectureFromGrade($event)"></app-grades>
          </div>

          <div class="web-view p-3" *ngIf="isCertificate">
            <app-certificate [coursename]="course.coursename" [isFromInstructor]="isFromInstructor" [templateid]="classTemplate?.templateid" ></app-certificate>
          </div>

          <div class="web-view p-3" *ngIf="isFeedback">
            <app-feedback [feedbackGiven]="course.feedbacksubmitted" [templateid]="course.feedbacktemplateid" [isFromInstructor]="isFromInstructor"
              (emit)="course.feedbacksubmitted = true">
            </app-feedback>
          </div>

          <!-- <div class="web-view-about  p-3"
            *ngIf="(lecture && lecture.contenttype != 8 && !(lecture.contenttype == 6 && lecture.quizview )) && !isGrade && !isCertificate && !isFeedback"> -->
          <div class="web-view-about  p-3"
            *ngIf="(lecture && lecture.contenttype != 8 &&  lecture.contenttype != 6 && lecture.contenttype != 7)&& !isGrade && !isCertificate && !isFeedback">
            <div class=" p-3 pt-lg-4 p-sm-0 pt-sm-2 mb-2">
              <div class="container">
                <h3 style="font-weight: 700;">About course</h3>
                <!-- <button (click)="test()" >TEST</button>

                <button (click)="test2()" >TEST 2</button> -->

                <div class="show-more-container" [class.show]="show" *ngIf="course.description">
                  <p class="fs-6 my-2" [innerHTML]="getSafeHtml(course.description)">
                  </p>
                </div>

                <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;"
                  (click)="show = !show" *ngIf="course.description">
                  <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                      aria-hidden="true"></i></span>
                  <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up" aria-hidden="true"></i></span>
                </button>
              </div>

            </div>

            <section class=" p-3  p-sm-0 mb-2">
              <div class="container">
                <h3 style="font-weight: 700;">Instructor</h3>


                <div class="instructor my-2">
                  <div class="instructor-default-img" *ngIf="course.profileimage == ''">
                    {{course.instructorname.charAt(0)}}
                  </div>
                  <img width="40" height="40" class="rounded-circle" *ngIf="course.profileimage != ''"
                    [src]="course.profileimageurl" alt="">
                  <div class="d-flex flex-column justify-content-center">
                    <span class="instructor-name fw-bold mx-2">{{course.instructorname}}</span>
                    <span class="institute mx-2" *ngIf="course.institute">{{course.institute}}</span>
                  </div>
                </div>
              </div>

            </section>
          </div>

        </div>


        <footer class="small-view" *ngIf="(tab_active == 3 || tab_active == 1) && lecture.unitid">
          <nav class="navbar fixed-bottom navbar-light bg-light ">
            <!-- <div class="container-fluid d-flex"> -->
            <button href='javascript:;' (click)='previousLecture();' class="btn next-prev prev" [disabled]="isFirst">
              <i class="fa fa-chevron-left left-right"> </i>
              <span style="color: white;" class="mx-2">Previous</span>
            </button>




            <button href='javascript:;' (click)='nextLecture();' class="btn next-prev next" [disabled]="isLast">
              <span style="color: white;" class="mx-2">Next</span>
              <i class="fa fa-chevron-right left-right"> </i>
            </button>
            <!-- </div> -->
          </nav>
        </footer>
      </div>
    </div>
    <div *ngIf="pageview == '2'" style="height: calc(100vh - 100px); overflow: auto;">
      <app-course-learn-pageview [course]="course"></app-course-learn-pageview>
    </div>
  </div>


  <div class="check-error" *ngIf="isError && !isLoading">
    <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
    <button type="button" (click)="reload()" class="btn btn-outline-secondary">
      Reload
    </button>
  </div>
</div>
