import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { MatDialog } from '@angular/material/dialog';
import { AddmembertypeComponent } from '../addmembertype/addmembertype.component';

@Component({
  selector: 'app-membertype',
  templateUrl: './membertype.component.html',
  styleUrls: ['./membertype.component.scss']
})
export class MembertypeComponent implements OnInit {

 
  displayedColumns: any;

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  gettingCategory: boolean = false;
  memberlist: any[] = [];
  showmember !: MatTableDataSource<any>;
  edit: string = "editchannel";
  paginate: boolean = false;
  searchText: string = "";
  role: string = "";
  isFocus: boolean = false;
  datacount: string = "0";
  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public router: Router,
    public allinoneService: AllInOneService,
    private _liveAnnouncer: LiveAnnouncer,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.role = data.role;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
    console.log(this.role);
  }

  ngOnInit(): void {
    this.displayedColumns = ['name', 'description', 'action'];
    this.getChannels();
    // this.getMember();
  }
  arr:any = [];
  max:any ;
  getMember() {
    this.memberlist = [];
    this.gettingCategory = true;
    this.showmember = new MatTableDataSource();
    // var fun = this.role == 'admin' ? this.courseService.channelGet(): this.courseService.getMychannel({type: '1'}) 
    const data = {
      channelid: this.selectedChannel,
    }
    this.courseService.getMembertype(data).subscribe(
      (category_data: any) => {
        if (category_data.returncode == '300') {
          const dataSource = new MatTableDataSource(category_data.datalist);
          this.memberlist = category_data.datalist;
          this.datacount = category_data.datalist.length;
          for (let i = 0; i < this.memberlist.length; i++) {
            this.arr.push(Number(this.memberlist[i]['sortkey']));
          }
          console.log(this.arr);
          this.max = this.arr.reduce((a: number, b: number) => Math.max(a, b));


          
          if (this.memberlist == undefined || this.memberlist.length == 0) {
            this.paginate = false;
          } else {
            this.paginate = true;
          }
          this.showmember = new MatTableDataSource(category_data.datalist);
          this.showmember.sort = this.sort;
          this.showmember.paginator = this.paginator;
          this.gettingCategory = false;
        } else {
          this.gettingCategory = false;
          if (category_data.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.gettingCategory = false;
            this.messageService.openSnackBar(category_data.status || category_data.message, 'warn');
          }
        }
      },
      (err: any) => {
        this.gettingCategory = false;
      }
    );
  }

  editMember(data: any) {
    const dialog = this.dialog.open(AddmembertypeComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        "channelid": this.selectedChannel,
        "name": data.name,
        "description": data.description,
        "edit": true,
        "memberid": data.id,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        //Editing
        this.getMember();
      }
    });
    
  }

  gotoMember(channelid: any) {
    this.router.navigateByUrl(`/${this.role}/category/${this.edit}/${channelid}/member`);
  }

  announceSortChange(sortState: Sort, data: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    console.log(data)
  }

  clear() {
    this.searchText = "";
    this.showmember.filter = "";
  }
  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showmember.filter = searchItem.trim().toLowerCase();

  }
  refresh() {
    this.gettingCategory = true;
    this.memberlist = [];
    if (this.memberlist == undefined || this.memberlist.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showmember = new MatTableDataSource();
    this.getMember();

  }

  gettingChannels: boolean = false;
  channels: any = [];
  selectedChannel: string = '';

  getChannels() {
    this.gettingChannels = true;
    if (this.role == 'admin') {
      // this.channelname = 'KHub';
      this.courseService.channelGet().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist.filter(
              (channel: any) => channel.status == '1'
            );
            var schannel = this.channels.find(
              (x: any) => x.channelid == this.allinoneService.selectedChannelid
            );
            this.selectedChannel = schannel
              ? schannel.channelid
              : this.channels[0].channelid;
            this.getMember();
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingChannels = false;
        },
        (err) => {
          this.gettingChannels = false;
        }
      );
    } else if (this.role == 'chladmin') {
      this.channels = this.allinoneService.adminChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.selectedChannel = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
      this.getMember();
    } else {
      this.channels = this.allinoneService.supervisorChannels;
      var schannel = this.channels.find(
        (x: any) => x.channelid == this.allinoneService.selectedChannelid
      );
      this.selectedChannel = schannel
        ? schannel.channelid
        : this.channels[0].channelid;
      this.gettingChannels = false;
      this.getMember();
    }
  }
  channelChange() {
    this.getMember();
  }

  newmember() {

    const dialog = this.dialog.open(AddmembertypeComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      closeOnNavigation: true,
      autoFocus: false,
      // scrollStrategy: new NoopScrollStrategy(),
      data: {
        "channelid": this.selectedChannel,
        "name": "",
        "edit": false,
        "description":"",
        "memberid": "",
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        //Editing
        this.getMember();

      }
    });

  }
}
