import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-feedback-textarea-data',
  templateUrl: './feedback-textarea-data.component.html',
  styleUrls: ['./feedback-textarea-data.component.scss']
})
export class FeedbackTextareaDataComponent implements OnInit {

  question: any;

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private dialogRef: MatDialogRef<FeedbackTextareaDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.question = data.question;
  }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close();
  }
}
