<h2>
  Subcategory
</h2>

<form>

  <div class="form-group" class="mb-3">
    <label for="status" class="mb-2">
      Category
    </label>
    <select class="form-select" name="status" required [(ngModel)]="category.categoryid" [disabled]="gettingCategory  || isEditing">
      <option *ngFor="let cat of categories" [value]="cat.categoryid">
        {{cat.categoryname}}
      </option>
    </select>
    <mat-progress-bar mode="indeterminate" *ngIf="gettingCategory"></mat-progress-bar>
  </div>

  <div class="form-group" class="mb-3">
    <label for="name" class="mb-2">
      Subcategory
    </label>
    <input type="text" class="form-control" placeholder="Personal Development" id="name" name="name"
      [(ngModel)]="category.subcatname" #name="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" required>
    <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && formSubmitted">
      <div *ngIf="name.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="form-group" class="mb-3">
    <label for="order" class="mb-2">
      Order
    </label>
    <input type="number" class="form-control" id="order" name="order" value="0" [(ngModel)]="category.order"
      #order="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && order.invalid }" required>
    <div class="invalid-feedback" *ngIf="order.invalid || (order.dirty || order.touched) && formSubmitted">
      <div *ngIf="order.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class="actions-button">
    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="onDismiss()" [disabled]="isLoading">
      Cancel
    </button>

    <app-button-loader [button_text]="'Save'" [isStillupload]="isLoading" (click)="submitSubcat()">
    </app-button-loader>

  </div>
</form>