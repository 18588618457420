import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Location } from '@angular/common';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { async } from 'rxjs';




@Component({
  selector: 'app-course-preview',
  templateUrl: './course-preview.component.html',
  styleUrls: ['./course-preview.component.scss']
})
export class CoursePreviewComponent implements OnInit {


  enrollSuccess: boolean = false;
  show: boolean = false;
  isStillUpload: boolean = false;
  isError: boolean = false;
  isLoading: boolean = true;
  courseid: string = "";
  course: any;
  instructor: any;
  isPreview: boolean = false;
  courseBy: boolean = false;
  coursebyText: string = "";
  category: string = "";
  bra: boolean = false;
  def_img = "../../../assets/images/khub.png";
  // anonymous: boolean = false;

  constructor(private courseService: CourseService, private route: ActivatedRoute,
    private location: Location,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    // private _seoService: SEOService,
    private router: Router) {
    // const courseid = this.route.snapshot.queryParamMap.get('courseid');
    // console.log(courseid);



    // if (pre) {
    //   this.isPreview = true;
    //   if (pre != "instructor") {
    //     this.isError = true;
    //   }
    // }
    // else {
    //   this.isPreview = false;
    // }

    this.route.params.subscribe(params => {
      this.courseid = params['courseid'];
    })
  }

  async ngOnInit(): Promise<any> {
    this.isLoading = true;

    const awaitCourse = await this.getCourse();
    if (awaitCourse == "success") {
      this.getInstructor();
    }
    else {
      this.isLoading = false;
    }

  }



  getCourse() {
    console.log(this.courseid+"?????>>>>");
    return new Promise(resolve => {
      this.courseService.getCourseDetail(this.courseid).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.course = res.data;
          this.course.studentcounts= parseInt(this.course.studentcounts);
         

          resolve('success');
        }
        else {
          this.isError = true;
          if (res.returncode == "210") {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
          else {
            this.messageService.openSnackBar(res.status, 'warn');
            if (res.statuscode == "200") {
              setTimeout(() => {
                this.router.navigateByUrl('/home');
              }, 1000);
            }
          }

          setTimeout(() => {
            if (res.returncode == "404") {
              this.location.back();
            }
          }, 3000);
          resolve('error');
        }

      }, err => {
        this.isError = true;

        resolve('error');
      });
    });
  }



  goBackPreview() {
      this.router.navigateByUrl(`instructor/courses/${this.courseid}/info`)
  }



  reload() {
    window.location.reload();
  }

  gotoName() {

  }

  getInstructor() {
    this.courseService.getInstructor(this.course.instructorid).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.instructor = res;
        this.isLoading = false;
        // this.anonymous = true;
      }
      else {
        this.messageService.openSnackBar(res.status, 'warn');
        setTimeout(() => {
          if (res.returncode == "404") {
            this.location.back();
          }
        }, 3000);
      }
    }, err => {
      this.isLoading = false;
    });
  }


  enrollCourse(): void {

  
  }


 
  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = 'https://bucketelearning.s3.ap-southeast-1.amazonaws.com/essential/defaultimage.jpg';
    source.src = imgSrc;
  }


  back() {
    this.location.back()
  }
 

}
