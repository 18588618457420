import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-user-analytics',
  templateUrl: './user-analytics.component.html',
  styleUrls: ['./user-analytics.component.scss']
})
export class UserAnalyticsComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any> | any;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('paginator') paginator !: MatPaginator;

  courses: any[] = [];
  channels: any[] = [];
  showcourses !: MatTableDataSource<any>;
  displayedColumns: any;

  isFocus: boolean = false;
  isLoading: boolean = false;
  paginate: boolean = false;
  gettingmyChannel: boolean = false;
  isAdmin: boolean = false;

  datacount: string = "0";
  searchText: string = "";
  filter: string = "";
  selectedChannel: any = "all"
  channelid: any = "";
  channelname: any = "";
  totaltime: any = "0 hr(s) 0 min(s)";

  constructor(
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  goToMyChannels(){

  }
  goToChannelAnalytics(){

  }

  announceSortChange(sort: Sort) {
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'coursename':
          return this.allinoneService.compare(a.coursename, b.coursename, isAsc);
        case 'timespent':
          return this.allinoneService.compare(a.totaltime, b.totaltime, isAsc);
        default:
          return 0;
      }
    });
    this.showcourses = new MatTableDataSource(this.courses);
    this.showcourses.paginator = this.paginator;
  }

  clear() {
    this.searchText = "";
    this.showcourses.filter = "";
  }



  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showcourses.filter = searchItem.trim().toLowerCase();
    let dataSource = new MatTableDataSource(this.courses);
  }

  

  refresh() {
    this.clear();
    this.totaltime = "0 hr(s) 0 min(s)";
    this.isLoading = true;
    this.courses = [];
    if (this.courses == undefined || this.courses.length == 0) {
      this.paginate = false;
    } else {
      this.paginate = true;
    }
    this.showcourses = new MatTableDataSource();
    // this.getCourse();
  }

  onChange(data: any) {
    const searchItem = data.target.value;
    console.log(searchItem);
    if (data.target.value == "all") {
      // this.getCourse();
    } else {
      this.showcourses.filter = searchItem.trim().toLowerCase();
    }

  }

}
