<app-top-bar [isAdmin]="isAdmin" [user]="user" *ngIf="!allinOneService.hideNavbar && !hideTopbar && !showLoading && (( authenticate && allinOneService.isApproved) || (!authenticate))" [isLoading]="isLoading"></app-top-bar>

<!-- <div class="main-content">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div> -->

<ng-container *ngIf="!showLoading; else elseTemplate;">


    <!-- <ng-container *ngIf="authenticate; else loginTemplate;"> -->
        <ng-container *ngIf="(authenticate && allinOneService.isApproved) || !authenticate; else notApprovedTemplate;">
            <div class="main-content">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
        </ng-container>

        <ng-template #notApprovedTemplate>
            <app-unauthorized></app-unauthorized>
        </ng-template>
    <!-- </ng-container> -->

    <!-- <ng-template #loginTemplate>
        <div class="main-content">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </ng-template> -->

</ng-container>

<!-- Loading Template -->
<ng-template #elseTemplate>
    <div class="loading-container">
        <span class="loader"></span>
    </div>

</ng-template>