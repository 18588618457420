import { Component, Inject, OnInit } from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-assignments-edit',
  templateUrl: './assignments-edit.component.html',
  styleUrls: ['./assignments-edit.component.scss']
})
export class AssignmentsEditComponent implements OnInit {

  assignmentname : string = '';
  courseid : string = '';
  unitid : string = '';

  formSubmitted : boolean = false;
  isLoading : boolean = false;

  submitForm : any = {
    unitname : '',
    deadlinedate : '',
    deadlinetime : ''
  }

  constructor(
    private dialogRef: MatDialogRef<AssignmentsEditComponent>,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.assignmentname = data.assignmentname
    this.courseid = data.courseid
    this.unitid = data.unitid
    if(data.deadlinedate) {
      this.submitForm.deadlinedate = this.allinoneService.formatDBToShowInEdit(data.deadlinedate)
    }

    if(data.deadlinetime) {
      this.submitForm.deadlinetime = this.allinoneService.f12to24(data.deadlinetime)
    }

  }

  ngOnInit(): void {
  }

  sumbit() {
    this.isLoading = true;
    var data = {
      courseid : this.courseid,
      unitid : this.unitid,
      deadlinedate : this.submitForm.deadlinedate ? this.allinoneService.formatDate(this.submitForm.deadlinedate) : '',
      deadlinetime : this.submitForm.deadlinetime ? this.allinoneService.f24to12(this.submitForm.deadlinetime) : '',
      id : ''
    }
    // console.log(data);
    // return;
    this.courseService.updateAssignmentDeadline(data).subscribe(
      (res:any) => {
        console.log(res);
        if(res.returncode == 300) {
          this.dialogRef.close({ 
            deadlinedate : data.deadlinedate,
            deadlinetime : data.deadlinetime
           });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err:any) => {
        this.isLoading = false;
      }
    )
  }

  deadlineclear() {
    this.submitForm.deadlinedate = '';
    this.submitForm.deadlinetime = '';
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

}
