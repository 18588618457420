import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.scss'],
})
export class AddmemberComponent implements OnInit {
  constructor(
    public allinoneService: AllInOneService,
    private router: Router,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.route.data.subscribe((data) => {
      this.adminrole = data.role;
    });
    if ((this.adminrole == 'admin' && !this.allinoneService.isRootAdmin()) || (this.adminrole == 'chladmin' && !this.allinoneService.isChlAdmin())) {
      this.router.navigateByUrl('')
    }
    this.getAllMember();
  }

  user_id: string = '';
  isadding: boolean = false;
  // role: string = '1';
  role: string = '';
  status: string = '001'; // 001 = acitve, 002 = deactivate
  membertype : string = '';
  adminrole: string = '';
  remark: string = '';
  channelname: string = '';
  getChannelName: boolean = false;
  chl_text: string = '';
  edit: string = '';
  edit_stat: boolean = false;

  memberlist: any[] = [];
  gettingmembertype :boolean = false;

  cat = ['instructor', 'role'];
  role_list: any[] = [
    {
      id: '1',
      name: 'Instructor',
    },
    {
      id: '2',
      name: 'Supervisor',
    },
    {
      id: '100',
      name: 'Admin',
    },
    {
      id: '3',
      name: 'HR Admin',
    },
  ];

  ngOnInit(): void {
    // this.role_list = [
    //   'Student', 'Instructor'
    // ]
    this.getMychannel();
    this.getMember();
  }

  getMychannel() {
    const edit_status = this.route.snapshot.params['edit'];
    if (edit_status == 'editchannel') {
      this.edit_stat = false;
      this.edit = edit_status;
      this.chl_text = 'Channels';
    } else if (edit_status == 'editmychannel') {
      this.edit_stat = true;
      this.edit = edit_status;
      this.chl_text = 'My Channels';
    }
  }

  getMember() {
    this.memberlist = [];
    const channelid = this.getChannelID();
    this.gettingmembertype = true;
    // var fun = this.role == 'admin' ? this.courseService.channelGet(): this.courseService.getMychannel({type: '1'}) 
    const data = {
      // channelid: this.selectedChannel,
      channelid: channelid.toString(),
    }
    this.courseService.getMembertype(data).subscribe(
      (category_data: any) => {
        if (category_data.returncode == '300') {
          this.memberlist = category_data.datalist;
        } else {
          this.messageService.openSnackBar(category_data.status || category_data.message, 'warn');
        }
        this.gettingmembertype = false;
      },
      (err: any) => {
        this.messageService.openSnackBar('Server error.', 'warn');
        this.gettingmembertype = false;
      }
    );
  }

  onChange(event: any) {
    this.role = event.target.value;
  }

  statusChange(event: any) {
    this.status = event.target.value;
  }

  memberChange(event : any) {
    this.membertype = event.target.value;
  }

  getChannelID() {
    const channelid = this.route.snapshot.params['channelid'];
    return channelid;
  }
  addMember() {
    if(this.membertype == '' || this.membertype == null) {
      return this.messageService.openSnackBar("Member Type cannot be blank.", "warn")
    }
    this.isadding = true;
    const channelid = this.getChannelID();

    const data = {
      adduserid: this.allinoneService.checkUserId(this.user_id.trim()),
      role: this.role,
      status: this.status.trim(),
      remark: this.remark.trim(),
      channelid: channelid.toString(),
      membertype : this.membertype
    };
    console.log("add member data >>", data)

    this.courseService.addmember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          const channelid = this.getChannelID();
          this.isadding = false;
          this.router.navigateByUrl(
            `/admin/channel/editchannel/${channelid}/member`
          );
        } else {
          this.isadding = false;
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.isadding = false;
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
      },
      (err) => {
        this.isadding = false;
      }
    );
  }

  getAllMember() {
    this.getChannelName = true;

    const channelid = this.getChannelID();
    const data = {
      channelid: channelid.toString(),
    };

    this.courseService.getMember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getChannelName = false;
          this.channelname = res.name;
        } else {
          this.getChannelName = false;
          if (res.returncode == '210') {
            this.getChannelName = false;
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.getChannelName = false;
            this.messageService.openSnackBar(res.status, 'warn');
          }
        }
      },
      (err) => {
        this.getChannelName = false;
      }
    );
  }

  goToChannels() {
    this.router.navigateByUrl(`/${this.adminrole}/channel`);
    // if (this.edit_stat) {
    //   this.router.navigateByUrl('/admin/mychannel');
    // } else {
    //   this.router.navigateByUrl('/admin/channel');
    // }
  }

  goToMembers() {
    const channelId = this.route.snapshot.params['channelid'];
    this.router.navigateByUrl(
      `${this.adminrole}/channel/${this.edit}/${channelId}/member`
    );
  }

  gotoChannelName() {
    const channelId = this.getChannelID();
    this.router.navigateByUrl(
      `${this.adminrole}/channel/${this.edit}/${channelId}`
    );
  }
}
