import { async } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from './shared/services/all-in-one.service';
import { CourseService } from './shared/services/course.service';
import { MessageService } from 'projects/e-learning/src/app/shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
import { resolve } from 'dns';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'E-Learning Instructor';
  hideTopbar: boolean = false;
  authenticate: boolean = false;
  isLoading: boolean = false;
  isAdmin: boolean = false;
  user: any;
  showLoading: boolean = false;

  constructor(
    private router: Router,
    private courseService: CourseService,
    public allinOneService: AllInOneService,
    private messageService: MessageService,
    private cookieService: CookieService
  ) {
    this.isLoading = true;
    this.showLoading = true;
  }
  async ngOnInit(): Promise<void> {
    let queryParams = new URLSearchParams(window.location.search);
    const appid = 'elearningstudio';
    let atoken = queryParams.get('atoken') || '';
    let userid = queryParams.get('userid') || '';
    userid = this.checkUserId(userid);
    if (atoken != '') {
      let data = {
        userid: this.checkUserId(userid),
        appid: appid,
        atoken: atoken,
      };
      this.courseService.exchangeToken(data).subscribe(async (res: any) => {
        if (res.returncode == '300') {
          // Success
          this.allinOneService.setCookie('atoken', res.atoken);

          this.allinOneService.setCookie('userid', res.usersyskey);

          this.allinOneService.setCookie('atoken', res.atoken);
          this.allinOneService.setCookie('kunyek_userid', userid);

          sessionStorage.setItem('session', res.session);
          sessionStorage.setItem('existsuser', res.type);
          sessionStorage.setItem('kunyekusername', res.username);

          const user = await this.getUser();
          const policy_menus = await this.getPolicy();
          const check = await this.checkDomain();
          this.checkUser();
          this.isLoading = false;
          this.showLoading = false;
          this.router.navigateByUrl('');
        } else {
          this.allinOneService.unauthorizedMsg = res.message;
          this.allinOneService.isApproved = false;
          this.isLoading = false;
          this.showLoading = false;
          this.allinOneService.logout();
        }
      });
    } else {
      atoken = this.allinOneService.getAtoken();
      if (atoken) {
        const awaitCheckToken = await this.checkTokenFailedHttpRequest();
        // const awaitCheckToken = await this.checkTokenFailedHttpRequest();
        if (awaitCheckToken != 'error') {
          const user = await this.getUser();
          const policy_menus = await this.getPolicy();
          const check = await this.checkDomain();
          this.checkUser();
          this.authenticate = true;
        } else {
          this.showLoading = false;
          this.allinOneService.logout();
        }
      } else {
        this.showLoading = false;
        this.authenticate = false;
        this.isLoading = false;
      }
    }
  }

  checkTokenFailedHttpRequest() {
    return new Promise((resolve) => {
      this.courseService.checkToken().subscribe(
        async (res: any) => {
          if (res.returncode == '0') {
            resolve('checktoken');
          } else {
            this.courseService.renewToken().subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.allinOneService.setCookie('atoken', res.atoken);
                  resolve('checktoken');
                } else {
                  resolve('error');
                }
              },
              (err) => {
                resolve('error');
              }
            );
          }
        },
        (err) => {
          resolve('error');
        }
      );
    });
  }
  checkUserId(userid: string) {
    const checkmail = this.validateMail(userid);
    if (!checkmail && !userid.includes('@')) {
      if (userid.startsWith('09')) {
        userid = '+959' + userid.slice(2, userid.length);
      } else if (userid.startsWith('+959')) {
        userid = userid;
      } else if (userid.startsWith('959')) {
        userid = '+959' + userid.slice(3, userid.length);
      } else {
        userid = '+959' + userid;
      }
    } else {
      userid = userid.toLowerCase();
    }

    return userid;
  }

  validateMail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  getUser() {
    return new Promise((resolve) => {
      this.courseService.getUser().subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            // let keys = atob(res.wak);
            // [this.allinOneService.acc_key, this.allinOneService.sec_key] =
            //   keys.split(',');
            this.user = res.data;
            this.allinOneService.user = this.user;
            this.isAdmin = this.user.userrole == '100';
            this.allinOneService.setCookie('role', this.user.userrole);
          } else {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
          this.isLoading = false;
          resolve(true);
        },
        (err) => {
          this.isLoading = false;
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinOneService.logout();
          }, 2000);
          resolve(false);
        }
      );
    });
  }

  onActivate(event: any) {
    window.scroll(0, 0);

    // if (
    //   this.router.url.includes('/courses/') &&
    // ) {
    //   this.hideTopbar = true;
    // }
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  checkUser() {
    this.courseService.checkUser().subscribe(async (res: any) => {
      if (res.returncode == '300') {
        // Success
      } else {
        this.allinOneService.unauthorizedMsg = res.message;
        this.allinOneService.isApproved = false;
        this.allinOneService.adminmail = res.adminmail;
      }
      setTimeout(() => {
        this.showLoading = false;
      }, 1000);
    });
  }
  async checkURL() {
    return new Promise(async (resolver) => {});
  }

  getPolicy() {
    return new Promise((resolve) => {
      this.courseService.getPolicy().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinOneService.policy_menus = res.datalist || [];
          } else {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
          resolve(true);
        },
        (err) => {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinOneService.logout();
          }, 2000);
          resolve(false);
        }
      );
    });
  }

  checkDomain() {
    return new Promise((resolve) => {
      this.courseService.checkDomain().subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinOneService.adminChannels = res.admin;
            this.allinOneService.supervisorChannels = res.supervisor;
            this.allinOneService.hradminChannels = res.hradmin;
          } else {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
          resolve(true);
        },
        (err) => {
          setTimeout(() => {
            this.router.navigateByUrl('login');
            this.allinOneService.logout();
          }, 2000);
          resolve(false);
        }
      );
    });
  }
}
