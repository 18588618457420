import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LoadingService } from '../../shared/services/loading.service';
import * as saveAs from 'file-saver';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-import-questions',
  templateUrl: './import-questions.component.html',
  styleUrls: ['./import-questions.component.scss']
})
export class ImportQuestionsComponent implements OnInit {

  @ViewChild('choosefile') choosefile: any;

  saveLoading: boolean = false;

  question: any = "";
  courseid: any = "";
  unitid: any = "";
  saveForm: boolean = false;
  progress: number = 0;
  errorList: any = [];
  checkError: boolean = false;
  isExpand: boolean = false;
  questiontype:any = "001";
  quesitontypes:any = [

    {
      name: 'Multiple Choice Question',
      id: '001',
    },
    {
      name: 'Fill in the blank',
      id: '003',
    },
    {
      name: 'Matching',
      id: '004',
    },
    {
      name: 'DropDown',
      id: '005',
    },
  ]

  mcqSampleTemplate = "<p class=\"p1\"><b>Multiple Choice with Explanation</b></p><p class=\"p1\">&#160;</p><p class=\"p1\">You can use this template as a guide to the simple editor markdown and OLX markup to use for multiple choice with explanation problems. Edit this component to replace this template with your own assessment.</p><p class=\"p1\">&#160;</p><p class=\"p1\">&gt;&gt;Add the question text, or prompt, here. This text is required. &lt;&lt;</p><p class=\"p1\">&#160;</p><p class=\"p1\">( ) an incorrect answer {{ You can specify explanation for this option }}<br>(x) the correct answer {{ You can specify explanation for this option }}<br>( ) an incorrect answer&#160;<span>{{ You can specify explanation for this option }}</span></p><p class=\"p1\">&#160;</p><p class=\"p1\">&gt;&gt;Add the question text, or prompt, here. This text is required.&lt;&lt;</p><p class=\"p1\">&#160;</p><p class=\"p1\">[ ] an incorrect answer {{ You can specify explanation for this option }}<br>[x] the correct answer&#160;<br>[ ] an incorrect answer&#160;</p><p class=\"p1\">[x] the correct answer</p>";
  blankSampleTemplate = "<p class=\"p1\"><b>Fill in the blank</b></p><p class=\"p1\">&#160;</p><p class=\"p1\">You can use this template as a guide to the simple editor markdown and OLX markup to use for fill in the blank problems. Edit this component to replace this template with your own assessment.</p><p class=\"p1\"><br></p><p>&gt;&gt; Add the question text, prompt, here. This text is required.&lt;&lt;</p><p>= the correct answer</p><p>or= optional acceptable variant of the correct answer</p><p class=\"p1\"><span class=\"ui-provider cfh cfi bsy cfj cfk cfl cfm cfn cfo cfp cfq cfr cfs cft cfu cfv cfw cfx cfy cfz cga cgb cgc cgd cge cgf cgg cgh cgi cgj cgk cgl cgm cgn cgo\" dir=\"ltr\"></span></p><p>fixed_sequence= yes</p><p>case_sensitive= no</p>";
  matchingSampleTemplate = "<p class=\"p1\"><b>Matching</b></p><p class=\"p1\">&#160;</p><p class=\"p1\">You can use this template as a guide to the simple editor markdown and OLX markup to use for matching problems. Edit this component to replace this template with your own assessment.</p><p class=\"p1\">&#160;</p><p class=\"p1\">&gt;&gt; First Item from left side of the matching &lt;&lt;</p><p class=\"p1\">(x) the correct answer for the first item<br></p><p class=\"p1\">&#160;</p><p class=\"p1\">&gt;&gt; Second Item from left side of the matching &lt;&lt;<br></p><p class=\"p1\">(x) the correct answer for the second item</p><p class=\"p1\"><br></p><p class=\"p1\">&gt;&gt; Third Item from left side of the matching &lt;&lt;<br></p><p class=\"p1\">(x) the correct answer for the third item</p>";
  dropDownSampleTemplate = "<p class=\"p1\"><b>DropDown</b></p><p class=\"p1\">&#160;</p><p class=\"p1\">You can use this template as a guide to the simple editor markdown and OLX markup to use for dropdown problems. Edit this component to replace this template with your own assessment.</p><p class=\"p1\"><br></p><p>&gt;&gt; Add the question text, prompt, here. This text is required.&lt;&lt;</p><p>options= answer1, answer2, answer3</p><p>answer= answer1</p><p>exp= exp1</p><p>options= answer4, answer5, answer6</p><p>answer= answer5</p><p>exp= exp2</p>";


  config = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '20rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    showToolbar: false,
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'insertUnorderedList',
        'insertOrderedList',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'bold',
        'italic',
        'underline'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  constructor(
    private messageService: MessageService,
    private allinoneService: AllInOneService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private http: HttpClient,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<ImportQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data)
    this.courseid = data.courseid;
    this.unitid = data.unitid;

    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.questionTypeChange();
  }

  questionTypeChange(){
    // if (this.question == ''){
      if (this.questiontype == '001') {
        this.question = this.mcqSampleTemplate;
      }
      else if(this.questiontype == '003'){
        this.question = this.blankSampleTemplate;
      }
      else if(this.questiontype == '004'){
        this.question = this.matchingSampleTemplate;
      }
      else if(this.questiontype == '005'){
        this.question = this.dropDownSampleTemplate;
      }
    // }
    
  }


  // uploadfile() {
  //   if (this.question.toString().trim() == '') {
  //     this.messageService.openSnackBar('Please enter the question.', 'warn');
  //     return;
  //   }

  //   this.saveLoading = true;
  //   var data = {
  //     "importtext": this.question,
  //     "unitid": this.unitid,
  //     "courseid": this.courseid,
  //   }
  //   this.courseService.importquestions(data).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.checkError = false;
  //         this.dialogRef.close(res);
  //         this.messageService.openSnackBar('Successful.', 'success');
  //       } else if (res.returncode == '200') {
  //         this.errorList = res.error_logs;
  //         this.checkError = true;
  //       } else {
  //         this.messageService.openSnackBar(
  //           res.status || res.message || res.error,
  //           'warn'
  //         );
  //         this.saveLoading = false;
  //       }
  //     },
  //     (err: any) => {
  //       this.saveLoading = false;
  //     }
  //   );



  // }

  uploadfile() {
    if (this.question.toString().trim() == '') {
      this.messageService.openSnackBar('Please enter the question.', 'warn');
      return;
    }

    this.saveLoading = true;
    var data = {
      "importtext": this.question,
      "unitid": this.unitid,
      "courseid": this.courseid,
      "type":this.questiontype,
    }
    this.courseService.importquestions(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.checkError = false;
          this.dialogRef.close(res);
          this.messageService.openSnackBar('Successful.', 'success');
        } else if (res.returncode == '200') {
          this.errorList = res.error_logs;
          this.checkError = true;
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || res.error,
            'warn'
          );
          this.saveLoading = false;
        }
      },
      (err: any) => {
        this.saveLoading = false;
      }
    );



  }


  cancel() {
    if (!this.saveLoading) {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        width: '250px',
        closeOnNavigation: true,
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialog.afterClosed().subscribe((dialogResult) => {
        this.loadingService.hide();
        if (dialogResult) {
          this.dialogRef.close();
        }
      });
    }

  }



  downloadSampleFile(): any {
    this.http
      .get(
        this.allinoneService.fileurl + 'import/ImportQuestionsSampleTemplate.txt',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'ImportQuestionsSampleTemplate.txt');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }


  resize() {
    this.isExpand = !this.isExpand;
    if(this.isExpand){
      this.config.height = '80vh';
      this.dialogRef.updateSize('100vw', 'auto');
    }
    else{ 
      this.config.height = '10rem';
      this.dialogRef.updateSize('900px', 'auto');
    }
  }

}
