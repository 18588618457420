<app-breadcrumb [s_breadcrumb]="'Profile'"></app-breadcrumb>

<div class="content container-fluid">
  <div class = "d-flex align-items-center mb-3">
    <div>
      <h2 class = "mb-0">
        <strong>Instructor Profiles</strong>
      </h2>
    </div>
    <div>
      <button mat-flat-button color="primary" class = "px-2" (click)="openProfileDialog()"><i class = "fa fa-plus me-2"></i>New</button>
    </div>
  </div>
  <ng-container *ngIf="!gettingProfiles;else elseTemplate">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-12 col-lg-4 mb-3">
        <mat-card class="d-flex align-items-center instructor-card">      
          <div class = "mainprofile">
            <mat-icon matTooltip="Main Profile">stars</mat-icon>
          </div>    
          <div class = "edit">
          </div>
          <div class = "editicon">
            <button class = "btn btn-outline-secondary" (click)="openProfileDialog(primaryInstructor)"><i class = "fa fa-edit"></i></button>
          </div>
          <div class="img-wrapper">
            <div [ngClass]="{ 'img-profile': primaryInstructor.profileimage == ''}">
              <img class="profileimage" *ngIf="primaryInstructor.profileimage != ''"
                [src]="primaryInstructor.profileimageurl" alt="">
              <span *ngIf="primaryInstructor.profileimage == ''"> {{
                primaryInstructor.name.charAt(0)}}</span>
            </div>
          </div>
          <div class="details d-flex flex-column ps-3">
            <div class="name">{{primaryInstructor.name}}</div>
            <div class="institute">{{primaryInstructor.institute}}</div>
          </div>
        </mat-card>
      </div>
      <div class="col-sm-12 col-md-6 col-12 col-lg-4 mb-3" *ngFor="let profile of profiles">
        <mat-card class="d-flex align-items-center instructor-card">
          <div class = "edit">
          </div>
          <div class = "editicon">
            <!-- <mat-icon>edit</mat-icon> -->
            <button class = "btn btn-outline-secondary" matTooltip="Edit Profile" (click)="openProfileDialog(profile)"><i class = "fa fa-edit"></i></button>
          </div>
          <div class="img-wrapper">
            <div [ngClass]="{ 'img-profile': profile.profileimage == ''}">
              <img class="profileimage" *ngIf="profile.profileimage != ''"
                [src]="profile.profileimageurl" alt="">
              <span *ngIf="profile.profileimage == ''"> {{
                profile.name.charAt(0)}}</span>
            </div>
          </div>
          <div class="details d-flex flex-column ps-3">
            <div class="name">{{profile.name}}</div>
            <div class="institute">{{profile.institute}}</div>
          </div>
        </mat-card>
      </div>
      <!-- <div class="col-sm-12 col-md-6 col-12 col-lg-4 mb-3">
        <mat-card class="d-flex align-items-center instructor-new-card" matTooltip="Create new profile">
          <mat-icon>add</mat-icon>
        </mat-card>
      </div> -->
    </div>
  </ng-container>

  <!-- Loading Skeleton -->
  <ng-template #elseTemplate>
    <div class="row">
      <div *ngFor="let x of [1,2,3,4]" class="col-sm-12 col-md-6 col-12 col-lg-4 mb-3">
        <mat-card class="d-flex align-items-center instructor-card">
          <div>
            <ngx-skeleton-loader style="width: auto;" appearance="circle" [theme]="{ height: '60px',width: '60px'}">
            </ngx-skeleton-loader>
          </div>
          <div class="details d-flex flex-column ps-3">
            <ngx-skeleton-loader [theme]="{ height: '10px',width: '150px'}" style="margin-bottom: -5px">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '10px',width: '60px'}" style="margin-bottom: -5px">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '10px',width: '70px'}" style="margin-bottom: -10px">
            </ngx-skeleton-loader>
          </div>
        </mat-card>
      </div>
    </div>
  </ng-template>
</div>