import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-custom-schedule-setup',
  templateUrl: './custom-schedule-setup.component.html',
  styleUrls: ['./custom-schedule-setup.component.scss'],
})
export class CustomScheduleSetupComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  tdyDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd')?.toString();
  allCourses: any[] = [];

  course: any = {
    scheduleid: '',
    name: '',
    description: '',
    status: '002',
    startdate: this.tdyDate,
    enddate: this.tdyDate,
    file: '',
    previewurl: '',
    filename: '',
    courseid: '',
    courseimageurl: '',
  };

  statusList = [
    {
      name: 'Ongoing',
      id: '002',
    },
    {
      name: 'Upcoming',
      id: '003',
    },
  ];

  channelid: string = '';
  imageChangedEvent: any = '';
  croppedImage: boolean = false;

  isEditing: boolean = false;
  isLoading: boolean = false;
  formSubmitted: boolean = false;
  gettingAllCourses: boolean = false;
  isCourseSelected: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private courseService: CourseService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<CustomScheduleSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isEditing = data.isEditing;
    this.channelid = data.channelid;
    this.allCourses = data.allCourses;
    if (this.isEditing) {
      this.course = {
        scheduleid: data.course.scheduleid,
        name: data.course.name,
        description: data.course.description,
        status: data.course.status,
        startdate: data.course.startdate,
        enddate: data.course.enddate,
        file: '',
        previewurl: data.course.imageurl,
        filename: data.course.image,
        courseid: data.course.courseid,
        courseimageurl: data.course.courseimage,
      };
      if (this.course.courseid) {
        this.isCourseSelected = true;
      }
      this.croppedImage = true;
    }
    if (this.allCourses.length == 0) {
      this.getAllCourses();
    }
  }

  ngOnInit(): void {
    if (this.isEditing) {
      this.course.startdate = this.course.startdate
        ? this.datePipe
            .transform(
              this.allinoneService.formatDateForDiscussionDatePipe(
                this.course.startdate
              ),
              'yyyy-MM-dd'
            )
            ?.toString()
        : Date.now();
      this.course.enddate = this.course.enddate
        ? this.datePipe
            .transform(
              this.allinoneService.formatDateForDiscussionDatePipe(
                this.course.enddate
              ),
              'yyyy-MM-dd'
            )
            ?.toString()
        : Date.now();
    }
    console.log(this.course);
  }

  dismiss() {
    if (!this.isLoading) {
      this.dialogRef.close();
    }
  }

  onFileSelect(event: any) {
    this.croppedImage = false;
    let file = event.target.files[0];
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (file) {
      if (filetypelist.indexOf(file.type) > -1) {
        this.imageChangedEvent = event;
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.course.file = file;
          this.course.previewurl = e.target.result;
          this.course.filename = this.allinoneService.getFileName(file);
        };
        reader.readAsDataURL(file);
      } else {
        this.messageService.openSnackBar(
          "File type '" + file.type + "' is not supported!",
          'warn'
        );
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.course.file = this.allinoneService.base64ToFile(
      event.base64,
      this.course.filename
    );
    this.course.previewurl = event.base64;
  }

  copyImage() {
    this.croppedImage = true;
    this.hiddenfileinput.nativeElement.value = '';
  }

  imageLoaded() {
    // show cropper
    console.log('image loaded.');
  }

  loadImageFailed() {
    console.log('image load fail.');
  }

  cropperReady() {}

  async submit() {
    this.formSubmitted = true;
    if (
      this.course.name == '' ||
      this.course.startdate == '' ||
      this.course.enddate == '' ||
      this.course.status == ''
    ) {
      return;
    }
    if (this.course.startdate > this.course.enddate) {
      return this.messageService.openSnackBar(
        'Please select a valid end date.',
        'warn'
      );
    }
    if (this.course.previewurl && !this.croppedImage) {
      return this.messageService.openSnackBar('Please crop the image.', 'warn');
    }

    this.isLoading = true;
    this.dialogRef.disableClose = true;

    if (this.course.file) {
      this.course.file = await this.allinoneService.compressImage(
        this.course.file,
        0.5
      );


      const fileUpload = await this.allinoneService.fileUploadToS3WithPath(
        this.course.file,
        this.course.filename,
        'schedule'
      );
      if (!fileUpload) {
        this.formSubmitted = false;
        this.isLoading = false;
        this.dialogRef.disableClose = false;
        return this.messageService.openSnackBar(
          'Something went wrong while uploading image.',
          'warn'
        );
      }
    }

    const startdate = this.datePipe.transform(
      this.course.startdate,
      'yyyyMMdd'
    );
    const enddate = this.datePipe.transform(this.course.enddate, 'yyyyMMdd');
    const data = {
      scheduleid: this.course.scheduleid,
      image: this.course.filename,
      name: this.course.name,
      description: this.course.description,
      startdate: startdate,
      enddate: enddate,
      status: this.course.status,
      channelid: this.channelid,
      courseid: this.course.courseid,
    };

    var fun = this.isEditing
      ? this.courseService.updateCustomSchedule(data)
      : this.courseService.addCustomSchedule(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isLoading = false;
          this.formSubmitted = false;
          this.dialogRef.disableClose = false;
          var schedule = {
            scheduleid: res.scheduleid,
            name: this.course.name,
            image: this.course.filename,
            imageurl: this.course.filename ? res.imageurl : '',
            description: this.course.description,
            startdate: startdate,
            enddate: enddate,
            status: this.course.status,
            courseid: this.course.courseid,
            courseimage: this.course.courseimageurl,
          };
          this.dialogRef.close(schedule);
        } else {
          this.isLoading = false;
          this.formSubmitted = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.isLoading = false;
        this.formSubmitted = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  getAllCourses() {
    this.gettingAllCourses = true;
    this.courseService.getCoursesByChannel(this.channelid, '').subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.allCourses = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingAllCourses = false;
      },
      (err) => {
        this.gettingAllCourses = false;
      }
    );
  }

  selectCourse(course: any) {
    this.isCourseSelected = true;
    this.course.name = course.coursename;
    this.course.courseid = course.courseid;
    if (this.course.courseimage != '') {
      this.course.courseimageurl =
        this.allinoneService.imgurl + course.courseimage;
    }
    this.course.file = '';
    this.course.filename = '';
    this.course.previewurl = '';
  }

  showList() {
    this.autocomplete.openPanel();
  }

  changeName() {
    this.isCourseSelected = false;
    this.course.courseimageurl = '';
    this.course.courseid = '';
  }

  removeImage() {
    this.course.file = '';
    this.course.filename = '';
    this.course.previewurl = '';
    this.croppedImage = false;
    this.hiddenfileinput.nativeElement.value = '';
    this.imageChangedEvent = '';
  }
}
