<app-breadcrumb [t_breadcrumb]="'New Channel'" [s_breadcrumb]="'Channels'" (emit)="goToChannels()"></app-breadcrumb>

<div class="container mt-3 mb-3">

  <!-- <form #f="ngForm" (ngSubmit)="f.form.valid && createChannel()" name="form" novalidate> -->
  <div>
    <div>
      <strong>Logo</strong>
    </div>
    <div style="position: relative;">
      <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
        <img class="drag-image me-3" [defaultImage]="'../../../assets/images/uploadphoto.png'"
          [lazyLoad]="imgURL ? imgURL : '../../../assets/images/uploadphoto.png'">
        <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
      </div>
      <button mat-icon-button class="remove-drag-image" *ngIf="imgURL" (click)="removeImage()">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </div>
  </div>

  <div class="form-group  mb-3">
    <label for="chl_name" class="form-label"><strong>Name</strong></label>
    <input type="text" [(ngModel)]="name" name="chl_name" class="form-control" id="chl_name" required #chlname="ngModel"
      autocomplete="off" [ngClass]="{ 'is-invalid': formSubmitted && chlname.invalid }" required>

    <div class="invalid-feedback" *ngIf="chlname.invalid || (chlname.dirty || chlname.touched) && formSubmitted">
      <div *ngIf="chlname.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>

  <div class=" mb-3">
    <label for="chl_desc" class="form-label"><strong>Description</strong></label>
    <textarea type="text" class="form-control" [(ngModel)]="chl_desc" name="chl_desc" id="chl_desc"
      style="height: 100px" #chldesc="ngModel" autocomplete="off"></textarea>
  </div>

  <!-- <div>
      <label class="form-label"><strong>Configurations</strong></label>
    </div> -->

  <div class="mt-3">
    <label class="form-label"><strong>Channel Type</strong></label>
  </div>

  <div class="d-flex align-items-center mb-3">
    <div class="form-check me-5">
      <input class="form-check-input" type="radio" name="channeltype" id="public" [(ngModel)]="channeltype" value="001">
      <label class="form-check-label" for="public">
        Public
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="channeltype" id="private" [(ngModel)]="channeltype"
        value="002">
      <label class="form-check-label" for="private">
        Private
      </label>
    </div>
  </div>

  <!-- <div class="form-group mb-3" *ngIf="channeltype == '002'">-->
  <div class="form-group mb-3" *ngIf="channeltype == '002'">
    <label for="orgid" class="form-label"><strong>Organization</strong></label>
    <select class="form-select" [(ngModel)]="orgid" [disabled]="gettingOrgs" name="orgid" id="orgid" #org_id="ngModel"
      required>

      <option value="">-</option>
      <option *ngFor="let org of orgs" value="{{org.orgid}}">
        {{org.name}}</option>
    </select>
    <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgs"></mat-progress-bar>

    <!-- <div class="invalid-feedback" *ngIf="org_id.invalid || (org_id.dirty || org_id.touched) && formSubmitted">
      <div *ngIf="org_id.errors?.required && formSubmitted">
        {{allinoneService.input_err_msg}}
      </div>
    </div> -->
  </div>

  <div class="mt-3">
    <label class="form-label"><strong>Settings</strong></label>
  </div>

  <!-- *ngIf="channeltype == '001'" -->
  <div class="container">
    <div class="row" style="width: 80%;">
      <div class="col-md-6 form-check mb-3">
        <input class="form-check-input" type="checkbox" id="publichide" [(ngModel)]="publichide">
        <label class="form-check-label" for="publichide">
          Make only accessable from domain
        </label>
      </div>

      <div class="col-md-6 form-check mb-3">
        <input class="form-check-input" type="checkbox" id="sharecertificate" [(ngModel)]="sharecertificate">
        <label class="form-check-label" for="sharecertificate">
          Show Share Certificate Button
        </label>
      </div>

      <div class="col-md-6 form-check mb-3">
        <input class="form-check-input" type="checkbox" id="allowselfsignup" [(ngModel)]="allowselfsignup">
        <label class="form-check-label" for="allowselfsignup">
          Allow Self Sign Up
        </label>
      </div>

      <div class="col-md-6 form-check mb-3">
        <input class="form-check-input" type="checkbox" id="showstudiobutton" [(ngModel)]="showstudiobutton">
        <label class="form-check-label" for="showstudiobutton">
          Show Studio Button
        </label>
      </div>

      <div class="col-md-6 form-check mb-3">
        <input class="form-check-input" type="checkbox" id="privatecourse" [(ngModel)]="privatecourse">
        <label class="form-check-label" for="privatecourse">
          Private Course
        </label>
      </div>

    </div>
  </div>

  <div class="mt-3">
    <label class="form-label"><strong>Home Page Banner</strong></label>
  </div>
  <!-- <div class="d-flex align-items-center mb-3">
    <div class="form-check me-5">
      <input class="form-check-input" type="radio" name="banner" id="none" [(ngModel)]="banner" value="001">
      <label class="form-check-label" for="none">
        None
      </label>
    </div>
    <div class="form-check me-5">
      <input class="form-check-input" type="radio" name="banner" id="image" [(ngModel)]="banner" value="002">
      <label class="form-check-label" for="image">
        Image Slider
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="banner" id="video" [(ngModel)]="banner" value="003">
      <label class="form-check-label" for="video">
        Video
      </label>
    </div>
  </div> -->
  <div class="d-flex align-items-center justify-content-between flex-wrap">
    <div class="d-flex align-items-center flex-wrap">
      <div class="form-check me-5 mb-3">
        <input class="form-check-input" type="radio" name="banner" id="none" [(ngModel)]="banner" value="001">
        <label class="form-check-label" for="none">
          None
        </label>
      </div>
      <div class="form-check me-5 mb-3">
        <input class="form-check-input" type="radio" name="banner" id="image" [(ngModel)]="banner" value="002">
        <label class="form-check-label" for="image">
          Image Slider
        </label>
      </div>
      <div class="form-check me-5 mb-3">
        <input class="form-check-input" type="radio" name="banner" id="video" [(ngModel)]="banner" value="003">
        <label class="form-check-label" for="video">
          Video
        </label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="banner" id="image-text" [(ngModel)]="banner" value="000">
        <label class="form-check-label" for="image-text">
          Image + Text
        </label>
      </div>
    </div>

  </div>

  <div *ngIf="banner == '003' && videoSlide">

    <div class="row img-slide-row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 course-card">
        <ng-container *ngIf="videoSlide.videotype == '001'">
          <iframe [src]="videoUrlPreview | safe"></iframe>
        </ng-container>
        <div *ngIf="videoSlide.videotype == '002'">
          <div>
            <vm-player>
              <vm-video>
                <source [attr.data-src]="videoUrlPreview" type="video/mp4" />
              </vm-video>
              <vm-default-ui no-click-to-play>
              </vm-default-ui>
            </vm-player>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="banner == '002'">
    <ng-container>
      <div class="row img-slide-row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 course-card mb-3"
          *ngFor="let slid of imageSliderList; let i = index;">
          <mat-card class="imgslider-card">
            <div class="editicon">
              <button class="btn btn-outline-secondary" (click)="addImageToSlide(i)"><i class="fa fa-edit"></i></button>
              <div style="width: 20px;"></div>
              <button class="btn btn-outline-secondary" (click)="removeFromImageSlide(i)"><i class="fa fa-trash"></i></button>
            </div>
            <div class="img-container">
              <img [src]="slid.previewurl" alt="Photo of a">
            </div>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="banner == '000'">
    <ng-container>
      <div class="row img-slide-row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 course-card mb-3"
          *ngFor="let slid of imageTextList; let i = index;">
          <mat-card class="imgslider-card">
            <div class="editicon">
              <button class="btn btn-outline-secondary" (click)="addImageToSlide(i)"><i class="fa fa-edit"></i></button>
              <div style="width: 20px;"></div>
              <button class="btn btn-outline-secondary" (click)="removeFromImageSlide(i)"><i class="fa fa-trash"></i></button>
            </div>
            <div class="img-container">
              <img [src]="slid.previewurl" alt="Photo of a">
            </div>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>


  <div class="d-flex justify-content-end">
    <ng-container *ngIf="banner != '001'">

      <button mat-flat-button color="primary" class="ms-0 mt-2 me-auto" (click)="addImageToSlide()"
        *ngIf="banner == '002'">
        <i class="fa fa-desktop"></i>
        Add Slide </button>

      <button mat-flat-button color="primary" class="ms-0 mt-2 me-auto"
        (click)="videoSlide ? addImageToSlide(0): addImageToSlide()" *ngIf="banner == '003'">
        <i class="fa fa-desktop"></i>
        Set Up Video </button>

        <button mat-flat-button color="primary" class="ms-0 mt-2 me-auto" (click)="addImageToSlide()"
        *ngIf="banner == '000'">
        <i class="fa fa-desktop"></i>
        Add Image Text </button>
    </ng-container>

    <div class="d-flex justify-content-end">
      <button [disabled]="isCreating" (click)="goToChannels()" mat-flat-button color="primary" class="mt-2">
        Cancel </button>
      <app-button-loader [button_text]="'Create'" [isStillupload]="isCreating" (click)="createChannel()">
      </app-button-loader>
    </div>
  </div>
  <!-- </form> -->

</div>