import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDragSortEvent,
  CdkDragEnter,
  CdkDragExit,
  CdkDragMove,
  CdkDragEnd,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CourseUnitEditorComponent } from '../course-unit-editor/course-unit-editor.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { promise, until } from 'protractor';
import { Router, ActivatedRoute } from '@angular/router';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.componet';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { LoadingService } from '../../shared/services/loading.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Coinstructor } from '../../shared/models/Coinstructor';
import { SEOService } from '../../shared/services/seo.service';
import { Question } from '../../shared/models/Quiz';
import { ChangeProfileComponent } from '../../profiles/change-profile/change-profile.component';
import { QuestionEditorComponent } from '../../quizz/question-editor/question-editor.component';
import { Schedule } from '../../shared/models/Schedule';
import { TemplateQuestionsViewComponent } from '../../feedback/template-questions-view/template-questions-view.component';
import { ImportQuestionsComponent } from '../import-questions/import-questions.component';
import { event } from 'jquery';
import { FileUploadTwoComponent } from '../../shared/components/file-upload-two/file-upload-two.component';
import { CourseContentDuplicateEditorComponent } from '../course-content-duplicate-editor/course-content-duplicate-editor.component';

declare var bootstrap: any;

@Component({
  selector: 'app-course-add',
  templateUrl: './course-add.component.html',
  styleUrls: ['./course-add.component.scss'],
})
export class CourseAddComponent implements OnInit {



  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeAllForm();
  }

  destroyed = new Subject<void>();
  currentScreenSize: string = '';

  assignmentList: any = [
    {
      assignmentname: 'Testing Assignment input',
      duedate: '10-09-2023'
    }
  ]

  public assChildData($event: any): void {
    console.log('child data');
    console.log(event);
  }

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultFontSize: '1',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName',
      ],
      [
        // 'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  default_sub_sections = [
    {
      sub_section_name: 'Sub Section',
      open: false,
      isRename: false,
      units: [],
    },
  ];

  originalMandatoryInfos: string[] = [];
  courseClasses: any[] = [];
  selectedClassId: string = '';
  classTemplate: any = {
    classid: '',
    templateid: '',
    grade: false,
    certificategrade: '',
    certificatefeedback: false,
  };

  course: any = {
    anonymousLearning: '001',
    paymenttype: '001', // 001 free - 002 paid
    memberspaymenttype: '001',
    memberspaymentid: '',
    instructorid: '',
    courseid: '',
    coursename: '',
    coursesubname: '',
    courseimage: '',
    courseimagefiletype: '',
    category: '',
    subcategory: '',
    description: '',
    requirements: '',
    keywords: '',
    coursestatus: '',
    channelid: '',
    channelname: '',
    fee: 0,
    membersfee: 0,
    currency: 'MMK',
    memberscurrency: 'MMK',
    type: '003', // 001 - Self-pace, 002 - Instructor Led, 003 - Hybrid,
    grading: '002',
    gradepage: false,
    certificatepage: false,
    feedbackpage: false,
    template: [],
    feedbacktemplateid: '',
    mandatorytype: '003', // 001 - department, 002 - post , 003 - optional
    mandatoryPosts: [],
    mandatoryDepts: [],
    newchanges: false,
  };

  courseSchedule: Schedule = {
    startdate: '',
    starttime: '',
    enddate: '',
    endtime: '',
  };
  coursename_diaplay = '';
  sections: any = [];
  connectedSectionDropList: string[] = [];
  connectedSubSectionDropList: string[] = [];
  classcodelist: any[] = [];
  departmentlist: any[] = [];

  newSectionForm = this.formBuider.group({
    name: ['', Validators.required],
  });
  newSectionFormSubmitted: boolean = false;

  renameSectionForm = this.formBuider.group({
    name: ['', Validators.required],
  });
  renameSectionFormSubmitted: boolean = false;

  newSubsectionFormSubmitted: boolean = false;

  newSubsectionForm = this.formBuider.group({
    name: ['', Validators.required],
  });

  renameSubSectionForm = this.formBuider.group({
    name: ['', Validators.required],
  });
  renameSubsectionFormSubmitted: boolean = false;

  renameUnitForm = this.formBuider.group({
    name: ['', Validators.required],
  });

  isNewSectionOpen: boolean = false;
  isLoading: boolean = true;
  isOpen = false;
  isStillupload: boolean = false;
  isStillUpdate: boolean = false;
  isStillUpdateCourse: boolean = false;
  isStillPublish: boolean = false;
  publicChangesLoading: boolean = false;
  gettingTemplate: boolean = false;
  gettingFeedbackTemplate: boolean = false;
  gettingCourseClasses: boolean = false;

  courseSubmitted: boolean = false;
  showClass: boolean = false;
  showSchedule: boolean = false;

  courseid: string = '';
  lectureid : string = '';
  curriculumUrl: string = '';
  infoUrl: string = '';
  instructorUrl: string = '';
  prerequisitesUrl: string = '';

  assignmentUrl: string = '';
  videoUrl: string = '';
  studentUrl: string = '';
  gradebookUrl: string = '';
  scheduleUrl: string = '';
  customPageUrl: string = '';

  imageChangedEvent: any = '';
  croppedImage?: any = '';
  coppedImage: boolean = false;

  gettingCategory: boolean = false;
  gettingDiscussion: boolean = false;
  gettingTypes: boolean = false;
  customPageSaving: boolean = false;

  isStillAddingInstructor: boolean = false;
  categories: any = [];
  subcategories: any = [];
  filteredSubcategories: any[] = [];
  prerequitiesCourse: any = [];
  imageUrl: string = '';
  fileName: string = '';
  filetype: string = '';
  data: any;
  fileToReturn: any;
  @ViewChild('fileInput') fileInput: any;

  //Instructors
  gettingInstructors: boolean = false;
  isNewInstructorOpen: boolean = false;
  newInstructorFormSubmitted: boolean = false;
  isPrimary: boolean = false;
  newInstructorForm = this.formBuider.group({
    name: ['', Validators.required],
  });
  coInstructorList: Coinstructor[] = [];
  primaryInstructor!: Coinstructor;

  templates: any[] = [];
  feedbackTemplates: any[] = [];
  //Class
  classUrl: string = '';

  questionForEdit: any = '';

  constructor(
    private formBuider: FormBuilder,
    private http: HttpClient,
    public domSanitizer: DomSanitizer,
    public breakpointObserver: BreakpointObserver,
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private courseService: CourseService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private loadingService: LoadingService,
    public datepipe: DatePipe,
    private _seoService: SEOService
  ) {
    this.isLoading = true;
    this.breakPoint();
    this.route.params.subscribe((params) => {
      this.courseid = params['courseid'];
      this.curriculumUrl = `/instructor/courses/${this.courseid}/curriculum`;
      this.infoUrl = `/instructor/courses/${this.courseid}/info`;
      this.instructorUrl = `/instructor/courses/${this.courseid}/instructors`;
      this.classUrl = `/instructor/courses/${this.courseid}/class`;
      this.studentUrl = `/instructor/courses/${this.courseid}/students`;
      this.assignmentUrl = `/instructor/courses/${this.courseid}/assignments`;
      this.videoUrl = `/instructor/courses/${this.courseid}/videos`;
      this.prerequisitesUrl = `/instructor/courses/${this.courseid}/prerequisites`;
      this.gradebookUrl = `/instructor/courses/${this.courseid}/gradebook`;
      this.scheduleUrl = `/instructor/courses/${this.courseid}/schedule`;
      this.customPageUrl = `/instructor/courses/${this.courseid}/custompage`;
    });
  }

  async ngOnInit() {
    this.GetPrerequitiesCourse();
    this.getInstructors();
    this.getAllClass();
    this.getCourseAndSections().then(async () => {
      this.getTypes();
      const gettypes = await this.getMandatoryTypes();
      this.isLoading = false;
      if (this.course.certificatepage) {
        this.getTemplate();
      }
      if (this.course.feedbackpage) {
        this.getFeedbackTemplate();
      }
      this.getCategory();
    });
  }

  getAllClass() {
    this.gettingCourseClasses = true;
    this.courseService.getAllClass(this.courseid).subscribe(
      (classes: any) => {
        if (classes.returncode == '300') {
          this.courseClasses = classes.datalist;
          this.gettingCourseClasses = false;
        } else {
          if (classes.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(classes.status || classes.message || classes.error, 'warn');
          }
          this.gettingCourseClasses = false;
        }
      },
      (err) => {
        this.gettingCourseClasses = false;
      }
    );
  }

  onClassChange() {
    let classid = this.selectedClassId;
    let foundClass = this.course?.template?.length && this.course.template.find((i: any) => i.classid == classid);
    if(foundClass) {
      this.classTemplate = foundClass
    } else {
      this.classTemplate.classid = classid;
      this.classTemplate.templateid = "";
      this.classTemplate.grade = false;
      this.classTemplate.certificategrade = "";
      this.classTemplate.certificatefeedback = false;
    }
  }

  goToPreview(type : string) {
    console.log(this.sections);
    if(type == '001') {
      this.router.navigateByUrl(`/preview/courses/${this.courseid}/lectures/published`);
    } else {
      this.router.navigateByUrl(`/preview/courses/${this.courseid}/lectures/unpublished`);
    }
  }

  getMandatoryTypes() {
    return new Promise((resolve) => {
      this.courseService.getMandatoryInfo(this.courseid).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (res.datalist.length > 0) {
              this.course.mandatorytype = res.datalist[0].type;
              res.datalist.map((item: any) => {
                this.originalMandatoryInfos.push(item.typename);
                if (this.course.mandatorytype == '001') {
                  this.course.mandatoryDepts.push(item.typename);
                } else {
                  this.course.mandatoryPosts.push(item.typename);
                }
              });
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            if (res.returncode == '210') {
              this.allinoneService.logout();
            }
          }
          resolve(true);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  getTypes() {
    this.gettingTypes = true;
    this.courseService.getType(this.course.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.classcodelist = res.classcodelist;
          this.departmentlist = res.departmentlist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingTypes = false;
      },
      (err) => {
        this.gettingTypes = false;
      }
    );
  }

  breakPoint() {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
  }

  certificateOnOff() {
    if (this.course.certificatepage && this.templates.length == 0) {
      this.getTemplate();
    }
  }

  feedbackOnOff() {
    if (this.course.feedbackpage && this.feedbackTemplates.length == 0) {
      this.getFeedbackTemplate();
    }
    else {
      this.course.certificatefeedback = false;
    }
  }

  getTemplate() {
    this.gettingTemplate = true;
    this.courseService.getTemplatesbyInstructor(this.course.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.templates = res.datalist;
          if (this.course.templateid == '') {
            this.course.templateid = this.templates[0].templateid;
            console.log('tempalteid ===> ' + this.course.templateid);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingTemplate = false;
      },
      (err) => {
        this.gettingTemplate = false;
      }
    );
  }


  getFeedbackTemplate() {
    this.gettingFeedbackTemplate = true;
    this.courseService.getFeedbackTemplate(this.course.channelid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.feedbackTemplates = res.datalist;
          if (this.course.feedbacktemplateid == '') {
            this.course.feedbacktemplateid = this.feedbackTemplates[0].fbtmpid;
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingFeedbackTemplate = false;
      },
      (err) => {
        this.gettingFeedbackTemplate = false;
      }
    );
  }

  addInstructor() {
    this.newInstructorFormSubmitted = true;
    if (this.newInstructorForm.invalid) {
      return;
    }
    this.isStillAddingInstructor = true;
    const co_instructor = this.allinoneService.checkUserId(
      this.newInstructorForm.value.name.toString().trim()
    );
    this.courseService.addCoInstructor(this.courseid, co_instructor).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.coInstructorList.push(res.data);
          this.showNewInstructor();
        } else {
          this.messageService.openSnackBar(res.message || res.error, 'warn');
        }
        this.isStillAddingInstructor = false;
        this.newInstructorFormSubmitted = false;
      },
      (err) => {
        this.isStillAddingInstructor = false;
        this.newInstructorFormSubmitted = false;
      }
    );
  }

  getInstructors() {
    this.gettingInstructors = true;
    this.isPrimary = false;
    this.courseService.getInstructors(this.courseid).subscribe(
      (res: any) => {
        if ((res.returncode == '300')) {
          this.coInstructorList = res.coinstructors;
          this.primaryInstructor = res.primary;
          this.primaryInstructor.profileimage = res.primary.profileid
            ? res.primary.profileimageurl
            : res.primary.profileimage;
          if (
            this.primaryInstructor.userid ==
            this.allinoneService.getKunyekUserId()
          ) {
            this.isPrimary = true;
          }
          if (this.coInstructorList.length == 0 && this.isPrimary) {
            this.showNewInstructor();
          }
        } else if (res.returncode == '403') {
          this.messageService.openSnackBar(res.message || "Unauthorized Access!", 'warn');
          this.router.navigateByUrl('/');
        } else {
          this.messageService.openSnackBar(res.message || res.error, 'warn');
        }
        this.gettingInstructors = false;
      },
      (err) => {
        this.gettingInstructors = false;
      }
    );
  }

  deleteCoInstructor(index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '400px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        message: 'Are you sure to remove ' + (this.coInstructorList[index].username ? this.coInstructorList[index].username : this.coInstructorList[index].userid) + ' from co-instructors?'
      }
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .removeCoinstructor(this.coInstructorList[index].id)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.coInstructorList.splice(index, 1);
              } else {
                this.messageService.openSnackBar(
                  res.message || res.error,
                  'warn'
                );
              }
              this.loadingService.hide();
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }

  transferOwnership(index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '400px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        message: 'Are you sure to transfer ownership of this course to ' + (this.coInstructorList[index].username ? this.coInstructorList[index].username : this.coInstructorList[index].userid) + '?'
      }
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .transferCourseOwnership(this.courseid, this.allinoneService.user.usersyskey, this.coInstructorList[index].id)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.getInstructors();
              } else {
                this.messageService.openSnackBar(
                  res.message || res.error,
                  'warn'
                );
              }
              this.loadingService.hide();
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }

  showNewInstructor() {
    this.isNewInstructorOpen = !this.isNewInstructorOpen;
    this.newInstructorFormSubmitted = false;
    this.newInstructorForm.reset();
  }

  anonymousChange(e: any) {
    if (e.target.checked) {
      this.course.anonymousLearning = '002';
    } else {
      this.course.anonymousLearning = '001';
    }
  }

  getCourseAndSections() {
    return new Promise((resolve) => {
      this.courseService.getCourseByCourseID(this.courseid).subscribe(
        (course_data: any) => {
          if (course_data.returncode == '300') {
            console.log('Your course data');
            console.log(course_data);
            console.log(course_data.paymenttypelist);

            //Course landing page [Course Detail]
            // this.course.instructorid = course_data.data.instructorid;

            this._seoService.updateTitle('- ' + course_data.data.coursename);
            this.course.instructorid = course_data.data.instructorid;
            this.course.courseid = course_data.data.courseid;
            this.course.coursename = course_data.data.coursename;
            this.coursename_diaplay = course_data.data.coursename;
            this.course.coursesubname = course_data.data.coursesubname;

            this.course.grade = course_data.data.certificategrade ? true : false;
            this.course.certificategrade = course_data.data.certificategrade;

            this.course.certificatefeedback = course_data.data.certificatefeedback;

            this.course.courseimage = course_data.data.courseimage;
            this.course.courseimagefiletype = 'url';
            this.course.category = course_data.data.category;
            this.course.subcategory = course_data.data.subcategory;
            this.course.description = course_data.data.description;
            this.course.requirements = course_data.data.requirements;
            this.course.keywords = course_data.data.t1;
            this.course.type = course_data.data.coursetype;
            this.course.grading = course_data.data.grading ?? '002';
            this.course.fee = parseInt(course_data.data.amount);
            this.course.paymenttype = course_data.data.paymenttype;
            // this.course.memberspaymenttype = course_data.paymenttypelist[0].paymenttype ? course_data.paymenttypelist[0].paymenttype  : '';
            this.course.membersfee = course_data.data.paymenttypelist.length > 0 ? course_data.data.paymenttypelist[0].amount : course_data.data.channelid ? parseInt(course_data.data.amount) : '';
            this.course.memberspaymenttype = course_data.data.paymenttypelist.length > 0 ? course_data.data.paymenttypelist[0].paymenttype : course_data.data.channelid ? course_data.data.paymenttype : '';
            // this.course.membersfee = course_data.paymenttypelist[0]['amount'] ? course_data.paymenttypelist[0]['amount'] : '';
            this.course.template = course_data.data?.template ?? [];
            let foundClass = course_data.data?.template?.length && course_data.data.template.find((i: any) => i.classid == "");
            if(foundClass) {
              this.classTemplate = foundClass
            }
            this.course.gradepage = course_data.data.gradepage;
            this.course.certificatepage = course_data.data.certificatepage;
            this.course.feedbackpage = course_data.data.feedbackpage;
            this.course.templateid = course_data.data.templateid || '';
            this.course.currency = course_data.data.currency;
            // this.course.memberscurrency = course_data.paymenttypelist[0].currency ? course_data.paymenttypelist[0].currency : '';
            // this.course.memberspaymentid = course_data.paymenttypelist[0].id ? course_data.paymenttypelist[0].id : '' ;
            this.course.memberscurrency = course_data.data.paymenttypelist.length > 0 ? course_data.data.paymenttypelist[0]['currency'] : course_data.data.channelid ? course_data.data.currency : '';
            this.course.memberspaymentid = course_data.data.paymenttypelist.length > 0 ? course_data.data.paymenttypelist[0]['id'] : '';
            // ***
            this.course.newchanges = course_data.data.newchanges;

            if (this.course.type == '001') {
              this.showClass = false;
            } else {
              this.showClass = true;
            }
            if (this.course.type == '002') {
              this.showSchedule = true;
            } else {
              this.showSchedule = false;
            }

            this.courseSchedule = {
              startdate: course_data.data.startdate || '',
              starttime: course_data.data.starttime || '',
              enddate: course_data.data.enddate || '',
              endtime: course_data.data.endtime || '',
            };

            this.course.coursestatus = course_data.data.coursestatus;
            (this.course.channelid = course_data.data.t2),
              (this.course.channelname = course_data.data.channelname),
              (this.course.anonymousLearning = course_data.data.access),
              //Curriculum  [Session & SubSection & Units]
              course_data.data.sections.map((sections: any) => {
                //Sub Sections

                /**
                 * @param subsectionid - id of the sub section
                 * @param sub_section_name - name of sub section
                 * @param sortkey - sort array for sub section
                 * @param open - check drop down  for sub section
                 * @param isRename - check for show sub section edition form
                 */

                var subsections: any = [];
                sections.subsections.map((subsectionsData: any) => {
                  //Units
                  /**
                   * @param unit_name - name of Leacture,
                   * @param open - check dro down for unit,
                   * @param isRename - check for show unit edition form,
                   * @param contenttype - type of document [video link - 1, pdf(ppt,doc) -2 , article - 3],
                   * @param content: unit.content_data
                   */

                  var units: any = [];
                  subsectionsData.units.map((unitData: any) => {
                    let icon;
                    if (unitData.contenttype == '1') {
                      if (
                        unitData.content
                          .toString()
                          .startsWith('https://youtu.be/') ||
                        unitData.content
                          .toString()
                          .startsWith('https://www.youtube.com')
                      ) {
                        icon = 'youtube';
                      } else {
                        icon = 'vimeo';
                      }
                    } else if (unitData.contenttype == '2') {
                      const filetype = unitData.content.split('.').pop();
                      if (filetype == 'pdf') {
                        icon = 'pdf';
                      } else if (filetype == 'docx') {
                        icon = 'word';
                      } else if (filetype == 'pptx') {
                        icon = 'powerpoint';
                      } else if (filetype == 'xlsx') {
                        icon = 'excel';
                      }
                    }
                    units.push({
                      icon: icon,
                      unitid: unitData.unitid,
                      unit_name: unitData.unitname,
                      open: false,
                      isRename: false,
                      isStillUpdateUnit: false,
                      sortkey: unitData.sortkey,
                      contenttype: Number(unitData.contenttype),
                      content: unitData.content,
                      libraryid: unitData.libraryid,
                      downloadurl: unitData.downloadurl,
                      questions: [],
                      gotquestions: false,
                      gettingquestions: false,
                      isNewQuestion: false,
                      maxattempt: unitData.maxattempt,
                      quizconfig: unitData.quizconfig,
                      grade: unitData.grade,
                      studytime: unitData.studytime,
                      deadlinedate: unitData.deadlinedate,
                      deadlinetime: unitData.deadlinetime,
                      attachment: unitData.attachment,
                      libraryfilename: unitData.libraryfilename,
                      showfeedback: unitData.showfeedback,
                      autonumbering: unitData.autonumbering,
                      allowdownload: unitData.allowdownload,
                      bygroup: unitData.bygroup,
                      groupquestionscount: unitData.groupquestionscount,
                      grouptime: unitData.grouptime,
                      attachmentlist : unitData.attachmentlist,
                      randomassignment : unitData.randomassignment,
                      randomassignmentcount: unitData.randomassignmentcount,
                      multifileupload : unitData.multifileupload,
                      multifileuploadcount: unitData.multifileuploadcount
                    });
                  });

                  // this.sections[section_index].sub_sections[sub_section_index].units.length
                  // subsection id array for cross drag and drop
                  this.connectedSubSectionDropList.push(
                    subsectionsData.subsectionid
                  );

                  subsections.push({
                    subsectionid: subsectionsData.subsectionid,
                    sub_section_name: subsectionsData.subsectionname,
                    sortkey: subsectionsData.sortkey,
                    open: true,
                    isRename: false,
                    units: units,
                  });
                });

                //Sections

                /**
                 * @param sectionid - id of the  section
                 * @param section_name - name of  section
                 * @param sortkey - sort array for section
                 * @param open - check drop down  for  section
                 * @param isRename - check for show  section edition form
                 * @param isNewSubsectionOpen - check for show  new sub section form
                 * @param isStilluploadSubSection - show loading if data post
                 */

                let unit = [];
                for (let a = 0; a < subsections.length; a++) {
                  for (let i = 0; i < subsections[a].units.length; i++) {
                    unit.push(subsections[a].units[i]);
                  }
                }

                // Connected Seciton ID lists
                this.connectedSectionDropList.push(sections.sectionid);

                // Sections Data
                this.sections.push({
                  sectionid: sections.sectionid,
                  section_name: sections.sectionname,
                  sortkey: sections.sortkey,
                  open: subsections.length == 0? true : false,
                  isNewSectionOpen: false,
                  newSectionFormSubmitted: false,
                  isStillupload: false,
                  isStilluploadSubSection: false,
                  isStillUpdateSubSection: false,
                  isRename: false,
                  sub_sections: subsections,
                  unitlength: unit.length,
                });
              });
            console.log('Your ans');
            console.log(course_data.paymenttypelist);
            if(this.sections.length == 1){
              this.sections[0].open = true;
            }
          } else if (course_data.returncode == "403") {
            this.messageService.openSnackBar(
              course_data?.message,
              'warn'
            );
            this.router.navigateByUrl('home');
          } else {
            if (course_data.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(course_data.status, 'warn');
            }
          }

          resolve('success');
        },
        (err) => {
          resolve('err');
        }
      );
    });
  }

  getCategory() {
    if (this.categories.length == 0) {
      this.gettingCategory = true;
      const data = {
        type: '2',
        channelid: this.course.channelid,
      };
      var fun = this.course.channelid
        ? this.courseService.getCategoryList(data)
        : this.courseService.getCategoryList();
      fun.subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.categories = res.data;
            if (this.course.channelid) {
              this.subcategories = res.subcategorylist;
              this.categoryChange();
            }
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }
          this.gettingCategory = false;
        },
        (err) => {
          this.gettingCategory = false;
        }
      );
    }
  }
  GetPrerequitiesCourse() {
    if (this.prerequitiesCourse.length == 0) {
      this.courseService.getPrerequities(this.courseid).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.prerequitiesCourse = res.datalist;
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }
        },
        (err) => { }
      );
    }
  }

  categoryChange() {
    this.filteredSubcategories = this.subcategories.filter(
      (item: any) => item.categoryid === this.course.category
    );
    const finding = this.filteredSubcategories.find(
      (item: any) => item.categoryname == this.course.subcategory
    );
    this.course.subcategory = finding ? this.course.subcategory : '';
  }

  fileChangeEvent(file: any) {
    this.coppedImage = false;
    if (file.target.files && file.target.files[0]) {
      //size filter
      const min_width = 150;
      const min_height = 150;

      try {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = (rs: any) => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];
            if (img_height < min_height || img_width < min_width) {
              this.fileInput.nativeElement.value = '';
              return this.messageService.openSnackBar(
                'Image quality is low',
                'warn'
              );
            } else {
              this.imageUrl = e.target.result;
              this.imageChangedEvent = file;
              this.course.courseimage = '';
              this.filetype = file.target.files[0].name.split('.').pop();
              this.fileName = this.getFileName(file.target.files[0]);
            }
          };
        };
        reader.readAsDataURL(file.target.files[0]);
      } catch (e) {
        console.log(e);
      }
    }
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  //   let File = base64ToFile(this.croppedImage);
  //   console.log(File);
  // }
  uploadImage() {
    var date = new Date();
    var millisecond = date.getMilliseconds();
    var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    var filename = this.fileName + '_' + time + '.' + this.filetype;

    this.course.courseimage = filename;

    this.allinoneService
      .fileUploadToS3WithPathForImage(this.fileToReturn, filename, 'images')
      .then(
        () => { },
        () => {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
        }
      );

    return this.http.options('');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.course.courseimage
    );
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
    // show cropper
  }
  getFileName(file: any) {
    var name = file.name.toString().trim();
    var splitName = name.split('.');
    return splitName[0];
  }

  copyImage() {
    this.course.courseimage = this.fileName;
    this.course.courseimagefiletype = 'base64';
    this.coppedImage = true;
    this.fileInput.nativeElement.value = '';
  }

  removeImage() {
    this.course.courseimage = '';
    this.course.courseimagefiletype = '';
    this.coppedImage = false;
    this.fileInput.nativeElement.value = '';
    this.imageChangedEvent = '';
  }

  loadImageFailed() {
    // show message
  }

  //description - 400
  updateCourse() {
    this.courseSubmitted = true;
    if (this.course.coursename == '') {
      window.scroll(0, 0);
      return;
    }

    if (this.course.paymenttype == '002' && this.course.fee < 1) {
      return this.messageService.openSnackBar(
        'Please enter appropriate course fee.',
        'warn'
      );
    }

    if (this.course.paymenttype == '002' && this.course.memberspaymenttype == '002' && this.course.membersfee < 1) {
      return this.messageService.openSnackBar(
        // 'Please enter appropriate members course fee.',
        'Please enter appropriate course fee for members.',
        'warn'
      );
    }

    if (!this.coppedImage && this.imageChangedEvent != '') {
      return this.messageService.openSnackBar(
        "You need to do 'crop image' ",
        'warn'
      );
    }

    if (this.course.certificatepage && this.course.templateid == '') {
      return this.messageService.openSnackBar(
        'Please select a template for certificate',
        'warn'
      );
    }

    // if (
    //   this.course.mandatorytype == '001' &&
    //   this.course.mandatoryDepts.length == 0
    // ) {
    //   return this.messageService.openSnackBar(
    //     'Please select at least one department.',
    //     'warn'
    //   );
    // }

    // if (
    //   this.course.mandatorytype == '002' &&
    //   this.course.mandatoryPosts.length == 0
    // ) {
    //   return this.messageService.openSnackBar(
    //     'Please select at least one post.',
    //     'warn'
    //   );
    // }

    this.isStillUpdateCourse = true;
    //split keywords
    if (this.course.keywords != '') {
      this.course.keywords = this.course.keywords.toString().split(',');
    }

    if (this.fileToReturn) {
      var date = new Date();
      var millisecond = date.getMilliseconds();
      var time = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
      var filename = this.fileName + '_' + time + '.' + this.filetype;
      this.course.courseimage = filename;
     

      this.allinoneService
        .fileUploadToS3WithPathForImage(this.fileToReturn, filename, 'images')
        .then(
          () => {
            this.saveCourse();
          },
          () => {
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
          }
        );
    } else {
      this.saveCourse();
    }
  }

  updateMandatoryInfos() {
    return new Promise((resolve) => {
      const data =
        this.course.mandatorytype == '001'
          ? this.course.mandatoryDepts
          : this.course.mandatoryPosts;
      this.courseService
        .updateMandatoryInfo(
          this.course.courseid,
          this.course.mandatorytype,
          data
        )
        .subscribe(
          (res: any) => {
            this.originalMandatoryInfos = data;
            resolve(true);
          },
          (err) => {
            resolve(true);
          }
        );
    });
  }

  updateCustomPage() {
    if (this.customPageSaving) {
      return;
    }
    if (this.course.feedbackpage && this.course.feedbacktemplateid == '') {
      return this.messageService.openSnackBar('Please select a feedback template.', 'warn');
    }
    if (this.course.certificatepage && this.course.grade && (this.course.certificategrade == '' || this.course.certificategrade == null)) {
      return this.messageService.openSnackBar('Please enter percentage.', 'warn');
    }

    if(this.course?.template?.length > 0) {
      let existClass = this.course.template.findIndex((i: any) => i.classid === this.selectedClassId);
      if(existClass !== -1) {
        this.course.template[existClass] = { ...this.classTemplate }
      } else {
        this.course.template = [...this.course.template, { ...this.classTemplate }];
      }
    } else {
      this.course.template = [{...this.classTemplate}];
    }

    this.customPageSaving = true;
    this.courseService.updateCourseCustomPage(this.course).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.messageService.openSnackBar('Updated Successfully', 'success');
      }
      else {
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.customPageSaving = false;
    }, err => {
      this.customPageSaving = false;
    })
  }

  saveCourse() {
    // return;
    this.courseService.updateCourse(this.course).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          let missing;
          if (this.course.mandatorytype != '003') {
            if (this.course.mandatorytype == '001') {
              if (
                this.course.mandatoryDepts.length ==
                this.originalMandatoryInfos.length
              ) {
                missing = this.course.mandatoryDepts.filter(
                  (item: any) => this.originalMandatoryInfos.indexOf(item) < 0
                );
                if (missing.length > 0) {
                  const updateMandatory = await this.updateMandatoryInfos();
                }
              } else {
                const updateMandatory = await this.updateMandatoryInfos();
              }
            } else if (this.course.mandatorytype == '002') {
              if (
                this.course.mandatoryPosts.length ==
                this.originalMandatoryInfos.length
              ) {
                missing = this.course.mandatoryPosts.filter(
                  (item: any) => this.originalMandatoryInfos.indexOf(item) < 0
                );
                if (missing.length > 0) {
                  const updateMandatory = await this.updateMandatoryInfos();
                }
              } else {
                const updateMandatory = await this.updateMandatoryInfos();
              }
            }
          }
          this.messageService.openSnackBar('Updated Successfully', 'success');
          this.fileToReturn = null;
          if (this.course.courseimage) {
            this.course.courseimage = res.data.courseimage;
            this.course.courseimagefiletype = 'url';
          }
          this.coursename_diaplay = this.course.coursename;
          if (this.course.type == '001') {
            this.showClass = false;
          } else {
            this.showClass = true;
          }
          if (this.course.type == '002') {
            this.showSchedule = true;
          } else {
            this.showSchedule = false;
          }
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
          }
        }
        this.isStillUpdateCourse = false;
        this.courseSubmitted = false;
      },
      (err) => {
        this.isStillUpdateCourse = false;
        this.courseSubmitted = false;
      }
    );
  }

  updateCourseStatus(coursestatus: any) {
    if (coursestatus == 0) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: '300px',
        scrollStrategy: new NoopScrollStrategy(),
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.courseService
            .updateCourseStatus(this.course.courseid, coursestatus)
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.messageService.openSnackBar(
                    'Unpublished Successfully',
                    'success'
                  );
                  this.course.coursestatus = coursestatus;
                } else {
                  if (res.returncode == '210') {
                    setTimeout(() => {
                      this.router.navigateByUrl('login');
                      this.allinoneService.logout();
                    }, 2000);
                  } else {
                    this.messageService.openSnackBar(
                      res.status || res.message || res.error,
                      'warn'
                    );
                  }
                }
                this.loadingService.hide();
                this.isStillPublish = false;
              },
              (err) => {
                this.loadingService.hide();
                this.isStillPublish = false;
              }
            );
        }
      });
    } else {
      // if (this.sections.length == 0 || this.course.coursesubname == '' || this.course.description.length < 300 || this.course.requirements == '' ||
      //   this.course.category == '' || this.course.courseimage == '') {

      // if (this.sections.length == 0 || this.course.category == '') {
      //   var myOffcanvas = document.getElementById('ntd_offcanvas');
      //   var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

      //   bsOffcanvas.show();
      // } else {}

        this.isStillPublish = true;
        this.courseService
          .updateCourseStatus(this.course.courseid, coursestatus)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                if (coursestatus == 1) {
                  this.messageService.openSnackBar(
                    'Published Successfully',
                    'success'
                  );
                  this.course.coursestatus = coursestatus;
                }
              } else {
                if (res.returncode == '210') {
                  setTimeout(() => {
                    this.router.navigateByUrl('login');
                    this.allinoneService.logout();
                  }, 2000);
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message || res.error,
                    'warn'
                  );
                }
              }
              this.isStillPublish = false;
            },
            (err) => {
              this.isStillPublish = false;
            }
          );

    }
  }

  goToPage(tab: string) {
    this.router.navigateByUrl(`/instructor/courses/${this.courseid}/${tab}`);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    this.sectionsortKeyUpdate();
  }

  dragAndDropQuestion(unit: any, event: CdkDragDrop<string[]>) {
    moveItemInArray(unit.questions, event.previousIndex, event.currentIndex);
    this.questionsortKeyUpdate(unit.questions);
  }

  dropEnd(event: CdkDragEnd) { }

  sectionsortKeyUpdate() {
    var sections: any = [];
    this.sections.map((section: any, index: number) => {
      section.sortkey = index;
      sections.push({
        sectionid: section.sectionid,
        sortkey: index,
      });
    });

    this.courseService.sortSections(sections, this.courseid).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          this.course.newchanges = true;
        }
      },
      (err) => { }
    );
  }

  questionsortKeyUpdate(questions: Question[]) {
    var fquestions: any = [];
    questions.map((question: any, index: number) => {
      question.sortkey = index;
      fquestions.push({
        quizid: question.quizid,
        sortkey: index.toString().trim(),
      });
    });

    this.courseService.sortQuestions(fquestions, this.courseid).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          this.course.newchanges = true;
        }
      },
      (err) => { }
    );
  }

  dropSubSection(event: CdkDragDrop<string[]>) {
    const sectionidForUpdate = event.container.id;
    const subsectionidForUpdate = event.item.data.subsectionid;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.draggedSubsectionUpdate(
        [],
        event.container.data,
        sectionidForUpdate,
        subsectionidForUpdate
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.draggedSubsectionUpdate(
        event.previousContainer.data,
        event.container.data,
        sectionidForUpdate,
        subsectionidForUpdate
      );
      this.sections.map((section: any) => {
        section.unitlength = 0;
        section.sub_sections.map((subsection: any) => {
          section.unitlength += subsection.units.length;
        });
      });
    }
  }

  draggedSubsectionUpdate(
    previousContainer: any[],
    currentContainer: any[],
    sectionid: string,
    subsectionid: string
  ) {
    var oldsubsection: any[] = [];
    var newsubsection: any[] = [];
    previousContainer.map((subsection: any, index: number) => {
      subsection.sortkey = index;
      oldsubsection.push({
        subsectionid: subsection.subsectionid,
        sortkey: index,
      });
    });
    const sub_index = currentContainer.findIndex(
      (subsection) => subsection.subsectionid === subsectionid
    );
    currentContainer.map((subsection: any, index: number) => {
      subsection.sortkey = index;
      if (
        (index >= sub_index && previousContainer.length > 0) ||
        previousContainer.length == 0
      ) {
        newsubsection.push({
          subsectionid: subsection.subsectionid,
          sortkey: index,
        });
      }
    });
    this.courseService
      .subSectionDragAndDrop(
        oldsubsection,
        newsubsection,
        subsectionid,
        sectionid,
        this.courseid
      )
      .subscribe((res: any) => {
        if (res.returncode == 300) {
          this.course.newchanges = true;
        }
      });
  }

  subSectionKeyUpdate(section_index: number) {
    var subsections: any = [];
    this.sections[section_index].sub_sections.map(
      (subsection: any, index: number) => {
        subsection.sortkey = index;

        subsections.push({
          subsectionid: subsection.subsectionid,
          sortkey: index,
        });
      }
    );

    this.courseService.sortSubSections(subsections).subscribe((res: any) => { });
  }

  dropSubSectionEnd(event: CdkDragEnd, section_index: number) {
    // var subsections: any = [];
    // this.sections[section_index].sub_sections.map((subsection: any, index: number) => {
    //   subsection.sortkey = index;
    //   console.log(subsection);
    //   subsections.push({
    //     "subsectionid": subsection.subsectionid,
    //     "sortkey": index
    //   });
    // });
    // console.log(subsections);
  }
  dropUnit(event: CdkDragDrop<string[]>) {
    const subsectionidForUpdate = event.container.id;
    const unitidForUpdate = event.item.data.unitid;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.draggedUnitUpdate(
        [],
        event.container.data,
        subsectionidForUpdate,
        unitidForUpdate
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.draggedUnitUpdate(
        event.previousContainer.data,
        event.container.data,
        subsectionidForUpdate,
        unitidForUpdate
      );
      this.sections.map((section: any) => {
        section.unitlength = 0;
        section.sub_sections.map((subsection: any) => {
          section.unitlength += subsection.units.length;
        });
      });
    }
  }
  draggedUnitUpdate(
    previousContainer: any[],
    currentContainer: any[],
    subsectionid: string,
    unitid: string
  ) {
    var oldunits: any[] = [];
    var newunits: any[] = [];
    previousContainer.map((unit: any, index: number) => {
      unit.sortkey = index;
      oldunits.push({
        unitid: unit.unitid,
        sortkey: index,
      });
    });
    //find the index of the new unit
    const unit_index = currentContainer.findIndex(
      (unit) => unit.unitid === unitid
    );
    currentContainer.map((unit: any, index: number) => {
      unit.sortkey = index;
      if (
        (index >= unit_index && previousContainer.length > 0) ||
        previousContainer.length == 0
      )
        newunits.push({
          unitid: unit.unitid,
          sortkey: index,
        });
    });
    this.courseService
      .unitDragAndDrop(oldunits, newunits, unitid, subsectionid, this.courseid)
      .subscribe((res: any) => {
        if (res.returncode == 300) {
          this.course.newchanges = true;
        }
      });
  }

  unitKeyUpdate(section_index: number, sub_section_index: number) {
    var units: any = [];
    this.sections[section_index].sub_sections[sub_section_index].units.map(
      (unit: any, index: number) => {
        unit.sortkey = index;

        units.push({
          unitid: unit.unitid,
          sortkey: index,
        });
      }
    );

    this.courseService.sortUnit(units, this.courseid).subscribe((res: any) => { });
  }

  clickdropDown(section: any, index: any) {
    section.open = !section.open;
    for (let b = 0; b < this.sections[index].sub_sections.length; b++) {
      this.sections[index].sub_sections[b].open = true;
    }
  }

  clickdropDown1(sub_section: { open: boolean }) {
    sub_section.open = !sub_section.open;
  }

  clickQuestionDropdown(unit: {
    contenttype: number;
    open: boolean;
    gotquestions: boolean;
    isNewQuestion: boolean;
  }) {
    if (unit.contenttype == 6) {
      if (unit.open && unit.isNewQuestion) {
        this.messageService.openSnackBar(
          'Your quiz is still in progress.',
          'warn'
        );
        return;
      }
      unit.open = !unit.open;
      if (unit.open) {
        if (!unit.gotquestions) {
          this.getQuestions(unit);
        }
      }
    }
  }

  getQuestions(unit: any) {
    unit.gettingquestions = true;
    this.courseService.getQuestionsByUnit(this.courseid, unit.unitid).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          unit.gettingquestions = false;
          unit.gotquestions = true;
          var temp: Question[] = [];
          (temp = res.datalist), (unit.questions = temp);
        } else {
          unit.gettingquestions = false;
          this.messageService.openSnackBar(
            res.message || res.status || res.message || res.error || res.error,
            'warn'
          );
        }
      },
      (err) => {
        unit.gettingquestions = false;
      }
    );
  }

  saveQuestion(unit: any, data: any) {
    var tempQ: Question = data;
    unit.questions.push(tempQ);
    unit.isNewQuestion = false;
  }

  updateQuestion(unit: any, data: any) {
    var tempQ: Question = data;
    unit.questions.map((question: Question) => {
      if (question.quizid == tempQ.quizid) {
        question.question = tempQ.question;
        question.answerlist = tempQ.answerlist;
        question.correctanswer = tempQ.correctanswer;
        question.sortkey = tempQ.sortkey;
        question.type = tempQ.type;
        question.casesensitive = tempQ.casesensitive || 'false';
      }
    });
    unit.isNewQuestion = false;
    this.questionForEdit = '';
  }

  cancelQuestion(unit: any) {
    unit.isNewQuestion = false;
    this.questionForEdit = '';
  }

  editQuestion(question: Question, unit: any) {
    // this.questionForEdit = question;
    // unit.isNewQuestion = true;
    const dialog = this.dialog.open(QuestionEditorComponent, {
      maxWidth: '95vw',
      width: '900px',
      maxHeight: '95vh',
      height: 'auto',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid: this.courseid,
        unitid: unit.unitid,
        sortkey: unit.questions.length,
        questionForEdit: question,
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.course.newchanges = true;
        // this.updateQuestion(unit, dialogResult);
        this.getQuestions(unit);
      }
    });
  }

  deleteQuestion(index: number, unit: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .deleteQuestion(unit.questions[index].quizid, this.courseid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.course.newchanges = true;
                unit.questions.splice(index, 1);
                this.loadingService.hide();
              } else {
                this.loadingService.hide();
                this.messageService.openSnackBar(
                  res.message ||
                  res.error ||
                  res.status ||
                  res.message ||
                  res.error,
                  'warn'
                );
              }
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }

  newQuestion(unit: any) {
    this.questionForEdit = null;
    const dialog = this.dialog.open(QuestionEditorComponent, {
      maxWidth: '95vw',
      width: '900px',
      maxHeight: '95vh',
      height: 'auto',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid: this.courseid,
        unitid: unit.unitid,
        sortkey:
          unit.questions.length > 0
            ? (
              parseInt(unit.questions[unit.questions.length - 1].sortkey) + 1
            ).toString()
            : '0',
        // allunitdata : unit
      },
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // this.course.newchanges = true;
        // unit.questions.push(dialogResult);
        // ***
        this.course.newchanges = true;
        this.getQuestions(unit);
      }
    });
  }

  showNewSection(index?: number): void {
    const promise = Promise.all(this.closeAllForm('close'));
    if (index != undefined) {
      for (let i = 0; i < this.sections.length; i++) {
        if (i != index) {
          this.sections[i].isNewSectionOpen = false;
        }
        this.sections[i].newSectionFormSubmitted = false;
      }
      this.sections[index].isNewSectionOpen =
        !this.sections[index].isNewSectionOpen;
    } else {
      this.isNewSectionOpen = !this.isNewSectionOpen;
    }
    this.newSectionForm.reset();
  }

  showNewSubsection(
    section: { isNewSubsectionOpen: boolean },
    status: string
  ): void {
    const promise = Promise.all(this.closeAllForm(status));
    section.isNewSubsectionOpen = !section.isNewSubsectionOpen;
    this.newSubsectionFormSubmitted = false;
    this.renameSubSectionForm.reset();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newSectionForm.controls;
  }
  get newSubsectionFormControl() {
    return this.newSubsectionForm.controls;
  }
  get renameSectionFormControl() {
    return this.renameSectionForm.controls;
  }
  get renameSubsectionFormControl() {
    return this.renameSubSectionForm.controls;
  }
  get newInsctructorformControl() {
    return this.newInstructorForm.controls;
  }

  addFirstSection(): void {
    this.newSectionFormSubmitted = true;

    if (this.newSectionForm.invalid) {
      return;
    }

    this.isStillupload = true;
    this.courseService
      .addSection(
        this.course.courseid,
        this.newSectionForm.value.name.toString().trim(),
        this.sections.length
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.course.newchanges = true;
            const sections = res.data;
            var tempSection = {
              sectionid: sections.sectionid,
              section_name: sections.sectionname.toString().trim(),
              sortkey: Number(this.sections.length),
              open: true,
              isNewSubsectionOpen: false,
              isStilluploadSubSection: false,
              isStillUpdateSubSection: false,
              isRename: false,
              unitlength: 0,
              sub_sections: [
                {
                  subsectionid: sections.subsectionid,
                  sub_section_name: sections.subsectionname,
                  sortkey: 0,
                  open: true,
                  isRename: false,
                  units: [],
                },
              ],
            };
            this.sections.push(tempSection);
            this.connectedSectionDropList.push(sections.sectionid);
            this.sectionsortKeyUpdate();
            this.newSectionForm.reset();
            this.showNewSection();
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }

          this.isStillupload = false;
          this.newSectionFormSubmitted = false;
        },
        (err) => {
          this.isStillupload = false;
          this.newSectionFormSubmitted = false;
        }
      );
  }

  addSection(index: number): void {
    this.sections[index].newSectionFormSubmitted = true;

    if (this.newSectionForm.invalid) {
      return;
    }

    this.sections[index].isStillupload = true;
    this.courseService
      .addSection(
        this.course.courseid,
        this.newSectionForm.value.name.toString().trim(),
        this.sections.length
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.course.newchanges = true;
            const sections = res.data;
            var tempSection = {
              sectionid: sections.sectionid,
              section_name: sections.sectionname.toString().trim(),
              sortkey: Number(this.sections.length),
              open: true,
              isNewSubsectionOpen: false,
              isStilluploadSubSection: false,
              isStillUpdateSubSection: false,
              isRename: false,
              unitlength: 0,
              sub_sections: [
                {
                  subsectionid: sections.subsectionid,
                  sub_section_name: sections.subsectionname,
                  sortkey: 0,
                  open: true,
                  isRename: false,
                  units: [],
                },
              ],
            };
            this.sections.splice(index + 1, 0, tempSection);
            this.connectedSectionDropList.push(sections.sectionid);
            this.sectionsortKeyUpdate();
            this.newSectionForm.reset();
            this.showNewSection(index);
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }

          this.sections[index].isStillupload = false;
          this.sections[index].newSectionFormSubmitted = false;
        },
        (err) => {
          this.sections[index].isStillupload = false;
          this.sections[index].newSectionFormSubmitted = false;
        }
      );
  }

  /**
   * @param status - check for subsection new form close and open
   */

  closeAllForm(status?: string) {
    return this.sections.map((section: any, index: number) => {
      section.isRename = false;
      if (status == 'open') {
        section.isNewSubsectionOpen = false;
      }
      section.sub_sections.map((subsection: any, ss: number) => {
        subsection.isRename = false;
        subsection.units.map((unit: any, su: number) => {
          unit.isRename = false;
        });
      });
    });
  }

  async showrenameSection(section_index: number) {
    const promise = Promise.all(this.closeAllForm());
    this.sections[section_index].isRename =
      !this.sections[section_index].isRename;
    this.renameSectionFormSubmitted = false;
    this.renameSectionForm.setValue({
      name: this.sections[section_index].section_name,
    });
  }

  renameSection(section_index: number): void {
    this.renameSectionFormSubmitted = true;

    if (this.renameSectionForm.invalid) {
      return;
    }

    this.isStillUpdate = true;
    this.renameSectionFormControl.name.disable();
    this.courseService
      .updateSection(
        this.sections[section_index].sectionid,
        this.renameSectionForm.value.name
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.course.newchanges = true;
            const sections = res.data;

            this.sections[section_index].section_name = sections.sectionname;
            this.renameSectionForm.reset();
            this.sections[section_index].isRename = false;

          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }

          this.isStillUpdate = false;
          this.renameSectionFormSubmitted = false;
          this.renameSectionFormControl.name.enable();
        },
        (err) => {
          this.renameSectionFormControl.name.enable();
          this.isStillUpdate = false;
          this.renameSectionFormSubmitted = false;
        }
      );
  }

  async showrenameSubsection(section_index: number, subsection_index: number) {
    const promise = Promise.all(this.closeAllForm());
    this.sections[section_index].sub_sections[subsection_index].isRename =
      !this.sections[section_index].sub_sections[subsection_index].isRename;
    this.renameSubsectionFormSubmitted = false;
    this.renameSubSectionForm.setValue({
      name: this.sections[section_index].sub_sections[subsection_index]
        .sub_section_name,
    });
  }

  addSubSection(section_index: number): void {
    this.newSubsectionFormSubmitted = true;

    this.sections[section_index].isStilluploadSubSection = true;

    this.courseService
      .addSubSection(
        this.sections[section_index].sectionid,
        this.renameSubSectionForm.value.name == '' ||
          this.renameSubSectionForm.value.name == null
          ? ''
          : this.renameSubSectionForm.value.name.toString().trim(),
        this.sections[section_index].sub_sections.length,
        this.course.courseid
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.course.newchanges = true;
            const subsections = res.data;
            this.connectedSubSectionDropList.push(subsections.subsectionid);
            this.sections[section_index].sub_sections.push({
              subsectionid: subsections.subsectionid,
              sub_section_name:
                subsections.subsectionname == ''
                  ? ''
                  : subsections.subsectionname.toString().trim(),
              sortkey: Number(this.sections[section_index].sub_sections.length),
              open: true,
              isRename: false,
              units: [],
            });

            this.newSubsectionForm.reset();
            this.renameSubSectionForm.reset();
            // this.renameSubSectionForm.value.name = "";
            this.showNewSubsection(this.sections[section_index], 'close');
          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }

          this.sections[section_index].isStilluploadSubSection = false;
          this.newSubsectionFormSubmitted = false;
        },
        (err) => {
          this.sections[section_index].isStilluploadSubSection = false;
          this.newSubsectionFormSubmitted = false;
        }
      );
  }

  renameSubSection(section_index: number, sub_section_index: number): void {
    this.renameSubsectionFormSubmitted = true;

    this.sections[section_index].sub_sections[
      sub_section_index
    ].isStillUpdateSubSection = true;
    this.renameSubsectionFormControl.name.disable();
    this.courseService
      .updateSubsection(
        this.sections[section_index].sub_sections[sub_section_index]
          .subsectionid,
        this.renameSubSectionForm.value.name == '' ||
          this.renameSubSectionForm.value.name == null
          ? ''
          : this.renameSubSectionForm.value.name.toString().trim()
      )
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.course.newchanges = true;
            const subsections = res.data;
            this.sections[section_index].sub_sections[
              sub_section_index
            ].sub_section_name = subsections.subsectionname;
            this.renameSubSectionForm.reset();
            this.sections[section_index].sub_sections[
              sub_section_index
            ].isRename = false;

          } else {
            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinoneService.logout();
              }, 2000);
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || res.error,
                'warn'
              );
            }
          }
          this.renameSubsectionFormControl.name.enable();
          this.sections[section_index].sub_sections[
            sub_section_index
          ].isStillUpdateSubSection = false;
          this.renameSubsectionFormSubmitted = false;
        },
        (err) => {
          this.renameSubsectionFormControl.name.enable();
          this.sections[section_index].sub_sections[
            sub_section_index
          ].isStillUpdateSubSection = false;
          this.renameSubsectionFormSubmitted = false;
        }
      );
  }

  /***
   * Content Type
   *  0 => Video
   *  1 => PDF
   *  3 => Article
   */

  showUnitEditor(
    section_index: number,
    sub_section_index: number,
    unit_index: number,
    action_status: string
  ): void {
    console.log('Your fun.');
    console.log(this.course);
    // console.log(this.course.libraryid);
    // console.log(this.course.libraryfilename);
    // console.log(this.course.attachment);

    /**
     * @param unit - unit data  for edit
     * @param unit_total_length - for sortkey
     */
    const promise = Promise.all(this.closeAllForm('close'));
    let dialogBoxSettings = {
      // width: '600px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      height: '100%',
      width: '100%',
      margin: '0 auto',
      hasBackdrop: true,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: 'uniteditor',
      data: {
        action_status: action_status,
        subsectionid:
          this.sections[section_index].sub_sections[sub_section_index]
            .subsectionid,
        unit:
          action_status == 'new'
            ? []
            : this.sections[section_index].sub_sections[sub_section_index]
              .units[unit_index],
        unit_total_length: Number(
          this.sections[section_index].sub_sections[sub_section_index].units
            .length
        ),
        courseid: this.course.courseid,
        attachment: this.course.libraryid ? this.course.libraryfilename : this.course.attachment
      },
    };
    let dialogRef = this.dialog.open(
      CourseUnitEditorComponent,
      dialogBoxSettings
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const unit = result.data;
        console.log('your result');
        console.log(result);


        if (result.action_status == 'new') {
          let icon;
          if (unit.contenttype == '1') {
            if (
              unit.content.toString().startsWith('https://youtu.be/') ||
              unit.content.toString().startsWith('https://www.youtube.com')
            ) {
              icon = 'youtube';
            } else {
              icon = 'vimeo';
            }
          } else if (unit.contenttype == '2') {
            const filetype = unit.content.split('.').pop();
            if (filetype == 'pdf') {
              icon = 'pdf';
            } else if (filetype == 'docx') {
              icon = 'word';
            } else if (filetype == 'pptx') {
              icon = 'powerpoint';
            } else if (filetype == 'xlsx') {
              icon = 'excel';
            }
          }

          this.sections[section_index].sub_sections[
            sub_section_index
          ].units.push({
            icon: icon,
            unitid: unit.unitid,
            unit_name: unit.unitname,
            open: unit.contenttype == 6 ? true : false,
            isRename: false,
            sortkey: unit.sortkey,
            contenttype: unit.contenttype,
            content: unit.content,
            path: unit.path,
            libraryid: unit.libraryid,
            downloadurl: unit.downloadurl,
            questions: [],
            gotquestions: true,
            gettingquestions: false,
            isNewQuestion: false,
            maxattempt: unit.maxattempt,
            quizconfig: unit.quizconfig,
            grade: unit.grade,
            studytime: unit.studytime,
            deadlinedate: unit.deadlinedate,
            deadlinetime: unit.deadlinetime,
            attachment: unit.attachment,
            libraryfilename: unit.libraryfilename,
            showfeedback: unit.showfeedback,
            autonumbering: unit.autonumbering,
            allowdownload: unit.allowdownload,
            bygroup: unit.bygroup,
            groupquestionscount: unit.groupquestionscount,
            grouptime: unit.grouptime,
            attachmentlist : unit.attachmentlist,
            randomassignment : unit.randomassignment,
            randomassignmentcount : unit.randomassignmentcount,
            multifileupload : unit.multifileupload,
            multifileuploadcount : unit.multifileuploadcount
          });
        } else {
          let icon;
          if (unit.contenttype == '1') {
            if (
              unit.content.toString().startsWith('https://youtu.be/') ||
              unit.content.toString().startsWith('https://www.youtube.com')
            ) {
              icon = 'youtube';
            } else {
              icon = 'vimeo';
            }
          } else if (unit.contenttype == '2') {
            const filetype = unit.content.split('.').pop();
            if (filetype == 'pdf') {
              icon = 'pdf';
            } else if (filetype == 'docx') {
              icon = 'word';
            } else if (filetype == 'pptx') {
              icon = 'powerpoint';
            } else if (filetype == 'xlsx') {
              icon = 'excel';
            }
          }

          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].unit_name = unit.unitname;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].content = unit.content;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].contenttype = unit.contenttype;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].icon = icon;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].downloadurl = unit.downloadurl;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].libraryid = unit.libraryid;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].maxattempt = unit.maxattempt;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].quizconfig = unit.quizconfig;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].open = false;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].grade = unit.grade;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].studytime = unit.studytime;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].deadlinedate = unit.deadlinedate;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].deadlinetime = unit.deadlinetime;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].attachment = unit.attachment;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].libraryfilename = unit.libraryfilename;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].showfeedback = unit.showfeedback;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].autonumbering = unit.autonumbering;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].allowdownload = unit.allowdownload;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].bygroup = unit.bygroup;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].groupquestionscount = unit.groupquestionscount;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].grouptime = unit.grouptime;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].attachmentlist = unit.attachmentlist;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].randomassignment = unit.randomassignment;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].randomassignmentcount = unit.randomassignmentcount;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].multifileupload = unit.multifileupload;
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].multifileuploadcount = unit.multifileuploadcount;
        }
        this.sections.map((section: any) => {
          section.unitlength = 0;
          section.sub_sections.map((subsection: any) => {
            section.unitlength += subsection.units.length;
          });
        });
        this.course.newchanges = true;
      }
    });
  }

  async showrenameUnit(unit: any) {
    unit.isRename = !unit.isRename;
    this.renameUnitForm.setValue({ name: unit.unit_name });
  }

  renameUnit(
    section_index: number,
    sub_section_index: number,
    unit_index: number
  ): void {
    this.sections[section_index].sub_sections[sub_section_index].units[
      unit_index
    ].isStillUpdateUnit = true;
    const unitid =
      this.sections[section_index].sub_sections[sub_section_index].units[
        unit_index
      ].unitid;
    const unitname = this.renameUnitForm.value.name;

    this.courseService.renameUnit(unitid, unitname).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          const units = res.data;

          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].unit_name = units.unitname;
          this.renameUnitForm.reset();
          this.sections[section_index].sub_sections[sub_section_index].units[
            unit_index
          ].isRename =
            !this.sections[section_index].sub_sections[sub_section_index].units[
              unit_index
            ].isRename;
        } else {
          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || res.error,
              'warn'
            );
          }
        }

        this.sections[section_index].sub_sections[sub_section_index].units[
          unit_index
        ].isStillUpdateUnit = false;
      },
      (err) => {
        this.sections[section_index].sub_sections[sub_section_index].units[
          unit_index
        ].isStillUpdateUnit = false;
      }
    );
  }

  duplicateContent(sectioni: any = '', subsectioni: any = '', uniti: any = '', type: string) {
    // console.log(type);
    // console.log(this.sections[sectioni]);
    // console.log(this.sections[sectioni].sectionid);
    // console.log(this.sections[sectioni].sub_sections[subsectioni].units[
    //   uniti
    // ]);

    var dup_id = '';
    var coursename = '';

    if (type == '001') {
      dup_id = this.sections[sectioni].sectionid;
      coursename = this.sections[sectioni].section_name;
    } else if (type == '002') {
      dup_id = this.sections[sectioni].sub_sections[subsectioni]
        .subsectionid
      coursename = this.sections[sectioni].sub_sections[subsectioni]
        .sub_section_name;
    } else if (type == '003') {
      dup_id = this.sections[sectioni].sub_sections[subsectioni].units[
        uniti
      ].unitid
      coursename = this.sections[sectioni].sub_sections[subsectioni].units[
        uniti
      ].unit_name
    }

    const dialog = this.dialog.open(CourseContentDuplicateEditorComponent, {
      width: '500px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      closeOnNavigation: true,
      autoFocus: false,
      panelClass: ['mat-typography'],
      data: {
        coursename: coursename,
        duplicateid: dup_id,
        courseid: this.courseid,
        duplicatetype: type
      }
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      console.log('dr');

      console.log(dialogResult);
      if (dialogResult.duplicateCon) {
        this.course.newchanges = true;
        this.sections = [];
        this.isLoading = true;
        this.getCourseAndSections().then(async () => {
          this.isLoading = false;
        });
      }
    })
  }

  test() {
    this.sections = [];
    this.isLoading = true;
    this.getCourseAndSections().then(async () => {
      this.isLoading = false;
    });
  }

  deleteSection(section_index: number): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .deleteSection(this.sections[section_index].sectionid)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.course.newchanges = true;
                const drag_index = this.connectedSectionDropList.findIndex(
                  (x: string) => x === this.sections[section_index].sectionid
                );
                this.connectedSectionDropList.splice(drag_index, 1);
                this.sections.splice(section_index, 1);
                if (this.sections.length > 0) {
                  this.sectionsortKeyUpdate();
                }
              } else {
                if (res.returncode == '210') {
                  setTimeout(() => {
                    this.router.navigateByUrl('login');
                    this.allinoneService.logout();
                  }, 2000);
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message || res.error,
                    'warn'
                  );
                }
              }
              this.loadingService.hide();
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }

  deleteSubSection(section_index: number, sub_section_index: number): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .deleteSubsection(
            this.sections[section_index].sub_sections[sub_section_index]
              .subsectionid
          )
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.course.newchanges = true;
                const drag_index = this.connectedSubSectionDropList.findIndex(
                  (x: string) =>
                    x ===
                    this.sections[section_index].sub_sections[sub_section_index]
                      .subsectionid
                );
                this.connectedSubSectionDropList.splice(drag_index, 1);
                this.sections[section_index].sub_sections.splice(
                  sub_section_index,
                  1
                );
                if (this.sections[section_index].sub_sections.length > 0) {
                  this.subSectionKeyUpdate(section_index);
                }
                this.sections.map((section: any) => {
                  section.unitlength = 0;
                  section.sub_sections.map((subsection: any) => {
                    section.unitlength += subsection.units.length;
                  });
                });
              } else {
                if (res.returncode == '210') {
                  setTimeout(() => {
                    this.router.navigateByUrl('login');
                    this.allinoneService.logout();
                  }, 2000);
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message || res.error,
                    'warn'
                  );
                }
              }

              this.loadingService.hide();
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }

  deleteUnit(
    section_index: number,
    sub_section_index: number,
    unit_index: number
  ): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.courseService
          .deleteUnit(
            this.sections[section_index].sub_sections[sub_section_index].units[
              unit_index
            ].unitid,
            this.courseid
          )
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.course.newchanges = true;
                this.sections[section_index].sub_sections[
                  sub_section_index
                ].units.splice(unit_index, 1);
                if (
                  this.sections[section_index].sub_sections[sub_section_index]
                    .units.length > 0
                ) {
                  this.unitKeyUpdate(section_index, sub_section_index);
                }
                this.sections.map((section: any) => {
                  section.unitlength = 0;
                  section.sub_sections.map((subsection: any) => {
                    section.unitlength += subsection.units.length;
                  });
                });
              } else {
                if (res.returncode == '210') {
                  setTimeout(() => {
                    this.router.navigateByUrl('login');
                    this.allinoneService.logout();
                  }, 2000);
                } else {
                  this.messageService.openSnackBar(
                    res.status || res.message || res.error,
                    'warn'
                  );
                }
              }
              this.loadingService.hide();
            },
            (err) => {
              this.loadingService.hide();
            }
          );
      }
    });
  }

  preview() {
    this.router.navigateByUrl(
      `preview/information/instructor/courses/${this.courseid}`
    );
  }

  previewcontent() {
    this.router.navigateByUrl(
      `preview/content/instructor/courses/${this.courseid}`
    );
  }

  testHtml() {
    var param = {
      unitname: 'test',
      contenttype: 'test',
      subsectionid: '123456',
      content: this.htmlContent,
      method: 1,
    };

    this.http
      .post(
        'https://d5vt0qav66.execute-api.ap-southeast-1.amazonaws.com/elearning/unit',
        param
      )
      .subscribe((data) => { });
  }

  checkTemplate(event: any) {
    this.classTemplate.templateid = event.target.value;
  }
  changeInstructorProfile() {
    if (this.isPrimary) {
      const dialog = this.dialog.open(ChangeProfileComponent, {
        width: '500px',
        minHeight: '350px',
        maxHeight: '90vh',
        closeOnNavigation: true,
        autoFocus: false,
        scrollStrategy: new NoopScrollStrategy(),
        data: {
          profileid: this.primaryInstructor.profileid,
          courseid: this.courseid,
        },
      });

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.primaryInstructor.profileid = result.profileid;
          this.primaryInstructor.institute = result.institute;
          this.primaryInstructor.profileimage = result.profileimageurl;
          this.primaryInstructor.username = result.name;
        }
      });
    }
  }
  addTag() {
    this.messageService.openSnackBar(
      'This feature is not available yet.',
      'info'
    );
  }

  viewDiscussion(unit: any) {
    this.router.navigateByUrl(
      `instructor/courses/${this.courseid}/curriculum/${unit.unitid}`
    );
    this.gettingDiscussion = true;
  }

  updateSchedule(schedule: Schedule) {
    this.courseSchedule = schedule;
  }

  viewFeedbackTemplateQuestions() {
    const dialog = this.dialog.open(TemplateQuestionsViewComponent, {
      maxWidth: '95vw',
      width: '800px',
      // height: '600px',
      maxHeight: '95vh',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        templateid: this.course.feedbacktemplateid,
        courseid: this.courseid
      }
    });

    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) { }
    });
  }

  newimportquestion(unit: any) {
    const dialog = this.dialog.open(ImportQuestionsComponent, {
      width: '900px',
      maxWidth: '99vw',
      height: 'auto',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        courseid: this.courseid,
        unitid: unit.unitid,
        sortkey: unit.questions.length,
      },
    });
    dialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.course.newchanges = true;
        for (let i = 0; i < dialogResult.questions.length; i++) {
          unit.questions.push(dialogResult.questions[i]);
        }
      }

    });

  }

  importFromExcel(unit: any) {
    let dialogRef = this.dialog.open(FileUploadTwoComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        channelid: this.course.channelid,
        type: '004',
        unitid: unit.unitid,
        courseid: this.courseid
      }
    });
    dialogRef.afterClosed().subscribe((dresult) => {
      if (dresult.success) {
        this.course.newchanges = true;
        this.getQuestions(unit);
      }
    })
  }

  publicChanges() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        message: 'Are you sure to publish the changes.'
      }
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.publicChangesLoading = true;
        var data = {
          courseid: this.courseid
        }
        this.courseService.publicChanges(data).subscribe((res: any) => {
          if (res.returncode == 300) {
            this.course.newchanges = false;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.loadingService.hide();
          this.publicChangesLoading = false;
        }),
          (err: any) => {
            this.loadingService.hide();
            this.publicChangesLoading = false;
          }
      }
    })
  }


}
