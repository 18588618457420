<div class="mb-3 d-flex justify-content-between flex-wrap">
  <!-- <button (click)="test()" >test</button> -->
  <div>
    <h2 class="mb-0"><strong>{{isEditing ? 'Edit Question' : 'New Question'}}</strong></h2>
  </div>
  <div>
    <select class="form-select" [(ngModel)]="type">
      <option *ngFor="let option of quesitontypes" [selected]="option.id == type" [value]="option.id">
        {{option.name}}
      </option>
    </select>
  </div>
</div>

<!-- <div class="mb-3 row">
  <label for="staticEmail" class="col-sm-2 col-form-label">Question Type</label>
  <div class="col-10">
    <select class="form-select">
      <option *ngFor="let option of quesitontypes" [selected]="option.id == type" [value]="option.id">
        {{option.name}}
      </option>
    </select>
  </div>mhnbgf7y6r4e3w2q524wt6rhgfduy7t6ytr54e3we45654ew32q1  q2w3e45r6780oi9u8
</div> -->

<!-- <button (click)="t()">t</button> -->



<ng-container *ngIf="type != '004'">
  <!-- <div class="mb-2" *ngIf="type != '001'"> -->
  <div class="mb-2">
    <label class="mb-2"><strong>Question</strong></label>
    <div class="wrapper">
      <button type="button" class="custom-button" (click)="AddQuestionBlank()" >{{type == '005' ?  'Add DropDown' : 'Add Blank'}}</button>
    <angular-editor [(ngModel)]="question" [config]="config" placeholder=" ">
    </angular-editor>
    </div>
  </div>

  <ng-container>
    <input style="display: none" type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput multiple>
    <button type="button" class="btn btn-custom mb-2" (click)="hiddenfileinput.click()">
      <i class="fa fa-plus me-1"></i> Image
    </button>
  </ng-container>

  <div>
    <div class="row">
      <div class="img-container" *ngFor="let item of imageList; let index = index">
        <div class="img-border card">
          <img [src]="item.previewurl">
          <mat-icon class="close" (click)="removeImage(index, item)">close</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-2 d-flex align-items-center flex-wrap">
    <div class="me-2">
      <strong>Answers</strong>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == '001' || type == '002'">
  <div *ngFor="let ans of answers; let index = index">
    <div class="form-check mb-2">
      <input class="form-check-input" type="checkbox" [name]="'answer'+index" [id]="index" [value]="index"
        [(ngModel)]="ans.correct" [checked]="ans.correct">
      <div class="d-flex">
        <div class="d-flex flex-column w-100">
          <div class="mb-2">
            <angular-editor [(ngModel)]="ans.answer" [config]="ans.config" placeholder="Add an answer" class="invalid">
            </angular-editor>
          </div>
          <div class="ps-5 w-100">
            <textarea class="form-control w-100" [(ngModel)]="ans.explanation" rows="1"
              style="border-radius:  0 !important;" placeholder="Explanation"></textarea>
          </div>
        </div>
        <div>
          <button mat-icon-button color="primary" (click)="removeAns(index)">
            <i class="fa fa-trash-o" aria-hidden="true" style="color: red;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == '003'">
  <div class="mb-3" *ngIf="blankanslist.length > 0">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let ans of blankanslist">
        {{ans}}
        <mat-icon class="chip-remove" (click)="remove(ans)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div class="input-group mb-3">
    <input type="text" class="form-control" [(ngModel)]="tempAns" (keyup.enter)="addAnswer()">
    <span class="input-group-text" (click)="addAnswer()" style="cursor: pointer;">Add</span>
  </div>

  <div class="mb-2">
    <strong>Explanation</strong>
  </div>
  <div class="w-100 mb-3">
    <textarea class="form-control w-100" [(ngModel)]="blankexplanation" rows="1" style="border-radius:  0 !important;"
      placeholder="Explaination for the correct answer"></textarea>
  </div>

  <div class="mb-2">
    <strong>Settings</strong>
  </div>
  <div class="d-flex">
    <div class="form-check ">
      <input class="form-check-input cus-point" type="checkbox" name="casesensitive" id="casesensitive"
        [checked]="casesensitive" (change)="changeCaseSensitive($event)">
      <label class="form-check-label cus-point" for="casesensitive">
        Case Sensitive
      </label>
    </div>
    <div class="form-check mx-3">
      <input class="form-check-input cus-point" type="checkbox" name="fixedsequence" id="fixedsequence"
        [checked]="fixedsequence" [(ngModel)]="fixedsequence">
      <label class="form-check-label cus-point" for="fixedsequence">
        Fixed Sequence
      </label>
    </div>
  </div>


</ng-container>

<ng-container *ngIf="type == '004'">
  <div class="mb-3">
    <label class="mb-2"><strong>Question</strong></label>
    <angular-editor [(ngModel)]="question" [config]="config" placeholder=" "></angular-editor>
  </div>

  <div class="form-check mb-3">
    <input class="form-check-input" type="checkbox" id="countbyquantity" [(ngModel)]="countbyquantity">
    <label class="form-check-label" for="countbyquantity">
      Count the grade by quantity
    </label>
  </div>

  <div class="mb-2 d-flex align-items-center flex-wrap">
    <div class="me-3">
      <strong>Correct Matches</strong>
    </div>
  </div>
  <div class="row">
    <div class="w-50 mb-2">
      Item
    </div>
    <div class="w-45 mb-2">
      Match
    </div>
  </div>
  <ng-container *ngFor="let item of matching; let index = index">
    <div class="row">
      <div class="w-50 mb-3 d-flex flex-column">
        <input hidden (change)="quizFile($event, item, '001')" type='file' accept="image/png, image/jpeg" #qhFile>
        <div class=" input-group" style="max-height: 33.5px;">
          <input *ngIf="!item.quizimgtype" type='text' class="form-control" [(ngModel)]="item.question">
          <!-- <input *ngIf="item.quizimgtype" (click)="matchingQuizUpload(item)" type='text' placeholder="Click and upload your image." class="form-control" [(ngModel)]="item.question" readonly> -->


          <div *ngIf="item.quizimgtype" (click)="qhFile.click()" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Choose File</span>
          </div>

          <input *ngIf="item.quizimgtype" (click)="qhFile.click()" type='text' class="form-control"
            [(ngModel)]="item.question" placeholder="Click and upload image." readonly>
          <button class="btn btn-custom" (click)="changeMatchingQuizType(item)">
            <!-- <i class="fas fa-arrows-alt-h"></i> -->
            <i *ngIf="!item.quizimgtype" class="fas fa-image"></i>
            <i *ngIf="item.quizimgtype" class="fas fa-font"></i>
          </button>
        </div>
        <div *ngIf="item.quizimgtype && item.quizimgurl" class="matching-img-con mt-2"
          (click)="imageViewer(item.quizimgurl)"><img [src]="item.quizimgurl" alt="">
        </div>
      </div>

      <!-- <img [src]="item.quizimgurl" alt=""> -->

      <div class="w-45 mb-3 d-flex flex-column">
        <input hidden (change)="quizFile($event, item, '002')" type='file' accept="image/png, image/jpeg" #anshFile>
        <div class="input-group" style="max-height: 33.5px;">
          <input *ngIf="!item.ansimgtype" type='text' class="form-control" [(ngModel)]="item.answer">
          <!-- <input *ngIf="item.ansimgtype" type='text' placeholder="Click and upload your image." class="form-control" [(ngModel)]="item.answer" disabled> -->

          <div *ngIf="item.ansimgtype" (click)="anshFile.click()" class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Choose File</span>
          </div>
          <input *ngIf="item.ansimgtype" type='text' class="form-control" [(ngModel)]="item.answer"
            (click)="anshFile.click()" placeholder="Click and upload image." readonly>
          <button class="btn btn-custom" (click)="changeMatchingAnsType(item)">
            <i *ngIf="!item.ansimgtype" class="fas fa-image"></i>
            <i *ngIf="item.ansimgtype" class="fas fa-font"></i>
          </button>
        </div>
        <div *ngIf="item.ansimgtype && item.ansimgurl" (click)="imageViewer(item.ansimgurl)"
          class="matching-img-con mt-2"><img [src]="item.ansimgurl" alt="">
        </div>
      </div>

      <div class="w-5 d-flex align-items-center mb-3" style="max-height: 33.5px;">
        <!-- <button mat-icon-button color="primary"> -->
        <i class="fa fa-trash-o cus-point" aria-hidden="true" style="color: red;" (click)="removeMatching(index)"></i>
        <!-- </button> -->
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="type == '005'">
  <div class="mb-3" *ngFor="let eachdropdown of dropdownanswers; let index = index">
    <div class="mb-3" *ngIf="eachdropdown.blankanslist.length > 0">
      <!-- <mat-chip-list #chipList>
        <mat-chip *ngFor="let ans of eachdropdown.blankanslist; let chipindex = index" (click)="selecetcorrectansfromchip(chipindex,index)">
          <input class="form-check-input" type="radio"   [checked]="eachdropdown.correctanswer==chipindex.toString()" (click)="selecetcorrectansfromchip(chipindex,index)">&nbsp; {{ans}}
          <mat-icon class="chip-remove" (click)="removefromdroopdown(ans,index,chipindex)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list> -->

      <mat-chip-list #chipList>
        <mat-chip *ngFor="let ans of eachdropdown.blankanslist; let chipindex = index">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [checked]="eachdropdown.correctanswer.includes(chipindex.toString())"
            (change)="toggleCorrectAnswer(chipindex, index)" 
          />
          &nbsp; {{ans}}
          <mat-icon class="chip-remove" (click)="removefromdroopdown(ans, index, chipindex)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="d-flex">
      <div class="d-flex flex-column w-100">
        <div class="input-group mb-1">
          <div>{{index+1}}.&nbsp; </div>
          <input type="text" class="form-control" [(ngModel)]="eachdropdown.inputfield" (keyup.enter)="addChoicestoDropdowns(index)">
          <span class="input-group-text" (click)="addChoicestoDropdowns(index)" style="cursor: pointer;">Add</span>
        </div>
        <div class="ps-5 w-100">
          <textarea class="form-control w-100" [(ngModel)]="eachdropdown.explanation" rows="1"
            style="border-radius:  0 !important;" placeholder="Explanation"></textarea>
        </div>
      </div>
      <div>
        <button mat-icon-button color="primary" (click)="removeChoicefromDropdown(index)">
          <i class="fa fa-trash-o" aria-hidden="true" style="color: red;"></i>
        </button>
      </div>
    </div>
  </div>

</ng-container>

<div class="mb-2">
  <strong>Mark</strong>
</div>
<div class="w-100 mb-3">
  <textarea  type="number" class="form-control" [(ngModel)]="mark" rows="1" style="border-radius:  0 !important;"></textarea>
</div>
<div class="d-flex align-items-center justify-content-between">
  <div>
    <button mat-button color="primary" (click)="onfocus()"
      *ngIf="((type == '001' || type == '002') && answers.length < 5 ) || (type == '004') || (type=='005')">
      <i class="fa fa-plus" aria-hidden="true"></i>
      Answer</button>

  </div>
  <div class="actions-button">
    <button mat-stroked-button class="mt-2 cancel-button" type="button" (click)="cancel()">
      Cancel
    </button>

    <app-button-loader [button_text]="'Save'" [isStillupload]="isStillUpload" (click)="save()">
    </app-button-loader>
  </div>
</div>