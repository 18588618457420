<app-breadcrumb [s_breadcrumb]="'Prerequisites'"></app-breadcrumb>
<div class="content">
  <div class = "d-flex justify-content-end mt-3">
    <div class="d-flex flex-wrap align-items-center">
      <div class="mb-3 me-2">
        Total :<b> {{ courses.length }}</b>
      </div>
  
      <div class="input-group mb-3" style="width: 260px !important">
        <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText" />
        <button class="btn" type="button" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          *ngIf="searchText" (click)="clear()" style="border: 1px solid var(--main-color); color: var(--main-color)">
          <i class="fa fa-times"></i>
        </button>
      </div>
  
      <div class="form-group mb-3" style="width: 260px !important; margin-left: 16px">
        <select class="form-select" [(ngModel)]="channelid" (ngModelChange)="getCourses()"
          matTooltip="Filter By Channel" [disabled]="gettingmyChannel">
          <option [value]="chl.channelid" *ngFor="let chl of channels">
            {{ chl.name }}
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingmyChannel"></mat-progress-bar>
      </div>
  
      <button class="mb-3 ms-2 btn new-class" matTooltip="Refresh" (click)="getCourses()" type="button">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>
  <div>
    <table mat-table #table [dataSource]="coursesTableData" matSort style="width: 100%;" (matSortChange)="sortData($event)">
  
      <!-- Course Image Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="width: 180px !important;">
            <img *ngIf="element.courseimage == ''" [src]="allinoneService.defaultImg" class="course-listing-image" />
            <img *ngIf="element.courseimage != ''" [src]="allinoneService.imgurl + element.courseimage"
              class="course-listing-image" />
          </td>
      </ng-container>
  
      <!-- Cours Name Column -->
      <ng-container matColumnDef="coursename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="coursename">Course Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.coursename}}
        </td>
      </ng-container>
  
     
  
      <!-- Prerequisite Column -->
      <ng-container matColumnDef="prerequisite">
        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
          Prerequisites
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 140px !important;">
          <span *ngIf="element.prerequisites.length == 0">--</span>
          <ul *ngIf="element.prerequisites.length > 0" class="p-3 m-0">
            <li *ngFor="let item of element.prerequisites">
              {{item.coursename}}
            </li>
          </ul>
        </td>
  
      </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openPrerequisiteForm(row)"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td colspan="5">
          <span class="nodata" *ngIf="!gettingCourses">Empty</span>
          <span class="nodata" *ngIf="gettingCourses">
            <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
          </span>
        </td>
      </tr>
  
  
    </table>
  
    <mat-paginator #paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]" *ngIf="courses.length > 0">
    </mat-paginator>
  </div>
</div>