<div *ngIf="!isLoading">
  <app-breadcrumb [s_breadcrumb]="coursename" [a_breadcrumb]="'Video Tracking'" [t_breadcrumb]="unitname" (emit)="goToVideo()" (emit1)="goToVideo()">
  </app-breadcrumb>
</div>
<div class="container-fluid">
  <div>
    <!-- <button (click)="test()" >test</button> -->
    <div class="my-4">

      <div class="course-header mb-2 d-flex justify-content-between align-items-center">
        <div class="d-flex w-100 flex-wrap justify-content-between align-items-center">
          <!-- <div class="co-title">
            Video Tracking
          </div> -->
          <button type="button" class="btn new-class" (click)="downloadSheet()"
              matTooltip="Export Service Tickets To Excel">
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Export
            </button>
          <div class="d-flex flex-wrap align-items-center">
            <div class="me-2">
              Total :<b> {{datacount}}</b>
            </div>
            <div class="input-group" style="width: 260px !important;">
              <input class="form-control" type="text" (keyup)="searchItem($event)" placeholder="Search"
                (focus)="isFocus = true" (blur)="isFocus = false" aria-label="Search" [(ngModel)]="searchText">
              <button class="btn" type="button" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}"
                *ngIf="searchText" (click)="clear()"
                style="border: 1px solid var(--main-color);color: var(--main-color);">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style="overflow: auto;">
        <table mat-table #table [dataSource]="showVideoDetails" matSort (matSortChange)="sortData($event)" matSort
          class="mat-elevation-z1" style="width: 100%;">

          <ng-container matColumnDef="userid">

            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by user id"
              style="padding-left:0px;">User ID</th>
            <td mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
              {{element.userid}}
            </td>
          </ng-container>

          <ng-container matColumnDef="username">

            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by user name"
              style="padding-left:0px;">User Name</th>
            <td mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
              {{element.username ? element.username : ''}}
            </td>
          </ng-container>

          <ng-container matColumnDef="currenttime">

            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by current time"
              style="padding-left:0px;">Current Time</th>
            <td mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
              {{element.currenttime ? element.currenttime : ''}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastviewdate">

            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last view date"
              style="padding-left:0px;">Last Viewed Date</th>
            <td mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
              {{element.lastviewdate != '-' ? allinoneService.formatDBToShow(element.lastviewdate) + " " + element.lastviewtime : '-'}}
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="lastviewtime">

            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last view time"
              style="padding-left:0px;">Last View Time</th>
            <td mat-cell *matCellDef="let element" style="position: relative; overflow: hidden;">
              {{element.lastviewtime ? element.lastviewtime : ''}}
            </td>
          </ng-container> -->

          <!-- <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
            Type
          </th>
          <td mat-cell *matCellDef="let element"> {{ checkType(element.content) }} </td>
        </ng-container> -->

          <!-- <ng-container matColumnDef="classcode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Class
          </th>
          <td mat-cell *matCellDef="let element" px-3>
            <span *ngIf="element.classid" class="badge bg-main">{{element.classcode}}</span>
            <span *ngIf="!element.classid" class = "addtoclass" (click) = "addToClass(element)">Add to class</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="feedback">
          <th mat-header-cell *matHeaderCellDef> Feedback </th>
          <td mat-cell *matCellDef="let element" style="width: 500px;">
            <span *ngIf="element.feedback" class="cus-point feedback-data" (click)="feedback(element)">{{element.feedback}}</span>
            <span *ngIf="!element.feedback" class = "addtoclass" (click)="feedback(element)">Give feedback</span>
          </td>
        </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td colspan="5">
              <span class="nodata" *ngIf="!isLoading">Empty</span>
              <span class="nodata" *ngIf="isLoading">
                <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
              </span>
            </td>
          </tr>
        </table>
        <mat-paginator #paginator [style.display]="paginate == true ? 'block': 'none'" [pageSize]="100"
          [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>