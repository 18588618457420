import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { HttpClient } from '@angular/common/http';
import { InstructorFilterPipe } from '../../shared/filter/filter.pipe';

@Component({
  selector: 'app-courses-by-student',
  templateUrl: './courses-by-student.component.html',
  styleUrls: ['./courses-by-student.component.scss'],
})
export class CoursesByStudentComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  isAdmin: boolean = false;
  channelname: any = '';
  userid: any = '';
  username: any = '';
  isLoading: boolean = true;
  totaltime: any = '0 hr(s) 0 min(s)';
  courses: any[] = [];
  datacount: string = '0';
  searchText: string = '';
  isFocus: boolean = false;
  showcourses!: MatTableDataSource<any>;
  paginate: boolean = false;
  displayedColumns: any;
  channelid: any = '';
  role: string = '';
  type: string = '';
  fromltuser: boolean = false;
  ltid: string = '';
  LTName: string = '';
  public: any = "";
  publicValue: any = "";

  // Web Socket
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  constructor(
    public allinoneService: AllInOneService,
    private router: Router,
    private courseService: CourseService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private filterPipe: InstructorFilterPipe
  ) {
    console.log('Your loading');

    console.log(this.isLoading);

    this.route.data.subscribe((data) => {
      this.role = data.role;
      this.type = data.type;
    });
    if ((this.role == 'admin' && !this.allinoneService.isRootAdmin()) || (this.role == 'chladmin' && !this.allinoneService.isChlAdmin()) || (this.role == 'supervisor' && !this.allinoneService.isSupervisor())) {
      this.router.navigateByUrl('')
    }
    this.userid = this.route.snapshot.params['userid'];

    if (this.type == 'learningtrack') {
      this.fromltuser = true
      this.ltid = this.route.snapshot.params['ltid'];
    }

    if (this.route.snapshot.params['channelid'] == 'public') {
      this.channelid = '';
    } else {
      this.channelid = this.route.snapshot.params['channelid'];
    }
    this.public = this.route.snapshot.queryParamMap.get('type') || "";
    this.publicValue = this.allinoneService.decrypt(this.public);

  }

  ngOnInit(): void {
    // this.getCourseByUser();
    this.showcourses = new MatTableDataSource();
    this.isLoading = true;
    this.getSocketData();
    this.displayedColumns = ['image', 'coursename', 'timespent', 'grade', 'progress', 'status','enrolleddate', 'completeddate'];
  }

  ngOnDestroy(): void {
    this.socket?.close(3001);
    clearTimeout(this.socketConnectTime);
    console.log('Destoryed');

  }

  test() {
    this.isLoading = true;
    console.log(this.isLoading);

  }

  // downloadSheet() {

  // }

  downloadSheet() {
    // var name = this.coursename + '.xlsx';
    var name = this.username ? this.username + '.xlsx' : this.userid + '.xlsx';
    var data: any = [];

    var filteredData = this.filterPipe.transform(
      this.courses,
      this.searchText,
      'coursesbystudent'
    );

    filteredData.map((d: any) => {
      data.push({
        'Course Name': d.coursename,
        'Time Spent': d.totaltime,
        'Grade': d.grade + '%',
        'Progress': d.percentage + '%',
        'Status': d.completed ? 'Completed' : !d.completed && d.percentage == 0 ? 'Not Started' : 'In Progress',
        'Enrolled Date': d.enrolleddate,
        'Completed Date': d.completeddate
      })
    })
    this.allinoneService.exportExcel(data, name);
  }

  getSocketData() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.isLoading = true;
    this.connectSocketLoading = true;
    // const webSocketURL = this.allinoneService.attendanceSocketUrl;
    const webSocketURL = this.allinoneService.socketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {

      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {

      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      console.log('Changed data');
      console.log(tempData);
      if (this.connectionId == '') {
        this.connectionId = tempData.connectionId;
      }

      if (this.updateCId) {
        this.updateConnectionID();
      }

      if (tempData.message == 'Forbidden') {
        // if (this.displayMode == 'Daterange') {
        //   this.searchWithDateRangeWithSocket(false)
        // } else if (this.displayMode == 'Absentreport') {
        //   this.searchWithDateRangeWithSocket(true)
        // }
        // this.getCourseDuplicateData();
        this.getCourseByUser();
      }

      this.drsocketfileurl = tempData.fileurl;

      console.log('file url');
      console.log(this.drsocketfileurl);


      if (this.drsocketfileurl != '') {
        console.log('call file url');

        this.http.get(this.drsocketfileurl)
          .subscribe(
            (res: any) => {
              this.courses = [];
              this.showcourses = new MatTableDataSource(this.courses);
              console.log("Your get data res");
              console.log(res);
              this.isLoading = false;
              this.channelname = res.channelname;
              this.username = res.username;
              this.LTName = res.learningpathname;
              res.datalist.map(async (course: any) => {
                await this.courses.push({
                  courseid: course.courseid,
                  coursename: course.coursename,
                  courseimage: course.courseimage,
                  totalsecond: course.totalsecond,
                  totaltime: course.totaltime,
                  grade: course.grade,
                  completed: course.completed,
                  percentage: course.percentage,
                  enrolleddate : course.enrolleddate,
                  completeddate : course.completeddate
                });
                this.totaltime = res.totaltime;
                this.datacount = res.datalist.length;
              });
              this.showcourses = new MatTableDataSource(this.courses);
              this.showcourses.filterPredicate = function (
                f,
                filter: string
              ): boolean {
                return (
                  f.coursename.toLowerCase().includes(filter) ||
                  f.coursename.toLowerCase().includes(filter)
                );
              };
              if (this.courses == undefined || this.courses.length == 0) {
                this.paginate = false;
              } else {
                this.paginate = true;
              }

              this.showcourses.sort = this.sort;
              this.showcourses.paginator = this.paginator;

              setTimeout(() => {
                this.isLoading = false;
              }, 400);
              this.isLoading = false;
              this.connectionId = '';
              this.socket?.close(3001);
              clearTimeout(this.socketConnectTime);
            }
          )
      }
      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {

      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });


    this.socket.addEventListener('close', (event) => {

      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });

  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.isLoading = false;
      }
    }, 180 * 1000);
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '001'
    }
    this.courseService.updateConnectionID(data).subscribe(
      (res: any) => {
        console.log('id updated res');
        this.updateCId = false;
        console.log(res);
      }
    )
  }

  goTo(route: string) {
    this.router.navigateByUrl(route);
  }

  goTo1() {
    var route = this.role + '/analytics/' + this.route.snapshot.params['channelid'] + `?type=${this.allinoneService.encrypt(this.publicValue)}`;
    this.router.navigateByUrl(route);
  }

  goToLT() {
    this.router.navigateByUrl(`hradmin/learningtrack`)
  }

  goToLTUser() {
    this.router.navigateByUrl(`hradmin/learningtrack/${this.channelid}/${this.ltid}/user`)
  }

  getCourseByUser() {
    this.courses = [];
    this.showcourses = new MatTableDataSource(this.courses);
    this.isLoading = true;
    var fun = this.fromltuser ? this.courseService
      .getAnalyticsCourse(this.channelid, this.userid, '', '', '', this.connectionId, true, this.ltid) : this.courseService
        .getAnalyticsCourse(this.channelid, this.userid, '', '', '', this.connectionId);
    fun.subscribe(
      (courses: any) => {
        if (courses.returncode == '300') {
          // this.isLoading = false;
          // this.channelname = courses.channelname;
          // this.username = courses.username;
          // courses.datalist.map(async (course: any) => {
          //   await this.courses.push({
          //     courseid: course.courseid,
          //     coursename: course.coursename,
          //     courseimage: course.courseimage,
          //     totalsecond: course.totalsecond,
          //     totaltime: course.totaltime,
          //   });
          //   this.totaltime = courses.totaltime;
          //   this.datacount = courses.datalist.length;
          // });
          // this.showcourses = new MatTableDataSource(this.courses);
          // this.showcourses.filterPredicate = function (
          //   f,
          //   filter: string
          // ): boolean {
          //   return (
          //     f.coursename.toLowerCase().includes(filter) ||
          //     f.coursename.toLowerCase().includes(filter)
          //   );
          // };
          // if (this.courses == undefined || this.courses.length == 0) {
          //   this.paginate = false;
          // } else {
          //   this.paginate = true;
          // }

          // this.showcourses.sort = this.sort;
          // this.showcourses.paginator = this.paginator;

          // setTimeout(() => {
          //   this.isLoading = false;
          // }, 400);

          // this.table.renderRows();
        } else {
          if (courses.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinoneService.logout();
            }, 2000);
          } else {
            this.messageService.openSnackBar(courses.status, 'warn');
          }
          // setTimeout(() => {
          // }, 400);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  searchItem(data: any) {
    const searchItem = data.target.value;
    this.showcourses.filter = searchItem.trim().toLowerCase();
  }

  refresh() {
    this.clear();
    this.totaltime = '0 hr(s) 0 min(s)';
    this.paginate = false;
    // this.getCourseByUser();
    this.connectWebSocket();
  }

  clear() {
    this.searchText = '';
    this.showcourses.filter = '';
    // this.showstudents.filter = "";
  }

  announceSortChange(sort: Sort) {
    const data = this.courses;
    if (!sort.active || sort.direction === '') {
      this.courses = data;
      return;
    }

    this.courses = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      var as = a.completed ? 'Completed' : !a.completed && a.percentage == 0 ? 'Not Started' : 'In Progress';
      var bs = b.completed ? 'Completed' : !b.completed && b.percentage == 0 ? 'Not Started' : 'In Progress';
      switch (sort.active) {
        case 'coursename':
          return this.compare(a.coursename, b.coursename, isAsc);
        case 'timespent':
          return this.compare(a.totalsecond, b.totalsecond, isAsc);
        case 'grade':
          return this.compare(a.grade, b.grade, isAsc);
        case 'progress':
          return this.compare(a.percentage, b.percentage, isAsc);
        case 'status':
          return this.compare(as, bs, isAsc);
        case 'completeddate':
          return this.compare(this.allinoneService.formatDateTwo(a.completeddate), this.allinoneService.formatDateTwo(b.completeddate), isAsc);
        case 'enrolleddate':
          return this.compare(this.allinoneService.formatDateTwo(a.enrolleddate), this.allinoneService.formatDateTwo(b.enrolleddate), isAsc);
        default:
          return 0;
      }
    });
    this.showcourses = new MatTableDataSource(this.courses);
    this.showcourses.paginator = this.paginator;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  goToUserAnalytic(course: any) {
    const channel = this.channelid ? this.channelid : 'public';


    this.router.navigateByUrl(
      `${this.role}/analytics/${channel}/user/${this.userid}/${course.courseid}?type=${this.allinoneService.encrypt(this.publicValue)}`
    );
  }
}
